<script setup lang="ts">
  import api from '@/services/api'
  import { Ref, onMounted, ref } from 'vue'
  import { i18n } from '@/i18n'
  import { watch } from 'vue'
  import { useDebounceFn } from '@vueuse/core'
  import { useToast } from 'primevue/usetoast'

  interface TagObject {
    tagId: string
    tagName: string
    name: string
    description: string | null
    photoCount: number
    createTime: string
    isDefault: boolean
    userId: string | null
  }

  interface IPaginationInfo {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }

  interface ITagResponse {
    data: TagObject[]
    pagination: IPaginationInfo
  }

  type RefWithElement<T extends HTMLElement> = Ref<T & { $el: T }>
  const { t } = i18n.global
  const takeValue = ref(20)
  const skipValue = ref(0)
  const dialogCreateTag = ref(false)
  const dialogEditTag = ref(false)
  const dialogDeleteTag = ref(false)
  const tagEdit = ref<TagObject | null>(null)
  const createField = ref('')
  const editField = ref('')
  const toast = useToast()
  const searchInput = ref<RefWithElement<HTMLInputElement> | null>(null)
  const loadingCreateTag = ref(false)
  const loadingEditTag = ref(false)
  const loadingGetTags = ref(false)
  const searchTerm = ref('')
  const tagResponse = ref<ITagResponse>({} as ITagResponse)
  const handleScrollPagination = () => {
    if (tagResponse.value?.pagination.rowCount > tagResponse.value?.data.length) {
      skipValue.value = skipValue.value + 20
      takeValue.value = 20
    }
  }

  watch([takeValue, skipValue], () => {
    fechtTags({ skip: skipValue.value, take: takeValue.value, query: searchTerm.value })
  })

  const handleEmptyCreate = () => {
    createField.value = ''
  }

  const handleEmptyEdit = () => {
    editField.value = ''
  }

  interface IFilterParams {
    skip?: number
    take?: number
    query?: string
  }

  const resetPagination = () => {
    skipValue.value = 0
    takeValue.value = 20
  }
  const resetData = () => {
    tagResponse.value = {} as ITagResponse
  }

  const fechtTags = async (params?: IFilterParams) => {
    loadingGetTags.value = true
    const paramsQuery = []
    if (params?.skip || params?.skip === 0) paramsQuery.push(`&skip=${params.skip}`)
    if (params?.take) paramsQuery.push(`&take=${params.take}`)
    const response = await api.get(`/Api/PhotoFeed/Tags?query=${params?.query ?? ''}${paramsQuery.join('')}`)
    tagResponse.value = response.data.data
    loadingGetTags.value = false
  }

  const createTag = async () => {
    try {
      loadingCreateTag.value = true
      const payload = { names: [createField.value] }
      await api.post('/Api/Photo/Tag', payload)
      resetPagination()
      resetData()
      dialogCreateTag.value = false
      fechtTags({ skip: skipValue.value, take: takeValue.value })
      toast.add({ severity: 'success', detail: t('tags.createTagSuccess'), life: 3000 })
    } catch (error: any) {
      if (error.response?.data?.responseException?.exceptionMessage?.message) {
        toast.add({ severity: 'error', summary: t('tags.createTagError'), detail: error.response?.data?.responseException?.exceptionMessage.message, life: 3000 })
      }
    } finally {
      loadingCreateTag.value = false
    }
  }

  const deleteTag = async () => {
    try {
      loadingEditTag.value = true
      await api.delete(`/Api/Photo/Tag/${tagEdit.value?.tagId}`)
      resetPagination()
      resetData()
      dialogDeleteTag.value = false
      dialogEditTag.value = false
      fechtTags({ skip: skipValue.value, take: takeValue.value })
      toast.add({ severity: 'success', detail: t('tags.deleteTagSuccess'), life: 3000 })
    } catch (error: any) {
      if (error.response?.data?.responseException?.exceptionMessage?.message) {
        toast.add({ severity: 'error', summary: t('tags.deleteTagError'), detail: error.response?.data?.responseException?.exceptionMessage.message, life: 3000 })
      }
    } finally {
      loadingEditTag.value = false
    }
  }

  const editTag = async () => {
    try {
      loadingEditTag.value = true
      const payload = { name: editField.value }
      await api.patch(`/Api/Photo/Tag/${tagEdit.value?.tagId}`, payload)
      resetPagination()
      resetData()
      dialogEditTag.value = false
      fechtTags({ skip: skipValue.value, take: takeValue.value })
      toast.add({ severity: 'success', detail: t('tags.editTagSuccess'), life: 3000 })
    } catch (error: any) {
      if (error.response?.data?.responseException?.exceptionMessage?.message) {
        toast.add({ severity: 'error', summary: t('tags.editTagError'), detail: error.response?.data?.responseException?.exceptionMessage.message, life: 3000 })
      }
    } finally {
      loadingEditTag.value = false
    }
  }

  const debouncedFn = useDebounceFn(async () => {
    console.log('debounced', { searchTerm: searchTerm.value })
    await fechtTags({ skip: skipValue.value, take: takeValue.value, query: searchTerm.value })
  }, 500)

  onMounted(() => {
    fechtTags({ skip: skipValue.value, take: takeValue.value, query: searchTerm.value })
  })
</script>
<template>
  <Toast position="top-right" />
  <div>
    <div>
      <div class="flex justify-content-between">
        <h1>Tags</h1>
        <Button
          :label="t('tags.createTag')"
          icon="pi pi-tags"
          icon-pos="right"
          class="p-button-text"
          @click="
            () => {
              dialogCreateTag = true
              handleEmptyCreate()
            }
          "
        />
      </div>
      <div class="pt-2">
        <span class="p-input-icon-left w-full md:w-full">
          <i class="pi pi-search" />
          <InputText v-model="searchTerm" type="text" :placeholder="t('directoryIndex.search')" class="w-full md:w-full" @input="debouncedFn" />
        </span>
      </div>
    </div>
    <div>
      <div class="w-full flex list-none flex-wrap py-4 gap-2">
        <Tag
          v-for="(tag, index) in tagResponse?.data"
          :key="tag.tagId"
          :value="tag.name"
          :disabled="tag.isDefault"
          :severity="['info', 'success', 'warning', 'danger'][index % 4]"
          @click="
            () => {
              dialogEditTag = true
              tagEdit = tag
              editField = tag.name
            }
          "
        />
      </div>
      <div v-show="!loadingGetTags && tagResponse?.pagination?.nextPage !== null" class="flex justify-content-center">
        <InfiniteLoading v-show="!loadingGetTags && tagResponse?.pagination?.nextPage !== null" @infinite="handleScrollPagination" />
      </div>
    </div>
  </div>
  <!-- dialog create -->
  <Dialog
    v-model:visible="dialogCreateTag"
    modal
    :header="t('tags.createTagHeader')"
    :draggable="false"
    :style="{ width: '50vw', marginTop: '70px' }"
    content-class="p-0"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <div class="w-full p-3 flex flex-column justify-content-end align-items-end">
      <div class="flex w-full align-items-center md:justify-content-end justify-content-center pb-4">
        <div class="col-12 flex align-items-center justify-content-center">
          <div class="w-full relative p-input-icon-right">
            <InputText ref="searchInput" v-model="createField" type="text" :placeholder="t('tags.createTagPlaceholder')" class="w-full pr-5" />
            <i v-if="loadingCreateTag" class="pi pi-spin pi-spinner absolute" style="right: 10px" />
            <i v-if="!loadingCreateTag && createField.length > 0" class="pi pi-times absolute" style="right: 10px" @click="handleEmptyCreate" />
          </div>
        </div>
      </div>
      <div class="">
        <Button :label="t('tags.createButton')" @click="createTag" />
      </div>
    </div>
  </Dialog>
  <!-- dialog edit -->
  <Dialog
    v-model:visible="dialogEditTag"
    modal
    :header="t('tags.editTagHeader')"
    :draggable="false"
    :style="{ width: '50vw', marginTop: '70px' }"
    content-class="p-0"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <div class="w-full p-3 flex flex-column justify-content-end align-items-end">
      <div class="flex w-full align-items-center md:justify-content-end justify-content-center pb-4">
        <div class="col-12 flex align-items-center justify-content-center">
          <div class="w-full relative p-input-icon-right">
            <InputText ref="searchInput" v-model="editField" type="text" :placeholder="t('tags.createTagPlaceholder')" class="w-full pr-5" />
            <i v-if="loadingEditTag" class="pi pi-spin pi-spinner absolute" style="right: 10px" />
            <i v-if="!loadingEditTag && editField.length > 0" class="pi pi-times absolute" style="right: 10px" @click="handleEmptyEdit" />
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-2">
        <Button :label="t('tags.deleteTagButton')" class="p-button-text" severity="danger" text @click="dialogDeleteTag = true" />
        <Button :label="t('tags.editButton')" class="p-button-info" :loading="loadingEditTag" @click="editTag" />
      </div>
    </div>
  </Dialog>
  <!-- dialog delete -->
  <Dialog v-model:visible="dialogDeleteTag" class="w-full m-0" style="max-width: 42rem" :header="t('tags.deleteTagHeader')" :modal="true" :draggable="false">
    <div class="flex flex-column gap-2">
      <div style="font-size: 1rem; font-weight: 600; margin-bottom: 0.5rem">
        {{ t('tags.deleteTagDescription') }}
      </div>
      <div class="flex flex-row gap-2">
        <Button :label="t('tags.deleteTagButtonCancel')" class="p-button-text" @click="dialogDeleteTag = false" />
        <Button :label="t('tags.deleteTagButton')" class="p-button-danger" @click="deleteTag" />
      </div>
    </div>
  </Dialog>
</template>
