<script lang="ts" setup>
  import { nextTick, ref, onUnmounted, type Ref } from 'vue'
  import { useIntersectionObserver } from '@vueuse/core'

  const props = defineProps({
    renderOnIdle: Boolean,
    unrender: Boolean,
    minHeight: {
      type: Number,
      default: () => 0,
    },
    unrenderDelay: {
      type: Number,
      default: 10000,
    },
  })

  const shouldRender = ref(false)
  const targetEl: Ref<null | HTMLDivElement> = ref(null)
  const fixedMinHeight = ref(0)
  let unrenderTimer: ReturnType<typeof setTimeout>
  let renderTimer: ReturnType<typeof setTimeout>

  function onIdle(
    cb = () => {
      //
    }
  ) {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(cb)
    } else {
      setTimeout(() => {
        nextTick(cb)
      }, 300)
    }
  }

  const { stop } = useIntersectionObserver(
    targetEl,
    ([{ isIntersecting }]) => {
      if (isIntersecting) {
        clearTimeout(unrenderTimer)
        renderTimer = setTimeout(() => (shouldRender.value = true), props.unrender ? 200 : 0)
        shouldRender.value = true
        if (!props.unrender) {
          stop()
        }
      } else if (props.unrender) {
        clearTimeout(renderTimer)
        unrenderTimer = setTimeout(() => {
          if (targetEl.value) {
            fixedMinHeight.value = targetEl.value.clientHeight
            shouldRender.value = false
          }
        }, props.unrenderDelay)
      }
    },
    {
      rootMargin: '600px',
    }
  )
  if (props.renderOnIdle) {
    onIdle(() => {
      shouldRender.value = true
      if (!props.unrender) {
        stop()
      }
    })
  }

  onUnmounted(() => {
    clearTimeout(unrenderTimer)
    clearTimeout(renderTimer)
    shouldRender.value = false
    targetEl.value = null
    fixedMinHeight.value = 0
  })
</script>

<template>
  <div ref="targetEl" :style="`min-height:${fixedMinHeight ? fixedMinHeight : minHeight}px`">
    <slot v-if="shouldRender" />
  </div>
</template>
