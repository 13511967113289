<script lang="ts" setup>
  import { computed, toRefs } from 'vue'
  import { stickers } from '../mocks/sticker'
  import { TShapeStickerPath } from '../shapes/types'
  import { useDialogs } from '../store/dialogs'
  import { templateShape } from '../templates/shape'
  import { useColor } from '../store/color'
  import { useStage } from '../store/stage'
  import { coordinatesShape } from '../mocks/coodinatesRotate'
  import { useShapes } from '../store/shapes'
  const colorStore = useColor()
  const stageStore = useStage()
  const shapesStore = useShapes()
  const { image } = toRefs(stageStore)
  const { showDialogSticker, handleShowDialog } = useDialogs()
  const { color } = toRefs(colorStore)
  const scaledSize = computed(() => {
    const referenceWidth = 1280
    const referenceHeight = 720

    const widthScale = image.value.original_width / referenceWidth
    const heightScale = image.value.original_height / referenceHeight
    const scale = (widthScale + heightScale) / 2

    const baseSize = 300 // Base size in pixels
    const scaledSize = baseSize * scale

    return scaledSize
  })
  const { handleCreateShape } = shapesStore
  const handleSticker = (keypath: TShapeStickerPath) => {
    const range = scaledSize.value
    const shape = templateShape({
      shapeKey: 'STICKER',
      color: color.value,
      screenWidth: image.value.width,
      screenHeight: image.value.height,
      image_scale: image.value.scale,
      image_rotation: image.value.rotation,
      width: range,
      height: range,
      pathSticker: keypath,
      points: [range, range],
      range,
      rotation: {
        angle: 90,
        points: [0, 0, 80, 0],
        rotation: coordinatesShape[`${image.value.rotation}` as keyof typeof coordinatesShape]?.rotate,
        x: 205.71428571428572,
        y: 290.9627329192547,
      },
    })
    handleCreateShape(shape)
  }
</script>

<template>
  <Dialog
    v-model:visible="showDialogSticker"
    :pt="{
      mask: {
        style: {
          zIndex: 1215,
        },
      },
    }"
    :modal="true"
    :base-z-index="1215"
    :auto-z-index="false"
    position="left"
    header="Add Sticker"
    class="w-full ml-8"
    style="max-width: 42rem; z-index: 1215"
    :draggable="false"
  >
    <div class="flex w-full align-items-center justify-content-center p-5">
      <div
        class="grid col-4 w-full"
        :style="{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
          padding: 0,
          margin: 0,
        }"
      >
        <div
          v-for="sticker in stickers"
          :key="sticker"
          @click="
            () => {
              handleSticker(sticker)
              handleShowDialog('showDialogSticker', false)
            }
          "
        >
          <img :src="`/stickers/diff/${sticker}.png`" :alt="`${sticker}.png`" width="100" height="100" class="flex flex-column align-items-center justify-content-center" />
        </div>
      </div>
    </div>
  </Dialog>
</template>
