<script lang="ts" async setup>
  import { i18n } from '@/i18n'
  import useVuelidate from '@vuelidate/core'
  import { Ref, ref, reactive } from 'vue'
  import { required } from '@vuelidate/validators'
  import { useRouter } from 'vue-router'
  import { AxiosError } from 'axios'
  import api from '@/services/api'
  import { captureException } from '@sentry/vue'

  interface ErrorResponse {
    status: number
    isError: boolean
    responseException: { exceptionMessage: { message: string } }
  }
  interface tenantDropdownValue {
    clientName: string
    name: string
  }
  interface IField {
    value?: string
    labelText: string
    isDisabled: boolean
  }
  interface ITenantSelection {
    value: tenantDropdownValue | null
    labelText: string
    tenants: any[]
    isActive: boolean
  }
  interface IButton {
    labelText: string
    icon: string
    isActive: boolean
    isLoading: boolean
    isDisabled: boolean
  }
  interface ISignInOptions {
    username: IField
    tenantSelection: ITenantSelection
    submit_button: IButton
  }
  interface IChangePassword {
    code: IField
    newPassword: IField
    confirmPassword: IField
    tenantSelection: ITenantSelection
    submit_button: IButton
  }

  const errorMessage: Ref<null | string> = ref(null)
  const router = useRouter()
  const formCode = ref(false)
  const ChangePassword: IChangePassword = reactive({
    code: { labelText: i18n.global.t('forgotPassword.code'), value: '', isDisabled: false },
    newPassword: { labelText: i18n.global.t('forgotPassword.newPassword'), isDisabled: false },
    confirmPassword: { labelText: i18n.global.t('forgotPassword.confirmPassword'), isDisabled: false },
    tenantSelection: { labelText: i18n.global.t('forgotPassword.selectATenant'), tenants: [], value: null, isActive: false },
    submit_button: { labelText: i18n.global.t('forgotPassword.resetPassword'), icon: 'pi pi-chevron-right', isActive: false, isLoading: false, isDisabled: false },
  })

  const resetPassword: ISignInOptions = reactive({
    username: { labelText: i18n.global.t('forgotPassword.userNameOrEmail'), isDisabled: false },
    tenantSelection: { labelText: i18n.global.t('forgotPassword.selectATenant'), tenants: [], value: null, isActive: false },
    submit_button: { labelText: i18n.global.t('forgotPassword.continue'), icon: 'pi pi-chevron-right', isActive: false, isLoading: false, isDisabled: false },
  })

  const rules = {
    username: { value: { required } },
  }

  const v$ = useVuelidate(rules, resetPassword)

  async function handleSubmit(isFormValid: boolean) {
    try {
      await v$.value.$validate()
      resetPassword.submit_button.isLoading = true
      if (!isFormValid) {
        resetPassword.submit_button.isLoading = false
        errorMessage.value = i18n.global.t('forgotPassword.usernameoremail')
        return
      }
      const payload = {
        email: resetPassword.username.value,
      }

      const {
        data: { data: response },
      } = await api.post(`/Api/ResetPassword`, payload)
      if (response) formCode.value = true
    } catch (err) {
      captureException(err)
      const error = err as AxiosError<ErrorResponse>
      errorMessage.value = `${error?.response?.data?.responseException.exceptionMessage.message}`
    } finally {
      resetPassword.submit_button.isLoading = false
    }
  }

  const rulesChangePassword = {
    code: { value: { required } },
    newPassword: { value: { required } },
    confirmPassword: { value: { required, sameAsPassword: (value: string) => value === ChangePassword.newPassword.value } },
  }

  const vChangePassword = useVuelidate(rulesChangePassword, ChangePassword)

  async function ResetPassword(isFormValid: boolean) {
    try {
      ChangePassword.submit_button.isLoading = true

      await vChangePassword.value.$validate()
      if (!isFormValid) {
        const confirmPasswordInvalid = vChangePassword.value.confirmPassword?.value?.sameAsPassword?.$invalid
        errorMessage.value = confirmPasswordInvalid ? i18n.global.t('forgotPassword.passwordsDontMatch') : i18n.global.t('forgotPassword.credentialsAreRequired')
        resetPassword.submit_button.isLoading = false
        return
      }

      const payload = {
        code: ChangePassword.code.value,
        password: ChangePassword.newPassword.value,
        confirmPassword: ChangePassword.confirmPassword.value,
      }

      await api.post(`/Api/ChangePassword`, payload)
      await router.push('/')
    } catch (err) {
      captureException(err)
      const error = err as AxiosError<ErrorResponse>
      errorMessage.value = `${error?.response?.data?.responseException.exceptionMessage.message}`
    } finally {
      ChangePassword.submit_button.isLoading = false
      resetPassword.submit_button.isLoading = false
    }
  }
</script>

<template>
  <div class="grid m-0 p-0" style="background-image: url('/assets/img/bg/bg1.webp')">
    <div class="col h-screen m-0 p-0">
      <div class="flex align-items-center justify-content-center h-screen">
        <Card v-if="formCode" class="border-round card-dimensions">
          <template #header>
            <div class="text-center mb-4">
              <img src="/assets/icon/logo.png" alt="Image" height="90" class="w-auto mt-4" style="margin-bottom: -14px" />
              <div class="text-900 text-3xl font-medium line-height-1">
                {{ $t('forgotPassword.production') }}
              </div>
              <span class="text-600 font-normal line-height-1">{{ $t('forgotPassword.byInfinityHomeServices') }}</span>
            </div>
          </template>

          <template #content>
            <Message v-if="errorMessage" severity="error" :closable="false" icon="pi pi-lock">{{ errorMessage }} </Message>
            <form class="w-full" @submit.prevent="ResetPassword(!vChangePassword.$invalid)">
              <div class="p-input-icon-left w-full mb-3">
                <i class="pi pi-lock" />
                <InputMask
                  v-model="ChangePassword.code.value"
                  :input-props="{ inputmode: 'decimal' }"
                  class="w-full"
                  mask="999999"
                  :disabled="ChangePassword.code.isDisabled"
                  :placeholder="ChangePassword.code.labelText"
                  :class="{ 'p-invalid': v$.username.$errors.length > 0 }"
                  name="code"
                  style="text-transform: lowercase"
                />
              </div>
              <div class="p-input-icon-left w-full mb-3">
                <Password
                  v-model="ChangePassword.newPassword.value"
                  toggle-mask
                  class="w-full"
                  input-class="w-full"
                  :feedback="false"
                  :input-props="{ disabled: ChangePassword.newPassword.isDisabled }"
                  :placeholder="ChangePassword.newPassword.labelText"
                  name="newPassword"
                />
              </div>
              <div class="p-input-icon-left w-full mb-3">
                <i class="pi pi-user" />
                <Password
                  v-model="ChangePassword.confirmPassword.value"
                  toggle-mask
                  class="w-full"
                  input-class="w-full"
                  :feedback="false"
                  :input-props="{ disabled: ChangePassword.confirmPassword.isDisabled }"
                  :placeholder="ChangePassword.confirmPassword.labelText"
                  name="confirmPassword"
                />
              </div>
              <div v-if="false" class="w-full mb-3">
                <Dropdown
                  v-model="ChangePassword.tenantSelection.value"
                  class="w-full"
                  :filter="true"
                  :placeholder="ChangePassword.tenantSelection.labelText"
                  :options="ChangePassword.tenantSelection.tenants"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="p-dropdown-car-option">
                      <div class="flex w-full align-items-center">
                        <img height="20" src="/assets/icon/logo.png" />
                        <span class="pl-2">{{ slotProps.value.name }}</span>
                      </div>
                    </div>
                  </template>

                  <template #option="slotProps">
                    <div class="p-dropdown-car-option">
                      <div class="flex w-full align-items-center">
                        <img height="20" src="/assets/icon/logo.png" />
                        <span class="pl-2">{{ slotProps.option.name }}</span>
                      </div>
                    </div>
                  </template>
                </Dropdown>
              </div>

              <div class="flex gap-3">
                <Button
                  type="submit"
                  class="w-full"
                  icon-pos="right"
                  :icon="ChangePassword.submit_button.icon"
                  :label="ChangePassword.submit_button.labelText"
                  :loading="ChangePassword.submit_button.isLoading"
                  :disabled="ChangePassword.submit_button.isDisabled"
                />
              </div>
            </form>
          </template>

          <template #footer>
            <div class="text-right">
              <span class="font-medium no-underline text-blue-500 text-right cursor-pointer" @click="router.push('/account/login')">{{ $t('forgotPassword.home') }}</span>
            </div>
          </template>
        </Card>
        <Card v-if="!formCode" class="border-round card-dimensions">
          <template #header>
            <div class="text-center mb-4">
              <img src="/assets/icon/logo.png" alt="Image" height="90" class="w-auto mt-4" style="margin-bottom: -14px" />
              <div class="text-900 text-3xl font-medium line-height-1">
                {{ $t('forgotPassword.production') }}
              </div>
              <span class="text-600 font-normal line-height-1">{{ $t('forgotPassword.byInfinityHomeServices') }}</span>
            </div>
          </template>

          <template #content>
            <Message v-if="errorMessage" severity="error" :closable="false" icon="pi pi-lock">{{ errorMessage }} </Message>
            <form class="w-full" @submit.prevent="handleSubmit(!v$.$invalid)">
              <div class="p-inputtext p-0 w-full p-input-icon-left w-full mb-3 flex flex-row align-items-center justify-content-center">
                <div class="flex align-items-center justify-content-center w-1 pl-2">
                  <i class="pi pi-user" />
                </div>

                <InputText
                  v-model="resetPassword.username.value"
                  type="text"
                  inputmode="text"
                  class="w-full border-0"
                  :disabled="resetPassword.username.isDisabled"
                  :placeholder="resetPassword.username.labelText"
                  :class="{ 'p-invalid': v$.username.$errors.length > 0 }"
                  autocomplete="text"
                  name="username"
                  autocapitalize="off"
                />
              </div>
              <div v-if="resetPassword.tenantSelection.isActive" class="w-full mb-3">
                <Dropdown
                  v-model="resetPassword.tenantSelection.value"
                  class="w-full"
                  :filter="true"
                  :placeholder="resetPassword.tenantSelection.labelText"
                  :options="resetPassword.tenantSelection.tenants"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="p-dropdown-car-option">
                      <div class="flex w-full align-items-center">
                        <img height="20" src="/assets/icon/logo.png" />
                        <span class="pl-2">{{ slotProps.value.name }}</span>
                      </div>
                    </div>
                  </template>

                  <template #option="slotProps">
                    <div class="p-dropdown-car-option">
                      <div class="flex w-full align-items-center">
                        <img height="20" src="/assets/icon/logo.png" />
                        <span class="pl-2">{{ slotProps.option.name }}</span>
                      </div>
                    </div>
                  </template>
                </Dropdown>
              </div>

              <div class="flex gap-3">
                <Button
                  type="submit"
                  class="w-full"
                  icon-pos="right"
                  :icon="resetPassword.submit_button.icon"
                  :label="resetPassword.submit_button.labelText"
                  :loading="resetPassword.submit_button.isLoading"
                  :disabled="resetPassword.submit_button.isDisabled"
                />
              </div>
            </form>
          </template>

          <template #footer>
            <div class="flex justify-content-between padding">
              <div class="text-right">
                <span class="font-medium no-underline text-color-secondary text-right cursor-pointer">{{ $t('forgotPassword.passwordReminder') }}</span>
              </div>
              <div class="text-right">
                <span class="font-medium no-underline text-blue-500 text-right cursor-pointer" @click="router.push('/account/login')">{{ $t('forgotPassword.login') }}</span>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .card-dimensions {
    min-width: 450px;
    max-width: 450px;
    @media (max-width: 470px) {
      min-width: 90%;
      width: 90%;
    }
  }
  .grid {
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
  }
</style>
