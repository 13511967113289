/* eslint-disable no-useless-catch */
import { newIQueuePhoto } from '@/db/tables'
import api from '@/services/api'
import axios from 'axios'
import { IPhotoResponse } from '@/interfaces/photo/photo.interface'
import { captureException } from '@sentry/vue'
import { base64ToBlob } from './base64Toblob'
import { CreateOrUpdateAnnotation } from '@/components/editor_images/services/Annotation'

type UploadPhotoBlob = Omit<newIQueuePhoto, 'date' | 'retries'>

export const uploadPhotoBlob = async (photo: UploadPhotoBlob) => {
  const type = photo.entityType === 'Lead' ? 'Leads' : 'Projects'

  try {
    const payload = {
      type: photo.photoType,
      id: photo.projectId,
      latitude: photo.latitude,
      longitude: photo.longitude,
      photoTypeId: photo.photoTypeId,
      filename: `${Date.now()}-${photo.id}.jpg`,
      uploadType: photo.uploadPhotoType,
    }
    const response = await api.post(`/api/${type}/SignedPhoto`, payload)
    const responseInfo = response.data.data as IPhotoResponse
    const url = responseInfo?.photoUrl as string
    await axios.put(url, photo.file, { headers: { 'Content-Type': 'application/octet-stream', 'x-ms-blob-type': 'BlockBlob' } })
    await api.post(`/api/${type}/SetActivePhoto/${responseInfo.id}`)
    await UploadEditorPhoto(responseInfo.id, photo)
    return responseInfo
  } catch (error) {
    captureException(error)
    return {} as IPhotoResponse
  }
}

export const uploadPhotosBlob = async (photos: UploadPhotoBlob[]): Promise<IPhotoResponse[]> => {
  const type = photos?.[0]?.entityType === 'Lead' ? 'Leads' : 'Projects'

  try {
    const payload = photos.map((photo) => ({
      ...photo,
      type: photo.photoType,
      id: photo.projectId,
      latitude: photo.latitude,
      longitude: photo.longitude,
      photoTypeId: photo.photoTypeId,
      filename: `${Date.now()}-${photo.id}.jpg`,
      uploadType: photo.uploadPhotoType,
    }))
    const response = await Promise.all(
      payload.map(async (item) => {
        const response = await api.post(`/api/${type}/SignedPhoto`, item)
        return {
          response: response,
          payload: item,
        }
      })
    )
    const responseInfo = response.map((item) => item.response.data.data as IPhotoResponse)
    const urls = responseInfo.map((item) => item.photoUrl)
    await Promise.all(urls.map((url, index) => axios.put(url, photos[index].file, { headers: { 'Content-Type': 'application/octet-stream', 'x-ms-blob-type': 'BlockBlob' } })))
    const ids = responseInfo.map((item) => item.id)
    await api.post(`/api/${type}/SetActivePhotos`, { photoIds: ids })
    await Promise.all(response?.map(async (e) => await UploadEditorPhoto(e.response.data?.data?.id, e?.payload as UploadPhotoBlob)))
    return responseInfo
  } catch (error) {
    captureException(error)
    return [] as IPhotoResponse[]
  }
}
export const UploadEditorPhoto = async (mediaId: string, photo: Omit<UploadPhotoBlob, 'file'>) => {
  if (!mediaId?.length || !photo?.annotation || !photo.base64_annotation) return

  const type = photo.entityType === 'Lead' ? 'Leads' : 'Projects'
  const payload = {
    type: photo.photoType,
    id: photo.id,
    latitude: photo.latitude,
    longitude: photo.longitude,
    photoTypeId: photo.photoTypeId,
    filename: `${Date.now()}-${photo.id}.jpg`,
    uploadType: photo.uploadPhotoType,
    mediaId: mediaId,
    mediaAction: 'UPDATE',
    mediaType: 'IMAGE',
  }

  try {
    const response = await api.post(`/Api/${type}/SignedMedia`, payload)
    const responseInfo = response.data.data as IPhotoResponse
    const url = responseInfo?.photoUrl as string
    const blob = base64ToBlob(`${photo.base64_annotation}`, 'image/jpeg')
    await axios.put(url, blob, { headers: { 'Content-Type': 'application/octet-stream', 'x-ms-blob-type': 'BlockBlob' } })
    await api.post(`/Api/${type}/UpdateMedia/${payload?.mediaId}`)
    if (payload.mediaId && photo?.annotation) {
      await CreateOrUpdateAnnotation({
        photoId: payload.mediaId,
        annotation: photo.annotation ?? '',
      })
        .then(() => console.log('Successfully, Annotation was saved!'))
        .catch(() => alert('Error Save Annotation, Could not save the annotation'))
    }
    return responseInfo
  } catch (error) {
    throw error
  }
}

export const UploadEditoUpdatew = async (mediaId: string, photo: Omit<UploadPhotoBlob, 'file'>) => {
  if (!mediaId?.length || !photo.base64_annotation) return

  const type = photo.entityType === 'Lead' ? 'Leads' : 'Projects'
  const payload = {
    type: photo.photoType,
    id: photo.id,
    latitude: photo.latitude,
    longitude: photo.longitude,
    photoTypeId: photo.photoTypeId,
    filename: `${Date.now()}-${photo.id}.jpg`,
    uploadType: photo.uploadPhotoType,
    mediaId: mediaId,
    mediaAction: 'UPDATE',
    mediaType: 'IMAGE',
  }

  try {
    const response = await api.post(`/Api/${type}/SignedMedia`, payload)
    const responseInfo = response.data.data as IPhotoResponse
    const url = responseInfo?.photoUrl as string
    const blob = base64ToBlob(`${photo.base64_annotation}`, 'image/jpeg')
    await axios.put(url, blob, { headers: { 'Content-Type': 'application/octet-stream', 'x-ms-blob-type': 'BlockBlob' } })
    await api.post(`/Api/${type}/UpdateMedia/${payload?.mediaId}`)
    return responseInfo
  } catch (error) {
    throw error
  }
}
