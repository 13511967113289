<script lang="ts" setup>
  import {
    // onMounted,
    ref,
    toRefs,
    // watch
  } from 'vue'
  import Button from 'primevue/button'
  import TriStateCheckbox from 'primevue/tristatecheckbox'
  import Menu from 'primevue/menu'
  // import { IPhoto } from '@/interfaces/photo/photo.interface'
  import api from '@/services/api'
  import { ITask } from '@/interfaces/punch-list/task/task.interface'
  import { ETaskStatus } from '@/enums/punch-list/task/task.enum'
  // import { useRenderPhotoContent } from '@/composables/useRenderPhotoContent'
  import PreviewImage from './PreviewImage.vue'
  // import Lightgallery from '@/components/LightgalleryComponent.vue'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import { captureException } from '@sentry/vue'
  // import { useToast } from 'primevue/usetoast'
  import {
    useProjectStore,

    // usePunchListCameraStore
  } from '@/store'
  import { storeToRefs } from 'pinia'
  import { i18n } from '@/i18n'
  import GalleryComponent from '@/components/GalleryComponent.vue'
  import { PhotoVariant } from '@/interfaces/photo/photo.interface'
  const { t } = i18n.global

  interface IProps {
    item: ITask
    checkState: boolean | null
    type: 'LIST' | 'COMPLETION'
    isFinalized?: boolean
    photoType?: PhotoTypeEnum
  }

  // const checkStatusValues = {
  //   COMPLETED: true,
  //   NOT_COMPLETED: false,
  //   NA: null,
  // }

  const props = defineProps<IProps>()

  const emit = defineEmits(['notApplicable', 'checkStateChange', 'selectedItem', 'takePicture', 'selectPhoto', 'deletetask'])
  const { item, type, isFinalized, checkState, photoType } = toRefs(props)
  // const punchListCameraStore = usePunchListCameraStore()
  // const { photoType: punchListType } = storeToRefs(punchListCameraStore)
  const menu = ref()
  const projectStore = useProjectStore()
  const commandNotAplicable = () => (isFinalized.value ? emit('notApplicable', item.value.id) : markNA())
  const { project } = storeToRefs(projectStore)
  async function markNA() {
    try {
      const payload = {
        status: ETaskStatus.NA,
      }

      await api.patch(`/Api/Projects/PunchLists/TaskLists/Tasks/${item.value.id}/UpdateStatus`, payload)

      item.value.status = payload.status
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }
  const handleErrorSentry = (error: unknown) => {
    captureException(error)
  }
  function setSelected() {
    if (type.value === 'COMPLETION') {
      emit('selectedItem', item.value)
    }
  }

  async function updateCheckboxStatus() {
    emit('checkStateChange')
  }

  const items = [
    {
      label: t('finalizePunchLists.notApplicable'),
      icon: 'pi pi-times-circle',
      command: commandNotAplicable,
    },
    {
      label: t('projectPunchList.deleteTask'),
      icon: 'pi pi-times-circle',
      command: () => {
        emit('deletetask', item.value)
      },
    },
  ]

  const checkClick = (task: ITask) => {
    if (task.status === ETaskStatus.NA || task.status === ETaskStatus.COMPLETED) {
      updateCheckboxStatus()
    }
  }

  const toggleMenu = (event: MouseEvent) => {
    if (menu.value && menu.value.toggle) {
      menu.value.toggle(event)
    } else if (menu.value && menu.value[0] && menu.value[0].toggle) {
      menu.value[0].toggle(event)
    }
    setSelected()
    event.preventDefault()
    event.stopPropagation()
  }

  type TEditImageTaskListItem = {
    image: {
      base64: string
      base64Content: string
      id: string
    }
  }
  const updatePhotoTaskList = async (id: string, base64Content: string) => {
    try {
      await api.patch(`/Api/Project/${project.value.id}/Photo/${id}`, { base64Content })
    } catch (error) {
      handleErrorSentry(error)
    }
  }
  const handleRemoveImageTaskListItm = (id: string) => {
    item.value.photos = item.value.photos?.filter((photo) => photo.id !== id)
  }

  const handleUpdateEditImageTaskListItem = (id: string, url: string) => {
    if (item.value.photos?.length) {
      item.value.photos = item.value.photos.map((photo) => {
        if (photo.id === id) {
          const newVariants = photo.variants.map((variant) => {
            if (variant.variant === 'THUMBNAIL') return { ...variant, uri: url }
            return variant
          })
          return {
            ...photo,
            url,
            variants: newVariants,
          }
        }
        return photo
      })
    }
  }

  const handleDeleteImageTaskListItem = async (id: string) => {
    try {
      handleRemoveImageTaskListItm(id)
      await api.delete(`/Api/Project/Photo/${id}`)
    } catch (error) {
      handleErrorSentry(error)
      // console.log(error)
    }
  }

  const handleEditImageTaskListItem = async ({ image: { base64, id, base64Content } }: TEditImageTaskListItem) => {
    handleUpdateEditImageTaskListItem(id, base64)
    await updatePhotoTaskList(id, base64Content)
  }
</script>

<template>
  <li class="flex align-items-center">
    <div
      style="min-height: 58px"
      class="flex py-2 gap-2 align-items-center w-full list-task-item"
      :class="{
        'item-not-applicable': checkState === false,
        'item-completed': checkState === true,
        'type-completion': type === 'COMPLETION',
      }"
      @click="setSelected"
    >
      <TriStateCheckbox
        v-model="checkState"
        :binary="true"
        :style="{
          cursor:
            checkState === null && (!item.photos || item.photos?.filter((p) => p.type === (photoType === PhotoTypeEnum.TASK_AFTER ? 'TASK_AFTER' : 'FINAL_PICTURES')).length === 0)
              ? 'not-allowed'
              : 'pointer',
        }"
        :class="{
          'p-checkbox-unchecked-custom': checkState === false,
          'p-checkbox-checked-custom': checkState === true,
        }"
        :disabled="item.status === ETaskStatus.NOT_COMPLETED"
        @click.stop="checkClick(item)"
      />
      <p class="w-full vertical-align-middle m-0" style="max-width: {{ item.photos && item.photos.length > 0 ? '70%' : '100%' }}">{{ item.title }}</p>
      <div v-if="item.photos && item.photos.length > 0" class="flex-grow-1 flex justify-content-end align-items-center gap-2 w-full" style="width: 32px">
        <div class="flex-grow-1 flex justify-content-end align-items-center w-full" style="height: 44px">
          <GalleryComponent
            :images="item.photos"
            :class-custom="{
              container: 'container-photos justify-content-end align-items-center h-full gap-1 flex',
              containerPhotos: 'w-full justify-content-end align-items-center h-full',
            }"
            show-detail
            show-comments
            show-edit
            show-delete
            class-container=""
            :limit="2"
            entity-type="Projects"
            @edit-image="handleEditImageTaskListItem"
            @delete-image="handleDeleteImageTaskListItem"
          >
            <template #item="slotProps">
              <div v-if="slotProps.item.url && slotProps.index < 2" style="height: 30px; width: 30px">
                <PreviewImage :url="slotProps.item?.variants?.find((variant: PhotoVariant) => variant.variant === 'THUMBNAIL')?.uri ?? slotProps.item.url" />
              </div>
            </template>
          </GalleryComponent>
        </div>
      </div>
      <Button v-if="item.status === ETaskStatus.NOT_COMPLETED" style="height: 50px" class="ml-2" text icon="pi pi-ellipsis-v" @click="toggleMenu" />
      <Menu ref="menu" :model="[...items]" :popup="true" />
    </div>
  </li>
</template>

<style lang="scss" scoped>
  .container-photos {
    align-items: center;
    height: 100%;
    margin: 0;
  }

  .list-task-item {
    border: 1px solid #e5e7eb;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    position: relative;
    background-color: #ffffff;

    &.type-completion {
      cursor: pointer;
    }

    &.item-active:not(.type-list):after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 7px;
      background-color: transparent;
      pointer-events: none;
      border: 3px solid #3b82f6;
    }
  }
</style>
<style lang="scss">
  .p-checkbox-unchecked-custom {
    > .p-checkbox-box {
      background: rgba(119, 119, 119, 1);
      border-color: rgba(119, 119, 119, 1);
    }
    &:hover > .p-checkbox-box {
      background: rgba(119, 119, 119, 0.5);
      border-color: rgba(119, 119, 119, 0.5);
    }
  }
  .p-checkbox-checked-custom {
    > .p-checkbox-box {
      background-color: rgba(59, 130, 246, 1) !important;
      border-color: rgba(59, 130, 246, 1) !important;
    }

    &:hover > .p-checkbox-box {
      background: rgba(59, 130, 246, 0.8) !important;
      border-color: rgba(59, 130, 246, 0.8) !important;
    }
  }
</style>
