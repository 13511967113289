export const globalSearchEnUS = {
  search: 'Search',
  allLabel: 'All',
  projectLabel: 'Projects',
  leadLabel: 'Leads',
  empty: 'No results found',
  startSearch: 'Search a project or lead',
  searchOnlyMyProjects: 'Search only my projects',
}

export const globalSearchEsMX = {
  search: 'Buscar',
  allLabel: 'Todos',
  projectLabel: 'Proyectos',
  leadLabel: 'Leads',
  empty: 'No se encontraron resultados',
  startSearch: 'Busca un proyecto o prospecto',
  searchOnlyMyProjects: 'Buscar solamente mis proyectos',
}
