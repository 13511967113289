<script setup lang="ts">
  import { toRefs } from 'vue'
  import Card from 'primevue/card'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import PreviewImage from './PunchListCamera/SideBar/ListTaskComponent/PreviewImage.vue'
  import Tag from 'primevue/tag'
  import { IProject } from '@/interfaces/project/project.interface'
  import { projectStages, removeSlash } from '@/options/project/project-stage.options'
  import { i18n } from '@/i18n'
  import { useEventBus } from '@vueuse/core'
  import { ICamera } from './PunchListCamera/Camera/CameraContainer.vue'
  import { delay } from '@/utils/delay'
  import { IQueuePhoto, QueueStatusType, addNewIQueuePhotosCamera, newIQueuePhoto } from '@/db/tables'
  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  import GalleryComponent from './GalleryComponent.vue'
  import { computed } from 'vue'
  import moment from 'moment'
  import { base64ToBlob } from '@/utils/base64Toblob'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'

  const { t } = i18n.global

  type Iprops = {
    project: IProject
    lead?: boolean
  }

  const uploadPhotoService = uploadNewQueuePhoto()
  const props = defineProps<Iprops>()
  const { project, lead } = toRefs(props)
  const camera = useEventBus<ICamera>('camera')
  const closeCamera = async (photos: IQueuePhoto[]) => {
    const uploadPhotoType = await devicePhotoTag('CAMERA')

    const newPhotos = photos.map((photo) => {
      const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
      const photoData: newIQueuePhoto = {
        id: photo.id,
        poNumberProject: project.value?.poNumber ?? '',
        projectId: photo.projectId,
        latitude: photo.latitude,
        longitude: photo.longitude,
        entityType: lead.value ? 'Lead' : 'Project',
        photoType: photo.photoType,
        photoTypeId: photo.photoTypeId,
        file: blob,
        status: 'IDLE' as QueueStatusType,
        date: new Date(),
        errorMessage: '',
        retries: 0,
        uploadPhotoType: uploadPhotoType,
      }
      return photoData
    })

    await addNewIQueuePhotosCamera(newPhotos)
    await uploadPhotoService.uploadQueuePhotos()
    await delay(1000)
  }

  async function takePictures() {
    camera.emit({
      propsCamera: {
        projectId: project.value.id,
        photoType: PhotoTypeEnum.PROGRESS_PICTURES,
      },
      actions: {
        closeCamera: closeCamera,
      },
    })
  }

  function renderTimeStamp() {
    if (project.value.actualStartDate) {
      const date = useDateFormatUtil(project.value.actualStartDate, 'MMM D, YYYY')
      return date
    } else {
      return '--'
    }
  }

  const today = moment().format('yyyy-MM-DD')
  const tomorrow = moment().add(1, 'days').format('yyyy-MM-DD')

  const getDay = computed(() => (dateISO: string | undefined) => {
    if (!dateISO) return ''
    // check if dateISO contains T
    if (!dateISO?.includes?.('T')) return dateISO
    const datePart = `${dateISO?.split?.('T')?.[0]}` || ''
    const timeFormat = moment(dateISO).format('hh:mm A')

    if (today === datePart) {
      return 'Today ' + timeFormat
    }
    if (datePart === tomorrow) {
      return 'Tomorrow ' + timeFormat
    }

    return datePart + ' ' + timeFormat
  })
</script>

<template>
  <Card
    :pt="{
      root: {
        style: {
          height: '100%',
        },
      },
      body: {
        style: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    }"
  >
    <template #title>
      <router-link :to="lead ? `/leads/${project.id}/media` : `/projects/${project.id}/media`">
        <h2 class="text-color font-bold text-xl mb-3 w-15 white-space-nowrap overflow-hidden text-overflow-ellipsis">
          {{ !lead ? project.name : getDay(project.meeting) }}
        </h2>
      </router-link>
    </template>

    <template #content>
      <div class="w-full flex flex-column mb-2">
        <div v-if="lead" class="mb-2 font-bold overflow-hidden white-space-nowrap" style="text-overflow: ellipsis">
          {{ project.name }}
        </div>
        <div class="mb-2 text-600 overflow-hidden white-space-nowrap" style="text-overflow: ellipsis">
          <i v-if="project.address" class="pi pi-directions"></i>
          {{ project.address }}
        </div>
        <p class="mb-2 text-600">
          <i class="pi pi-hashtag"></i>
          {{ project?.poNumber ?? t('projectCardComponent.noPoNumber') }}
        </p>
        <div v-if="project.actualStartDate || project.actualStartDate === null" class="mb-2 text-600 overflow-hidden white-space-nowrap" style="text-overflow: ellipsis">
          <i class="pi pi-calendar"></i>
          {{ project.actualStartDate !== null ? renderTimeStamp() : t('projectCardComponent.noActualStartDate') }}
        </div>
        <div class="mb-2 text-600 overflow-hidden white-space-nowrap" style="text-overflow: ellipsis">
          <i v-if="project.projectType" class="pi pi-home"></i>
          {{ project.projectType }}
        </div>
        <div v-if="project.projectManager?.fullName" class="mb-2 text-600 overflow-hidden white-space-nowrap" style="text-overflow: ellipsis">
          <i v-if="project.projectManager?.fullName" class="pi pi-user"></i>
          PM: {{ project.projectManager?.fullName }}
        </div>
        <div v-if="!lead" class="mb-2 text-600 overflow-hidden white-space-nowrap align-items-center flex flex-row flex-wrap gap-2">
          <Tag v-if="project.hasOpenPunchList" severity="warning" :value="t('projectCardComponent.openPuncList')" icon="pi pi-list"></Tag>
          <Tag v-if="project.hasOpenProblem" severity="danger" :value="t('projectCardComponent.openProblems')" icon="pi pi-info-circle"></Tag>
          <Tag v-if="!project.hasOpenProblem && !project.hasOpenPunchList" severity="info" :value="t('projectCardComponent.nothingPending')" icon="pi pi-check-circle"></Tag>
          <template v-for="(crew, index) in project?.crews ?? []">
            <Tag
              v-if="crew.crewCompany"
              :key="crew.crewCompany + index"
              severity="info"
              :value="`${crew.crewCompany} ${crew.numberCrewUsers > 0 ? `(+${crew.numberCrewUsers})` : ''} `"
              icon="pi pi-users"
            ></Tag>
          </template>
        </div>
      </div>
    </template>
    <template v-if="!lead" #footer>
      <div
        v-if="!lead && project?.photos.length > 0"
        class="surface-900"
        style="
          height: 100px;
          width: calc(100% + 2.5rem);
          margin-left: -1.25rem;
          margin-right: -1.25rem;
          padding-left: 1.25rem;
          padding-right: 1.25rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        "
      >
        <GalleryComponent
          :class-custom="{
            container: ' w-full align-items-center h-full gap-4 grid col-5 ImageColumns',
            containerPhotos: 'w-full justify-content-end align-items-center h-full ',
          }"
          :limit="4"
          :images="project.photos.slice(0, 4)"
          class-container="wi"
          show-detail
          show-comments
          entity-type="Projects"
        >
          <template #item="slotProps">
            <div v-if="slotProps.item.url" style="height: 70px">
              <PreviewImage :url="slotProps.item.url" />
            </div>
          </template>
        </GalleryComponent>
      </div>

      <div
        v-else-if="!lead"
        class="surface-900"
        style="
          height: 100px;
          width: calc(100% + 2.5rem);
          margin-left: -1.25rem;
          margin-right: -1.25rem;
          padding-left: 1.25rem;
          padding-right: 1.25rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        "
      >
        <div class="w-full flex flex-column justify-content-evenly align-items-center h-full">
          <!-- <span class="text-sm text-white">
            {{ t('projectCardComponent.noPhotos') }}
          </span> -->

          <!-- <Button class="p-button-rounded" :label="t('projectCardComponent.takePicture')" icon="pi pi-camera" @click="takePictures"></Button> -->
        </div>
      </div>
      <div
        class="flex align-items-center justify-content-between"
        style="
          border-top: 1px solid rgba(0, 0, 0, 0.15);
          width: calc(100% + 2.5rem);
          margin-left: -1.25rem;
          margin-right: -1.25rem;
          padding-left: 1.25rem;
          padding-right: 1.25rem;
          padding-top: 0.75rem;
          margin-bottom: -0.5rem;
        "
      >
        <div class="text-left">
          <template v-if="project?.distance?.value !== '0' && project?.distance?.value !== ''">
            <div v-if="project.distance && project.distance.value && project.distance.value.length > 0">
              <span class="text-2xl">{{ parseInt(project.distance.value) }} </span>
              <span class="text-600 text-base pl-1">{{ t('projectCardComponent.miles') }} {{ t('projectCardComponent.away') }}</span>
            </div>

            <div v-else>
              <span class="text-2xl">{{ t('projectCardComponent.noLocation') }}</span>
            </div>
          </template>
        </div>
        <div class="text-right flex-grow-1">
          <Chip
            class="text-xs font-bold"
            :label="
              projectStages.find((p) => p.value === removeSlash(project?.projectStage)) !== undefined
                ? t(projectStages.find((p) => p.value === removeSlash(project?.projectStage))?.label ?? '')
                : project.projectStage
            "
            :style="`backgroundColor: ${projectStages.find((p) => p.value === removeSlash(project.projectStage))?.color}`"
          />
        </div>
      </div>
    </template>
  </Card>
</template>
<style>
  .ImageColumns {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important ;
    gap: 18px !important;
  }
</style>
