<script lang="ts" setup>
  import { computed, toRefs } from 'vue'
  import { useStage } from './store/stage'
  import { useShapes } from './store/shapes'
  import { templateShape } from './templates/shape'
  import { coordinatesShape } from './mocks/coodinatesRotate'
  import { useColor } from './store/color'
  import { useDraw } from './store/draw'
  import { useDialogs } from './store/dialogs'
  import DialogSticker from './dialogs/DialogSticker.vue'
  import DialogColors from './dialogs/DialogColors.vue'
  import DialogText from './dialogs/DialogText.vue'
  import { calculateBase } from './utils/calculateBase'
  import { calculateFontSize } from './utils/calculateFontSize'

  const stageStore = useStage()
  const shapesStore = useShapes()
  const { SET_ROTATE_IMAGE } = stageStore
  const colorStore = useColor()
  const { handleShowDialog } = useDialogs()
  const { image } = toRefs(stageStore)
  const { color } = toRefs(colorStore)

  const drawStore = useDraw()
  const { counter, history } = toRefs(shapesStore)
  const { isPaint } = toRefs(drawStore)
  const { handleCreateShape, handleGoBack, handleGoForward, handleClearStage, handleSelectShapeId } = shapesStore
  const { isPaintOn, stopPaintOff } = drawStore

  const scaledSize = computed(() => {
    return calculateBase(image.value.original_width, image.value.original_height)
  })
  const baseFontSize = computed(() => {
    return calculateFontSize(image.value.original_width, image.value.original_height)
  })

  const handleBox = () => {
    stopPaintOff()
    const range = scaledSize.value
    const shape = templateShape({
      shapeKey: 'BOX',
      color: color.value,
      screenWidth: image.value.width,
      screenHeight: image.value.height,
      points: [0, 0, range, 0, range, range, 0, range, 0, 0],
      shadowPoints: [0, 0, range, 0, range, range, 0, range, 0, 0],
      image_scale: image.value.scale,
      image_rotation: image.value.rotation,
      range,
    })
    handleCreateShape(shape)
  }
  const handleArrow = () => {
    stopPaintOff()
    const range = scaledSize.value
    const coordinates = {
      '0': {
        points: [0, range, range, 0],
        shadowPoints: [0, range, range, 0],
      },
      '90': {
        points: [range, range, 0, 0],
        shadowPoints: [range, range, 0, 0],
      },
      '180': {
        points: [range, 0, 0, range],
        shadowPoints: [range, 0, 0, range],
      },
      '270': {
        points: [0, 0, range, range],
        shadowPoints: [0, 0, range, range],
      },
    }
    const arrowposition = coordinates[`${image.value.rotation}` as keyof typeof coordinates]
    const shape = templateShape({
      shapeKey: 'ARROW',
      color: color.value,
      screenWidth: image.value.width,
      screenHeight: image.value.height,
      points: arrowposition.points,
      shadowPoints: arrowposition.shadowPoints,
      image_scale: image.value.scale,
      image_rotation: image.value.rotation,
      range,
    })
    handleCreateShape(shape)
  }
  const handleCircle = () => {
    stopPaintOff()
    const range = scaledSize.value / 2
    const shape = templateShape({
      shapeKey: 'CIRCLE',
      color: color.value,
      screenWidth: image.value.width,
      screenHeight: image.value.height,
      radius: range,
      points: [0, 0, range, 0],
      shadowPoints: [0, 0, range, 0],
      image_scale: image.value.scale,
      image_rotation: image.value.rotation,
      range,
    })
    handleCreateShape(shape)
  }

  const handleDate = () => {
    stopPaintOff()
    const shape = templateShape({
      shapeKey: 'TEXT',
      color: color.value,
      screenWidth: image.value.width,
      screenHeight: image.value.height,
      text: new Date().toDateString(),
      points: [0, 0],
      scale: 2,
      centerTextFirstTime: false,
      cm: '0',
      fontSize: baseFontSize.value,
      ft: '0',
      height: 20,
      in: '0',
      m: '0',
      pathSticker: 'check',
      radius: 80,
      rotation: {
        angle: 90,
        points: [0, 0, 80, 0],
        rotation: coordinatesShape[`${image.value.rotation}` as keyof typeof coordinatesShape]?.rotate,
        x: 205.71428571428572,
        y: 290.9627329192547,
      },
      shadowPoints: [],
      text_unit: 'ABC',
      thickness: 6,
      width: 20,
      image_scale: image.value.scale,
      image_rotation: image.value.rotation,
      range: 0,
    })
    handleCreateShape(shape)
  }
  const handleDialogStickerOpen = () => {
    stopPaintOff()
    handleShowDialog('showDialogSticker', true)
  }

  const handleDialogColorOpen = () => {
    handleShowDialog('showDialogColors', true)
  }
  const handleDialogTextOpen = () => {
    stopPaintOff()
    handleShowDialog('showDialogText', true)
  }
</script>

<template>
  <DialogColors />
  <DialogSticker />
  <DialogText />

  <nav class="editor_bar_tools">
    <section class="w-full">
      <button
        class="w-full h-3rem border-round border-3 cursor-pointer border-black-alpha-20"
        :style="{
          background: color,
          borderWidth: 0,
          borderRadius: 12,
        }"
        @click="handleDialogColorOpen"
      ></button>
    </section>
    <section id="content" class="flex flex-column w-full gap-2">
      <button
        class="editor_bar_button"
        :style="{
          backgroundColor: isPaint ? 'red' : '#f3f3f3',
        }"
        @click="
          () => {
            isPaintOn()
            handleSelectShapeId(null)
          }
        "
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path
            d="M497.939 74.158L437.847 14.062C419.097 -4.688 388.662 -4.688 369.914 14.062L313.384 70.611L441.378 198.633L497.939 142.084C516.687 123.316 516.687 92.91 497.939 74.158ZM31.037 352.955C28.802 355.189 27.281 358.033 26.66 361.131L0.32 492.854C-1.704 502.967 6.156 512 15.945 512C16.994 512 18.062 511.896 19.144 511.68L150.855 485.336C153.953 484.717 156.796 483.195 159.031 480.963L418.75 221.258L290.75 93.232L31.037 352.955ZM131.945 440.168L56.8 455.197L71.828 380.047L96 355.875V416H156.115L131.945 440.168Z"
            :fill="isPaint ? 'white' : 'black'"
          />
        </svg>
      </button>
      <button class="editor_bar_button" @click="handleArrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path
            d="M413 251.562C405.14 259.812 394.578 264 384 264C374.093 264 364.187 260.344 356.453 253L264 165.18V440C264 462.094 246.093 480 224 480S184 462.094 184 440V165.18L91.547 253C75.562 268.188 50.234 267.531 35 251.562C19.781 235.531 20.437 210.219 36.453 195L196.453 43C211.89 28.312 236.109 28.312 251.547 43L411.547 195C427.562 210.219 428.218 235.531 413 251.562Z"
          />
        </svg>
      </button>
      <button id="box" class="editor_bar_button" @click="handleBox">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path d="M448 96V416C448 451.346 419.346 480 384 480H64C28.654 480 0 451.346 0 416V96C0 60.654 28.654 32 64 32H384C419.346 32 448 60.654 448 96Z" />
        </svg>
      </button>
      <button class="editor_bar_button" @click="handleCircle">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path d="M496 256C496 388.548 388.548 496 256 496S16 388.548 16 256S123.452 16 256 16S496 123.452 496 256Z" />
        </svg>
      </button>

      <button class="editor_bar_button" @click="handleDialogTextOpen">
        <svg width="55" height="50" viewBox="0 0 55 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26.7361 0H3.81945C1.70978 0 0 1.99844 0 4.46429V9.82143C0 12.2873 1.70978 14.2857 3.81945 14.2857C5.92912 14.2857 7.6389 12.2873 7.6389 9.82143V8.92857H11.5197C11.511 9.05212 11.4583 9.15815 11.4583 9.28437V42.8571H9.16668C7.47924 42.8571 6.11112 44.4545 6.11112 46.4286C6.11112 48.4027 7.47924 50 9.16668 50H21.3889C23.0763 50 24.4445 48.4027 24.4445 46.4286C24.4445 44.4545 23.0763 42.8571 21.3889 42.8571H19.0972V9.28437C19.0972 9.15815 19.0446 9.05212 19.0358 8.92857H22.9167V9.82143C22.9167 12.2873 24.6265 14.2857 26.7361 14.2857C28.8458 14.2857 30.5556 12.2873 30.5556 9.82143V4.46429C30.5556 1.99844 28.8458 0 26.7361 0ZM53.4719 37.5035H48.8888V12.4965H53.4719C54.8326 12.4965 55.5129 10.5706 54.5461 9.45435L46.9076 0.523214C46.3108 -0.17433 45.3561 -0.17433 44.7594 0.523214L37.1206 9.45435C36.2255 10.4869 36.7029 12.4965 38.1949 12.4965H42.778V37.5035H38.1949C36.8342 37.5035 36.1538 39.4291 37.1206 40.5456L44.7594 49.4767C45.3561 50.1743 46.3108 50.1743 46.9076 49.4767L54.5461 40.5456C55.4413 39.5128 54.9639 37.5035 53.4719 37.5035Z"
            fill="black"
          />
        </svg>
      </button>

      <button class="editor_bar_button" @click="handleDate">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path
            d="M256 16C123.42 16 16 123.418 16 256C16 388.578 123.42 496 256 496S496 388.578 496 256C496 123.418 388.58 16 256 16ZM339.217 334.406C334.498 340.688 327.295 344 319.998 344C314.982 344 309.936 342.438 305.607 339.188L241.607 291.188C235.576 286.656 232.014 279.562 232.014 272V152C232.014 138.75 242.764 128 256.014 128S280.014 138.75 280.014 152V260L334.42 300.812C345.014 308.75 347.17 323.781 339.217 334.406Z"
          />
        </svg>
      </button>
      <button class="editor_bar_button" @click="handleDialogStickerOpen">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path
            d="M260.625 319.854H212.625L205.5 305.604C201.5 294.852 191.25 287.852 179.875 287.852H108.125C96.75 287.852 86.5 294.852 82.5 305.604L75.5 319.854H27.5C12.25 319.854 0 332.106 0 347.231V484.492C0 499.744 12.25 511.994 27.375 511.994H260.625C275.75 511.994 288 499.744 288 484.492V347.231C288 332.106 275.75 319.854 260.625 319.854ZM144 467.99C115.25 467.99 92 444.738 92 415.986S115.25 363.983 144 363.983S196 387.234 196 415.986S172.75 467.99 144 467.99ZM116.625 219.346C119.625 222.471 123.75 224.096 128 224.096S136.375 222.471 139.25 219.346L236 119.463C264.25 90.461 262.5 42.332 231.125 15.58C203.75 -7.797 163 -3.547 137.875 22.455L128 32.58L118.125 22.455C93 -3.547 52.25 -7.797 24.875 15.58C-6.5 42.332 -8.125 90.461 19.875 119.463L116.625 219.346ZM478.125 0.328L329.5 23.08C314.875 25.33 304 38.832 304 54.832V161.592C298.75 160.467 293.375 159.967 288 159.842C252.625 159.842 224 181.344 224 207.846S252.625 255.85 288 255.85C323.25 255.85 351.75 234.598 352 208.221V99.586L464 82.459V129.588C458.75 128.463 453.375 127.963 448 127.838C412.625 127.838 384 149.34 384 175.842C384 202.469 412.625 223.846 448 223.846C483.25 223.846 511.75 202.594 512 176.217V31.955C512 12.455 496 -2.422 478.125 0.328ZM496 368H440.275L478.719 278.297C481.656 271.438 479.438 263.453 473.406 259.063C467.313 254.641 459.063 255.031 453.469 259.953L325.469 371.953C320.438 376.344 318.688 383.391 321.031 389.625C323.375 395.875 329.344 400 336 400H391.725L353.281 489.703C350.344 496.563 352.562 504.547 358.594 508.938C361.406 510.984 364.719 512 368 512C371.781 512 375.531 510.672 378.531 508.047L506.531 396.047C511.562 391.656 513.312 384.609 510.969 378.375C508.625 372.125 502.656 368 496 368Z"
          />
        </svg>
      </button>
      <!-- <button class="editor_bar_button">
        <svg viewBox="0 0 24 24" role="presentation">
          <path d="M3,17V19H9V17H3M3,5V7H13V5H3M13,21V19H21V17H13V15H11V21H13M7,9V11H3V13H7V15H9V9H7M21,13V11H11V13H21M15,9H17V7H21V5H17V3H15V9Z"></path>
        </svg>
      </button> -->
      <button
        class="editor_bar_button relative flex flex-column"
        @click="
          () => {
            SET_ROTATE_IMAGE()
          }
        "
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path
            d="M430.299 288H430.299C410.78 288 394.167 300.24 385.859 317.903C378.977 332.535 369.598 346.034 357.806 357.812C330.618 385.031 294.46 400 255.989 400C222.818 400 191.742 388.285 166.276 367.77L200.028 333.998C216.863 317.152 205.176 288.576 181.601 288H34.661C24.323 288.289 16 296.662 16 307.07V452.965C16 477.009 45.074 489.046 62.07 472.039L97.883 436.205C141.668 474.738 197.147 496 255.989 496C320.086 496 380.37 471.031 425.684 425.688C445.859 405.527 461.926 382.387 473.561 357.267C488.501 325.014 465.845 288 430.299 288ZM449.93 39.961L414.117 75.795C370.332 37.262 314.853 16 256.011 16C191.914 16 131.63 40.969 86.316 86.312C66.141 106.472 50.073 129.613 38.438 154.732C23.499 186.986 46.155 224 81.7 224H81.7C101.22 224 117.833 211.76 126.141 194.096C133.023 179.463 142.402 165.965 154.194 154.188C181.382 126.969 217.54 112 256.011 112C289.182 112 320.258 123.715 345.724 144.23L311.972 178.002C295.137 194.848 306.824 223.424 330.399 224H477.339C487.677 223.711 496 215.338 496 204.93V59.036C496 34.992 466.926 22.954 449.93 39.961Z"
          />
        </svg>

        <p class="absolute bottom-0 right-0 bg-white font-bold">
          {{ image?.rotation }}
        </p>
      </button>
      <button class="editor_bar_button" @click="handleGoBack">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path
            d="M62.07 39.961L97.883 75.795C141.668 37.262 197.147 16 255.989 16C320.086 16 380.37 40.969 425.684 86.312C471.03 131.625 496 191.906 496 256S471.03 380.375 425.684 425.688C380.37 471.031 320.086 496 255.989 496C211.848 496 169.517 484.158 132.637 461.977C106.328 446.153 102.872 409.111 124.581 387.402L124.581 387.402C139.725 372.259 163.415 368.379 181.745 379.453C203.924 392.851 229.404 400 255.989 400C294.46 400 330.618 385.031 357.806 357.812C385.026 330.625 399.996 294.469 399.996 256S385.026 181.375 357.806 154.188C330.618 126.969 294.46 112 255.989 112C222.818 112 191.742 123.715 166.276 144.23L200.026 178C217.034 195.018 204.981 224.104 180.921 224.104H35.175C24.585 224.104 16 215.519 16 204.929V59.036C16 34.992 45.074 22.954 62.07 39.961Z"
            :fill="counter === 0 ? 'gray' : 'black'"
          ></path>
        </svg>
      </button>
      <button class="editor_bar_button" @click="handleGoForward">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" style="transform: scaleX(-1)">
          <path
            d="M62.07 39.961L97.883 75.795C141.668 37.262 197.147 16 255.989 16C320.086 16 380.37 40.969 425.684 86.312C471.03 131.625 496 191.906 496 256S471.03 380.375 425.684 425.688C380.37 471.031 320.086 496 255.989 496C211.848 496 169.517 484.158 132.637 461.977C106.328 446.153 102.872 409.111 124.581 387.402L124.581 387.402C139.725 372.259 163.415 368.379 181.745 379.453C203.924 392.851 229.404 400 255.989 400C294.46 400 330.618 385.031 357.806 357.812C385.026 330.625 399.996 294.469 399.996 256S385.026 181.375 357.806 154.188C330.618 126.969 294.46 112 255.989 112C222.818 112 191.742 123.715 166.276 144.23L200.026 178C217.034 195.018 204.981 224.104 180.921 224.104H35.175C24.585 224.104 16 215.519 16 204.929V59.036C16 34.992 45.074 22.954 62.07 39.961Z"
            :fill="counter === history?.length - 1 ? 'gray' : 'black'"
          ></path>
        </svg>
      </button>

      <button class="editor_bar_button" @click="handleClearStage">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
          <path
            d="M93.125 257.721C71.25 275.094 53 313.466 38.625 355.088L99 333.09C104.75 330.965 109.625 337.84 105.625 342.589L11 454.706C3.75 486.953 0 510.201 0 510.201S206.625 523.825 266.625 476.079C326.625 428.208 343.25 325.965 343.25 325.965L256.5 216.724C256.5 216.724 153.125 209.974 93.125 257.721ZM633.244 12.339C622.4 -1.566 602.338 -4.113 588.338 6.714L362.619 182.293L327.625 138.23C322.5 131.855 312.5 133.105 309 140.48L283.75 194.975L370.5 304.217L429.25 291.718C437.25 290.093 440.625 280.594 435.625 274.219L402.436 232.43L627.65 57.242C641.588 46.383 644.119 26.275 633.244 12.339Z"
            :fill="history?.[history?.length - 1]?.type === 'CLEAR_ALL' ? 'gray' : 'black'"
          />
        </svg>
      </button>
    </section>
  </nav>
</template>
<style scoped>
  .editor_bar_tools {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px;
    z-index: 1001;
    background: white;
    width: 100%;
    height: 100%;
  }
  .editor_bar_button {
    border-width: 0px;
    height: 40px;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
  }
  .editor_bar_button:hover {
    background-color: #f3f3f3;
  }
</style>
