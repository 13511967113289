<script lang="ts" setup>
  import { ref, toRefs } from 'vue'
  import { TShapeProps } from '../store/shapes'
  import { KonvaEventObject } from 'konva/lib/Node'
  import ActionCircles from './ActionCircles.vue'
  import { handlePointDragMove } from '../utils/pointPosition'
  import Konva from 'konva'
  import ActionOptions from './ActionOptions.vue'
  const props = defineProps<TShapeProps>()
  const shapeRef = ref<Konva.LineConfig>()
  const { shape, image, device, draggable } = toRefs(props)
  const emits = defineEmits(['ondragstart', 'ondragmoving', 'ondragstop', 'onselectshape', 'ondelete', 'oncopy'])
  const handleSelect = () => {
    emits('onselectshape', shape.value)
  }
  const handleDragStart = () => {
    emits('ondragstart', shape.value)
  }
  const handleDragMove = (e: KonvaEventObject<Event>) => {
    shape.value.x = e?.target?.x() / image.value?.scale
    shape.value.y = e?.target?.y() / image.value?.scale
    emits('ondragmoving', shape.value)
  }
  const handleDragStop = () => {
    emits('ondragstop', shape.value)
  }

  const handleDragMovePoint = (circlePosition: number, x: number, y: number) => {
    const newPoints = handlePointDragMove(circlePosition, 'BOX')(shape.value.points, x / image.value.scale, y / image.value.scale, shape?.value?.x, shape?.value?.y)
    shape.value.points = newPoints
  }
</script>

<template>
  <v-line
    :id="`shadow-${shape?.id}`"
    ref="shapeRef"
    :draggable="false"
    :config="{
      x: shape?.x * image?.scale + device.shadow_range,
      y: shape?.y * image?.scale + device.shadow_range,
      points: shape?.points?.map((e) => e * image?.scale),
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: device.thickness,
      rotation: 0,
      hitStrokeWidth: 40,
    }"
  ></v-line>
  <v-line
    :id="shape?.id"
    ref="shapeRef"
    :draggable="draggable"
    :config="{
      x: shape?.x * image?.scale,
      y: shape?.y * image?.scale,
      points: shape?.points?.map((e) => e * image?.scale),
      fill: 'transparent',
      stroke: shape?.color,
      strokeWidth: device.thickness,
      rotation: 0,
      hitStrokeWidth: 40,
    }"
    @click="handleSelect"
    @tap="handleSelect"
    @touchstart="handleDragStart"
    @touchmove="handleDragMove"
    @touchend="handleDragStop"
    @dragstart="handleDragStart"
    @dragmove="handleDragMove"
    @dragend="handleDragStop"
  ></v-line>
  <ActionOptions
    :options="['copy', 'delete']"
    :show="isSelected"
    :x="shape.x * image.scale + shape?.points[0] * image.scale - 140"
    :y="shape.y * image.scale + shape?.points[1] * image.scale - 70"
    @delete="() => emits('ondelete', shape)"
    @copy="() => emits('oncopy', shape)"
  />
  <ActionCircles
    v-if="isSelected"
    :id="shape?.id"
    :color="shape.color"
    :keyshape="'box'"
    :positions="[0, 2, 4, 6]"
    :points="() => shape?.points?.map((e) => e * image?.scale)"
    :xs="shape.x * image.scale"
    :ys="shape.y * image.scale"
    @ondragmoving="({ circlePoint, x, y }) => handleDragMovePoint(circlePoint, x, y)"
    @ondragstop="handleDragStop"
  />
</template>
