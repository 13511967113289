<script lang="ts" setup>
  import { computed, toRefs, watch } from 'vue'
  import { useDialogs } from '../store/dialogs'
  import { useTextInDialog } from '../store/text'
  import { templateShape } from '../templates/shape'
  import { useColor } from '../store/color'
  import { useStage } from '../store/stage'
  import { coordinatesShape } from '../mocks/coodinatesRotate'
  import { useShapes } from '../store/shapes'
  import { calculateFontSize } from '../utils/calculateFontSize'

  const { showDialogText, handleShowDialog } = useDialogs()
  const textStore = useTextInDialog()
  const stageStore = useStage()
  const shapesStore = useShapes()
  const colorStore = useColor()
  const { shapes } = toRefs(shapesStore)
  const { color } = toRefs(colorStore)
  const { image } = toRefs(stageStore)
  const { cm, ft, in: InProp, m, text, text_unit, id: IdSelectedShape } = toRefs(textStore)
  const { handleCreateShape, handleUpdateShape } = shapesStore

  const baseFontSize = computed(() => {
    return calculateFontSize(image.value.original_width, image.value.original_height)
  })

  const handleText = () => {
    if (IdSelectedShape.value !== null) {
      handleUpdateShape({
        ...shapes.value[IdSelectedShape.value ?? ''],
        cm: cm.value,
        ft: ft.value,
        in: InProp.value,
        m: m.value,
        text: text.value,
        text_unit: text_unit.value,
      })
      return
    }
    const shape = templateShape({
      shapeKey: 'TEXT',
      color: color.value,
      screenWidth: image.value.width,
      screenHeight: image.value.height,
      text: text.value,
      points: [0, 0],
      scale: 2,
      centerTextFirstTime: false,
      cm: cm.value,
      fontSize: baseFontSize.value,
      ft: ft.value,
      height: 20,
      in: InProp.value,
      m: m.value,
      pathSticker: 'check',
      radius: 80,
      rotation: {
        angle: 90,
        points: [0, 0, 80, 0],
        rotation: coordinatesShape[`${image.value.rotation}` as keyof typeof coordinatesShape]?.rotate,
        x: 205.71428571428572,
        y: 290.9627329192547,
      },
      shadowPoints: [],
      text_unit: text_unit.value,
      thickness: 6,
      width: 20,
      image_scale: image.value.scale,
      image_rotation: image.value.rotation,
      range: 0,
    })
    handleCreateShape(shape)
  }

  const handleCreateText = () => {
    handleText()
    handleShowDialog('showDialogText', false)
    textStore.handleResetText()
  }
  const handleCloseDialog = () => {
    handleShowDialog('showDialogText', false)
    textStore.handleResetText()
  }

  const isDisabled = computed(() => {
    if (text_unit.value === 'ABC') {
      return text?.value?.trim()?.length === 0
    }
    if (text_unit.value === 'FTIN') {
      return ft?.value?.trim()?.length === 0 || InProp?.value?.trim()?.length === 0
    }
    if (text_unit.value === 'MCM') {
      return m?.value?.trim()?.length === 0 || cm?.value?.trim()?.length === 0
    }
    return false
  })

  watch(showDialogText, (currentDialog) => {
    if (currentDialog) return
    textStore.handleResetText()
  })
</script>
<template>
  <Dialog
    v-model:visible="showDialogText"
    :pt="{
      mask: {
        style: {
          zIndex: 1210,
        },
      },
    }"
    :modal="true"
    :auto-z-index="false"
    :base-z-index="1210"
    header="Add Text"
    class="w-full m-0"
    style="max-width: 42rem"
    :draggable="false"
  >
    <section class="flex flex-column gap-2">
      <header class="flex gap-2">
        <Button label="ABC" :severity="text_unit === 'ABC' ? '' : 'secondary'" :outlined="text_unit !== 'ABC'" @click="text_unit = 'ABC'" />
        <Button label="FT. IN." :severity="text_unit === 'FTIN' ? '' : 'secondary'" :outlined="text_unit !== 'FTIN'" @click="text_unit = 'FTIN'" />
        <Button label="M. CM." :severity="text_unit === 'MCM' ? '' : 'secondary'" :outlined="text_unit !== 'MCM'" @click="text_unit = 'MCM'" />
      </header>
      <Textarea v-if="text_unit === 'ABC'" v-model="text" rows="4" class="w-full p-2"></Textarea>
      <div v-if="text_unit === 'FTIN'" class="flex flex-row align-items-center justify-content-center gap-3">
        <p style="margin: 0">Ft.</p>
        <InputText v-model="ft" type="number" />
        <p>In.</p>
        <InputText v-model="InProp" type="number" />
      </div>
      <div v-if="text_unit === 'MCM'" class="flex flex-row align-items-center justify-content-center gap-3">
        <p style="margin: 0">M.</p>
        <InputText v-model="m" type="number" />
        <p>Cm.</p>
        <InputText v-model="cm" type="number" />
      </div>
      <div class="w-full flex justify-content-end align-items-center gap-2">
        <Button label="Cancel" class="w-auto" severity="secondary" raised @click="handleCloseDialog" />
        <Button label="Save" class="w-auto" severity="" :disabled="isDisabled" @click="handleCreateText" />
      </div>
    </section>
  </Dialog>
</template>
