<script lang="ts" setup>
  import { onMounted, ref, toRefs, watch } from 'vue'
  import { TShapeProps } from '../store/shapes'
  import { KonvaEventObject } from 'konva/lib/Node'
  import ActionOptions from './ActionOptions.vue'
  import { showTypTextUnit } from '../utils/textUnit'
  import ActionCircle from './ActionCircle.vue'
  const props = defineProps<TShapeProps>()
  const { shape, image, device, draggable } = toRefs(props)
  const emits = defineEmits(['ondragstart', 'ondragmoving', 'ondragstop', 'onselectshape', 'ondelete', 'ontext', 'oncopy'])
  const boxTransform = ref()
  const textWidthShape = ref(0)
  const textHeightShape = ref(0)
  const initialScale = ref(0)
  const savedFontSize = ref(0)

  const handleSelect = () => {
    shape.value.points = [0, 0, textWidthShape.value * 2, 0]
    emits('onselectshape', shape.value)
  }
  const handleDragStart = () => {
    emits('ondragstart', shape.value)
  }
  const handleDragMove = (e: KonvaEventObject<DragEvent>) => {
    shape.value.x = e.target.x() / image.value.scale
    shape.value.y = e.target.y() / image.value.scale
    emits('ondragmoving', shape.value)
  }

  const handleDragStop = () => {
    emits('ondragstop', shape.value)
  }

  const calculateRotatedPoint = (x: number, y: number, rotation: number, centerX: number, centerY: number) => {
    const radians = (rotation * Math.PI) / 180

    const rotatedX = centerX + (x - centerX) * Math.cos(radians) - (y - centerY) * Math.sin(radians)
    const rotatedY = centerY + (x - centerX) * Math.sin(radians) + (y - centerY) * Math.cos(radians)

    return {
      x: rotatedX,
      y: rotatedY,
    }
  }

  const handleDragStarPoint = (circlePosition: number, x: number, y: number) => {
    const x1 = shape.value.points[0]
    const y1 = shape.value.points[1]
    const x2 = x
    const y2 = y
    const scale = image.value.scale
    const currentDistance = Math.sqrt(Math.pow(x2 * scale - x1 * scale, 2) + Math.pow(y2 * scale - y1 * scale, 2))
    initialScale.value = currentDistance
    savedFontSize.value = shape.value.fontSize
  }
  const handleDragMovePoint = (circlePosition: number, x: number, y: number) => {
    const dx = x / image.value.scale - shape.value.x
    const dy = y / image.value.scale - shape.value.y

    const newRadius = Math.sqrt(dx * dx + dy * dy)
    const angleRadians = Math.atan2(dy, dx)
    const angleDegrees = angleRadians * (180 / Math.PI)

    shape.value.points[circlePosition] = dx
    shape.value.points[circlePosition + 1] = dy
    shape.value.radius = newRadius
    shape.value.rotation.rotation = angleDegrees

    const scale = image.value.scale
    const x1 = shape.value.points[0]
    const y1 = shape.value.points[1]
    const currentDistance = Math.sqrt(Math.pow(x * scale - x1 * scale, 2) + Math.pow(y * scale - y1 * scale, 2))

    const newScale = currentDistance / initialScale.value
    shape.value.fontSize = savedFontSize.value * newScale

    handleGetDimension()
  }
  const handleGetDimension = () => {
    textWidthShape.value = boxTransform.value?.getNode().width()
    textHeightShape.value = boxTransform.value?.getNode().height()
  }
  onMounted(handleGetDimension)
  watch([image, shape], handleGetDimension)
</script>

<template>
  <v-text
    :id="`text-static-${shape?.id}`"
    ref="boxTransform"
    :draggable="false"
    :config="{
      x: 0,
      y: 0,
      fill: shape.color,
      text: showTypTextUnit(shape),
      rotation: 0,
      hitStrokeWidth: 20,
      fontSize: shape.fontSize * image?.scale,
      fontStyle: 'bold',
      opacity: 0,
    }"
  ></v-text>
  <v-text
    :id="`text-shadow-${shape?.id}`"
    :draggable="false"
    :config="{
      x: shape?.x * image?.scale + device.shadow_range,
      y: shape?.y * image?.scale + device.shadow_range,
      fill: 'black',
      text: showTypTextUnit(shape),
      rotation: shape.rotation?.rotation,
      hitStrokeWidth: 20,
      fontSize: shape.fontSize * image?.scale,
      fontStyle: 'bold',
      offsetX: textWidthShape / 2,
      offsetY: textHeightShape / 2,
    }"
  ></v-text>
  <v-text
    :id="shape?.id"
    :draggable="draggable"
    :config="{
      x: shape?.x * image?.scale,
      y: shape?.y * image?.scale,
      fill: shape.color,
      text: showTypTextUnit(shape),
      rotation: shape.rotation?.rotation,
      hitStrokeWidth: 20,
      fontSize: shape.fontSize * image?.scale,
      fontStyle: 'bold',
      offsetX: textWidthShape / 2,
      offsetY: textHeightShape / 2,
    }"
    @click="handleSelect"
    @tap="handleSelect"
    @touchstart="handleDragStart"
    @touchmove="handleDragMove"
    @touchend="handleDragStop"
    @dragstart="handleDragStart"
    @dragmove="handleDragMove"
    @dragend="handleDragStop"
  ></v-text>
  <!-- <ActionCircle
    v-if="isSelected"
    :id="shape?.id"
    :color="shape.color"
    :keyshape="'text'"
    :x="calculateRotatedPoint(shape?.x * image?.scale - textWidthShape / 2, shape?.y * image?.scale, shape.rotation?.rotation, shape?.x * image?.scale, shape?.y * image?.scale)?.x"
    :y="
      calculateRotatedPoint(
        shape?.x * image?.scale - textWidthShape / 2,
        shape?.y * image?.scale + textHeightShape / 2,
        shape.rotation?.rotation,
        shape?.x * image?.scale,
        shape?.y * image?.scale
      )?.y
    "
    @ondragstart="({ x, y }) => handleDragStarPoint(0, x, y)"
    @ondragmoving="({ x, y }) => handleDragMovePoint(0, x, y)"
    @ondragstop="handleDragStop"
  /> -->

  <ActionCircle
    v-if="isSelected"
    :id="shape?.id"
    :color="shape.color"
    :keyshape="'text'"
    :x="
      calculateRotatedPoint(
        shape?.x * image?.scale + textWidthShape / 2,
        shape?.y * image?.scale + textHeightShape / 2,
        shape.rotation?.rotation,
        shape?.x * image?.scale,
        shape?.y * image?.scale
      )?.x
    "
    :y="
      calculateRotatedPoint(
        shape?.x * image?.scale + textWidthShape / 2,
        shape?.y * image?.scale + textHeightShape / 2,
        shape.rotation?.rotation,
        shape?.x * image?.scale,
        shape?.y * image?.scale
      )?.y
    "
    @ondragstart="({ x, y }) => handleDragStarPoint(2, x, y)"
    @ondragmoving="({ x, y }) => handleDragMovePoint(2, x, y)"
    @ondragstop="handleDragStop"
  />
  <ActionOptions
    :options="['copy', 'delete', 'text']"
    :show="isSelected"
    :x="shape.x * image.scale - 80"
    :y="shape.y * image.scale - 100"
    @delete="() => emits('ondelete', shape)"
    @text="() => emits('ontext', shape)"
    @copy="() => emits('oncopy', shape)"
    @rotate="
      () => {
        shape.rotation.rotation = shape.rotation.rotation + 90 === 360 ? 0 : shape.rotation.rotation + 90
      }
    "
  />
</template>
