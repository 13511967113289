<script lang="ts" async setup>
  import { ref, watch, onMounted, toRaw, computed } from 'vue'
  import { i18n } from '@/i18n'
  import { captureException } from '@sentry/vue'
  import api from '@/services/api'
  import 'v3-infinite-loading/lib/style.css'
  import { DataTablePageEvent } from 'primevue/datatable'
  import { FilterMatchMode, FilterMatchModeOptions } from 'primevue/api'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import { useDebounceFn } from '@vueuse/core'

  interface IPagination {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }

  interface ILead {
    address: string
    customerNumber: string
    id: string
    salesManager: string
    updatedAt: string
  }

  type ILeadResponse = {
    leads: ILead[]
    pagination: IPagination
  }

  type matchModeType = 'starsWith' | 'contains' | 'notContains' | 'endsWith' | 'equals' | 'notEquals'

  type filterValue = {
    value: string
    matchMode: matchModeType | string
  }

  type specialFilterValue = {
    operator: string
    constraints: filterValue[]
  }

  type filterType = {
    [key: string]: filterValue | specialFilterValue
  }

  interface Filter {
    value: any
    matchMode: keyof FilterMatchModeOptions
  }

  interface Filters {
    [key: string]: Filter
  }

  type sortType = {
    field: string
    order: number
  }

  interface IFilterParams {
    skip?: number | null
    take?: number | null
    sort?: sortType
    filters?: filterType
  }

  const matchModeMapping: Record<string, keyof FilterMatchModeOptions | any> = {
    startsWith: 'STARTS_WITH',
    contains: 'CONTAINS',
    notContains: 'NOT_CONTAINS',
    endsWith: 'ENDS_WITH',
    equals: 'EQUALS',
    notEquals: 'NOT_EQUALS',
    between: 'BETWEEN',
    dateIs: 'EQUALS',
    dateIsNot: 'NOT_EQUALS',
    dateBefore: 'LOWER_THAN',
    dateAfter: 'GREATER_THAN',
    default: 'EQUALS',
  }

  const { t } = i18n.global
  const takeValue = ref(10)
  const skipValue = ref(0)
  const refreshTrigger = ref(0)
  const leadsResponse = ref<ILeadResponse>({} as ILeadResponse)
  const loading = ref(false)
  const sort = ref<sortType | null>(null)

  const filters = ref({
    CustomerNumber: { value: '', matchMode: FilterMatchMode.CONTAINS },
    CustomerName: { value: '', matchMode: FilterMatchMode.CONTAINS },
    SalesManager: { value: '', matchMode: FilterMatchMode.CONTAINS },
    Address: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    UpdatedAt: { value: '', matchMode: FilterMatchMode.DATE_IS },
  })

  const convertFormat = (filters: Filters | Record<string, any>) => {
    const rawFilters = toRaw(filters) as Record<string, any>
    return Object.keys(rawFilters)
      .map((key) => {
        const filter = rawFilters[key]
        if (!filter || (Array.isArray(filter.value) && filter.value.length === 0)) {
          return null
        }
        return {
          field: key,
          value: filter.value,
          matchMode: matchModeMapping[filter.matchMode],
        }
      })
      .filter((item) => item && item.value !== '')
  }

  const fetchLeads = async (filterParams?: IFilterParams) => {
    try {
      loading.value = true
      const params = []

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      params.push(`timeZone=${timezone}`)

      if (filterParams?.skip !== undefined) {
        params.push(`skip=${filterParams.skip}`)
      }
      if (filterParams?.take !== undefined) {
        params.push(`take=${filterParams.take}`)
      }
      const payload = {
        filterProperties: convertFormat(filterParams?.filters ?? {}).filter((item) => item?.value !== null),
        sortFields: filterParams?.sort
          ? [
              {
                sortField: filterParams?.sort.field,
                sortOrder: filterParams?.sort.order > 0 ? 'ASC' : 'DESC',
              },
            ]
          : undefined,
      }
      const response = await api.post(`/Api/v2/Leads/Filter?${params.join('&')}`, payload)

      leadsResponse.value = {
        leads: response.data.data.items,
        pagination: response.data.data.pagination,
      }
    } catch (err) {
      captureException(err)
    } finally {
      loading.value = false
    }
  }

  async function updateLeads() {
    await fetchLeads({
      take: takeValue.value,
      skip: skipValue.value,
      sort: sort.value === null ? undefined : sort.value,
      filters: filters.value,
    })
  }

  watch(
    [filters, refreshTrigger],
    () => {
      skipValue.value = 0
      updateLeads()
    },
    { deep: true }
  )

  function refreshTable() {
    refreshTrigger.value++
  }

  watch([takeValue, skipValue, sort], () => {
    updateLeads()
  })

  const onPage = (event: DataTablePageEvent) => {
    loading.value = true
    skipValue.value = event.first
    takeValue.value = event.rows
    updateLeads().finally(() => {
      loading.value = false
    })
  }

  const debounced = useDebounceFn(async () => {
    await updateLeads()
  }, 100)

  const onFilter = async () => {
    await debounced()
  }

  onMounted(async () => {
    await fetchLeads({
      take: takeValue.value,
      skip: skipValue.value,
    })
  })
  const uniqueItems = computed(() => {
    const items = leadsResponse.value?.leads || []
    return items.map((item, index) => ({
      ...item,
      idField: `${item.id}-${index}`,
    }))
  })
</script>

<template>
  <div class="flex flex-column">
    <header class="mt-3">
      <div class="flex justify-content-between align-items-center">
        <h1 class="mb-0">{{ t('leadsIndex.leads') }}</h1>
      </div>
    </header>
    <div class="table-container">
      <DataTable
        ref="dt"
        v-model:rows="takeValue"
        v-model:filters="filters"
        class="w-full pt-3"
        lazy
        scrollable
        :first="skipValue"
        :value="uniqueItems"
        size="large"
        data-key="idField"
        :paginator="leadsResponse?.pagination?.rowCount > (leadsResponse?.leads ?? []).length"
        :rows-per-page-options="[5, 10, 20, 50]"
        style="position: static"
        :total-records="leadsResponse?.pagination?.rowCount ?? 10"
        filter-display="row"
        scroll-height="flex"
        @page="onPage($event)"
        @filter="onFilter()"
        @refresh="refreshTable"
      >
        <Column
          field="CustomerNumber"
          :show-filter-operator="false"
          :show-apply-button="false"
          :show-add-button="false"
          :header="t('leadsIndex.table.headers.ccNumber')"
          header-style="position: sticky; top: 0; z-index: 1; background: white; left: 0; width: 150px; min-width: 150px; border-top-left-radius: 10px"
          body-style="position: sticky; left: 0; background: white; width: 150px; min-width: 150px; z-index: 0;"
          style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white; position: sticky; top: 0; left: 0; z-index: 1"
        >
          <template #body="{ data }">
            <router-link :to="`/leads/${data?.id}/media`">{{ data?.customerNumber }}</router-link>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" placeholder="Search by customer Number" @input="filterCallback()" />
          </template>
        </Column>
        <Column
          field="CustomerName"
          style="min-width: 20rem; background-color: white"
          :header="t('leadsIndex.table.headers.customerName')"
          :show-filter-operator="false"
          :show-apply-button="false"
          :show-add-button="false"
        >
          <template #body="{ data }">
            {{ data.customerName }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" @input="filterCallback()" />
          </template>
        </Column>
        <Column
          field="SalesManager"
          style="min-width: 20rem; background-color: white"
          :header="t('leadsIndex.table.headers.salesManager')"
          :show-filter-operator="false"
          :show-apply-button="false"
          :show-add-button="false"
        >
          <template #body="{ data }">
            {{ data.salesManager }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by sales Manager" @input="filterCallback()" />
          </template>
        </Column>
        <Column
          field="Address"
          style="min-width: 20rem; background-color: white"
          :header="t('leadsIndex.table.headers.address')"
          :show-filter-operator="false"
          :show-apply-button="false"
          :show-add-button="false"
        >
          <template #body="{ data }">
            {{ data.address }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by address" @input="filterCallback()" />
          </template>
        </Column>
        <Column
          field="UpdatedAt"
          :header="t('leadsIndex.table.headers.lastModified')"
          data-type="date"
          style="min-width: 20rem; background-color: white"
          header-style="background: white; border-top-right-radius: 10px"
          :show-filter-operator="false"
          :show-apply-button="false"
          :show-add-button="false"
        >
          <template #body="{ data }">
            {{ useDateFormatUtil(data.updatedAt, 'MMM D, YYYY h:mm A') }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Calendar v-model="filterModel.value" date-format="mm/dd/yy" class="p-column-filter" placeholder="MM/DD/YYYY" mask="99/99/9999" @date-select="filterCallback()" />
          </template>
        </Column>
        <template #empty>
          <div v-if="loading" class="text-center p-4">
            <span>Loading data...</span>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<style scoped>
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .table-container {
    flex: 1;
    overflow-y: auto;
  }
  .header {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
</style>
