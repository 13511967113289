<script lang="ts" setup>
  import TagsMobile from './TagsMobile.vue'
  import TagsWeb from './TagsWeb.vue'
  import { useBreakpointsStore } from '@/store'
  import { storeToRefs } from 'pinia'
  const breakpointStore = useBreakpointsStore()
  const { lg } = storeToRefs(breakpointStore)
</script>

<template>
  <TagsWeb v-if="lg" />
  <TagsMobile v-else />
</template>
