export const settingsQueuePhotoEnUs = {
  queuePhotosTitle: 'Photo Q',
  queuePhotos: 'Queue Photos',
  queueCardUpload: 'Pending',
  uploadAll: 'Upload All',
}

export const settingsQueuePhotoEsMx = {
  queuePhotosTitle: 'Photo Q',
  queuePhotos: 'Fotos en cola',
  queueCardUpload: 'Pendiente',
  uploadAll: 'Subir todo',
}
