<script lang="ts" setup>
  import { KonvaEventObject } from 'konva/lib/Node'
  import { toRefs } from 'vue'
  import { device } from '../mocks/device'
  type Props = {
    id: string
    positions: number[]
    points: () => number[]
    xs: number
    ys: number
    color: string
    keyshape: 'arrow' | 'box' | 'text' | 'circle' | 'draw' | 'sticker'
  }

  const props = defineProps<Props>()
  const { positions, id, keyshape, xs, ys, color } = toRefs(props)
  const { points } = props
  const emits = defineEmits(['ondragstart', 'ondragmoving', 'ondragstop'])

  const handleDragStart = (circlePoint: number, e: KonvaEventObject<Event>) => {
    const data = e?.target?.getStage()?.getRelativePointerPosition()
    emits('ondragstart', {
      circlePoint,
      x: data?.x,
      y: data?.y,
    })
  }
  const handleDragMove = (circlePoint: number, e: KonvaEventObject<Event>) => {
    const data = e?.target?.getStage()?.getRelativePointerPosition()
    emits('ondragmoving', {
      circlePoint,
      x: data?.x,
      y: data?.y,
    })
  }
  const handleDragStop = (circlePoint: number, e: KonvaEventObject<Event>) => {
    const data = e?.target?.getStage()?.getRelativePointerPosition()
    emits('ondragstop', {
      circlePoint,
      x: data?.x,
      y: data?.y,
    })
  }
</script>

<template>
  <template v-for="point in positions" :key="`${id}-${keyshape}-action-circle-${point}`">
    <v-circle
      :config="{
        x: points()[point] + xs,
        y: points()[point + 1] + ys,
        fill: 'rgba(0,0,0,0.3)',
        radius: 20,
      }"
    ></v-circle>
    <v-circle
      :config="{
        x: points()[point] + xs + device.shadow_range,
        y: points()[point + 1] + ys + device.shadow_range,
        fill: 'black',
        radius: 13,
      }"
    ></v-circle>
    <v-circle
      :config="{
        x: points()[point] + xs,
        y: points()[point + 1] + ys,
        fill: color,
        radius: 13,
      }"
    ></v-circle>
    <v-circle
      :config="{
        x: points()[point] + xs,
        y: points()[point + 1] + ys,
        fill: 'white',
        radius: 6,
      }"
    ></v-circle>
    <v-circle
      :id="`${id}-${keyshape}-action-circle-${point}`"
      :config="{
        x: points()[point] + xs,
        y: points()[point + 1] + ys,
        fill: 'transparent',
        radius: 20,
      }"
      :draggable="true"
      @touchstart="(e: KonvaEventObject<Event>) => handleDragStart(point, e)"
      @touchmove="(e: KonvaEventObject<Event>) => handleDragMove(point, e)"
      @touchend="(e: KonvaEventObject<Event>) => handleDragStop(point, e)"
      @dragstart="(e: KonvaEventObject<Event>) => handleDragStart(point, e)"
      @dragmove="(e: KonvaEventObject<Event>) => handleDragMove(point, e)"
      @dragend="(e: KonvaEventObject<Event>) => handleDragStop(point, e)"
    ></v-circle>
  </template>
</template>
