export const crewUserEnUS = {
  addCrewUser: 'Add Crew User',
  updateCrewUser: 'Update Crew User',
  deleteCrewUser: 'Delete Crew User',
  activeCrewUser: 'Active Crew User',
  phone: 'Phone',
  email: 'Email',
  firstname: 'First Name',
  lastname: 'Last Name',
  submit: 'Submit',
  companyInformation: 'Company Information',
  companyName: 'Company Name',
  companyEmail: 'Email',
  companyPhone: 'Phone',
  noAvailableEmail: 'No mail available',
  noAvailablePhone: 'No phone available',
  menuEdit: 'Edit',
  menuDelete: 'Delete',
  menuActive: 'Active',
  deleteCrewUserMessage: 'Are you sure you want to delete this user?',
  CrewButtonCancel: 'Cancel',
  CrewButtonConfirm: 'Confirm',
  activeCrewUserMessage: 'Are you sure you want to activate this user?',
  activeTab: 'Active',
  disabledTab: 'Disabled',
  projects: 'Projects',
  users: 'Users',
}

export const crewUserEsMX = {
  addCrewUser: 'Agregar usuario',
  updateCrewUser: 'Actualizar usuario',
  deleteCrewUser: 'Eliminar usuario',
  activeCrewUser: 'Activar usuario',
  phone: 'Teléfono',
  email: 'Correo electrónico',
  firsname: 'Nombre',
  lastname: 'Apellido',
  submit: 'Enviar',
  companyInformation: 'Información de la empresa',
  companyName: 'Nombre de la empresa',
  companyEmail: 'Email',
  companyPhone: 'Teléfono',
  noAvailableEmail: 'No hay correo disponible',
  noAvailablePhone: 'NO hay telefono disponible',
  menuEdit: 'Editar',
  menuDelete: 'Eliminar',
  deleteCrewUserMessage: '¿Estás seguro de que quieres eliminar este usuario?',
  CrewButtonCancel: 'Cancelar',
  CrewButtonConfirm: 'Confirmar',
  activeCrewUserMessage: '¿Estás seguro de que quieres activar este usuario?',
  activeTab: 'Activos',
  disabledTab: 'Inactivos',
  projects: 'Proyectos',
  users: 'Usuarios',
}
