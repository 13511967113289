<script setup lang="ts">
  import ProjectMediaImageComponent from '@/components/ProjectMedia/ProjectMediaImageComponent.vue'
  import { baseUrl } from '@/constants'
  import { TPhotoContent } from '@/constants/types'
  import axios from 'axios'
  import Button from 'primevue/button'
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'

  const router = useRoute()
  const projectId = router.params?.id ?? 'b7d86f0b-2515-47b0-152b-08dc12086ba4'
  const tenant = router?.params?.tenant ?? 'infinity-exteriors'
  const entity = router?.params?.entity ?? 'Projects'

  const takeRef = ref(50)
  const skipRef = ref(0)
  const listPhotos = ref([] as TPhotoContent[][])
  const hasNextPage = ref(true)
  const isFetchingMore = ref(false)
  const isLoading = ref(true)
  const pagination = ref<{
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  } | null>(null)
  const fetchProjectPhotos = async (type: 'INITIAL' | 'MORE') => {
    try {
      if (type === 'INITIAL') {
        isLoading.value = true
      } else {
        isFetchingMore.value = true
      }
      const skip = skipRef.value === 0 ? 0 : (skipRef.value as number) * takeRef?.value
      const filter = `?take=${takeRef?.value}&skip=${skip}`

      const response = await axios.get(`${baseUrl}/${tenant}/Api/v2/${entity}/Photos/${projectId}/Public${filter}`)
      const photos = response?.data?.data?.photos as TPhotoContent[]

      hasNextPage.value = response.data?.data?.pagination?.nextPage !== null
      pagination.value = response?.data?.data?.pagination
      if (type === 'INITIAL') {
        listPhotos.value = [photos]
      } else {
        listPhotos.value = [...listPhotos.value, photos]
      }
    } catch (error) {
      throw new Error('Error fetch project photos')
    } finally {
      isLoading.value = false
      isFetchingMore.value = false
    }
  }
  onMounted(async () => {
    await fetchProjectPhotos('INITIAL')
  })
  const handleNextPage = async () => {
    if (hasNextPage?.value && !isFetchingMore.value) {
      skipRef.value = skipRef.value + 1
      await fetchProjectPhotos('MORE')
    }
  }
</script>

<template>
  <main class="flex flex-column relative flex-wrap justify-content-center align-items-center w-full gap-2 pb-4">
    <header v-if="!isLoading" class="w-full sticky top-0 z-5 bg-white py-2 px-2">
      <p class="m-0"><strong>Total Photos:</strong> {{ pagination?.rowCount }}</p>
      <p class="m-0"><strong>Page: </strong>{{ pagination?.pageNumber }} / {{ pagination?.pageCount }}</p>
      <p class="m-0"><strong>Size: </strong>{{ takeRef }}</p>
    </header>
    <section class="flex flex-row flex-wrap justify-content-start align-items-center w-full gap-2 pl-2 pr-2">
      <div v-for="(image, index) of listPhotos.flat()" :key="`${image?.id}` ?? index" class="relative" style="width: 150px !important; height: 150px !important">
        <ProjectMediaImageComponent :is-upload-type-available="true" :photo="image" :is-tag-available="true" />
      </div>
    </section>
    <div v-if="hasNextPage && !isLoading">
      <Button :loading="isFetchingMore" :label="'Load More Photos'" @click="handleNextPage"></Button>
    </div>
  </main>
</template>
