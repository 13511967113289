export const biometricUS = {
  UNKNOWN_ERROR: 'Unknown error',
  BIOMETRICS_UNAVAILABLE: 'Biometrics unavailable',
  USER_LOCKOUT: 'User lockout',
  BIOMETRICS_NOT_ENROLLED: 'Biometrics not enrolled',
  USER_TEMPORARY_LOCKOUT: 'User temporary lockout',
  AUTHENTICATION_FAILED: 'Authentication failed',
  APP_CANCEL: 'App cancel',
  INVALID_CONTEXT: 'Invalid context',
  NOT_INTERACTIVE: 'Not interactive',
  PASSCODE_NOT_SET: 'Passcode not set',
  SYSTEM_CANCEL: 'System cancel',
  USER_CANCEL: 'User cancel',
  USER_FALLBACK: 'User fallback',
  title: 'Face ID biometric',
}

export const biometricES = {
  UNKNOWN_ERROR: 'Error desconocido',
  BIOMETRICS_UNAVAILABLE: 'Biometría no disponible',
  USER_LOCKOUT: 'Bloqueo de usuario',
  BIOMETRICS_NOT_ENROLLED: 'Biometría no registrada',
  USER_TEMPORARY_LOCKOUT: 'Bloqueo temporal de usuario',
  AUTHENTICATION_FAILED: 'Autenticación fallida',
  APP_CANCEL: 'Cancelación de la aplicación',
  INVALID_CONTEXT: 'Contexto inválido',
  NOT_INTERACTIVE: 'No interactivo',
  PASSCODE_NOT_SET: 'Código no establecido',
  SYSTEM_CANCEL: 'Cancelación del sistema',
  USER_CANCEL: 'Cancelación del usuario',
  USER_FALLBACK: 'Fallback del usuario',
  title: 'Identificacion Facial Biometrica',
}

//  unknownError = 0,
//   biometricsUnavailable = 1,
//   userLockout = 2,
//   biometricsNotEnrolled = 3,
//   userTemporaryLockout = 4,
//   authenticationFailed = 10,
//   appCancel = 11,
//   invalidContext = 12,
//   notInteractive = 13,
//   passcodeNotSet = 14,
//   systemCancel = 15,
//   userCancel = 16,
//   userFallback = 17,

// export const erroresBiometricosValues = [
//   'Error desconocido',
//   'Biometría no disponible',
//   'Bloqueo de usuario',
//   'Biometría no registrada',
//   'Bloqueo temporal de usuario',
//   'Autenticación fallida',
//   'Cancelación de la aplicación',
//   'Contexto inválido',
//   'No interactivo',
//   'Código no establecido',
//   'Cancelación del sistema',
//   'Cancelación del usuario',
//   'Fallback del usuario',
// ]
