export const typePhotoEnUs = {
  PROGRESS_PICTURES: 'Progress',
  PREPRODUCTION_PICTURES: 'Pre-Production',
  TASK_BEFORE: 'Task Before',
  TASK_LIST: 'Task List',
  TASK: 'Task',
  TASK_PICTURES: 'Task Pictures',
  FINAL_PICTURES: 'Final Pictures',
  PROFILE_PICTURES: 'Profile',
  TASK_AFTER: 'Task After',
  PROBLEM: 'Problem',
}

export const typePhotoEsMx = {
  PROGRESS_PICTURES: 'Progreso',
  PREPRODUCTION_PICTURES: 'Pre-Producción',
  TASK_BEFORE: 'Antes de la Tarea',
  TASK_LIST: 'Lista de Tareas',
  TASK: 'Tarea',
  TASK_PICTURES: 'Fotos de Tarea',
  FINAL_PICTURES: 'Fotos Finales',
  PROFILE_PICTURES: 'Fotos de Perfil',
  TASK_AFTER: 'Después de la Tarea',
  PROBLEM: 'Problema',
}
