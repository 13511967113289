export const projectAssignCrewsEnUS = {
  crews: 'Crews',
  assign: 'ASSIGN',
  noCrewAssignedYet: 'No crews yet',
  assignCrew: 'Assign Crew',
  removeCrew: 'Remove user',
  removeCrewUser: 'Are you sure you want to remove this user from the crew?',
  buttonCancel: 'Cancel',
  buttonRemove: 'Remove',
}

export const projectAssignCrewsEsMX = {
  crews: 'Crews',
  assign: 'ASIGNAR',
  noCrewAssignedYet: 'Aún no hay equipos',
  assignCrew: 'Asignar Equipo',
  removeCrew: 'Remover usuario',
  removeCrewUser: '¿Estás seguro que deseas remover este usuario del equipo?',
  buttonCancel: 'Cancelar',
  buttonRemove: 'Remover',
}
