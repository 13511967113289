<script setup lang="ts">
  import { i18n } from '@/i18n'
  import { useAccountStore, useBreakpointsStore, useUserStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
  import { useToast } from 'primevue/usetoast'
  import { ref } from 'vue'
  const { t } = i18n.global
  const breakpointStore = useBreakpointsStore()
  const accountStore = useAccountStore()
  const userStore = useUserStore()
  const toast = useToast()
  const { md } = storeToRefs(breakpointStore)
  const showDialogTenant = ref(false)
  const { tenant, tenants } = storeToRefs(accountStore)
  const tenantValue = ref(tenants.value.find((tenantData) => tenantData.clientName === tenant.value))
  const suggestions = ref(tenants.value)
  const handleClick = () => {
    showDialogTenant.value = true
    console.log('click')
  }

  const search = (event: AutoCompleteCompleteEvent) => {
    suggestions.value = tenants.value.filter((tenant) => tenant.name.toLowerCase().includes(event.query.toLowerCase()))
  }

  const handleSubmitForm = () => {
    if (tenantValue.value?.clientName !== tenant.value && tenantValue.value) {
      userStore.getUserInfo()
      tenant.value = tenantValue.value.clientName
      showDialogTenant.value = false
      toast.add({ severity: 'success', summary: t('projectId.success'), detail: `${t('settingsIndex.changedTenant')} ${tenantValue.value.name}`, life: 10000 })
    }
  }
</script>

<template>
  <Dialog
    v-model:visible="showDialogTenant"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0"
    style="max-width: 40rem"
    :modal="true"
    :dismissable-mask="true"
    :draggable="false"
    :show-header="true"
    :close-on-escape="true"
    :closable="true"
    :header="t('settingsIndex.loginWithOtherTenant')"
  >
    <form action="" class="flex flex-column gap-3" @submit.prevent="handleSubmitForm">
      <p class="m-0">
        {{ t('settingsIndex.loginWithOtherTenantDescriptionModal') }}
      </p>
      <AutoComplete v-model="tenantValue" :suggestions="suggestions" option-label="name" option-value="value" class="w-full" :dropdown="true" @complete="search" />
      <Button :loading="false" :disabled="false" :label="t('settingsIndex.loginWithOtherTenantButton')" severity="primary" type="submit" />
    </form>
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>
  <div v-if="tenants.length > 1">
    <Button :label="t('settingsIndex.loginWithOtherTenantButton')" class="w-full" severity="primary" :outlined="true" @click="handleClick" />
  </div>
</template>
