<script lang="ts" setup>
  import { ref } from 'vue'
  import { useUserStore } from '@/store/Account/UserStore'
  import { useToast } from 'primevue/usetoast'
  import api from '@/services/api'
  import { IComment } from '@/models/comment/interfaces'
  import { i18n } from '@/i18n'
  import { useDateFormat } from '@vueuse/core'
  import { captureException } from '@sentry/vue'
  import { clearHtml } from '@/utils/clearHtml'

  const emit = defineEmits(['updateComment', 'deleteComment'])
  const useUser = useUserStore()
  const toast = useToast()
  interface Props {
    comment: IComment
  }

  const props = defineProps<Props>()
  const editing = ref(false)
  const editLoading = ref(false)
  const displayConfirmation = ref(false)
  const deleteLoading = ref(false)
  const dateFormat = useDateFormat(props.comment.createdAt, 'MMM D, YYYY h:mm A', { locales: 'en-US' })
  function toggleEditing() {
    editing.value = !editing.value
  }

  function toggleConfirmation() {
    displayConfirmation.value = !displayConfirmation.value
  }

  async function confirmDeletion() {
    try {
      deleteLoading.value = true
      await api.delete(`/Api/Projects/Photo/Comments/${props.comment.id}`)
      emit('deleteComment', { id: props.comment.id })
      toast.add({ severity: 'success', summary: i18n.global.t('commentComponent.success'), detail: i18n.global.t('commentComponent.commentDeleted'), life: 3000 })
      deleteLoading.value = false
      toggleConfirmation()
    } catch (err) {
      captureException(err)
      deleteLoading.value = false
      console.log('err', err)
      toast.add({ severity: 'error', summary: i18n.global.t('commentComponent.error'), detail: i18n.global.t('commentComponent.errorDeleting'), life: 3000 })
    }
  }

  async function editComment() {
    try {
      editLoading.value = true
      const comment = document.getElementById('edit-comment') as HTMLTextAreaElement

      const payload = { id: props.comment.id, comment: comment.value }
      await api.patch('/Api/Projects/Photo/Comment', payload)
      emit('updateComment', payload)
      toast.add({ severity: 'success', summary: i18n.global.t('commentComponent.success'), detail: i18n.global.t('commentComponent.commentEdited'), life: 3000 })
      editing.value = false
      editLoading.value = false
    } catch (err) {
      captureException(err)
      console.log('err', err)
      editLoading.value = false
      toast.add({ severity: 'error', summary: i18n.global.t('commentComponent.error'), detail: i18n.global.t('commentComponent.errorEditing'), life: 3000 })
    }
  }
</script>

<template>
  <!-- card info  -->
  <div class="flex flex-column w-full">
    <div>
      <div class="flex">
        <Avatar
          :image="`https://contacts.zoho.com/file?ID=${props.comment.user.zuid}&fs=thumb`"
          class="mr-3"
          size="normal"
          shape="circle"
          style="min-width: 2.7rem; min-height: 2.7rem"
        />
        <div class="flex flex-column p-0">
          <p class="p-0 m-0 text-base font-bold">{{ props.comment.user.fullName }}</p>
          <p class="p-0 m-0 text-xs">{{ dateFormat }}</p>
        </div>
      </div>
    </div>

    <!-- comennts -->
    <div class="flex-row w-full align-items-start mt-3">
      <div class="flex flex-column w-full">
        <div class="flex flex-column flex-1 border-round-lg" style="background-color: white">
          <div v-if="!editing" class="w-full text-sm comment-container p-2 border-round-lg" style="white-space: pre-wrap; background-color: white" v-html="props.comment.comment" />
          <div v-if="editing" class="w-full">
            <Textarea
              id="edit-comment"
              :model-value="props.comment.comment"
              class="flex-grow-1 w-full p-2"
              :auto-resize="true"
              rows="2"
              @touchstart="props.comment.comment"
            ></Textarea>
            <div class="flex flex-row align-items-center justify-content-end gap-2 pt-2">
              <Button :label="i18n.global.t('commentComponent.cancel')" class="p-button-danger p-button-outlined" @click="toggleEditing" />
              <Button :label="i18n.global.t('commentComponent.submit')" :loading="editLoading" :disabled="editLoading" @click="editComment" />
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-content-between align-items-center text-sm">
          <div v-if="useUser.zuid === props.comment.user.zuid" class="flex flex-row justify-content-start align-items-center gap-2">
            <Button class="btn p-button-text p-button-sm" :label="i18n.global.t('commentComponent.edit')" @click="toggleEditing" />
            <Button class="btn p-button-text p-button-sm p-button-danger" :label="i18n.global.t('commentComponent.delete')" @click="toggleConfirmation" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="displayConfirmation"
    :header="i18n.global.t('commentComponent.confirmDeletion')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>{{ $t('commentComponent.areYouSure') }}</span>
    </div>
    <template #footer>
      <Button :label="i18n.global.t('commentComponent.cancel')" class="p-button-outlined" @click="toggleConfirmation" />
      <Button :label="i18n.global.t('commentComponent.confirm')" class="p-button-danger" @click="confirmDeletion" />
    </template>
  </Dialog>
</template>
<style>
  .comment-container {
    display: flex;
  }
</style>
