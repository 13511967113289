import { ref } from 'vue'
import { IHttpConnectionOptions, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

const createSignalRConnection = (url: string, options: IHttpConnectionOptions) => new HubConnectionBuilder().withUrl(url, options).configureLogging(LogLevel.Information).build()

export const useSingalR = (url: string, key: string, options: signalR.IHttpConnectionOptions = {}) => {
  const connectionSingal = ref<signalR.HubConnection>()
  const startConnection = async (callback?: (e: any) => void) => {
    const connection = createSignalRConnection(url, options)

    try {
      connection.on(key, (e: any) => {
        if (callback) {
          callback(e)
        }
      })

      await connection.start()
      connectionSingal.value = connection
      console.log(`SignalR connected. ${key}`)
    } catch (err) {
      console.error('Error while starting SignalR connection: ', err)
      setTimeout(startConnection, 5000) // Retry connection every 5 seconds
    }
  }
  const stopConnection = async () => {
    if (connectionSingal.value) {
      connectionSingal.value.stop().then(() => console.log('SignalR disconnected.'))
    }
  }

  return { connectionSingal, startConnection, stopConnection }
}
