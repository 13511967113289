<script lang="ts" setup>
  import { useRoute } from 'vue-router'
  import { onMounted, ref } from 'vue'
  import api from '@/services/api'
  import { i18n } from '@/i18n'
  import { DataTablePageEvent } from 'primevue/datatable'
  import { FilterMatchMode, FilterMatchModeOptions } from 'primevue/api'
  import generateFilterByString from '@/utils/filters/filterByString'

  type IuserDirectory = {
    id: string
    fullName: string
    email: string
    phoneNumber: string
    phoneClean: null | string
    profile: string
    zuid: string
  }

  type filterValue<T> = {
    value: T
    matchMode: keyof FilterMatchModeOptions
  }

  type filterTypeData = {
    fullName: filterValue<string>
    email: filterValue<string>
    phoneClean: filterValue<string>
    profile: filterValue<string>
  }

  type sortType = {
    field: string
    order: number
  }

  type paginationType = {
    take: number
    skip: number
  }

  interface IFilterParams {
    skip?: number
    take?: number
    query?: string
    group?: string
    sort?: sortType
  }

  const takeValue = ref(10)
  const skipValue = ref(0)
  const route = useRoute()
  const userDirectoryResponse = ref<IuserDirectory[]>([])
  const dataTable = ref<IuserDirectory[]>([])
  const { t } = i18n.global
  const filter = route.params.filter
  const filters = ref<filterTypeData>({
    fullName: { value: '', matchMode: FilterMatchMode.CONTAINS as keyof FilterMatchModeOptions },
    email: { value: '', matchMode: FilterMatchMode.CONTAINS as keyof FilterMatchModeOptions },
    phoneClean: { value: '', matchMode: FilterMatchMode.CONTAINS as keyof FilterMatchModeOptions },
    profile: { value: '', matchMode: FilterMatchMode.CONTAINS as keyof FilterMatchModeOptions },
  })

  function getDataTable<T extends IuserDirectory>(array: T[], pagination: paginationType, filter?: filterTypeData) {
    let filteredArray: T[] = array
    const start = pagination.skip
    const end = start + pagination.take

    const applyFilter = (key: keyof Omit<filterTypeData, 'rating'>) => {
      const constraint = filter?.[key]
      if (constraint && typeof constraint.value === 'string' && constraint.value !== '') {
        const fnFilterString = generateFilterByString<IuserDirectory>(constraint.matchMode, constraint.value, key as keyof IuserDirectory)
        filteredArray = filteredArray.filter((data) => fnFilterString(data))
      }
    }
    applyFilter('fullName')
    applyFilter('phoneClean')
    applyFilter('email')
    applyFilter('profile')

    dataTable.value = filteredArray
    return filteredArray.slice(start, end)
  }

  async function getUsersByGroup(params?: IFilterParams) {
    const {
      data: { data: response },
    } = await api.get(`/Api/Users/ProfileBy/${(params?.group ?? '').toUpperCase()}`)
    userDirectoryResponse.value = response
    dataTable.value = userDirectoryResponse.value
  }

  const formatPhoneNumber = (str: string) => {
    const cleaned = ('' + str).replace(/\D/g, '')
    if (cleaned.length > 10) return `+${cleaned}`
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    return null
  }

  const onPage = (event: DataTablePageEvent) => {
    skipValue.value = event.first
    takeValue.value = event.rows
    const data = getDataTable(userDirectoryResponse.value, { skip: event.first, take: event.rows }, filters.value)
    dataTable.value = data
  }

  const onFilter = () => {
    getDataTable(userDirectoryResponse.value, { skip: skipValue.value, take: takeValue.value }, filters.value)
  }

  onMounted(async () => {
    const group = filter ? (filter as string) : 'all'
    await getUsersByGroup({ group })
  })

  const statusColors: Record<string, string> = {
    ADMINISTRATOR: '#2f90f7',
    PROJECT_MANAGER: '#f76c5e',
    SALES_PERSON: '#ffd700',
    ADDITIONAL_SALESMAN: '#00c48c',
    GENERAL_OFFICE: '#8e44ad',
    PROJECT_COORDINATOR: '#ff7f50',
    ACCOUNTING: '#20b2aa',
    GENERAL_MANAGER: '#ff4500',
    CREW_MANAGER: '#4682b4',
    CREW_USER: '#32cd32',
  }

  function getSeverity(status: string): string {
    const formattedStatus = status.toUpperCase().replace(/\s+/g, '_')
    return statusColors[formattedStatus]
  }
</script>

<template>
  <div class="flex flex-column h-full">
    <header class="w-full mt-3">
      <div class="flex flex-column lg:flex-row lg:justify-content-between flex-nowrap md:flex-wrap gap-3">
        <div>
          <h1 class="mb-0">{{ t('directoryIndex.directory') }}</h1>
        </div>
      </div>
    </header>
    <DataTable
      ref="dt"
      v-model:rows="takeValue"
      v-model:filters="filters"
      class="w-full pt-3"
      lazy
      :first="skipValue"
      :value="dataTable || []"
      size="large"
      paginator
      :rows-per-page-options="[5, 10, 20, 50]"
      data-key="id"
      :total-records="userDirectoryResponse.length"
      :global-filter-fields="['fullName', 'email', 'phone', 'profile']"
      filter-display="row"
      @page="onPage($event)"
      @filter="onFilter()"
    >
      <Column
        field="fullName"
        :header="t('directoryIndex.table.headers.name')"
        :show-filter-operator="false"
        :show-apply-button="false"
        :show-add-button="false"
        header-style="position: sticky; top: 0; z-index: 1; background: white; left: 0; width: 150px; min-width: 150px; border-top-left-radius: 10px"
        body-style="position: sticky; left: 0; background: white; width: 150px; min-width: 150px; z-index: 0;"
        style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white; position: sticky; top: 0; left: 0; z-index: 1"
      >
        <template #body="{ data }">
          <div class="flex align-items-center">
            <Avatar :image="`https://contacts.zoho.com/file?ID=${data.zuid}&fs=thumb`" class="mr-2" size="large" shape="circle" />
            <h2 class="text-lg font-bold m-0">
              {{ data.fullName }}
            </h2>
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" @input="filterCallback()" />
        </template>
      </Column>
      <Column
        field="email"
        :header="t('directoryIndex.table.headers.email')"
        :show-filter-operator="false"
        :show-apply-button="false"
        :show-add-button="false"
        style="min-width: 20rem; background-color: white"
      >
        <template #body="{ data }">
          <a :href="'mailto:' + data?.email" class="text-right">{{ data?.email }}</a>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by email" @input="filterCallback()" />
        </template>
      </Column>
      <Column
        field="phoneClean"
        :header="t('directoryIndex.table.headers.phone')"
        :show-filter-operator="false"
        :show-apply-button="false"
        :show-add-button="false"
        style="min-width: 20rem; background-color: white"
      >
        <template #body="{ data }">
          <a :href="'tel:' + formatPhoneNumber(data.phoneClean)" class="text-right">{{ formatPhoneNumber(data?.phoneClean) || '-' }}</a>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by phone" @input="filterCallback()" />
        </template>
      </Column>
      <Column
        field="profile"
        :header="t('directoryIndex.table.headers.profile')"
        :show-filter-operator="false"
        :show-apply-button="false"
        :show-add-button="false"
        header-style="background: white; border-top-right-radius: 10px"
        style="min-width: 15rem; background-color: white"
      >
        <template #body="{ data }">
          <div style="display: flex; justify-content: center; align-items: center; height: 100%">
            <Tag
              :value="data.profile"
              :style="{
                minWidth: '144px',
                height: '25px',
                backgroundColor: getSeverity(data.profile),
              }"
              :severity="getSeverity(data.profile)"
            />
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by profile" @input="filterCallback()" />
        </template>
      </Column>
    </DataTable>
    <!-- <TestDataTableAdvance /> -->
  </div>
</template>

<style>
  .p-chip.location-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }

  .p-tabview .p-tabview-nav-btn.p-link {
    background: #eff3f8;
  }
</style>
