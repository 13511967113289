import { zohoConnectEnUS } from '@/i18n/views/ZohoConnectCallback'
import { changePasswordEnUS } from './components/account/ChangePassword'
import { finalizePunchListsEnUs } from './components/FinalizePunchList'
import { GalleryComponentEnUs } from './components/GallryComponent'
import { globalSearchEnUS } from './components/GlobalSearch'
import { SiderBarPhotoFeedEnUs } from './components/Home/Web/SideBarPhotoFeed'
import { LeadCardComponentEnUS } from './components/LeadCardComponent'
import { menuComponentEnUS } from './components/MenuComponent'
import { projectSortEnUs } from './components/options/projectSort'
import { projectStagesEnUs } from './components/options/ProjectStage'
import { projectTypesEnUS } from './components/options/projectTypes'
import { typePhotoEnUs } from './components/options/typePhotos'
import { photoQueueComponentEnUS } from './components/PhotoQueueComponent'
import { pinturaEditorEnUS } from './components/pintura/pintura'
import { projectCardComponentEnUS } from './components/ProjectCardComponent'
import { projectCardsComponentEnUS } from './components/ProjectCardsComponent'
import { reviewsCrewEnUS } from './components/ReviewsCrew'
import { appLayoutEnUS } from './layouts/AppLayout'
import { commentComponentEnUS } from './views/CommentComponent'
import { commentsComponentEnUS } from './views/CommentsComponent'
import { crewDirectoryEnUS } from './views/CrewDirectoryIndex'
import { crewIndexEnUS } from './views/CrewIndex'
import { crewUserEnUS } from './views/CrewUser'
import { directoryIndexEnUS } from './views/DirectoryIndex'
import { forgotPasswordEnUS } from './views/ForgotPassword'
import { homeIndexEnUS } from './views/HomeIndex'
import { integrationsIndexEnUS } from './views/IntegrationsIndex'
import { LeadCardsViewEnUS } from './views/LeadComponents'
import { GalleryCommentsComponentEsUS } from './components/gallery/comments'
import { loginIndexEnUS } from './views/LoginIndex'
import { noteComponentEnUS } from './views/NoteComponent'
import { policiesIndexEnUS } from './views/PoliciesIndex'
import { profileIndexEnUS } from './views/ProfileIndex'
import { projectAssignCrewsEnUS } from './views/ProjectAssignCrews'
import { projectAssignCrewsAddEnUS } from './views/ProjectAssignCrewsAdd'
import { projectAssignCrewUsersEnUS } from './views/ProjectAssignCrewUsers'
import { projectAssignCrewUsersAddEnUS } from './views/ProjectAssignCrewUsersAdd'
import { projectCalendarEnUS } from './views/ProjectCalendar'
import { projectDocumentsEnUS } from './views/ProjectDocuments'
import { projectIdEnUS } from './views/ProjectId'
import { projectLaborBillEnUS } from './views/ProjectLaborBill'
import { projectMediaEnUS } from './views/ProjectMedia'
import { projectNotesEnUS } from './views/ProjectNotes'
import { projectProblemEnUS } from './views/ProjectProblem'
import { projectProblemsEnUS } from './views/ProjectProblems'
import { projectProblemsAddEnUS } from './views/ProjectProblemsAdd'
import { projectPunchListEnUS } from './views/ProjectPunchList'
import { projectPunchListAddEnUS } from './views/ProjectPunchListAdd'
import { projectsIndexEnUS } from './views/ProjectsIndex'
import { projectWorkContactsEnUS } from './views/ProjectWorkContacts'
import { scheduleIndexEnUS } from './views/ScheduleIndex'
import { settingsIndexEnUS } from './views/SettingsIndex'
import { SettingsPermissionInfoEnUs } from './views/settingsPermissionInfo'
import { settingsWorkDocumentIndexEnUS } from './views/SettingsWorkDocumentIndex'
import { settingsQueuePhotoEnUs } from './views/SettingsQueuePhoto'
import { editorImageEnUs } from './components/editorImage'
import { tagsEnUS } from './components/tag'
import { biometricUS } from './components/biometric/biometric'
import { MapIndexUS } from './views/MapView'
import { verifiyIdentityUs } from './faceId/verifyIdentity'
import { uploadMediaUS } from './views/uploadMedia'
import { tagsDataTableColumnUS } from './views/TagsDataTableColumns'
import { leadsIndexEnUS } from './views/LeadsIndex'
import { CalendarEn } from './components/carlendar'
import { photoQEn } from './components/photoQ'
import { FilterMediaEn } from './components/FilterMedia'

export const enUS = {
  photoQ: photoQEn,
  filterMedia: FilterMediaEn,
  appLayout: appLayoutEnUS,
  homeIndex: homeIndexEnUS,
  forgotPassword: forgotPasswordEnUS,
  loginIndex: loginIndexEnUS,
  calendar: CalendarEn,
  policiesIndex: policiesIndexEnUS,
  crewDirectory: crewDirectoryEnUS,
  crewIndex: crewIndexEnUS,
  directoryIndex: directoryIndexEnUS,
  projectCalendar: projectCalendarEnUS,
  projectAssignCrews: projectAssignCrewsEnUS,
  projectAssignCrewsAdd: projectAssignCrewsAddEnUS,
  noteComponent: noteComponentEnUS,
  projectNotes: projectNotesEnUS,
  projectLaborBill: projectLaborBillEnUS,
  projectProblems: projectProblemsEnUS,
  projectProblemsAdd: projectProblemsAddEnUS,
  commentComponent: commentComponentEnUS,
  commentsComponent: commentsComponentEnUS,
  projectMedia: projectMediaEnUS,
  projectPunchList: projectPunchListEnUS,
  projectPunchListAdd: projectPunchListAddEnUS,
  projectDocuments: projectDocumentsEnUS,
  projectId: projectIdEnUS,
  projectsIndex: projectsIndexEnUS,
  tagsData: tagsDataTableColumnUS,
  projectWorkContacts: projectWorkContactsEnUS,
  scheduleIndex: scheduleIndexEnUS,
  integrationsIndex: integrationsIndexEnUS,
  profileIndex: profileIndexEnUS,
  settingsIndex: settingsIndexEnUS,
  settingsWorkDocumentIndex: settingsWorkDocumentIndexEnUS,
  settingPermissionInfo: SettingsPermissionInfoEnUs,
  menuComponent: menuComponentEnUS,
  photoQueueComponent: photoQueueComponentEnUS,
  projectCardComponent: projectCardComponentEnUS,
  projectCardsComponent: projectCardsComponentEnUS,
  projectProblem: projectProblemEnUS,
  crewUser: crewUserEnUS,
  projectAssignCrewUsers: projectAssignCrewUsersEnUS,
  zohoConnet: zohoConnectEnUS,
  pinturaEditor: pinturaEditorEnUS,
  globalSearch: globalSearchEnUS,
  reviewsCrew: reviewsCrewEnUS,
  map: MapIndexUS,
  finalizePunchLists: finalizePunchListsEnUs,
  changePassword: changePasswordEnUS,
  projectAssignCrewUsersAdd: projectAssignCrewUsersAddEnUS,
  sideBarPhotoFeed: SiderBarPhotoFeedEnUs,
  galleryComponent: GalleryComponentEnUs,
  projectStage: projectStagesEnUs,
  projectSort: projectSortEnUs,
  biometricModal: biometricUS,
  projectTypes: projectTypesEnUS,
  photoType: typePhotoEnUs,
  leadCardComponent: LeadCardComponentEnUS,
  galleryCommentComponent: GalleryCommentsComponentEsUS,
  LeadCardsView: LeadCardsViewEnUS,
  settingsQueuePhoto: settingsQueuePhotoEnUs,
  editorImage: editorImageEnUs,
  uploadMedia: uploadMediaUS,
  faceIdVerify: verifiyIdentityUs,
  tags: tagsEnUS,
  leadsIndex: leadsIndexEnUS,
  close: 'Close',
  copy: 'Copy',
  share: 'Share',
  request: 'Request permission',
  unselectAll: 'Unselect All',
  crewCardReview: 'Reviews',
  crewMessageReviews: 'There are no reviews yet...',
  galleryError: 'The image did not load correctly.',
  permissionGlobalTitle: 'Permission Settings',
  permissionGlobalDescription: 'To provide you with the best experience, we need access to:',
  permissionAccess: {
    camera: 'Camera',
    location: 'Geolocation',
    notification: 'Notifications',
  },
  permissionMessage: 'Some features will not work properly if you do not enable the necessary permissions. To fully enjoy our app.',
}
