<script lang="ts" setup>
  import { onMounted, ref, toRefs } from 'vue'
  import InputCalendar from './InputCalendar.vue'
  import api from '@/services/api'
  import { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
  import { i18n } from '@/i18n'
  const { t } = i18n.global

  type Props = {
    entityId: string
    type: 'project' | 'Lead'
  }

  const props = defineProps<Props>()
  const { entityId, type } = toRefs(props)
  const emit = defineEmits(['apply'])
  const showDialogFilter = ref(false)

  const startDateRef = ref<string | null>(null)
  const endDateRef = ref<string | null>(null)

  const selectedUploadType = ref()
  const selectedPhotoType = ref()
  const selectedUserId = ref()

  type IMediaUsers = {
    fullName: string
    id: string
  }

  const fetchMediaUsers = async () => {
    const response = await api.get(`/Api/${type.value}/${entityId.value}/Media/Users`)
    if (!response?.data) {
      return [] as IMediaUsers[]
    }
    return response?.data?.data as IMediaUsers[]
  }

  const today = new Date()
  const lastSevenDays = new Date()
  lastSevenDays.setDate(today.getDate() + 7)

  const handleLoadUsers = async () => {
    const response = await fetchMediaUsers()
    for (const user of response) {
      optionsUserRef.value.push({
        id: user.id,
        value: user.id,
        name: user.fullName,
      })
    }
  }

  const handleClickFilter = () => {
    showDialogFilter.value = true
  }

  const handleApplyCalendar = (value: { startDate: string; endDate: string }) => {
    startDateRef.value = value?.startDate
    endDateRef.value = value?.endDate
  }
  const handleCleanFilters = () => {
    selectedUploadType.value = null
    selectedPhotoType.value = null
    selectedUserId.value = null
    startDateRef.value = null
    endDateRef.value = null
  }

  const handleApplyAndClose = () => {
    emit('apply', {
      uploadType: selectedUploadType.value?.value || null,
      photoType: selectedPhotoType.value?.value || null,
      userId: selectedUserId.value?.value || null,
      startDate: startDateRef.value,
      endDate: endDateRef.value,
    })
  }
  const uploadTypeOptionsArray = [
    {
      id: 'ALL',
      value: '',
      name: 'filterMedia.dialog.uploadType.options.ALL',
    },
    {
      id: 'CAMERA_MOVIL',
      value: 'CAMERA_MOVIL',
      name: 'filterMedia.dialog.uploadType.options.CAMERA_MOVIL',
    },
    {
      id: 'LIBRARY_MOVIL',
      value: 'LIBRARY_MOVIL',
      name: 'filterMedia.dialog.uploadType.options.LIBRARY_MOVIL',
    },
  ]

  const photoTypeOptionsArray = [
    {
      id: 'ALL',
      value: '',
      name: 'filterMedia.dialog.photoType.options.ALL',
    },
    {
      id: 'PROGRESS_PICTURES',
      value: 'PROGRESS_PICTURES',
      name: 'filterMedia.dialog.photoType.options.PROGRESS_PICTURES',
    },
    {
      id: 'TASK_BEFORE',
      value: 'TASK_BEFORE',
      name: 'filterMedia.dialog.photoType.options.TASK_BEFORE',
    },
    {
      id: 'TASK_LIST',
      value: 'TASK_LIST',
      name: 'filterMedia.dialog.photoType.options.TASK_LIST',
    },
    {
      id: 'TASK',
      value: 'TASK',
      name: 'filterMedia.dialog.photoType.options.TASK',
    },
    {
      id: 'TASK_PICTURES',
      value: 'TASK_PICTURES',
      name: 'filterMedia.dialog.photoType.options.TASK_PICTURES',
    },
    {
      id: 'FINAL_PICTURES',
      value: 'FINAL_PICTURES',
      name: 'filterMedia.dialog.photoType.options.FINAL_PICTURES',
    },
    {
      id: 'PROFILE_PICTURES',
      name: 'filterMedia.dialog.photoType.options.PROFILE_PICTURES',
      value: 'PROFILE_PICTURES',
    },
    {
      id: 'TASK_AFTER',
      value: 'TASK_AFTER',
      name: 'filterMedia.dialog.photoType.options.TASK_AFTER',
    },
    {
      id: 'PROBLEM',
      value: 'PROBLEM',
      name: 'filterMedia.dialog.photoType.options.PROBLEM',
    },
    {
      id: 'PREPRODUCTION_PICTURES',
      value: 'PREPRODUCTION_PICTURES',
      name: 'filterMedia.dialog.photoType.options.PREPRODUCTION_PICTURES',
    },
  ]

  type Option = {
    id: string
    value: string
    name: string
  }
  const optionUploadRef = ref<Option[]>([])
  const optionsPhotoTypeRef = ref<Option[]>([])
  const optionsUserRef = ref<Option[]>([
    {
      id: 'ALL',
      value: '',
      name: 'filterMedia.dialog.uploadType.options.ALL',
    },
  ])
  const stores = {
    uploadType: optionUploadRef,
    photoType: optionsPhotoTypeRef,
    userId: optionsUserRef,
  }

  const optionsArray = {
    uploadType: uploadTypeOptionsArray,
    photoType: photoTypeOptionsArray,
    userId: optionsUserRef?.value,
  }

  const handleCompleteUploadType = (p: AutoCompleteCompleteEvent, key: keyof typeof stores) => {
    stores[key].value = optionsArray[key]?.filter((e) => e?.name?.toLowerCase()?.includes(p?.query?.toLowerCase()))
  }

  onMounted(() => {
    handleLoadUsers()
  })
</script>

<template>
  <Dialog
    v-model:visible="showDialogFilter"
    class="w-full ml-8"
    style="max-width: 40rem; z-index: 1215"
    modal
    :draggable="false"
    close-on-escape
    closable
    :header="t('filterMedia.label')"
    @after-hide="handleApplyAndClose"
  >
    <section class="flex flex-column gap-2" style="min-height: 480px; height: 100%">
      <p class="m-0">{{ t('filterMedia.dialog.date.label') }}</p>
      <InputCalendar :primary-color="'#000'" secondary-color="#E2E2E2" :start-date="startDateRef" :end-date="endDateRef" @apply="handleApplyCalendar" />
      <p class="m-0">{{ t('filterMedia.dialog.uploadType.label') }}</p>
      <AutoComplete
        v-model="selectedUploadType"
        option-label="label"
        class="w-full"
        :dropdown="true"
        force-selection
        :placeholder="t('filterMedia.dialog.uploadType.placeholder')"
        :suggestions="
          optionUploadRef.map((sort) => ({
            ...sort,
            label: t(sort.name),
          }))
        "
        @complete="(e) => handleCompleteUploadType(e, 'uploadType')"
      >
        <template #item="slotProps">
          <div class="flex justify-content-start align-items-center">
            <p>{{ slotProps.item.label }}</p>
          </div>
        </template>
      </AutoComplete>

      <p class="m-0">{{ t('filterMedia.dialog.photoType.label') }}</p>
      <AutoComplete
        v-model="selectedPhotoType"
        class="w-full"
        :dropdown="true"
        force-selection
        option-label="label"
        :placeholder="t('filterMedia.dialog.photoType.placeholder')"
        :suggestions="
          optionsPhotoTypeRef.map((sort) => ({
            ...sort,
            label: t(sort.name),
          }))
        "
        @complete="(e) => handleCompleteUploadType(e, 'photoType')"
      >
        <template #item="slotProps">
          <div class="flex justify-content-start align-items-center">
            <p>{{ slotProps.item.label }}</p>
          </div>
        </template>
      </AutoComplete>

      <p class="m-0">{{ t('filterMedia.dialog.user.label') }}</p>
      <AutoComplete
        v-model="selectedUserId"
        option-label="label"
        class="w-full"
        :dropdown="true"
        force-selection
        :placeholder="t('filterMedia.dialog.user.placeholder')"
        :suggestions="
          optionsUserRef.map((sort) => ({
            ...sort,
            label: t(sort.name),
          }))
        "
        @complete="(e) => handleCompleteUploadType(e, 'userId')"
      >
        <template #item="slotProps">
          <div class="flex justify-content-start align-items-center">
            <p v-if="slotProps.item.label.includes('filterMedia.')">{{ t(slotProps.item.label) }}</p>
            <p v-else>{{ slotProps.item.label }}</p>
          </div>
        </template>
      </AutoComplete>
    </section>
    <footer class="flex w-full">
      <Button :icon="'pi pi-trash'" :label="t('filterMedia.dialog.actions.clearFilters')" :class="'flex w-full bg-black-alpha-90 border-0'" @click="handleCleanFilters" />
      <Button :label="t('filterMedia.dialog.actions.close')" :class="'flex w-full bg-black-alpha-90 border-0'" @click="showDialogFilter = false" />
    </footer>
  </Dialog>
  <Button :icon="'pi pi-cog'" :label="t('filterMedia.label')" class="bg-black-alpha-90 border-0" @click="handleClickFilter" />
</template>
