<script lang="ts" setup>
  import { KonvaEventObject } from 'konva/lib/Node'
  import { toRefs } from 'vue'

  type Props = {
    id: string
    x: number
    y: number
    color: string
    keyshape: 'arrow' | 'box' | 'text' | 'circle' | 'draw' | 'sticker'
  }
  const props = defineProps<Props>()
  const { x, y, color, id } = toRefs(props)
  const emits = defineEmits(['ondragstart', 'ondragmoving', 'ondragstop'])

  const handleDragStart = (e: KonvaEventObject<Event>) => {
    const data = e?.target?.getStage()?.getRelativePointerPosition()
    emits('ondragstart', {
      x: data?.x,
      y: data?.y,
      e,
    })
  }
  const handleDragMove = (e: KonvaEventObject<Event>) => {
    const data = e?.target?.getStage()?.getRelativePointerPosition()
    emits('ondragmoving', {
      x: data?.x,
      y: data?.y,
      e,
    })
  }
  const handleDragStop = (e: KonvaEventObject<Event>) => {
    const data = e?.target?.getStage()?.getRelativePointerPosition()
    emits('ondragstop', {
      x: data?.x,
      y: data?.y,
      e,
    })
  }
</script>

<template>
  <v-circle
    :config="{
      x: x,
      y: y,
      fill: 'rgba(0,0,0,0.3)',
      radius: 20,
    }"
  ></v-circle>
  <v-circle
    :config="{
      x: x,
      y: y,
      fill: color,
      radius: 13,
    }"
  ></v-circle>
  <v-circle
    :config="{
      x: x,
      y: y,
      fill: 'white',
      radius: 6,
    }"
  ></v-circle>
  <v-circle
    :id="`${id}-${keyshape}-action-circle`"
    :config="{
      x: x,
      y: y,
      fill: 'transparent',
      radius: 20,
    }"
    :draggable="true"
    @touchstart="(e: KonvaEventObject<Event>) => handleDragStart(e)"
    @touchmove="(e: KonvaEventObject<Event>) => handleDragMove(e)"
    @touchend="(e: KonvaEventObject<Event>) => handleDragStop(e)"
    @dragstart="(e: KonvaEventObject<Event>) => handleDragStart(e)"
    @dragmove="(e: KonvaEventObject<Event>) => handleDragMove(e)"
    @dragend="(e: KonvaEventObject<Event>) => handleDragStop(e)"
  ></v-circle>
</template>
