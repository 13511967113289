export const projectCardComponentEnUS = {
  away: 'Away',
  miles: 'Miles',
  noActualStartDate: 'No actual start date provided',
  noPoNumber: 'No PO Number Provided',
  noPhotos: 'No photos for this project. Be the first!',
  takePicture: 'CAPTURE NOW',
  noLocation: 'No Location Provided',
  openPuncList: 'Open Punch Lists',
  openProblems: 'Open Problems',
  nothingPending: 'Nothing pending at the moment',
}

export const projectCardComponentEsMX = {
  away: 'de Distancia',
  miles: 'Millas',
  noActualStartDate: 'No se proporcionó fecha de inicio',
  noPoNumber: 'No se proporcionó PO Number',
  noPhotos: 'No hay fotos para este proyecto. ¡Sé el primero!',
  takePicture: 'CAPTURAR AHORA',
  noLocation: 'No hay ubicación proporcionada',
  openPuncList: 'Listas de pendientes abiertos',
  openProblems: 'Problemas abiertos',
  nothingPending: 'Nada pendiente por el momento',
}
