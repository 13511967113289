<script setup lang="ts">
  import { computed, ref } from 'vue'
  import api from '@/services/api'
  import { storeToRefs } from 'pinia'
  import { useToast } from 'primevue/usetoast'
  import { useCloseOutStore } from '@/store'
  import ListTaskIndex from './ListTaskComponent/ListTaskIndex.vue'
  import ReviewsIndex from '../Reviews/ReviewsIndex.vue'
  import BottomAccions from './BottomAccions.vue'
  import { i18n } from '@/i18n'
  import { IQueuePhoto } from '@/db/tables'
  import { ITask } from '@/interfaces/punch-list/task/task.interface'

  type Iprops = {
    images?: IQueuePhoto[]
    selectedItem?: ITask | null
  }

  const props = defineProps<Iprops>()
  const { t } = i18n.global
  const toast = useToast()
  const emit = defineEmits(['reviewsSubmitted', 'update:images', 'setSelectedItem', 'takePicture', 'selectPhoto'])

  const images = computed({
    get: () => props.images,
    set: (value) => {
      emit('update:images', value)
    },
  })

  const punchListCameraStore = useCloseOutStore()
  const { nextStep, previousStep } = punchListCameraStore
  const { photoType, punchList, allItemsComplete, crewsForReview, closeOutListExists, currentStep, isLoadingCloseOut } = storeToRefs(punchListCameraStore)
  const submitLoading = ref(false)

  const submitCloseOut = async () => {
    try {
      submitLoading.value = true
      const reviews = []
      for (const crew of crewsForReview.value) {
        for (const review of crew.reviews) {
          const { rating, comment } = review
          if (rating === 0 || !comment || comment.length === 0) {
            toast.add({
              severity: 'warn',
              summary: 'Warn Message',
              detail: 'Error missing review information',
              life: 3000,
            })
            return
          }
          reviews.push(review)
        }
      }

      const reviewPromises = reviews.map((review) => api.post('/Api/Projects/Reviews', review))

      await Promise.all(reviewPromises)
      emit('reviewsSubmitted')

      //   await stopCamera()
    } catch (err) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error submitting close out',
        life: 3000,
      })
    } finally {
      submitLoading.value = false
    }
  }
</script>

<template>
  <div v-if="closeOutListExists" class="w-full h-full flex flex-column mt-3" style="max-width: 820px">
    <div id="scroll-container" class="flex flex-column flex-grow-1 h-full overflow-auto" style="padding-left: 1.25rem; padding-right: 1.25rem; padding-bottom: 1.25rem">
      <ListTaskIndex
        v-if="currentStep === 1"
        :task-lists="punchList?.taskLists ?? []"
        :images="images"
        :selected-item="selectedItem"
        @select-photo="emit('selectPhoto')"
        @set-selected-item="
          (item) => {
            emit('setSelectedItem', item)
          }
        "
      />
      <ReviewsIndex v-if="currentStep === 2" :crews-for-review="crewsForReview" />
    </div>

    <BottomAccions
      v-if="!isLoadingCloseOut && closeOutListExists"
      :all-items-complete="allItemsComplete"
      :current-step="currentStep"
      :close-out-list-exists="closeOutListExists"
      :punch-list="punchList"
      :photo-type="photoType"
      :submit-loading="submitLoading"
      @submit-close-out="submitCloseOut"
      @next-step="nextStep"
      @previous-step="previousStep"
    />
  </div>

  <div v-else class="w-full h-full flex align-items-center justify-content-center text-center px-4 font-semibold text-lg">
    {{ t('finalizePunchLists.noListCloseOut') }}
    support@infinityhomeservices.com
  </div>
</template>
