import { useBreakpointsStore } from '@/store'
import { storeToRefs } from 'pinia'
import { useMediaQuery } from '@vueuse/core'
import { onMounted, watch } from 'vue'

export const useBreakpoints = () => {
  const xsMedia = useMediaQuery('(max-width: 575px)')
  const smMedia = useMediaQuery('(min-width: 576px)')
  const mdMedia = useMediaQuery('(min-width: 768px)')
  const lgMedia = useMediaQuery('(min-width: 992px)')
  const xlMedia = useMediaQuery('(min-width: 1200px)')

  const useBreakpoints = useBreakpointsStore()

  const setMediaQueries = () => {
    useBreakpoints.setBreakPoints(xsMedia.value, smMedia.value, mdMedia.value, lgMedia.value, xlMedia.value)
  }

  watch([xsMedia, smMedia, mdMedia, lgMedia, xlMedia], () => {
    setMediaQueries()
  })
  onMounted(() => {
    useBreakpoints.setBreakPoints(false, false, false, false, false)
    setTimeout(() => {
      setMediaQueries()
    }, 500)
  })

  const { sm, md, lg, xs, xl } = storeToRefs(useBreakpoints)

  return { sm, md, lg, xs, xl }
}
