<script lang="ts" setup>
  import ProjectCardComponent from '@/components/ProjectCardComponent.vue'
  import LoadingProjectCard from './LoadingProjectCard.vue'
  import Lazy from '@/components/LazyComponent.vue'
  import { IProject } from '@/interfaces/project/project.interface'
  import { toRefs } from 'vue'

  type Iprops = {
    projects: IProject[]
    loadingProjects: boolean
    isLeads?: boolean
  }
  const props = defineProps<Iprops>()
  const { loadingProjects, projects } = toRefs(props)
</script>

<template>
  <!--project card-->
  <template v-if="(projects ?? []).length > 0">
    <div class="flex flex-wrap mt-2 w-full justify-content-evenly gap-3">
      <Lazy v-for="project in projects" :key="project.id" :unrender="true" :min-height="!isLeads ? 300 : undefined" class="col col-12 sm:col-6 md:col-4 flex-grow-1">
        <ProjectCardComponent :project="project" :lead="isLeads" />
      </Lazy>
    </div>

    <div class="h-2rem" />
  </template>

  <div v-else-if="!loadingProjects" class="col-12">
    <div class="card flex align-items-center justify-content-center flex-wrap col gap-2">
      <h6 class="pt-3">No Projects. Either try again later or update your filters to see more.</h6>
    </div>
  </div>

  <!-- skeleton-->
  <div v-if="loadingProjects" class="flex flex-wrap mt-2 w-full justify-content-evenly gap-3">
    <LoadingProjectCard v-for="i in 8" :key="i" :is-leads="isLeads" class="col col-12 sm:col-6 md:col-4 flex-grow-1 p-0" :style="{ minHeight: !isLeads ? '300px' : undefined }" />
  </div>
</template>
