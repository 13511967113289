export enum ProjectTypeEnum {
  NONE = '-None-',
  COMMERCIAL_GUTTERS = 'Commercial Gutters',
  COMMERCIAL_INSULATION = 'Commercial Insulation',
  COMMERCIAL_SIDING = 'Commercial Siding',
  COMMERCIAL_WINDOWS = 'Commercial Windows',
  CONCRETE = 'Concrete',
  CONSTRUCTION = 'Construction',
  DECK = 'Deck',
  GUTTER_GUARDS = 'Gutter Guards',
  GUTTERS = 'Gutters',
  INSULATION = 'Insulation',
  LABOR_ONLY = 'Labor Only',
  NEW_CONSTRUCTION_ROOFING = 'New Construction - Roofing',
  NEW_CONSTRUCTION_SIDING = 'New Construction - Siding',
  NEW_CONSTRUCTION_WINDOWS = 'New Construction - Windows',
  REPAIRS = 'Repairs',
  ROOFING = 'Roofing',
  ROOFING_GUTTERS = 'Roofing & Gutters',
  ROOFING_CEDAR_INSTALL = 'Roofing - Cedar Install',
  ROOFING_CEDAR_REDECK = 'Roofing - Cedar redeck',
  ROOFING_COMMERCIAL = 'Roofing - Commercial',
  ROOFING_FLAT_ROOF_RESIDENTIAL = 'Roofing - Flat roof (residential)',
  ROOFING_METAL = 'Roofing - Metal',
  ROOFING_PLANKER = 'Roofing - Planker',
  ROOFING_RANCH = 'Roofing - Ranch',
  ROOFING_SPECIALTY = 'Roofing - Specialty',
  SIDING_HARDI = 'Siding - Hardi',
  SIDING_HOLLOW = 'Siding - Hollow',
  SIDING_INSULATED = 'Siding - Insulated',
  SIDING_REPAIRS = 'Siding - Repairs',
  SIDING_SMARTSIDE = 'Siding - Smartside',
  SIDING_STONE = 'Siding - Stone',
  SKYLIGHTS = 'Skylights',
  TENANT = 'Tenant',
  TRIM_WORK = 'Trim Work',
  WINDOWS_DOORS = 'Windows & Doors',
  WINDOWS_DOORS_COMMERCIAL = 'Windows & Doors - Commercial',
  WINDOWS_DOORS_ENTRY_DOOR = 'Windows & Doors - Entry Door',
  WINDOWS_DOORS_GARAGE_DOOR = 'Windows & Doors - Garage door',
  WINDOWS_DOORS_GREAT_LAKES = 'Windows & Doors - Great Lakes',
  WINDOWS_DOORS_MARVIN = 'Windows & Doors - Marvin',
  WINDOWS_DOORS_PATIO_DOOR = 'Windows & Doors - Patio Door',
  WINDOWS_DOORS_PELLA = 'Windows & Doors - Pella',
  WINDOWS_DOORS_PROVIA = 'Windows & Doors - Provia',
  WINDOWS_DOORS_RICHLIN = 'Windows & Doors - Richlin',
  WINDOWS_DOORS_SIERRA = 'Windows & Doors - Sierra',
}
