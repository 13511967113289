<script lang="ts" setup>
  import { useBreakpointsStore, useUserStore } from '@/store'
  import api from '@/services/api'
  import { storeToRefs } from 'pinia'
  import { Ref, onMounted, ref, toRefs, watch } from 'vue'
  import NewCrewUser from './NewCrewUser.vue'
  import UpdateCrewUser from './UpdateCrewUser.vue'
  import ActiveTab from './tabs/ActiveTab.vue'
  import DisabledTab from './tabs/DisabledTab.vue'
  import { i18n } from '@/i18n'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import { useRoute, useRouter } from 'vue-router'
  import CardCrewDirectoryInvitation from '@/components/CardCrewDirectoryInvitation.vue'

  interface ICrewUser {
    email: string
    firstName: string
    fullName: string
    id: string
    lastName: string
    marketLocations: string[]
    phoneNumber: string
  }

  interface ICompany {
    name: string
    phone: string
    email: string
    globalRanking: number
    isAuthorized: boolean
  }

  const route = useRoute()
  const router = useRouter()
  const crewId = ref(route.params.id)

  const { t } = i18n.global
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const dialogCreateIsActive: Ref<boolean> = ref(false)
  const dialogDeleteIsActive: Ref<boolean> = ref(false)
  const dialogActiveIsActive: Ref<boolean> = ref(false)
  const dialogUpdateIsActive: Ref<boolean> = ref(false)
  const crewUsersActive: Ref<ICrewUser[]> = ref([])
  const crewUsersDisabled: Ref<ICrewUser[]> = ref([])
  const crewUserSelect = ref({} as ICrewUser)
  const company = ref({} as ICompany)
  const projects = ref([])
  const activeMain = ref(0)
  const showAllComment: any = ref(null)
  const active = ref(t('crewUser.activeTab'))
  const userStore = useUserStore()
  const { permissions } = toRefs(userStore)
  const changeCreateDialog = () => {
    dialogCreateIsActive.value = !dialogCreateIsActive.value
  }

  const changeUpdateDialog = () => {
    dialogUpdateIsActive.value = !dialogUpdateIsActive.value
  }

  const changeDeleteDialog = () => {
    dialogDeleteIsActive.value = !dialogDeleteIsActive.value
  }

  const changeActiveDialog = () => {
    dialogActiveIsActive.value = !dialogActiveIsActive.value
  }

  const tabMenu = [t('crewUser.activeTab'), t('crewUser.disabledTab')]

  const tabMenuMain = [
    { id: 'projects', label: t('crewUser.projects') },
    { id: 'users', label: t('crewUser.users') },
  ]

  const getCrewUsers = async () => {
    if (!crewId.value) return
    await api.get(`/Api/CrewUsers/${crewId.value}`).then((response) => {
      crewUsersActive.value = response.data.data
    })
    await api.get(`/Api/CrewUsers?CrewCompanyId=${crewId.value}&includeDeleted=false&onlyDeleted=true`).then((response) => {
      crewUsersDisabled.value = response.data.data
    })
  }
  const closeDialogCreate = async () => {
    await getCrewUsers()
    changeCreateDialog()
  }

  const closeDialogUpdate = async () => {
    await getCrewUsers()
    changeUpdateDialog()
  }

  const deleteCrewUser = async () => {
    changeDeleteDialog()
    await api.delete(`/Api/CrewUser/delete/${crewUserSelect.value.id}`, {
      data: { CrewCompanyId: crewId.value },
    })
    await getCrewUsers()
  }

  const activeCrewUser = async () => {
    changeActiveDialog()
    await api.put(`/Api/CrewUser/Reincorporate/${crewUserSelect.value.id}`, {
      data: { CrewCompanyId: crewId.value },
    })
    await getCrewUsers()
  }

  const getInfoCrew = () => {
    if (!crewId.value) return
    api.get(`/Api/CrewCompany/${crewId.value}`).then((response) => {
      company.value = response.data.data
    })
  }

  const getProjectsCrew = async () => {
    if (!crewId.value) return
    api.get(`/Api/CrewCompany/${crewId.value}/GetProjects`).then((response) => {
      projects.value = response.data.data
    })
  }
  const getInfoCrewRequest = () => {
    getInfoCrew()
    getProjectsCrew()
    getCrewUsers()
  }

  watch(route, () => {
    if (route.params.id === crewId.value) return
    crewId.value = route.params.id
    getInfoCrewRequest()
  })

  onMounted(() => {
    getInfoCrewRequest()
  })
</script>

<template>
  <div class="pb-7">
    <card unstyled style="background-color: white; border-radius: 20px">
      <template #header>
        <img alt="crew placeholder" class="placeholder-crews" src="/assets/img/bg/bg1.webp" />
      </template>
      <template #content>
        <div class="p-4">
          <div class="flex justify-content-between flex-grow-1 w-full gap-2">
            <div style="font-size: 2.5rem; font-weight: 700; margin-bottom: 0.5rem">
              {{ company.name }}
            </div>
            <Rating :model-value="company.globalRanking" :stars="5" :cancel="false" />
          </div>
          <div class="mb-4">
            <div class="font-bold">{{ t('crewUser.companyEmail') }}</div>
            <div>
              <a v-if="company.email" :href="`mailto:${company.email}`">{{ company.email }}</a>
              <p v-else>{{ t('crewUser.noAvailableEmail') }}</p>
            </div>
          </div>
          <div>
            <div class="font-bold">{{ t('crewUser.companyPhone') }}</div>
            <div>
              <a v-if="company.phone" :href="`tel:${company.phone}`">{{ company.phone }}</a>
              <p v-else>{{ t('crewUser.noAvailablePhone') }}</p>
            </div>
          </div>
          <section v-if="permissions?.crewDirectoryPermissions?.canInviteCrew" class="mt-2">
            <CardCrewDirectoryInvitation
              v-bind="{
              id: crewId as string,
              name:company.name
            }"
            />
          </section>
        </div>
        <div class="grid resetlayout">
          <div class="col-12 overflow-hidden">
            <TabView v-model:activeIndex="activeMain" :scrollable="true">
              <TabPanel v-for="item in tabMenuMain" :key="item.id" :header="item.label" content-class="hidden" />
            </TabView>
          </div>
          <div v-if="activeMain === 1" class="col-12">
            <div class="grid p-3">
              <div class="grid flex justify-content-between items-center m-0">
                <div class="w-full flex justify-content-between">
                  <div class="lg:col-6 col-4">
                    <h4>{{ t('crewUser.users') }}</h4>
                  </div>
                  <div v-if="company.isAuthorized" class="flex justify-content-end">
                    <Button :label="t('crewUser.addCrewUser')" icon="pi pi-plus" class="p-button-text" @click="changeCreateDialog" />
                  </div>
                </div>
                <div class="flex justify-content-end px-2">
                  <SelectButton v-model="active" :options="tabMenu" aria-labelledby="basic" unselectable />
                </div>
              </div>
              <div v-if="active === t('crewUser.activeTab')" class="col-12 h-full">
                <div class="h-full">
                  <ActiveTab
                    :is-authorized="company.isAuthorized"
                    :crew-users="crewUsersActive"
                    :crew-user-select="crewUserSelect"
                    @change-create-dialog="changeCreateDialog"
                    @change-delete-dialog="changeDeleteDialog"
                    @change-update-dialog="changeUpdateDialog"
                    @toggle-menu="
                      (value) => {
                        crewUserSelect = value.crewUser
                      }
                    "
                  />
                </div>
                <div class="h-2rem" />
              </div>
              <div v-if="active === t('crewUser.disabledTab')" class="col-12 h-full p-5" style="background-color: #eff3f8">
                <div class="col-12 h-full">
                  <DisabledTab
                    :is-authorized="company.isAuthorized"
                    :crew-users="crewUsersDisabled"
                    :crew-user-select="crewUserSelect"
                    @change-create-dialog="changeCreateDialog"
                    @change-delete-dialog="changeActiveDialog"
                    @change-update-dialog="changeUpdateDialog"
                    @toggle-menu="
                      (value) => {
                        crewUserSelect = value.crewUser
                      }
                    "
                  />
                </div>
              </div>
              <div class="h-2rem" />
            </div>
          </div>
          <div v-if="activeMain === 0" class="col-12">
            <div class="grid p-3">
              <div class="col-12">
                <h4>{{ t('crewUser.projects') }}</h4>
              </div>

              <div v-for="project of ((projects) as any)" :key="project.id" class="col col-12 sm:col-6 md:col-4">
                <Card style="min-height: 500px">
                  <template #title>
                    <div class="flex justify-content-between cursor-pointer" @click="router.push(`/projects/${project.id}/media`)">
                      <span class="text-overflow-ellipsis white-space-nowrap overflow-hidden">
                        {{ project.projectName }}
                      </span>
                    </div>
                  </template>
                  <template #content>
                    <div class="flex flex-column gap-2">
                      <span class="text-overflow-ellipsis white-space-nowrap overflow-hidden">
                        <i v-if="project.address" class="pi pi-directions"></i>
                        {{ project.address }}
                      </span>
                      <span>
                        <i class="pi pi-hashtag"></i>
                        {{ project.poNumber }}
                      </span>
                      <span>
                        <i v-if="project.projectType" class="pi pi-calendar"></i>
                        {{ project.createdTime ? useDateFormatUtil(project.createdTime, 'MMM D, YYYY') : '--' }}
                      </span>
                      <span>
                        <i v-if="project.projectType" class="pi pi-home"></i>
                        {{ project.projectType }}
                      </span>
                    </div>

                    <div
                      v-for="(review, index) of project.reviews"
                      :key="index"
                      class="flex flex-column gap-1 border-1 mt-3 border-round-lg p-4 border-gray-300 relative review-card"
                      style="background-color: #eff3f8"
                    >
                      <div class="p-card w-full shadow-none flex-column" style="background-color: #eff3f8">
                        <div class="flex justify-content-between align-items-center flex-row gap-1 flex-wrap">
                          <div class="flex align-items-center flex-row gap-1 justify-content-center">
                            <Avatar :image="`https://contacts.zoho.com/file?ID=${review?.user?.zuid}&fs=thumb`" class="mr-2" size="large" shape="circle" />
                            <div class="flex flex-column align-items-center gap-1">
                              <div class="text-lg font-bold">
                                {{ review?.user?.fullName }}
                              </div>
                              <div v-if="review?.user?.profile" class="text-muted font-normal text-sm">
                                {{ review?.user?.profile }}
                              </div>
                            </div>
                          </div>
                          <p class="my-0 pt-2">
                            <Rating :model-value="review.rating" :stars="5" :cancel="false" />
                          </p>
                        </div>
                      </div>
                      <div class="flex flex-content-between pt-2">
                        <label v-if="review.reviewType === 'WORKMANSHIP'" for="workmanship_comments" class="w-full text-sm font-bold">{{ t('reviewsCrew.workmanship') }}</label>
                        <label v-if="review.reviewType === 'TIMELINESS'" for="workmanship_comments" class="w-full text-sm font-bold">{{ t('reviewsCrew.timeliness') }}</label>
                      </div>
                      <p
                        class="overflow-hidden white-space-normal text-overflow-ellipsis description-review"
                        :class="{ 'long-description-review': showAllComment === review.createdAt }"
                        @click="() => (showAllComment = showAllComment === review.createdAt ? null : review.createdAt)"
                      >
                        {{ review.comment }}
                      </p>
                    </div>
                  </template>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </template>
    </card>
    <div class="flex w-full flex-wrap">
      <Dialog v-model:visible="dialogCreateIsActive" :position="!md ? 'bottom' : undefined" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
        <template #header>
          <h5>{{ t('crewUser.addCrewUser') }}</h5>
        </template>
        <NewCrewUser @close-add-dialog="closeDialogCreate" />
      </Dialog>
      <Dialog v-model:visible="dialogUpdateIsActive" :position="!md ? 'bottom' : undefined" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
        <template #header>
          <h5>{{ t('crewUser.updateCrewUser') }}</h5>
        </template>
        <UpdateCrewUser :crew-user="crewUserSelect" @close-add-dialog="closeDialogUpdate" />
      </Dialog>
      <Dialog v-model:visible="dialogDeleteIsActive" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
        <template #header>
          <h5>{{ t('crewUser.deleteCrewUser') }}</h5>
        </template>

        <div class="flex flex-column gap-2">
          <div style="font-size: 1rem; font-weight: 600; margin-bottom: 0.5rem">
            {{ t('crewUser.deleteCrewUserMessage') }}
          </div>
          <div class="flex flex-row gap-2">
            <Button :label="t('crewUser.CrewButtonCancel')" class="p-button-text" @click="changeDeleteDialog" />
            <Button :label="t('crewUser.CrewButtonConfirm')" class="p-button-danger" @click="deleteCrewUser" />
          </div>
        </div>
      </Dialog>
      <Dialog v-model:visible="dialogActiveIsActive" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
        <template #header>
          <h5>{{ t('crewUser.activeCrewUser') }}</h5>
        </template>

        <div class="flex flex-column gap-2">
          <div style="font-size: 1rem; font-weight: 600; margin-bottom: 0.5rem">
            {{ t('crewUser.activeCrewUserMessage') }}
          </div>
          <div class="flex flex-row gap-2">
            <Button :label="t('crewUser.CrewButtonCancel')" class="p-button-text" @click="changeActiveDialog" />
            <Button :label="t('crewUser.CrewButtonConfirm')" class="p-button-danger" @click="activeCrewUser" />
          </div>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .description-review {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Número máximo de líneas a mostrar */
    -webkit-box-orient: vertical;
  }
  .long-description-review {
    -webkit-line-clamp: unset;
  }
  .resetlayout {
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .card {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    color: #6c757d;
    background: #f8f9fa;
    font-weight: 700;
    border-radius: 6px;
    width: 90%;
  }
  .placeholder-crews {
    max-height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
  }

  .review-card::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0px;
    bottom: 4px;
    width: 15px;
    height: 16px;
    clear: both;
    background-color: #eff3f8;
    z-index: 1;
  }

  .review-card::before {
    content: '';
    display: inline-block;
    position: absolute;
    right: -5.3px;
    bottom: 8.5px;
    width: 8px;
    height: 8px;
    clear: both;
    transform: rotate(45deg);
    z-index: 1;
    background-color: #eff3f8;
    border: 1px solid #e0e0e0;
    border-radius: 1px;
  }
</style>
