import { deleteNewIQueuePhoto, getNewIQueuePhotos, newIQueuePhoto, updateNewIQueuePhoto } from '@/db/tables'
import { uploadPhotoBlob } from '@/utils/uploadPhotoBlob'
import { useEventBus } from '@vueuse/core'
import { reactive, ref, toRefs } from 'vue'

export type IeventBus = {
  uploadQueue?: newIQueuePhoto[]
}

const state = reactive({
  queue: [] as newIQueuePhoto[],
})
const uploadNewQueuePhoto = () => {
  const QueuePhotos = state.queue
  const bus = useEventBus<IeventBus>('queuePhoto')
  const imagesupload = ref(false)
  const chargerQueuePhoto = async () => {
    if (QueuePhotos.length > 0) return
    state.queue = await getNewIQueuePhotos()
    bus.emit({
      uploadQueue: QueuePhotos,
    })
  }

  const uploadQueuePhoto = async (photo: newIQueuePhoto) => {
    try {
      state.queue = state.queue.map((item) =>
        item.id === photo.id
          ? {
              ...item,
              status: 'UPLOADING',
            }
          : item
      )
      bus.emit({
        uploadQueue: state.queue,
      })
      await uploadPhotoBlob(photo)
      await updateNewIQueuePhoto({ ...photo, status: 'COMPLETE' })
      bus.emit({
        uploadQueue: state.queue,
      })
      await deleteNewIQueuePhoto(photo.id)
      state.queue = state.queue.filter((item) => item.id !== photo.id)
      bus.emit({
        uploadQueue: state.queue,
      })
    } catch (error) {
      await updateNewIQueuePhoto({
        ...photo,
        status: 'ERROR',
        errorMessage: JSON.stringify(error),
        retries: photo.retries + 1,
      })
      state.queue = state.queue.map((item) => (item.id === photo.id ? { ...item, status: 'ERROR' } : item))
      bus.emit({
        uploadQueue: state.queue,
      })
    }
  }

  const uploadQueuePhotos = async () => {
    imagesupload.value = true
    await chargerQueuePhoto()
    // console.log('uploadQueuePhotos PROJECTS')
    // process all photos in sequence
    for await (const item of state.queue) {
      await uploadQueuePhoto(item).catch((error) => {
        console.error('Error uploading photo:', error)
      })
    }
    imagesupload.value = false
  }

  return { ...toRefs(state), QueuePhotos, chargerQueuePhoto, uploadQueuePhoto, uploadQueuePhotos, imagesupload }
}

export default uploadNewQueuePhoto
