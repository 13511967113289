<script setup lang="ts">
  import { GetListNotifications, Notification, UpdateAllNotifications, UpdateNotification } from '../../services/notifications'
  import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
  import { useUserStore } from '@/store/Account/UserStore'
  import { storeToRefs } from 'pinia'
  import { TypesStatus } from './mock'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import ProgressSpinner from 'primevue/progressspinner'
  import { useRouter } from 'vue-router'

  const skip = ref(0)
  const take = ref(20)
  const userStore = useUserStore()
  const { id: userId } = storeToRefs(userStore)
  const isLoading = ref(false)
  const isFetchingMore = ref(false)
  const hasNextPage = ref(false)
  const listNotifications = ref([] as Notification[][])
  const router = useRouter()
  const content = ref<HTMLDivElement>()
  const emit = defineEmits(['closeDialog'])

  const tabs = {
    media: 'media',
    punch_list: 'punch-list',
    crews: 'assigned-crew',
    notes: 'notes',
    problems: 'problems',
  }

  const fetchNotifications = async (type: 'INITIAL' | 'MORE') => {
    try {
      if (type === 'INITIAL') {
        isLoading.value = true
      } else {
        isFetchingMore.value = true
      }
      const response = await GetListNotifications(userId.value, take.value, skip.value)
      const notifications = response?.data?.data?.items as Notification[]

      hasNextPage.value = response.data?.data?.pagination?.nextPage !== null

      if (type === 'INITIAL') {
        isLoading.value = false
        listNotifications.value = [notifications]
      } else {
        isFetchingMore.value = false
        listNotifications.value = [...listNotifications.value, notifications]
      }
    } catch (error) {
      if (type === 'INITIAL') {
        isLoading.value = false
      } else {
        isFetchingMore.value = false
      }
    }
  }

  onMounted(async () => {
    fetchNotifications('INITIAL')
    if (content?.value) {
      content.value.addEventListener('scroll', () => {
        if (content?.value) {
          if (content.value.scrollTop + content.value.clientHeight >= content.value.scrollHeight - 10) {
            if (!isFetchingMore.value) {
              skip.value = skip.value + 1
            }
          }
        }
      })
    }
  })

  watch([skip, hasNextPage], ([currentSkip, currrentNextPage]) => {
    if (currentSkip !== 0 && currrentNextPage) {
      fetchNotifications('MORE')
    }
  })

  onUnmounted(() => {
    skip.value = 0
    isLoading.value = false
    isFetchingMore.value = false
  })

  const htmlList = computed(() => {
    return listNotifications.value?.flat() as Notification[]
  })

  const handleRedirect = async (url: string, id: string) => {
    try {
      await UpdateNotification(id)
      const normalized = url?.replace('/home', '')?.toLowerCase()
      const parts = normalized.split('?')
      const tab = parts?.[1]?.split('&')

      const first_tab = tab?.find((e) => e?.includes('tab='))?.replace('tab=', '')

      const main_url = parts?.[0]

      router.push(`${main_url}/${tabs[first_tab as keyof typeof first_tab]}`)
      emit('closeDialog', true)
    } catch (error) {
      throw new Error('Error UPDATE Notification ')
    }
  }
  const handleMarkAllAsRead = async () => {
    try {
      await UpdateAllNotifications(userId.value)
      skip.value = 0
      isLoading.value = false
      hasNextPage.value = false
      isFetchingMore.value = false
      listNotifications.value = []
      fetchNotifications('INITIAL')
    } catch (error) {
      throw new Error('Error UPDATE Notifications')
    }
  }
  const handleSync = () => {
    skip.value = 0
    isLoading.value = false
    isFetchingMore.value = false
    hasNextPage.value = false
    listNotifications.value = []
    fetchNotifications('INITIAL')
  }
</script>

<template>
  <div class="flex flex-row justify-content-between pb-2">
    <Button
      class="text-sm"
      :pt="{
        root: {
          style: {
            fontSize: 10,
            border: 'none',
            padding: '5px !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      }"
      style="width: 30px; height: 30px"
      @click="handleSync"
    >
      <i class="pi pi-sync text-white font-bold"></i>
    </Button>
    <Button
      class="text-sm"
      :pt="{
        root: {
          style: {
            fontSize: 10,
            backgroundColor: 'black',
            border: 'none',
            padding: '5px !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      }"
      @click="handleMarkAllAsRead"
    >
      <p class="m-0 p-0 font-bold" style="line-height: 0 !important">Mark all as read</p></Button
    >
  </div>
  <div v-if="isLoading" class="flex align-items-center justify-content-center w-full h-full">
    <ProgressSpinner
      style="width: 20px; height: 20px"
      stroke-width="8"
      :pt="{ circle: { style: { stroke: 'black', animation: 'p-progress-spinner-dash 1.5s ease-in-out infinite' } } }"
    />
  </div>
  <ul ref="content" class="h-20rem max-h-20rem overflow-y-scroll overflow-x-hidden m-0 p-0 flex flex-column gap-4">
    <li v-for="data in htmlList" :key="data.id" class="cursor-pointer" @click="handleRedirect(data?.url, data?.id)">
      <article class="p-0 flex flex-column gap-2">
        <header class="flex flex-row justify-content-between align-items-center">
          <span class="text-sm font-bold">
            {{ data?.title }}
          </span>
          <div v-if="!data?.viewed" style="background-color: #84a9ff; width: 10px; height: 10px; border-radius: 50%"></div>
        </header>
        <div class="flex flex-row gap-2 align-items-center">
          <figure class="bg-black-alpha-90 p-0 m-0 border-round-lg flex align-items-center justify-content-center" style="width: 25px; height: 25px">
            <i class="pi pi-bell text-white font-bold"></i>
          </figure>
          <p class="text-sm">
            {{ TypesStatus[data?.type as keyof typeof TypesStatus]?.label }}
          </p>
        </div>
        <div class="flex flex-column gap-2">
          <p class="m-0 bg-black-alpha-10 p-2 text-sm">
            {{ data?.body }}
          </p>
          <p class="text-xs font-bold">
            {{ useDateFormatUtil(data?.createdAt, 'MMM D, YYYY h:mm A') }}
          </p>
        </div>
      </article>
    </li>
    <li v-if="isFetchingMore" class="flex w-full align-items-center justify-content-center">
      <ProgressSpinner
        style="width: 20px; height: 20px"
        stroke-width="8"
        :pt="{ circle: { style: { stroke: 'black', animation: 'p-progress-spinner-dash 1.5s ease-in-out infinite' } } }"
      />
    </li>
  </ul>
</template>
<style scoped>
  .spinner ::ng-deep .p-progress-spinner-circle {
    stroke: #ffffff !important;
  }
</style>
