<script lang="ts" setup>
  import CrewDirectoryMobile from './CrewDirectoryMobile.vue'
  import CrewDirectoryWeb from './CrewDirectoryWeb.vue'
  import { useBreakpointsStore } from '@/store'
  import { storeToRefs } from 'pinia'
  const breakpointStore = useBreakpointsStore()
  const { lg } = storeToRefs(breakpointStore)
</script>

<template>
  <CrewDirectoryWeb v-if="lg" />
  <CrewDirectoryMobile v-else />
</template>
