<script lang="ts" setup>
  import { toRefs } from 'vue'

  type Iprops = {
    title: string
  }

  const props = defineProps<Iprops>()
  const { title } = toRefs(props)
</script>

<template>
  <h4 v-if="title" class="text-lg font-bold">
    {{ title }}
  </h4>
  <ul role="list" class="flex flex-column close-out-list w-full">
    <slot></slot>
  </ul>
</template>

<style lang="scss">
  .close-out-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 8px;
    gap: 12px;
  }
</style>
