<script lang="ts" setup>
  import { ref, toRefs } from 'vue'
  import Menu from 'primevue/menu'
  import api from '@/services/api'
  import { useToast } from 'primevue/usetoast'
  import { useBreakpointsStore, useProjectStore } from '@/store'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import Button from 'primevue/button'
  import { i18n } from '@/i18n'
  import { IProblem, ProblemStatusEnum } from '@/interfaces/problem'
  import { storeToRefs } from 'pinia'
  import { getPermission, getPermissionsUser } from '@/components/PermissionRole/funtions'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import Lightgallery from '@/components/LightgalleryComponent.vue'
  import { IQueuePhoto } from '@/db/tables'
  import { captureException } from '@sentry/vue'
  import ProjectProblemCardDescription from '@/components/ProjectProblemCard/ProjectProblemCardDescription.vue'
  import ProjectProblemPhoto from '@/components/ProjectProblemCard/ProjectProblemPhoto.vue'
  import { ICamera } from '@/components/PunchListCamera/Camera/CameraContainer.vue'
  import { useEventBus } from '@vueuse/core'
  import useThumbnail from '@/composables/useThumbnail'
  import ImageComponent from '@/components/ImageComponent.vue'
  import { uploadPhotosBlob } from '@/utils/uploadPhotoBlob'
  import { base64ToBlob } from '@/utils/base64Toblob'

  interface Props {
    problem: IProblem
    projectId: string
  }

  const emit = defineEmits(['updateProblem', 'removeProblem', 'showProblem'])
  const props = defineProps<Props>()
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const projectStore = useProjectStore()
  const { project } = storeToRefs(projectStore)
  const { problem } = toRefs(props)
  const camera = useEventBus<ICamera>('camera')
  const toast = useToast()
  const displayConfirmation = ref(false)
  const deleteLoading = ref(false)
  const dialogPhotosVisible = ref(false)
  const { url, handleError, handleLoad } = useThumbnail(problem.value.projectPhotos[0])

  const allMenuItems = [
    { label: i18n.global.t('projectProblem.open'), value: 'OPEN', command: markOpen, nodoname: 'projectsProblemsPermissions', keyname: 'closed' },
    { label: i18n.global.t('projectProblem.inProgress'), value: 'IN_PROGRESS', command: markInProgress, nodoname: 'projectsProblemsPermissions', keyname: 'closed' },
    { label: i18n.global.t('projectProblem.closed'), value: 'CLOSED', command: startClosedProcess, nodoname: 'projectsProblemsPermissions', keyname: 'closed' },
    {
      label: i18n.global.t('projectProblem.delete'),
      value: 'DELETE',
      command: toggleConfirmation,
      nodoname: 'projectsProblemsPermissions',
      keyname: 'delete',
      class: 'text-red',
      style: { color: 'red' },
    },
  ]

  interface IMenuItem {
    label?: string
    value?: string
    command?: () => void | Promise<void>
    nodoname: string
    keyname: string
    class?: string
    style?: any
  }

  const filteredMenuItems = (allItems: IMenuItem[], problemStatus: ProblemStatusEnum) => {
    return allItems.filter((item) => item.value !== problemStatus).filter((item) => getPermission(getPermissionsUser(), item?.keyname, item?.nodoname))
  }

  const menu = ref()
  const menuItems = ref(filteredMenuItems(allMenuItems, problem.value.problemStatus))

  function updateMenuItems() {
    menuItems.value = filteredMenuItems(allMenuItems, problem.value.problemStatus)
  }

  const closeCamera = async (photos: IQueuePhoto[]) => {
    if (photos.length === 0) return

    const photoUpload = await uploadPhotosBlob(
      photos.map((photo) => {
        const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
        return { ...photo, file: blob }
      })
    )
    await markClosed(photoUpload.map((photo) => photo.id))
  }

  async function markOpen() {
    try {
      const payload = { problemStatus: 'OPEN' }
      const {
        data: { data: resp },
      } = await api.patch(`/Api/Problem/${problem.value.id}`, payload)

      emit('updateProblem', resp)
      updateMenuItems()
      toast.add({ severity: 'success', summary: i18n.global.t('projectProblem.success'), detail: i18n.global.t('projectProblem.successMarkedOpen'), life: 3000 })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: i18n.global.t('projectProblem.error'), detail: i18n.global.t('projectProblem.errorMarkedOpen'), life: 3000 })
    }
  }

  async function markInProgress() {
    try {
      const payload = { problemStatus: 'IN_PROGRESS' }

      const {
        data: { data: resp },
      } = await api.patch(`/Api/Problem/${problem.value.id}`, payload)

      emit('updateProblem', resp)

      updateMenuItems()
      toast.add({ severity: 'success', summary: i18n.global.t('projectProblem.success'), detail: i18n.global.t('projectProblem.successMarkedInProgress'), life: 3000 })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: i18n.global.t('projectProblem.error'), detail: i18n.global.t('projectProblem.errorMarkedInProgress'), life: 3000 })
    }
  }

  function toggleConfirmation() {
    displayConfirmation.value = !displayConfirmation.value
  }

  async function confirmDeletion() {
    try {
      deleteLoading.value = true
      await api.delete(`/Api/Problem/${problem.value.id}`)
      emit('removeProblem', problem.value.id)
      toast.add({ severity: 'success', summary: i18n.global.t('projectProblem.success'), detail: i18n.global.t('projectProblem.successDeleted'), life: 3000 })
      deleteLoading.value = false
      toggleConfirmation()
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: i18n.global.t('projectProblem.error'), detail: i18n.global.t('projectProblem.errorDeleting'), life: 3000 })
    }
  }

  function startClosedProcess() {
    camera.emit({ propsCamera: { projectId: project.value.id, photoType: PhotoTypeEnum.PROBLEM }, actions: { closeCamera: closeCamera } })
  }

  async function markClosed(photosid: string[]) {
    try {
      const payload = { problemStatus: 'CLOSED', photoAfterIds: photosid }

      const {
        data: { data: resp },
      } = await api.patch(`/Api/Problem/${problem.value.id}`, payload)

      emit('updateProblem', resp)

      toast.add({ severity: 'success', summary: i18n.global.t('projectProblem.success'), detail: i18n.global.t('projectProblem.successMarkedClosed'), life: 3000 })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: i18n.global.t('projectProblem.error'), detail: i18n.global.t('projectProblem.errorMarkedClosed'), life: 3000 })
    }
  }

  function toggleMenu(event: MouseEvent) {
    if (menu.value && menu.value.toggle) {
      menu.value.toggle(event)
    } else if (menu.value && menu.value[0] && menu.value[0].toggle) {
      menu.value[0].toggle(event)
    }
    event.preventDefault()
    event.stopPropagation()
  }

  const handleClickRedirect = async () => {
    emit('showProblem', props.problem)
  }
</script>

<template>
  <div class="col col-12 md:col-4 cursor-pointer" @click="handleClickRedirect">
    <Card style="min-height: 360px">
      <template #header>
        <div class="relative">
          <PermissionRole v-if="project.isAuthorized && !project.isClosed" nodo-name="projectsProblemsPermissions" key-name="options">
            <div class="absolute" style="top: 8px; right: 8px; z-index: 1">
              <Button severity="primary" plain icon="pi pi-ellipsis-v" @click="toggleMenu" />
              <Menu ref="menu" :model="menuItems" :popup="true" />
            </div>
          </PermissionRole>
        </div>
        <figure class="mx-0" style="height: 150px">
          <ImageComponent
            :url="problem.projectPhotos.length > 0 ? url : 'https://via.placeholder.com/300x300?text=No+image'"
            style="max-height: 150px; object-fit: cover; width: 100%; border-top-left-radius: 10px; border-top-right-radius: 10px"
            @onerror="handleError"
            @onload="handleLoad"
          />
        </figure>
      </template>
      <template #content>
        <ProjectProblemCardDescription :problem="problem" />
      </template>
    </Card>
  </div>
  <Dialog
    v-model:visible="displayConfirmation"
    :position="!md ? 'bottom' : undefined"
    :header="i18n.global.t('projectProblem.confirmDeletion')"
    class="w-full m-0"
    style="max-width: 36rem"
    :modal="true"
    :dismissable-mask="true"
    :draggable="false"
  >
    <div class="confirmation-content">
      <span>{{ $t('projectProblem.areYouSure') }}</span>
    </div>
    <template #footer>
      <Button :label="i18n.global.t('projectProblem.cancel')" class="p-button-outlined" @click="toggleConfirmation" />
      <Button :label="i18n.global.t('projectProblem.confirm')" class="p-button-danger" @click="confirmDeletion" />
    </template>
  </Dialog>

  <Dialog v-model:visible="dialogPhotosVisible" :header="'photos'" class="w-full m-0" style="max-width: 80vw" :modal="true" :draggable="false">
    <div class="flex w-full p-2 flex-column align-items-center">
      <Lightgallery
        id="problem-photos"
        class="grid w-full"
        :photos="
          problem.projectPhotos.map((photo) => ({
            url: photo.url,
            id: photo.id,
          }))
        "
      >
        <a
          v-for="photo in problem?.projectPhotos ?? []"
          :key="photo.id"
          :data-src="photo.url"
          class="flex masonry-item-container p-2 col col-12 sm:col-6 md:col-4 lg-item p-0 justify-content-center"
        >
          <ProjectProblemPhoto :photo="photo" />
        </a>
      </Lightgallery>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 300px;
    width: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
</style>
