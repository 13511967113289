import { TShapeStickerPath } from '../shapes/types'

export const stickers: TShapeStickerPath[] = [
  'approved',
  'check',
  'circle_blue',
  'circle_green',
  'circle_red',
  'circle_warning',
  'end',
  'finished',
  'start',
  'thumbsdown',
  'thumbsup',
  'warning',
  'x',
]
