import { reactive, toRefs } from 'vue'

export const colors = [
  '#e9f300',
  '#f32d00',
  '#00f310',
  '#00f3d7',
  '#000cf3',
  '#ae00f3',
  '#f30075',
  '#e9e9e9',
  '#FF0000',
  '#0000FF',
  '#008000',
  '#FFFF00',
  '#FFA500',
  '#800080',
  '#FFC0CB',
  '#A52A2A',
  '#808080',
  '#FFFFFF',
  '#40E0D0',
  '#00FFFF',
  '#FFD700',
  '#DC143C',
  '#8B4513',
  '#B22222',
  '#ADFF2F',
  '#FF1493',
  '#1E90FF',
]
const state = reactive({
  color: '#FFFF00',
})

function SET_COLOR(newColor: string) {
  state.color = newColor
}

export function useColor() {
  return {
    ...toRefs(state),
    SET_COLOR,
  }
}
