export const photoTypeOptions = [
  {
    value: 'PREPRODUCTION_PICTURES',
    label: 'photoType.PREPRODUCTION_PICTURES',
    severity: 'info',
  },
  {
    value: 'PROGRESS_PICTURES',
    label: 'photoType.PROGRESS_PICTURES',
    severity: 'info',
  },
  // {
  //   value: 'TASK',
  //   label: 'photoType.TASK',
  //   severity: 'warning',
  // },
  // {
  //   value: 'TASK_LIST',
  //   label: 'photoType.TASK_LIST',
  //   severity: 'warning',
  // },
  {
    value: 'TASK_AFTER',
    label: 'photoType.TASK_AFTER',
    severity: 'warning',
  },
  {
    value: 'TASK_BEFORE',
    label: 'photoType.TASK_BEFORE',
    severity: 'warning',
  },
  // {
  //   value: 'TASK_PICTURES',
  //   label: 'photoType.TASK_PICTURES',
  //   severity: 'warning',
  // },
  {
    value: 'PROBLEM',
    label: 'photoType.PROBLEM',
    severity: 'danger',
  },
  {
    value: 'FINAL_PICTURES',
    label: 'photoType.FINAL_PICTURES',
    severity: 'info',
  },
  // {
  //   value: 'PROFILE_PICTURES',
  //   label: 'photoType.PROFILE_PICTURES',
  //   severity: 'info',
  // },
]
