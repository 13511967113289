// composables/useStage.js

import { reactive, toRefs } from 'vue'

// Tu tipo TStage
export type TStage = {
  width: number
  height: number
}

// Tu tipo TIMAGE
export type TIMAGE = {
  scale: number
  scale_x: number
  scale_y: number
  image: HTMLImageElement
  width: number
  height: number
  original_width: number
  original_height: number
  x: number
  y: number
  rotation: number
}

// Tipo Store
type Store = {
  stage: TStage
  image: TIMAGE
}

// Función calculateDimension
export const calculateDimension = (mode: 'PORTRAIT' | 'LANDSCAPE' = 'PORTRAIT', screenWidth: number, screenHeight: number, width: number, height: number) => {
  const imgWidth = width
  const imgHeight = height

  const isLandscape = mode === 'LANDSCAPE'
  const refWidth = isLandscape ? imgHeight : imgWidth
  const refHeight = isLandscape ? imgWidth : imgHeight

  const sx = screenWidth / refWidth
  const sy = screenHeight / refHeight
  const scale = Math.min(sx, sy)

  const newWidth = imgWidth * scale
  const newHeight = imgHeight * scale
  const x = (screenWidth - newWidth) / 2
  const y = (screenHeight - newHeight) / 2

  return {
    x,
    y,
    width: Number.isNaN(newWidth) ? 0 : newWidth,
    height: Number.isNaN(newHeight) ? 0 : newHeight,
    original_image_width: imgWidth,
    original_image_height: imgHeight,
    sx,
    sy,
    scale,
  }
}

// Estado reactivo global
const state = reactive<Store>({
  stage: {
    width: 0,
    height: 0,
  },
  image: {
    scale: 1,
    scale_x: 0,
    scale_y: 0,
    image: null as unknown as HTMLImageElement,
    width: 0,
    height: 0,
    original_width: 0,
    original_height: 0,
    x: 0,
    y: 0,
    rotation: 0,
  },
})

// Getters
function GET_STAGE_DIMENSIONS() {
  return state.stage
}

function GE_IMAGE_DIMENSIONS() {
  return state.image
}

// Actions
function SET_ROTATE(r: number) {
  state.image.rotation = r
  SET_DIMENSION()
}

function SET_STAGE_DIMENSIONS(s: TStage) {
  state.stage = s
}

function SET_DIMENSION() {
  const {
    x,
    y,
    sx,
    sy,
    width: newWidth,
    height: newHeight,
    scale,
  } = calculateDimension(
    [90, 270].includes(state.image.rotation) ? 'LANDSCAPE' : 'PORTRAIT',
    state.stage.width,
    state.stage.height,
    state.image.original_width,
    state.image.original_height
  )

  state.image = {
    ...state.image,
    scale,
    x,
    y,
    width: newWidth,
    height: newHeight,
    scale_x: sx,
    scale_y: sy,
  }
}

function SET_IMAGE_DIMENSIONS(image: HTMLImageElement) {
  const imgWidth = image.naturalWidth
  const imgHeight = image.naturalHeight

  state.image = {
    ...state.image,
    image,
    original_height: imgHeight,
    original_width: imgWidth,
  }
  SET_DIMENSION()
}

function SET_ROTATE_IMAGE() {
  const pr = state.image.rotation
  state.image.rotation = pr + 90 === 360 ? 0 : pr + 90
  SET_DIMENSION()
}

export function useStage() {
  return {
    ...toRefs(state),
    GET_STAGE_DIMENSIONS,
    GE_IMAGE_DIMENSIONS,
    SET_ROTATE,
    SET_STAGE_DIMENSIONS,
    SET_DIMENSION,
    SET_IMAGE_DIMENSIONS,
    SET_ROTATE_IMAGE,
  }
}
