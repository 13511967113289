<script lang="ts" setup>
  import { onMounted, ref, toRefs, watch } from 'vue'
  import { TShapeProps } from '../store/shapes'
  import { KonvaEventObject } from 'konva/lib/Node'
  import Konva from 'konva'
  import ActionOptions from './ActionOptions.vue'
  import ActionCircles from './ActionCircles.vue'
  const props = defineProps<TShapeProps>()
  const shapeRef = ref<Konva.LineConfig>()
  const { shape, image, device, draggable } = toRefs(props)
  const emits = defineEmits(['ondragstart', 'ondragmoving', 'ondragstop', 'onselectshape', 'ondelete', 'oncopy'])
  const textWidthShape = ref(0)
  const textHeightShape = ref(0)
  const initialScale = ref(0)
  const savedFontSize = ref(0)

  const handleSelect = () => {
    shape.value.points = [0, 0, textWidthShape.value * 2, 0]
    emits('onselectshape', shape.value)
  }
  const handleDragStart = () => {
    emits('ondragstart', shape.value)
  }
  const handleDragMove = (e: KonvaEventObject<Event>) => {
    shape.value.x = e?.target?.x() / image.value?.scale
    shape.value.y = e?.target?.y() / image.value?.scale
    emits('ondragmoving', shape.value)
  }
  const handleDragStop = () => {
    emits('ondragstop', shape.value)
  }

  const imageStickerShadow = new Image()
  imageStickerShadow.src = `/stickers/shadow/${shape?.value.pathSticker}.png`
  const imageStickerDiff = new Image()
  imageStickerDiff.src = `/stickers/diff/${shape?.value.pathSticker}.png`

  const handleGetDimension = () => {
    textWidthShape.value = shapeRef.value?.getNode().width()
    textHeightShape.value = shapeRef.value?.getNode().height()
  }
  const handleDragStarPoint = (circlePosition: number, x: number, y: number) => {
    const x1 = shape.value.points[0]
    const y1 = shape.value.points[1]
    const x2 = x
    const y2 = y
    const scale = image.value.scale
    const currentDistance = Math.sqrt(Math.pow(x2 * scale - x1 * scale, 2) + Math.pow(y2 * scale - y1 * scale, 2))
    initialScale.value = currentDistance
    savedFontSize.value = shape.value.fontSize
  }
  const handleDragMovePoint = (circlePosition: number, x: number, y: number) => {
    if (circlePosition === 0) {
      shape.value.x = x / image.value.scale
      shape.value.y = y / image.value.scale
      return
    }
    if (circlePosition === 2) {
      const dx = x / image.value.scale - shape.value.x
      const dy = y / image.value.scale - shape.value.y

      // Calcula la nueva anchura y altura basándote en las diferencias
      const newWidth = Math.abs(dx * 2)
      // const newHeight = Math.abs(dy * 2)

      shape.value.width = newWidth
      shape.value.height = newWidth

      // Calcula el nuevo ángulo de rotación
      const angleRadians = Math.atan2(dy, dx)
      const angleDegrees = angleRadians * (180 / Math.PI)
      shape.value.rotation.rotation = angleDegrees

      // Ajusta la posición central del shape
      shape.value.points = [0, 0, dx, dy]

      handleGetDimension()
    }
  }

  onMounted(handleGetDimension)
  watch([image, shape], handleGetDimension)
</script>

<template>
  <v-image
    :id="`sticker-shadow-${shape?.id}`"
    :draggable="false"
    :config="{
      x: shape?.x * image?.scale + device.shadow_range,
      y: shape?.y * image?.scale + device.shadow_range,
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: draggable ? 2 : 0,
      width: shape.width * image?.scale,
      height: shape.height * image?.scale,
      rotation: shape.rotation?.rotation,
      image: imageStickerShadow,
      offsetX: textWidthShape / 2,
      offsetY: textHeightShape / 2,
    }"
  ></v-image>
  <v-image
    :id="shape?.id"
    ref="shapeRef"
    :draggable="draggable"
    :config="{
      x: shape?.x * image?.scale,
      y: shape?.y * image?.scale,
      fill: 'transparent',
      stroke: shape?.color,
      strokeWidth: draggable ? 2 : 0,
      width: shape.width * image?.scale,
      height: shape.height * image?.scale,
      rotation: shape.rotation?.rotation,
      image: imageStickerDiff,
      offsetX: textWidthShape / 2,
      offsetY: textHeightShape / 2,
    }"
    @click="handleSelect"
    @tap="handleSelect"
    @touchstart="handleDragStart"
    @touchmove="handleDragMove"
    @touchend="handleDragStop"
    @dragstart="handleDragStart"
    @dragmove="handleDragMove"
    @dragend="handleDragStop"
  ></v-image>
  <ActionOptions
    :options="['copy', 'delete']"
    :show="isSelected"
    :x="shape.x * image.scale - textWidthShape / 2"
    :y="shape.y * image.scale - textHeightShape / 2 - 50"
    @delete="() => emits('ondelete', shape)"
    @copy="() => emits('oncopy', shape)"
  />
  <ActionCircles
    v-if="isSelected"
    :id="shape?.id"
    :color="shape.color"
    :keyshape="'sticker'"
    :positions="[0, 2]"
    :points="() => shape.points?.map?.((e) => e * image.scale)"
    :xs="shape.x * image.scale"
    :ys="shape.y * image.scale"
    @ondragstart="({ circlePoint, x, y }) => handleDragStarPoint(circlePoint, x, y)"
    @ondragmoving="({ circlePoint, x, y }) => handleDragMovePoint(circlePoint, x, y)"
    @ondragstop="handleDragStop"
  />
</template>
