import { reactive, toRefs } from 'vue'

const store = reactive({
  dialogSearch: false,
})

const handleChange = (state: boolean) => {
  store.dialogSearch = state
}

export const useSearchDialog = () => {
  return {
    ...toRefs(store),
    handleChange,
  }
}
