import { ProjectStageEnum, ProjectTypeEnum } from '@/models/project'
import { defineStore } from 'pinia'
import { ProjectInterface } from '@/models/project'
import api from '@/services/api'
import { TPhotoContent } from '@/constants/types'
import { IActivePunchList, IPunchList } from '@/interfaces/punch-list/punch-list.interface'
// import { ITaskList } from '@/interfaces/punch-list/task-list/task-list.interface'
// import { ITask } from '@/interfaces/punch-list/task/task.interface'
import { ILead, IProject } from '@/interfaces/project/project.interface'
import { captureException } from '@sentry/vue'
import { IProblem } from '@/interfaces/problem'
import { ShareMediaPayload } from '@/interfaces/photo/photo.interface'
import { IAssignedCrews } from '@/store'
import { delay } from '@/utils/delay'
import { splitQueryParams } from '@/utils/splitQueryParams'

export interface ILeadInterface extends ProjectInterface {
  customerNumber?: string
}
interface IWorkContacts {
  id: string
  fullName: string
  email?: string
  phone: string
  profile: string
  photoUrl?: string
  crewCompany: string
  zuid?: string
}

interface IFilterParams {
  skip?: number | null
  take?: number | null
  sort?: string | null
  projectType?: string | null
  projectStage?: string | null
}

interface IFilterLeads {
  startDate?: string
  endDate?: string
  skip?: number | null
  take?: number | null
}

interface IPagination {
  pageSize: number
  pageNumber: number
  pageCount: number
  rowCount: number
  nextPage: string | null
}
interface LeadStore {
  loadingProjects: boolean
  loadingProject: boolean
  loadingProjectMedia: boolean
  loadingProjectPunchList: boolean
  loadingProjectDocuments: boolean
  loadingProjectAssignedCrews: boolean
  loadingProjectWorkContacts: boolean
  pagination: IPagination
  paginationMedia: IPagination
  projects: ILead[]
  projectsNear: ILead[]
  project: ILeadInterface
  projectMedia: TPhotoContent[]
  projectPunchList: IPunchList[]
  activePunchList: IActivePunchList | null
  activeTaskListId: string | null
  projectDocuments: []
  projectProblems: IProblem[]
  projectAssignedCrew: IAssignedCrews[]
  projectWorkContacts: IWorkContacts[]
  error: null | any
}

export type IntialFilter = { uploadType: string | null; photoType: string | null; userId: string | null; startDate: string | null; endDate: string | null }

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const useLeadStore = defineStore({
  id: 'Leads',
  state: (): LeadStore => ({
    loadingProjects: false,
    loadingProject: false,
    loadingProjectMedia: false,
    loadingProjectPunchList: false,
    loadingProjectDocuments: false,
    loadingProjectAssignedCrews: false,
    loadingProjectWorkContacts: false,
    pagination: {
      pageSize: 0,
      pageNumber: 0,
      pageCount: 0,
      rowCount: 0,
      nextPage: '',
    },
    projects: [],
    projectsNear: [],
    projectProblems: [],
    project: {} as ILeadInterface,
    projectMedia: [] as TPhotoContent[],
    paginationMedia: {
      pageSize: 0,
      pageNumber: 0,
      pageCount: 0,
      rowCount: 0,
      nextPage: '',
    },
    projectPunchList: [] as IPunchList[],
    activePunchList: null,
    activeTaskListId: null,
    projectDocuments: [],
    projectAssignedCrew: [] as IAssignedCrews[],
    projectWorkContacts: [] as IWorkContacts[],
    error: null,
  }),

  getters: {
    getProject: (state): ProjectInterface => state.project,
    getProjectMedia: (state): TPhotoContent[] => state.projectMedia,
    getProjectPunchList: (state): IPunchList[] => state.projectPunchList,
    getProjectDocuments: (state): [] => state.projectDocuments,
    getProjectAssignedCrew: (state): IAssignedCrews[] => state.projectAssignedCrew,
    getProjectsNear: (state): IProject[] => state.projectsNear,
    getProjectWorkContacts: (state): IWorkContacts[] => state.projectWorkContacts,
  },

  actions: {
    async setDefaultProjects() {
      console.log('setDefaultProjects')
      this.projects = []
      await delay(1000)
      console.log('setDefaultProjects done', this.projects)
    },
    async fetchLeads(params: IFilterLeads): Promise<void> {
      try {
        this.loadingProjects = true

        const response = await api.get(`/Api/v2/Leads/Schedule`, { params })

        this.pagination = response.data.data.pagination
        if (this.pagination.rowCount === 0) {
          this.projects = []
        } else {
          this.projects = [...this.projects, ...response.data.data.items]
        }
      } catch (err) {
        captureException(err)
        this.error = err
      } finally {
        this.loadingProjects = false
      }
    },
    async fetchProjects(filterParams?: IFilterParams): Promise<void> {
      try {
        this.loadingProjects = true
        const params = []
        // if (latitude) params.push(`&latitude=${latitude}`)
        // if (longitude) params.push(`&longitude=${longitude}`)
        if (filterParams && filterParams.sort) params.push(`&sort=${filterParams.sort}`)
        params.push(`&filter.ProjectType=${filterParams?.projectType ?? 'ALL'}`)
        // params.push(
        //   `&filter.ProjectStage=${filterParams?.projectStage ?? 'ALL'}`
        // )
        if (filterParams && filterParams.skip) params.push(`&skip=${filterParams?.skip}`)
        if (filterParams && filterParams.take) params.push(`&take=${filterParams?.take}`)

        const response = await api.get(`/Api/v2/Leads?${params.join('')}`)

        this.pagination = response.data.data.pagination
        this.projects = [...this.projects, ...response.data.data.items]
      } catch (err) {
        captureException(err)
        this.error = err
        console.log(err)
      } finally {
        this.loadingProjects = false
      }
    },
    async fetchProjectById(id: string): Promise<void> {
      try {
        this.loadingProjects = true
        const response = await api.get(`/Api/Leads/${id}`)
        this.project = response.data.data as ProjectInterface
      } catch (err) {
        captureException(err)
        console.log(err)
        this.error = err
      } finally {
        this.loadingProjects = false
      }
    },
    async fetchProjectsNear(): Promise<void> {
      console.log(1)
    },
    async addPhotoToProject(payload: any) {
      if (this.project.id && this.project.id !== payload.projectId) this.project.photos.push(payload)

      const index = this.projects.findIndex((p) => p.id === payload.projectId)
      if (index === -1) return

      const updateIndex = this.projects[index].photos.findIndex((p: any) => {
        if (p?.url) {
          if (p.url.includes(payload?.fileName)) return true
        } else {
          if (p?.fileName) {
            if (payload.url.includes(p?.fileName)) return true
          }
        }
        return false
      })
      if (updateIndex === -1) this.projects[index].photos.push(payload)
      else this.projects[index].photos[updateIndex] = payload
    },
    setProjectMedia(media: TPhotoContent[]) {
      this.projectMedia = media
    },
    async injectPhotoIntoProjectMedia(payload: TPhotoContent): Promise<void> {
      this.projectMedia = [...this.projectMedia, payload]
    },
    async setActivePunchList(apl: IActivePunchList | null): Promise<void> {
      this.activePunchList = apl
    },
    async setActiveTaskListId(id: string | null): Promise<void> {
      if (id === null) {
        this.activeTaskListId = null
      } else {
        this.activeTaskListId = id
      }
    },
    setDefaultProjectsMedia() {
      this.projectMedia = []
    },
    async fetchProjectMedia(id: string, filters?: { media?: IntialFilter; skip: number; take: number }): Promise<void> {
      try {
        this.loadingProjectMedia = true

        // const filter = filters?.photoType?.length
        //   ? `?photoType=${filters?.photoType}&take=${filters?.take || 20}&skip=${filters?.skip || 0}`
        //   : `?take=${filters?.take || 20}&skip=${filters?.skip || 0}`
        const query = splitQueryParams(filters?.media)
        const filter = `?take=${filters?.take || 20}&skip=${filters?.skip || 0}&timeZone=${timezone}${query}`
        const res = await api.get(`/Api/v2/Leads/Photos/${id}${filter}`)
        const data = res?.data?.data.photos
        this.paginationMedia = res.data.data.pagination
        this.projectMedia = [...this.projectMedia, ...data]
      } catch (e) {
        captureException(e)
        console.log(e)
      } finally {
        this.loadingProjectMedia = false
      }
    },
    async fetchAssignedCrews(id: string): Promise<void> {
      try {
        this.loadingProjectAssignedCrews = true
        const response = await api.get(`/Api/Projects/CrewCompanies/Project/${id}`)
        this.projectAssignedCrew = response.data.data
        this.loadingProjectAssignedCrews = false
      } catch (e) {
        captureException(e)
        console.log(e)
      } finally {
        this.loadingProjectAssignedCrews = false
      }
    },
    async fetchWorkContacts(): Promise<void> {
      try {
        this.loadingProjectWorkContacts = true
        const response = await api.get(`/api/Users/Mention`)
        this.projectWorkContacts = response.data.data
        this.loadingProjectWorkContacts = false
      } catch (e) {
        captureException(e)
        console.log(e)
      } finally {
        this.loadingProjectWorkContacts = false
      }
    },
    async shareMedia(payload: ShareMediaPayload): Promise<any> {
      try {
        return await api.post(`/Api/Photos/Share`, payload)
      } catch (e) {
        captureException(e)
      }
    },
    destroy() {
      this.loadingProjects = false
      this.loadingProject = false
      this.loadingProjectMedia = false
      this.loadingProjectPunchList = false
      this.loadingProjectDocuments = false
      this.loadingProjectAssignedCrews = false
      this.loadingProjectWorkContacts = false
      this.projects = []
      this.projectsNear = []
      this.project = {
        id: '',
        projectName: '',
        name: '',
        address: '',
        street: '',
        state: '',
        zipCode: '',
        city: '',
        isClosed: false,
        ownerManager: null,
        projectManager: null,
        contact: {
          email: '',
          firstName: '',
          fullName: '',
          lastName: '',
          mobile: '',
          phone: '',
        },
        marketLocation: {
          id: '',
          name: '',
          address: '',
          phone: '',
          website: '',
        },
        paymentType: null,
        projectNumber: '',
        projectType: ProjectTypeEnum.NONE,
        amount: '',
        projectStage: ProjectStageEnum.NONE,
        photos: [],
        punchLists: null,
        crewCompanies: null,
        latitude: 0,
        longitude: 0,
        estimationLink: null,
        productionLink: null,
        contractLink: null,
        // distance?:        Distance;
        duration: undefined,
        actualStartDate: undefined,
        isAuthorized: false,
        poNumber: '',
      }
      this.projectMedia = []
      this.projectPunchList = []
      this.projectDocuments = []
      this.projectAssignedCrew = []
      this.projectWorkContacts = []
      this.error = null
    },
  },
})
