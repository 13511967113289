import { toRefs, watch } from 'vue'
import { useShapes } from '../store/shapes'

export const useOrder = () => {
  const shapeStore = useShapes()
  const { handleDeleteOrderShape, handleOrderShape } = shapeStore
  const { shapeId, shapes } = toRefs(shapeStore)
  watch(shapeId, (currentShapeId) => {
    if (!currentShapeId) return
    if (!shapes.value[currentShapeId]) return

    const shape = { ...shapes.value[currentShapeId] }

    handleDeleteOrderShape(shape)
    setTimeout(() => {
      handleOrderShape(shape)
    }, 10)
  })
}
