export const MapIndexMX = {
  DEFAULT: 'Todos',
  READY_FOR_PRODUCTION: 'Listo para producción',
  COMPLETE: 'Completados',
  IN_PRODUCTION: 'En producción',
  WILL_CALLED_ORDERED: 'Llamada ordenada',
  PM_APPROVED: 'Aprobado por PM',
  SCHEDULE_PRE_WALK_THROUGH: 'Programado para una visita previa',
  PROJECT_SCHEDULED: 'Proyectos programado',
  TODAY: 'Hoy',
  ALL: 'Todos',
  PO_REJECTED_BY_PM: 'PO rechazado por PM',
  READY_TO_VERIFY: 'Listo Para verificar',
  CORRECTED_PO_AWAITING_APPROVAL: 'PO Corregido pendiente de aprobacion',
  SECOND_PO_REJECTED: 'Segundo PO rechazado',
  CONTRACT_VERIFIED: 'Contrato verificado',
  MEASURE: 'Medir',
  MEASURE_SCHEDULED: 'Medida prevista',
  THIS_WEEK: 'Esta semana',
  THIS_MONTH: 'Este Mes',
  THIS_YEAR: 'Este año',
  NONE: 'No hay informacion',
  LAST_30_DAYS: 'Ultimos 30 dias',
  LABEL: 'Selecciona una opcion',
  VIEW_PROJECT: 'Ver proyecto',
  VIEW_LEAD: 'Ver lead',
  PHOTOS: 'No hay fotos en el proyecto',
  PHOTOS_LEAD: 'No hay fotos en leads',
  BUTTON_PROJECT: 'Proyectos',
  BUTTON_TOOLTIP_PROJECT: 'Abrir Proyecto',
  BUTTON_TOOLTIP_LEAD: 'Abrir lead',
  DIALOG_NATIVE_MAP_TITLE: 'Mas opciones',
}

export const MapIndexUS = {
  DEFAULT: 'All',
  ALL: 'All',
  READY_FOR_PRODUCTION: 'Ready for production',
  CORRECTED_PO_AWAITING_APPROVAL: 'Corrected PO awaiting approval',
  READY_TO_VERIFY: 'Ready to verify',
  MEASURE: 'Measure',
  SECOND_PO_REJECTED: 'Second po rejected',
  MEASURE_SCHEDULED: 'Measure scheduled',
  PO_REJECTED_BY_PM: 'PO rejected by PM',
  CONTRACT_VERIFIED: 'Contract Verified',
  COMPLETE: 'Completed',
  IN_PRODUCTION: 'In Production',
  WILL_CALLED_ORDERED: 'Will called ordered',
  PM_APPROVED: 'PM approved',
  SCHEDULE_PRE_WALK_THROUGH: 'Schedule pre walk through',
  PROJECT_SCHEDULED: 'Project Scheduled',
  TODAY: 'Today',
  NONE: 'No information available',
  THIS_WEEK: 'This Week',
  THIS_MONTH: 'This Month',
  THIS_YEAR: 'This Year',
  LAST_30_DAYS: 'Last 30 days',
  LABEL: 'Select an option',
  VIEW_PROJECT: 'View Project',
  VIEW_LEAD: 'View lead',
  PHOTOS: "There aren't photos in this project",
  PHOTOS_LEAD: "There aren't photos in this lead",
  BUTTON_PROJECT: 'Projects',
  BUTTON_TOOLTIP_PROJECT: 'Open project',
  BUTTON_TOOLTIP_LEAD: 'Open lead',
  DIALOG_NATIVE_MAP_TITLE: 'More options',
}
