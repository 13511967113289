export const scheduleIndexEnUS = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  today: 'Today',
}

export const scheduleIndexEsMX = {
  day: 'Día',
  week: 'Semana',
  month: 'Mes',
  today: 'Hoy',
}
