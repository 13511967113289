<script setup lang="ts"></script>

<template>
  <Button :label="$t('projectId.shareImages')" :icon="'pi pi-share-alt'" class="p-button-text uppercase ButtonShare" v-bind="$attrs"> </Button>
</template>
<style>
  @media (max-width: 494px) {
    .ButtonShare {
      font-size: 0px;
    }
  }
</style>
