<script lang="ts" setup>
  import { toRefs } from 'vue'

  import { useDraw } from './store/draw'
  import { useColor } from './store/color'
  import { device } from './mocks/device'
  import { ImageStage } from './store/shapes'

  type Props = {
    image: ImageStage
  }
  const props = defineProps<Props>()
  const drawStore = useDraw()

  const colorStore = useColor()
  const { color } = toRefs(colorStore)
  const { points } = toRefs(drawStore)
  const { image } = toRefs(props)
</script>

<template>
  <v-line
    :draggable="false"
    :config="{
      stroke: 'black',
      strokeWidth: device.thickness,
      globalCompositeOperation: 'source-over',
      lineCap: 'round',
      lineJoin: 'round',
      points: points?.map((e) => e * image.scale + 2),
    }"
  ></v-line>
  <v-line
    :draggable="false"
    :config="{
      stroke: color,
      strokeWidth: device.thickness,
      globalCompositeOperation: 'source-over',
      lineCap: 'round',
      lineJoin: 'round',
      points: points?.map((e) => e * image.scale),
    }"
  ></v-line>
</template>
