<script lang="ts" setup>
  import LeadCardComponent from '@/components/LeadCardComponent.vue'
  import LoadingProjectCard from './LoadingProjectCard.vue'
  import Lazy from '@/components/LazyComponent.vue'
  import { i18n } from '@/i18n'
  import { ILead } from '@/interfaces/project/project.interface'
  import { toRefs } from 'vue'

  type Iprops = {
    leads: ILead[]
    loadingProjects: boolean
  }
  const { t } = i18n.global
  const props = defineProps<Iprops>()
  const { loadingProjects, leads } = toRefs(props)
</script>

<template>
  <!--project card-->
  <template v-if="(leads ?? []).length > 0">
    <div class="flex flex-wrap mt-2 w-full justify-content-evenly gap-2">
      <Lazy v-for="(project, index) in leads" :key="project.id" :unrender="true" class="col col-12 sm:col-6 md:col-4 flex-grow-1">
        <LeadCardComponent :lead="project" :index="index" />
      </Lazy>
    </div>

    <div class="h-2rem" />
  </template>

  <div v-else-if="!loadingProjects" class="col-12">
    <div class="card flex align-items-center justify-content-center flex-wrap col gap-2">
      <h6 class="pt-3">
        {{ t('LeadCardsView.noProject') }}
      </h6>
    </div>
  </div>

  <!-- skeleton-->
  <div v-if="loadingProjects" class="flex flex-wrap mt-2 w-full justify-content-evenly gap-3">
    <LoadingProjectCard v-for="i in 8" :key="i" is-leads class="col col-12 sm:col-6 md:col-4 flex-grow-1 p-0" style="min-height: auto" />
  </div>
</template>
