<script lang="ts" setup>
  import { onMounted, ref, toRefs } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import api from '@/services/api'
  import Button from 'primevue/button'
  import CommentComponent from './CommentComponent.vue'
  import { i18n } from '@/i18n'
  import Sidebar from 'primevue/sidebar'
  import { captureException } from '@sentry/vue'
  import { useLeadStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import EditorContent from '@/components/EditorNotes/EditorContent.vue'
  import { EditorComponent } from '@/components/EditorNotes/editorInterface'

  type Iprops = { photoId: string }

  interface IEditedComment {
    id: string
    comment: string
  }

  const { t } = i18n.global
  const props = defineProps<Iprops>()
  const emit = defineEmits(['update-comment', 'delete-comment', 'close-comments', 'create-comment'])
  const leadStore = useLeadStore()
  const { fetchWorkContacts } = useLeadStore()
  const { projectWorkContacts } = storeToRefs(leadStore)
  const { photoId } = toRefs(props)
  const toast = useToast()
  const isOpen = ref(true)
  const comments = ref<any[]>([])
  const noteInfo = ref('')
  const editorChild = ref<EditorComponent | null>(null)
  const submitLoading = ref(false)

  const getWorkContacts = async () => {
    await fetchWorkContacts()
  }

  const closeComments = () => {
    emit('close-comments')
  }

  async function fetchComments() {
    try {
      const {
        data: { data: resp },
      } = await api.get(`/Api/Projects/Photo/${photoId.value}/Comments`)
      comments.value = [...resp]
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function submitComment() {
    if (clearHtml(noteInfo?.value).trim().length === 0) return
    try {
      const html = noteInfo.value
      submitLoading.value = true
      const payload = { photoId: photoId.value, comment: html }
      await api.post(`/Api/Projects/Photo/Comment`, payload)
      submitLoading.value = false
      if (editorChild.value) {
        editorChild.value.commands.clearEditorContent()
      }
      await fetchComments()
      toast.add({ severity: 'success', summary: t('commentsComponent.success'), detail: t('commentsComponent.commentSaved'), life: 3000 })
      emit('create-comment')
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: t('commentsComponent.error'), detail: t('commentsComponent.errorCreating'), life: 3000 })
      submitLoading.value = false
    }
  }

  function updateCommentAfterEdit(editedComment: IEditedComment) {
    const comment = comments.value.find((n) => n.id === editedComment.id)
    if (comment) comment.comment = editedComment.comment
    emit('update-comment', editedComment)
  }

  function removeCommentAfterDelete(deletedComment: { id: string }) {
    const commentIndex = comments.value.findIndex((n) => n.id === deletedComment.id)
    if (commentIndex > -1) comments.value.splice(commentIndex, 1)
    emit('delete-comment', deletedComment)
  }
  const updatecontent = (content: string) => {
    noteInfo.value = content
  }

  const clearHtml = (html = '') => {
    const regex = /(<([^>]+)>)/gi
    return html.replace(regex, '')
  }

  function onKeydownEditor() {
    submitComment()
    if (editorChild.value) {
      editorChild.value.commands.focus()
    }
  }

  onMounted(async () => {
    await fetchComments()
    await getWorkContacts()
    if (editorChild.value) {
      editorChild.value.commands.focus()
    }
  })
</script>

<template>
  <Sidebar
    id="photo-comment"
    v-model:visible="isOpen"
    append-to="body"
    :dismissable="true"
    :base-z-index="1105"
    position="bottom"
    style="background: #ffffff; max-width: 32rem; height: calc(100% - 1rem)"
    class="p-sidebar-lg w-full m-2 border-round-lg"
    @hide="closeComments"
  >
    <template #header>
      <div class="w-full font-bold">{{ t('commentsComponent.coments') }}</div>
    </template>

    <div class="h-full w-full flex flex-column max-h-full">
      <div class="w-full h-full flex-grow-1" style="overflow-x: hidden; overflow-y: auto; padding: 1.25rem; height: calc(100% - 55px); max-height: calc(100% - 55px)">
        <div v-if="comments.length > 0">
          <div v-for="comment in comments" :id="comment.id" :key="comment.id" class="flex flex-row mb-4">
            <CommentComponent :comment="comment" @update-comment="updateCommentAfterEdit" @delete-comment="removeCommentAfterDelete" />
          </div>
        </div>

        <div v-else class="flex flex-column align-items-center justify-content-center flex-grow-1 h-full">
          {{ t('commentsComponent.noComments') }}
        </div>
      </div>
      <div class="w-full flex gap-2 p-2" style="border-top: 1px solid rgba(0, 0, 0, 0.2); max-height: 55px">
        <div class="w-full flex flex-row align-items-center gap-2">
          <EditorContent
            ref="editorChild"
            :items-suggestions="projectWorkContacts"
            class="flex-1"
            :placeholder="i18n.global.t('galleryCommentComponent.placeholder')"
            @create:note="onKeydownEditor"
            @update:note="updatecontent"
          />
          <Button icon="pi pi-send" :loading="submitLoading" :disabled="clearHtml(noteInfo).trim().length === 0 ? true : submitLoading" @click="submitComment" />
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<style>
  .p-sidebar-header-content {
    min-height: 0 !important;
  }
</style>
