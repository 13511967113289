import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications'
import { Device } from '@capacitor/device'
import { allowPushNotification, statusDeviceToken, useDevieTokenStore } from '@/store'

import { Capacitor } from '@capacitor/core'
import { useRouter } from 'vue-router'

//TODO: ESTO NECESITA HACERSE UNA VEZ MAS.
// const getPermission = async () => {
//   const DeviveTokenStore = useDevieTokenStore()
//   const { receive } = await PushNotifications.requestPermissions()

//   if (receive === 'granted') {
//     await PushNotifications.register()
//     PushNotifications.addListener('registration', async (token: Token) => {
//       await statusDeviceToken(true)
//       await DeviveTokenStore.setDeviceToken(token.value)
//     })
//     // Some issue with our setup and push will not work
//     PushNotifications.addListener('registrationError', async (error) => {
//       await statusDeviceToken(false)
//       console.log('Error on registration: ' + JSON.stringify(error))
//     })

//     PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
//       console.log('Push action performed: ', notification)
//       await redirect(notification)
//     })
//   } else {
//     await allowPushNotification(false)
//   }
// }

// const redirect = async (notification: ActionPerformed) => {
//   const url = notification.notification?.data?.link
//   if (url) await sendUrl(url)
// }

// const sendUrl = async (url: string) => {
//   const router = useRouter()
//   await router.push(url)
// }

export const usePushNotificationsMobile = () => {
  const DeviveTokenStore = useDevieTokenStore()
  const router = useRouter()

  const getPermission = async () => {
    const result = await PushNotifications.requestPermissions()
    if (result.receive === 'granted') {
      await PushNotifications.register()

      PushNotifications.addListener('registration', async (token: Token) => {
        await statusDeviceToken(true)
        await DeviveTokenStore.setDeviceToken(token.value)
      })
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', async (error) => {
        await statusDeviceToken(false)
        console.log('Error on registration: ' + JSON.stringify(error))
      })

      PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        console.log('Push action performed: ', notification)
        await redirect(notification)
      })
    } else {
      await allowPushNotification(false)
    }
  }
  const actionPerformedPushNotification = async () => {
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      // console.log('Push action performed: ', notification)
      await redirect(notification)
    })
  }

  const redirect = async (notification: ActionPerformed) => {
    // console.log('notification', notification.notification)
    const url = notification.notification?.data?.link
    if (url) await sendUrl(url)
  }

  const sendUrl = async (url: string) => {
    // console.log('url', url)
    await router.push(url)
  }
  const activePushNotification = async () => {
    if (Capacitor.isNativePlatform()) await getPermission()
  }
  return { getPermission, activePushNotification, actionPerformedPushNotification }
}

export const logDeviceInfo = async () => {
  const info = await Device.getInfo()
  if (info.platform === 'ios') return `${info.platform}-${info.osVersion}`
  if (info.platform === 'android') return `${info.platform}-${info.osVersion}`
  const userAgent = window.navigator.userAgent
  return `${info.platform}-${userAgent}`
}
