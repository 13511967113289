import { ref } from 'vue'

export type DaysArray = {
  date: string
  datesp: string
  isCurrentMonth: boolean
  month: number
  year: number
  day: number
}

export function createRangeDays(startDate: string | null, endDate: string | null): string[] {
  const start: Date = new Date(startDate ?? '')
  const end: Date = new Date(endDate ?? '')

  const dayCount: number = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1

  return Array.from({ length: dayCount }, (_, i) => {
    const date = new Date(start)
    date.setDate(start.getDate() + i)
    return date.toISOString().split('T')[0]
  })
}

export function useDaysOfMonth() {
  const dateArray = ref<DaysArray[]>([])

  const createDaysArray = (year: number, month: number): DaysArray[] => {
    const firstDayOfMonth = new Date(year, month, 1)
    const lastDayOfMonth = new Date(year, month + 1, 0)

    // Find the last Sunday before the 1st of the month
    const firstDayPosition = firstDayOfMonth.getDay() // 0 (Sunday) - 6 (Saturday)
    const startDate = new Date(firstDayOfMonth)
    startDate.setDate(startDate.getDate() - firstDayPosition)

    // Find the first Saturday after the last day of the month
    const endDate = new Date(lastDayOfMonth)
    const lastDayPosition = lastDayOfMonth.getDay() // 0 (Sunday) - 6 (Saturday)
    endDate.setDate(endDate.getDate() + (6 - lastDayPosition))

    const daysArray: DaysArray[] = []

    // eslint-disable-next-line prefer-const
    let currentDate = startDate
    while (currentDate <= endDate) {
      const isCurrentMonth = currentDate.getMonth() === month
      daysArray.push(createDayObject(currentDate, isCurrentMonth))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return daysArray
  }

  const createDayObject = (date: Date, isCurrentMonth: boolean): DaysArray => ({
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.toISOString(),
    day: date.getDate(),
    datesp: date.toISOString().split('T')[0],
    isCurrentMonth,
  })

  const updateDaysArray = (inputYear: number, inputMonth: number) => {
    const daysArray = createDaysArray(inputYear, inputMonth)
    dateArray.value = daysArray
  }

  return {
    dateArray,
    updateDaysArray,
  }
}
