<script lang="ts" setup>
  import { toRefs } from 'vue'
  import LazyLoadComponent from '@/components/LazyLoadComponent.vue'
  import ImageComponent from '@/components/ImageComponent.vue'
  // import Skeleton from 'primevue/skeleton'
  import { TPhotoContent } from '@/constants/types'
  import useThumbnail from '@/composables/useThumbnail'
  interface IProps {
    photo: TPhotoContent
  }

  const props = defineProps<IProps>()
  const { photo } = toRefs(props)

  const { url, failed, handleError, handleLoad } = useThumbnail(photo.value)
</script>

<template>
  <LazyLoadComponent class="cursor-pointer masonry-item shadow-2">
    <div style="height: 100%; width: 100%">
      <figure :class="failed ? 'hidden' : ''">
        <ImageComponent :url="url" @onerror="handleError" @onload="handleLoad" />
      </figure>
      <div :class="!failed ? 'hidden' : ''" class="w-full h-full flex px-3 justify-content-center align-items-center">
        <ProgressBar
          :pt="{
            value: { style: { 'background-color': 'var(--gray-300)' } },
          }"
          mode="indeterminate"
          style="height: 6px; width: 100%"
        />
      </div>
    </div>
  </LazyLoadComponent>
</template>

<style lang="scss" scoped>
  .hidden {
    display: none;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
