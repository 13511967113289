export const projectAssignCrewUsersEnUS = {
  crews: 'Crew users',
  assign: 'ASSIGN CREW USER',
  assingCrewUser: 'Assign Crew User',
  viewCrewUser: 'View Crew User',
  noCrewAssignedYet: 'No crews yet',
  assignCrew: 'Assign Crew',
  removeCrew: 'Remove user',
  removeCrewUser: 'Are you sure you want to remove this user from the crew?',
  deletedCrewUserSuccess: 'User removed successfully',
  buttonCancel: 'Cancel',
  buttonRemove: 'Remove',
  selectCrew: 'Select Crew',
  myCrews: 'My Crew',
  success: 'Success',
}

export const projectAssignCrewUsersEsMX = {
  crews: 'Equipo de usuarios',
  assign: 'ASIGNAR USUARIO AL EQUIPO',
  assingCrewUser: 'Asignar Equipo',
  viewCrewUser: 'Ver Equipo',
  noCrewAssignedYet: 'Aún no hay equipos',
  assignCrew: 'Asignar Equipo',
  removeCrew: 'Remover usuario',
  removeCrewUser: '¿Estás seguro que deseas remover este usuario del equipo?',
  deletedCrewUserSuccess: 'Usuario removido correctamente',
  buttonCancel: 'Cancelar',
  buttonRemove: 'Remover',
  selectCrew: 'Seleccionar usuario',
  myCrews: 'Mi Equipo',
  success: 'Éxito',
}
