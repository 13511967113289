<script setup lang="ts">
  import { usePhotoQueueStore } from '@/store'
  import { PhotoStatusEnum } from '@/db/tables/PhotoQueue.table'

  const photoQueueStore = usePhotoQueueStore()
</script>

<template>
  <Sidebar v-model:visible="photoQueueStore.sidebarActive" position="bottom" class="p-sidebar-lg">
    <div>
      <div class="text-4xl font-bold">
        {{ $t('photoQueueComponent.photoQueue') }}
      </div>
      <div v-if="photoQueueStore.photoQueue.length > 0">
        <card v-for="photo in photoQueueStore.photoQueue" :key="photo.id" class="mb-2">
          <template #content>
            <div class="flex flex-wrap justify-content-between align-items-center gap-4">
              <div class=""><img :src="photo.base64Data" height="20" /></div>
              <div class="flex-grow-1">
                <ProgressBar :value="photo.progress" style="height: 0.5rem" :show-value="false" />
              </div>
              <div class="">
                <span v-if="photo.status === PhotoStatusEnum.COMPLETE">{{ $t('photoQueueComponent.done') }}</span>
                <span v-else>{{ $t('photoQueueComponent.uploading') }}</span>
              </div>
            </div>
          </template>
        </card>
      </div>
      <div v-else>
        <div class="flex justify-content-center align-items-center p-8 text-500">
          <div class="">
            <div class="align-items-center text-center">
              <i class="pi pi-folder" style="font-size: 2rem"></i>
            </div>
            <div class="text-2xl">
              {{ $t('photoQueueComponent.emptyQueue') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
</template>
