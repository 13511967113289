<script setup lang="ts">
  import { toRef } from 'vue'

  const props = defineProps<{
    project: any
    goBack?: () => void
    isUploading?: boolean
  }>()

  const isUploading = toRef(props, 'isUploading')
</script>

<template>
  <div class="flex flex-row align-items-center w-full mb-3">
    <Button v-if="goBack" class="p-button-text p-button-rounded" style="min-height: 42px; min-width: 42px; height: 42px; width: 42px" icon="pi pi-arrow-left" @click="goBack" />
    <div class="flex flex-column align-items-srart justify-content-start ml-2" style="width: calc(100% - 84px)">
      <h5 class="text-left font-bold mb-1 text-overflow-ellipsis white-space-nowrap overflow-hidden">
        {{ project.projectName || project.name }}
      </h5>
      <div v-if="project?.poNumber" class="text-600 text-left text-sm text-overflow-ellipsis white-space-nowrap overflow-hidden">
        {{ project?.poNumber }}
      </div>
      <div class="text-600 text-left text-sm text-overflow-ellipsis white-space-nowrap overflow-hidden">
        {{ project.address || project.street }}
      </div>
      <div class="text-600 text-left text-sm text-overflow-ellipsis white-space-nowrap overflow-hidden">
        {{ project.projectStage }}
      </div>
    </div>
    <div v-if="isUploading" class="align-self-start">
      <i class="pi pi-spin pi-spinner text-primary" style="font-size: 2rem"></i>
    </div>
  </div>
</template>
