export const SiderBarPhotoFeedEnUs = {
  title: 'Filter',
  endDatelaceHolder: 'End Date',
  startDatePlaceHolder: 'Start Date',
  projectPlaceHolder: 'Select Project',
  photoTypePlaceHolder: 'Select Photo Type',
  tagPhotoPlaceHolder: 'Select Tag Photo',
  userPlaceHolder: 'Select User',
  resetValues: 'Reset Values',
  rangePlaceHolder: 'Picture Taken Between',
}

export const SiderBarPhotoFeedEsMx = {
  title: 'Filtrar',
  endDatelaceHolder: 'Fecha de finalización',
  startDatelaceHolder: 'Fecha de inicio',
  projectPlaceHolder: 'Seleccionar proyecto',
  tagPhotoPlaceHolder: 'Seleccionar etiqueta de foto',
  photoTypePlaceHolder: 'Seleccionar tipo de foto',
  userPlaceHolder: 'Seleccionar usuario',
  resetValues: 'Restablecer valores',
  rangePlaceHolder: 'Imagen tomada entre',
}
