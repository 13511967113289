<script async setup lang="ts">
  import Sidebar from 'primevue/sidebar'
  import Galleria from 'primevue/galleria'
  import Button from 'primevue/button'
  import { toRefs, ref } from 'vue'
  import { IQueuePhoto } from '@/db/tables'
  import Editor from '@/components/editor_images/Editor.vue'

  interface IPreviewCameraProps {
    visible: boolean
    images: IQueuePhoto[]
  }

  const props = withDefaults(defineProps<IPreviewCameraProps>(), {
    images: () => [] as IQueuePhoto[],
  })

  const emit = defineEmits(['closePreview', 'deleteImage', 'editImage'])

  const { visible, images } = toRefs(props)

  const initialEditorImageSelected = {
    photoId: '',
    photoOURL: '',
    annotation: null,
    photo: null as IQueuePhoto | null,
  }
  const editor_image_selected = ref<{ photoId: string; photoOURL: string; annotation: string | null; photo: IQueuePhoto | null }>(initialEditorImageSelected)

  const showDialogEditor = ref(false)

  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
  ]

  function generateBackgroundImage(src: string) {
    return {
      backgroundImage: `url(${src})`,
    }
  }

  const handleCloseEditor = () => {
    editor_image_selected.value = initialEditorImageSelected
    showDialogEditor.value = false
  }
  const handleSaveEditor = (data: { base_64: string; annotation: string; photoId: string }) => {
    const newPhoto = {
      ...editor_image_selected.value.photo,
      annotation: data?.annotation,
      base64_annotation: data?.base_64,
    } as IQueuePhoto
    emit('editImage', newPhoto)
    editor_image_selected.value = initialEditorImageSelected
    showDialogEditor.value = false
  }
  const handleClickEditPhoto = (photo: IQueuePhoto) => {
    editor_image_selected.value = {
      photoId: photo.id,
      annotation: photo.annotation ?? null,
      photoOURL: photo.base64 ?? '',
      photo: photo,
    }
    showDialogEditor.value = true
  }
</script>

<template>
  <Editor
    v-if="showDialogEditor"
    :available-annotations="false"
    :photoid="editor_image_selected?.photoId"
    :photourl="editor_image_selected?.photoOURL"
    :annotation="editor_image_selected?.annotation"
    @close="handleCloseEditor"
    @save="handleSaveEditor"
  />
  <!-- <EditImageIndex v-if="open && imageSelect" :photo="imageSelect" @close-edit="(value) => handleEditImage(value?.image)" /> -->
  <Sidebar
    :auto-z-index="false"
    :base-z-index="1150"
    :visible="visible"
    append-to="body"
    :dismissable="true"
    position="full"
    :show-close-icon="false"
    style="background: #ffffff; left: 0; top: 0; right: 0; bottom: 0"
    class="preview-container absolute"
  >
    <div class="flex w-full absolute top-0 left-0 z-5 border-none p-4" style="background-color: #000000">
      <Button class="p-button-rounded p-button p-button-icon-only p-button-text" @click="$emit('closePreview')">
        <svg class="text-white text-xl" style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
        </svg>
      </Button>
    </div>
    <div class="gallery bg-black-alpha-90 relative">
      <Galleria :value="images" :responsive-options="responsiveOptions" :num-visible="5" :num-scroll="1" :circular="true">
        <template #item="slotProps">
          <div
            class="bg-no-repeat bg-transparent w-full h-full bg-contain bg-center"
            :style="generateBackgroundImage(slotProps?.item?.base64_annotation ?? slotProps.item.base64 ?? slotProps.item.url)"
          />
          <div class="absolute top-0 right-0 p-4 z-5 border-none">
            <Button class="p-button-rounded p-button p-button-icon-only p-button-text" @click="() => handleClickEditPhoto(slotProps.item)">
              <svg class="text-white text-xl" viewBox="0 0 16 16" style="display: block; margin: auto; width: 18px; height: 18px">
                <path
                  fill="currentColor"
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z"
                />
              </svg>
            </Button>
            <Button class="p-button-rounded p-button p-button-icon-only p-button-text" @click="$emit('deleteImage', slotProps.item)">
              <svg class="text-white text-xl" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
              </svg>
            </Button>
          </div>
        </template>
        <template #thumbnail="slotProps">
          <img :src="slotProps?.item?.base64_annotation ?? slotProps.item.base64 ?? slotProps.item.url" style="width: 100%; display: block; object-fit: cover; height: 100%" />
        </template>
      </Galleria>
    </div>
  </Sidebar>
</template>

<style>
  /* hide header for camera sidebar */
  .preview-container.p-sidebar .p-sidebar-header {
    display: none;
    padding: 0;
  }
  /* hide left and right padding for camera sidebar */
  .preview-container.p-sidebar .p-sidebar-content {
    padding: 0;
    overflow: hidden;
    height: 100%;
  }

  .preview-container.p-sidebar .p-sidebar-content .p-galleria,
  .preview-container.p-sidebar .p-sidebar-content .p-galleria-content,
  .preview-container.p-sidebar .p-sidebar-content .p-galleria-item-wrapper,
  .preview-container.p-sidebar .p-sidebar-content .p-galleria-item-container,
  .preview-container.p-sidebar .p-sidebar-content .p-galleria-thumbnail-container,
  .preview-container.p-sidebar .p-sidebar-content .p-galleria-thumbnail-items-container,
  .preview-container.p-sidebar .p-sidebar-content .p-galleria-thumbnail-items,
  .preview-container.p-sidebar .p-sidebar-content .p-galleria-thumbnail-item,
  .preview-container.p-sidebar .p-sidebar-content .p-galleria-thumbnail-item-content {
    height: 100%;
  }

  .preview-container.p-sidebar .p-sidebar-content .p-galleria-thumbnail-wrapper {
    height: 80px;
  }
</style>

<style scoped lang="scss">
  .gallery {
    height: 100%;
  }
</style>
