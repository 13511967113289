<script setup lang="ts">
  import api from '@/services/api'
  import uuid from 'uuid-random'
  import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
  import { IPhoto } from '@/interfaces/photo/photo.interface'
  import { onMounted, toRefs } from 'vue'
  import { ref } from 'vue'
  import { i18n } from '@/i18n'
  interface TagObject {
    tagId: string
    tagName: string
    name: string
    description: string | null
    photoCount: number
    createTime: string
    userId: string | null
  }
  interface IPaginationInfo {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }

  interface ITagResponse {
    data: TagObject[]
    pagination: IPaginationInfo
  }

  type IProps = {
    photo: IPhoto
  }

  function filtrarDatos<T>(datos: T[], filtro: T[], key: keyof T) {
    return datos.filter((dato) => filtro.every((f) => f[key] !== dato[key]))
  }

  const { t } = i18n.global
  const props = defineProps<IProps>()
  const emit = defineEmits(['updatePhotoTags'])
  const { photo } = toRefs(props)
  const tagResponse = ref<ITagResponse>({} as ITagResponse)
  const autoCompleteTag = ref<any | null>(null)
  const tagsFieldValue = ref<TagObject[]>([])
  const loadingGetTags = ref(false)
  const isSubmitting = ref(false)
  const tagProvisionalName = ref('')

  interface IFilterParams {
    skip?: number
    take?: number
    query?: string
  }

  const fechtTags = async (params?: IFilterParams) => {
    loadingGetTags.value = true
    const paramsQuery = []
    if (params?.query) paramsQuery.push(`query=${params.query}`)
    if (params?.skip || params?.skip === 0) paramsQuery.push(`&skip=${params.skip}`)
    if (params?.take) paramsQuery.push(`&take=${params.take}`)
    const response = await api.get(`/Api/PhotoFeed/Tags?${paramsQuery.join('')}`)
    loadingGetTags.value = false
    tagResponse.value = response.data.data
  }

  const createProvisionalTag = async (name: string) => {
    const tag = {
      tagId: uuid(),
      name: name,
      tagName: name,
      createTime: '',
      photoCount: 0,
      userId: null,
      description: null,
    }

    autoCompleteTag.value?.hide()
    const element = document.getElementById('assignToTag')
    element?.focus()
    element?.setAttribute('value', '')
    tagsFieldValue.value = [...tagsFieldValue.value, tag]
    tagResponse.value = {
      data: [...tagResponse.value.data, tag],
      pagination: tagResponse.value.pagination,
    }
  }

  const submit = async () => {
    isSubmitting.value = true
    const tags = tagsFieldValue.value.map((tag) => tag.name)
    const payload = {
      names: tags,
    }
    const response = await api.post(`/Api/Photo/${photo.value.id}/Tag`, payload)
    isSubmitting.value = false
    emit('updatePhotoTags', response.data.data as IPhoto)
  }

  const searchTag = async (event: AutoCompleteCompleteEvent) => {
    tagProvisionalName.value = event.query
    await fechtTags({ query: event.query, skip: 0, take: 50 })
  }

  onMounted(async () => {
    tagsFieldValue.value = photo.value.tags
    await fechtTags({ skip: 0, take: 50 })
  })
</script>
<template>
  <div class="field p-fluid">
    <AutoComplete
      ref="autoCompleteTag"
      v-model="tagsFieldValue"
      input-id="assignToTag"
      class="w-full"
      :dropdown="true"
      :force-selection="true"
      option-label="name"
      multiple
      :placeholder="'Select to Tag'"
      :suggestions="filtrarDatos([...(tagResponse?.data ?? [])], [...tagsFieldValue], 'tagId')"
      @complete="searchTag($event)"
      @change="console.log($event)"
      @item-unselect="console.log($event)"
    >
      <template #empty>
        <div class="flex justify-content-center align-items-center" style="height: 3rem">
          <Button icon="pi pi-plus" :label="t('tags.createButton')" class="p-button-rounded p-button-text p-button-plain" @click="createProvisionalTag(tagProvisionalName)" />
        </div>
      </template>
    </AutoComplete>
  </div>
  <div class="flex justify-content-end mt-4 py-3 -mb-5 -mx-4 px-4" style="border-top: 0px solid rgba(0, 0, 0, 0.15); width: calc(100% + 3rem); background-color: #f5f7fa">
    <Button label="SUBMIT" style="background-color: #3c82f6" class="p-button-info" :disabled="isSubmitting" :loading="isSubmitting" @click="submit()" />
  </div>
</template>
