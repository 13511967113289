import { ProblemTypeEnum, ProblemStatusEnum } from './problem.enum'
import { i18n } from '@/i18n'
const { t } = i18n.global

export const ProblemTypeOptions = Object.keys(ProblemTypeEnum).map((key) => {
  const options = ProblemTypeEnum as { [key: string]: any }
  const label = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase().replace(/_/g, ' ')

  return {
    value: options[key],
    label: t('projectProblemsAdd.' + label.toLocaleLowerCase()),
  }
})

export const ProblemStatusOptions = Object.keys(ProblemStatusEnum).map((key) => {
  const options = ProblemStatusEnum as { [key: string]: any }
  const label = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase().replace(/_/g, ' ')
  return {
    value: options[key],
    label,
  }
})
