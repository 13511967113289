export enum ProblemTypeEnum {
  DAMAGE = 'DAMAGE',
  ROT = 'ROT',
  MATERIALS = 'MATERIALS',
  OTHER = 'OTHER',
}

export enum ProblemStatusEnum {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}
