<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script lang="ts" setup>
  import { toRefs } from 'vue'

  import { i18n } from '@/i18n'
  import { newIQueuePhoto, QueueStatusType } from '@/db/tables'
  import { onMounted, ref } from 'vue'

  //Images
  import ERROR from '@/assets/photoQ/ERROR.png'
  import LOADING from '@/assets/photoQ/LOADING.png'
  import IDLE from '@/assets/photoQ/IDLE.png'
  import COMPLETE from '@/assets/photoQ/COMPLETE.png'

  type Iprops = {
    queuePhoto: newIQueuePhoto
  }

  const { t } = i18n.global
  const props = defineProps<Iprops>()
  const emit = defineEmits(['uploadPhoto', 'editPhoto'])
  const { queuePhoto } = toRefs(props)

  const typePhoto = {
    PROGRESS_PICTURES: 'photoType.PROGRESS_PICTURES',
    PREPRODUCTION_PICTURES: 'photoType.PREPRODUCTION_PICTURES',
    TASK: 'photoType.TASK',
    TASK_LIST: 'photoType.TASK_LIST',
    TASK_AFTER: 'photoType.TASK_AFTER',
    TASK_BEFORE: 'photoType.TASK_BEFORE',
    TASK_PICTURES: 'photoType.TASK_PICTURES',
    FINAL_PICTURES: 'photoType.FINAL_PICTURES',
    PROFILE_PICTURES: 'photoType.PROFILE_PICTURES',
    PROBLEM: 'photoType.PROBLEM',
  }

  const url = ref('')

  onMounted(() => {
    url.value = URL.createObjectURL(queuePhoto.value.file)
  })

  const formatFileSize = (sizeInBytes: number): string => {
    const size = sizeInBytes / (1024 * 1024)
    return size.toFixed(2) + ' MB'
  }

  const uploadPhotoBlob = async (photo: newIQueuePhoto) => {
    emit('uploadPhoto', photo)
  }

  const statusMessages: { [key in QueueStatusType]: string } = {
    IDLE: t('photoQ.waitingQueue'),
    UPLOADING: t('photoQ.uploadQueue'),
    COMPLETE: t('photoQ.uploadedQueue'),
    ERROR: t('photoQ.retryQueue'),
  }
</script>

<template>
  <div class="w-ful flex flex-1">
    <div style="width: 70px; height: 70px">
      <img :src="url" alt="" style="width: 100%; height: 100%; object-fit: cover" class="border-round cover-full" />
    </div>
    <div class="flex-wrap flex flex-column flex-1 pl-3 justify-content-center">
      <p class="text-600 mb-0 text-sm font-semibold">{{ queuePhoto?.entityName }}</p>
      <p class="text-600 mb-0 text-sm font-semibold">#{{ queuePhoto?.poNumberProject }}</p>
      <p class="text-600 mb-0 text-sm">{{ queuePhoto?.status ? statusMessages[queuePhoto?.status] : statusMessages['ERROR'] }}</p>
    </div>

    <!-- State Button -->
    <div class="flex flex-grow-0 flex-shrink-0 align-items-center">
      <template v-if="queuePhoto.status === 'ERROR'">
        <div style="flex-direction: column; display: flex; align-items: center; gap: 10">
          <p class="text-600 mb-0 text-sm font-semibold">{{ queuePhoto?.retries }}</p>
          <Button :loading="false" style="background-color: transparent; border: none" @click="uploadPhotoBlob(queuePhoto)">
            <img :src="ERROR" alt="Error Status" style="width: 30px; height: 30px; object-fit: contain" />
          </Button>
        </div>
      </template>

      <template v-else-if="queuePhoto.status === 'IDLE'">
        <div style="flex-direction: column; display: flex; align-items: center; gap: 10">
          <img :src="IDLE" alt="IDLE Status" style="width: 30px; height: 30px; object-fit: contain" />
        </div>
      </template>

      <template v-else-if="queuePhoto.status === 'UPLOADING'">
        <div style="flex-direction: column; display: flex; align-items: center; gap: 10">
          <p class="text-600 mb-0 text-sm font-semibold">{{ formatFileSize(queuePhoto?.file.size) }}</p>
          <img class="rotate-infinite" :src="LOADING" alt="UPLOADING Status" style="width: 30px; height: 30px; object-fit: contain" />
        </div>
      </template>

      <template v-else-if="queuePhoto.status === 'COMPLETE'">
        <img :src="COMPLETE" alt="COMPLETE Status" style="width: 30px; height: 30px; object-fit: contain" />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .line-height-normal {
    line-height: normal;
  }
  .border-left-8 {
    border-left: 8px solid;
  }

  .border-blue {
    border-color: #3b82f6;
  }

  .p-card .p-card-body {
    padding: 0.25rem;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .rotate-infinite {
    animation: rotate 2s linear infinite;
  }
</style>
