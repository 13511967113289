const addQueryParam = <T>(paramName: keyof T, params: T | undefined | null) => {
  const paramValue = params?.[paramName]
  if (paramValue !== undefined && paramValue !== null && paramValue !== '') {
    return `&${paramName as string}=${paramValue}`
  }
  return ''
}

export const splitQueryParams = <T>(params: T | undefined | null) => {
  if (!params) {
    return ''
  }

  const arrKeys = Object.keys(params ?? {}) as (keyof T)[]
  const keysQuery = arrKeys.map((key) => {
    return addQueryParam<T>(key, params)
  })

  const paramsQuery = keysQuery?.join('')
  return paramsQuery
}
