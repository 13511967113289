export const projectProblemsEnUS = {
  problems: 'Problems',
  create: 'CREATE',
  noProblemsYet: 'No problems yet',
  createAProblem: 'Create Problem',
}

export const projectProblemsEsMX = {
  problems: 'Problemas',
  create: 'CREAR',
  noProblemsYet: 'Sin problemas',
  createAProblem: 'Crear problema',
}
