export const policiesIndexEnUS = {
  title: 'IHS Production Web App – User Policies',
  lastUpdated: 'Last updated:',
  lastUpdatedDate: 'August 26, 2022',
  agreementOfOnlineBehavior: {
    title: 'AGREEMENT OF ONLINE BEHAVIOR',
    paragraph1:
      'Any access or use of IHS Production Web App constitutes acceptance of the following Terms & Conditions on your behalf. Acceptance means you will be bound to the terms in this agreement and any provisions made in the future. IHS Production has the right to amend their Terms & Conditions and Privacy Policy at any time. It is your sole responsibility to check back frequently for updates and changes.',
    paragraph2: 'You agree to not use this App or our services to undermine the integrity of the App or our services.',
    paragraph3: 'The IHS Production Web App is only available to employees of Infinity Home Services and its subsidiary companies.',
  },
  limitationOfWarrantyAndLiability: {
    title: 'LIMITATION OF WARRANTY AND LIABILITY',
    paragraph1:
      'You, the user, agree to use the App and services completely at your own risk. There are no warranties given for any services rendered included, but not limited to information, uninterrupted access or products or services provided. We disclaim; any warranties concerning availability, accuracy, omissions, content of information, or suitable use of product or services. We disclaim any liability applying to service; deletion, omission, interruption, communication or transmission failure, unauthorized access to information or record, inability to access site, or computer virus pertaining to negligence, breach of contract, or any other form of action. We disclaim any liability applying to product; performance, defect, theft, destruction, or alteration pertaining to negligence, breach of contract, or any other form of action. This App nor its services nor any partners or affiliates shall Not be liable for damages caused by use of this App or its services.',
    paragraph2: 'IHS Production Web App and any information found on the site are offered for informational purposes only.',
    paragraph3:
      'IHS Production is not liable or responsible for the availability, usefulness, or accuracy of the content made available or transmitted to this site. The information has not been verified by an outside source or authenticated either wholly or partially by IHS Production. IHS Production is not responsible or liable for any misfortune, hardships, or injury resulting from the use of this App.',
  },
  warranty: {
    title: 'WAIVER',
    paragraph1:
      'No provision hereof and no breach of any provision hereof shall be deemed waived by any previous waiver of such provision or of any breach thereof, by any previous custom, practice, or course of dealing or by either party’s failure to object to provisions contained in any communication or order.',
  },
  severability: {
    title: 'SEVERABILITY',
    paragraph1:
      'Any provision hereof prohibited or unenforceable under applicable law shall be ineffective only to such extent and without invalidating the remaining provisions of this document.',
  },
  termsAndConditions: {
    title: 'TERMS & CONDITIONS',
    paragraph1: 'By using the IHS Production Web App you accept the following terms of use:',
    paragraph2:
      'Electronic Communications - When you use the App or send us email, you are communicating with us electronically. You agree to receive communication from us electronically. You agree that what we provide to you electronically satisfies any legal requirement stating that such communications must be made in writing.',
    paragraph3:
      'License – IHS Production grants you a limited license to view and use this App and not to download or modify it in part or whole. You are explicitly not granted permission to use data gathering tools, link directly to individual site elements, or otherwise exploit this App in part or whole for commercial purposes. You may not use the IHS Production logo in that link without permission.',
    paragraph4:
      'Your Account - It is your responsibility to keep your account login and password confidential. IHS Production reserves the right to refuse service, delete accounts, remove and/or edit content, and cancel your account at their discretion.',
    paragraph5: 'Other Companies – IHS Production does not assume any responsibility or liability for any third party services offered through our App.',
    paragraph6: 'Applicable Law - As it regards resolving any legal disputes, you agree that the laws of the state of Wisconsin govern these terms of use.',
    paragraph7:
      'Modification and Severability – IHS Production reserves the right to modify these terms of use and our other policies at any time. If any of the terms of use stated herein are deemed to be invalid, that specific condition is deemed severable and does not affect the other conditions herein.',
  },
  linking: {
    title: 'LINKING',
    paragraph1: 'When you link a Google account in the admin area, IHS Production will use the account in the following ways.',
    paragraph2: 'Personal info (name and email) will be shown to all admin users for reference.',
    paragraph3: 'Emails for user management (e.g. user password reset email) will be sent on behalf of this account through Gmail.',
    paragraph4:
      'When you disconnect your Google account, your account will no longer be used for these purposes and this functionality will be unavailable until an account is linked.',
    paragraph5: 'When you link a Google account to a company, IHS Production will use the account in the following ways.',
    paragraph6: 'Personal info (name and email) will be shown to admin users in that company for reference.',
    paragraph7: 'Emails for company operation by any user in that company will be sent on behalf of this account through Gmail.',
    paragraph8: 'Documents created using IHS Production by any user in that company will be uploaded to Google Drive folders for storage and may later be downloaded or deleted.',
    paragraph9: 'Links to these folders and documents may be made available to users within that company.',
    paragraph10: 'If configured, a Google Sheet with configuration information may be imported into IHS Production.',
    paragraph11:
      'When you disconnect your Google account, your account will no longer be used for these purposes and this functionality will be unavailable until an account is linked.',
    paragraph12: 'When you link a Hover account to a company, IHS Production will use the account in the following ways.',
    paragraph13: 'The Hover company name will be shown to admin users in that company for reference.',
    paragraph14: 'The list of hover jobs, accessible by this user, can be searched by users in that company.',
    paragraph15: 'Details and measurements of these jobs may be shown to users in that company.',
    paragraph16:
      'When you disconnect your Hover account, your account will no longer be used for these purposes and this functionality will be unavailable until an account is linked.',
    paragraph17: 'When you link a Zoho account to a company, IHS Production will use the account in the following ways.',
    paragraph18: 'The account name and email address will be shown to admin users in that company for reference.',
    paragraph19: 'The Zoho CRM organization domain name will be retrieved to allow links to specific Zoho CRM pages to be made available to users of that company.',
    paragraph20: 'Zoho CRM user information will be imported into IHS Production to assist with keeping Zoho CRM users in sync with IHS Production users of that company.',
    paragraph21: 'Zoho CRM leads will be imported into IHS Production and converted into IHS Production leads.',
    paragraph22:
      'When you disconnect your Zoho account, your account will no longer be used for these purposes and this functionality will be unavailable until an account is linked.',
  },
  privacy: {
    title: 'PRIVACY',
    paragraph1:
      'IHS Production is providing this privacy statement to insure its users our dedication to user privacy. When you visit IHS Production you can expect the following practices:',
    paragraph2: 'We do not acquire any personal information unless it is specifically volunteered to us',
    paragraph3:
      'Any information collected from you while using our App may be used to: personalize your experience on our App, improve our App or to improve customer service. The information collected, whether private or public, will not be given, sold, exchanged, or transferred for any reason to another company or person without your consent. The email you provide will only be used; for you to use the App, update you on information regarding your use of the App, and News about the IHS Production App.',
    paragraph4:
      'All data sends are conducted in a secure environment to protect against fraudulent observation and or use. We do not store your financial information on the web site such as credit card information.',
    paragraph5:
      'Data is visible to IHS Production administrators and developers as well as other users within the same company. Documents generated through IHS Production may also be emailed to customers.',
    paragraph6:
      'IHS Production utilizes 3rd party software to collect analytics data and log errors to help improve IHS Production. These include Sentry (https://sentry.io/), Microsoft Application Insights, and Google Analytics.',
  },
  copyrights: {
    title: 'COPYRIGHTS',
    paragraph1:
      'Copyright © IHS Production 2022. All rights reserved. The materials on this App, including all text, images, graphics, design concepts and information, are protected by applicable copyright laws. Reproducing, copying, duplicating, publishing, distributing, or use of any information on IHS Production Web App, in whole or part, is restricted (except as provided by the Terms & Conditions of this site or with written permission from IHS Production)',
  },
  support: {
    title: 'SUPPORT',
    paragraph1: 'In case of problems with your account, deletion or handling of information from it, contact the email ',
    paragraph2: ' or the phone',
  },
  Team: 'Infinity Home Services',
  accessIhsHomeBuilder: 'Access IHS Production',
  about: 'About',
}

export const policiesIndexEsMX = {
  title: 'Aplicación web para constructores de casas de IHS: Políticas de usuario',
  lastUpdated: 'Última actualización:',
  lastUpdatedDate: '26 de agosto de 2022',
  agreementOfOnlineBehavior: {
    title: 'ACUERDO DE COMPORTAMIENTO EN LÍNEA',
    paragraph1:
      'Cualquier acceso o uso de la aplicación web para constructores de casas de IHS constituye la aceptación de los siguientes Términos y Condiciones de su parte. La aceptación significa que quedará vinculado a los términos de este acuerdo y a cualquier disposición que se realice en el futuro. IHS Production tiene derecho a modificar sus Términos y Condiciones y Política de Privacidad en cualquier momento. Es su responsabilidad exclusiva revisar con frecuencia las actualizaciones y cambios.',
    paragraph2: 'Usted acepta no utilizar esta aplicación ni nuestros servicios para socavar la integridad de la aplicación o nuestros servicios.',
    paragraph3: 'La aplicación web para constructores de casas de IHS solo está disponible para empleados de Infinity Home Services y sus empresas subsidiarias.',
  },
  limitationOfWarrantyAndLiability: {
    title: 'LIMITACIÓN DE GARANTÍA Y RESPONSABILIDAD',
    paragraph1:
      'Usted, el usuario, acepta utilizar la aplicación y los servicios completamente bajo su propio riesgo. No se otorgan garantías para los servicios prestados, incluidos, entre otros, la información, el acceso ininterrumpido o los productos o servicios proporcionados. Renunciamos a cualquier garantía relativa a la disponibilidad, exactitud, omisiones, contenido de información o uso adecuado del producto o servicio. Renunciamos a cualquier responsabilidad aplicable al servicio; eliminación, omisión, interrupción, fallo de comunicación o transmisión, acceso no autorizado a la información o registro, incapacidad para acceder al sitio o virus informático que pertenezca a negligencia, incumplimiento de contrato o cualquier otra forma de acción. Renunciamos a cualquier responsabilidad aplicable al producto; rendimiento, defecto, robo, destrucción o alteración que pertenezca a negligencia, incumplimiento de contrato o cualquier otra forma de acción. Ni esta aplicación, ni sus servicios, ni ningún socio o afiliado serán responsables por daños causados por el uso de esta aplicación o sus servicios.',
    paragraph2: 'La aplicación web para constructores de casas de IHS y cualquier información encontrada en el sitio se ofrecen solo con fines informativos.',
    paragraph3:
      'IHS Production no es responsable ni se hace responsable de la disponibilidad, utilidad o precisión del contenido disponible o transmitido a este sitio. La información no ha sido verificada por una fuente externa ni autenticada total o parcialmente por IHS Production. IHS Production no es responsable ni se hace responsable de ninguna desgracia, dificultad o lesión resultante del uso de esta aplicación.',
  },
  warranty: {
    title: 'RENUNCIA',
    paragraph1:
      'Ninguna disposición del presente documento ni incumplimiento de cualquier disposición del presente documento se considerará renunciada por ninguna renuncia previa de dicha disposición o de cualquier incumplimiento de la misma, por cualquier costumbre, práctica o curso de conducta anterior, o por la falta de objeción de cualquiera de las partes a las disposiciones contenidas en cualquier comunicación o pedido.',
  },
  severability: {
    title: 'SEPARABILIDAD',
    paragraph1:
      'Cualquier disposición del presente documento prohibida o no exigible en virtud de la ley aplicable será ineficaz solo en esa medida y sin invalidar las disposiciones restantes de este documento.',
  },
  termsAndConditions: {
    title: 'TÉRMINOS Y CONDICIONES',
    paragraph1: 'Al utilizar la aplicación web para constructores de casas de IHS, acepta los siguientes términos de uso:',
    paragraph2:
      'Comunicaciones electrónicas: cuando utiliza la aplicación o nos envía un correo electrónico, se está comunicando con nosotros electrónicamente. Acepta recibir comunicaciones de nuestra parte electrónicamente. Acepta que lo que le proporcionamos electrónicamente satisface cualquier requisito legal que establezca que dichas comunicaciones deben realizarse por escrito.',
    paragraph3:
      'Licencia: IHS Production le otorga una licencia limitada para ver y utilizar esta aplicación y no para descargarla ni modificarla en parte o en su totalidad. No se le otorga explícitamente permiso para utilizar herramientas de recopilación de datos, enlazar directamente a elementos individuales del sitio ni explotar esta aplicación en parte o en su totalidad con fines comerciales. No puede utilizar el logotipo de IHS Production en ese enlace sin permiso.',
    paragraph4:
      'Su cuenta: es su responsabilidad mantener confidenciales su inicio de sesión y su contraseña. IHS Production se reserva el derecho de rechazar el servicio, eliminar cuentas, eliminar y / o editar contenido y cancelar su cuenta a su discreción.',
    paragraph5: 'Otras empresas: IHS Production no asume ninguna responsabilidad o responsabilidad por ningún servicio de terceros ofrecido a través de nuestra aplicación.',
    paragraph6: 'Ley aplicable: en cuanto a la resolución de cualquier disputa legal, acepta que las leyes del estado de Wisconsin rigen estos términos de uso.',
    paragraph7:
      'Modificación y separabilidad: IHS Production se reserva el derecho de modificar estos términos de uso y otras políticas en cualquier momento. Si alguna de las condiciones establecidas aquí se considera inválida, esa condición específica se considera separable y no afecta a las otras condiciones establecidas aquí.',
  },
  linking: {
    title: 'ENLACE',
    paragraph1: 'Cuando enlace una cuenta de Google en el área de administración, IHS Production utilizará la cuenta de la siguiente manera.',
    paragraph2: 'La información personal (nombre y correo electrónico) se mostrará a todos los usuarios administradores como referencia.',
    paragraph3:
      'Se enviarán correos electrónicos para la gestión de usuarios (por ejemplo, correo electrónico de restablecimiento de contraseña de usuario) en nombre de esta cuenta a través de Gmail.',
    paragraph4: 'Cuando desconecte su cuenta de Google, su cuenta ya no se utilizará para estos fines y esta funcionalidad no estará disponible hasta que se vincule una cuenta.',
    paragraph5: 'Cuando vincule una cuenta de Google a una empresa, IHS Production utilizará la cuenta de la siguiente manera.',
    paragraph6: 'La información personal (nombre y correo electrónico) se mostrará a todos los usuarios administradores como referencia.',
    paragraph7: 'Se enviarán correos electrónicos a través de Gmail.',
    paragraph8:
      'Los documentos creados con IHS Production por cualquier usuario de esa empresa se cargarán en las carpetas de Google Drive para su almacenamiento y luego podrán descargarse o eliminarse.',
    paragraph9: 'Los enlaces a estas carpetas y documentos pueden estar disponibles para los usuarios dentro de esa empresa.',
    paragraph10: 'Si está configurado, se puede importar una hoja de Google con información de configuración a IHS Production.',
    paragraph11: 'Cuando desconecte su cuenta de Google, su cuenta ya no se utilizará para estos fines y esta funcionalidad no estará disponible hasta que se vincule una cuenta.',
    paragraph12: 'Cuando vincula una cuenta Hover a una empresa, IHS Production utilizará la cuenta de las siguientes maneras.',
    paragraph13: 'El nombre de la empresa Hover se mostrará a los usuarios administradores de esa empresa como referencia.',
    paragraph14: 'Los usuarios de esa empresa pueden buscar en la lista de trabajos flotantes a los que puede acceder este usuario.',
    paragraph15: 'Los detalles y las medidas de estos trabajos se pueden mostrar a los usuarios de esa empresa.',
    paragraph16: 'Cuando desconecte su cuenta Hover, su cuenta ya no se utilizará para estos fines y esta funcionalidad no estará disponible hasta que se vincule una cuenta.',
    paragraph17: 'Cuando vincula una cuenta de Zoho a una empresa, IHS Production utilizará la cuenta de las siguientes maneras.',
    paragraph18: 'El nombre de la cuenta y la dirección de correo electrónico se mostrarán a los usuarios administradores de esa empresa como referencia.',
    paragraph19:
      'El nombre de dominio de la organización de Zoho CRM se recuperará para permitir que los enlaces a páginas específicas de Zoho CRM estén disponibles para los usuarios de esa empresa.',
    paragraph20:
      'La información del usuario de Zoho CRM se importará a IHS Production para ayudar a mantener a los usuarios de Zoho CRM sincronizados con los usuarios de IHS Production de esa empresa.',
    paragraph21: 'Los prospectos de Zoho CRM se importarán a IHS Production y se convertirán en prospectos de IHS Production.',
    paragraph22: 'Cuando desconecte su cuenta de Zoho, su cuenta ya no se utilizará para estos fines y esta funcionalidad no estará disponible hasta que se vincule una cuenta.',
  },
  privacy: {
    title: 'PRIVACIDAD',
    paragraph1:
      'IHS Production proporciona esta declaración de privacidad para asegurar a sus usuarios nuestra dedicación a la privacidad del usuario. Cuando visite IHS Production, puede esperar las siguientes prácticas:',
    paragraph2: 'No adquirimos ninguna información personal a menos que nos la ofrezcan específicamente.',
    paragraph3:
      'Cualquier información recopilada de usted mientras usa nuestra aplicación puede usarse para: personalizar su experiencia en nuestra aplicación, mejorar nuestra aplicación o mejorar el servicio al cliente. La información recopilada, ya sea privada o pública, no se entregará, venderá, intercambiará ni transferirá por ningún motivo a otra empresa o persona sin su consentimiento. El correo electrónico que proporcione solo se utilizará; para que use la aplicación, actualizarlo con información sobre su uso de la aplicación y noticias sobre la aplicación IHS Production.',
    paragraph4:
      'Todos los envíos de datos se realizan en un entorno seguro para proteger contra la observación o el uso fraudulento. No almacenamos su información financiera en el sitio web, como la información de la tarjeta de crédito.',
    paragraph5:
      'Los datos son visibles para los administradores y desarrolladores de IHS Production, así como para otros usuarios dentro de la misma empresa. Los documentos generados a través de IHS Production también pueden enviarse por correo electrónico a los clientes.',
    paragraph6:
      'IHS Production utiliza software de terceros para recopilar datos analíticos y registrar errores para ayudar a mejorar IHS Production. Estos incluyen Sentry (https://sentry.io/), Microsoft Application Insights y Google Analytics.',
  },
  copyrights: {
    title: 'COPYRIGHTS',
    paragraph1:
      'Copyright © IHS Production 2022. All rights reserved. The materials on this App, including all text, images, graphics, design concepts and information, are protected by applicable copyright laws. Reproducing, copying, duplicating, publishing, distributing, or use of any information on IHS Production Web App, in whole or part, is restricted (except as provided by the Terms & Conditions of this site or with written permission from IHS Production)',
  },
  support: {
    title: 'SOPORTE',
    paragraph1: 'En caso de problemas con tu cuenta, eliminacion o manejo de informacion de la misma comunicate con el email ',
    paragraph2: ' o el teléfono',
  },
  Team: 'The IHS Production Team',
  accessIhsHomeBuilder: 'Access IHS Production',
  about: 'About',
}
