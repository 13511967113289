import { reactive, toRefs } from 'vue'

const state = reactive({
  showDialogText: false,
  showDialogSticker: false,
  showDialogColors: false,
})

export const handleShowDialog = (type: 'showDialogText' | 'showDialogSticker' | 'showDialogColors', value: boolean) => {
  state[type] = value
}

export const useDialogs = () => {
  return {
    ...toRefs(state),
    handleShowDialog,
  }
}
