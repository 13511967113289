import { ProjectInterface, ProjectStageEnum, ProjectTypeEnum } from '@/models/project'
import { defineStore } from 'pinia'
import api from '@/services/api'
import { TPhotoContent } from '@/constants/types'
import { IProject } from '@/interfaces/project/project.interface'
import { IActivePunchList, IPunchList } from '@/interfaces/punch-list/punch-list.interface'
import { ITaskList } from '@/interfaces/punch-list/task-list/task-list.interface'
import { ITask } from '@/interfaces/punch-list/task/task.interface'
import { captureException } from '@sentry/vue'
import { IProblem } from '@/interfaces/problem'
import { ShareMediaPayload } from '@/interfaces/photo/photo.interface'
import { IPhotoFeed } from '@/components/PhotoFeedMedia/PhotoFeedMediaImageComponent.vue'
import { splitQueryParams } from '@/utils/splitQueryParams'

interface ICrewManager {
  fullName: string
  email?: string
  phone: string
  rating: null
}

export interface IAssignedCrews {
  id: string
  name: string
  crewCompanyId: string
  manager: ICrewManager
  reviews: []
  status: 'READY_FOR_PRODUCTION' | 'IN_PRODUCTION' | 'PENDING_REVIEW' | 'PENDING_PUNCHLIST' | 'COMPLETED'
  ratingCache: number
  editable?: boolean
  ratingCount: number
}

interface IWorkContacts {
  id: string
  fullName: string
  email?: string
  phone: string
  profile: string
  photoUrl?: string
  crewCompany: string
  zuid?: string
}

interface IFilterParams {
  skip?: number | null
  take?: number | null
  sort?: string | null
  projectType?: string | null
  projectStage?: string | null
}

interface IPagination {
  pageSize: number
  pageNumber: number
  pageCount: number
  rowCount: number
  nextPage: string | null
}

interface ProjectStore {
  loadingProjects: boolean
  loadingProject: boolean
  loadingProjectMedia: boolean
  loadingProjectPunchList: boolean
  loadingProjectDocuments: boolean
  loadingProjectAssignedCrews: boolean
  loadingProjectWorkContacts: boolean
  pagination: IPagination
  paginationMedia: IPagination
  projects: IProject[]
  projectsNear: IProject[]
  project: ProjectInterface
  projectMedia: TPhotoContent[]
  projectPunchList: IPunchList[]
  activePunchList: IActivePunchList | null
  activeTaskListId: string | null
  projectDocuments: []
  projectProblems: IProblem[]
  projectAssignedCrew: IAssignedCrews[]
  projectWorkContacts: IWorkContacts[]
  error: null | any
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const useProjectStore = defineStore({
  id: 'Projects',
  state: (): ProjectStore => ({
    loadingProjects: false,
    loadingProject: false,
    loadingProjectMedia: false,
    loadingProjectPunchList: false,
    loadingProjectDocuments: false,
    loadingProjectAssignedCrews: false,
    loadingProjectWorkContacts: false,
    pagination: {
      pageSize: 0,
      pageNumber: 0,
      pageCount: 0,
      rowCount: 0,
      nextPage: null,
    },
    projects: [],
    projectsNear: [],
    projectProblems: [],
    project: {} as ProjectInterface,
    projectMedia: [] as TPhotoContent[],
    paginationMedia: {
      pageSize: 0,
      pageNumber: 0,
      pageCount: 0,
      rowCount: 0,
      nextPage: null,
    },
    projectPunchList: [] as IPunchList[],
    activePunchList: null,
    activeTaskListId: null,
    projectDocuments: [],
    projectAssignedCrew: [] as IAssignedCrews[],
    projectWorkContacts: [] as IWorkContacts[],
    error: null,
  }),

  getters: {
    getProject: (state): ProjectInterface => state.project,
    getProjectMedia: (state): TPhotoContent[] => state.projectMedia,
    getProjectPunchList: (state): IPunchList[] => state.projectPunchList,
    getProjectDocuments: (state): [] => state.projectDocuments,
    getProjectAssignedCrew: (state): IAssignedCrews[] => state.projectAssignedCrew,
    getProjectsNear: (state): IProject[] => state.projectsNear,
    getProjectWorkContacts: (state): IWorkContacts[] => state.projectWorkContacts,
  },

  actions: {
    setDefaultProjects() {
      this.projects = []
    },
    setDefaultPagination() {
      this.pagination = {
        pageSize: 0,
        pageNumber: 0,
        pageCount: 0,
        rowCount: 0,
        nextPage: null,
      }
    },
    setLoadingProjects(state: boolean) {
      this.loadingProjects = state
    },
    setloadingProject(state: boolean) {
      this.loadingProject = state
    },
    setProjectError(error: any) {
      this.error = error
    },
    async fetchProjects(filterParams?: IFilterParams): Promise<void> {
      try {
        this.loadingProjects = true
        const params = []
        if (filterParams && filterParams.sort) params.push(`&sort=${filterParams.sort}`)
        params.push(`&filter.ProjectType=${filterParams?.projectType ?? 'ALL'}`)
        params.push(`&filter.ProjectStage=${filterParams?.projectStage ?? 'ALL'}`)
        if (filterParams && filterParams.skip) params.push(`&skip=${filterParams?.skip}`)
        if (filterParams && filterParams.take) params.push(`&take=${filterParams?.take}`)

        const response = await api.get(`/Api/v2/Projects?${params.join('')}`)

        this.pagination = response.data.data.pagination
        this.projects = [...this.projects, ...response.data.data.items]
      } catch (err) {
        captureException(err)
        this.error = err
        console.log(err)
      } finally {
        this.loadingProjects = false
      }
    },
    async fetchProjectById(id: string): Promise<void> {
      this.loadingProjects = true
      this.error = null
      try {
        const response = await api.get(`/Api/Projects/${id}`)
        this.project = response.data.data as ProjectInterface
      } catch (err) {
        captureException(err)
        this.project = {} as ProjectInterface
        this.error = err
        console.log(err)
        throw err
      } finally {
        this.loadingProjects = false
      }
    },
    async fetchProjectsNear(): Promise<void> {
      console.log(1)
    },
    setProjectMedia(media: TPhotoContent[]) {
      this.projectMedia = media
    },
    // TODO: Duplicate code
    async addPhotoToProject(payload: any) {
      if (this.project.id && this.project.id !== payload.projectId) this.project.photos.push(payload)

      const index = this.projects.findIndex((p) => p.id === payload.projectId)
      if (index === -1) return

      const updateIndex = this.projects[index].photos.findIndex((p: any) => {
        if (p?.url) {
          if (p.url.includes(payload?.fileName)) return true
        } else {
          if (p?.fileName) {
            if (payload.url.includes(p?.fileName)) return true
          }
        }
        return false
      })
      if (updateIndex === -1) this.projects[index].photos.push(payload)
      else this.projects[index].photos[updateIndex] = payload
    },
    async injectPhotoIntoProjectMedia(payload: TPhotoContent): Promise<void> {
      this.projectMedia = [...this.projectMedia, payload]
    },
    async setActivePunchList(apl: IActivePunchList | null): Promise<void> {
      this.activePunchList = apl
    },
    async setActiveTaskListId(id: string | null): Promise<void> {
      if (id === null) {
        this.activeTaskListId = null
      } else {
        this.activeTaskListId = id
      }
    },
    async injectTaskListIntoActivePunchList(newTaskList: ITaskList): Promise<void> {
      if (this.activePunchList) {
        this.activePunchList.taskLists = [...this.activePunchList.taskLists, newTaskList]
        await this.fetchProjectPunchList(this.project.id)
      }
    },
    async injectTasksIntoActiveTaskList(newTasks: ITask[]) {
      if (this.activePunchList) {
        const activeTaskList = this.activePunchList.taskLists.find((tl) => tl.id === this.activeTaskListId)
        if (activeTaskList) {
          activeTaskList.tasks = [...activeTaskList.tasks, ...newTasks]
        }
        await this.fetchProjectPunchList(this.project.id)
      }
    },
    setDefaultProjectsMedia() {
      this.paginationMedia = {
        pageSize: 0,
        pageNumber: 0,
        pageCount: 0,
        rowCount: 0,
        nextPage: '',
      }
      this.projectMedia = []
    },
    setSocketMedia(data: IPhotoFeed) {
      const searchInTheGroup = this.projectMedia?.slice(0, 25)
      const existThisMedia = searchInTheGroup?.find((e) => e?.id === data?.id)
      if (existThisMedia) return

      this.projectMedia = [
        {
          ...data,
          description: '',
          user: {
            ...data.user,
            photoUrl: data?.user?.photoUrl ?? '',
          },
        },
        ...this.projectMedia,
      ]
    },
    async fetchProjectMedia(id: string, filters?: any): Promise<void> {
      try {
        this.loadingProjectMedia = true

        // const filter = filters?.photoType?.length
        //   ? `?photoType=${filters?.photoType}&take=${filters?.take || 50}&skip=${filters?.skip || 0}`
        //   : `?take=${filters?.take || 50}&skip=${filters?.skip || 0}`

        const query = splitQueryParams(filters?.media)
        const filter = `?take=${filters?.take || 20}&skip=${filters?.skip || 0}&timeZone=${timezone}${query}`
        const res = await api.get(`/Api/v2/Projects/Photos/${id}${filter}`)
        const data = res?.data?.data.photos
        this.paginationMedia = res.data.data.pagination
        this.projectMedia = [...this.projectMedia, ...data]
      } catch (e) {
        captureException(e)
        console.log(e)
      } finally {
        this.loadingProjectMedia = false
      }
    },
    async fetchProjectProblems(id: string): Promise<void> {
      const {
        data: { data: resp },
      } = await api.get(`/Api/Projects/${id}/Problems`)
      this.projectProblems = [...resp]
    },
    setProjectProblems(problems: IProblem[]) {
      this.projectProblems = problems
    },
    // TODO: Duplicate code
    async fetchProjectPunchList(id: string): Promise<void> {
      try {
        this.loadingProjectPunchList = true
        const response = await api.get(`/Api/Projects/${id}/PunchLists`)
        this.projectPunchList = response.data.data
        this.loadingProjectPunchList = false
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        this.loadingProjectPunchList = false
      }
    },
    resetProjectPunchList() {
      this.projectPunchList = []
    },
    // TODO: Duplicate code
    async fetchAssignedCrews(id: string): Promise<void> {
      try {
        this.loadingProjectAssignedCrews = true
        const response = await api.get(`/Api/Projects/CrewCompanies/Project/${id}`)
        this.projectAssignedCrew = response.data.data
        this.loadingProjectAssignedCrews = false
      } catch (e) {
        captureException(e)
        console.log(e)
      } finally {
        this.loadingProjectAssignedCrews = false
      }
    },
    async fetchWorkContacts(id: string): Promise<void> {
      try {
        this.loadingProjectWorkContacts = true
        const response = await api.get(`/Api/Projects/${id}/Users/WorkContacts`)
        this.projectWorkContacts = response.data.data
        this.loadingProjectWorkContacts = false
      } catch (e) {
        captureException(e)
        console.log(e)
      } finally {
        this.loadingProjectWorkContacts = false
      }
    },
    async shareMedia(payload: ShareMediaPayload): Promise<any> {
      try {
        return await api.post(`/Api/Photos/Share`, payload)
      } catch (e) {
        captureException(e)
      }
    },
    fetchGallery(company: string, token: string): Promise<any> {
      return api.get(`/${company}/Api/Photos/Share/${token}`)
    },
    destroy() {
      this.loadingProjects = false
      this.loadingProject = false
      this.loadingProjectMedia = false
      this.loadingProjectPunchList = false
      this.loadingProjectDocuments = false
      this.loadingProjectAssignedCrews = false
      this.loadingProjectWorkContacts = false
      this.projects = []
      this.projectsNear = []
      this.project = {
        id: '',
        projectName: '',
        projectFolderLink: '',
        name: '',
        address: '',
        street: '',
        state: '',
        zipCode: '',
        city: '',
        isClosed: false,
        ownerManager: null,
        projectManager: null,
        contact: {
          email: '',
          firstName: '',
          fullName: '',
          lastName: '',
          mobile: '',
          phone: '',
          photo: '',
          zuid: '',
        },
        marketLocation: {
          id: '',
          name: '',
          address: '',
          phone: '',
          website: '',
        },
        paymentType: null,
        projectNumber: '',
        projectType: ProjectTypeEnum.NONE,
        amount: '',
        projectStage: ProjectStageEnum.NONE,
        photos: [],
        punchLists: null,
        crewCompanies: null,
        latitude: 0,
        longitude: 0,
        estimationLink: null,
        productionLink: null,
        contractLink: null,
        // distance?:        Distance;
        duration: undefined,
        actualStartDate: undefined,
        isAuthorized: false,
        poNumber: '',
      }
      this.projectMedia = []
      this.projectPunchList = []
      this.projectDocuments = []
      this.projectAssignedCrew = []
      this.projectWorkContacts = []
      this.error = null
    },
  },
})
