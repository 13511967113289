import { uploadPhotoType } from '@/db/tables'
import { Device } from '@capacitor/device'

type TDevice = 'ios' | 'android' | 'web'

type ITPhoto = {
  CAMERA: { [key in TDevice]: uploadPhotoType }
  UPLOAD: { [key in TDevice]: uploadPhotoType }
}

const typesPayload: ITPhoto = {
  CAMERA: { web: 'CAMERA_DESKTOP', android: 'CAMERA_MOVIL', ios: 'CAMERA_MOVIL' },
  UPLOAD: { web: 'LIBRARY_DESKTOP', android: 'LIBRARY_MOVIL', ios: 'LIBRARY_MOVIL' },
}

const devicePhotoTag = async (payload: keyof typeof typesPayload) => {
  const { platform } = await Device.getInfo()
  return typesPayload[payload][platform] ?? 'UNKNOWN'
}

export default devicePhotoTag
