import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes'
// import {store} from "@/store";

const router = createRouter({ history: createWebHistory(), routes })

// router.beforeEach(waitForStorageToBeReady)
// router.beforeEach(async () => {})

export default router
