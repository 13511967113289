import { VueRenderer } from '@tiptap/vue-3'
import tippy, { Instance, Props } from 'tippy.js'
import api from '@/services/api'
import MentionsList from './MentionsList.vue'

export default {
  items: async ({ query }: { query: any }) => {
    if (query && query.length > 0) {
      const {
        data: { data: resp },
      } = await api.get(`/Api/Users/Search?byName=true&q=${query}`)
      return [...resp].filter((item) => item.fullName.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
    } else {
      const {
        data: { data: resp },
      } = await api.get('/Api/Users/GetAllWithZuid')

      return [...resp].slice(0, 5)
    }
  },

  render: () => {
    let component: VueRenderer
    let popup: Instance<Props>[]
    let mentionItems: HTMLElement | null
    let noteContentBounds: DOMRect

    return {
      onStart: (props: any) => {
        const noteContent = document.getElementById('note_content') as HTMLElement

        noteContentBounds = noteContent?.getBoundingClientRect()

        setTimeout(() => {
          mentionItems = document.getElementById('mention-items')

          if (mentionItems) {
            mentionItems.style.width = noteContentBounds?.width + 'px'
          }
        }, 1)

        component = new VueRenderer(MentionsList, {
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: 'parent',
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'top-start',
        })
      },

      onUpdate(props: any) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}
