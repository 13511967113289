<!-- eslint-disable prefer-const -->
<script lang="ts" setup>
  import { onMounted, ref, watch } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import { useProjectStore } from '@/store'
  import { useEventBus } from '@vueuse/core'
  import api from '@/services/api'
  import { i18n } from '@/i18n'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import AutoComplete from 'primevue/autocomplete'
  import Avatar from 'primevue/avatar'
  import Button from 'primevue/button'
  import InputText from 'primevue/inputtext'
  import { IAddTask } from '@/interfaces/punch-list/task/task.interface'
  import { IUser } from '@/interfaces/user/user.interface'
  import { IQueuePhoto } from '@/db/tables'
  import { captureException } from '@sentry/vue'
  import { ICamera } from '@/components/PunchListCamera/Camera/CameraContainer.vue'
  import { Capacitor } from '@capacitor/core'
  import { Device } from '@capacitor/device'
  import UploadButtonComponent from '@/components/UploadButtonComponent.vue'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import PhotoList from '@/components/PhotosList/PhotoList.vue'
  import processMediaInputAndGetMediaIds from '@/utils/proccessMediaInputAndGetIds'
  import Editor from '@/components/editor_images/Editor.vue'

  const { t } = i18n.global
  const projectStore = useProjectStore()
  const { fetchProjectPunchList } = projectStore

  const toast = useToast()
  const isSubmitting = ref<boolean>(false)
  const punchListNameFieldValue = ref<string | null>(null)
  const punchListAssignToUserFieldValue = ref<IUser | null>(null)
  const punchListAssignToUserSuggestions = ref<IUser[]>([])
  const punchListTaskListFieldValue = ref<string | null>(null)
  const punchListTasks = ref<IAddTask[]>([])
  const addNewTaskDisabled = ref<boolean>(false)
  const camera = useEventBus<ICamera>('camera')

  const closeCamera = async (currentIndex: number, photos: IQueuePhoto[]) => {
    const uploadPhotoType = await devicePhotoTag('CAMERA')
    const taskCameraPhotos = photos.map((photo) => ({ ...photo, uploadPhotoType, type_media: 'IMAGE' })) as IQueuePhoto[]
    const photosUpload = punchListTasks.value[currentIndex]?.photos?.filter((photo) => {
      return photo.uploadPhotoType?.includes('LIBRARY')
    }) as IQueuePhoto[]
    punchListTasks.value[currentIndex].photos = [...taskCameraPhotos, ...photosUpload]
  }

  const props = defineProps({ projectId: { type: String, required: true } })
  const emit = defineEmits(['closeAddDialog'])

  const createPunchList = async () => {
    addNewTaskDisabled.value = true
    try {
      if (!punchListAssignToUserFieldValue.value) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'A user must be assigned', life: 3000 })
        isSubmitting.value = false
        return
      }

      isSubmitting.value = true

      const tasks = await Promise.all(
        punchListTasks.value.map(async (punch) => {
          const mediaIds = await processMediaInputAndGetMediaIds(punch.photos)
          return {
            title: `${punch.title}`,
            photoIds: mediaIds,
          }
        })
      )
      const payload = {
        name: punchListNameFieldValue.value,
        description: null,
        assignedToId: punchListAssignToUserFieldValue.value.id,
        projectId: props.projectId,
        taskLists: [{ title: punchListTaskListFieldValue.value, instructions: null, details: null, tasks }],
      }

      await api.post('/Api/Projects/PunchListTree', payload)
      await fetchProjectPunchList(props.projectId)
      emit('closeAddDialog')
      toast.add({ severity: 'success', summary: t('projectPunchListAdd.success'), detail: t('projectPunchListAdd.punchListCreated'), life: 3000 })
      isSubmitting.value = false
    } catch (e) {
      captureException(e)
      toast.add({ severity: 'error', summary: t('projectPunchListAdd.error'), detail: `${e}`, life: 3000 })
      isSubmitting.value = false
      addNewTaskDisabled.value = false
    }
  }

  const searchUsersToAssignToPunchList = async ($event: any) => {
    const term = $event.query
    punchListAssignToUserSuggestions.value = await getUsersToAssignPunchList(term)
  }

  const getUsersToAssignPunchList = async (searchTerm: string): Promise<any[]> => {
    const {
      data: { data: resp },
    } = await api.get(`/Api/Projects/${props.projectId}/Users/PunchList?&query=${searchTerm}`)
    return resp
  }

  function addTask() {
    punchListTasks.value.push({
      title: '',
      instructions: null,
      details: null,
      isPhotoRequired: true,
      photos: [],
    })
  }

  async function takePictures(index: number) {
    const previewPhotosCamera = punchListTasks.value[index]?.photos?.filter((photo) => {
      return photo?.uploadPhotoType?.includes('CAMERA')
    })

    camera.emit({
      propsCamera: { projectId: props.projectId, photoType: PhotoTypeEnum.TASK_BEFORE },
      cameraOptions: {
        photos: previewPhotosCamera,
      },
      actions: { closeCamera: (images) => closeCamera(index, images) },
    })
  }

  function deleteTask(index: number) {
    punchListTasks.value.splice(index, 1)
  }

  watch(punchListTasks.value, (oldValue, newValue) => {
    let isDisabled = false
    for (const task of newValue) {
      if (!task.title || task.title.length === 0) {
        isDisabled = true
        break
      }
      if (task.photos.length === 0) {
        isDisabled = true
        break
      }
    }
    addNewTaskDisabled.value = isDisabled
  })

  const handleImages = (currentIndex: number, images: IQueuePhoto[]) => {
    const activeTaskPhotos = punchListTasks.value[currentIndex]?.photos || []
    punchListTasks.value[currentIndex].photos = [...images, ...activeTaskPhotos]
  }

  const device = ref<'ios' | 'android' | 'web' | 'mac' | 'win'>('web')
  onMounted(async () => {
    if (Capacitor.isNativePlatform()) {
      const info = await Device.getInfo()
      device.value = info.platform
    }

    if (!Capacitor.isNativePlatform()) {
      const userAgent = navigator.userAgent.toLowerCase()
      const isWindows = userAgent.includes('win')
      if (isWindows) device.value = 'win'
      const isMac = userAgent.includes('mac')
      if (isMac) device.value = 'mac'
    }
  })

  const showDialogEditor = ref(false)
  const initialEditorImageSelected = {
    photoId: '',
    photoOURL: '',
    annotation: null,
    taskIndex: null,
  }
  const editor_image_selected = ref<{ photoId: string; photoOURL: string; annotation: string | null; taskIndex: number | null }>(initialEditorImageSelected)
  const handleChangePunchlistName = (value: string | undefined) => {
    punchListTaskListFieldValue.value = `${value} - ${t('projectPunchListAdd.label')}  `
  }
  const handleRemoveImageInTask = (index: number, id: string) => {
    const photos = punchListTasks.value[index].photos?.filter((e) => e?.id !== id)
    punchListTasks.value[index].photos = photos
  }
  const handleCloseEditor = () => {
    showDialogEditor.value = false
    editor_image_selected.value = initialEditorImageSelected
  }
  const handleSaveEditor = (data: { base_64: string; annotation: string; photoId: string }) => {
    const indx = Number(editor_image_selected?.value?.taskIndex)
    const photos = punchListTasks.value[indx].photos?.map((e) => {
      if (e.id === data?.photoId) {
        return {
          ...e,
          base64_annotation: data?.base_64,
          annotation: data?.annotation,
        }
      }
      return e
    })
    punchListTasks.value[indx].photos = photos
    showDialogEditor.value = false
    editor_image_selected.value = initialEditorImageSelected
  }
  const handleClickEditPhoto = (index: number, photo: IQueuePhoto) => {
    editor_image_selected.value = {
      photoId: photo.id,
      annotation: photo.annotation ?? null,
      photoOURL: photo.base64 ?? '',
      taskIndex: index,
    }
    showDialogEditor.value = true
  }
</script>

<template>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column gap-1">
      <label for="assignToUser">{{ t('projectPunchListAdd.assignToUser') }}</label>
      <AutoComplete
        v-model="punchListAssignToUserFieldValue"
        class="w-full"
        option-label="fullName"
        :placeholder="t('projectPunchListAdd.assignToUserPlaceholder')"
        :dropdown="true"
        force-selection
        :suggestions="punchListAssignToUserSuggestions"
        @complete="searchUsersToAssignToPunchList($event)"
      >
        <template #item="slotProps">
          <div class="flex justify-content-start align-items-center">
            <div>
              <Avatar :image="`https://contacts.zoho.com/file?ID=${slotProps.item.zuid}&fs=thumb`" class="mr-2" size="large" shape="circle" />
            </div>
            <div>
              <div class="ml-2 text-base">{{ slotProps.item.fullName }}</div>
              <div class="ml-2 text-muted text-sm">
                {{ slotProps.item.profile }}
                <span v-if="slotProps.item.crewCompany"> - {{ slotProps.item.crewCompany }}</span>
              </div>
            </div>
          </div>
        </template>
      </AutoComplete>
    </div>

    <div class="flex flex-column gap-1">
      <label for="name">{{ $t('projectPunchListAdd.punchList') }}</label>
      <InputText id="name" v-model="punchListNameFieldValue" type="text" class="p-inputtext w-full" @update:model-value="handleChangePunchlistName" />
    </div>

    <div class="flex flex-column gap-1">
      <label for="tasklist">{{ $t('projectPunchListAdd.tasklist') }}</label>
      <InputText id="tasklist" v-model="punchListTaskListFieldValue" type="text" class="p-inputtext w-full" />
    </div>
    <Editor
      v-if="showDialogEditor"
      :available-annotations="false"
      :photoid="editor_image_selected?.photoId"
      :photourl="editor_image_selected?.photoOURL"
      :annotation="editor_image_selected?.annotation"
      @close="handleCloseEditor"
      @save="handleSaveEditor"
    />
    <ul class="flex flex-column gap-4 p-0">
      <li v-for="(task, taskIndex) in punchListTasks" :key="`task-${taskIndex}`" class="flex flex-column gap-2">
        <div class="w-full flex flex-row align-items-center justify-content-between">
          <p class="font-bold text-center m-0">{{ t('projectPunchListAdd.taskName') }} {{ taskIndex + 1 }}</p>
          <Button v-if="punchListTasks.length > 1" icon="pi pi-trash" severity="danger" @click="deleteTask(taskIndex)" />
        </div>

        <div class="flex flex-column gap-2">
          <section class="flex flex-column w-full flex gap-2 align-items-end">
            <div class="flex flex-row gap-2 w-full">
              <InputText v-model="task.title" type="text" class="w-full" />
            </div>
          </section>
          <div class="flex flex-row align-items-center w-full align-items-end justify-content-end">
            <!-- <Button
              v-tooltip.bottom="'Camera'"
              :rounded="false"
              icon="pi pi-camera"
              :label="Capacitor.isNativePlatform() ? '' : t('projectMedia.capture')"
              class="p-button p-component RemoveText p-button-text ButtonInputFile ButtonInputImage"
              @click="takePictures(taskIndex)"
            /> -->
            <UploadButtonComponent
              entity-type="Project"
              photo-type="TASK_BEFORE"
              :project-id="props.projectId"
              :label-button="Capacitor.isNativePlatform() ? '' : t('projectMedia.upload')"
              class-button=" p-button-text ButtonInputFile ButtonInputImage "
              @on-media="(images) => handleImages(taskIndex, images)"
            />
          </div>
        </div>
        <PhotoList :photos="task.photos" @edit-photo="(photo) => handleClickEditPhoto(taskIndex, photo)" @remove="(id) => handleRemoveImageInTask(taskIndex, id)" />
      </li>
    </ul>

    <div class="flex justify-content-center w-full">
      <Button :disabled="addNewTaskDisabled" :label="t('projectPunchListAdd.addTask')" icon="pi pi-plus" class="p-button p-button-text" @click="addTask" />
    </div>
    <div class="flex justify-content-end">
      <Button
        :label="t('projectProblems.create')"
        class="w-full"
        :loading="isSubmitting"
        :disabled="
          addNewTaskDisabled ||
          !punchListNameFieldValue ||
          punchListNameFieldValue.length === 0 ||
          !punchListAssignToUserFieldValue ||
          !punchListTaskListFieldValue ||
          punchListTaskListFieldValue.length === 0 ||
          punchListTasks.length === 0 ||
          isSubmitting
        "
        @click="createPunchList"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 595px) {
    .ButtonInputImage {
      font-size: 0 !important;
    }
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .p-divider.p-divider-horizontal::before {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
</style>
