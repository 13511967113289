import { safeToString } from './filterByString'
import { FilterMatchModeOptions } from 'primevue/api'

function formatDateToMidnight(date: Date): number {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  date.toISOString()
  return date.getTime()
}

function generateFilterByDate<T>(matchMode: keyof FilterMatchModeOptions, value: string, key: keyof T) {
  const targetDate = new Date(value)

  if (isNaN(targetDate.getTime())) {
    throw new Error('Invalid date string')
  }
  console.log({
    matchMode,
  })

  switch (matchMode?.toUpperCase()) {
    case 'DATEIS':
      return (data: T) => {
        return formatDateToMidnight(new Date(safeToString(data[key]))) === targetDate.getTime()
      }
    case 'DATEISNOT':
      return (data: T) => formatDateToMidnight(new Date(safeToString(data[key]))) !== targetDate.getTime()
    case 'DATEBEFORE':
      return (data: T) => formatDateToMidnight(new Date(safeToString(data[key]))) < targetDate.getTime()
    case 'DATEAFTER':
      return (data: T) => formatDateToMidnight(new Date(safeToString(data[key]))) > targetDate.getTime()
    default:
      throw new Error('Invalid date match mode')
  }
}

export default generateFilterByDate
