<script lang="ts" setup>
  import { onMounted, Ref, ref, watch, toRefs } from 'vue'
  import api from '@/services/api'
  import { useToast } from 'primevue/usetoast'
  import { ICrewUser } from './Types'
  import { captureException } from '@sentry/vue'
  import { i18n } from '@/i18n'
  const { t } = i18n.global

  interface Iprops {
    projectId: string
    crewCompanyId?: string
    usersAssigns?: ICrewUser[]
  }

  function filtrarDatos<T>(datos: T[], filtro: T[], key: keyof T) {
    return datos.filter((dato) => filtro.every((f) => f[key] !== dato[key]))
  }
  const emit = defineEmits(['updateAssignedCrews'])
  const props = defineProps<Iprops>()
  const { usersAssigns, projectId } = toRefs(props)

  const toast = useToast()

  const assignedCrewsFieldValue: Ref<ICrewUser[]> = ref([])
  const assignedCrewsSuggestion: Ref<ICrewUser[]> = ref([])
  const isSubmitting = ref(false)

  watch(assignedCrewsFieldValue, (val) => {
    if (!val) return
    const data = assignedCrewsSuggestion.value
    const filtro = val
    console.log('value', { data, filtro })
  })

  const assignCrew = async () => {
    try {
      if (!assignedCrewsFieldValue.value?.length) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Error assigning crew', life: 3000 })
        isSubmitting.value = false
        return
      }
      console.log(assignedCrewsFieldValue.value, 'assignedCrewsFieldValue.value')

      isSubmitting.value = true
      const crewIds = assignedCrewsFieldValue.value.map((c) => c.id)
      const payload = { id: crewIds, crewCompanyId: props.crewCompanyId }
      await api.post(`/Api/CrewCompany/Projects/${projectId.value}/AssociateUsers`, payload)
      toast.add({ severity: 'success', summary: t('projectAssignCrewUsersAdd.success'), detail: t('projectAssignCrewUsersAdd.successAssigning'), life: 3000 })
      isSubmitting.value = false
      emit('updateAssignedCrews')
    } catch (err: any) {
      captureException(new Error(err))
      isSubmitting.value = false
      toast.add({
        severity: 'error',
        summary: t('projectAssignCrewUsersAdd.error'),
        detail: err.response.data.responseException.exceptionMessage.message || t('projectAssignCrewUsersAdd.errorassigning'),
        life: 3000,
      })
    }
  }

  const searchCrewToAssignToProject = async ($event: any) => {
    const term = $event.query as string
    assignedCrewsSuggestion.value = await searchCrewsToAssign({ query: term, crewCompanyId: props.crewCompanyId })
  }

  const resetAssignCrewModel = () => {
    assignedCrewsFieldValue.value = []
    assignedCrewsSuggestion.value = []
  }
  type filterParamsType = {
    query?: string
    crewCompanyId?: string
  }

  const searchCrewsToAssign = async (filterParams: filterParamsType): Promise<any[]> => {
    const params = []
    if (filterParams.query) params.push(`query=${filterParams.query}`)
    if (filterParams.crewCompanyId) params.push(`crewCompanyId=${filterParams.crewCompanyId}`)
    const url = `/Api/CrewUsers?${params.join('&')}`
    const {
      data: { data: resp },
    } = await api.get(url)
    return resp
  }

  onMounted(async () => {
    resetAssignCrewModel()
  })
</script>

<template>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column gap-2">
      <label for="assignToUser">{{ t('projectAssignCrewsAdd.selectCrew') }}</label>
      <AutoComplete
        v-model="assignedCrewsFieldValue"
        class="w-full"
        :dropdown="true"
        :force-selection="true"
        option-label="fullName"
        multiple
        :placeholder="assignedCrewsFieldValue.length !== 0 ? '' : t('projectAssignCrewUsersAdd.assignCrew')"
        :suggestions="filtrarDatos(assignedCrewsSuggestion, [...assignedCrewsFieldValue, ...(usersAssigns ?? [])], 'id')"
        :pt="{
          container: {
            style: {
              width: '100%',
            },
          },
        }"
        @complete="searchCrewToAssignToProject($event)"
      >
        <template #item="slotProps">
          <div class="flex justify-content-start align-items-center">
            <div>
              <img v-if="slotProps.item?.photoUrl" :src="slotProps.item?.photoUrl" height="50" />
              <img v-else src="/assets/default/crews.png" height="50" />
            </div>
            <div>
              <div class="ml-2 text-base w">{{ slotProps.item.name }}</div>
              <div v-if="slotProps.item.fullName" class="ml-2 text-base w">
                {{ slotProps.item.fullName }}
              </div>
            </div>
          </div>
        </template>
      </AutoComplete>
    </div>
    <Button :label="t('projectProblems.create')" class="w-full" :disabled="isSubmitting" :loading="isSubmitting" @click="assignCrew()" />
  </div>
</template>

<style lang="css" scoped></style>
