<script lang="ts" setup>
  import ImageComponent from '@/components/ImageComponent.vue'
  import LazyLoadComponent from '@/components/LazyLoadComponent.vue'
  import { i18n } from '@/i18n'
  import { toRefs } from 'vue'
  import { TPhotoContent } from '../../constants/types'

  const { t } = i18n.global

  interface IProps {
    photo: TPhotoContent
    isSelected?: boolean
    selectable?: boolean
    isTagAvailable?: boolean
    isUploadTypeAvailable: boolean
  }

  const severityTagMedia = {
    PROGRESS_PICTURES: 'info',
    PREPRODUCTION_PICTURES: 'info',
    TASK: 'warning',
    TASK_LIST: 'warning',
    TASK_AFTER: 'warning',
    TASK_BEFORE: 'warning',
    TASK_PICTURES: 'warning',
    FINAL_PICTURES: 'info',
    PROFILE_PICTURES: 'info',
    PROBLEM: 'danger',
  }

  const typePhoto = {
    PROGRESS_PICTURES: 'photoType.PROGRESS_PICTURES',
    PREPRODUCTION_PICTURES: 'photoType.PREPRODUCTION_PICTURES',
    TASK: 'photoType.TASK',
    TASK_LIST: 'photoType.TASK_LIST',
    TASK_AFTER: 'photoType.TASK_AFTER',
    TASK_BEFORE: 'photoType.TASK_BEFORE',
    TASK_PICTURES: 'photoType.TASK_PICTURES',
    FINAL_PICTURES: 'photoType.FINAL_PICTURES',
    PROFILE_PICTURES: 'photoType.PROFILE_PICTURES',
    PROBLEM: 'photoType.PROBLEM',
  }

  const props = defineProps<IProps>()
  const { photo, isTagAvailable = true, isUploadTypeAvailable = true } = toRefs(props)

  defineEmits<{ (e: 'selected', photo: TPhotoContent): void }>()

  const handleIcon = (type: 'unknown' | 'camera' | 'library') => {
    return photo.value.uploadType?.toLowerCase().includes(type)
  }
</script>

<template>
  <LazyLoadComponent class="cursor-pointer masonry-item shadow-2">
    <div class="image-select h-full w-full" :class="{ 'image-select--selected-border': isSelected }">
      <figure :class="{ 'image-select--selected-scale': isSelected }">
        <div v-if="selectable" class="image-select__selector p-2" @click.stop="$emit('selected', photo)">
          <div v-if="isSelected" class="image-select__selector__circle image-select__selector__circle--selected shadow-4">
            <i class="pi pi-check"></i>
          </div>
          <div v-else class="image-select__selector__circle shadow-4"></div>
        </div>
        <ImageComponent :url="photo?.variants?.[0]?.uri ?? photo?.url" @onerror="() => {}" @onload="() => {}" />
        <div v-if="isUploadTypeAvailable" class="absolute" style="top: 10px; right: 10px">
          <i v-if="handleIcon('library')" style="color: white; background-color: #3b82f6; border-radius: 5px" class="pi pi-upload p-2"></i>
          <i v-else-if="handleIcon('camera')" style="color: white; background-color: #3b82f6; border-radius: 5px" class="pi pi-camera p-2"></i>
          <i v-else style="color: white; background-color: #3b82f6; border-radius: 5px" class="pi pi-camera p-2"></i>
        </div>
        <Tag
          v-if="isTagAvailable"
          :value="t(typePhoto[photo.type as keyof typeof typePhoto ?? 'PROGRESS_PICTURES'] ?? typePhoto.PROGRESS_PICTURES)"
          :severity="severityTagMedia[photo.type as keyof typeof severityTagMedia] ?? 'info'"
          class="absolute bottom-0 right-0 mb-2 mr-2"
        />
      </figure>
    </div>
  </LazyLoadComponent>
</template>

<style lang="scss" scoped>
  .image-select {
    transition: border 0.1s ease-in-out;

    & > figure {
      transition: transform 0.1s ease-in-out;
    }

    &__selector {
      position: absolute;
      right: 4px;
      top: 4px;
      z-index: 1;

      &__circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #999999;

        &--selected {
          display: flex;
          justify-content: center;
          align-items: center;
          color: whitesmoke;
          background-color: var(--primary-color);
        }
      }
    }

    &--selected-scale {
      transform: scale(0.96);
    }

    &--selected-border {
      border: 2px solid var(--primary-color);
      border-radius: 4px;
    }
  }

  .hidden {
    display: none;
  }
  .masonry-item-container {
    height: 200px;
    width: 200px;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
