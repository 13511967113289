<script setup lang="ts">
  import { delay } from '@/utils/delay'
  import { ref, toRefs, watch } from 'vue'
  type IImageComponent = {
    url: string
  }
  const emit = defineEmits<{
    (e: 'onerror'): void
    (e: 'onload'): void
  }>()

  const props = defineProps<IImageComponent>()
  const { url } = toRefs(props)
  const MAX_ATTEMPTS = 10
  const attempts = ref(0)
  const imageSrc = ref(url.value)
  const hidden = ref(false)

  const addtime = (photo: string) => {
    if (!photo || photo.includes('base64')) {
      return photo
    }

    return photo
  }

  const handleError = async () => {
    if (attempts.value < MAX_ATTEMPTS) {
      // console.log(
      //   `Error al cargar la imagen. Intento ${intentos.value} de ${MAX_INTENTOS}.`
      // )
      // console.log('Intentando', imageSrc.value)
      // console.log('Intentando props', props.url)
      await delay(10000)
      attempts.value++
      // Intentar cargar otra vez la imagen, usando la misma ruta.
      //   imageSrc.value = `${props.url}?t=${Math.random()}`
      imageSrc.value = url.value
    } else {
      emit('onerror')
      // console.error(
      //   `No se pudo cargar la imagen después de ${MAX_INTENTOS} intentos.`
      // )

      await delay(1000)
      // Aquí puedes cargar una imagen alternativa o realizar cualquier otra acción.
      // Por ejemplo, cargar una imagen de reemplazo:
      // imageSrc.value = "ruta_de_la_imagen_alternativa.jpg";
    }
  }

  watch(url, (newUrl, oldValue) => {
    if (newUrl !== oldValue) {
      imageSrc.value = newUrl
      attempts.value = 0
    }
  })

  const handleLoad = () => {
    emit('onload')
    hidden.value = true
  }
</script>
<template>
  <img
    id="miImagen"
    :key="`${imageSrc}-${attempts}`"
    :src="addtime(imageSrc)"
    alt="media image"
    class="h-full w-full border-round-md lazyload"
    style="object-fit: cover"
    :class="!hidden ? 'hidden' : ''"
    @error="handleError"
    @load="handleLoad"
  />
</template>

<style lang="scss">
  .hidden {
    display: none;
  }
</style>
