import { usePushNotificationsMobile } from '@/services/pushNotification'
import { useUserStore } from '@/store'
import { Capacitor } from '@capacitor/core'
import { storeToRefs } from 'pinia'
import { usePushNotifications } from './usePushNotifications'

export const useUserInfo = () => {
  const { activePushNotification } = usePushNotificationsMobile()
  const userStore = useUserStore()
  const { initializeFirebase } = usePushNotifications()
  const { id } = storeToRefs(userStore)
  const { getUserInfo } = userStore
  const refeshInfo = async () => {
    if (id.value) {
      await getUserInfo()
      // if (Capacitor.isNativePlatform()) {
      //   // await activePushNotification()
      // } else {
      //   await initializeFirebase()
      // }
    }
  }
  const getInfoUserFirstTime = async () => {
    await getUserInfo()
    if (Capacitor.isNativePlatform()) await activePushNotification()
    else await initializeFirebase()
  }

  return { refeshInfo, getInfoUserFirstTime }
}
