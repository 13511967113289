<script lang="ts" setup>
  import ProjectsIndexMobile from './ProjectsIndexMobile.vue'
  import ProjectsIndexWeb from './ProjectsIndexWeb.vue'
  import { useBreakpointsStore } from '@/store'
  import { storeToRefs } from 'pinia'
  const breakpointStore = useBreakpointsStore()
  const { lg } = storeToRefs(breakpointStore)
</script>

<template>
  <ProjectsIndexWeb v-if="lg" />
  <ProjectsIndexMobile v-else />
</template>
