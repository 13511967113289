<script lang="ts" setup>
  import { ref, watch, toRef, type Ref } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import api from '@/services/api'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import Button from 'primevue/button'
  import { i18n } from '@/i18n'
  import InputText from 'primevue/inputtext'
  import { IAddTask } from '@/interfaces/punch-list/task/task.interface'
  import { IQueuePhoto } from '@/db/tables'
  import { captureException } from '@sentry/vue'
  import { useEventBus } from '@vueuse/core'
  import { ICamera } from '@/components/PunchListCamera/Camera/CameraContainer.vue'
  import UploadButtonComponentVue from '@/components/UploadButtonComponent.vue'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import { Capacitor } from '@capacitor/core'
  import PhotoList from '@/components/PhotosList/PhotoList.vue'

  import processMediaInputAndGetMediaIds from '@/utils/proccessMediaInputAndGetIds'
  import Editor from '@/components/editor_images/Editor.vue'
  type Iprops = {
    projectId: string
    taskListId: string
  }
  const props = defineProps<Iprops>()

  const taskListId = toRef(props, 'taskListId')

  const toast = useToast()
  const camera = useEventBus<ICamera>('camera')
  const { t } = i18n.global
  const isSubmitting = ref(false)
  const punchListTasks: Ref<IAddTask[]> = ref([{ title: null, instructions: null, details: null, isPhotoRequired: true, photos: [] }])
  const addNewTaskDisabled = ref(true)

  const emit = defineEmits(['closeAddTasksDialog'])
  const closeCamera = async (currentIndex: number, photos: IQueuePhoto[]) => {
    const uploadPhotoType = await devicePhotoTag('CAMERA')
    const photosTask = punchListTasks.value[currentIndex].photos
    const tasksPhotos = photos.map((photo) => ({ ...photo, uploadPhotoType }))
    const photosUpload = photosTask.filter((photo) => {
      return photo.uploadPhotoType?.includes('LIBRARY')
    })
    punchListTasks.value[currentIndex].photos = [...tasksPhotos, ...photosUpload]
  }

  const createTask = async () => {
    try {
      isSubmitting.value = true
      addNewTaskDisabled.value = true
      const tasks = await Promise.all(
        punchListTasks.value?.map(async (task) => {
          const mediaIds = await processMediaInputAndGetMediaIds(task.photos)
          return { taskListId: props.taskListId, title: task.title, instructions: null, details: null, isPhotoRequired: true, photoIds: mediaIds }
        })
      )
      await api.post('/Api/Projects/TaskTree', tasks)
      isSubmitting.value = false

      emit('closeAddTasksDialog')
      toast.add({ severity: 'success', summary: 'Success', detail: 'Successfully added tasks', life: 3000 })
    } catch (e) {
      captureException(e)
      toast.add({ severity: 'error', summary: 'Error', detail: `${e}`, life: 3000 })
      isSubmitting.value = false
      addNewTaskDisabled.value = false
    }
  }

  function addTask() {
    punchListTasks.value.push({ taskListId: taskListId.value, title: null, instructions: null, details: null, isPhotoRequired: true, photos: [] })
  }

  async function takePictures(index: number) {
    const photosCameraTask = punchListTasks.value[index].photos?.filter((photo) => {
      return photo?.uploadPhotoType?.includes('CAMERA')
    })

    camera.emit({
      propsCamera: { projectId: props.projectId, photoType: PhotoTypeEnum.TASK_BEFORE },
      cameraOptions: { photos: photosCameraTask },
      actions: { closeCamera: (images) => closeCamera(index, images) },
    })
  }

  function deleteTask(index: number) {
    punchListTasks.value.splice(index, 1)
  }

  watch(punchListTasks.value, (_, newValue) => {
    let isDisabled = false
    for (const task of newValue) {
      if (!task.title || task.title.length === 0) {
        isDisabled = true
        break
      }

      if (task.photos.length === 0) {
        isDisabled = true
        break
      }
    }

    addNewTaskDisabled.value = isDisabled
  })
  const handleImages = (currentIndex: number, images: IQueuePhoto[]) => {
    const activeTaskPhotos = punchListTasks.value[currentIndex]?.photos || []

    punchListTasks.value[currentIndex].photos = [...images, ...activeTaskPhotos]
  }

  const showDialogEditor = ref(false)
  const initialEditorImageSelected = {
    photoId: '',
    photoOURL: '',
    annotation: null,
    taskIndex: null,
  }
  const editor_image_selected = ref<{ photoId: string; photoOURL: string; annotation: string | null; taskIndex: number | null }>(initialEditorImageSelected)

  const handleRemoveImageInTask = (index: number, id: string) => {
    const photos = punchListTasks.value[index].photos?.filter((e) => e?.id !== id)
    punchListTasks.value[index].photos = photos
  }
  const handleCloseEditor = () => {
    showDialogEditor.value = false
    editor_image_selected.value = initialEditorImageSelected
  }
  const handleSaveEditor = (data: { base_64: string; annotation: string; photoId: string }) => {
    const indx = Number(editor_image_selected?.value?.taskIndex)
    const photos = punchListTasks.value[indx].photos?.map((e) => {
      if (e.id === data?.photoId) {
        return {
          ...e,
          base64_annotation: data?.base_64,
          annotation: data?.annotation,
        }
      }
      return e
    })
    punchListTasks.value[indx].photos = photos
    showDialogEditor.value = false
    editor_image_selected.value = initialEditorImageSelected
  }
  const handleClickEditPhoto = (index: number, photo: IQueuePhoto) => {
    editor_image_selected.value = {
      photoId: photo.id,
      annotation: photo.annotation ?? null,
      photoOURL: photo.base64 ?? '',
      taskIndex: index,
    }
    showDialogEditor.value = true
  }
</script>

<template>
  <div class="flex flex-column gap-3">
    <ul class="flex flex-column gap-4 p-0 m-0">
      <!-- <EditImageIndex
        v-if="isEditSelectedImage"
        :photo="selectedImage"
        @close-edit="
         (values) =>
           handleCloseEditImage({
             ...values?.image as TEditImage,
             Getphotos: (index) => punchListTasks[index]?.photos,
             onNewPhotos: (index, photos) => punchListTasks[index].photos = photos,
           })
       "
      /> -->
      <Editor
        v-if="showDialogEditor"
        :available-annotations="false"
        :photoid="editor_image_selected?.photoId"
        :photourl="editor_image_selected?.photoOURL"
        :annotation="editor_image_selected?.annotation"
        @close="handleCloseEditor"
        @save="handleSaveEditor"
      />
      <li v-for="(task, taskIndex) in punchListTasks" :key="`task-${taskIndex}`" class="flex flex-column gap-3">
        <div class="w-full flex flex-row align-items-center justify-content-between">
          <p class="font-bold text-center m-0">{{ t('projectPunchListAdd.taskName') }} {{ taskIndex + 1 }}</p>
          <Button v-if="punchListTasks.length > 1" icon="pi pi-trash" severity="danger" @click="deleteTask(taskIndex)" />
        </div>
        <div class="flex flex-column gap-2">
          <InputText v-model="task.title" type="text" class="p-inputtext flex-grow-1" />
          <div class="flex flex-row align-items-center w-full align-items-end justify-content-end">
            <!-- <Button
              v-tooltip.bottom="'Camera'"
              icon="pi pi-camera"
              :label="Capacitor.isNativePlatform() ? '' : t('projectMedia.capture')"
              class="p-button ButtonInputImageTask p-component RemoveText p-button-text ButtonInputFile ButtonInputImageCamera"
              @click="takePictures(taskIndex)"
            /> -->
            <UploadButtonComponentVue
              class-button="p-button-text ButtonInputFile ButtonInputImageTask"
              entity-type="Project"
              photo-type="TASK_BEFORE"
              :project-id="props.projectId"
              :label-button="Capacitor.isNativePlatform() ? '' : t('projectMedia.upload')"
              @on-media="(images) => handleImages(taskIndex, images)"
            />
          </div>
        </div>
        <PhotoList :photos="task.photos" @edit-photo="(photo) => handleClickEditPhoto(taskIndex, photo)" @remove="(id) => handleRemoveImageInTask(taskIndex, id)" />
        <!-- <PhotoList
          :photos="task.photos"
          @edit-photo="
            (image) =>
              handleOpenEditImage({
                id: image.id,
                index: taskIndex,
                base64: image.base64,
                base64Content: `${image.base64Content}`,
              })
          "
          @remove="
            (id) =>
              removePhoto(
                {
                  index: taskIndex,
                  Getphotos: () => task.photos,
                  onNewPhotos: (index, photos) => {
                    punchListTasks[index].photos = photos
                  },
                },
                id
              )
          "
        /> -->
      </li>
    </ul>

    <div class="flex justify-content-center w-full">
      <Button :disabled="addNewTaskDisabled" :label="t('projectPunchListAdd.addTask')" icon="pi pi-plus" class="p-button p-button-text" @click="addTask" />
    </div>

    <div class="flex justify-content-end">
      <Button
        :label="t('projectProblems.create')"
        class="w-full"
        :loading="isSubmitting"
        :disabled="addNewTaskDisabled || punchListTasks.length === 0 || isSubmitting"
        @click="createTask()"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 595px) {
    .ButtonInputImageTask {
      font-size: 0 !important;
    }
  }
  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .p-divider.p-divider-horizontal::before {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
</style>
