export const projectsIndexEnUS = {
  projects: 'Projects',
  sort: 'Sort',
  projectsFilter: 'Projects Filter',
  filterType: 'Filter Type',
  filterTypeSearch: 'Filter Type',
  filterStage: 'Filter Stage',
  filterStageSearch: 'Filter Stage',
  table: {
    headers: {
      poNumber: 'PO Number',
      salesPerson: 'Sales Person',
      projectManager: 'Project Manager',
      crewManager: 'Crew Manager',
      address: 'Address',
      stage: 'Stage',
      lastModified: 'Last Modification',
      customerName: 'Customer Name',
    },
  },
}

export const projectsIndexEsMX = {
  projects: 'Proyectos',
  sort: 'Ordenar',
  projectsFilter: 'Filtro de proyectos',
  filterType: 'Tipo de proyecto',
  filterTypeSearch: 'Filtrar tipo',
  filterStage: 'Etapa del proyecto',
  filterStageSearch: 'Filtrar etapa',
  table: {
    headers: {
      poNumber: 'PO Number',
      salesPerson: 'Vendedor',
      projectManager: 'Gerente de proyecto',
      crewManager: 'Gerente de equipo',
      address: 'Dirección',
      stage: 'Etapa',
      lastModified: 'Última modificación',
      customerName: 'Nombre del cliente',
    },
  },
}
