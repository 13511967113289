<script lang="ts" setup>
  import { onMounted, ref, toRefs, watch } from 'vue'
  import { IntialFilter, useLeadStore, useUserStore } from '@/store'
  import { storeToRefs } from 'pinia'
  // import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import ProjectMediaImageComponent from '@/components/ProjectMedia/ProjectMediaImageComponent.vue'

  import UploadButtonComponent from '@/components/UploadButtonComponent.vue' // ONIMG,
  import { IQueuePhoto, QueuePhotoType } from '@/db/tables'
  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { i18n } from '@/i18n'
  import InfiniteLoading from 'v3-infinite-loading'
  import 'v3-infinite-loading/lib/style.css'
  import api from '@/services/api'
  import GalleryComponent from '@/components/GalleryComponent.vue'
  import ShareImagesBtn from '@/components/ShareImagesBtn.vue'
  import { getPermission } from '@/components/PermissionRole/funtions'

  import ProjectMediaVideoComponent from '@/components/ProjectMedia/ProjectMediaVideoComponent.vue'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import { UploadEditoUpdatew } from '@/utils/uploadPhotoBlob'
  import FilterMediaComponent from '@/components/FilterMediaComponent.vue'
  import { useRouter } from 'vue-router'

  const { t } = i18n.global
  const props = defineProps({ projectId: { type: String, required: true } })
  // const emit = defineEmits(['uploading'])
  const userStore = useUserStore()
  const { permissions } = storeToRefs(userStore)
  const projectStore = useLeadStore()
  const { fetchProjectMedia, setDefaultProjectsMedia, setProjectMedia } = projectStore
  const takeValue = ref(10)
  const emit = defineEmits(['uploading'])
  const router = useRouter()

  const skipValue = ref(0)
  const { projectMedia, paginationMedia, loadingProjectMedia, project } = storeToRefs(projectStore)
  const loadingFile = ref(false)
  const isProjectMediaFirstRequestDone = ref(false)
  const filter = ref<IntialFilter>({ uploadType: null, photoType: null, userId: null, startDate: null, endDate: null })
  const queueStore = uploadNewQueuePhoto()
  const { queue } = toRefs(queueStore)
  const uploadFiles = async (photos: IQueuePhoto[]) => {
    emit('uploading', photos)
  }

  watch(queue, async () => {
    if ((queue.value ?? []).length == 0) {
      setTimeout(async () => {
        resetPagination()
        setDefaultProjectsMedia()
        await fetchProjectMedia(props.projectId)
      }, 1000)
    }
  })

  const handleApply = async (values: IntialFilter) => {
    filter.value = values
    setDefaultProjectsMedia()
    resetPagination()
  }

  const resetPagination = () => {
    takeValue.value = 10
    skipValue.value = 0
  }

  const deleteImage = async (id: string) => {
    await api.delete(`/Api/Lead/Photo/${id}`)
    setProjectMedia(projectMedia.value.filter((x) => x.id !== id))
  }

  const updateFavorite = async (value: { id: string; isFavorite: boolean }) => {
    console.log('value update', value)
    await api.patch(`/Api/Leads/Photo/${value.id}/SetFavorite`, { isFavorite: value.isFavorite })

    setProjectMedia(
      projectMedia.value.map((x) => {
        if (x.id === value.id) return { ...x, isFavorite: value.isFavorite }
        return x
      })
    )
  }

  const editImage = async (value: { photoId: string; base_64: string }) => {
    const media = projectMedia.value?.find((e) => e?.id === value?.photoId)
    if (!media) return
    setProjectMedia(
      projectMedia.value.map((x) => {
        if (x.id === value.photoId) {
          const newVariants = x.variants.map((variant) => {
            if (variant.variant === 'THUMBNAIL') return { ...variant, uri: value.base_64 }
            return variant
          })
          return { ...x, url: value.base_64, variants: newVariants }
        }
        return x
      })
    )
    const { latitude, longitude } = await fetchCurrentLocationOnce()

    await UploadEditoUpdatew(media.id, {
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      entityType: 'Lead',
      id: props.projectId,
      base64_annotation: value.base_64,
      photoType: media?.type as QueuePhotoType,
      photoTypeId: '',
    })
    // await api.patch(`/Api/Lead/${project.value.id}/Photo/${value.id}`, { base64Content: value.base64Content })
  }

  const handleScrollPagination = () => {
    if (paginationMedia.value?.nextPage === null) return
    if (loadingProjectMedia?.value) return

    skipValue.value = skipValue.value + 10
    takeValue.value = 10
  }

  watch(
    [skipValue, filter],
    async () => {
      await fetchProjectMedia(props.projectId, { skip: skipValue.value, take: takeValue.value, media: filter.value })
    },
    { deep: true }
  )

  onMounted(async () => {
    setDefaultProjectsMedia()
    await fetchProjectMedia(props.projectId)
  })
</script>

<template>
  <header class="flex align-items-center justify-content-between title-tab mb-3">
    <FilterMediaComponent type="Lead" :entity-id="project.id" @apply="handleApply" />
    <PermissionRole v-if="project.isAuthorized" :nodo-name="'leadsMediaPermissions'" :key-name="'capturePhoto'">
      <div class="flex">
        <PermissionRole :nodo-name="'leadsMediaPermissions'" :key-name="'shareImageGallery'">
          <ShareImagesBtn @click="router.push(`/leads/${project.id}/share-images`)" />
        </PermissionRole>
        <UploadButtonComponent
          entity-type="Lead"
          photo-type="PREPRODUCTION_PICTURES"
          :project-id="props.projectId"
          :label-button="t('projectMedia.upload')"
          :icon-button="!loadingFile ? 'pi pi-upload' : 'pi pi-spin pi-spinner'"
          class-button="p-button-text ButtonInputFile"
          @on-media="uploadFiles"
        />
      </div>
    </PermissionRole>
  </header>
  <GalleryComponent
    v-model:images="projectMedia"
    :loading-next-images="loadingProjectMedia"
    show-detail
    :show-add-tag="project.isAuthorized && getPermission(permissions, 'add', 'photoTag')"
    :show-comments="project.isAuthorized"
    :show-delete="project.isAuthorized"
    entity-type="Leads"
    :show-edit="project.isAuthorized"
    show-favorite
    @next="handleScrollPagination"
    @delete-image="(id) => deleteImage(id)"
    @edit-image="(data: any | undefined) => editImage(data)"
    @update-favorite="updateFavorite"
  >
    <template #item="slotProps">
      <ProjectMediaVideoComponent v-if="slotProps.item?.mediaType === 'VIDEO'" :is-upload-type-available="true" :photo="slotProps.item" :is-tag-available="true" />
      <ProjectMediaImageComponent v-else :is-upload-type-available="true" :photo="slotProps.item" :is-tag-available="true" />
    </template>
    <template #default>
      <div v-show="!loadingProjectMedia && paginationMedia?.nextPage !== null" class="flex justify-content-center py-3">
        <InfiniteLoading v-show="paginationMedia?.nextPage" @infinite="handleScrollPagination" />
      </div>
    </template>
  </GalleryComponent>
  <div v-if="!projectMedia.length && !loadingProjectMedia && isProjectMediaFirstRequestDone" class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem">
    <h6>{{ t('projectMedia.noMedia') }}</h6>
  </div>
</template>

<style lang="scss">
  #photo-comment .p-sidebar-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  #photo-comment .p-sidebar-header-content {
    width: 100%;
  }
  #photo-comment .p-sidebar-content {
    height: calc(100% - 63px);
    max-height: calc(100% - 63px);
    padding: 0;
    overflow: hidden;
  }
</style>

<style lang="scss" scoped>
  @media (max-width: 493px) {
    .ButtonLeadUpload {
      font-size: 0;
    }
  }
  .title-tab {
    min-height: 40px;
  }
  .masonry-item-container {
    height: 200px;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
<style scoped lang="scss">
  @media (max-width: 494px) {
    .ButtonInputFile {
      font-size: 0px;
    }
  }
</style>
