<script setup lang="ts">
  import api from '@/services/api'
  import { onMounted, ref, watch } from 'vue'
  import PhotoFeedMediaImageComponent, { IPhotoFeed } from '@/components/PhotoFeedMedia/PhotoFeedMediaImageComponent.vue'
  import { IUsersResponse } from '@/components/Home/Web/SideBarFilter.vue'
  import 'v3-infinite-loading/lib/style.css'
  import { IOption } from '@/interfaces/option/option.interface'
  import { i18n } from '@/i18n'
  import GalleryHomeComponent from '@/components/GalleryHomeComponent.vue'
  import { useSignalRMedia } from '@/composables/useSignalrMedia'
  import { onUnmounted } from 'vue'
  import { baseUrl } from '@/constants'
  import { storeToRefs } from 'pinia'
  import { useAccountStore } from '@/store'
  import { photoTypeOptions } from '@/options/photo/photo-types.options'
  import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import { UploadEditorPhoto } from '@/utils/uploadPhotoBlob'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import { QueuePhotoType } from '@/db/tables'
  interface IFilterParams {
    skip?: number
    take?: number
    sort?: string | null
    projectType?: string | null
    projectStage?: string | null
    UserId?: string | null
    startDate?: Date | null
    endDate?: Date | null
    ProjectId?: string | null
    photoType?: string | null
    tagId?: string | null
  }
  interface ITagResponse {
    data: TagObject[]
    pagination: IPaginationInfo
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const { t } = i18n.global
  const fechtPhotoFeedMedia = async (params: IFilterParams) => {
    let filterCount = 0
    const paramsQuery = []
    // console.log(params.endDate)
    if (params.skip || params.skip === 0) paramsQuery.push(`skip=${params.skip}`)
    if (params.take) paramsQuery.push(`&take=${params.take}`)
    if (params.sort) paramsQuery.push(`&sort=${params.sort}`)
    if (params.projectType) paramsQuery.push(`&projectType=${params.projectType}`)
    if (params.projectStage) paramsQuery.push(`&projectStage=${params.projectStage}`)
    if (params.photoType) {
      paramsQuery.push(`&photoType=${params.photoType}`)
      filterCount++
    }
    if (params.UserId) {
      paramsQuery.push(`&UserId=${params.UserId}`)
      filterCount++
    }
    if (params.startDate) {
      paramsQuery.push(`&startDate=${params.startDate.toISOString().slice(0, 10)}`)
      filterCount++
    }

    if (params.endDate) {
      paramsQuery.push(`&endDate=${params.endDate.toISOString().slice(0, 10)}`)
      filterCount++
    }
    if (params.ProjectId) {
      filterCount++
      paramsQuery.push(`&ProjectId=${params.ProjectId}`)
    }
    if (params.tagId) {
      filterCount++
      paramsQuery.push(`&photoTagId=${params.tagId}`)
    }
    const { data } = await api.get(`/api/v2/PhotoFeed?${paramsQuery.join('')}&timeZone=${timezone}`, {})
    return { data: data.data as { photos: IPhotoFeed[]; pagination: IPaginationInfo }, filterCount }
  }

  interface IPaginationInfo {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }
  interface IPhotoFeedResponse {
    photos: Map<string, IPhotoFeed[]>
    pagination: IPaginationInfo
  }

  export interface TagObject {
    tagId: string
    tagName: string
    name: string
    description: string | null
    photoCount: number
    createTime: string
    userId: string | null
  }
  const accountStore = useAccountStore()
  const { tenant } = storeToRefs(accountStore)
  const initialTakeValue = 18
  const initialSkipValue = 0
  const takeValue = ref(initialTakeValue)
  const skipValue = ref(initialSkipValue)
  const photoFeedResponse = ref<IPhotoFeedResponse>({
    photos: new Map<string, IPhotoFeed[]>(),
    pagination: {
      pageSize: 0,
      pageNumber: 0,
      pageCount: 0,
      rowCount: 0,
      nextPage: null,
    },
  } as IPhotoFeedResponse)
  const loadingPhotos = ref(false)
  const photosGallery = ref<IPhotoFeed[]>([])
  // const projectResponse = ref<IProjectsResponse[]>([])
  const usersResponse = ref<IUsersResponse[]>([])

  const filterCount = ref(0)
  const tagResponse = ref<ITagResponse>({} as ITagResponse)
  const usersFiltered = ref<IUsersResponse[]>([])

  const isLoadingChunks = ref(true)

  ///////------------
  const photoTypeSelected = ref<IOption | null>(null)
  const userSelected = ref<IUsersResponse | null>(null)
  const TagSelected = ref<TagObject | null>(null)
  const DateRangeSelected = ref<Date[]>()
  const dateStart = ref<Date | null>(null)
  const dateEnd = ref<Date | null>(null)
  const fetchphotoFeedUsers = async () => {
    const { data } = await api.get('/api/PhotoFeed/Users')
    usersResponse.value = data.data
    usersFiltered.value = data.data
  }

  const handleScrollPagination = () => {
    if (!photoFeedResponse?.value?.pagination?.nextPage) return
    skipValue.value = skipValue.value + takeValue.value
  }

  const handleEditImage = async (value: { photo: { photoId: string; base_64: string; annotation: string; date: string }; image: IPhotoFeed }) => {
    const keySection = value.image?.createdTime?.split('T')?.[0] ?? ''
    const photosOfTheSection = photoFeedResponse.value.photos.get(keySection) ?? []

    const handle = (x: IPhotoFeed) => {
      if (x.id === value.image.id) {
        const newVariants = x.variants.map((variant) => {
          if (variant.variant !== 'ORIGINAL') return { ...variant, uri: value?.photo?.base_64 }
          return variant
        })
        return { ...x, url: value?.photo?.base_64, variants: newVariants }
      }
      return x
    }
    const newPhotos = photosOfTheSection.map(handle)
    photosGallery.value = photosGallery?.value?.map(handle)
    photoFeedResponse.value.photos?.set(keySection, newPhotos)
    const { latitude, longitude } = await fetchCurrentLocationOnce()

    await UploadEditorPhoto(value?.image?.id, {
      ...value?.image,
      id: value?.image?.entityFeed?.id,
      annotation: value?.photo?.annotation,
      base64_annotation: value?.photo?.base_64,
      longitude: `${longitude}`,
      latitude: `${latitude}`,
      photoType: value?.image?.type as QueuePhotoType,
    })
  }

  const fetchPhotoFeedList = async () => {
    if (loadingPhotos?.value) return
    loadingPhotos.value = true
    const { data, filterCount: filterNumber } = await fechtPhotoFeedMedia({
      skip: skipValue.value,
      take: takeValue.value,
      UserId: userSelected.value?.id ?? null,
      ProjectId: null,
      endDate: dateEnd.value ?? null,
      startDate: dateStart.value ?? null,
      photoType: photoTypeSelected.value?.value ?? null,
      tagId: TagSelected.value?.tagId ?? null,
    })

    for (const element of data.photos) {
      const createdTime = element?.createdTime?.split('T')?.[0]
      // 2024-09-09T18:42:25Z
      const dataPhotos = photoFeedResponse.value.photos?.get(createdTime) ?? []
      photoFeedResponse.value.photos?.set(createdTime, [...dataPhotos, element])
    }
    // photoFeedResponse.value.photos = [...(photoFeedResponse.value?.photos ?? []), ...data.photos]
    photoFeedResponse.value.pagination = data.pagination
    filterCount.value = filterNumber
    loadingPhotos.value = false
  }
  const fechtTags = async (params?: { skip?: number; take?: number; query?: string }) => {
    const paramsQuery = []
    if (params?.query) paramsQuery.push(`query=${params.query}`)
    if (params?.skip || params?.skip === 0) paramsQuery.push(`&skip=${params.skip}`)
    if (params?.take) paramsQuery.push(`&take=${params.take}`)
    const response = await api.get(`/Api/PhotoFeed/Tags?${paramsQuery.join('')}`)
    tagResponse.value = response.data.data
  }
  const updateFavorite = async (value: { id: string; isFavorite: boolean; date: string }) => {
    await api.patch(`/Api/Leads/Photo/${value.id}/SetFavorite`, {
      isFavorite: value.isFavorite,
    })
    const findPhotosInSection = photoFeedResponse.value.photos?.get(value.date)
    if (!findPhotosInSection) return
    const newPhotos = findPhotosInSection.map((x) => {
      if (x.id === value.id) {
        return {
          ...x,
          isFavorite: value.isFavorite,
        }
      }
      return x
    })
    photoFeedResponse.value.photos?.set(value?.date, newPhotos)
  }

  const content = ref<HTMLDivElement>()

  const callback = (data: IPhotoFeed) => {
    const keySection = data?.createdTime?.split('T')?.[0] ?? ''
    const searchInGroup = photoFeedResponse.value?.photos?.get(keySection)
    const existThisPhoto = searchInGroup?.find((e) => e?.id === data?.id)
    if (existThisPhoto) return
    photoFeedResponse.value.photos.set(keySection, [data, ...(searchInGroup ?? [])])
  }
  const { startConnection, stopConnection } = useSignalRMedia(`${baseUrl}/media`, 'media', tenant.value as string, callback)

  const resetPagination = () => {
    photoFeedResponse.value.photos?.clear()
    photoFeedResponse.value.pagination = {
      pageSize: 0,
      pageNumber: 0,
      pageCount: 0,
      rowCount: 0,
      nextPage: 'RESET',
    }
    skipValue.value = initialSkipValue
    takeValue.value = initialTakeValue
    isLoadingChunks.value = true
  }
  const loadContent = async () => {
    if (!isLoadingChunks?.value) return

    const interval = setInterval(() => {
      if (!content?.value) return
      if (content?.value?.scrollTop + content?.value?.clientHeight >= content?.value?.scrollHeight) {
        if (loadingPhotos?.value) return
        if (photoFeedResponse?.value?.pagination?.nextPage === null) {
          console.log('STOP SKIP NEXT PAGE IS NULL CHUNCKS')
          isLoadingChunks.value = false
          clearInterval(interval)
          return
        }
        if (photoFeedResponse?.value?.pagination?.nextPage === 'RESET') {
          console.log('TRYING TO SKIP')

          return
        }
        console.log('SKIP NEW')
        isLoadingChunks.value = true
        skipValue.value = skipValue.value + takeValue.value
      } else {
        console.log('STOP SKIP CHUNCKS')
        isLoadingChunks.value = false
        clearInterval(interval)
      }
    }, 2000)
  }

  watch([skipValue], () => {
    fetchPhotoFeedList()
  })
  watch(isLoadingChunks, () => {
    loadContent()
  })
  watch([photoTypeSelected, userSelected, TagSelected, dateStart, dateEnd], async () => {
    resetPagination()
    fetchPhotoFeedList()
  })
  onMounted(() => {
    loadContent()
  })
  onMounted(() => {
    if (!content?.value) return

    if (photoFeedResponse?.value?.pagination?.nextPage !== null || !loadingPhotos?.value) {
      content.value.addEventListener('scroll', () => {
        if (loadingPhotos?.value) return
        if (photoFeedResponse?.value?.pagination?.nextPage === null) return
        if (!content?.value) return
        if (content.value.scrollTop + content.value.clientHeight >= content.value.scrollHeight - 10) {
          skipValue.value = skipValue.value + takeValue.value
        }
      })
    }
  })
  onMounted(() => {
    fetchPhotoFeedList()
    startConnection()
    fechtTags({ query: '', skip: 0, take: 50 })
    fetchphotoFeedUsers()
  })

  onUnmounted(() => {
    stopConnection()
  })

  const activeIndex = ref(0)
  const displayCustom = ref(false)
  const imageClick = (image: IPhotoFeed) => {
    const photos = Array.from(photoFeedResponse?.value?.photos?.values())?.flat()
    const findIndex = photos?.findIndex((e) => e?.id === image?.id)
    photosGallery.value = photos
    activeIndex.value = findIndex
    displayCustom.value = true
  }
  const handleClose = () => {
    activeIndex.value = 0
    displayCustom.value = false
  }
  const filteredTypes = ref<IOption[]>(photoTypeOptions)

  const searchTag = async (event: AutoCompleteCompleteEvent) => {
    await fechtTags({ query: event.query, skip: 0, take: 50 })
  }
  const searchTypePhoto = (e: AutoCompleteCompleteEvent) => {
    filteredTypes.value = photoTypeOptions.filter((type) => {
      return type.label.toLowerCase().includes(e.query.toLowerCase())
    })
  }
  const searchUser = (e: AutoCompleteCompleteEvent) => {
    usersFiltered.value = usersResponse.value.filter((user) => {
      return user.fullName.toLowerCase()?.includes(e.query.toLowerCase())
    })
  }
  const handleUpdateDates = (e: Date | Date[] | (Date | null)[] | null | undefined) => {
    if (!e || !Array.isArray(e)) return
    const startDate = e?.[0] ?? null
    const endDate = e?.[1] ?? null
    dateStart.value = startDate ? new Date(startDate) : null
    dateEnd.value = endDate ? new Date(endDate) : null
  }
  const handleUpdateToday = (e: Date) => {
    if (!e) return
    const startDate = e
    dateStart.value = startDate ? new Date(startDate) : null
    dateEnd.value = null
  }
</script>

<template>
  <div class="flex flex-column h-full w-full">
    <header class="mt-3 mb-4 flex flex-column">
      <h1 class="m-0">{{ t('homeIndex.home_desktop_title') }}</h1>
      <div class="flex flex-row gap-2">
        <div class="relative w-full">
          <Button
            v-if="dateStart !== null || dateEnd !== null"
            class="absolute p-button-text color-primary text-black-alpha-90"
            icon="pi pi-times"
            style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
            @click.stop="
              () => {
                DateRangeSelected = []
                dateStart = null
                dateEnd = null
              }
            "
          />
          <Calendar
            v-model="DateRangeSelected"
            class="w-full"
            selection-mode="range"
            :placeholder="t('sideBarPhotoFeed.rangePlaceHolder')"
            :manual-input="false"
            date-format="yy/mm/dd"
            show-icon
            show-button-bar
            hide-on-range-selection
            :pt="{
              dropdownButton: {
                root: {
                  style: {
                    backgroundColor: 'black',
                    borderColor: 'black',
                  },
                },
              },
            }"
            @update:model-value="handleUpdateDates"
            @today-click="(date:Date) => {
              handleUpdateToday(date)
          }"
            @clear-click="() => {}"
          />
        </div>
        <div class="relative w-full">
          <Button
            v-if="photoTypeSelected !== null"
            class="absolute p-button-text color-primary text-black-alpha-90"
            icon="pi pi-times"
            style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
            @click.stop="photoTypeSelected = null"
          />
          <AutoComplete
            v-model="photoTypeSelected"
            class="w-full"
            dropdown
            option-label="label"
            :placeholder="t('sideBarPhotoFeed.photoTypePlaceHolder')"
            :suggestions="
              filteredTypes.map((sort) => ({
                ...sort,
                label: t(sort.label),
              }))
            "
            :pt="{
              dropdownButton: {
                root: {
                  style: {
                    backgroundColor: 'black',
                    borderColor: 'black',
                  },
                },
              },
            }"
            @complete="searchTypePhoto"
            @change="
              (e) => {
                if (!e?.value.length) {
                  filteredTypes = photoTypeOptions
                }
              }
            "
          >
            <template #item="slotProps">
              <div class="flex justify-content-start align-items-center">
                <p>{{ slotProps.item.label }}</p>
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="relative w-full">
          <Button
            v-if="TagSelected !== null"
            class="absolute p-button-text color-primary text-black-alpha-90"
            icon="pi pi-times"
            style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
            @click.stop="TagSelected = null"
          />
          <AutoComplete
            v-model="TagSelected"
            class="w-full"
            dropdown
            option-label="name"
            :placeholder="t('sideBarPhotoFeed.tagPhotoPlaceHolder')"
            :suggestions="tagResponse?.data ?? []"
            :pt="{
              dropdownButton: {
                root: {
                  style: {
                    backgroundColor: 'black',
                    borderColor: 'black',
                  },
                },
              },
            }"
            @complete="searchTag"
          >
            <template #item="slotProps">
              <div class="flex justify-content-start align-items-center">
                <p>{{ slotProps.item.name }}</p>
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="relative w-full">
          <Button
            v-if="userSelected !== null"
            class="absolute p-button-text color-primary text-black-alpha-90"
            icon="pi pi-times"
            style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
            @click.stop="userSelected = null"
          />
          <AutoComplete
            v-model="userSelected"
            dropdown
            class="w-full"
            option-label="fullName"
            :placeholder="t('sideBarPhotoFeed.userPlaceHolder')"
            :suggestions="usersFiltered"
            :pt="{
              dropdownButton: {
                root: {
                  style: {
                    backgroundColor: 'black',
                    borderColor: 'black',
                  },
                },
              },
            }"
            @complete="searchUser"
            @change="
              (e) => {
                if (!e?.value.length) {
                  usersFiltered = [...usersResponse]
                }
              }
            "
          >
            <template #item="slotProps">
              <div class="flex justify-content-start align-items-center">
                <p>{{ slotProps.item.fullName }}</p>
              </div>
            </template>
          </AutoComplete>
        </div>
      </div>
    </header>

    <p v-if="!Array.from(photoFeedResponse?.photos?.values())?.length && !loadingPhotos">{{ t('homeIndex.START_TAKING_PHOTOS') }} &#128293;</p>
    <div ref="content" class="w-full overflow-y-scroll overflow-x-hidden">
      <template
        v-for="stage of Array.from(photoFeedResponse?.photos, ([key, items]) => ({
          key, // La clave del Map
          date: key, // Generar una fecha actual
          items, // Los valores asociados a la clave
        }))"
        :key="`${stage?.date ?? index}`"
      >
        <section class="relative w-full">
          <header class="pt-2 pb-2">
            <p class="font-bold m-0 p-0" style="font-size: 1.4rem">{{ useDateFormatUtil(stage?.date, 'dddd, MMMM Do, YYYY') }}</p>
          </header>
          <ul class="list-none m-0 p-0 flex flex-row flex-wrap w-full">
            <li v-for="image of stage?.items" :key="`section-${stage?.date}-${image?.id}`" class="relative m-0 p-0" style="width: 150px !important">
              <span
                style="
                  z-index: 1;
                  position: absolute;
                  right: 7px;
                  top: 10px;
                  padding: 5px;
                  cursor: pointer;
                  color: #ffc400;
                  background-color: rgba(0, 0, 0, 0.4);
                  border-radius: 6px;
                "
                @click="
                  () =>
                    updateFavorite({
                      id: `${image?.id}`,
                      isFavorite: !image.isFavorite,
                      date: stage?.date,
                    })
                "
              >
                <i :class="`pi ${image.isFavorite ? 'pi-star-fill' : 'pi-star'}`" style="font-size: 1rem"></i>
              </span>
              <div
                class="w-full masonry-item-container col col-6 sm:col-4 md:col-3 lg:col-3 xl:w-full lg-item-container lg-item relative p-1"
                style="height: 153px"
                @click="imageClick(image)"
              >
                <PhotoFeedMediaImageComponent :photo="image" />
              </div>
            </li>
          </ul>
        </section>
      </template>
    </div>

    <GalleryHomeComponent
      :active-index="activeIndex"
      :display-custom="displayCustom"
      :images="photosGallery"
      show-detail
      show-comments
      show-edit
      show-favorite
      @next="handleScrollPagination"
      @update-favorite="updateFavorite"
      @edit-image="handleEditImage"
      @on-close="handleClose"
    />
  </div>
</template>

<style lang="scss" scoped>
  .footer_loading {
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    width: 100%;
  }
  .masonry-item-container {
    height: 200px;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
