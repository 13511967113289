export const SettingsPermissionInfoEnUs = {
  title: 'Settings Permission Info',
  cameraTitle: 'Camera',
  cameraDescription: 'Permission to access your camera.',
  geolocationTitle: 'GeoLocation',
  geolocationDescription: 'Permission to access your location.',
  notificationTitle: 'Notifications',
  notificationDescription: 'Permission to received Notifications.',
  textButton: 'request permission',
  alertTitle: 'Permission Required',
  FACEID: 'Face ID',
  FACEID_DESC: 'Permission to access the face id.',
  alertMessage: 'Something is blocking the permissions, check the page or browser settings',
}

export const SettingsPermissionInfoEsMx = {
  title: 'Configuración de permisos',
  cameraTitle: 'Cámara',
  FACEID: 'Face ID',
  FACEID_DESC: 'Permiso para habilitar face id.',
  cameraDescription: 'Permiso para acceder a tu cámara.',
  geolocationTitle: 'Geolocalización',
  geolocationDescription: 'Permiso para acceder a tu ubicación.',
  notificationTitle: 'Notificaciones',
  notificationDescription: 'Permiso para recibir notificaciones.',
  textButton: 'solicitar permiso',
  alertTitle: 'Permiso requerido',
  alertMessage: 'Al parecer algo esta bloqueando los permisos, verifica la configuracion de la pagina o del navegador',
}
