<!-- eslint-disable @typescript-eslint/ban-ts-comment -->
<script lang="ts" async setup>
  import { Capacitor } from '@capacitor/core'
  import { onMounted, reactive, ref, onUnmounted } from 'vue'
  import { Haptics, ImpactStyle } from '@capacitor/haptics'
  import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator'
  import api from '@/services/api'
  import { checkIsBiometricAvailable, setCredentialsNative } from '@/services/biometric'
  import { useBreakpointsStore, useUserStore } from '@/store'
  import { i18n } from '@/i18n'
  import { storeToRefs } from 'pinia'
  const { t } = i18n.global
  const emit = defineEmits(['submitSuccess', 'submitError'])

  const errorMessage = ref<null | string>(null)

  interface IField {
    value?: string
    labelText: string
    isDisabled: boolean
  }

  interface IButton {
    labelText: string
    icon: string
    isActive: boolean
    isLoading: boolean
    isDisabled: boolean
  }

  interface IChangePassword {
    currentPassword: IField
    newPassword: IField
    confirmPassword: IField
    submit_button: IButton
  }

  const form = reactive({
    newPassword: '',
    confirmPassword: '',
    currentPassword: '',
  })
  //@ts-ignore
  const rules = {
    newPassword: [
      { required: true, message: t('changePassword.credentialsAreRequired') },
      {
        min: 8,
        message: t('changePassword.passwordMinLength'),
      },
    ],
    confirmPassword: [
      {
        required: true,
        message: t('changePassword.credentialsAreRequired'),
      },
      {
        //@ts-ignore
        validator: (rule, value, callback, source) => {
          return value === source.newPassword
        },
        message: t('changePassword.passwordsDontMatch'),
      },
    ],
    currentPassword: [
      {
        required: true,
        message: t('changePassword.credentialsAreRequired'),
      },
    ],
  }

  const ChangePassword: IChangePassword = reactive({
    currentPassword: {
      labelText: t('changePassword.actualPassword'),
      isDisabled: false,
    },
    newPassword: {
      labelText: t('changePassword.newPassword'),
      isDisabled: false,
    },
    confirmPassword: {
      labelText: t('changePassword.confirmPassword'),
      isDisabled: false,
    },

    submit_button: {
      labelText: t('changePassword.changePassword'),
      icon: 'pi pi-chevron-right',
      isActive: false,
      isLoading: false,
      isDisabled: false,
    },
  })

  const { errorFields, pass } = useAsyncValidator(form, rules)
  const breakpointsStore = useBreakpointsStore()

  const { md } = storeToRefs(breakpointsStore)
  const { email } = useUserStore()
  const faceIdDetected = ref(false)

  async function handleSubmit() {
    try {
      ChangePassword.submit_button.isLoading = true
      if (Capacitor.isNativePlatform()) await Haptics.impact({ style: ImpactStyle.Medium })
      if (!pass.value) {
        const errorMessages = errorFields.value
        const errorMessagesNewPassword = errorMessages?.newPassword
        const errorMessagesConfirmPassword = errorMessages?.confirmPassword
        const errorMessagesCurrentPassword = errorMessages?.currentPassword

        if (errorMessagesCurrentPassword) {
          errorMessage.value = errorMessagesCurrentPassword[0].message ?? ''
        } else if (errorMessagesNewPassword) {
          errorMessage.value = errorMessagesNewPassword[0].message ?? ''
        } else if (errorMessagesConfirmPassword) {
          errorMessage.value = errorMessagesConfirmPassword[0].message ?? ''
        }

        ChangePassword.submit_button.isLoading = false
        return
      }

      errorMessage.value = null

      const payload = {
        currentPassword: form.currentPassword,
        password: form.newPassword,
        confirmPassword: form.confirmPassword,
      }

      await api.patch(`/Api/Users/UpdatePassword`, payload)

      await setCredentialsNative(email, payload?.confirmPassword)
      emit('submitSuccess')
    } catch (error: any) {
      let message = 'Error Request'
      if (error.response?.data?.responseException?.exceptionMessage) message = error.response?.data?.responseException?.exceptionMessage
      emit('submitError', message)
    } finally {
      ChangePassword.submit_button.isLoading = false
    }
  }
  onMounted(async () => {
    const result = await checkIsBiometricAvailable()
    if (result) {
      faceIdDetected.value = true
    }
  })
  onUnmounted(() => {
    faceIdDetected.value = false
  })
</script>

<template>
  <form class="w-full flex flex-column gap-3" @submit.prevent="handleSubmit()">
    <div class="w-full gap-2 flex flex-column">
      <p class="font-bold m-0">
        {{ ChangePassword.currentPassword.labelText }}
      </p>
      <Password
        v-model="form.currentPassword"
        toggle-mask
        class="w-full"
        input-class="w-full"
        :feedback="false"
        :input-props="{
          disabled: ChangePassword.currentPassword.isDisabled,
        }"
        :placeholder="ChangePassword.currentPassword.labelText"
        name="confirmPassword"
      />
    </div>
    <div class="w-full gap-2 flex flex-column">
      <p class="font-bold m-0">
        {{ ChangePassword.newPassword.labelText }}
      </p>
      <Password
        v-model="form.newPassword"
        toggle-mask
        class="w-full"
        input-class="w-full"
        :feedback="false"
        :input-props="{
          disabled: ChangePassword.newPassword.isDisabled,
        }"
        :placeholder="ChangePassword.newPassword.labelText"
        name="newPassword"
      />
    </div>
    <div class="w-full gap-2 flex flex-column">
      <p class="font-bold m-0">
        {{ ChangePassword.confirmPassword.labelText }}
      </p>
      <Password
        v-model="form.confirmPassword"
        toggle-mask
        class="w-full"
        input-class="w-full"
        :feedback="false"
        :input-props="{
          disabled: ChangePassword.confirmPassword.isDisabled,
        }"
        :placeholder="ChangePassword.confirmPassword.labelText"
        name="confirmPassword"
      />
    </div>
    <p v-if="faceIdDetected">{{ t('settingsIndex.FACEID_PASSWORD') }}</p>
    <Message
      v-if="errorMessage"
      severity="error"
      :closable="false"
      icon="pi pi-lock"
      :pt="{
        root: {
          style: {
            margin: '0px',
          },
        },
      }"
      >{{ errorMessage }}
    </Message>

    <div class="flex gap-3">
      <Button
        type="submit"
        class="w-full"
        icon-pos="right"
        :icon="ChangePassword.submit_button.icon"
        :label="t('settingsIndex.labelPassword')"
        :loading="ChangePassword.submit_button.isLoading"
        :disabled="ChangePassword.submit_button.isDisabled"
      />
    </div>
  </form>

  <div
    :style="{
      marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
    }"
  ></div>
</template>
