export const mimetypes = [
  {
    ext: 'aac',
    type: 'audio',
  },
  {
    ext: 'avif',
    type: 'image',
  },
  {
    ext: 'avi',
    type: 'video',
  },
  {
    ext: 'bmp',
    type: 'image',
  },
  {
    ext: 'csv',
    type: 'document',
  },
  {
    ext: 'docx',
    type: 'document',
  },
  {
    ext: 'doc',
    type: 'document',
  },
  {
    ext: 'giv',
    type: 'image',
  },
  {
    ext: 'jpeg',
    type: 'image',
  },
  {
    ext: 'jpg',
    type: 'image',
  },
  {
    ext: 'mid',
    type: 'audio',
  },
  {
    ext: 'midi',
    type: 'audio',
  },
  {
    ext: 'mp3',
    type: 'audio',
  },
  {
    ext: 'mp4',
    type: 'video',
  },
  {
    ext: 'mpeg',
    type: 'video',
  },
  {
    ext: 'oga',
    type: 'audio',
  },
  {
    ext: 'ogv',
    type: 'video',
  },
  {
    ext: 'png',
    type: 'image',
  },
  {
    ext: 'pdf',
    type: 'pdf',
  },
  {
    ext: 'tif',
    type: 'image',
  },
  {
    ext: 'tiff',
    type: 'image',
  },
  {
    ext: 'ts',
    type: 'video',
  },
  {
    ext: 'wav',
    type: 'audio',
  },
  {
    ext: 'weba',
    type: 'audio',
  },
  {
    ext: 'webm',
    type: 'video',
  },
  {
    ext: 'webp',
    type: 'image',
  },
  {
    ext: 'xls',
    type: 'document',
  },
  {
    ext: 'xlsx',
    type: 'document',
  },
]
