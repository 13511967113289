<script lang="ts" setup>
  import { ProjectInterface } from '@/models/project'
  import { Browser } from '@capacitor/browser'
  import { Capacitor } from '@capacitor/core'
  import { onMounted, ref } from 'vue'
  import { i18n } from '@/i18n'
  const { t } = i18n.global

  const openCapacitorSite = async (url: string) => {
    await Browser.open({ url: url, presentationStyle: 'fullscreen' })

    Browser.addListener('browserFinished', async () => {
      await Browser.removeAllListeners()
    })
  }
  const documents = ref<{
    [key: string]: {
      url: string | null
      text: string
    }
  }>({
    estimation: { url: '', text: t('projectDocuments.estimation') },
    production: { url: '', text: t('projectDocuments.production') },
    contractLink: { url: '', text: t('projectDocuments.contract') },
    Project: {
      url: '',
      text: t('projectDocuments.project'),
    },
  })

  const openBrowser = async (url: string) => {
    if (Capacitor.isNativePlatform()) await openCapacitorSite(url)
    else window.open(url, '_blank')
  }
  interface IProps {
    projectData: ProjectInterface
  }

  const props = defineProps<IProps>()
  onMounted(() => {
    documents.value.estimation.url = props.projectData.estimationLink
    documents.value.production.url = props.projectData.productionLink
    documents.value.contractLink.url = props.projectData.contractLink
    documents.value.Project.url = props?.projectData?.projectFolderLink ?? ''
  })
</script>

<template>
  <div class="col-12">
    <div class="flex align-items-center justify-content-between title-tab">
      <div class="flex text-xl align-items-center">
        <div class="flex pr-2">
          <i class="pi pi-folder" />
        </div>
        <div class="flex text-xl">Documents</div>
      </div>
    </div>
    <div
      v-if="props.projectData.estimationLink === null && props.projectData.productionLink === null && props.projectData.contractLink === null"
      class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem"
    >
      <h6 class="text-center font-bold">
        {{ t('projectDocuments.projectOld') }}
      </h6>
    </div>
    <div v-else class="flex w-full">
      <div class="flex w-full align-items-start flex-auto gap-2 justify-content-start flex-wrap">
        <template v-for="value in documents" :key="`document-${value.text}`">
          <div v-if="value.url" class="flex">
            <div class="flex flex-column align-items-center justify-content-center" style="max-width: 144px; cursor: pointer" @click="openBrowser(value.url)">
              <div class="flex align-items-center justify-content-center w-full" style="max-height: 120px">
                <svg width="100" height="100" viewBox="0 0 263 211" fill="none">
                  <path d="M232.5 29.75H118.875L93.625 4.5H30.5C16.6125 4.5 5.25 15.8625 5.25 29.75V80.25H257.75V55C257.75 41.1125 246.388 29.75 232.5 29.75Z" fill="#FFA000" />
                  <path
                    d="M232.5 29.75H30.5C16.6125 29.75 5.25 41.1125 5.25 55V181.25C5.25 195.138 16.6125 206.5 30.5 206.5H232.5C246.388 206.5 257.75 195.138 257.75 181.25V55C257.75 41.1125 246.388 29.75 232.5 29.75Z"
                    fill="#FFCA28"
                  />
                  <defs>
                    <clipPath id="clip0_80522_62">
                      <rect width="50.4673" height="45" fill="white" transform="translate(199 42)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <p class="text-center font-bold" style="max-width: 90%; text-overflow: ellipsis; overflow: hidden; display: block">
                {{ value.text }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
  .title-tab {
    min-height: 40px;
  }
</style>
