export const separateInfo = (texto = '') => {
  const regex = /\(([^|]+)\|([^\])]+)\]*/ // non-global regex
  const partes = texto.split('\n\n')

  if (partes.length === 2) {
    const match = partes[0].match(regex) // use match() instead of exec()
    if (match && match[1] && match[2]) return { author: match[1], valor: match[2], info: partes[1] }
  }

  return { info: partes[0] }
}
