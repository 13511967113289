<script setup lang="ts">
  import ProjectMediaImageComponent from '@/components/ProjectMedia/ProjectMediaImageComponent.vue'
  import { TPhotoContent } from '@/constants/types'
  import { useProjectStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { computed, onMounted, ref } from 'vue'

  type Iprops = {
    visible: boolean
    projectId: string
  }

  const props = defineProps<Iprops>()
  const projectStore = useProjectStore()
  const { fetchProjectMedia } = projectStore
  const { projectMedia } = storeToRefs(projectStore)
  const photosSelected = ref<TPhotoContent[]>([])

  const emit = defineEmits(['update:visible', 'submitPhotos'])

  const isVisible = computed({
    get: () => props.visible,
    set: (value) => {
      photosSelected.value = []
      emit('update:visible', value)
    },
  })

  onMounted(async () => {
    await fetchProjectMedia(props.projectId, { photoType: 'PROGRESS_PICTURES' })
  })
</script>

<template>
  <Sidebar
    id="select-photo-sidebar"
    v-model:visible="isVisible"
    append-to="body"
    :dismissable="true"
    position="full"
    :show-close-icon="true"
    class="absolute p-0"
    :pt="{
      content: { class: 'p-0' },
    }"
  >
    <div class="w-full h-full flex flex-column">
      <div id="scroll-container" class="flex flex-column flex-grow-1 h-full overflow-auto" style="padding-left: 1.25rem; padding-right: 1.25rem">
        <div class="grid w-full">
          <div v-for="photo in projectMedia" :key="photo.id" :data-src="photo.url" class="masonry-item-container col col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3 lg-item">
            <div
              class="relative w-full h-full"
              :class="{
                'border-selected': photosSelected.some((p) => p.id === photo.id),
              }"
            >
              <Button
                class="absolute z-1 p-2"
                :severity="photosSelected.some((p) => p.id === photo.id) ? 'danger' : 'info'"
                @click="
                  () => {
                    const existPhoto = photosSelected.some((p) => p.id === photo.id)
                    if (!existPhoto) {
                      photosSelected.push(photo)
                    } else {
                      photosSelected = photosSelected.filter((p) => p.id !== photo.id)
                    }
                  }
                "
              >
                <i
                  class="pi text-white"
                  :class="{
                    'pi-check-circle': photosSelected.some((p) => p.id === photo.id),
                    'pi-circle-off': !photosSelected.some((p) => p.id === photo.id),
                  }"
                />
              </Button>
              <ProjectMediaImageComponent :photo="photo" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row align-items-center justify-content-end" style="padding: 17.5px">
        <Button
          label="SUBMIT"
          style="min-width: 92px"
          class="btn"
          @click="
            () => {
              emit('submitPhotos', photosSelected)
              isVisible = false
            }
          "
        ></Button>
      </div>
    </div>
  </Sidebar>
</template>

<style scoped lang="scss">
  .masonry-item-container {
    position: relative;
    height: 200px;
  }

  .border-selected {
    border: 3px solid #3b82f6;
    border-radius: 8px;
  }
</style>
