export const imageBase64ToArrayBuffer = (imageData: string): ArrayBufferLike => {
  const binary_string: string = atob(imageData)
  const len: number = binary_string.length
  const bytes: Uint8Array = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
