<script setup lang="ts">
  import { toRefs } from 'vue'
  import { IActivePunchList } from '@/interfaces/punch-list/punch-list.interface'
  import { i18n } from '@/i18n'
  import { useCloseOutStore } from '@/store'

  const { t } = i18n.global
  type Iprops = {
    photoType?: string
    currentStep?: number
    punchList?: IActivePunchList | null
    allItemsComplete?: boolean
    submitLoading?: boolean
  }

  const props = defineProps<Iprops>()
  const { allItemsComplete, currentStep, photoType, punchList, submitLoading } = toRefs(props)

  const punchListCameraStore = useCloseOutStore()
  const { crewsForReview } = toRefs(punchListCameraStore)

  const emit = defineEmits<{
    (e: 'nextStep'): void
    (e: 'previousStep'): void
    (e: 'submitCloseOut'): void
  }>()

  const nextStep = () => {
    emit('nextStep')
  }

  const previousStep = () => {
    emit('previousStep')
  }

  const submitCloseOut = () => {
    emit('submitCloseOut')
  }
</script>

<template>
  <div v-if="photoType === 'FINAL_PICTURES'" class="flex flex-row align-items-center justify-content-between py-2 px-4 mb-4 bg-white">
    <div v-if="currentStep === 1" style="width: 92px" />
    <Button
      v-else-if="currentStep === 2 && punchList && punchList.taskLists && (punchList?.taskLists.length ?? []) > 0"
      :label="t('finalizePunchLists.back')"
      style="min-width: 92px"
      class="btn p-button-secondary"
      icon="pi pi-angle-left"
      @click="previousStep"
    ></Button>
    <div v-if="currentStep === 2 && punchList && punchList.taskLists && (punchList?.taskLists.length ?? []) > 0">Step {{ currentStep }} of 2</div>
    <Button
      v-if="currentStep === 1"
      class="btn p-button-info"
      style="min-width: 92px"
      :label="t('finalizePunchLists.next')"
      icon="pi pi-angle-right"
      icon-pos="right"
      :disabled="!allItemsComplete"
      @click="nextStep"
    ></Button>
    <Button
      v-else
      class="btn"
      style="min-width: 92px"
      :label="t('finalizePunchLists.submit')"
      :loading="submitLoading"
      :disabled="submitLoading || crewsForReview?.length === 0"
      @click="submitCloseOut"
    ></Button>
  </div>
</template>
