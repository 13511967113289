import instance from './api'

export const updateBadgeUser = async () => {
  try {
    const data = instance.patch('/Api/Notification/ClearBadget')
    return data
  } catch (error) {
    throw new Error('Error with update badge user')
  }
}

export type Notification = {
  body: string
  createdAt: string
  id: string
  title: string
  type: string
  url: string
  viewed: boolean
}

export const GetListNotifications = async (userId: string, take: number, skip: number) => {
  try {
    const skp = skip === 0 ? 0 : skip * take
    const { data } = await instance.get(`/Api/Notification/${userId}?take=${take}&skip=${skp}`)
    return data
  } catch (error) {
    throw new Error('Error with GET ListNotifications')
  }
}

export const UpdateNotification = async (notificationId: string) => {
  try {
    await instance.patch(`/Api/Notification/${notificationId}`)
  } catch (error) {
    throw new Error('Error with UPDATE Notification')
  }
}

export const UpdateAllNotifications = async (userId: string) => {
  try {
    await instance.patch(`/Api/Notification/User/${userId}`)
  } catch (error) {
    throw new Error('Error with UPDATE All Notifications')
  }
}
