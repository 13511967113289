export const directoryIndexEnUS = {
  all: 'All',
  production: 'Production',
  sales: 'Sales',
  office: 'Office',
  directory: 'Directory',
  search: 'Search',
  table: {
    headers: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      profile: 'Profile',
    },
  },
}

export const directoryIndexEsMX = {
  all: 'Todos',
  production: 'Producción',
  sales: 'Ventas',
  office: 'Oficina',
  directory: 'Directorio',
  search: 'Buscar',
  table: {
    headers: {
      name: 'Nombre',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      profile: 'Perfil',
    },
  },
}
