<script setup lang="ts">
  import { Editor, EditorContent } from '@tiptap/vue-3'
  import StarterKit from '@tiptap/starter-kit'
  import Mention from '@tiptap/extension-mention'
  import suggestion from '@/components/mentions/suggestion'
  import Placeholder from '@tiptap/extension-placeholder'
  import { ref, watch, toRefs, onUnmounted, onMounted } from 'vue'

  type objSuggestion = {
    fullName?: string
    id?: string
    zuid?: string | null
  }

  type Iprops = {
    placeholder?: string
    itemsSuggestions?: objSuggestion[]
    note?: {
      Note_Content: string
    }
  }

  const props = defineProps<Iprops>()
  const emit = defineEmits(['update:note', 'create:note'])
  const { itemsSuggestions } = toRefs(props)
  const editor = ref()

  const onKeydownEditor = (e: KeyboardEvent) => {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      emit('create:note', editor.value?.getHTML())
      return false
    }
  }

  const clearEditorContent = () => {
    if (editor.value) {
      editor.value.commands.clearContent()
    }
  }

  const initializeEditor = () => {
    const CustomMention = Mention.extend({
      addAttributes() {
        return {
          zuid: { parseHTML: (element) => element.getAttribute('data-zuid') },
          fullname: { parseHTML: (element) => element.getAttribute('data-fullname') },
          id: { parseHTML: (element) => element.getAttribute('data-id') },
        }
      },
    }).configure(
      itemsSuggestions.value
        ? {
            HTMLAttributes: { class: 'mention' },
            suggestion: {
              ...suggestion,
              items: ({ query }) => {
                console.log('handle', { query })
                if (!query.length) return itemsSuggestions?.value ?? []
                return (itemsSuggestions?.value ?? []).filter((suggestion) => {
                  const needle = query.toLowerCase()
                  return (suggestion?.fullName ?? '').toLocaleLowerCase().indexOf(needle) > -1
                })
              },
            },
            renderLabel({ options, node }) {
              return `${options.suggestion.char}${node.attrs.fullname ?? node.attrs.id}`
            },
          }
        : {}
    )
    if (editor.value) {
      editor.value.destroy()
    }

    editor.value = new Editor({
      extensions: [StarterKit, CustomMention, Placeholder.configure({ placeholder: props.placeholder ?? '' })],
      onUpdate: ({ editor }) => {
        emit('update:note', editor.getHTML())
      },
      editorProps: { attributes: { class: 'p-inputtext' } },
    })
  }

  watch([() => props.placeholder, () => props.itemsSuggestions], (newVal, oldVal) => {
    if (newVal !== oldVal) initializeEditor()
  })

  onMounted(() => {
    initializeEditor()
  })

  onUnmounted(() => {
    if (editor.value) editor.value.destroy()
  })

  defineExpose({
    commands: {
      focus: () => editor.value?.commands.focus(),
      clearEditorContent,
    },
  })
</script>

<template>
  <div class="flex w-10">
    <EditorContent id="note_content" :editor="editor" class="w-full block" @keydown="onKeydownEditor" />
  </div>
</template>

<style lang="css">
  .layout-save {
    padding-bottom: calc(var(--sab) + 1rem) !important;
  }

  #note_content,
  #note_content > div {
    /* height: 44px !important; */
    min-height: 44px !important;
    max-height: 64px !important;
    border: none;
  }
  #note_content > div {
    /* height: 44px !important; */
    min-height: 44px !important;
    max-height: 64px !important;
    width: 100%;
    overflow: hidden;
    word-break: break-all;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #note_content > div {
    overflow: auto;
  }

  #note_content p {
    margin: 0;
  }

  .mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
  }
  .ProseMirror:focus {
    outline: none;
  }
</style>

<style lang="css" scoped>
  .title-tab {
    min-height: 40px;
  }

  .off-screen {
    bottom: -80px;
    transition: bottom 0.3s;
  }
  .new-note-container {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
    max-width: 32rem;
  }

  .container-reply {
    background-color: #83aff745;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    padding: 5px 10px;
    border-radius: 10px;
  }

  @media only screen and (max-width: 991px) {
    .new-note-container {
      left: 0;
      right: 0;
      width: 100%;
      max-width: unset;
      margin-left: 0;
      margin-right: 0;
    }

    .off-screen.show {
      bottom: 0;
    }
  }

  @media only screen and (min-width: 992px) {
    .new-note-container {
      left: unset;
      right: unset;
      margin-left: -1rem;
      margin-right: -1rem;
      width: calc(100% + 2rem);
      max-width: 32rem;
    }

    .off-screen.show {
      bottom: 1rem;
    }
  }
</style>
