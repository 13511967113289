export const GalleryComponentEnUs = {
  dialog: {
    removePhoto: 'Remove Photo',
    removePhotoDescription: 'Are you sure you want to remove this photo?',
    removePhotoCancel: 'Cancel',
    removePhotoConfirm: 'Confirm',
  },
  dialogTags: {
    addTag: 'Add Tag',
    removeTag: 'Remove Tag',
    searchTags: 'Search Tags',
  },
  addTag: 'Add / Manage Tags',
  seeLess: 'See Less',
  seeMore: 'See more',
  titleTags: 'Tags',
  titleComments: 'Comments',
}

export const GalleryComponentEsMx = {
  dialog: {
    removePhoto: 'Remover Foto',
    removePhotoDescription: '¿Estás seguro de que quieres eliminar esta foto?',
    removePhotoCancel: 'Cancelar',
    removePhotoConfirm: 'Confirmar',
  },
  dialogTags: {
    addTag: 'Agregar Etiqueta',
    removeTag: 'Eliminar Etiqueta',
    searchTags: 'Buscar Etiquetas',
  },
  titleTags: 'Etiquetas',
  titleComments: 'Comentarios',
  seeMore: 'Ver más',
  seeLess: 'Ver menos',
  addTag: 'Agregar / Administrar etiquetas',
}
