export const appLayoutEnUS = {
  appName: 'PRODUCTION',
  search: 'Search',
  menu: 'Menu',
  clear: 'Clear',
  projects: 'Projects',
  leads: 'Leads',
  no_date: 'No date',
  buttonUpdate: 'Update App',
  appVersion: 'The application version does not match with the server version, please update',
}

export const appLayoutEsMX = {
  appName: 'PRODUCTION',
  search: 'Buscar',
  menu: 'Menú',
  clear: 'Limpiar',
  projects: 'Proyectos',
  leads: 'Prospectos',
  no_date: 'Sin fecha',
  buttonUpdate: 'Actualizar',
  appVersion: 'La version de la aplicacion no coincide con la version del servidor, por favor actualiza',
}
