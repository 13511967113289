<script lang="ts" setup>
  import { toRefs } from 'vue'
  import { colors, useColor } from '../store/color'
  import { useDialogs } from '../store/dialogs'
  import { useShapes } from '../store/shapes'
  const { showDialogColors, handleShowDialog } = useDialogs()
  const shapesStore = useShapes()
  const colorStore = useColor()
  const { shapeId, shapes } = toRefs(shapesStore)
  const { SET_COLOR } = colorStore
  const { handleUpdateShape } = shapesStore
</script>
<template>
  <Dialog
    v-model:visible="showDialogColors"
    :pt="{
      mask: {
        style: {
          zIndex: 1216,
        },
      },
    }"
    :modal="true"
    :base-z-index="1215"
    :auto-z-index="false"
    header="Colors"
    class="w-full ml-8"
    style="max-width: 25rem; z-index: 1215"
    :draggable="false"
    position="topleft"
    dismissable-mask
  >
    <div class="flex flex-row flex-wrap w-full align-items-center justify-content-start p-0 gap-2">
      <button
        v-for="c in colors"
        :key="`color-scheme-${c}`"
        :style="{
          backgroundColor: c,
          width: '35px',
          height: '35px',
        }"
        class="border-none border-3 border-round border-black-alpha-20 cursor-pointer"
        @click="
          () => {
            if (shapeId !== null && shapes[shapeId]) {
              handleUpdateShape({
                ...shapes[shapeId],
                color: c,
              })
            }
            SET_COLOR(c)
            handleShowDialog('showDialogColors', false)
          }
        "
      ></button>
    </div>
  </Dialog>
</template>
