import { menuComponentEsMX } from '@/i18n/components/MenuComponent'
import { photoQueueComponentEsMX } from '@/i18n/components/PhotoQueueComponent'
import { projectCardComponentEsMX } from '@/i18n/components/ProjectCardComponent'
import { projectCardsComponentEsMX } from '@/i18n/components/ProjectCardsComponent'
import { appLayoutEsMX } from '@/i18n/layouts/AppLayout'
import { commentComponentEsMX } from '@/i18n/views/CommentComponent'
import { commentsComponentEsMX } from '@/i18n/views/CommentsComponent'
import { crewDirectoryEsMX } from '@/i18n/views/CrewDirectoryIndex'
import { crewIndexEsMX } from '@/i18n/views/CrewIndex'
import { crewUserEsMX } from '@/i18n/views/CrewUser'
import { directoryIndexEsMX } from '@/i18n/views/DirectoryIndex'
import { forgotPasswordEsMX } from '@/i18n/views/ForgotPassword'
import { homeIndexEsMX } from '@/i18n/views/HomeIndex'
import { integrationsIndexEsMX } from '@/i18n/views/IntegrationsIndex'
import { loginIndexEsMX } from '@/i18n/views/LoginIndex'
import { noteComponentEsMX } from '@/i18n/views/NoteComponent'
import { policiesIndexEsMX } from '@/i18n/views/PoliciesIndex'
import { profileIndexEsMX } from '@/i18n/views/ProfileIndex'
import { projectAssignCrewsEsMX } from '@/i18n/views/ProjectAssignCrews'
import { projectAssignCrewsAddEsMX } from '@/i18n/views/ProjectAssignCrewsAdd'
import { projectAssignCrewUsersEsMX } from '@/i18n/views/ProjectAssignCrewUsers'
import { projectCalendarEsMX } from '@/i18n/views/ProjectCalendar'
import { projectDocumentsEsMX } from '@/i18n/views/ProjectDocuments'
import { projectMediaEsMX } from '@/i18n/views/ProjectMedia'
import { projectNotesEsMX } from '@/i18n/views/ProjectNotes'
import { projectProblemEsMX } from '@/i18n/views/ProjectProblem'
import { projectProblemsEsMX } from '@/i18n/views/ProjectProblems'
import { projectProblemsAddEsMX } from '@/i18n/views/ProjectProblemsAdd'
import { projectPunchListEsMX } from '@/i18n/views/ProjectPunchList'
import { projectPunchListAddEsMX } from '@/i18n/views/ProjectPunchListAdd'
import { projectsIndexEsMX } from '@/i18n/views/ProjectsIndex'
import { projectWorkContactsEsMX } from '@/i18n/views/ProjectWorkContacts'
import { scheduleIndexEsMX } from '@/i18n/views/ScheduleIndex'
import { settingsIndexEsMX } from '@/i18n/views/SettingsIndex'
import { settingsWorkDocumentIndexEsMX } from '@/i18n/views/SettingsWorkDocumentIndex'
import { zohoConnectEsMX } from '@/i18n/views/ZohoConnectCallback'
import { changePasswordEsMX } from './components/account/ChangePassword'
import { finalizePunchListsEsMx } from './components/FinalizePunchList'
import { GalleryComponentEsMx } from './components/GallryComponent'
import { globalSearchEsMX } from './components/GlobalSearch'
import { SiderBarPhotoFeedEsMx } from './components/Home/Web/SideBarPhotoFeed'
import { LeadCardComponentEsMX } from './components/LeadCardComponent'
import { projectSortEsMx } from './components/options/projectSort'
import { projectStagesEsMx } from './components/options/ProjectStage'
import { projectTypesEsMX } from './components/options/projectTypes'
import { typePhotoEsMx } from './components/options/typePhotos'
import { pinturaEditorEsMx } from './components/pintura/pintura'
import { reviewsCrewEsMX } from './components/ReviewsCrew'
import { LeadCardsViewEsMX } from './views/LeadComponents'
import { GalleryCommentsComponentEs } from './components/gallery/comments'
import { projectAssignCrewUsersAddEsMX } from './views/ProjectAssignCrewUsersAdd'
import { projectIdEsMX } from './views/ProjectId'
import { projectLaborBillEsMX } from './views/ProjectLaborBill'
import { SettingsPermissionInfoEsMx } from './views/settingsPermissionInfo'
import { settingsQueuePhotoEsMx } from './views/SettingsQueuePhoto'
import { editorImageEsMx } from './components/editorImage'
import { tagsEsMX } from './components/tag'
import { biometricES } from './components/biometric/biometric'
import { MapIndexMX } from './views/MapView'
import { verifiyIdentityEs } from './faceId/verifyIdentity'
import { uploadMediaES } from './views/uploadMedia'
import { tagsDataTableColumnEs } from './views/TagsDataTableColumns'
import { leadsIndexEsMX } from './views/LeadsIndex'
import { CalendarEs } from './components/carlendar'
import { photoQEs } from './components/photoQ'
import { FilterMediaEs } from './components/FilterMedia'

export const esMX = {
  photoQ: photoQEs,
  filterMedia: FilterMediaEs,
  appLayout: appLayoutEsMX,
  menuComponent: menuComponentEsMX,
  calendar: CalendarEs,
  photoQueueComponent: photoQueueComponentEsMX,
  projectCardsComponent: projectCardsComponentEsMX,
  projectCardComponent: projectCardComponentEsMX,
  commentComponent: commentComponentEsMX,
  commentsComponent: commentsComponentEsMX,
  crewDirectory: crewDirectoryEsMX,
  crewIndex: crewIndexEsMX,
  directoryIndex: directoryIndexEsMX,
  forgotPassword: forgotPasswordEsMX,
  tagsData: tagsDataTableColumnEs,
  homeIndex: homeIndexEsMX,
  integrationsIndex: integrationsIndexEsMX,
  loginIndex: loginIndexEsMX,
  noteComponent: noteComponentEsMX,
  policiesIndex: policiesIndexEsMX,
  profileIndex: profileIndexEsMX,
  settingsIndex: settingsIndexEsMX,
  settingPermissionInfo: SettingsPermissionInfoEsMx,
  projectAssignCrews: projectAssignCrewsEsMX,
  projectAssignCrewsAdd: projectAssignCrewsAddEsMX,
  projectCalendar: projectCalendarEsMX,
  projectDocuments: projectDocumentsEsMX,
  faceIdVerify: verifiyIdentityEs,
  projectId: projectIdEsMX,
  projectMedia: projectMediaEsMX,
  projectNotes: projectNotesEsMX,
  projectLaborBill: projectLaborBillEsMX,
  projectProblem: projectProblemEsMX,
  projectProblems: projectProblemsEsMX,
  projectProblemsAdd: projectProblemsAddEsMX,
  projectPunchList: projectPunchListEsMX,
  projectPunchListAdd: projectPunchListAddEsMX,
  projectsIndex: projectsIndexEsMX,
  projectWorkContacts: projectWorkContactsEsMX,
  scheduleIndex: scheduleIndexEsMX,
  settingsWorkDocumentIndex: settingsWorkDocumentIndexEsMX,
  crewUser: crewUserEsMX,
  biometricModal: biometricES,
  map: MapIndexMX,
  projectAssignCrewUsers: projectAssignCrewUsersEsMX,
  zohoConnect: zohoConnectEsMX,
  pinturaEditor: pinturaEditorEsMx,
  uploadMedia: uploadMediaES,
  globalSearch: globalSearchEsMX,
  reviewsCrew: reviewsCrewEsMX,
  finalizePunchLists: finalizePunchListsEsMx,
  changePassword: changePasswordEsMX,
  projectAssignCrewUsersAdd: projectAssignCrewUsersAddEsMX,
  sideBarPhotoFeed: SiderBarPhotoFeedEsMx,
  galleryComponent: GalleryComponentEsMx,
  projectStage: projectStagesEsMx,
  projectSort: projectSortEsMx,
  projectTypes: projectTypesEsMX,
  photoType: typePhotoEsMx,
  leadCardComponent: LeadCardComponentEsMX,
  LeadCardsView: LeadCardsViewEsMX,
  galleryCommentComponent: GalleryCommentsComponentEs,
  settingsQueuePhoto: settingsQueuePhotoEsMx,
  editorImage: editorImageEsMx,
  leadsIndex: leadsIndexEsMX,
  tags: tagsEsMX,
  close: 'Cerrar',
  copy: 'Copiar',
  request: 'Solicitar permiso',
  share: 'Compartir',
  unselectAll: 'Deseleccionar Todo',
  crewCardReview: 'Reseñas',
  crewMessageReviews: 'No hay reseñas aun...',
  galleryError: 'La imagen no se cargó correctamente.',
  permissionGlobalTitle: 'Configuración de Permisos',
  permissionGlobalDescription: 'Para brindarte la mejor experiencia, necesitamos acceso a:',
  permissionAccess: {
    camera: 'Camera',
    location: 'Geolocation',
    notification: 'Notifications',
  },
  permissionMessage: 'Algunas funcionalidades no funcionarán correctamente si no habilitas los permisos necesarios. Para disfrutar plenamente de nuestra aplicación.',
}
