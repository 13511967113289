<script setup lang="ts">
  import { onUnmounted, ref, toRefs, watch, type Ref } from 'vue'
  import { useDebounceFn } from '@vueuse/core'
  import api from '@/services/api'
  import { i18n } from '@/i18n'
  import { IProject } from '@/interfaces/project/project.interface'
  import TabPanel from 'primevue/tabpanel'
  import TabView from 'primevue/tabview'
  import { useUserStore } from '@/store'
  import { useSearchDialog } from '@/store/searchStore'

  type IEntity = IProject & {
    customerNumber: string | null
    type: string
  }

  const dialogStore = useSearchDialog()
  const { dialogSearch } = toRefs(dialogStore)

  type RefWithElement<T extends HTMLElement> = Ref<T & { $el: T }>

  const { t } = i18n.global
  const loadingProjects = ref(false)
  const projectValues: Ref<IEntity[]> = ref([])
  const querySearch = ref('')
  const inputSearch = ref('')
  const searchInput = ref<RefWithElement<HTMLInputElement> | null>(null)
  const searchOnlyMyProjects = ref(true)
  const userStore = useUserStore()
  const { id: UserId, permissions } = toRefs(userStore)
  const handleRouterPush = () => {
    dialogStore.handleChange(false)
    handleEmptySearch()
  }

  const debounced = useDebounceFn(async () => {
    if (inputSearch.value.length === 0) {
      handleEmptySearch()
      return
    }
    querySearch.value = inputSearch.value
  }, 500)

  const handleGLobalSearch = async () => {
    await debounced()
  }
  const handleEmptySearch = () => {
    projectValues.value = []
    querySearch.value = ''
  }

  const tabs = [
    { id: 'all', label: t('globalSearch.allLabel') },
    { id: 'projects', label: t('globalSearch.projectLabel') },
    { id: 'leads', label: t('globalSearch.leadLabel') },
  ]
  const tabActive = ref(0)
  const keysklabels = {
    0: 'all',
    1: 'Project',
    2: 'Lead',
  }
  const handleFetch = async (url: string) => {
    loadingProjects.value = true
    const { data } = await api.get(url)
    projectValues.value = data.data.results
    loadingProjects.value = false
  }

  watch([tabActive, searchOnlyMyProjects, querySearch, UserId, permissions], async ([tab, seonlyprojct, query, user, per]) => {
    if (!query?.length) return
    if (tab === 1 && seonlyprojct && per?.searchPermissions?.canSearchMyOwnProjects) {
      await handleFetch(`/Api/v2/GlobalSearch?query=${query}&userId=${user}`)
      return
    }

    await handleFetch(`/Api/v2/GlobalSearch?query=${query}`)
  })
  onUnmounted(() => {
    tabActive.value = 0
    querySearch.value = ''
    inputSearch.value = ''
  })
</script>

<template>
  <Dialog
    :visible="dialogSearch"
    modal
    :header="t('globalSearch.search')"
    :draggable="false"
    position="top"
    :style="{ width: '50vw', marginTop: '70px' }"
    content-class="p-0"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
    @hide="handleEmptySearch"
    @update:visible="dialogStore.handleChange(false)"
  >
    <div style="width: 100%">
      <div class="flex w-full align-items-center md:justify-content-end justify-content-center pt-0 p-4">
        <div class="relative col-12 flex align-items-center justify-content-center">
          <div class="flex flex-row align-items-center gap-2 p-input-icon-left w-full relative p-input-icon-right">
            <i class="pi pi-search" style="width: fit-content; top: 10px" />
            <InputText ref="searchInput" v-model="inputSearch" type="text" :placeholder="t('globalSearch.search')" class="w-full pr-5" @input="handleGLobalSearch" />
            <i v-if="loadingProjects" class="pi pi-spin pi-spinner absolute" style="right: 30px" />
            <i v-if="querySearch.length > 0" class="pi pi-times absolute" style="right: 10px" @click="handleEmptySearch" />
          </div>
        </div>
      </div>
      <div v-if="projectValues.length > 0" class="flex w-full align-items-start justify-content-start pt-0 p-4">
        <section>
          <TabView
            v-model:activeIndex="tabActive"
            :scrollable="true"
            :previous-button-props="{
              style: 'display: none',
            }"
            :next-button-props="{
              style: 'display: none',
            }"
          >
            <TabPanel v-for="item in tabs" :key="item.id" :header="item.label" content-class="hidden" />
          </TabView>
        </section>
      </div>
      <header
        v-if="projectValues.length > 0 && tabActive === 1 && permissions?.searchPermissions?.canSearchMyOwnProjects"
        class="flex w-full align-items-start justify-content-start pt-0 p-4"
      >
        <div class="flex flex-row align-items-center justify-content-center">
          <p class="m-0 p-0">{{ t('globalSearch.searchOnlyMyProjects') }}</p>
          <label class="switch">
            <input type="checkbox" :checked="searchOnlyMyProjects" @change="() => (searchOnlyMyProjects = !searchOnlyMyProjects)" />
            <span class="slider round"></span>
          </label>
        </div>
      </header>
    </div>

    <!-- Scrollable Content -->
    <template v-if="projectValues.length > 0">
      <div class="scrollable-content">
        <div class="flex flex-wrap w-full justify-content-evenly p-4" style="background-color: var(--surface-ground)">
          <div class="grid w-full">
            <router-link
              v-for="project in projectValues?.filter((e)=> {
                const kt = keysklabels[tabActive as keyof typeof keysklabels]
                if (kt ==='all') {
                  return e
                }
                return e?.type === kt
              })"
              :key="project.id"
              :to="`/${project.type === 'Project' ? 'projects' : 'leads'}/${project.id}/media`"
              class="text-color col-12 md:col-6 lg:col-6"
              @click="handleRouterPush"
            >
              <article class="card_search_item bg-white border-round-2xl border-1 border-gray-100 w-full h-full" style="max-width: 100%">
                <section :style="{ objectFit: 'cover', height: '100px', width: '100%' }">
                  <img v-if="project.photoUrl" :src="project.photoUrl" alt="" srcset="" :style="{ objectFit: 'cover', height: '100px', width: '100%' }" class="border-round" />
                  <div v-if="!project.photoUrl" class="border-round w-full h-full flex justify-content-center align-items-center" style="background-color: #e6e6e6">
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5 1.5C3.89687 1.5 3 2.39688 3 3.5V10.5C3 11.6031 3.89687 12.5 5 12.5H16C17.1031 12.5 18 11.6031 18 10.5V3.5C18 2.39688 17.1031 1.5 16 1.5H5ZM12.375 4.83437L15.375 9.33438C15.5281 9.56563 15.5438 9.85938 15.4125 10.1031C15.2812 10.3469 15.0281 10.5 14.75 10.5H6.25C5.9625 10.5 5.7 10.3344 5.575 10.075C5.45 9.81563 5.48438 9.50625 5.66563 9.28125L7.66563 6.78125C7.80938 6.60313 8.02188 6.5 8.25 6.5C8.47812 6.5 8.69375 6.60313 8.83438 6.78125L9.375 7.45625L11.125 4.83125C11.2656 4.625 11.5 4.5 11.75 4.5C12 4.5 12.2344 4.625 12.375 4.83437ZM6 4.5C6 4.23478 6.10536 3.98043 6.29289 3.79289C6.48043 3.60536 6.73478 3.5 7 3.5C7.26522 3.5 7.51957 3.60536 7.70711 3.79289C7.89464 3.98043 8 4.23478 8 4.5C8 4.76522 7.89464 5.01957 7.70711 5.20711C7.51957 5.39464 7.26522 5.5 7 5.5C6.73478 5.5 6.48043 5.39464 6.29289 5.20711C6.10536 5.01957 6 4.76522 6 4.5ZM1.5 4.25C1.5 3.83437 1.16562 3.5 0.75 3.5C0.334375 3.5 0 3.83437 0 4.25V11.25C0 13.5969 1.90313 15.5 4.25 15.5H14.25C14.6656 15.5 15 15.1656 15 14.75C15 14.3344 14.6656 14 14.25 14H4.25C2.73125 14 1.5 12.7688 1.5 11.25V4.25Z"
                        fill="#D0D0D0"
                      />
                    </svg>
                  </div>
                </section>
                <section class="flex flex-column gap-1 justify-content-between">
                  <p class="w-full text-color font-bold text-sm w-15 white-space-wrap overflow-hidden text-overflow-ellipsis m-0">
                    {{ project.name }}
                  </p>
                  <p class="w-full text-color text-sm w-15 white-space-wrap overflow-hidden text-overflow-ellipsis m-0">
                    {{ project.address }}
                  </p>
                  <p class="m-0">#{{ project?.poNumber ?? project?.customerNumber }}</p>
                  <p class="m-0">{{ project.status }}</p>
                </section>
              </article>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<style lang="css" scoped>
  .scrollable-content {
    overflow-y: auto;
    max-height: 500px;
    padding: 10px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    transform: scale(0.7);
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .card_search_item {
    padding: 12px;
    display: grid;
    grid-template-columns: 100px 1fr;
    height: 100%;
    min-height: 100px;
    border: 2px solid rgb(239, 239, 239) !important;
    gap: 12px;
  }
  .chip-project {
    background-color: var(--green-800);
    color: var(--primary-color-text);
  }
  .chip-lead {
    background-color: var(--green-100);
  }
</style>
