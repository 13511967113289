export const homeIndexEnUS = {
  nearByProjects: 'Nearby Projects',
  nearNyLeads: `Today's Leads`,
  nearByProjectsEmpty: 'You have no projects assigned to you',
  quickContacts: 'Quick Contacts',
  home_desktop_title: 'Photo Feed',
  noQuickContacts: 'No Quick Contacts have been saved',
  START_TAKING_PHOTOS: 'Start taking picturings to ignite your photo feed ',
}

export const homeIndexEsMX = {
  nearByProjects: 'Proyectos cercanos',
  home_desktop_title: 'Feed de fotos',

  nearNyLeads: 'Leads de hoy',
  nearByProjectsEmpty: 'No tiene proyectos asignados',
  quickContacts: 'Contactos rápidos',
  noQuickContacts: 'No se han guardado contactos rápidos',
  START_TAKING_PHOTOS: 'Empieza a tomar fotos para propulsar tu Photofeed ',
}
