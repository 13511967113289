export const projectProblemsAddEnUS = {
  type: 'Type',
  description: 'Description',
  submit: 'SUBMIT',
  assignedToUser: 'Assign To User',
  assignUser: 'Assign user',
  assignedDate: 'Assigned Date',
  captureImage: 'CAPTURE IMAGE',
  successCreated: 'Successfully created problem',
  errorCreating: 'Error creating problem',
  success: 'Success',
  error: 'Error',
  damage: 'Damage',
  rot: 'Rot',
  materials: 'Materials',
  other: 'Other',
}

export const projectProblemsAddEsMX = {
  type: 'Tipo',
  description: 'Descripción',
  submit: 'ENVIAR',
  assignedToUser: 'Asignar a usuario',
  captureImage: 'CAPTURAR IMAGEN',
  successCreated: 'Problema creado con éxito',
  errorCreating: 'Error creando problema',
  success: 'Éxito',
  error: 'Error',
  damage: 'Daño',
  rot: 'Podrido',
  materials: 'Materiales',
  other: 'Otro',
}
