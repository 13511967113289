<script lang="ts" async setup>
  import { ref, type Ref, watch, onMounted } from 'vue'
  import LeadCardsComponentVue from '@/components/LeadCardsComponent.vue'
  import InputCalendar from '@/components/InputCalendar.vue'
  import { useLeadStore } from '@/store'
  import Sidebar from 'primevue/sidebar'
  import { i18n } from '@/i18n'
  import { storeToRefs } from 'pinia'
  import { captureException } from '@sentry/vue'
  import InfiniteLoading from 'v3-infinite-loading'
  // import QuickContacsVue from '@/components/QuickContacs.vue'
  import 'v3-infinite-loading/lib/style.css'

  const { t } = i18n.global

  const projectStore = useLeadStore()
  const { fetchLeads, setDefaultProjects } = projectStore
  const { projects, pagination, loadingProjects } = storeToRefs(projectStore)
  const projectsFilterIsVisible = ref(false)
  const loading = ref(false)
  const takeValue: Ref = ref(6)
  const skipValue: Ref = ref(0)
  const today = new Date()
  const lastSevenDays = new Date()
  lastSevenDays.setDate(today.getDate() + 7)

  const startDateRef = ref(today.toISOString().split('T')[0])
  const endDateRef = ref(lastSevenDays.toISOString().split('T')[0])

  const resetPagination = () => {
    takeValue.value = 6
    skipValue.value = 0
    setDefaultProjects()
  }

  async function updateProjects(dateParams: { startDate: string; endDate: string }) {
    if (loading?.value) return
    startDateRef.value = dateParams.startDate
    endDateRef.value = dateParams.endDate

    try {
      loading.value = true
      await fetchLeads({
        take: takeValue.value,
        skip: skipValue.value,
        startDate: startDateRef.value,
        endDate: endDateRef.value,
      })
    } catch (err) {
      console.error('err', err)
      captureException(err)
    } finally {
      loading.value = false
    }
  }

  const handleScrollPagination = () => {
    if (pagination.value.nextPage !== null) {
      skipValue.value = skipValue.value + 6
      takeValue.value = 6
    }
  }

  onMounted(() => {
    resetPagination()
    updateProjects({ startDate: startDateRef.value, endDate: endDateRef?.value })
  })

  watch([takeValue, skipValue], () => updateProjects({ startDate: startDateRef.value, endDate: endDateRef?.value }))
</script>

<template>
  <div class="flex flex-column h-full p-save-bottom">
    <div class="mt-3">
      <div class="flex flex-column">
        <h1 class="mb-0">{{ t('homeIndex.nearNyLeads') }}</h1>
      </div>
    </div>
    <div class="col-12 md:col-4">
      <InputCalendar
        class="w-full"
        :primary-color="'#0b60b0'"
        :secondary-color="'#D6E4FF'"
        :start-date="startDateRef"
        :end-date="endDateRef"
        @apply="
          (e) => {
            updateProjects(e)
            resetPagination()
          }
        "
      />
    </div>
    <div class="flex-1 overflow-y-scroll">
      <LeadCardsComponentVue :leads="projects" :loading-projects="loadingProjects" is-leads />

      <div v-show="pagination?.nextPage !== null && !loadingProjects" class="flex justify-content-center py-3">
        <InfiniteLoading v-show="pagination?.nextPage !== null" @infinite="handleScrollPagination" />
      </div>
    </div>
    <!-- <QuickContacsVue /> -->
  </div>
  <Sidebar
    id="projects-filter-sidebar"
    v-model:visible="projectsFilterIsVisible"
    append-to="body"
    :dismissable="true"
    position="right"
    :show-close-icon="true"
    class="flex w-full"
    style="max-width: 400px"
  >
    <template #header>
      <h5 class="m-0 text-left w-full">
        {{ t('projectsIndex.projectsFilter') }}
      </h5>
    </template>
  </Sidebar>
</template>

<style lang="scss">
  .project-filter {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");

    input:not(.empty) {
      padding-right: 28px;
    }
  }

  #projects-filter-sidebar {
    .p-sidebar-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    .p-sidebar-header-content {
      width: 100%;
    }
  }
</style>

<style scoped lang="scss">
  .p-save-bottom {
    padding-bottom: var(--sab);
  }
  .p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-chip.status-readytoverify,
  .p-chip.status-inproduction {
    background: #ffda62;
    color: #333;
  }
  .p-chip.status-contractverify {
    background: #4137be;
    color: #fff;
  }
  .near-projects-container {
    width: 100%;
    @media screen and (min-width: 80em) {
      width: initial;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      .p-card {
        margin-bottom: 0 !important;
      }
    }
  }
</style>
<style lang="scss">
  .home-messages {
    .p-message {
      .p-message-wrapper {
        display: flex;
        width: 100%;

        > .p-message-text {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }
  .lb-pull-refresh {
    width: 100%;
  }
</style>
