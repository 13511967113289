<script lang="ts" setup>
  import api from '@/services/api'
  import { onMounted, ref, watch } from 'vue'
  import { IOption } from '@/interfaces/option/option.interface'
  import { projectTypes } from '@/options/project/project-types.options'
  import { projectStages } from '@/options/project/project-stage.options'
  import { hasObjectWithValue } from '@/utils/hasObjectWithLabel'
  import Sidebar from 'primevue/sidebar'
  import AutoComplete from 'primevue/autocomplete'
  import { captureException } from '@sentry/vue'
  import PullRefresh from '@/components/PullRefresh.vue'
  import InfiniteLoading from 'v3-infinite-loading'
  import 'v3-infinite-loading/lib/style.css'
  import { ILead } from '@/interfaces/project/project.interface'

  import { i18n } from '@/i18n'
  const { t } = i18n.global
  import loadingLeadCard from '@/components/LoadingProjectCard.vue'
  import leadSimpleCardComponent from '@/components/LeadSimpleCardComponent.vue'

  interface IPagination {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }

  const leadsLoading = ref(false)
  const leads = ref<ILead[]>([])
  const leadsFilterIsVisible = ref(false)
  const typeValue = ref<IOption | null>(null)
  const stageValue = ref<IOption | null>(null)
  const loading = ref(false)
  const filteredTypes = ref<IOption[]>(projectTypes)
  const filteredStages = ref<IOption[]>(projectStages)
  const pagination = ref<IPagination>({
    pageSize: 0,
    pageNumber: 0,
    pageCount: 0,
    rowCount: 0,
    nextPage: null,
  })
  const takeValue = ref(6)
  const skipValue = ref(0)
  // const rowCountLeadsNearMe = ref(0)

  onMounted(async () => {
    await fetchProjects({})
  })

  async function fetchProjects({ projectType, projectStage }: { projectType?: string; projectStage?: string }) {
    try {
      leadsLoading.value = true

      const params = []
      params.push(`&filter.ProjectType=${projectType ?? 'ALL'}`)
      params.push(`&filter.ProjectStage=${projectStage ?? 'ALL'}`)

      const {
        data: { data: lnm },
      } = await api.get(`/Api/v2/Leads?${params.join('')}&skip=${skipValue.value}&take=${takeValue.value}`)

      leads.value = [...leads.value, ...lnm.items]

      pagination.value = lnm.pagination
    } catch (err) {
      captureException(err)
    } finally {
      leadsLoading.value = false
    }
  }

  async function updateType($event: any) {
    try {
      const { query } = $event
      filteredTypes.value = projectTypes.filter((pt) => pt.value.includes(query.toUpperCase()))
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function typeSelected($event: any) {
    try {
      typeValue.value = $event.value
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function updateStage($event: any) {
    try {
      const { query } = $event
      filteredStages.value = projectStages.filter((ps) => ps.value.includes(query.toUpperCase()))
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function stageSelected($event: any) {
    try {
      stageValue.value = $event.value
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function updateProjects() {
    try {
      await fetchProjects({ projectType: typeValue.value?.value, projectStage: stageValue.value?.value })
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  function resetValue(type: 'type' | 'stage') {
    if (type === 'type') typeValue.value = null
    else if (type === 'stage') stageValue.value = null
  }

  watch([skipValue, takeValue], () => {
    fetchProjects({ projectType: typeValue.value?.value, projectStage: stageValue.value?.value })
  })

  watch(typeValue, async (newValue, oldValue) => {
    if (newValue !== oldValue && (!newValue || newValue.label.length === 0 || hasObjectWithValue(projectTypes, 'value', newValue.value))) {
      resetPagination()
      await updateProjects()
    }
  })

  watch(stageValue, async (newValue, oldValue) => {
    if (newValue !== oldValue && (!newValue || newValue.label.length === 0 || hasObjectWithValue(projectStages, 'value', newValue.value))) {
      resetPagination()
      await updateProjects()
    }
  })

  const resetPagination = () => {
    leads.value = []
    skipValue.value = 0
    takeValue.value = 6
  }

  const onRefresh = () => {
    resetPagination()
    fetchProjects({}).then(() => (loading.value = false))
  }
  const handleScrollPagination = () => {
    if (pagination.value.nextPage !== null) {
      skipValue.value = skipValue.value + 6
      takeValue.value = 6
    }
  }
</script>

<template>
  <div class="mb-4 flex flex-column h-full">
    <div class="col col-12">
      <div class="flex justify-content-between align-items-center">
        <h1 class="mb-0">Leads</h1>
      </div>
    </div>
    <div class="flex flex-column flex-1 overflow-y-hidden">
      <PullRefresh @refresh="onRefresh">
        <template v-if="leads.length > 0">
          <div class="flex flex-wrap mt-2 w-full justify-content-evenly gap-2">
            <template v-for="lead in leads" :key="lead.id">
              <div v-if="lead?.name?.length" class="col col-12 sm:col-6 md:col-4 flex-grow-1 p-0 my-1">
                <leadSimpleCardComponent :lead="lead" />
              </div>
            </template>
          </div>
        </template>
        <template v-if="!leadsLoading">
          <div v-if="!leads.length" class="col-12">
            <div class="card flex align-items-center justify-content-center flex-wrap col gap-2">
              <h6 class="pt-3">No Leads available.</h6>
            </div>
          </div>
        </template>

        <div v-else class="flex flex-wrap mt-2 w-full justify-content-evenly gap-2">
          <loadingLeadCard v-for="i in 4" :key="i" class="col col-12 sm:col-6 md:col-4 flex-grow-1 p-0" style="min-height: auto" is-leads />
        </div>
        <div class="h-1rem w-1rem" />
        <div v-show="!leadsLoading && pagination?.nextPage !== null" class="flex justify-content-center">
          <InfiniteLoading v-show="pagination?.nextPage !== null" @infinite="handleScrollPagination" />
        </div>
      </PullRefresh>
    </div>
  </div>

  <Sidebar
    id="projects-filter-sidebar"
    v-model:visible="leadsFilterIsVisible"
    append-to="body"
    :dismissable="true"
    position="right"
    :show-close-icon="true"
    class="flex w-full"
    style="max-width: 400px"
  >
    <template #header>
      <h5 class="m-0 text-left w-full">
        {{ t('leadsIndex.projectsFilter') }}
      </h5>
    </template>

    <div class="flex flex-column flex-grow-1 h-full py-4">
      <div id="scroll-container" class="flex flex-column flex-grow-1 h-full overflow-auto gap-3">
        <div class="field w-full mb-0">
          <label for="sortAutocomplete" class="w-full">{{ t('leadsIndex.filterType') }}:</label>
          <div class="relative w-full">
            <div
              v-if="typeValue"
              class="absolute project-filter"
              style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
              @click.stop="resetValue('type')"
            ></div>
            <AutoComplete
              id="typeAutocomplete"
              v-model="typeValue"
              class="w-full"
              :class="{ empty: !typeValue }"
              style="max-height: 40px"
              :dropdown="true"
              :force-selection="true"
              option-label="label"
              :placeholder="t('leadsIndex.filterTypeSearch')"
              :suggestions="filteredTypes.map((type) => ({ ...type, label: t(type.label) }))"
              @complete="updateType($event)"
              @item-select="typeSelected($event)"
            >
              <template #item="slotProps">
                {{ slotProps.item.label }}
              </template>
            </AutoComplete>
          </div>
        </div>

        <div class="field w-full mb-0">
          <label for="sortAutocomplete" class="w-full">{{ t('leadsIndex.filterStage') }}:</label>
          <div class="relative w-full">
            <div
              v-if="stageValue"
              class="absolute project-filter"
              style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
              @click.stop="resetValue('stage')"
            ></div>
            <AutoComplete
              id="stageAutocomplete"
              v-model="stageValue"
              class="w-full"
              :class="{ empty: !stageValue }"
              style="max-height: 40px"
              :dropdown="true"
              :force-selection="true"
              option-label="label"
              :placeholder="t('leadsIndex.filterStageSearch')"
              :suggestions="filteredStages.map((stage) => ({ ...stage, label: t(stage.label) }))"
              @complete="updateStage($event)"
              @item-select="stageSelected($event)"
            >
              <template #item="slotProps">
                {{ slotProps.item.label }}
              </template>
            </AutoComplete>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<style scoped lang="scss">
  .p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-chip.status-readytoverify,
  .p-chip.status-inproduction {
    background: #ffda62;
    color: #333;
  }
  .p-chip.status-contractverify {
    background: #4137be;
    color: #fff;
  }
  .near-projects-container {
    width: 100%;
    @media screen and (min-width: 80em) {
      width: initial;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      .p-card {
        margin-bottom: 0 !important;
      }
    }
  }
</style>
<style lang="scss">
  .home-messages {
    .p-message {
      .p-message-wrapper {
        display: flex;
        width: 100%;

        > .p-message-text {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }
  .lb-pull-refresh {
    width: 100%;
  }
</style>
