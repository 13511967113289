export const photoQEn = {
  retryQueue: 'Upload Failed',
  uploadedQueue: 'Uploaded Successfully',
  uploadQueue: 'Currrently Uploading',
  waitingQueue: 'Waiting to Upload',
  messageFinished: 'Congratulations, you have no pending images to upload. All your images have been successfully uploaded.',
  suggestionMessage: 'If the internet connection is intermittent or there are issues, your images will be saved here to prevent them from being lost.',
}

export const photoQEs = {
  retryQueue: 'Subida fallida',
  uploadedQueue: 'Subido exitosamente',
  uploadQueue: 'Cargando actualmente',
  waitingQueue: 'Esperando para subir',
  messageFinished: 'Felicitaciones, no tienes imágenes pendientes para cargar. Todas tus imágenes se han cargado correctamente.',
  suggestionMessage: 'Si tu conexión a internet es intermitente o hay problemas, tus imágenes se guardarán aquí para evitar que se pierdan.',
}
