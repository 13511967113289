<script lang="ts" setup>
  import { ref, toRefs } from 'vue'

  const props = defineProps<{ url: string }>()
  const { url } = toRefs(props)
  const emit = defineEmits(['error', 'load'])
  const loading = ref(false)
  const handleLoadImage = (e: Event) => {
    emit('load', e)
    loading.value = true
  }
</script>

<template>
  <img
    v-if="url"
    :src="url"
    :style="{
      width: '100%',
      height: '100%',
      display: loading ? 'flex' : 'none',
    }"
    style="object-fit: contain; -webkit-user-drag: none; user-drag: none"
    @error="(e) => emit('error', e)"
    @load="handleLoadImage"
  />
</template>
