<script lang="ts" setup>
  import NearByLeads from './NearBy/NearByLeads.vue'
  import NearByProjects from './NearBy/NearByProjects.vue'
  import { getPermission, getPermissionsUser } from '@/components/PermissionRole/funtions'
  const permissions = getPermissionsUser()
</script>

<template>
  <NearByLeads v-if="getPermission(permissions, 'todayLeads', 'menuPermissions')" />
  <NearByProjects v-else />
</template>

<style scoped lang="scss">
  .p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-chip.status-readytoverify,
  .p-chip.status-inproduction {
    background: #ffda62;
    color: #333;
  }
  .p-chip.status-contractverify {
    background: #4137be;
    color: #fff;
  }
  .near-projects-container {
    width: 100%;
    @media screen and (min-width: 80em) {
      width: initial;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      .p-card {
        margin-bottom: 0 !important;
      }
    }
  }
</style>
<style lang="scss">
  .home-messages {
    .p-message {
      .p-message-wrapper {
        display: flex;
        width: 100%;

        > .p-message-text {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }
  .lb-pull-refresh {
    width: 100%;
  }
</style>
