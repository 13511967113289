<script lang="ts" setup>
  import { IProject } from '@/interfaces/project/project.interface'
  import { toRefs } from 'vue'
  // import moment from 'moment'
  // import { computed } from 'vue'
  import { i18n } from '@/i18n'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  type ILead = {
    customerNumber: string | null
    projectInterests?: string[]
  } & IProject

  type Iprops = {
    lead: ILead
  }

  const { t } = i18n.global
  const props = defineProps<Iprops>()
  const { lead } = toRefs(props)

  const renderTimeStamp = () => {
    if (lead.value.actualStartDate) {
      const date = useDateFormatUtil(lead.value.actualStartDate, 'MMM D, YYYY')
      return date
    } else {
      return '--'
    }
  }
</script>

<template>
  <Card class="col col-12 flex-grow-1">
    <template #title>
      <router-link :to="`/leads/${lead.id}/media`">
        <h2 class="text-color font-bold text-xl mb-3 w-15 white-space-nowrap overflow-hidden text-overflow-ellipsis">
          {{ lead.name }}
        </h2>
      </router-link>
    </template>
    <template #content>
      <div class="w-full flex flex-column mb-2">
        <div class="mb-2 text-600 overflow-hidden white-space-nowrap" style="text-overflow: ellipsis">
          <i v-if="lead.address" class="pi pi-directions"></i>
          {{ lead.address }}
        </div>
        <p class="mb-2 text-600">
          <i class="pi pi-hashtag"></i>
          {{ lead?.customerNumber ?? t('LeadCardComponent.noCustomerNumber') }}
        </p>
        <div v-if="lead.actualStartDate || lead.actualStartDate === null" class="mb-2 text-600 overflow-hidden white-space-nowrap" style="text-overflow: ellipsis">
          <i class="pi pi-calendar"></i>
          {{ lead.actualStartDate !== null ? renderTimeStamp() : t('leadCardComponent.noActualStartDate') }}
        </div>
        <div class="mb-2 text-600 overflow-hidden white-space-nowrap" style="text-overflow: ellipsis">
          <i v-if="(lead?.projectInterests ?? []).length !== 0" class="pi pi-home"></i>
          {{ (lead?.projectInterests ?? []).length > 0 ? lead?.projectInterests?.join(', ') : t('leadCardComponent.noProjectInterests') }}
        </div>
      </div>
    </template>
  </Card>
</template>

<style scoped lang="scss">
  .border-left-8 {
    border-left: 8px solid;
  }

  .border-blue {
    border-color: #3b82f6;
  }
</style>
