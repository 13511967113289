<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup lang="ts">
  import api from '@/services/api'
  import { useRouter } from 'vue-router'
  import { ref, computed, onMounted, watch } from 'vue'
  import { IProjectV2 } from '@/interfaces/project/project.interface'

  // Refs
  const router = useRouter()
  const currentStartDate = ref(new Date())
  const projects = ref<IProjectV2[]>([])

  //Funtions
  async function fetchCalendarData(startDate: string, endDate: string) {
    try {
      const paramsQuery = new URLSearchParams({
        startDate: startDate,
        endDate: endDate,
      }).toString()
      const { data } = await api.get(`/Api/v2/Projects/Calendar?${paramsQuery}`)
      projects.value = data.data.items
    } catch (error) {
      console.log('Error Calendar: ', error)
    }
  }

  function getMonday(d: Date): Date {
    const day = d.getDay()
    const diff = d.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(d.setDate(diff))
  }

  function formatDate(date: Date): string {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const formatDate2 = (dateString: string | Date) => {
    const date = new Date(dateString)
    return date.toISOString().split('T')[0]
  }

  function formatDateDay(date: Date) {
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: '2-digit' }
    const formattedDate = date.toLocaleDateString('en-US', options)
    const [name, number] = formattedDate.split(' ')
    return { name: name, number: number }
  }

  function formatDatePreview(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short' }
    return date.toLocaleDateString('es-ES', options)
  }

  const dateRange = computed(() => {
    const endDate = new Date(currentStartDate.value)
    endDate.setDate(currentStartDate.value.getDate() + 6)
    return `${formatDatePreview(currentStartDate.value)} - ${formatDatePreview(endDate)}`
  })

  function updateCalendarData() {
    const startDate = formatDate(currentStartDate.value)
    const endDate = new Date(currentStartDate.value)
    endDate.setDate(endDate.getDate() + 6)
    const endDateFormatted = formatDate(endDate)
    fetchCalendarData(startDate, endDateFormatted)
  }

  function prevWeek() {
    const startDate = new Date(currentStartDate.value)
    startDate.setDate(startDate.getDate() - 7)
    currentStartDate.value = getMonday(startDate)
  }

  function nextWeek() {
    const startDate = new Date(currentStartDate.value)
    startDate.setDate(startDate.getDate() + 7)
    currentStartDate.value = getMonday(startDate)
  }

  function navigateToProyects(_event: IProjectV2) {
    router.push(`/projects/${_event.id}/media`)
  }

  const weekDays = computed(() => {
    const startDate = currentStartDate.value
    const daysOfWeek = []
    for (let i = 0; i < 7; i++) {
      const dayDate = new Date(startDate)
      dayDate.setDate(startDate.getDate() + i)
      daysOfWeek.push({ date: dayDate })
    }
    return daysOfWeek
  })

  //Hooks
  onMounted(() => {
    updateCalendarData()
  })

  const today = computed(() => {
    const todayDate = new Date()
    return formatDate(todayDate)
  })

  const projectsByDay = computed(() =>
    weekDays.value.map(({ date }) => {
      const formattedDayDate = formatDate2(date)
      const { name, number } = formatDateDay(date)

      return {
        name,
        number,
        date: formattedDayDate,
        projects: projects.value.filter(({ actualStartDate }) => formattedDayDate === formatDate2(actualStartDate)),
      }
    })
  )

  watch(currentStartDate, () => {
    updateCalendarData()
  })
</script>

<template>
  <div style="display: flex; justify-content: center">
    <div class="flex flex-column pt-3" style="width: 1080px">
      <h1>Calendar</h1>

      <!-- Options Calendar -->
      <div class="flex justify-content-between align-items-center flex-wrap">
        <button class="p-button p-button-secondary buttonCalendar" @click="prevWeek">&lt;</button>
        <h2 class="textRangeCalendar text-center flex-grow">{{ dateRange.toLocaleUpperCase() }}</h2>
        <button class="p-button p-button-secondary buttonCalendar" @click="nextWeek">&gt;</button>
      </div>

      <!-- Columns Calendar -->
      <div class="flex flex-column pt-5">
        <div v-for="(day, index) in projectsByDay" :key="index" class="p-col mb-4">
          <div class="p-mb-1 flex flex-row">
            <div class="flex flex-column pt-3" style="width: 2rem; display: flex; align-items: center">
              <p class="font-bold" :class="{ 'text-blue-500': day.date === today }">
                {{ day.number.toLocaleUpperCase() }}
              </p>
              <p
                class="font-bold"
                :class="{ 'bg-blue-500 text-white': day.date === today }"
                :style="day.date === today ? { borderRadius: '100px', justifyContent: 'center', width: '30px', height: '30px', display: 'flex', alignItems: 'center' } : {}"
              >
                {{ day.name.toLocaleUpperCase() }}
              </p>
            </div>
            <div class="ml-4 flex flex-1">
              <div class="flex flex-1">
                <ul v-if="day.projects.length" class="p-list list-none w-full">
                  <li v-for="(event, i) in day.projects" :key="i" class="mb-3 flex">
                    <button class="p-card flex-1 p-card-body p-6 shadow-none hover:shadow-none text-left border-none button-hover-pointer" @click="navigateToProyects(event)">
                      <p class="font-bold">{{ event.name }}</p>
                      <p class="gap-4">{{ event.address }}</p>
                      <p>{{ event.poNumber }}</p>
                    </button>
                  </li>
                </ul>
                <div v-else class="p-card shadow-none flex-1 p-card-body ml-6 mt-3">
                  <div class="p-5">
                    <p class="text-center text-gray-500">No events today</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .buttonCalendar {
    height: 45px;
    width: 40px;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    font-size: 23px;
    border: none;
    cursor: pointer;
  }
  .textRangeCalendar {
    font-size: 1.3rem;
    align-items: center;
    text-align: center;
    font-weight: bold;
  }
  .buttonCalendar:hover {
    background-color: #333;
  }
</style>
