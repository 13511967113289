<script lang="ts" setup>
  import { onMounted, ref, toRefs } from 'vue'
  import LazyLoadComponent from '@/components/LazyLoadComponent.vue'
  import ImageComponent from '@/components/ImageComponent.vue'
  import Skeleton from 'primevue/skeleton'
  import { IProblemPhoto } from '@/interfaces/problem'

  interface IProps {
    photo: IProblemPhoto
  }

  const props = defineProps<IProps>()
  const { photo } = toRefs(props)
  const url = ref('')
  const failed = ref(false)
  const handleError = () => {
    failed.value = true
  }

  const handleLoad = () => {
    failed.value = false
  }

  onMounted(() => {
    url.value = photo.value.url
  })
</script>

<template>
  <LazyLoadComponent class="cursor-pointer masonry-item shadow-2">
    <div style="height: 100%; width: 100%">
      <figure :class="failed ? 'hidden' : ''">
        <ImageComponent :url="url" @onerror="handleError" @onload="handleLoad" />
      </figure>
      <div :class="!failed ? 'hidden' : ''" class="w-full h-full flex px-3 justify-content-center align-items-center">
        <ProgressBar
          :pt="{
            value: { style: { 'background-color': 'var(--gray-300)' } },
          }"
          mode="indeterminate"
          style="height: 6px; width: 100%"
        />
      </div>
    </div>
  </LazyLoadComponent>
</template>

<style lang="scss" scoped>
  .hidden {
    display: none;
  }
  .masonry-item {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }
    }
  }
</style>
