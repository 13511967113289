export const finalizePunchListsEnUs = {
  noListCloseOut: 'This close-out list does not exist. If you continue to receive this message please email ',
  back: 'Back',
  submit: 'Submit',
  next: 'Next',
  selectPhoto: 'Select Photo',
  takePicture: 'Take Picture',
  notApplicable: 'Not Applicable',
  crewReview: 'Crew Review',
  upload: 'Upload photo',
  emptyListCrews: 'The list of crews is empty',
}

export const finalizePunchListsEsMx = {
  noListCloseOut: 'Esta lista de cierre no existe. Si continúa recibiendo este mensaje, envíe un correo electrónico a ',
  back: 'Regresar',
  submit: 'Enviar',
  next: 'Siguiente',
  selectPhoto: 'Seleccionar Foto',
  takePicture: 'Tomar Foto',
  notApplicable: 'No Aplica',
  crewReview: 'Revisión de crew',
  upload: 'Subir Foto',
  emptyListCrews: 'La lista de crews esta vacia',
}
