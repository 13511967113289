export const editorImageEnUs = {
  save: 'SAVE',
  cancel: 'CANCEL',
  reset: 'RESET',
  adjustColor: 'Adjust Color',
  brightness: 'Brightness',
  contrast: 'Contrast',
  saturation: 'Saturation',
  discardChanges: 'Discard changes',
  discardChangesMessage: 'Are you sure you want to close? All changes will be lost',
  discardButtonCancel: 'Cancel',
  discardButtonAccept: 'Confirm',
}

export const editorImageEsMx = {
  save: 'GUARDAR',
  cancel: 'CANCELAR',
  reset: 'REINICIAR',
  adjustColor: 'Ajustar Color',
  brightness: 'Brillo',
  contrast: 'Contraste',
  saturation: 'Saturación',
  discardChanges: 'Descartar cambios',
  discardChangesMessage: ' ¿Estás seguro que quieres cerrar? Todos los cambios se perderán',
  discardButtonCancel: 'Cancelar',
  discardButtonAccept: 'Aceptar',
}
