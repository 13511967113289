// composables/useDraw.js
import { reactive, toRefs } from 'vue'

const state = reactive({
  isDrawing: false,
  isPaint: false,
  points: [] as number[],
})

function startDrawing(x: number, y: number, scale: number) {
  if (!state.isPaint) return

  state.points = [x / scale, y / scale]
  state.isDrawing = true
}

function continuousDrawing(x: number, y: number, scale: number) {
  if (!state.isDrawing) return

  state.points = [...state.points, x / scale, y / scale]
}

function stopDrawing() {
  return state.points
}

function clearDrawPoints() {
  state.isDrawing = false
  state.points = []
}

function isPaintOn() {
  state.isPaint = !state.isPaint
}
function stopPaintOff() {
  state.isPaint = false
  state.isDrawing = false
}
function hadleResetDraw() {
  state.isDrawing = false
  state.points = []
  state.isPaint = false
}
export function useDraw() {
  return {
    ...toRefs(state),
    startDrawing,
    continuousDrawing,
    stopDrawing,
    clearDrawPoints,
    isPaintOn,
    stopPaintOff,
    hadleResetDraw,
  }
}
