import axios from 'axios'
import { baseUrl } from '@/constants'
import { useAccountStore } from '@/store'
import { storeToRefs } from 'pinia'
import * as Sentry from '@sentry/vue'
const instance = axios.create({ baseURL: baseUrl })
const version = import.meta.env.PACKAGE_VERSION

const noAuthPaths = ['/api/resetpassword']
const publicPaths: string[] = ['galleries']

instance.interceptors.request.use(
  async (config) => {
    const accountStore = useAccountStore()
    const { tenant, accessToken } = storeToRefs(accountStore)
    const pathname: string = window.location.pathname

    const path: string = pathname?.split?.('/')?.[1] ?? ''

    if (config.url && tenant && tenant.value) {
      if (!publicPaths.some((pb) => path.includes(pb))) config.url = `${tenant.value}${config.url}`
    }
    if (config && config.headers && !noAuthPaths.includes(`${config.url}`.toLocaleLowerCase())) config.headers.Authorization = `Bearer ${accessToken.value}`

    config.headers['appversion'] = version
    return config
  },
  (err) => Promise.reject(err)
)

instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response?.status === 401) {
      const accountStore = useAccountStore()
      accountStore.$reset()
      const win: Window = window
      win.location = '/account/logout'
      return
    }
    if (error?.response?.config) {
      const { config } = error.response
      Sentry.captureException(error, {
        level: 'warning',
        tags: { axios: 'request' },
        contexts: {
          status: error.response?.status,
          data: { method: config.method, url: config.url, Autorization: config.headers?.Authorization ?? 'no token', data: config.data },
        },
      })
    } else {
      Sentry.captureException(error, { level: 'warning', tags: { axios: 'request' } })
    }
    return Promise.reject(error)
  }
)

export default instance
