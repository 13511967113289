export const settingsIndexEnUS = {
  settings: 'Settings',
  companyInformation: 'Company Information',
  companyName: 'Company Name',
  address: 'Address',
  website: 'Website',
  integrations: 'Integrations',
  connectWithGoogle: 'Connect with Google',
  pageDescription: 'Select and connect tools you use too integrate in your workflow',
  connectWithZohoDescription: 'Connect your Zoho CRMAccount',
  connectWithGoogleWorkspaceDescription: 'Connect your Google Workspace account',
  connectWithOffice365Description: 'Connect your Office 365 account',
  connect: 'Connect',
  connected: 'Connected',
  changePassword: 'Change password',
  labelPassword: 'CHANGE PASSWORD',
  errorTitle: 'Something went wrong',
  successTitle: 'Success',
  successMessage: 'Your password has been changed successfully',
  settingsDiagnostic: 'Send Diagnostic',
  settingsTitleDiagnostic: 'How can we help you?',
  settingsDescriptionDiagnostic: 'Are you having problems? Please send us a description of the problem.',
  settingsSendDiagnostic: 'Send diagnostic',
  successDiagnostic: 'Send diagnosis successfully',
  successTitleDiagnostic: 'Success',
  loginWithOtherUserAdmin: 'Login with another user of the organization',
  loginWithOtherUserTitleModal: 'Login with another user',
  loginWithOtherUserDescriptionModal: 'Select the user of your organization to log in temporarily, your session as administrator will remain active.',
  loginWithOtherUserLabelDropdown: 'Select a user',
  loginWithOtherUserButtonSubmit: 'Log in',
  loginWithOtherUserError: 'Please select a user',
  loginBackToAdmin: 'Back to admin',
  loginBackToAdminDescription: 'Would you like to return to the administrator? ',
  loginBackToAdminButton: 'Yes! Go back',
  loginWithOtherTenant: 'Login with another tenant',
  loginWithOtherTenantDescriptionModal: 'Select the organization you want to log in with',
  loginWithOtherTenantButton: 'Change tenant',
  dialog_settings: 'More options',
  FACEID_PASSWORD: 'Your Face ID is going to update too.',
  changedTenant: 'Now you are in the organization:',
}

export const settingsIndexEsMX = {
  settings: 'Configuraciones',
  companyInformation: 'Información de la empresa',
  companyName: 'Nombre de la empresa',
  address: 'Dirección',
  website: 'Sitio web',
  integrations: 'Integraciones',
  connectWithGoogle: 'Conectar con Google',
  pageDescription: 'Seleccione y conecte las herramientas que utiliza para integrar en su flujo de trabajo',
  connectWithZohoDescription: 'Conecta tu cuenta de Zoho CRM',
  connectWithGoogleWorkspaceDescription: 'Conecta tu cuenta de Google Workspace',
  connectWithOffice365Description: 'Conecta tu cuenta de Office 365',
  connect: 'Conectar',
  connected: 'Conectado',
  changePassword: 'Cambiar contraseña',
  labelPassword: 'CAMBIAR CONTRASEÑA',
  errorTitle: 'Algo salió mal',
  successTitle: 'Éxito',
  successMessage: 'Su contraseña se ha cambiado correctamente',
  settingsDiagnostic: 'Enviar Diagnóstico',
  settingsTitleDiagnostic: 'En que podemos ayudarte?',
  settingsDescriptionDiagnostic: 'Estas teniendo problemas? Porfavor envianos una descripcion del problema',
  settingsSendDiagnostic: 'Enviar diagnostico',
  successDiagnostic: 'Diagnóstico enviado con éxito',
  successTitleDiagnostic: 'Éxitosamente',
  loginWithOtherUserAdmin: 'Acceder con otro usuario de la organización',
  loginWithOtherUserTitleModal: 'Acceder con otro usuario',
  loginWithOtherUserDescriptionModal: 'Selecciona el usuario de tu organizacion para iniciar una sesion temporal, Tu sesion como administrador continuara activa.',
  loginWithOtherUserLabelDropdown: 'Selecciona un usuario',
  loginWithOtherUserButtonSubmit: 'Iniciar sesion',
  loginWithOtherUserError: 'Porfavor selecciona un usuario',
  loginBackToAdmin: 'Regresar al administrador',
  loginBackToAdminDescription: '¿Desea volver al administrador? ',
  loginBackToAdminButton: 'Si! Quiero regresar',
  loginWithOtherTenant: 'Acceder con otra organizacion',
  loginWithOtherTenantDescriptionModal: 'Selecciona la organizacion con la que deseas iniciar sesion',
  loginWithOtherTenantButton: 'Cambiar de organizacion',
  dialog_settings: 'Mas opciones',
  FACEID_PASSWORD: 'Tus credenciales de Face ID tambien se actualizaran.',
  changedTenant: 'Ahora estás en la organización:',
}
