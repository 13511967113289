export const projectProblemEnUS = {
  inProgress: 'In Progress',
  open: 'Open',
  closed: 'Close',
  delete: 'Delete',
  success: 'Success',
  successMarkedOpen: 'Problem marked as Open',
  error: 'Error',
  errorMarkedOpen: 'Error marking Problem as Open',
  successMarkedInProgress: 'Problem marked as In Progress',
  errorMarkedInProgress: 'Error marking Problem as In Progress',
  successCreated: 'Successfully created problem',
  errorCreating: 'Error creating problem',
  successDeleted: 'Problem deleted',
  errorDeleting: 'Error deleting Problem',
  successMarkedClosed: 'Problem marked as Closed',
  errorMarkedClosed: 'Error marking Problem as Closed',
  type: 'Type',
  description: 'Description',
  assignedTo: 'Assigned To',
  assignedDate: 'Assigned Date',
  confirmDeletion: 'Confirm Deletion',
  areYouSure: 'Are you sure you want to delete this Problem?',
  cancel: 'CANCEL',
  confirm: 'CONFIRM',
}

export const projectProblemEsMX = {
  inProgress: 'En progreso',
  open: 'Abrir',
  closed: 'Cerrar',
  delete: 'Eliminar',
  success: 'Éxito',
  successMarkedOpen: 'Problema marcado como abierto',
  error: 'Error',
  errorMarkedOpen: 'Error marcando problema como abierto',
  successMarkedInProgress: 'Problema marcado como en progreso',
  errorMarkedInProgress: 'Error marcando problema como en progreso',
  successCreated: 'Problema creado con éxito',
  errorCreating: 'Error creando problema',
  successDeleted: 'Problema eliminado',
  errorDeleting: 'Error eliminando problema',
  successMarkedClosed: 'Problema marcado como cerrado',
  errorMarkedClosed: 'Error marcando problema como cerrado',
  type: 'Tipo',
  description: 'Descripción',
  assignedTo: 'Asignado a',
  assignedDate: 'Fecha de asignación',
  confirmDeletion: 'Confirmar eliminación',
  areYouSure: '¿Estás seguro de que quieres eliminar este problema?',
  cancel: 'CANCELAR',
  confirm: 'CONFIRMAR',
}
