export const commentsComponentEnUS = {
  warn: 'Warn',
  cannotBeBlank: 'Comment cannot be blank',
  error: 'Error',
  Success: 'Success',
  commentSaved: 'Comment saved',
  errorCreating: 'Error creating comment',
  noComments: 'No Comments',
  submit: 'SUBMIT',
  coments: 'Comments',
}

export const commentsComponentEsMX = {
  warn: 'Advertencia',
  cannotBeBlank: 'El comentario no puede estar vacío',
  error: 'Error',
  Success: 'Éxito',
  commentSaved: 'Comentario guardado',
  errorCreating: 'Error al crear el comentario',
  noComments: 'Sin comentarios',
  submit: 'ENVIAR',
  coments: 'Comentarios',
}
