<script setup lang="ts">
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, onUnmounted, ref, toRefs, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useBreakpointsStore, useLeadStore, useUserStore } from '@/store'
  import ProjectMedia from './project-media/ProjectMedia.vue'
  import ProjectNotes from './notes/ProjectNotes.vue'
  import ProjectDocuments from './LeadDocuments.vue'
  import api from '@/services/api'
  import GalleryComponent from '@/components/GalleryComponent.vue'

  import { i18n } from '@/i18n'
  const { t } = i18n.global

  import ContactSheet from '@/components/ContactSheet.vue'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { getPermission, getPermissionsUser, Permissions } from '@/components/PermissionRole/funtions'
  import TabView from 'primevue/tabview'
  import { Capacitor } from '@capacitor/core'
  import { addNewIQueuePhotosCamera, IQueuePhoto, newIQueuePhoto, QueueStatusType } from '@/db/tables'
  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  import { delay } from '@/utils/delay'
  import { TPhotoContent } from '@/constants/types'
  import QueueCardProjectLead from '@/components/QueueCardProjectLead.vue'
  import { base64ToBlob } from '@/utils/base64Toblob'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import { checkinUpdate } from '@/services/project'
  import { useToast } from 'primevue/usetoast'

  const getTabsPermission = (
    menu: {
      id: string
      label: string
      keyNodo: string
      keyName: string
    }[]
  ) => {
    return menu.filter((item) => getPermission(getPermissionsUser()?.leadsPermissions as Permissions, item.keyName, item.keyNodo))
  }
  const userStore = useUserStore()
  const toast = useToast()
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const { zohoIntegration } = storeToRefs(userStore)
  const loadingProjects = ref(true)
  const photoNotification = ref<null | TPhotoContent>(null)
  const route = useRoute()
  const projectId = ref(`${route.params.id}`)
  const tab = `${route.params.tab}`
  const contactInfoVisible = ref(false)
  const router = useRouter()
  const queueStore = uploadNewQueuePhoto()
  const { uploadQueuePhotos } = queueStore
  const { queue } = toRefs(queueStore)
  const projectStore = useLeadStore()
  const { fetchProjectById, destroy, setProjectMedia } = useLeadStore()
  const { project, projectMedia } = storeToRefs(projectStore)

  async function populateProjectDetails() {
    const requests = [fetchProjectById(projectId.value)]
    Promise.all(requests).then(() => {
      loadingProjects.value = false
    })
  }

  onMounted(async () => {
    await populateProjectDetails()
    uploadQueuePhotos().then(() => console.log('uploadQueuePhotos'))
  })

  const tabs = [
    { id: 'media', label: t('projectId.media'), keyNodo: 'leadsMediaPermissions', keyName: 'show' },
    { id: 'documents', label: t('projectId.documents'), keyNodo: 'leadsDocumentsPermissions', keyName: 'show' },
    { id: 'notes', label: t('projectId.notes'), keyNodo: 'leadsNotePermissions', keyName: 'show' },
  ]

  const tabMenu = ref(getTabsPermission(tabs))
  const tabActive = ref(tabMenu.value.findIndex((thisTab) => thisTab.id.includes(tab)) || 0)
  const showDialogRelocation = ref(false)
  const loadinCheckin = ref(false)
  const loadingUpdateCheckin = ref(false)

  const handleUpdateCheckin = async () => {
    try {
      loadingUpdateCheckin.value = true
      const { latitude, longitude } = await fetchCurrentLocationOnce(true)
      await checkinUpdate({
        id: projectId.value,
        payload: {
          latitude,
          longitude,
        },
        type: 'Leads',
      })
      showDialogRelocation.value = false
      toast.add({ severity: 'success', summary: t('projectId.success'), detail: t('projectId.checkInUpdate.success'), life: 3000 })
    } catch (error) {
      console.log('hello test')
      toast.add({
        severity: 'error',
        summary: t('projectId.error'),
        detail: t('projectId.checkInUpdate.error'),
        life: 3000,
      })
    } finally {
      loadingUpdateCheckin.value = false
    }
  }
  const checkinLead = async () => {
    try {
      loadinCheckin.value = true
      const { latitude, longitude } = await fetchCurrentLocationOnce(true)
      await api.post('/Api/Leads/CheckIn', {
        leadId: projectId.value,
        latitude: `${latitude}`,
        longitude: `${longitude}`,
      })
      toast.add({ severity: 'success', summary: t('projectId.success'), detail: t('projectId.checkedInCorrectly'), life: 3000 })
    } catch (error: any) {
      const errorMessage = error.response?.data?.responseException?.exceptionMessage?.message

      toast.add({
        severity: 'error',
        summary: t('projectId.error'),
        detail: errorMessage ?? t('projectId.errorCheckingIn'),
        life: 3000,
      })

      const label = 'You are not in the lead area'
      if (errorMessage === label) {
        showDialogRelocation.value = true
      }
    } finally {
      loadinCheckin.value = false
    }
  }

  const editImage = async (value: { id: string; base64: string; base64Content: string }) => {
    if (!value) return
    console.log('value data', value)
    setProjectMedia(
      projectMedia.value.map((x) => {
        if (x.id === value.id) return { ...x, url: value.base64 }
        return x
      })
    )
    await api.patch(`/Api/Lead/${project.value.id}/Photo/${value.id}`, {
      base64Content: value.base64Content,
    })
  }

  const openUrlInTab = (address: string, latitude?: number, longitude?: number) => {
    let url = ''

    if (Capacitor.getPlatform() === 'ios') {
      if (latitude !== undefined && longitude !== undefined && latitude !== null && longitude !== null) {
        url = `http://maps.apple.com/?q=${latitude},${longitude}`
      } else {
        url = `http://maps.apple.com/?q=${address}`
      }
    } else {
      if (latitude !== undefined && longitude !== undefined && latitude !== null && longitude !== null) {
        url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
      } else {
        url = `https://www.google.com/maps/search/?api=1&query=${address}`
      }
    }

    const _url = new URL(url)
    console.log(_url.href)
    window.open(_url.href, '_blank', 'noreferrer')
  }

  function tabClick(tabEvent: { index: number; originalEvent: Event }) {
    router.push(`/leads/${projectId.value}/${tabMenu.value[tabEvent.index].id}`)
  }

  function goBack() {
    router.push('/leads')
  }

  function openContactSheet() {
    contactInfoVisible.value = !contactInfoVisible.value
  }

  const uploadPhotos = async (photos: IQueuePhoto[]) => {
    const uploadPhotoType = await devicePhotoTag('UPLOAD')
    const info = photos.map((photo) => {
      const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')

      const photoData: newIQueuePhoto = {
        id: photo.id,
        projectId: photo.projectId,
        latitude: photo.latitude,
        longitude: photo.longitude,
        photoType: photo.photoType,
        entityType: 'Lead',
        photoTypeId: photo.photoTypeId,
        file: blob,
        status: 'IDLE' as QueueStatusType,
        date: new Date(),
        errorMessage: '',
        retries: 0,
        uploadPhotoType,
        poNumberProject: project.value?.customerNumber ?? '',
      }
      return { ...photoData }
    })

    await addNewIQueuePhotosCamera(info)
    await uploadQueuePhotos()
    await delay(1000)
  }

  const redirectTab = (tab: string) => {
    const tabMenuIndex = tabMenu.value.findIndex((item) => item.id === tab)
    tabActive.value = tabMenuIndex
    router.push(`/leads/${projectId.value}/${tab}`)
  }

  // const takePictures = (id: string) => {
  //   camera.emit({
  //     propsCamera: { projectId: id, photoType: PhotoTypeEnum.PREPRODUCTION_PICTURES },
  //     actions: { takePicture: takePicture, editPhoto: editPhoto, deletePhoto: deletePhoto, closeCamera: closeCamera },
  //   })
  // }
  watch(project, (currentProject) => {
    if (currentProject?.isConverted) {
      router?.push(`${currentProject?.redirectURL}`)
    }
  })
  watch(
    () => `${route.params.id}`,
    async (newProjectId, oldProjectId) => {
      if (newProjectId !== oldProjectId && oldProjectId !== undefined && newProjectId !== `undefined`) {
        loadingProjects.value = true
        projectId.value = newProjectId
        await populateProjectDetails()
      }
    },
    { immediate: true }
  )

  onUnmounted(() => {
    destroy()
  })
</script>

<template>
  <template v-if="!loadingProjects">
    <GalleryComponent
      v-if="photoNotification !== null"
      :images="[photoNotification]"
      show-detail
      active-comment
      :show-comments="project.isAuthorized"
      :class-custom="{
        container: 'hidden',
      }"
      entity-type="Leads"
      @edit-image="(data: any | undefined) => editImage(data.image)"
    />

    <div class="grid mx-0 justify-content-center relative h-full">
      <Toast position="bottom-right" />
      <div class="col col-12 px-0 flex flex-column" style="height: 100%">
        <div class="grid">
          <div class="col col-12">
            <div class="flex flex-row align-items-center w-full mb-3">
              <Button class="p-button-text p-button-rounded" style="min-height: 42px; min-width: 42px; height: 42px; width: 42px" icon="pi pi-arrow-left" @click="goBack" />
              <div class="flex flex-column align-items-srart justify-content-start ml-2" style="width: calc(100% - 84px)">
                <h5 class="text-left font-bold mb-1 text-overflow-ellipsis white-space-nowrap overflow-hidden">
                  {{ project.name }}
                </h5>
                <div v-if="project?.customerNumber" class="text-600 text-left text-sm text-overflow-ellipsis white-space-nowrap overflow-hidden">
                  {{ project?.customerNumber }}
                </div>
                <div class="text-600 text-left text-sm text-overflow-ellipsis white-space-nowrap overflow-hidden">
                  {{ project.address }}
                </div>
                <div class="text-600 text-left text-sm text-overflow-ellipsis white-space-nowrap overflow-hidden">
                  {{ project.projectStage }}
                </div>
              </div>
            </div>

            <div class="flex-row flex justify-content-evenly align-items-center gap-2 w-full" :class="{ 'h-5rem': !md, 'mb-2': !md }">
              <!-- customer details -->
              <PermissionRole :nodo-name="'buttons'" :key-name="'contact'">
                <Button class="btn flex-column md:flex-row flex justify-content-center align-items-center w-full" :class="{ 'h-full': !md, 'px-2': !md }" @click="openContactSheet">
                  <svg style="height: 24px; width: 24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <title>account</title>
                    <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
                  </svg>
                  <span class="text-xs mt-1 md:text-base md:mt-0 md:ml-2">{{ t('projectId.contact') }}</span>
                </Button>
              </PermissionRole>

              <!-- directions -->
              <PermissionRole :nodo-name="'buttons'" :key-name="'directions'">
                <Button
                  class="btn flex-column md:flex-row flex justify-content-center align-items-center w-full"
                  :class="{ 'h-full': !md, 'px-2': !md }"
                  @click="openUrlInTab(`${project.address}`, project.latitude, project.longitude)"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M14,14.5V12H10V15H8V11A1,1 0 0,1 9,10H14V7.5L17.5,11M21.71,11.29L12.71,2.29H12.7C12.31,1.9 11.68,1.9 11.29,2.29L2.29,11.29C1.9,11.68 1.9,12.32 2.29,12.71L11.29,21.71C11.68,22.09 12.31,22.1 12.71,21.71L21.71,12.71C22.1,12.32 22.1,11.68 21.71,11.29Z"
                    />
                  </svg>
                  <span class="text-xs mt-1 md:text-base md:mt-0 md:ml-2">{{ t('projectId.directions') }}</span>
                </Button>
              </PermissionRole>

              <!-- check-in -->
              <PermissionRole v-if="project.isAuthorized" :nodo-name="'buttons'" :key-name="'checkIn'">
                <Button
                  class="btn flex-column md:flex-row flex justify-content-center align-items-center w-full"
                  :class="{ 'h-full': !md, 'px-2': !md }"
                  :disabled="!zohoIntegration"
                  :loading="loadinCheckin"
                  @click="checkinLead"
                >
                  <svg v-if="!loadinCheckin" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                    />
                  </svg>
                  <svg v-if="loadinCheckin" class="rotating" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                  <span class="text-xs mt-1 md:text-base md:mt-0 md:ml-2">{{ $t('projectId.checkIn') }}</span>
                </Button>
              </PermissionRole>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col overflow-hidden">
            <TabView
              v-model:activeIndex="tabActive"
              :scrollable="true"
              :previous-button-props="{
                style: 'display: none',
              }"
              :next-button-props="{
                style: 'display: none',
              }"
              @tab-click="tabClick"
            >
              <TabPanel
                v-for="item in tabMenu.filter((item) => getPermission(getPermissionsUser(), item.keyName, item.keyNodo))"
                :key="item.id"
                :header="item.label"
                content-class="hidden"
              />
            </TabView>
          </div>
        </div>
        <div class="grid overflow-y-auto overflow-x-hidden flex-1 layout-save-m">
          <div
            v-if="(queue ?? []).length > 0"
            class="grid align-content-start w-full h-10rem p-2 flex-column mb-3 align-items-srart gap-2"
            :class="(queue ?? []).length > 3 && 'custombar overflow-x-scroll'"
          >
            <QueueCardProjectLead v-for="photo in queue || []" :key="photo.id" :photo="photo" />
          </div>
          <div v-if="tabMenu[tabActive]?.id === 'media'" class="col-12 h-full relative px-0">
            <ProjectMedia
              :project-id="projectId"
              @uploading="
                (e) => {
                  uploadPhotos(e)
                }
              "
            />

            <div class="h-2rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'documents'" class="col-12 h-full relative px-0">
            <ProjectDocuments :project-data="project" />

            <div class="h-2rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'notes'" class="col-12 h-full relative px-0">
            <ProjectNotes :project-id="projectId" />

            <div class="h-6rem" />
          </div>
        </div>
        <PermissionRole v-if="Capacitor.isNativePlatform()" :nodo-name="'quickMenu'" :key-name="'show'">
          <div
            class="z-5 flex justify-content-center py-4 layout-save relative"
            style="background-color: #f9fafb; width: 100%; position: fixed !important; bottom: 0; right: 0; left: 0; z-index: 1 !important; border-top: 3px solid #e5e7eb"
          >
            <div class="absolute flex gap-2">
              <PermissionRole :nodo-name="'quickMenu'" :key-name="'documents'">
                <Button rounded icon="pi pi-folder" class="p-4 shadow-3 btn-camera" severity="secondary" style="top: -40px" @click="() => redirectTab('documents')" />
              </PermissionRole>
              <!-- <PermissionRole :nodo-name="'quickMenu'" :key-name="'camera'">
                <Button rounded icon="pi pi-camera" class="p-5 shadow-3 btn-camera" style="top: -50px" @click="() => takePictures(projectId)" />
              </PermissionRole> -->
              <PermissionRole :nodo-name="'quickMenu'" :key-name="'notes'">
                <Button rounded icon="pi pi-pencil" class="p-4 shadow-3 btn-camera" severity="secondary" style="top: -40px" @click="() => redirectTab('notes')" />
              </PermissionRole>
            </div>
          </div>
        </PermissionRole>
      </div>
    </div>

    <Dialog
      v-model:visible="showDialogRelocation"
      class="w-full ml-8"
      style="max-width: 40rem; z-index: 1215"
      modal
      :draggable="false"
      close-on-escape
      closable
      :header="t('projectId.checkInUpdate.title')"
    >
      <Message severity="error" :closable="false" icon="pi pi-lock">{{ t('projectId.checkInUpdate.leadError') }} </Message>
      <p>
        {{ t('projectId.checkInUpdate.makeSure') }}
      </p>
      <Button
        class="btn flex-row flex justify-content-center align-items-center w-full bg-black-alpha-90 border-0"
        :label="t('projectId.checkInUpdate.finish')"
        :loading="loadingUpdateCheckin"
        @click="handleUpdateCheckin"
      >
      </Button>
    </Dialog>

    <Dialog
      v-model:visible="contactInfoVisible"
      :position="!md ? 'bottom' : undefined"
      class="w-full m-0"
      style="max-width: 36rem"
      :modal="true"
      :dismissable-mask="true"
      :draggable="false"
      :close-on-escape="true"
      :closable="true"
    >
      <template #header>
        <div class="flex flex-row w-full align-items-center gap-2">
          <Avatar
            :image="project.contact?.photo ?? `https://contacts.zoho.com/file?ID=${project.contact?.zuid ?? ''}&fs=thumb`"
            size="large"
            shape="circle"
            style="background-color: rgba(0, 0, 0, 0.1)"
          />
          <p class="font-bold text-xl m-0 text-overflow-ellipsis white-space-nowrap">{{ project.contact?.fullName ?? project.contact?.firstName }}</p>
        </div>
      </template>
      <ContactSheet :project-contact="project.contact" />
      <div
        :style="{
          marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
        }"
      ></div>
    </Dialog>
  </template>

  <template v-else>
    <div class="card">
      <div>
        <div>
          <div>
            <Skeleton width="60%" />
          </div>
          <div class="mt-1 text-600">
            <Skeleton width="40%" />
          </div>
        </div>
      </div>
      <hr />
      <div class="grid">
        <div class="col">
          <Skeleton width="100%" height="8rem" />
        </div>
      </div>
      <hr />
      <div class="grid align-items-center justify-content-between">
        <div class="col">
          <Skeleton />
        </div>
        <div class="col">
          <div>
            <Skeleton />
          </div>
        </div>
        <div class="col">
          <div class="text-right">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<style lang="css" scoped>
  .layout-save-m {
    margin-bottom: calc(var(--sab) + 1rem) !important;
  }
  .layout-save {
    padding-bottom: calc(var(--sab) + 1rem) !important;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 0rem;
  }
  .ProseMirror {
    border: none;
    outline: none;
  }
  .ProseMirror:focus {
    border: none;
    outline: none;
  }
  .ProseMirror-focus {
    border: none;
    outline: none;
  }
  .gallery-item {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .review-buttons {
    display: grid;
    gap: 1rem;
  }
  .p-rating .p-rating-icon.pi-star-fill {
    color: orange !important;
  }
  .p-rating .p-rating-icon {
    font-size: 1.7rem !important;
  }
  .p-tabview {
    margin-bottom: 0;
  }
</style>

<style lang="scss" scoped>
  button.btn-camera .p-button-icon.pi.pi-camera {
    font-size: 1.5rem;
  }
  .title-tab {
    min-height: 40px;
  }
  .masonry-item-container {
    height: 200px;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
