import { defineStore } from 'pinia'
import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
import { IPhotoData } from '@/store'
import { IActivePunchList } from '@/interfaces/punch-list/punch-list.interface'
import { FinalizeReviewTypes } from '@/constants/types'
import { ITask } from '@/interfaces/punch-list/task/task.interface'
import { ICrew } from '@/interfaces/crew/Crew'
import api from '@/services/api'
import { IPhoto } from '@/interfaces/photo/photo.interface'
import { ETaskStatus } from '@/enums/punch-list/task/task.enum'
import { captureException } from '@sentry/vue'
import { i18n } from '@/i18n'
const { t } = i18n.global

interface CameraStore {
  projectId: string
  photoType: PhotoTypeEnum
  photoTypeId: string
  isActive: boolean
  isLoading: boolean
  description: string
  punchListId: string | null
  punchList: IActivePunchList | null
  punchListHeader: string
  allItemsComplete: boolean
  selectedItem: ITask | null
  crewsForReview: ICrew[]
  closeOutListExists: boolean
  currentStep: number
  isLoadingCloseOut: boolean
  isLoadingCrewsInCloseOut: boolean
}

const updateStatusTaskRequest = async (task: ITask, taskStatus: ETaskStatus) => {
  const payload = { status: taskStatus }
  await api.patch(`/Api/Projects/PunchLists/TaskLists/Tasks/${task.id}/UpdateStatus`, payload)
  task.status = taskStatus
  task.isCompleted = true
  return task
}

const updateStatusFinalize = async (projectId: string) => {
  const {
    data: { data: response },
  } = await api.get(`/Api/Project/${projectId}/FinalizePunchLists`)
  return response
}
const initialStateCloseOut = {
  projectId: '',
  photoType: PhotoTypeEnum.TASK_BEFORE,
  photoTypeId: '',
  description: '',
  punchListId: '',
  isActive: false,
  isLoading: false,
  punchList: null,
  punchListHeader: '',
  allItemsComplete: false,
  selectedItem: null,
  crewsForReview: [],
  closeOutListExists: true,
  isLoadingCloseOut: false,
  isLoadingCrewsInCloseOut: false,
  currentStep: 1,
}

export const useCloseOutStore = defineStore({
  id: 'PunchListCamera',
  state: (): CameraStore => initialStateCloseOut,
  getters: {},
  actions: {
    setProjectId(projectId: string) {
      this.projectId = projectId
    },
    setActiveState(payload: boolean) {
      this.isActive = payload
    },
    setLoadingState(payload: boolean) {
      this.isLoading = payload
    },
    clearLoadContentCloseout() {
      this.projectId = ''
      this.photoType = PhotoTypeEnum.TASK_BEFORE
      this.photoTypeId = ''
      this.description = ''
      this.punchListId = ''
      this.isActive = false
      this.isLoading = false
      this.isLoadingCloseOut = false
      this.isLoadingCrewsInCloseOut = false
      this.punchList = null
      this.punchListHeader = ''
      this.allItemsComplete = false
      this.selectedItem = null
      this.crewsForReview = []
      this.closeOutListExists = true
      this.currentStep = 1
    },
    clearLoadContentCrewsCloseOut() {
      this.isLoadingCloseOut = false
      this.isLoadingCrewsInCloseOut = false
      this.crewsForReview = []
    },
    startCamera(projectId: string, photoData: IPhotoData) {
      this.isActive = true
      this.isLoading = true
      this.projectId = projectId
      this.photoType = photoData?.type
      this.photoTypeId = photoData?.photoTypeId || ''
      this.punchListId = photoData?.punchListId || ''
    },
    async loadCrewContentInCloseout() {
      try {
        this.isLoadingCrewsInCloseOut = true
        const {
          data: { data: crews },
        } = await api.get(`/Api/Projects/CrewCompanies/Project/${this.projectId}/WithReview`)

        this.crewsForReview = crews as ICrew[]

        if (!this.crewsForReview) return

        this.crewsForReview.forEach((crew) => {
          const { reviews } = crew
          const hasWorkmanshipReview = reviews.some((review) => review.reviewType === FinalizeReviewTypes.WORKMANSHIP)
          const hasTimelinessReview = reviews.some((review) => review.reviewType === FinalizeReviewTypes.TIMELINESS)

          reviews.forEach((review) => {
            review.projectCrewCompanyId = crew.id
          })

          if (reviews.length === 0) {
            reviews.push(
              { projectCrewCompanyId: crew.id, rating: 0, reviewType: FinalizeReviewTypes.WORKMANSHIP },
              { projectCrewCompanyId: crew.id, rating: 0, reviewType: FinalizeReviewTypes.TIMELINESS }
            )
          } else if (!hasWorkmanshipReview || !hasTimelinessReview) {
            reviews.push({
              projectCrewCompanyId: crew.id,
              rating: 0,
              reviewType: hasWorkmanshipReview ? FinalizeReviewTypes.TIMELINESS : FinalizeReviewTypes.WORKMANSHIP,
            })
          }
        })
      } catch (error) {
        console.error('Error loading crew content in closeout:', error)
      } finally {
        this.isLoadingCrewsInCloseOut = false
      }
    },
    async loadContentCloseout() {
      try {
        this.closeOutListExists = true
        // console.log(this.punchListId)
        this.isLoadingCloseOut = true
        if (this.punchListId) {
          const {
            data: { data: response },
          } = await api.get(`/Api/Projects/PunchLists/${this.punchListId}`)

          this.punchList = response
          this.punchListHeader = response.name
          this.allItemsComplete = response.percentageCompleted === 100
          this.currentStep = 1

          for (const taskList of response.taskLists) {
            for (const task of taskList.tasks) {
              this.selectedItem = task
              break
            }
            break
          }
        } else {
          const {
            data: { data: response },
          } = await api.get(`/Api/Project/${this.projectId}/FinalizePunchLists`)
          // console.log('current Step')

          this.currentStep = 1
          this.punchList = response
          this.punchListHeader = response.name ?? '' + ' Close Out List'
          this.allItemsComplete = response.percentageCompleted === 100
          // if (response.percentageCompleted === 100) this.currentStep = 2

          for (const taskList of response.taskLists) {
            for (const task of taskList.tasks) {
              this.selectedItem = task
              break
            }
            break
          }
          await this.loadCrewContentInCloseout()
        }
      } catch (err) {
        captureException(err)
        console.log('err', err)

        this.punchListHeader = 'List does not exist'
        this.closeOutListExists = false
      } finally {
        this.isLoadingCloseOut = false
      }
    },

    updatePhotosForTask(newPhotos: IPhoto[], selectedItem?: ITask) {
      const taskList = this.punchList?.taskLists.find((tl) => tl.id === selectedItem?.taskListId)
      if (!taskList) return
      const task = taskList.tasks.find((t) => t.id === selectedItem?.id)
      if (!task) return
      if (!task.photos) task.photos = []

      task.photos.push(...newPhotos)
    },
    async updateStatusTask(task: ITask, taskStatus: ETaskStatus) {
      await updateStatusTaskRequest(task, taskStatus)
      if (!this.punchListId) {
        const response = await updateStatusFinalize(this.projectId)
        this.allItemsComplete = response.percentageCompleted === 100
      }
    },

    setSelectedItem(item: ITask) {
      this.selectedItem = item
    },
    nextStep() {
      const scrollContainer = document.getElementById('scroll-container')
      if (scrollContainer) scrollContainer.scrollTop = 0
      this.currentStep = 2
      this.punchListHeader = t('finalizePunchLists.crewReview')
    },
    previousStep() {
      const scrollContainer = document.getElementById('scroll-container')
      if (scrollContainer) scrollContainer.scrollTop = 0
      this.currentStep = 1
      if (this.punchList && this.punchList.name) {
        this.punchListHeader = this.punchList.name ?? '' + ' Close Out List'
      }
    },
  },
})
