<script lang="ts" setup>
  import { ref, watch, onMounted, toRaw, computed } from 'vue'
  import { i18n } from '@/i18n'
  import { captureException } from '@sentry/vue'
  import api from '@/services/api'
  import 'v3-infinite-loading/lib/style.css'
  import { DataTablePageEvent } from 'primevue/datatable'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import { FilterMatchMode, FilterMatchModeOptions } from 'primevue/api'

  interface IPagination {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }

  interface IProject {
    id: string
    address: string
    crewManager: string
    projectManager: string
    projectNumber: string | null
    projectStage: string
    salesManager: string
    updatedAt: string
  }

  interface IStage {
    id: number
    name: string
  }

  type IProjectsResponse = {
    items: IProject[]
    pagination: IPagination
  }

  type IStageProyect = IStage[]

  type matchModeType = 'STARTS_WITH' | 'CONTAINS' | 'NOT_CONTAINS' | 'ENDS_WITH' | 'EQUALS' | 'NOT_EQUALS'

  type filterValue = {
    value: string | number | null | Date | string[] | number[] | Date[]
    matchMode: matchModeType | string
  }

  type specialFilterValue = {
    operator: string
    constraints: filterValue[]
  }

  type filterType = {
    [key: string]: filterValue | specialFilterValue
  }

  type sortType = {
    field: string
    order: number
  }

  interface IFilterParams {
    skip?: number | null
    take?: number | null
    sort?: sortType
    filters?: filterType
  }

  interface Stage {
    id: number
    name: string
  }

  const { t } = i18n.global
  const takeValue = ref(10)
  const skipValue = ref(0)
  const loading = ref(false)
  const refreshTrigger = ref(0)
  const projectsResponse = ref<IProjectsResponse>({} as IProjectsResponse)
  const listStageResponse = ref<IStageProyect>([])
  const loadingProjects = ref(false)
  const loadingListStage = ref(false)
  const sort = ref<sortType | null>(null)

  const matchModeMapping: Record<string, keyof FilterMatchModeOptions | any> = {
    startsWith: 'STARTS_WITH',
    contains: 'CONTAINS',
    notContains: 'NOT_CONTAINS',
    endsWith: 'ENDS_WITH',
    equals: 'EQUALS',
    notEquals: 'NOT_EQUALS',
    between: 'BETWEEN',
    dateIs: 'EQUALS',
    dateIsNot: 'NOT_EQUALS',
    dateBefore: 'LOWER_THAN',
    dateAfter: 'GREATER_THAN',
    default: 'EQUALS',
  }

  interface Filter {
    value: any
    matchMode: keyof FilterMatchModeOptions
  }

  interface Filters {
    [key: string]: Filter
  }

  const filters = ref<filterType>({
    ProjectNumber: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    CustomerName: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    SalesManager: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    ProjectManager: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    CrewManager: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    Address: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    ProjectStage: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    UpdatedAt: { value: null, matchMode: FilterMatchMode.DATE_IS },
  })

  const convertFormat = (filters: Filters | Record<string, any>) => {
    const rawFilters = toRaw(filters)

    return Object.keys(rawFilters)
      .map((key) => {
        const filter = rawFilters[key]
        if (!filter || filter.value === '' || (Array.isArray(filter.value) && filter.value.length === 0)) {
          return null
        }
        if (key === 'ProjectStage' && Array.isArray(filter.value)) {
          const ids = filter.value.map((item: any) => item.id)
          if (ids.length === 0) return null
          return {
            field: 'ProjectStage',
            ArrayValue: ids,
            MatchMode: 10,
          }
        }
        return {
          field: key,
          value: filter.value,
          matchMode: matchModeMapping[filter.matchMode],
        }
      })
      .filter((item) => item && (item.value !== '' || item.ArrayValue))
  }

  const fetchProjects = async (filterParams?: IFilterParams) => {
    try {
      loading.value = true
      loadingProjects.value = true
      const params: string[] = []

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      params.push(`timeZone=${timezone}`)

      if (filterParams?.skip !== undefined) {
        params.push(`skip=${filterParams.skip}`)
      }
      if (filterParams?.take !== undefined) {
        params.push(`take=${filterParams.take}`)
      }

      const payload = {
        filterProperties: convertFormat(filterParams?.filters ?? {}).filter((item) => item && (item.value !== null || item.ArrayValue)),
        sortFields: filterParams?.sort
          ? [
              {
                sortField: filterParams.sort.field,
                sortOrder: filterParams.sort.order > 0 ? 'ASC' : 'DESC',
              },
            ]
          : undefined,
      }

      const response = await api.post(`/Api/v2/Projects/Filter?${params.join('&')}`, payload)
      projectsResponse.value = {
        items: response.data.data.items || [],
        pagination: response.data.data.pagination,
      }
    } catch (err) {
      captureException(err)
      throw err
    } finally {
      loading.value = false
      loadingProjects.value = false
    }
  }

  const fetchListStage = async () => {
    try {
      loadingListStage.value = true
      const response = await api.get('/Api/Project/Stages')
      listStageResponse.value = response.data.data.map((item: { name: string }) => ({
        ...item,
        name: item.name
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/^\w/, (c) => c.toLocaleUpperCase()),
      }))
    } catch (err) {
      captureException(err)
      throw err
    } finally {
      loadingListStage.value = false
    }
  }

  async function updateProjects() {
    await fetchProjects({
      take: takeValue.value,
      skip: skipValue.value,
      sort: sort.value === null ? undefined : sort.value,
      filters: filters.value,
    })
  }

  watch(
    [filters, refreshTrigger],
    () => {
      skipValue.value = 0
      updateProjects()
    },
    { deep: false }
  )

  function refreshTable() {
    refreshTrigger.value++
  }

  watch([takeValue, skipValue], () => {
    updateProjects()
  })

  const onPage = (event: DataTablePageEvent) => {
    loading.value = true
    skipValue.value = event.first
    takeValue.value = event.rows
    updateProjects().finally(() => {
      loading.value = false
    })
  }

  onMounted(async () => {
    fetchListStage()
    await fetchProjects({
      take: takeValue.value,
      skip: skipValue.value,
    })
  })

  const statusColors: Record<string, string> = {
    WILL_CALLED_ORDERED: '#f39c12',
    READY_FOR_PRODUCTION: '#2ecc71',
    PROJECT_SCHEDULED: '#3498db',
    IN_PRODUCTION: '#e67e22',
    COMPLETE: '#27ae60',
    NONE: '#7f8c8d',
    READY_TO_VERIFY: '#8e44ad',
    CONTRACT_VERIFIED: '#1abc9c',
    SCHEDULE_PRE_WALK_THROUGH: '#f1c40f',
    PM_APPROVED: '#6ac44f',
    PO_REJECTED_BY_PM: '#e74c3c',
    CORRECTED_PO_AWAITING_APPROVAL: '#f39c12',
    SECOND_PO_REJECTED: '#c0392b',
    ALL: '#34495e',
    MEASURE_SCHEDULED: '#d6ca6b',
    MEASURE: '#e6cb02',
  }

  function getSeverity(status: string): string {
    return statusColors[status]
  }

  const uniqueItems = computed(() => {
    const items = projectsResponse.value.items || []
    return items.map((item, index) => ({
      ...item,
      idField: `${item.projectNumber}-${index}`,
    }))
  })
</script>

<template>
  <div class="flex flex-column">
    <header class="mt-3">
      <div class="flex justify-content-between align-items-center">
        <h1 class="mb-0">{{ t('projectsIndex.projects') }} web</h1>
      </div>
    </header>
    <div class="table-container">
      <DataTable
        ref="dt"
        v-model:rows="takeValue"
        v-model:filters="filters"
        class="w-full pt-3"
        lazy
        :first="skipValue"
        :value="uniqueItems"
        size="large"
        :paginator="projectsResponse?.pagination?.rowCount > (projectsResponse?.items ?? []).length"
        :rows-per-page-options="[5, 10, 20, 50]"
        data-key="idField"
        :total-records="projectsResponse?.pagination?.rowCount ?? 10"
        filter-display="row"
        scrollable
        scroll-height="flex"
        @page="onPage($event)"
        @refresh="refreshTable"
      >
        <Column
          field="ProjectNumber"
          :header="t('projectsIndex.table.headers.poNumber')"
          header-style="position: sticky; top: 0; z-index: 1; background: white; left: 0; width: 150px; min-width: 150px; border-top-left-radius: 10px"
          body-style="position: sticky; left: 0; background: white; width: 150px; min-width: 150px; z-index: 0;"
          style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white; position: sticky; top: 0; left: 0; z-index: 1"
        >
          <template #body="{ data }">
            <router-link :to="`/projects/${data?.id}/media`">{{ data?.projectNumber ?? 'No poNumber' }}</router-link>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText ref="ProjectNumber" v-model="filterModel.value" type="text" placeholder="Search by Project Number" @input="filterCallback()" />
          </template>
        </Column>

        <Column
          field="CustomerName"
          :header="t('projectsIndex.table.headers.customerName')"
          style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white"
        >
          <template #body="{ data }">
            {{ data.customerName }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" placeholder="Search by Customer Name" @input="filterCallback()" />
          </template>
        </Column>
        <Column
          field="SalesManager"
          :header="t('projectsIndex.table.headers.salesPerson')"
          style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white"
        >
          <template #body="{ data }">
            {{ data.salesManager }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" placeholder="Search by Sales Person" @input="filterCallback()" />
          </template>
        </Column>

        <Column
          field="ProjectManager"
          :header="t('projectsIndex.table.headers.projectManager')"
          style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white"
        >
          <template #body="{ data }">
            {{ data.projectManager }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" placeholder="Search by ProjectManager" @input="filterCallback()" />
          </template>
        </Column>

        <Column
          field="CrewManager"
          :header="t('projectsIndex.table.headers.crewManager')"
          style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white"
        >
          <template #body="{ data }">
            {{ data.crewManager }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" placeholder="Search by Crew Manager" @input="filterCallback()" />
          </template>
        </Column>

        <Column field="Address" :header="t('projectsIndex.table.headers.address')" style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white">
          <template #body="{ data }">
            {{ data.address }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" placeholder="Search by Address" @input="filterCallback()" />
          </template>
        </Column>
        <Column
          field="ProjectStage"
          :header="t('projectsIndex.table.headers.stage')"
          :show-filter-menu="false"
          body-style="padding-left:4rem"
          style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white"
        >
          <template #body="{ data }">
            <Tag
              :value="data.projectStage.replace(/_/g, ' ')"
              :style="{
                minWidth: '144px',
                height: '25px',
                backgroundColor: getSeverity(data.projectStage),
              }"
              :severity="getSeverity(data.projectStage)"
            />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              v-model="filterModel.value"
              :options="listStageResponse"
              option-label="name"
              placeholder="Select Stage"
              style="min-width: 14rem"
              :max-selected-labels="1"
              @change="filterCallback()"
            >
              <template #option="slotProps">
                <div class="flex items-center gap-2">
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column
          field="UpdatedAt"
          :header="t('projectsIndex.table.headers.lastModified')"
          data-type="date"
          style="min-width: 20rem; background-color: white"
          header-style="background: white; border-top-right-radius: 10px"
          :show-filter-operator="false"
          :show-apply-button="false"
          :show-add-button="false"
        >
          <template #body="{ data }">
            {{ useDateFormatUtil(data.updatedAt, 'MMM D, YYYY h:mm A') }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Calendar v-model="filterModel.value" date-format="mm/dd/yy" class="p-column-filter" placeholder="MM/DD/YYYY" mask="99/99/9999" @date-select="filterCallback()" />
          </template>
        </Column>
        <template #empty>
          <div v-if="loading" class="text-center p-4">
            <span>Loading data...</span>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<style scoped>
  .table-container {
    flex: 1;
    overflow-y: auto;
  }

  .p-datatable-thead th {
    position: sticky;
    top: 0;
    z-index: 500;
    background-color: white;
    border-bottom: 1px solid #ddd;
  }

  .p-datatable {
    table-layout: auto;
    width: 100%;
  }

  .p-datatable-tbody td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
