const imageUrlToBase64 = (imageUrl: string, callback: (base64String: string) => void) => {
  const img = new Image()
  img.crossOrigin = 'anonymous'
  img.onload = function () {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height

    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.drawImage(img, 0, 0, img.width, img.height)
      const base64String = canvas.toDataURL('image/jpeg')
      callback(base64String)
    }
  }

  img.src = imageUrl
}

export default imageUrlToBase64

export const imgUrlToBlobBase64 = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )
