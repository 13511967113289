import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite'
import { IQueuePhoto } from './tables'

export const initializeWebEnvironment = async () => {
  const jeepSqliteEl = document.createElement('jeep-sqlite')
  document.body.appendChild(jeepSqliteEl)
  await customElements.whenDefined('jeep-sqlite')
  const sqlite = new SQLiteConnection(CapacitorSQLite)
  await sqlite.initWebStore()
}

let dbConnection = null as {
  db: SQLiteDBConnection
  sqlite: SQLiteConnection
} | null

export const initializeDatabase = async () => {
  if (dbConnection !== null) return dbConnection

  const sqlite = new SQLiteConnection(CapacitorSQLite)
  const ret = await sqlite.checkConnectionsConsistency()
  const isConn = (await sqlite.isConnection('db_vite', false)).result

  let db = null
  if (ret.result && isConn) db = await sqlite.retrieveConnection('db_vite', false)
  else db = await sqlite.createConnection('db_vite', false, 'no-encryption', 1, false)

  await db.open()
  // console.log(`db: db_vite opened`)
  dbConnection = { db, sqlite }
  return { db, sqlite }
}

export const updateTableIQueuePhoto = async (sqlite: SQLiteConnection) => {
  const isDBExist = await sqlite.isDatabase('db_vite')
  if (isDBExist.result === true) {
    console.log(`db: db_vite exist`)
    await sqlite.addUpgradeStatement('db_vite', 1, [
      `CREATE TABLE IF NOT EXISTS NEW_QUEUE_PHOTO_TABLE (
        id TEXT PRIMARY KEY,
        projectId TEXT NOT NULL,
        latitude TEXT NOT NULL,
        longitude TEXT NOT NULL,
        base64 TEXT NOT NULL,
        base64Content TEXT NOT NULL,
        photoType TEXT NOT NULL,
        photoTypeId TEXT,
         site TEXT
      );`,
      `INSERT INTO NEW_QUEUE_PHOTO_TABLE
        SELECT * FROM QUEUE_PHOTO_TABLE;`,
      `DROP TABLE QUEUE_PHOTO_TABLE;`,
      `ALTER TABLE NEW_QUEUE_PHOTO_TABLE RENAME TO QUEUE_PHOTO_TABLE;`,
      `CREATE TABLE IF NOT EXISTS NEW_QUEUE_PHOTO_TABLEV2 (
        id TEXT PRIMARY KEY,
        projectId TEXT,
        latitude TEXT NOT NULL,
        longitude TEXT NOT NULL,
        photoType TEXT NOT NULL,
        photoTypeId TEXT,
        date TEXT NOT NULL,
        file BLOB NOT NULL,
        status TEXT NOT NULL,
        uploadDate TEXT,
        errorMessage TEXT,
        retries INTEGER NOT NULL,
         site TEXT
      );`,
    ])
  }
}

export const updateTableINewQueuePhoto = async (sqlite: SQLiteConnection) => {
  const isDBExist = await sqlite.isDatabase('db_vite')
  if (isDBExist.result === true) {
    console.log(`db: db_vite exist`)
    await sqlite.addUpgradeStatement('db_vite', 1, [`DROP TABLE NEW_QUEUE_PHOTO_TABLEV2;`])
  }
}

export const createTableIQueuePhoto = async (db: SQLiteDBConnection) => {
  const createTableSQL = `
  CREATE TABLE IF NOT EXISTS QUEUE_PHOTO_TABLE (
    id TEXT PRIMARY KEY,
    projectId TEXT NOT NULL,
    latitude TEXT NOT NULL,
    longitude TEXT NOT NULL,
    base64 TEXT NOT NULL,
    base64Content TEXT NOT NULL,
    photoType TEXT NOT NULL,
    photoTypeId TEXT,
      site TEXT
  )
`
  const res = await db.execute(createTableSQL)
  // console.log(`res: ${JSON.stringify(res)}`)
}

export const checkIsExistTableIQueuePhoto = async (db: SQLiteDBConnection) => {
  const queryCheck = `SELECT name FROM sqlite_master WHERE type='table' AND name='QUEUE_PHOTO_TABLE';`
  const res = await db.query(queryCheck)
  return (res.values ?? []).length > 0
}

export const insertDataIntoTableIQueuePhoto = async (db: SQLiteDBConnection, photo: IQueuePhoto) => {
  const queryInsert = `INSERT INTO QUEUE_PHOTO_TABLE VALUES (?,?,?,?,?,?,?,?) `
  const values = [photo.id, photo.projectId, photo.latitude, photo.longitude, photo.base64, photo.base64Content, photo.photoType, photo.photoTypeId]

  await db.query(queryInsert, values)
}

export const closeDatabaseAndConnection = async (db: SQLiteDBConnection, sqlite: SQLiteConnection) => {
  // console.log('closeDatabaseAndConnection')
  const isOpen = await db.isDBOpen()
  if (isOpen?.result) {
    await db.close()
    await sqlite.closeConnection('db_vite', false)
  }
  dbConnection = null
}
