export interface Permissions {
  [key: string]: boolean | Permissions
}

import { useUserStore } from '@/store'
import { storeToRefs } from 'pinia'

export const obtenerValor = <T extends Permissions>(objeto: T, clave: string) => {
  if (Object.prototype.hasOwnProperty.call(objeto, clave)) {
    // console.log('objeto[clave]', objeto[clave])
    return objeto[clave]
  }

  for (const key in objeto) {
    if (typeof objeto[key] === 'object') {
      const valor = obtenerValor(objeto[key] as Permissions, clave) as Permissions

      if (valor !== undefined) {
        return valor
      }
    }
  }

  return undefined
}

export const getValueKey = (obj: Record<string, boolean>, key: string) => {
  // console.log('obj', obj, key)
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key]
  }
  return undefined
}

export const getPermission = (obj: Permissions, key: string, nodo: string) => {
  const nodoPermission = obtenerValor(obj, nodo)
  // console.log('obj', key, nodo, nodoPermission)
  if (nodoPermission && typeof nodoPermission !== 'boolean') {
    const permission = getValueKey(nodoPermission as Record<string, boolean>, key)
    if (permission !== undefined && permission !== false) {
      // console.log('permission', permission)
      return true
    }
  }
  // console.log('permission', false)
  return false
}

export const getPermissionsUser = () => {
  const userStore = useUserStore()
  const { permissions } = storeToRefs(userStore)
  return permissions.value
}

export const getPermissionsByRole = (role: string | string[]) => {
  const userStore = useUserStore()
  const { profile } = storeToRefs(userStore)

  if (Array.isArray(role)) {
    return role.some((r) => r === profile.value)
  }

  return profile.value === role
}

export const getPermissionsByKeyName = (key: string, nodo: string) => {
  const permissions = getPermissionsUser()
  return getPermission(permissions, key, nodo)
}

export const example = {
  permissions: {
    menuPermissions: {
      home: true,
      projects: true,
      crewDirectory: true,
      directory: true,
      settings: true,
    },
    projectsPermissions: {
      buttons: {
        contact: true,
        directions: true,
        checkIn: true,
        closeOut: true,
      },
      sections: {
        projectsMediaPermissions: {
          show: true,
          taskAfter: true,
          taskBefore: true,
          capturePhoto: true,
        },
        projectsPunchListsPermissions: {
          show: true,
          createPunchList: true,
          complete: true,
          createTask: true,
        },
        workContactsPermissions: {
          show: true,
        },
        projectsCrewsPermissions: {
          show: true,
          assign: true,
        },
        projectsNotePermissions: {
          show: true,
          delete: true,
          edit: true,
          create: true,
        },

        projectsLaborBillPermissions: {
          show: true,
          add: true,
        },
        projectsProblemsPermissions: {
          delete: true,
          create: true,
          show: true,
          closed: true,
        },
      },
    },
    directoryPermissions: {
      directorySectionsPermissions: {
        directoryAllPermissions: {
          show: true,
        },
        directorySalesPermissions: {
          show: true,
        },
        directoryOfficePermissions: {
          show: true,
        },
        directoryProductionPermissions: {
          show: true,
        },
      },
    },
    crewDirectoryPermissions: {
      show: true,
    },
    settingsPermissions: {
      integrations: true,
    },
  },
}
