export const menuComponentEnUS = {
  home: 'Home',
  projects: 'Projects',
  leads: 'Leads',
  schedule: 'Schedule',
  crewDirectory: 'Crew Directory',
  company: 'Company',
  directory: 'Directory',
  settings: 'Settings',
  profile: 'Profile',
  logout: 'Logout',
  photoqueue: 'Photo Q',
  todayLeads: 'Today Leads',
  nearByProjects: 'Nearby Projects',
  tagsData: 'Tags',
  mapsSidebar: 'Maps',
  calendar: 'Calendar',
}

export const menuComponentEsMX = {
  home: 'Inicio',
  calendar: 'Calendario',
  projects: 'Proyectos',
  leads: 'Leads',
  schedule: 'Calendario',
  crewDirectory: 'Directorio de la tripulación',
  company: 'Compañía',
  directory: 'Directorio',
  settings: 'Configuraciones',
  profile: 'Perfil',
  logout: 'Cerrar sesión',
  photoqueue: 'Foto Q',
  todayLeads: 'Leads de hoy',
  nearByProjects: 'Proyectos cercanos',
  tagsData: 'Etiquetas',
  mapsSidebar: 'Mapa',
}
