import { AccountStatus, useAccountStore } from '@/store'
import { storeToRefs } from 'pinia'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const pendingPasswordGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const accountStore = useAccountStore()
  const { accountStatus } = storeToRefs(accountStore)
  if (accountStatus.value !== AccountStatus.PENDING_PASSWORD) return next('/')
  next()
}
