<script async setup lang="ts">
  import { onMounted, onUnmounted, ref } from 'vue'
  import {
    useCloseOutStore,

    // useBreakpointsStore,
    useProjectStore,
  } from '@/store'
  import { Device } from '@capacitor/device'
  import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera'
  import api from '@/services/api'
  import Button from 'primevue/button'
  import { storeToRefs } from 'pinia'
  import { useToast } from 'primevue/usetoast'
  import ListHeader from './SideBar/HeaderComponent.vue'
  import CloseOutSections from './SideBar/CloseOutSections.vue'
  import CameraComponent from './Camera/CameraComponent.vue'
  import { ITask } from '@/interfaces/punch-list/task/task.interface'
  import { ITaskList } from '@/interfaces/punch-list/task-list/task-list.interface'
  import { IQueuePhoto } from '@/db/tables'
  import SelectPhotoIndex from './SelectPhoto/SelectPhotoIndex.vue'
  import { IPhoto } from '@/interfaces/photo/photo.interface'
  import { ETaskStatus } from '@/enums/punch-list/task/task.enum'
  import { Capacitor } from '@capacitor/core'
  import { useEventBus } from '@vueuse/core'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import { toBase64 } from '@/utils/base64'
  import { uploadPhotoType } from '@/db/tables'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import uuid from 'uuid-random'
  import { uploadPhotosBlob } from '@/utils/uploadPhotoBlob'
  import { base64ToBlob } from '@/utils/base64Toblob'
  const assignFirtsTask = (taskList: ITaskList[]) => {
    if (taskList.length > 0) {
      return taskList[0].tasks[0]
    }
    return null
  }

  // const breakpointsStore = useBreakpointsStore()
  // const { md } = storeToRefs(breakpointsStore)
  const punchListCameraStore = useCloseOutStore()
  const { updateStatusTask, updatePhotosForTask, setActiveState, loadContentCloseout, clearLoadContentCloseout } = punchListCameraStore
  const { projectId, photoType, punchList, isLoadingCloseOut, isLoadingCrewsInCloseOut } = storeToRefs(punchListCameraStore)
  const { fetchProjectById, project } = useProjectStore()

  const activeCamera = ref(false)
  const toast = useToast()

  // const listIsVisible = ref(true)
  const getPhotos = ref(false)
  const selectedItem = ref<null | ITask>(null)

  const closeProject = async () => {
    try {
      await api.patch(`/Api/Project/${projectId.value}/CloseOut`, {
        isClosed: true,
      })
      await fetchProjectById(projectId.value)
      setActiveState(false)
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Successfully closed out project',
        life: 3000,
      })
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error closing out project',
        life: 3000,
      })
    }
  }

  const uploadPhotosItem = async (photos: IQueuePhoto[], task: ITask) => {
    const imagesResponse = await uploadPhotosBlob(
      photos.map((photo) => {
        const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
        return { ...photo, file: blob }
      })
    )
    updatePhotosForTask(imagesResponse, task)
    return imagesResponse
  }

  const uploadAndUpdateTask = async (photos: IQueuePhoto[], task: ITask) => {
    if (photos.length === 0) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: `No picutres taken`,
        life: 3000,
      })
      return
    }
    await uploadPhotosItem(photos, task)
    await updateStatusTask(task, ETaskStatus.COMPLETED)
    await loadContentCloseout()
  }

  const bus = useEventBus('uploadimagesgallery')

  const getPhotoData = async (file: File, uploadPhotoType: uploadPhotoType) => {
    const base64: any = await toBase64(file)
    const { latitude, longitude } = await fetchCurrentLocationOnce()
    const generateID = uuid()

    return {
      id: generateID,
      base64: base64,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      photoType: photoType.value,
      photoTypeId: selectedItem?.value?.id,
      entityType: 'Project',
      projectId: project?.id,
      base64Content: `${base64.split('base64,')[1]}`,
      uploadPhotoType,
    }
  }
  const inputFilesRef = ref()
  const payload = {
    status: ETaskStatus.COMPLETED,
  }

  const handleChangeFiles = async (event: any) => {
    const files = event.target.files as File[]
    const validImageFiles = Array.from(files).filter((file) => ['image/png', 'image/jpeg'].includes(file.type))
    const uploadPhotoType = await devicePhotoTag('UPLOAD')

    const promises = validImageFiles.map((file) => getPhotoData(file, uploadPhotoType))
    const data = (await Promise.all(promises)) as IQueuePhoto[]

    await uploadPhotosItem(data, selectedItem.value as ITask)
    await updateStatusTask(selectedItem.value as ITask, ETaskStatus.COMPLETED)
    if (selectedItem.value) {
      selectedItem.value.status = payload.status
    }

    await loadContentCloseout()
  }
  const handlePhotoCameraIOS = async (image: Photo) => {
    const { latitude, longitude } = await fetchCurrentLocationOnce()
    const generateID = uuid()
    const formatB64 = `data:image/${image.format};base64,${image?.base64String?.toString()}`
    const uploadPhotoType = await devicePhotoTag('UPLOAD')

    return {
      id: generateID,
      base64: formatB64 as any,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      photoType: photoType.value,
      photoTypeId: selectedItem?.value?.id,
      entityType: 'Project',
      projectId: project?.id,
      base64Content: `${image.base64String}`,
      uploadPhotoType,
    }
  }

  const handleGalleryIOS = async () => {
    const image = await Camera.getPhoto({
      quality: 60,
      allowEditing: false,
      source: CameraSource.Photos,
      resultType: CameraResultType.Base64,
    })

    const data = await handlePhotoCameraIOS(image)
    await uploadPhotosItem([data as IQueuePhoto], selectedItem.value as ITask)
    await updateStatusTask(selectedItem.value as ITask, ETaskStatus.COMPLETED)
    await loadContentCloseout()
    if (selectedItem.value) {
      selectedItem.value.status = payload.status
    }
  }

  bus.on(async () => {
    const info = await Device.getInfo()
    if (info.platform === 'ios') {
      handleGalleryIOS()
    } else if (info.platform === 'web' || info.platform === 'android') {
      inputFilesRef.value?.click()
    }
  })

  const assingPhotosForTask = async (photos: IPhoto[], task: ITask) => {
    try {
      const photosID = photos.map((photo) => photo.id)
      await api.post('/Api/Projects/ConvertPhoto', {
        photoIds: photosID,
        taskId: task.id,
      })
      updatePhotosForTask(photos, task)
      await updateStatusTask(task, ETaskStatus.COMPLETED)
    } catch (error) {
      console.log(error)
    }
  }

  onMounted(async () => {
    await loadContentCloseout()
    selectedItem.value = assignFirtsTask(punchList.value?.taskLists ?? [])
  })
  onUnmounted(() => {
    clearLoadContentCloseout()
  })
</script>

<template>
  <CameraComponent
    v-if="activeCamera"
    :project-id="projectId"
    :photo-type="photoType"
    :photo-type-id="selectedItem?.id ?? ''"
    @close-camera="
      (images) => {
        if (selectedItem) uploadAndUpdateTask(images, selectedItem)
        activeCamera = false
      }
    "
  />
  <input ref="inputFilesRef" style="display: none" type="file" accept="image/jpeg, image/png" multiple @change="handleChangeFiles" />
  <div class="relative h-full" :class="activeCamera && 'hidden'">
    <SelectPhotoIndex
      v-model:visible="getPhotos"
      :project-id="projectId"
      @submit-photos="
        (photos) => {
          if (selectedItem) {
            assingPhotosForTask(photos, selectedItem)
          }
        }
      "
    />

    <!-- desktop -->
    <div class="flex flex-column w-full h-full bg-white">
      <div class="p-sidebar-header" :class="Capacitor.getPlatform() === 'ios' && 'pt-6'" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
        <div class="p-sidebar-header-content w-full flex justify-content-between align-items-center align-content-center p-3">
          <ListHeader />
          <Button icon="pi pi-times" severity="secondary" @click="setActiveState(false)" />
        </div>
      </div>
      <div class="p-sidebar-content flex flex-grow-1 flex-column justify-content-center align-items-center" style="height: 100%; padding: 0px">
        <section v-if="isLoadingCloseOut || isLoadingCrewsInCloseOut" class="flex justify-content-center align-items-center mt-4" style="height: 20px">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem; color: black"></i>
        </section>

        <CloseOutSections
          :selected-item="selectedItem"
          @take-picture="
            () => {
              activeCamera = true
            }
          "
          @select-photo="
            () => {
              getPhotos = true
            }
          "
          @reviews-submitted="
            () => {
              closeProject()
            }
          "
          @set-selected-item="
            (item) => {
              selectedItem = item
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  #controls {
    background: rgb(0 0 0 / 41%);
  }

  .transparent {
    background: transparent !important;
  }

  #punchlist-camera-container {
    background: transparent !important;
  }

  #punchlist-camera-container > .p-sidebar-header {
    display: none;
    padding: 0;
  }
  #punchlist-camera-container > .p-sidebar-content {
    padding: 0;
    overflow: hidden;
    height: 100%;
  }

  #list-sidebar > .p-sidebar-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .p-sidebar-header-content {
    min-height: 5rem;
    max-height: 5rem;
  }

  #list-sidebar > .p-sidebar-header > .p-sidebar-header-content {
    min-height: 5rem;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  #list-sidebar > .p-sidebar-content {
    // overflow: auto;
    padding: 0px;
    height: 100%;
    background-color: var(--surface-ground);

    h4 {
      margin-top: 2rem;

      &:first-child {
        margin-top: 1rem;
      }
    }
  }

  .punch-list-container {
    > h4 {
      margin-top: 2rem;

      &:first-child {
        margin-top: 1rem;
      }
    }
  }

  #camera-close-control {
    > .pi {
      font-size: 1.25rem;
      color: var(--surface-800);
    }
  }
</style>

<style scoped lang="scss">
  :deep(#video) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00000069;
    height: 112px;
  }
  .camera {
    width: 100%;
    height: 100%;
  }
  .content-camera-preview {
    height: 100%;
    background: transparent !important;
  }
</style>
