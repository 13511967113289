export const TypesStatus = {
  'picture-comment-lead': {
    label: `"Lead picture comment"`,
    icon: 'comment',
  },
  checkin: {
    label: `"Checkin"`,
    icon: 'check',
  },
  'picture-comment': {
    label: `"Project picture comment"`,
    icon: 'comment',
  },
  'create-problem': {
    label: `"Create problem"`,
    icon: 'x',
  },
  'problem-status-change': {
    label: `"Problem status change"`,
    icon: 'bell',
  },
  'assign-crew-to-project': {
    label: `"Assign crew to project"`,
    icon: 'user',
  },
  'create-punch-list': {
    label: `"Create punch list"`,
    icon: 'plus',
  },
  'complete-punch-list': {
    label: `"Complete punch list"`,
    icon: 'list',
  },
  'complete-final-punch-list': {
    label: ` "Final Punch List"`,
    icon: 'list',
  },
  'change-status-from_ready-to-in-production': {
    label: ` "Ready" to "In Production",`,
    icon: 'bell',
  },
  'change-status-from_ready-to-pending-review': {
    label: `"Ready" to "Pending Review"`,
    icon: 'bell',
  },
  'change-status-from-pending-review-to-pending-punch-list': {
    label: `"Pending Review" to "Pending Punch List"`,
    icon: 'bell',
  },
  'change-status-from-pending-punch-list-to-completed': {
    label: `"Pending Punch List" to "Completed"`,
    icon: 'bell',
  },
  'review-average': {
    label: `"Review average"`,
    icon: 'star',
  },
  extenal: {
    label: `"External"`,
    icon: 'tree',
  },
  'extenal-checkin': {
    label: `"External checkin"`,
    icon: 'check',
  },
  'extenal-closeout': {
    label: `"External closeout"`,
    icon: 'lock',
  },
  'extenal-review': {
    label: `"External review"`,
    icon: 'star',
  },
  'extenal-problem-update': {
    label: `"External problem update"`,
    icon: 'bell',
  },
  'external-problem': {
    label: `"External problem"`,
    icon: 'x',
  },
  'extenal-laborbill': {
    label: `"External labor bill"`,
    icon: 'money-bill',
  },
  'extenal-crew-assign': {
    label: `"External crew assign"`,
    icon: 'user',
  },
  'external-punchlist': {
    label: `"External punch list"`,
    icon: 'list',
  },
  'user-mention-note-lead-reply': {
    label: `"User mention note lead reply"`,

    icon: 'comment',
  },
  'user-mention-note': {
    label: `"User mention note"`,
    icon: 'user',
  },
  'photo-mention-lead': {
    label: `"Photo mention lead"`,
    icon: 'user',
  },
  'user-mention': {
    label: `"User mention"`,
    icon: 'user',
  },
  'user-mention-lead': {
    label: `"User mention lead"`,
    icon: 'user',
  },
  'user-mention-note-lead': {
    label: `"User mention note lead"`,
    icon: 'user',
  },
  'external-crew-assign': {
    label: `"External crew assign"`,
    icon: 'user',
  },
}
