<script setup lang="ts">
  import { IQueuePhoto, PhotoTypeEnum } from '@/db/tables'
  import { useEventBus } from '@vueuse/core'
  import { onUnmounted, ref, watch } from 'vue'
  import CameraComponent from './CameraComponent.vue'

  interface propsCamera {
    projectId?: string
    photoTypeId?: string
    photoType?: PhotoTypeEnum
  }

  interface CameraOptions {
    hiddenCloseButton?: boolean
    hiddenControls?: boolean
    photos?: IQueuePhoto[]
  }

  interface IAccionsCamera {
    takePicture?: (photo: IQueuePhoto) => void
    closeCamera?: (photos: IQueuePhoto[]) => void
    deletePhoto?: (photo: IQueuePhoto) => void
    editPhoto?: (photo: IQueuePhoto) => void
  }

  export interface ICamera {
    propsCamera?: propsCamera
    cameraOptions?: CameraOptions
    actions?: IAccionsCamera
  }

  const bus = useEventBus<ICamera>('camera')

  const activeCamera = ref(false)
  const cameraOptions = ref<CameraOptions>({})
  const actions = ref<IAccionsCamera>({})
  const propsCameraComponet = ref<propsCamera>({})

  const listener = (props: ICamera) => {
    console.log('listener click')
    activeCamera.value = true
    cameraOptions.value = props?.cameraOptions ?? {}
    actions.value = props?.actions ?? {}
    propsCameraComponet.value = props?.propsCamera ?? {}
  }

  const unsubscribe = bus.on(listener)

  const htmlElement = document.getElementsByTagName('html')[0]

  onUnmounted(() => {
    unsubscribe()
    bus.reset()
  })

  const closeCamera = (photos: IQueuePhoto[]) => {
    console.log('listener click')
    actions.value?.closeCamera?.(photos)
    activeCamera.value = false
  }

  const takePicture = (photo: IQueuePhoto) => {
    actions.value?.takePicture?.(photo)
  }

  const deletePhoto = (photo: IQueuePhoto) => {
    actions.value?.deletePhoto?.(photo)
  }

  const editPhoto = (photo: IQueuePhoto) => {
    actions.value?.editPhoto?.(photo)
  }

  /** prevents show scroll when the camera is opened & maintains scroll position when is closed */
  watch(activeCamera, (newValue) => {
    htmlElement.style.overflowY = newValue ? 'hidden' : 'visible'
  })
</script>

<template>
  <CameraComponent
    v-if="activeCamera"
    :project-id="propsCameraComponet.projectId"
    :photo-type="propsCameraComponet.photoType"
    :photo-type-id="propsCameraComponet.photoTypeId"
    :photos="cameraOptions.photos"
    :hidden-close-button="cameraOptions.hiddenCloseButton"
    :hidden-controls="cameraOptions.hiddenControls"
    @close-camera="closeCamera"
    @take-picture="takePicture"
    @delete-photo="deletePhoto"
    @edit-photo="editPhoto"
  />
  <div class="relative h-full" :class="activeCamera && 'hidden'">
    <slot />
  </div>
</template>

<style lang="scss">
  .camera-transition-leave-to,
  .camera-transition-enter-from {
    transform: translateY(10%); /* Comienza desde abajo */
    opacity: 0;
  }

  .camera-transition-leave-from,
  .camera-transition-enter-to {
    transform: translateY(-10%); /* Comienza desde abajo */
    opacity: 1;
  }
  .camera-transition-enter-active,
  .camera-transition-leave-active {
    transition: all 1s;
  }
</style>
