import { defineStore } from 'pinia'

interface LoaderStore {
  isActive: boolean
}

export const useLoaderStore = defineStore({
  id: 'Loader',
  state: (): LoaderStore => ({ isActive: false }),
  getters: { isActiveState: (state): boolean => state.isActive },
  actions: {
    setLoaderActiveState(payload: boolean): void {
      this.isActive = payload
    },
  },
})
