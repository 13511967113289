<script setup lang="ts">
  import { computed, toRefs } from 'vue'
  import ListTaskItem from './ListTaskItem.vue'
  import TaskItemPunchList from './TaskItemPunchList.vue'
  import { ITaskList } from '@/interfaces/punch-list/task-list/task-list.interface'
  import { useCloseOutStore } from '@/store'
  import { ITask } from '@/interfaces/punch-list/task/task.interface'
  import { ETaskStatus } from '@/enums/punch-list/task/task.enum'
  import { IQueuePhoto } from '@/db/tables'
  // import uploadPhotos from '@/utils/uploadPhotos'
  import { uploadPhotosBlob } from '@/utils/uploadPhotoBlob'
  import { base64ToBlob } from '@/utils/base64Toblob'
  // import { IPhoto } from '@/interfaces/photo/photo.interface'
  // import { useToast } from 'primevue/usetoast'
  const punchListCameraStore = useCloseOutStore()
  const { updateStatusTask, updatePhotosForTask } = punchListCameraStore

  type Iprops = {
    selectedItem?: ITask | null
    taskLists: ITaskList[]
    images?: IQueuePhoto[]
  }

  // const toast = useToast()
  // const findTaskIndex = (taskList: ITask[], taskId: string) => {
  //   return taskList.findIndex((taskItem) => taskItem.id === taskId)
  // }

  // const findTaskListIndex = (taskList: ITaskList[], taskId: string) => {
  //   return taskList.findIndex((taskList) => taskList.tasks.some((taskItem) => taskItem.id === taskId))
  // }
  // const nextTaskNotComplete = (taskCurrent: ITask, taskList: ITaskList[]) => {
  //   const currentTaskListIndex = findTaskListIndex(taskList, taskCurrent.id)
  //   const currentTaskIndex = findTaskIndex(taskList[currentTaskListIndex].tasks, taskCurrent.id)

  //   if (currentTaskIndex !== -1 && currentTaskListIndex !== -1) {
  //     // Find the next incomplete task in the current task list
  //     for (let i = currentTaskIndex + 1; i < taskList[currentTaskListIndex].tasks.length; i++) {
  //       const nextTask = taskList[currentTaskListIndex].tasks[i]
  //       if (nextTask.status === ETaskStatus.NOT_COMPLETED) {
  //         return nextTask
  //       }
  //     }

  //     // Find the next incomplete task in the subsequent task lists
  //     for (let i = currentTaskListIndex + 1; i < taskList.length; i++) {
  //       const nextTaskList = taskList[i]
  //       for (const nextTask of nextTaskList.tasks) {
  //         if (nextTask.status === ETaskStatus.NOT_COMPLETED) {
  //           return nextTask
  //         }
  //       }
  //     }
  //   }

  //   return null // No next incomplete task found
  // }

  const emit = defineEmits(['update:images', 'setSelectedItem', 'takePicture', 'selectPhoto'])

  const props = defineProps<Iprops>()
  const { taskLists, selectedItem } = toRefs(props)

  const images = computed({
    get: () => props.images,
    set: (value) => {
      emit('update:images', value)
    },
  })
  const uploadPhotosItem = async (photos: IQueuePhoto[], task: ITask) => {
    console.log('subiendo photos')
    // const imagesResponse = await uploadPhotos(photos)

    // console.log('imagesResponse', imagesResponse)
    const imagesResponse = await uploadPhotosBlob(
      photos.map((photo) => {
        const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
        return { ...photo, file: blob }
      })
    )
    updatePhotosForTask(imagesResponse, task)
    return imagesResponse
  }

  const updateStatusCheckTask = async (task: ITask) => {
    try {
      // const photosCamera = images.value?.filter(
      //   (p) => p.photoType === 'TASK_AFTER' || p.photoType === 'FINAL_PICTURES'
      // )
      // const photos = task.photos?.filter(
      //   (p) => p.type === 'TASK_AFTER' || p.type === 'FINAL_PICTURES'
      // )
      // if (
      //   photos?.length === 0 &&
      //   photosCamera?.length === 0 &&
      //   task.status !== ETaskStatus.NA
      // ) {
      //   toast.add({
      //     severity: 'error',
      //     summary: 'Error',
      //     detail: `No take picutres`,
      //     life: 3000,
      //   })
      //   return
      // }
      // await uploadPhotosItem(images.value ?? [], task)
      const payloadOptions = {
        [ETaskStatus.COMPLETED]: ETaskStatus.NOT_COMPLETED,
        [ETaskStatus.NOT_COMPLETED]: ETaskStatus.COMPLETED,
        [ETaskStatus.NA]: ETaskStatus.NOT_COMPLETED,
      }
      await updateStatusTask(task, payloadOptions[task.status])
      // if (payloadOptions[task.status] === ETaskStatus.COMPLETED) {
      //   const nextTask = nextTaskNotComplete(task, taskLists.value)
      //   emit('setSelectedItem', nextTask)
      // }
    } catch (error) {
      console.log('error', error)
    }
  }
</script>

<template>
  <div v-if="taskLists.length > 0" class="punch-list-container">
    <ListTaskItem v-for="taskList in taskLists" :key="taskList.id" :title="taskList.title">
      <TaskItemPunchList
        v-for="task in taskList.tasks"
        :key="task.id"
        :class="{ 'item-active': selectedItem && selectedItem.id === task.id }"
        :type="'COMPLETION'"
        :item="task"
        :check-state="task.status === 'COMPLETED' ? true : task.status === 'NA' ? false : null"
        :is-finalized="true"
        @select-photo="emit('selectPhoto')"
        @not-applicable="
          () => {
            emit('setSelectedItem', task)
            if (selectedItem) updateStatusTask(task, ETaskStatus.NA)
          }
        "
        @check-state-change="
          () => {
            emit('setSelectedItem', task)
            if (selectedItem) updateStatusCheckTask(task)
          }
        "
        @selected-item="
          (item: ITask) => {
            emit('setSelectedItem', item)
          }
        "
      />
    </ListTaskItem>
  </div>
</template>
