export const device = {
  thickness: 7,
  hitbox: 25,
  circle_hitbox: 5,
  sticker_width: 0,
  sticker_height: 0,
  sticker_thickness: 4,
  arrow_head_scale: 0,
  arrow_thickness: 1,
  box_width: 1.4,
  box_height: 1.4,
  text_fontsize: 0,
  text_opions_circles: 50,
  shadow_range: 2,
}
