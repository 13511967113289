<script lang="ts" setup>
  import { computed, toRefs } from 'vue'
  import { ActionOptions, OPT } from './types'

  const props = defineProps<ActionOptions>()
  const { show, x, y, options } = toRefs(props)

  const arryOptions = computed(() => {
    return [
      {
        id: 1,
        label: 'text' as OPT,
        path: '/icons/text.svg',
        onPress: () => emits('text'),
      },
      {
        id: 2,
        label: 'copy' as OPT,
        path: '/icons/copy.svg',
        onPress: () => emits('copy'),
      },

      {
        id: 3,
        label: 'delete' as OPT,
        path: '/icons/delete.svg',
        onPress: () => emits('delete'),
      },
    ]?.filter((e) => options.value.includes(e?.label))
  })

  const createIcon = (path: string) => {
    const icon = new Image()

    icon.src = path ?? '/icons/text.svg'
    return icon
  }

  const sizeIcon = 35
  const emits = defineEmits(['text', 'copy', 'delete'])
</script>

<template>
  <v-group
    v-if="show"
    :config="{
      x: x,
      y: y,
      draggable: false,
      id: `action-editor`,
    }"
  >
    <v-rect
      :config="{
        width: arryOptions.length * 36,
        height: 40,
        fill: 'rgba(0, 0, 0, 0.50)',
        stroke: 'rgba(0, 0, 0, 0.4)',
        strokeWidth: 2,
        cornerRadius: 6,
      }"
    ></v-rect>
    <v-image
      v-for="(opt, index) in arryOptions"
      :key="opt.id"
      :config="{
        id: `action-editor-${index}`,
        width: sizeIcon,
        height: sizeIcon,
        x: index === 0 ? 2 : index * 35,
        y: 5,
        image: createIcon(opt.path),
        opacity: 1,
      }"
      @touchstart="opt.onPress"
      @click="opt.onPress"
    />
  </v-group>
</template>
