export const FilterMediaEn = {
  label: 'Filters',
  dialog: {
    all: 'All Options',
    date: {
      label: 'Date',
      placeholder: 'Choose a range date',
    },
    uploadType: {
      label: 'Upload Type',
      placeholder: 'Choose a upload type',
      options: {
        ALL: 'All Options',
        CAMERA_MOVIL: 'Mobile camera',
        LIBRARY_MOVIL: 'Library Mobile',
      },
    },
    photoType: {
      label: 'Photo Type',
      placeholder: 'Choose a photo type',
      options: {
        ALL: 'All Options',
        PROGRESS_PICTURES: 'Progress Pictures',
        TASK_BEFORE: 'Task Before',
        TASK_LIST: 'Task List',
        TASK: 'Task',
        TASK_PICTURES: 'Task Pictures',
        FINAL_PICTURES: 'Final Pictures',
        PROFILE_PICTURES: 'Profile Pictures',
        TASK_AFTER: 'Task After',
        PROBLEM: 'Problem',
        PREPRODUCTION_PICTURES: 'Pre Production Pictures',
      },
    },
    user: {
      label: 'User',
      placeholder: 'Choose a user',
    },
    actions: {
      clearFilters: 'Clear Filters',
      close: 'Close',
    },
  },
}

export const FilterMediaEs = {
  label: 'Filtros',
  dialog: {
    all: 'Todas las Opciones',
    date: {
      label: 'Fecha',
      placeholder: 'Seleccione un intervalo de fechas',
    },
    uploadType: {
      label: 'Tipo de carga',
      placeholder: 'Seleccione un tipo de carga',
      options: {
        ALL: 'Todas las opciones',
        CAMERA_MOVIL: 'Cámara móvil',
        LIBRARY_MOVIL: 'Biblioteca móvil',
      },
    },
    photoType: {
      label: 'Tipo de foto',
      placeholder: 'Seleccione un tipo de foto',
      options: {
        ALL: 'Todas las opciones',
        PROGRESS_PICTURES: 'Fotos de progreso',
        TASK_BEFORE: 'Tarea anterior',
        TASK_LIST: 'Lista de tareas',
        TASK: 'Tarea',
        TASK_PICTURES: 'Fotos de la tarea',
        FINAL_PICTURES: 'Fotos finales',
        PROFILE_PICTURES: 'Fotos de perfil',
        TASK_AFTER: 'Tarea Después',
        PROBLEM: 'Problema',
        PREPRODUCTION_PICTURES: 'Fotos de preproducción',
      },
    },
    user: {
      label: 'Usuario',
      placeholder: 'Seleccione un usuario',
    },
    actions: {
      clearFilters: 'Limpiar Filtros',
      close: 'Cerrar',
    },
  },
}
