<script lang="ts" setup>
  import { useRouter } from 'vue-router'
  import { useLoaderStore, useAccountStore, useProjectStore, useDevieTokenStore } from '@/store'
  import { onMounted } from 'vue'
  import { useUserStore } from '@/store/Account/UserStore'

  const router = useRouter()
  const loaderStore = useLoaderStore()
  const { setLoaderActiveState } = loaderStore
  const accountStore = useAccountStore()
  const userStore = useUserStore()
  const projectStore = useProjectStore()
  const deviceTokenStore = useDevieTokenStore()

  setLoaderActiveState(true)
  accountStore.$reset()
  userStore.$reset()
  projectStore.$reset()
  deviceTokenStore.$reset()

  onMounted(async () => {
    setTimeout(async () => {
      await router.push('/account/login')
    }, 2000)
  })
</script>

<template>
  <div></div>
</template>
