export const projectAssignCrewUsersAddEnUS = {
  assignCrew: 'Assign User to Crew',
  submit: 'SUBMIT',
  error: 'Error',
  success: 'Success',
  errorassigning: 'Error assigning crew',
  successAssigning: 'Crew successfully assigned',
}

export const projectAssignCrewUsersAddEsMX = {
  assignCrew: 'Asignar Usuario para Equipo',
  submit: 'ENVIAR',
  error: 'Error',
  success: 'Éxito',
  errorassigning: 'Error al asignar el equipo',
  successAssigning: 'Equipo asignado correctamente',
}
