import { TPhotoContent } from '@/constants/types'
import { delay } from '@/utils/delay'
import { onMounted, ref, watch } from 'vue'

type IPhoto = {
  url: string
  variants?: TPhotoContent['variants']
}

const useThumbnail = (initialPhoto: IPhoto) => {
  const photo = ref(initialPhoto)
  const url = ref<string>('')
  const failure = ref<boolean>(true)
  const numberAttempts = ref<number>(0)
  const attempts = 1

  const handleError = async () => {
    if (numberAttempts.value < attempts) {
      numberAttempts.value++
      url.value = photo.value.url
      await delay(1000)
    } else {
      failure.value = true
    }
  }

  const handleLoad = () => {
    failure.value = false
  }

  const handleMountPhotos = () => {
    const urlPhoto = photo.value?.variants?.find((variant) => variant.variant === 'THUMBNAIL')?.uri ?? photo.value?.url
    url.value = urlPhoto
    return urlPhoto
  }

  const handleResetAttempts = () => {
    numberAttempts.value = 0
  }

  onMounted(() => {
    handleMountPhotos()
    handleResetAttempts()
  })

  watch(
    photo,
    (newPhoto, oldPhoto) => {
      if (newPhoto && oldPhoto && newPhoto.url !== oldPhoto.url) {
        handleMountPhotos()
        handleResetAttempts()
      }
    },
    { deep: true }
  )

  return {
    url,
    failed: failure,
    handleMountPhotos,
    handleResetAttempts,
    handleLoad,
    handleError,
  }
}

export default useThumbnail
