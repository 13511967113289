export const coordinatesShape = {
  '0': {
    rotate: 0,
  },
  '90': {
    rotate: -90,
  },
  '180': {
    rotate: -180,
  },
  '270': {
    rotate: 90,
  },
}
