import { NavigationGuard } from 'vue-router'
import localForage from 'localforage'
import { AccountStore } from '@/store'

export const isAuthenticatedGuard: NavigationGuard = async (to, from, next) => {
  const account = (await localForage.getItem('Account')) as AccountStore
  if (account) {
    if (account?.isSignedIn) return next()
  }
  return next({ path: '/account/logout' })
}
