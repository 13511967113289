<script lang="ts" setup>
  import { toRefs } from 'vue'
  import { TShapeProps } from '../store/shapes'
  import { KonvaEventObject } from 'konva/lib/Node'
  import ActionOptions from './ActionOptions.vue'
  const props = defineProps<TShapeProps>()
  const { shape, image, device, draggable, isSelected } = toRefs(props)
  const emits = defineEmits(['ondragstart', 'ondragmoving', 'ondragstop', 'onselectshape', 'ondelete', 'oncopy'])
  const handleSelect = () => {
    emits('onselectshape', shape.value)
  }
  const handleDragStart = () => {
    emits('ondragstart', shape.value)
  }
  const handleDragMove = (e: KonvaEventObject<Event>) => {
    shape.value.x = e?.target?.x() / image.value.scale
    shape.value.y = e?.target?.y() / image.value.scale
    emits('ondragmoving', shape.value)
  }
  const handleDragStop = () => {
    emits('ondragstop', shape.value)
  }
</script>

<template>
  <v-line
    :id="`shape-draw-shadow-${shape.id}`"
    :draggable="false"
    :config="{
      x: shape.x * image.scale,
      y: shape.y * image.scale,
      stroke: 'black',
      strokeWidth: device.thickness,
      globalCompositeOperation: 'source-over',
      lineCap: 'round',
      lineJoin: 'round',
      points: shape.points?.map((e) => e * image.scale + 2),
    }"
  ></v-line>
  <v-line
    :id="`shape-draw-diff-${shape.id}`"
    :draggable="draggable"
    :config="{
      x: shape.x * image.scale,
      y: shape.y * image.scale,
      stroke: shape.color,
      strokeWidth: device.thickness,
      globalCompositeOperation: 'source-over',
      lineCap: 'round',
      lineJoin: 'round',
      hitStrokeWidth: 40,
      points: shape.points?.map((e) => e * image.scale),
    }"
    @click="handleSelect"
    @tap="handleSelect"
    @touchstart="handleDragStart"
    @touchmove="handleDragMove"
    @touchend="handleDragStop"
    @dragstart="handleDragStart"
    @dragmove="handleDragMove"
    @dragend="handleDragStop"
  ></v-line>

  <ActionOptions
    :options="['copy', 'delete']"
    :show="isSelected"
    :x="shape.x * image.scale + shape.points[0] * image.scale"
    :y="shape.y * image.scale + shape.points[1] * image.scale"
    @delete="() => emits('ondelete', shape)"
    @copy="() => emits('oncopy', shape)"
  />
</template>
