export const calculateFontSize = (original_width: number, original_height: number) => {
  const baseFontSize = 160 // Tamaño de fuente para 1920 x 1080
  const referenceWidth = 1280
  const referenceHeight = 720

  const widthScale = original_width / referenceWidth
  const heightScale = original_height / referenceHeight

  const scale = Math.min(widthScale, heightScale)

  const adjustedFontSize = baseFontSize * scale

  return adjustedFontSize
}
