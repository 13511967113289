export default {
  finetuneLabel: 'Ajuste fino',
  finetuneIcon: '<g stroke-width=".125em" stroke="currentColor" fill="none"><path d="M4 1v5.5m0 3.503V23M12 1v10.5m0 3.5v8M20 1v15.5m0 3.5v3M2 7h4M10 12h4M18 17h4"/></g>',

  finetuneLabelBrightness: 'Brillo',
  finetuneLabelContrast: 'Contraste',
  finetuneLabelSaturation: 'Saturación',
  finetuneLabelExposure: 'Exposición',
  finetuneLabelTemperature: 'Temperatura',
  finetuneLabelGamma: 'Gama',
  finetuneLabelClarity: 'Nitidez',
  finetuneLabelVignette: 'Viñeta',
}
