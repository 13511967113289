import { IQueuePhoto } from '@/db/tables'
import { IPhotoResponse } from '@/interfaces/photo/photo.interface'
import api from '@/services/api'
import { captureException } from '@sentry/vue'

import axios from 'axios'

export const uploadVideoBlob = async (video: IQueuePhoto): Promise<IPhotoResponse> => {
  try {
    const type = video.entityType === 'Lead' ? 'Leads' : 'Projects'
    const payload = {
      type: video.photoType,
      id: video.projectId,
      latitude: video.latitude,
      longitude: video.longitude,
      photoTypeId: video.photoTypeId,
      filename: `${Date.now()}-${video.id}.mp4`,
      uploadType: video.uploadPhotoType,
      mediaType: 'VIDEO',
    }
    const response = await api.post(`/api/${type}/SignedMedia`, payload)
    const responseInfo = response.data.data as IPhotoResponse
    const url = responseInfo?.photoUrl as string
    await axios.put(url, video.file, { headers: { 'Content-Type': 'application/octet-stream', 'x-ms-blob-type': 'BlockBlob' } })
    await api.post(`/api/${type}/SetActivePhoto/${responseInfo.id}`)
    return responseInfo
  } catch (error) {
    captureException(error)
    return {} as IPhotoResponse
  }
}

export const uploadVideosBlob = async (videos: IQueuePhoto[]): Promise<IPhotoResponse[]> => {
  if (!videos.length || videos === undefined || videos === null) {
    return [] as IPhotoResponse[]
  }

  try {
    const response = await Promise.all(
      videos.map(async (video) => {
        const type = video.entityType === 'Lead' ? 'Leads' : 'Projects'
        const payload = {
          type: video.photoType,
          id: video.projectId,
          latitude: video.latitude,
          longitude: video.longitude,
          photoTypeId: video.photoTypeId,
          filename: `${Date.now()}-${video.id}.mp4`,
          uploadType: video.uploadPhotoType,
          mediaType: 'VIDEO',
        }
        const response = await api.post(`/api/${type}/SignedMedia`, payload)
        const responseInfo = response.data.data as IPhotoResponse
        const url = responseInfo?.photoUrl as string
        await axios.put(url, video.file, { headers: { 'Content-Type': 'application/octet-stream', 'x-ms-blob-type': 'BlockBlob' } })
        await api.post(`/api/${type}/SetActivePhoto/${responseInfo.id}`)
        return responseInfo
      })
    )
    return response
  } catch (error) {
    captureException(error)
    return [] as IPhotoResponse[]
  }
}
