<script lang="ts" setup>
  import api from '@/services/api'
  import { useDebounceFn } from '@vueuse/core'
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'

  import { i18n } from '@/i18n'
  import ProgressSpinner from 'primevue/progressspinner'

  type ICrewCompany = {
    id: string
    crewCompanyId: null | string
    name: string
    email: null | string
    phone: null | string
    logoUrl: null | string
    rating: number
    manager: {
      fullName: string
      email: string
      phone: string
      rating: null | number
    }
  }

  const { t } = i18n.global
  const router = useRouter()
  const crewCompanies = ref<ICrewCompany[]>([])
  const searchTerm = ref('')
  const loading = ref(false)

  const getCrewDirectoryList = async (query = '') => {
    try {
      loading.value = true
      const {
        data: { data: response },
      } = await api.get(`/Api/CrewCompanies?query=${query}`)
      crewCompanies.value = response
    } finally {
      loading.value = false
    }
  }
  const debouncedFn = useDebounceFn(async () => {
    await getCrewDirectoryList(searchTerm.value)
  }, 500)
  const searchCrewDirectory = () => {
    debouncedFn()
  }

  onMounted(async () => {
    await getCrewDirectoryList()
  })
</script>

<template>
  <div>
    <div class="col">
      <div class="flex flex-column lg:flex-row lg:justify-content-between flex-nowrap md:flex-wrap gap-3">
        <div>
          <h1 class="mb-0">{{ t('crewDirectory.crewDirectory') }}</h1>
        </div>
        <div>
          <span class="p-input-icon-left w-full md:w-full">
            <i class="pi pi-search" />
            <InputText v-model="searchTerm" type="text" :placeholder="t('crewDirectory.search')" class="w-full md:w-full" @input="searchCrewDirectory" />
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading" class="flex justify-content-center w-full align-items-center h-full">
    <ProgressSpinner
      style="width: 50px; height: 50px"
      stroke-width="5"
      animation-duration="1s"
      :pt="{ circle: { style: { stroke: '#2563eb', animation: 'p-progress-spinner-dash 1.5s ease-in-out infinite' } } }"
    />
  </div>

  <div v-else class="grid">
    <div v-for="crewCompany in crewCompanies" :key="crewCompany.id" class="col-12 md:col-6 lg:col-6 xl:col-4">
      <div class="flex w-full justify-content-start flex-wrap">
        <article class="p-card w-full shadow-none p-3 h-full">
          <div class="col pb-0">
            <div class="flex align-items-center justify-content-between mb-4">
              <div class="flex align-items-center">
                <Avatar :image="`https://contacts.zoho.com/file?fs=thumb&ID=`" class="mr-2" size="large" shape="circle" />
                <h2 class="text-lg font-bold m-0 cursor-pointer" @click="router.push(`/my-company/${crewCompany.id}`)">
                  {{ crewCompany.name }}
                </h2>
              </div>
              <p v-if="crewCompany.rating === 0 || crewCompany.rating === null" class="flex gap-1 align-items-center text-lg">
                <span>{{ 'N/A' }}</span>
              </p>
              <p v-else class="flex gap-1 align-items-center text-lg">
                <i class="pi pi-star-fill" style="color: #ffca22; font-size: 1.2rem"></i><span>{{ crewCompany.rating }}</span>
              </p>
            </div>
            <div class="flex flex-column gap-2">
              <a v-if="crewCompany.phone" :href="`tel:${crewCompany.phone}`" label="Link" class="p-1 p-button p-button-text flex gap-1 mb-0 w-max"
                ><i class="pi pi-phone"></i> <span> {{ t('crewDirectory.phone') }}: {{ crewCompany.phone || '---' }}</span></a
              >
              <p v-else class="mb-0 text-400 p-1">
                <i class="pi pi-phone"></i>
                <span> {{ t('crewDirectory.phone') }}: {{ crewCompany.phone || '---' }}</span>
              </p>

              <a v-if="crewCompany.email" :href="`mailto:${crewCompany.email}`" label="Link" class="p-1 p-button p-button-text flex gap-1 w-full"
                ><i class="pi pi-at"></i>
                <span class="inline-block overflow-hidden white-space-nowrap text-overflow-ellipsis"> {{ t('crewDirectory.email') }}: {{ crewCompany.email || '---' }}</span></a
              >
              <p v-else class="mb-0 text-400 p-1">
                <i class="pi pi-at"></i>
                <span> {{ t('crewDirectory.email') }}: {{ crewCompany.email || '---' }}</span>
              </p>

              <a v-if="crewCompany.phone" :href="`sms://${crewCompany.phone}`" label="Link" class="p-1 p-button p-button-text flex gap-1 w-max"
                ><i class="pi pi-comment"></i> <span> {{ t('crewDirectory.sms') }}: {{ crewCompany.phone || '---' }}</span></a
              >
              <p v-else class="mb-0 text-400 p-1">
                <i class="pi pi-comment"></i>
                <span> {{ t('crewDirectory.sms') }}: {{ crewCompany.phone || '---' }}</span>
              </p>
            </div>
          </div>
          <div class=""></div>
        </article>
      </div>
    </div>
  </div>
  <div v-if="!crewCompanies?.length && !loading">No hay crews disponibles</div>
</template>
