<script lang="ts" setup>
  import { ref, watch } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import { IAddTask } from '@/interfaces/punch-list/task/task.interface'
  import api from '@/services/api'
  import { Capacitor } from '@capacitor/core'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import Button from 'primevue/button'
  import InputText from 'primevue/inputtext'
  import { IQueuePhoto } from '@/db/tables'
  import { captureException } from '@sentry/vue'
  import { ICamera } from '@/components/PunchListCamera/Camera/CameraContainer.vue'
  import { useEventBus } from '@vueuse/core'
  import UploadButtonComponentVue from '@/components/UploadButtonComponent.vue'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import { i18n } from '@/i18n'
  import PhotoList from '@/components/PhotosList/PhotoList.vue'
  import processMediaInputAndGetMediaIds from '@/utils/proccessMediaInputAndGetIds'
  import Editor from '@/components/editor_images/Editor.vue'
  const props = defineProps({ projectId: { type: String, required: true }, punchListId: { type: String, required: true } })

  const toast = useToast()
  const camera = useEventBus<ICamera>('camera')
  const isSubmitting = ref<boolean>(false)
  const punchListTaskListFieldValue = ref<string | null>(null)
  const punchListTasks = ref<IAddTask[]>([])
  const addNewTaskDisabled = ref(false)
  const { t } = i18n.global
  const emit = defineEmits(['closeAddTaskListDialog'])

  const closeCamera = async (currentIndex: number, photos: IQueuePhoto[]) => {
    const photosListTask = punchListTasks.value[currentIndex].photos
    const uploadPhotoType = await devicePhotoTag('CAMERA')
    const taskCameraPhotos = photos.map((photo) => ({ ...photo, uploadPhotoType }))

    const photosUpload = photosListTask?.filter((photo) => {
      return photo.uploadPhotoType?.includes('LIBRARY')
    })
    punchListTasks.value[currentIndex].photos = [...taskCameraPhotos, ...photosUpload]
  }

  const createTaskList = async () => {
    try {
      addNewTaskDisabled.value = true
      isSubmitting.value = true
      const tasks = await Promise.all(
        punchListTasks.value?.map(async (taskList) => {
          const mediaIds = await processMediaInputAndGetMediaIds(taskList.photos)
          return { title: taskList.title, instructions: null, details: null, isPhotoRequired: true, photoIds: mediaIds }
        })
      )

      const payload = [{ punchListId: props.punchListId, title: punchListTaskListFieldValue.value, instructions: null, details: null, tasks }]
      await api.post('/Api/Projects/TaskListTree', payload)
      // await injectTaskListIntoActivePunchList(tempTaskList)

      isSubmitting.value = false
      emit('closeAddTaskListDialog')
      toast.add({ severity: 'success', summary: 'Success', detail: 'Successfully created task list', life: 3000 })
    } catch (e) {
      addNewTaskDisabled.value = false
      captureException(e)
      toast.add({ severity: 'error', summary: 'Error', detail: `${e}`, life: 3000 })
      isSubmitting.value = false
    }
  }

  function addTask() {
    punchListTasks.value.push({ title: null, instructions: null, details: null, isPhotoRequired: true, photos: [] })
  }

  async function takePictures(index: number) {
    const photosCameraTasklist = punchListTasks.value[index].photos?.filter((photo) => {
      return photo?.uploadPhotoType?.includes('CAMERA')
    })

    camera.emit({
      propsCamera: { projectId: props.projectId, photoType: PhotoTypeEnum.TASK_BEFORE },
      cameraOptions: { photos: photosCameraTasklist },
      actions: { closeCamera: (images) => closeCamera(index, images) },
    })
  }

  function deleteTask(index: number) {
    punchListTasks.value.splice(index, 1)
  }

  watch(punchListTasks.value, (oldValue, newValue) => {
    let isDisabled = false
    for (const task of newValue) {
      if (!task.title || task.title.length === 0) {
        isDisabled = true
        break
      }

      if (task.photos.length === 0) {
        isDisabled = true
        break
      }
    }

    addNewTaskDisabled.value = isDisabled
  })
  const handleImages = (currentIndex: number, images: IQueuePhoto[]) => {
    const activeTaskPhotos = punchListTasks.value[currentIndex]?.photos || []

    punchListTasks.value[currentIndex].photos = [...images, ...activeTaskPhotos]
  }

  const showDialogEditor = ref(false)
  const initialEditorImageSelected = {
    photoId: '',
    photoOURL: '',
    annotation: null,
    taskIndex: null,
  }
  const editor_image_selected = ref<{ photoId: string; photoOURL: string; annotation: string | null; taskIndex: number | null }>(initialEditorImageSelected)

  const handleRemoveImageInTask = (index: number, id: string) => {
    const photos = punchListTasks.value[index].photos?.filter((e) => e?.id !== id)
    punchListTasks.value[index].photos = photos
  }
  const handleCloseEditor = () => {
    showDialogEditor.value = false
    editor_image_selected.value = initialEditorImageSelected
  }
  const handleSaveEditor = (data: { base_64: string; annotation: string; photoId: string }) => {
    const indx = Number(editor_image_selected?.value?.taskIndex)
    const photos = punchListTasks.value[indx].photos?.map((e) => {
      if (e.id === data?.photoId) {
        return {
          ...e,
          base64_annotation: data?.base_64,
          annotation: data?.annotation,
        }
      }
      return e
    })
    punchListTasks.value[indx].photos = photos
    showDialogEditor.value = false
    editor_image_selected.value = initialEditorImageSelected
  }
  const handleClickEditPhoto = (index: number, photo: IQueuePhoto) => {
    editor_image_selected.value = {
      photoId: photo.id,
      annotation: photo.annotation ?? null,
      photoOURL: photo.base64 ?? '',
      taskIndex: index,
    }
    showDialogEditor.value = true
  }
</script>

<template>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column gap-1">
      <label for="tasklist">{{ $t('projectPunchListAdd.tasklist') }}</label>
      <InputText id="tasklist" v-model="punchListTaskListFieldValue" type="text" class="p-inputtext w-full" />
    </div>
    <Editor
      v-if="showDialogEditor"
      :available-annotations="false"
      :photoid="editor_image_selected?.photoId"
      :photourl="editor_image_selected?.photoOURL"
      :annotation="editor_image_selected?.annotation"
      @close="handleCloseEditor"
      @save="handleSaveEditor"
    />

    <ul class="flex flex-column gap-4 p-0 m-0">
      <li v-for="(task, taskIndex) in punchListTasks" :key="`task-${taskIndex}`" class="flex flex-column gap-2">
        <div class="w-full flex flex-row align-items-center justify-content-between">
          <p class="font-bold text-center m-0">{{ t('projectPunchListAdd.taskName') }} {{ taskIndex + 1 }}</p>
          <Button v-if="punchListTasks.length > 1" icon="pi pi-trash" severity="danger" @click="deleteTask(taskIndex)" />
        </div>
        <div class="flex flex-column gap-2">
          <InputText v-model="task.title" type="text" class="p-inputtext flex-grow-1" />
          <div class="flex flex-row align-items-center w-full align-items-end justify-content-end">
            <!-- <Button
              v-tooltip.bottom="'Camera'"
              icon="pi pi-camera"
              class="p-button p-component RemoveText p-button-text ButtonInputFile ButtonInputImageCamera"
              :label="Capacitor.isNativePlatform() ? '' : t('projectMedia.capture')"
              @click="() => takePictures(taskIndex)"
            /> -->
            <UploadButtonComponentVue
              photo-type="TASK_BEFORE"
              entity-type="Project"
              :project-id="props.projectId"
              :label-button="Capacitor.isNativePlatform() ? '' : t('projectMedia.upload')"
              class-button="p-button-text ButtonInputFile"
              @on-media="(images) => handleImages(taskIndex, images)"
            />
          </div>
        </div>
        <PhotoList :photos="task.photos" @edit-photo="(photo) => handleClickEditPhoto(taskIndex, photo)" @remove="(id) => handleRemoveImageInTask(taskIndex, id)" />
      </li>
    </ul>

    <div class="flex justify-content-center w-full">
      <Button :disabled="addNewTaskDisabled" :label="t('projectPunchListAdd.addTask')" icon="pi pi-plus" class="p-button p-button-text" @click="addTask" />
    </div>
    <div class="flex justify-content-end">
      <Button
        :label="t('projectProblems.create')"
        class="w-full"
        :loading="isSubmitting"
        :disabled="addNewTaskDisabled || !punchListTaskListFieldValue || punchListTaskListFieldValue.length === 0 || isSubmitting"
        @click="createTaskList"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 595px) {
    .ButtonInputImageCamera {
      font-size: 0 !important;
    }
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;

    // > li {
    //   border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    //   &:first-child {
    //     border-top: 1px solid rgba(0, 0, 0, 0.3);
    //   }
    // }
  }

  .p-divider.p-divider-horizontal::before {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
</style>
