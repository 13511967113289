export default {
  frameLabel: 'Marco',
  frameIcon: `<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em">
            <rect x="2" y="2" width="20" height="20" rx="4"/>
            <rect x="6" y="6" width="12" height="12" rx="1"/>
        </g>`,

  frameLabelMatSharp: 'Mate',
  frameLabelMatRound: 'Biselado',
  frameLabelLineSingle: 'Línea',
  frameLabelLineMultiple: 'Cebra',
  frameLabelEdgeSeparate: 'Insertar',
  frameLabelEdgeOverlap: 'Más',
  frameLabelEdgeCross: 'Madera',
  frameLabelCornerHooks: 'Gancho',
  frameLabelPolaroid: 'Polaroid',
}
