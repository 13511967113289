import { FilterMatchModeOptions } from 'primevue/api'

const safeToNumber = (value: any): number => {
  const num = Number(value)
  return isNaN(num) ? NaN : num
}

function generateFilterByNumbers<T>(matchMode: keyof FilterMatchModeOptions, value: string | number, key: keyof T) {
  if (value === '') return () => true

  const numericValue = safeToNumber(value)

  return (data: T) => {
    const dataValue = safeToNumber(data[key])
    if (isNaN(numericValue) || isNaN(dataValue)) return false

    switch (matchMode?.toUpperCase()) {
      case 'EQUALS':
        return dataValue === numericValue
      case 'LESSTHAN':
        return dataValue < numericValue
      case 'LESSTHANOREQUALTO':
        return dataValue <= numericValue
      case 'GREATERTHAN':
        return dataValue > numericValue
      case 'GREATERTHANOREQUALTO':
        return dataValue >= numericValue
      case 'NOTEQUALS':
        return dataValue !== numericValue
      default:
        return false
    }
  }
}

export default generateFilterByNumbers
