export enum PhotoStatusEnum {
  PENDING_SIGNED_URL = 'PENDING_SIGNED_URL',
  PENDING_UPLOAD = 'PENDING_UPLOAD',
  UPLOADING = 'UPLOADING',
  COMPLETE_UPLOADING = 'COMPLETE_UPLOADING',
  FAILED_UPLOADING = 'FAILED_UPLOADING',
  PENDING_ADD_TO_PROJECT = 'PENDING_ADD_TO_PROJECT',
  COMPLETE_ADD_TO_PROJECT = 'COMPLETE_ADD_TO_PROJECT',
  FAILED_ADD_TO_PROJECT = 'FAILED_ADD_TO_PROJECT',
  URL_EXPIRED = 'URL_EXPIRED',
  COMPLETE = 'COMPLETE',
}

export enum PhotoTypeEnum {
  PROGRESS_PICTURES = 'PROGRESS_PICTURES',
  PREPRODUCTION_PICTURES = 'PREPRODUCTION_PICTURES',
  TASK_BEFORE = 'TASK_BEFORE',
  TASK_LIST = 'TASK_LIST',
  TASK = 'TASK',
  TASK_PICTURES = 'TASK_PICTURES',
  FINAL_PICTURES = 'FINAL_PICTURES',
  PROFILE_PICTURES = 'PROFILE_PICTURES',
  TASK_AFTER = 'TASK_AFTER',
  PROBLEM = 'PROBLEM',
}

export interface PhotoQueueInterface {
  id?: string
  projectId: string
  signedUrl?: string
  fileUrl?: string
  fileName: string
  description?: string
  latitude: string
  longitude: string
  base64Data: string
  progress?: number
  status: PhotoStatusEnum
  photoType?: PhotoTypeEnum
  photoTypeId?: string
}

export const PHOTO_QUEUE_TABLE = '++id, projectId, status'
