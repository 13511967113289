export const projectTypesEnUS = {
  ROOFING_PLANKER: 'Roofing - Planker',
  SIDING: 'Siding',
  WINDOWS: 'Windows',
  REPAIRS: 'Repairs',
  NONE: '-None-',
  COMMERCIAL_GUTTERS: 'Commercial Gutters',
  COMMERCIAL_INSULATION: 'Commercial Insulation',
  COMMERCIAL_SIDING: 'Commercial Siding',
  COMMERCIAL_WINDOWS: 'Commercial Windows',
  CONSTRUCTION: 'Construction',
  DECK: 'Deck',
  GUTTER_GUARDS: 'Gutter Guards',
  GUTTERS: 'Gutters',
  INSULATION: 'Insulation',
  LABOR_ONLY: 'Labor Only',
  NEW_CONSTRUCTION_ROOFING: 'New Construction - Roofing',
  NEW_CONSTRUCTION_SIDING: 'New Construction - Siding',
  NEW_CONSTRUCTION_WINDOWS: 'New Construction - Windows',
  ROOFING: 'Roofing',
  ROOFING_GUTTERS: 'Roofing & Gutters',
  ROOFING_CEDAR_INSTALL: 'Roofing - Cedar Install',
  ROOFING_CEDAR_REDECK: 'Roofing - Cedar redeck',
  ROOFING_COMMERCIAL: 'Roofing - Commercial',
  ROOFING_FLAT_ROOF_RESIDENTIAL: 'Roofing - Flat roof (residential)',
  ROOFING_METAL: 'Roofing - Metal',
  ROOFING_RANCH: 'Roofing - Ranch',
  ROOFING_SPECIALTY: 'Roofing - Specialty',
  SIDING_HARDI: 'Siding - Hardi',
  SIDING_HOLLOW: 'Siding - Hollow',
  SIDING_INSULATED: 'Siding - Insulated',
  SIDING_REPAIRS: 'Siding - Repairs',
  SIDING_SMARTSIDE: 'Siding - Smartside',
  SIDING_STONE: 'Siding - Stone',
  SKYLIGHTS: 'Skylights',
  TRIM_WORK: 'Trim Work',
  WINDOWS_DOORS: 'Windows & Doors',
  WINDOWS_DOORS_COMMERCIAL: 'Windows & Doors - Commercial',
  WINDOWS_DOORS_ENTRY_DOOR: 'Windows & Doors - Entry Door',
  WINDOWS_DOORS_GARAGE_DOOR: 'Windows & Doors - Garage door',
  WINDOWS_DOORS_GREAT_LAKES: 'Windows & Doors - Great Lakes',
  WINDOWS_DOORS_MARVIN: 'Windows & Doors - Marvin',
  WINDOWS_DOORS_PATIO_DOOR: 'Windows & Doors - Patio Door',
  WINDOWS_DOORS_PELLA: 'Windows & Doors - Pella',
  WINDOWS_DOORS_PROVIA: 'Windows & Doors - Provia',
  WINDOWS_DOORS_RICHLIN: 'Windows & Doors - Richlin',
  WINDOWS_DOORS_SIERRA: 'Windows & Doors - Sierra',
}

export const projectTypesEsMX = {
  ROOFING_PLANKER: 'Techador - Planker',
  SIDING: 'Revestimiento',
  WINDOWS: 'Ventanas',
  REPAIRS: 'Reparaciones',
  NONE: '-Ninguna-',
  COMMERCIAL_GUTTERS: 'Canalones comerciales',
  COMMERCIAL_INSULATION: 'Aislamiento comercial',
  COMMERCIAL_SIDING: 'Revestimiento comercial',
  COMMERCIAL_WINDOWS: 'Ventanas comerciales',
  CONSTRUCTION: 'Construcción',
  DECK: 'Cubierta',
  GUTTER_GUARDS: 'Guardias de canal',
  GUTTERS: 'Canalones',
  INSULATION: 'Aislamiento',
  LABOR_ONLY: 'Solo mano de obra',
  NEW_CONSTRUCTION_ROOFING: 'Nueva construcción - Techado',
  NEW_CONSTRUCTION_SIDING: 'Nueva construcción - Revestimiento',
  NEW_CONSTRUCTION_WINDOWS: 'Nueva construcción - Ventanas',
  ROOFING: 'Techado',
  ROOFING_GUTTERS: 'Techado y canalones',
  ROOFING_CEDAR_INSTALL: 'Techado - Instalación de cedro',
  ROOFING_CEDAR_REDECK: 'Techado - Cubierta de cedro',
  ROOFING_COMMERCIAL: 'Techado - Comercial',
  ROOFING_FLAT_ROOF_RESIDENTIAL: 'Techado - Techo plano (residencial)',
  ROOFING_METAL: 'Techado - Metal',
  ROOFING_RANCH: 'Techado - Rancho',
  ROOFING_SPECIALTY: 'Techado - Especialidad',
  SIDING_HARDI: 'Revestimiento - Hardi',
  SIDING_HOLLOW: 'Revestimiento - Hueco',
  SIDING_INSULATED: 'Revestimiento - Aislado',
  SIDING_REPAIRS: 'Revestimiento - Reparaciones',
  SIDING_SMARTSIDE: 'Revestimiento - Smartside',
  SIDING_STONE: 'Revestimiento - Piedra',
  SKYLIGHTS: 'Tragaluz',
  TRIM_WORK: 'Trabajo de recorte',
  WINDOWS_DOORS: 'Ventanas y puertas',
  WINDOWS_DOORS_COMMERCIAL: 'Ventanas y puertas - Comercial',
  WINDOWS_DOORS_ENTRY_DOOR: 'Ventanas y puertas - Puerta de entrada',
  WINDOWS_DOORS_GARAGE_DOOR: 'Ventanas y puertas - Puerta de garaje',
  WINDOWS_DOORS_GREAT_LAKES: 'Ventanas y puertas - Grandes Lagos',
  WINDOWS_DOORS_MARVIN: 'Ventanas y puertas - Marvin',
  WINDOWS_DOORS_PATIO_DOOR: 'Ventanas y puertas - Puerta de patio',
  WINDOWS_DOORS_PELLA: 'Ventanas y puertas - Pella',
  WINDOWS_DOORS_PROVIA: 'Ventanas y puertas - Provia',
  WINDOWS_DOORS_RICHLIN: 'Ventanas y puertas - Richlin',
  WINDOWS_DOORS_SIERRA: 'Ventanas y puertas - Sierra',
}
