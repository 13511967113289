export const verifiyIdentityUs = {
  title: 'Log in session quickly with biometric or code',
  subtitle: 'Your recent session with:',
  description: 'For the security of you account, please verify it using your fingerprint or phone code.',
  reason: 'For access more quickly and secure',
}

export const verifiyIdentityEs = {
  title: 'Inicia sesión rápidamente con biometría o código',
  reason: 'Para acceder mas rapido y seguro',
  subtitle: 'Tu reciente sesión en:',
  description: 'Por tu seguridad de tu cuenta, por favor verificate utilizando tu huella o tu codigo movil',
}
