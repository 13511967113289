import {
  Geolocation,
  PermissionStatus,

  // Position
} from '@capacitor/geolocation'

// interface ICoordinates {
//   latitude: number
//   longitude: number
// }

export const checkUserGeolocationPermissions = (): Promise<PermissionStatus> => Geolocation.checkPermissions()
export const requestUserGeolocationPermissions = (): Promise<PermissionStatus> => Geolocation.requestPermissions()
export const fetchCurrentLocationOnce = async (enableHighAccuracy = false) => {
  const { coords } = await Geolocation.getCurrentPosition({ enableHighAccuracy: enableHighAccuracy })
  return { latitude: coords.latitude, longitude: coords.longitude }
}
