<script lang="ts" setup>
  import DirectoryMobile from './DirectoryMobile.vue'
  import DirectoryWeb from './DirectoryWeb.vue'
  import { useBreakpointsStore } from '@/store'
  import { storeToRefs } from 'pinia'
  const breakpointStore = useBreakpointsStore()
  const { lg } = storeToRefs(breakpointStore)
</script>

<template>
  <DirectoryWeb v-if="lg" />
  <DirectoryMobile v-else />
</template>
