import { defineStore, storeToRefs } from 'pinia'
import api from '@/services/api'
import { logDeviceInfo } from '@/services/pushNotification'
import { useUserStore, useAccountStore } from '@/store'

export const registerDeviceUser = async (tokenDevice: string, deviceInfo: string) => {
  const accountStore = useAccountStore()
  const { tenant, accessToken } = storeToRefs(accountStore)
  if (!tenant.value || !accessToken.value) return
  if (tokenDevice) {
    const payload = {
      deviceToken: tokenDevice,
      deviceInfo: deviceInfo,
    }
    await api.post('/Api/Users/AddDeviceToken', payload)
  }
}

export const allowPushNotification = async (allow: boolean) => {
  const userStore = useUserStore()
  const { id } = storeToRefs(userStore)
  if (!id.value) return
  await api.patch(`/Api/User/${id.value}/Profile/AllowNotification`, { allow: allow })
}

export const statusDeviceToken = async (status: boolean) => {
  const userStore = useUserStore()
  const { id } = storeToRefs(userStore)
  if (!id.value) return
  await api.patch(`/Api/User/${id.value}/Profile/StatusDeviceToken`, { status: status })
}

export const useDevieTokenStore = defineStore({
  id: 'DeviceToken',
  state: () => ({ deviceToken: '', infoDeviceToken: '' }),
  actions: {
    async setDeviceToken(token: string) {
      const deviceInfo = await logDeviceInfo()
      this.deviceToken = token
      this.infoDeviceToken = deviceInfo
      await this.registerDeviceUser()
    },
    async registerDeviceUser() {
      await registerDeviceUser(this.deviceToken, this.infoDeviceToken)
    },
  },
})
