export const projectDocumentsEnUS = {
  production: 'Production',
  estimation: 'Estimation',
  contract: 'Contract',
  project: 'Project',
  projectOld: 'This project uses an old project structure',
}

export const projectDocumentsEsMX = {
  production: 'Producción',
  estimation: 'Estimación',
  contract: 'Contrato',
  project: 'Poyecto',
  projectOld: 'Este proyecto usa una estructura de proyecto antigua',
}
