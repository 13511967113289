<script lang="ts" async setup>
  import ProjectCardsComponent from '@/components/ProjectCardsComponent.vue'
  import api from '@/services/api'
  import { ref } from 'vue'

  const projects = ref([])
  const loadingProjects = ref(false)

  const props = defineProps({ projectId: { type: String, required: true } })

  const getProjectsRelated = async () => {
    try {
      loadingProjects.value = true
      const response = await api.get(`/Api/Projects/${props.projectId}/GetByReference`)
      projects.value = response.data.data
    } catch (e) {
      console.log(e)
    } finally {
      loadingProjects.value = false
    }
  }

  getProjectsRelated()
</script>

<template>
  <ProjectCardsComponent :projects="projects" :loading-projects="loadingProjects" />
</template>
