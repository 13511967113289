<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, toRefs } from 'vue'
  import Card from 'primevue/card'
  import Button from 'primevue/button'
  import Dialog from 'primevue/dialog'
  import InputText from 'primevue/inputtext'
  import FileIconComponent from '@/components/FileIconComponent.vue'
  import { useToast } from 'primevue/usetoast'
  import Menu from 'primevue/menu'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import api from '@/services/api'
  import { INote } from '@/models/note/interfaces'
  import { i18n } from '@/i18n'
  // import { useBreakpointsStore } from '@/store'
  // import { storeToRefs } from 'pinia'
  import suggestion from '@/components/mentions/suggestion'
  import Mention from '@tiptap/extension-mention'
  import { Editor, EditorContent } from '@tiptap/vue-3'
  import StarterKit from '@tiptap/starter-kit'
  import Avatar from 'primevue/avatar'
  import ContactSheet from '@/components/ContactSheet.vue'
  import { captureException } from '@sentry/vue'
  import { separateInfo } from '@/utils/separateInfo'
  import { computed } from 'vue'

  interface IProps {
    note: INote
  }

  const emit = defineEmits(['updateNote', 'deleteNote', 'replyNote'])
  const props = defineProps<IProps>()
  const { note } = toRefs(props)

  const contactInfoVisible = ref(false)
  const editing = ref(false)
  const editLoading = ref(false)
  const displayConfirmation = ref(false)
  const deleteLoading = ref(false)

  const editor = ref()

  const menu = ref()
  const menuItems = [{ label: i18n.global.t('noteComponent.reply'), command: () => emit('replyNote', note.value) }]

  const toast = useToast()

  async function toggleVisibility() {
    contactInfoVisible.value = !contactInfoVisible.value
  }

  function cancelEditing() {
    editor.value.commands.setContent(props.note.Note_Content)
    toggleEditing()
  }

  function toggleEditing() {
    if (editing.value) {
      editor.value.options.editable = false
      editor.value.options.element.firstChild.classList.remove('p-inputtext')
    } else {
      editor.value.options.editable = true
      editor.value.options.element.firstChild.classList.add('p-inputtext')
    }
    editing.value = !editing.value
  }

  async function editNote() {
    try {
      editLoading.value = true

      const note_title = document.getElementById('note_title') as HTMLInputElement

      const Note_Title = note_title.value

      const html = editor.value.getHTML()

      await api.put(`/Api/Notes/${note.value.id}`, {
        Note_Title,
        Note_Content: html,
      })

      emit('updateNote', {
        id: note.value.id,
        Note_Title,
        Note_Content: html,
      })

      toast.add({ severity: 'success', summary: 'Success', detail: 'Note edited', life: 3000 })

      editLoading.value = false
      toggleEditing()
    } catch (err) {
      captureException(err)
      editLoading.value = false
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error editing note', life: 3000 })
    }
  }

  function toggleConfirmation() {
    displayConfirmation.value = !displayConfirmation.value
  }

  async function confirmDeletion() {
    try {
      deleteLoading.value = true

      await api.delete(`/Api/Notes/${note.value.id}`)

      emit('deleteNote', { id: note.value.id })

      toast.add({ severity: 'success', summary: 'Success', detail: 'Note deleted', life: 3000 })

      deleteLoading.value = false
      toggleConfirmation()
    } catch (err) {
      captureException(err)
      deleteLoading.value = false
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error deleting note', life: 3000 })
    }
  }

  function toggleMenu(event: MouseEvent) {
    menu.value.toggle(event)
  }

  function renderTimeStamp() {
    const date = useDateFormatUtil(note.value.Created_Time, 'MMM D, YYYY h:mm A')
    return date
  }

  onMounted(async () => {
    const CustomMention = Mention.extend({
      addAttributes() {
        return {
          'data-type': {
            parseHTML: (element) => element.getAttribute('data-type'),
            renderHTML() {
              return { 'data-type': 'mention' }
            },
          },
          zuid: {
            parseHTML: (element) => element.getAttribute('zuid'),
            renderHTML(attributes) {
              return { zuid: attributes.zuid }
            },
          },
          fullname: {
            parseHTML: (element) => element.getAttribute('fullname'),
            renderHTML(attributes) {
              return { fullname: attributes.fullname }
            },
          },
          id: {
            parseHTML: (element) => element.getAttribute('id'),
            renderHTML(attributes) {
              return { id: attributes.id }
            },
          },
        }
      },
    }).configure({
      HTMLAttributes: { class: 'mention' },
      suggestion,
      renderLabel({ options, node }) {
        return `${options.suggestion.char}${node.attrs.fullname ?? node.attrs.id}`
      },
    })

    if (props.note.Note_Content) {
      editor.value = new Editor({ extensions: [StarterKit, CustomMention], content: separateInfo(props.note.Note_Content).info, editable: false })
    }
  })

  function onKeydownEditor(e: KeyboardEvent) {
    if (!e.shiftKey && e.key === 'Enter') {
      editNote()
    }
  }

  onUnmounted(() => {
    if (editor.value) editor.value.destroy()
  })
  const image_profile = computed(() => {
    return 'https://contacts.zoho.com/file?ID=' + note.value?.Owner?.zuid ?? '' + '&fs=thumb'
  })
</script>

<template>
  <Card class="w-full">
    <template #header>
      <header class="w-full flex flex-row p-2 max-w-100" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
        <div class="flex flex-row w-full text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: calc(100% - 42px)">
          <Avatar :image="image_profile" size="large" shape="circle" />
          <div class="flex flex-column w-full ml-2" style="max-width: calc(100% - 46px)">
            <p class="font-bold text-lg m-0 text-overflow-ellipsis white-space-nowrap overflow-hidden cursor-pointer block" style="color: #3b82f6" @click="toggleVisibility">
              {{ note.Owner.name }}
            </p>
            <p class="text-sm" style="color: rgba(0, 0, 0, 0.45)">{{ renderTimeStamp() }}</p>
          </div>
        </div>
        <div v-if="!separateInfo(note.Note_Content).author" class="flex flex-row align-items-center justify-content-end gap-2">
          <Button class="btn p-button-text p-button-secondary p-button-rounded p-2" @click="toggleMenu">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
              />
            </svg>
          </Button>
          <PermissionRole :nodo-name="'projectsNotePermissions'" :key-name="'create'">
            <Menu ref="menu" :model="menuItems" :popup="true" />
          </PermissionRole>
        </div>
      </header>
    </template>

    <template v-if="note.Note_Title || editing" #title>
      <span v-if="note.Note_Title">{{ note.Note_Title }}</span>
      <span v-if="editing" class="w-full block">
        <label for="Note_Title" class="w-full block label">{{ $t('noteComponent.noteTitle') }}</label>
        <InputText id="note_title" name="Note_Title" type="text" class="w-full block" :model-value="note.Note_Title" />
      </span>
    </template>

    <template #content>
      <div class="w-full flex flex-column gap-2">
        <div v-if="separateInfo(note.Note_Content).author" class="w-full flex flex-column gap-1 container-reply">
          <p class="font-bold text-sm m-0 text-overflow-ellipsis white-space-nowrap overflow-hidden block" style="color: #3b82f6">
            {{ separateInfo(note.Note_Content).author }}
          </p>
          <p class="text-xs" style="color: #aeaeae">{{ separateInfo(note.Note_Content).valor }}</p>
        </div>
        <span class="w-full block gap-1">
          <label v-if="editing" for="edit_note_content" class="w-full block label">{{ $t('noteComponent.noteContent') }}</label>
          <EditorContent :editor="editor" name="edit_note_content" class="flex-grow-1 block" @keydown="onKeydownEditor" />

          <div v-if="editing" class="flex flex-row align-items-center justify-content-end gap-2 pt-2">
            <Button :label="i18n.global.t('noteComponent.cancel')" class="p-button-danger p-button-outlined" @click="cancelEditing" />

            <Button :label="i18n.global.t('noteComponent.submit')" :loading="editLoading" :disabled="editLoading" @click="editNote" />
          </div>
        </span>

        <div v-if="note.isCheckIn">
          <span class="checkin">
            <div>
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                />
              </svg>
            </div>
            <span>
              {{ note.Parent_Id && note.Parent_Id.name }}
            </span>
          </span>
        </div>

        <div v-if="!editing && ((note.$attachments && note.$attachments.length > 0) || note.$voice_note)" class="flex flex-row gap-2">
          <FileIconComponent v-for="attachment in note.$attachments" :key="attachment.id" :name="attachment.File_Name" :path="attachment.$link_url" />

          <FileIconComponent v-if="note.$voice_note" :name="note.$voice_note.file_name" :path="note.$voice_note.$link_url" />
        </div>
      </div>
    </template>
  </Card>

  <Dialog
    v-model:visible="contactInfoVisible"
    :pt="{
      mask: {
        style: {
          zIndex: 1211,
        },
      },
    }"
    :modal="true"
    :auto-z-index="false"
    :base-z-index="1210"
    :header="note?.Owner.name"
    class="w-full m-0"
    style="max-width: 42rem"
    :draggable="false"
    :position="'center'"
  >
    <template #header>
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <Avatar :image="image_profile" shape="circle" />
        <span class="font-bold white-space-nowrap">{{ note?.Owner.name }}</span>
      </div>
    </template>
    <ContactSheet
      :project-contact="{
        ...note.Owner,
        fullName: note?.Owner.name,
        phone: note?.Owner.phonenumber,
      }"
    />
  </Dialog>

  <Dialog v-model:visible="displayConfirmation" header="Confirm Deletion" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '350px' }" :modal="true">
    <div class="confirmation-content">
      <span>{{ $t('noteComponent.areYouSure') }}</span>
    </div>
    <template #footer>
      <Button :label="i18n.global.t('noteComponent.cancel')" class="p-button-outlined" @click="toggleConfirmation" />
      <Button :label="i18n.global.t('noteComponent.confirm')" class="p-button-danger" @click="confirmDeletion" />
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
  .container-reply {
    background-color: #83aff745;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    padding: 5px 10px;
    border-radius: 10px;
    /* #d1ddf1 */
    /* #83aff745 */
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
  }
  .label {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .note_content {
    white-space: pre-wrap;
  }
  .checkin {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    border: 1px solid green;
    border-radius: 8px;
    overflow: hidden;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: green;
      color: white;
      padding: 4px;
    }

    span {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
</style>
