<script lang="ts" setup>
  import { onMounted, ref } from 'vue'
  import api from '@/services/api'
  import ProjectProblemsAdd from './ProjectProblemsAdd.vue'
  import Button from 'primevue/button'
  import ProjectProblem from './ProjectProblem.vue'
  import { useBreakpointsStore, useProjectStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { IProblem } from '@/interfaces/problem/problem.interface'
  import { captureException } from '@sentry/vue'
  import DetailProblem from './DetailProblem.vue'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { i18n } from '@/i18n'

  const props = defineProps({ projectId: { type: String, required: true } })
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const projectStore = useProjectStore()
  const { setProjectProblems } = projectStore
  const { project } = storeToRefs(projectStore)
  const problems = ref<IProblem[]>([])
  const problemItem = ref<any>()
  const createOpen = ref(false)
  const showProblem = ref(false)

  const { t } = i18n.global

  function toggleCreateDialog() {
    createOpen.value = !createOpen.value
  }

  async function fetchProblems() {
    try {
      const {
        data: { data: resp },
      } = await api.get(`/Api/Projects/${props.projectId}/Problems`)
      problems.value = [...resp]
      setProjectProblems(resp)
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function updateProblems() {
    fetchProblems()
    // problems.value = [...problems.value, resp]
    // setProjectProblems([...problems.value, resp])
    createOpen.value = false
  }

  async function updateProblem(editedProblem: any) {
    const problem = problems.value.find((p) => p.id === editedProblem.id)
    if (!problem) return

    problem.problemStatus = editedProblem.problemStatus
    setProjectProblems(problems.value)
  }

  function removeProblem(id: string) {
    problems.value = problems.value.filter((p) => p.id !== id)
    setProjectProblems(problems.value)
  }

  onMounted(async () => {
    await fetchProblems()
  })

  const handleShowProblem = (problem: any) => {
    showProblem.value = true
    problemItem.value = problem
  }

  const handleHideProblem = () => {
    showProblem.value = false
    problemItem.value = null
  }
</script>

<template>
  <div v-if="!showProblem" class="col-12">
    <div class="flex align-items-center justify-content-between title-tab">
      <div class="flex text-xl align-items-center">
        <div class="flex pr-2">
          <i class="pi pi-wrench" />
        </div>
        <div class="flex text-xl">{{ $t('projectProblems.problems') }}</div>
      </div>

      <PermissionRole v-if="!project.isClosed" nodo-name="projectsProblemsPermissions" key-name="create">
        <div class="flex">
          <Button :label="i18n.global.t('projectProblems.create')" icon="pi pi-plus" class="p-button-text" @click="toggleCreateDialog" />
        </div>
      </PermissionRole>
    </div>
  </div>

  <div v-if="!showProblem" class="grid w-full">
    <ProjectProblem
      v-for="problem in problems"
      :key="problem.id"
      :problem="problem"
      :project-id="props.projectId"
      @update-problem="updateProblem"
      @remove-problem="removeProblem"
      @show-problem="handleShowProblem"
    />
  </div>

  <div v-else>
    <DetailProblem :problem="problemItem" :project="project" @hide-problem="handleHideProblem"></DetailProblem>
  </div>

  <Dialog
    v-model:visible="createOpen"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0 hide-on-camera"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="t('projectProblems.createAProblem')"
  >
    <ProjectProblemsAdd :problems="problems" :project-id="props.projectId" @update-problems="updateProblems" />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>
</template>

<style lang="scss" scoped>
  .title-tab {
    min-height: 40px;
  }
</style>
<style lang="scss">
  .layout-save {
    padding-bottom: calc(var(--sab) + 1rem) !important;
  }
</style>
