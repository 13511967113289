export const CalendarEn = {
  days: {
    sunday: 'SUN',
    monday: 'MON',
    tuesday: 'TUE',
    wednesday: 'WED',
    thursday: 'THU',
    friday: 'FRI',
    saturday: 'SAT',
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  actions: {
    cancel: 'Cancel',
    apply: 'Apply',
  },
  empty: 'Choose a date range',
}

export const CalendarEs = {
  days: {
    sunday: 'DOM',
    monday: 'LUN',
    tuesday: 'MAR',
    wednesday: 'MIÉ',
    thursday: 'JUE',
    friday: 'VIE',
    saturday: 'SÁB',
  },
  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
  },
  actions: {
    cancel: 'Cancelar',
    apply: 'Aplicar',
  },
  empty: 'Selecciona un intervalo de fechas',
}
