export const forgotPasswordEnUS = {
  forgotPassword: 'Forgot password',
  production: 'Production',
  byInfinityHomeServices: 'By Infinity Home Services',
  emailRecoveryPrompt: 'Enter your email to recover your password',
  passwordReminder: 'Do you remember your password?',
  continue: 'Continue',
  selectATenant: 'Select a tenant',
  userNameOrEmail: 'User ID',
  login: 'Login',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  code: 'Code',
  resetPassword: 'Reset password',
  home: 'Home',
  usernameoremail: 'User ID required',
  credentialsAreRequired: 'credentials are required',
  passwordsDontMatch: 'Passwords do not match',
}

export const forgotPasswordEsMX = {
  forgotPassword: 'Olvidé mi contraseña',
  production: 'Producción',
  byInfinityHomeServices: 'Por Infinity Home Services',
  emailRecoveryPrompt: 'Ingresa tu correo para recuperar tu contraseña',
  passwordReminder: '¿Recordaste tu contraseña?',
  continue: 'Continuar',
  selectATenant: 'Selecciona una empresa',
  userNameOrEmail: 'ID de usuario',
  login: 'Iniciar sesión',
  newPassword: 'Nueva contraseña',
  confirmPassword: 'Confirmar contraseña',
  code: 'Código',
  resetPassword: 'Restablecer contraseña',
  home: 'Inicio',
  usernameoremail: 'ID de usuario es requerido',
  credentialsAreRequired: 'Se requiere las credenciales',
  passwordsDontMatch: 'Las contraseñas no coinciden',
}
