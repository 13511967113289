<script lang="ts" setup>
  import ChangeLanguage, { Language } from '@/services/changeLanguage'
  import { useUserStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { DropdownChangeEvent } from 'primevue/dropdown'
  import { ref, onMounted } from 'vue'
  const languages = [
    { name: 'English', value: 'en' },
    { name: 'Español', value: 'es' },
  ]
  const userStore = useUserStore()
  const { settings } = storeToRefs(userStore)
  const langs = ref(languages)

  const currentLangUser = () => {
    const languageUser = settings.value.find((item) => item.key === 'Language')

    if (languageUser) {
      return languages.find((item) => item.value === languageUser.value) ?? languages[0]
    }
    return languages[0]
  }

  const currentLang = ref(currentLangUser())

  onMounted(() => {
    const lang = currentLangUser()
    currentLang.value = lang
    ChangeLanguage(lang.value as Language)
  })
</script>

<template>
  <div>
    <Dropdown
      v-model="currentLang"
      :options="langs"
      option-label="name"
      class="w-full"
      placeholder="Select a Language"
      @change="(event: DropdownChangeEvent) => {
        ChangeLanguage(event.value.value)
      }"
    />
  </div>
</template>
