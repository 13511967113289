import { uploadPhotoType } from '@/db/tables'

interface IUser {
  fullName: string
  photoUrl?: string
  zuid?: string
  id?: string
}

interface PhotoVariant {
  variant: 'ORIGINAL' | 'THUMBNAIL' | 'MEDIUM'
  uri: string
}

export type TPhotoContent = {
  commentCount: number
  createdTime?: string
  createdAt?: string
  description?: string
  mediaType?: 'IMAGE' | 'VIDEO'
  url: string
  id: string
  type: string
  user: IUser
  uploadType?: uploadPhotoType
  variants: PhotoVariant[]
}
export enum PhotoFormatIdx {
  sm = 1,
  md = 2,
  lg = 3,
  xl = 4,
}
export enum CloseProjectStepType {
  INITIAL = 0,
  REVIEW = 1,
  FINALIZE = 2,
}
export interface PhotoFinalPictureEvent {
  photoUrl: string
  taskId: string
  taskListId: string
}

export enum FinalizeReviewTypes {
  WORKMANSHIP = 'WORKMANSHIP',
  TIMELINESS = 'TIMELINESS',
}
