<script setup lang="ts">
  import HomeWeb from './HomeWeb.vue'
  import HomeMobile from './HomeMobile.vue'
  import { Capacitor } from '@capacitor/core'
  import { onMounted, ref } from 'vue'

  const isNativePlatform = ref(false)
  onMounted(() => {
    isNativePlatform.value = Capacitor.isNativePlatform()
  })
</script>

<template>
  <HomeMobile v-if="Capacitor.isNativePlatform()" />
  <HomeWeb v-else />
</template>
