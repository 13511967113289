import { reactive, toRefs } from 'vue'
import { TShape, TShapeTextUnit } from '../shapes/types'

const initialState = {
  text_unit: 'ABC' as TShapeTextUnit,
  ft: '',
  in: '',
  m: '',
  cm: '',
  text: '',
  id: null as string | null,
}
const state = reactive(initialState)

const handleResetText = () => {
  state.cm = ''
  state.ft = ''
  state.in = ''
  state.m = ''
  state.text = ''
  state.text_unit = 'ABC'
  state.id = null
}
const handleSetText = (shape: TShape) => {
  state.cm = shape.cm
  state.ft = shape.ft
  state.id = shape.id
  state.in = shape.in
  state.m = shape.m
  state.text = shape.text
  state.text_unit = shape.text_unit
}
export const useTextInDialog = () => {
  return {
    ...toRefs(state),
    handleResetText,
    handleSetText,
  }
}
