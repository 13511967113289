<script setup lang="ts">
  import { ref, onMounted } from 'vue'

  const targetElement = ref<HTMLElement | null>(null)
  const isVisible = ref(false)

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0]
    if (entry.isIntersecting) {
      isVisible.value = true
      intersectionObserver.disconnect()
    }
  }

  const options: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  }

  const intersectionObserver = new IntersectionObserver(handleIntersection, options)

  onMounted(() => {
    if (targetElement.value) {
      intersectionObserver.observe(targetElement.value)
    }
  })
</script>
<template>
  <div ref="targetElement">
    <slot></slot>
  </div>
</template>
