export const changePasswordEnUS = {
  actualPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  changePassword: 'Change password',
  credentialsAreRequired: 'credentials are required',
  passwordsDontMatch: 'Passwords do not match',
  passwordMinLength: 'Password must be at least 8 characters long',
}

export const changePasswordEsMX = {
  actualPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  confirmPassword: 'Confirmar contraseña',
  changePassword: 'Cambiar contraseña',
  credentialsAreRequired: 'Se requiere las credenciales',
  passwordsDontMatch: 'Las contraseñas no coinciden',
  passwordMinLength: 'La contraseña debe tener al menos 8 caracteres',
}
