<script lang="ts" setup>
  import { useCloseOutStore } from '@/store'
  import { storeToRefs } from 'pinia'

  const punchListCameraStore = useCloseOutStore()
  const { punchListHeader } = storeToRefs(punchListCameraStore)
</script>

<template>
  <h5 class="m-0 font-bold">{{ punchListHeader }}</h5>
</template>

<style lang="scss" scoped></style>
