<script lang="ts" setup>
  import { toRefs } from 'vue'
  import { formatPhoneNumber } from '@/utils/formatPhoneNumber'
  import { i18n } from '@/i18n'
  const { t } = i18n.global

  interface IContact {
    id?: string
    zuid?: string
    email: string
    firstName?: string
    fullName?: string
    lastName?: string
    mobile?: string
    phone?: string
    photo?: string
  }

  interface IProps {
    projectContact: IContact
  }

  const props = defineProps<IProps>()

  const { projectContact } = toRefs(props)

  function composeHrefPhone(str: string) {
    return str.replace(/\D/g, '')
  }

  function composeFormattedPhone(str: string) {
    return formatPhoneNumber(str)
  }
</script>

<template>
  <section v-if="projectContact !== undefined && projectContact !== null" class="flex flex-column w-full" style="gap: 1rem">
    <article v-if="projectContact?.phone && projectContact?.phone.length > 0" class="flex flex-row w-full align-items-center justify-content-between text-sm">
      <p class="m-0 font-bold">{{ t('projectId.phoneNumber') }}:</p>
      <a :href="'tel:+1' + composeHrefPhone(projectContact?.phone)" class="text-right">{{ composeFormattedPhone(projectContact?.phone) }}</a>
    </article>

    <article v-if="projectContact?.mobile && projectContact?.mobile.length > 0" class="flex flex-row w-full align-items-center justify-content-between text-sm">
      <p class="m-0 font-bold">{{ t('projectId.mobileNumber') }}:</p>
      <a :href="'tel:+1' + composeHrefPhone(projectContact?.mobile)" class="text-right">{{ composeFormattedPhone(projectContact?.mobile) }}</a>
    </article>

    <article v-if="projectContact?.email && projectContact?.email.length > 0" class="flex flex-row w-full align-items-center justify-content-between text-sm">
      <p class="m-0 font-bold">Email:</p>
      <a :href="'mailto:' + projectContact?.email" class="text-right">{{ projectContact?.email }}</a>
    </article>
  </section>
  <div v-else class="p-4 flex align-items-center justify-content-center">
    <span class="text-center font-bold">
      {{ t('projectId.contactNotFount') }}
    </span>
  </div>
</template>
