<script setup lang="ts">
  import QueueCard from '@/components/QueueCard.vue'
  import { onMounted } from 'vue'
  import { i18n } from '@/i18n'
  import { useEventBus } from '@vueuse/core'
  import { newIQueuePhoto } from '@/db/tables'
  import { onUnmounted } from 'vue'
  import { ref } from 'vue'
  import uploadNewQueuePhoto, { IeventBus } from '@/composables/uploadQueuePhoto'
  const bus = useEventBus<IeventBus>('queuePhoto')
  const dataQueuePhoto = ref<newIQueuePhoto[]>([])

  const listener = (props: IeventBus) => {
    console.log(props)
    if (props?.uploadQueue) {
      dataQueuePhoto.value = props.uploadQueue
    }
  }

  const unsubscribe = bus.on(listener)

  const { chargerQueuePhoto, uploadQueuePhoto, uploadQueuePhotos } = uploadNewQueuePhoto()
  const { t } = i18n.global

  onMounted(async () => {
    await chargerQueuePhoto()
  })

  onUnmounted(() => {
    unsubscribe()
    bus.reset()
  })
</script>

<template>
  <div class="flex flex-col flex-column">
    <header class="mt-3 flex flex-column justify-between items-center">
      <h1 class="mb-0">{{ t('settingsQueuePhoto.queuePhotosTitle') }}</h1>
      <div class="flex flex-row items-center">
        <div class="flex flex-col col">
          <p class="text-lg text-gray-500">
            {{ dataQueuePhoto.length }}
            {{ t('settingsQueuePhoto.queuePhotos').toLocaleLowerCase() }}
          </p>
        </div>
        <Button icon="pi pi-upload" class="p-button-text" :label="t('settingsQueuePhoto.uploadAll')" @click="uploadQueuePhotos" />
      </div>
    </header>

    <div v-if="(dataQueuePhoto ?? []).length > 0" class="flex flex-wrap mt-2 w-full justify-content-evenly gap-2">
      <div v-for="photo in dataQueuePhoto" :key="photo.id" class="col col-12 sm:col-6 md:col-4 flex-grow-1 p-0 my-1">
        <QueueCard :queue-photo="photo" @upload-photo="(e) => uploadQueuePhoto(e)" />
      </div>
    </div>
  </div>
</template>
