<script setup lang="ts">
  import api from '@/services/api'
  import { i18n } from '@/i18n'
  import { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
  import { IPhoto } from '@/interfaces/photo/photo.interface'
  import { onMounted, toRefs } from 'vue'
  import { ref } from 'vue'
  import { watch } from 'vue'
  const { t } = i18n.global
  interface TagObject {
    tagId: string
    tagName: string
    name: string
    description: string | null
    photoCount: number
    createTime: string
    userId: string | null
  }
  interface IPaginationInfo {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }

  interface ITagResponse {
    data: TagObject[]
    pagination: IPaginationInfo
  }

  type IProps = {
    photo: IPhoto
  }

  function filtrarDatos<T>(datos: T[], filtro: T[], key: keyof T) {
    return datos.filter((dato) => filtro.every((f) => f[key] !== dato[key]))
  }

  const props = defineProps<IProps>()
  const emit = defineEmits(['updatePhotoTags'])
  const { photo } = toRefs(props)
  const searchField = ref('')
  const tagResponse = ref<ITagResponse>({} as ITagResponse)
  const tagsFieldValue = ref<TagObject[]>([])
  const loadingGetTags = ref(false)
  const isSubmitting = ref(false)
  const editField = ref('')
  const editTagDialog = ref(false)
  const loadingEditTag = ref(false)
  const tagEdit = ref<TagObject>({} as TagObject)

  interface IFilterParams {
    skip?: number
    take?: number
    query?: string
  }

  const fechtTags = async (params?: IFilterParams) => {
    loadingGetTags.value = true
    const paramsQuery = []
    if (params?.query) paramsQuery.push(`query=${params.query}`)
    if (params?.skip || params?.skip === 0) paramsQuery.push(`&skip=${params.skip}`)
    if (params?.take) paramsQuery.push(`&take=${params.take}`)
    const response = await api.get(`/Api/PhotoFeed/Tags?${paramsQuery.join('')}`)
    loadingGetTags.value = false
    tagResponse.value = response.data.data
  }

  const submit = async () => {
    isSubmitting.value = true
    const tags = tagsFieldValue.value.map((tag) => tag.name)
    const payload = {
      names: tags,
    }
    const response = await api.post(`/Api/Photo/${photo.value.id}/Tag`, payload)
    isSubmitting.value = false
    emit('updatePhotoTags', response.data.data as IPhoto)
  }

  watch(searchField, (newValue) => {
    searchTag(newValue)
  })

  const searchTag = async (query: string) => {
    await fechtTags({ query, skip: 0, take: 50 })
  }

  onMounted(async () => {
    tagsFieldValue.value = photo.value.tags
    await fechtTags({ skip: 0, take: 50 })
  })

  const handleEmptySearch = () => {
    searchField.value = ''
  }
  const removeTag = (tag: TagObject) => {
    tagsFieldValue.value = tagsFieldValue.value.filter((t) => t.tagId !== tag.tagId)
  }

  const handleEmptyEdit = () => {
    editField.value = ''
  }
  const editTag = async () => {
    loadingEditTag.value = true
    const payload = { name: editField.value }
    await api.patch(`/Api/Photo/Tag/${tagEdit.value?.tagId}`, payload)
    editTagDialog.value = false
    loadingEditTag.value = false
    fechtTags({ skip: 0, take: 50 })
  }

  const openDialogEdit = (tag: TagObject) => {
    tagEdit.value = tag
    editField.value = tag.name
    editTagDialog.value = true
  }
</script>
<template>
  <div class="h-full flex flex-column" style="min-height: 500px">
    <div class="col-12 flex align-items-center justify-content-center">
      <div class="p-input-icon-left w-full relative p-input-icon-right">
        <i class="pi pi-search" style="width: fit-content" />
        <InputText ref="searchInput" v-model="searchField" type="text" :placeholder="t('galleryComponent.dialogTags.searchTags')" class="w-full pr-5" />
        <i v-if="loadingGetTags" class="pi pi-spin pi-spinner absolute" style="right: 10px" />
        <i v-if="!loadingGetTags && searchField.length > 0" class="pi pi-times absolute" style="right: 10px" @click="handleEmptySearch" />
      </div>
    </div>
    <div class="w-full overflow-x-scroll">
      <ul class="w-full flex list-none flex-wrap gap-2 px-0">
        <li v-for="tag in tagsFieldValue ?? []" :key="tag.tagId" class="border-1 border-solid border-gray-300 p-1 border-round-md">
          <i class="pi pi-tag" />
          {{ tag.name }}
          <Button class="p-0 border-circle" outlined icon="pi pi-times" size="small" style="height: 20px; width: 20px" @click="removeTag(tag)" />
        </li>
      </ul>
    </div>
    <div class="flex-1 overflow-y-auto">
      <ul class="w-full flex flex-column list-none flex-wrap gap-2 px-0">
        <li v-for="tag in tagResponse.data ?? []" :key="tag.tagId" class="flex w-full justify-content-between border-1 border-solid border-gray-300 p-1 border-round-md">
          <div class="flex gap-3 align-items-center">
            <Checkbox v-model="tagsFieldValue" :input-id="tag.tagId" name="category" :value="tag" />
            <label :for="tag.tagId">{{ tag.name }}</label>
          </div>
          <Button icon="pi pi-pencil" text size="small" style="height: 20px; width: 20px" @click="openDialogEdit(tag)" />
        </li>
      </ul>
    </div>
    <div class="flex justify-content-end mt-4 py-3 -mb-5 -mx-4 px-4">
      <Button label="SUBMIT" class="p-button-info" :disabled="isSubmitting" :loading="isSubmitting" @click="submit()" />
    </div>
  </div>
  <Dialog v-model:visible="editTagDialog" header=" " class="w-full m-0" position="bottom" style="max-width: 42rem" :modal="true" :draggable="false">
    <div class="w-full p-3 flex flex-column justify-content-end align-items-end">
      <div class="flex w-full align-items-center md:justify-content-end justify-content-center pb-4">
        <div class="col-12 flex align-items-center justify-content-center">
          <div class="w-full relative p-input-icon-right">
            <InputText ref="searchInput" v-model="editField" type="text" :placeholder="t('tags.createTagPlaceholder')" class="w-full pr-5" />
            <i v-if="loadingEditTag" class="pi pi-spin pi-spinner absolute" style="right: 10px" />
            <i v-if="!loadingEditTag && editField.length > 0" class="pi pi-times absolute" style="right: 10px" @click="handleEmptyEdit" />
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-2">
        <Button :label="t('tags.editButton')" class="p-button-info" :loading="loadingEditTag" @click="editTag" />
      </div>
    </div>
  </Dialog>
</template>
