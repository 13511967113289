<script setup lang="ts">
  import { onMounted, onUnmounted, toRefs } from 'vue'
  import { ICrew } from '@/interfaces/crew/Crew'
  import { i18n } from '@/i18n'
  import { useCloseOutStore } from '@/store'

  const { t } = i18n.global
  type Iprops = {
    crewsForReview: ICrew[]
  }

  const props = defineProps<Iprops>()
  const { crewsForReview } = toRefs(props)
  const closeOutStore = useCloseOutStore()
  const { isLoadingCrewsInCloseOut } = toRefs(closeOutStore)
  const { loadCrewContentInCloseout, clearLoadContentCrewsCloseOut } = closeOutStore
  onMounted(() => {
    loadCrewContentInCloseout()
  })
  onUnmounted(() => {
    clearLoadContentCrewsCloseOut()
  })
</script>
<template>
  <div class="punch-list-container flex-column gap-4" style="padding-top: 1.25rem; padding-bottom: 1.25rem">
    <p v-if="!crewsForReview?.length && !isLoadingCrewsInCloseOut">{{ t('finalizePunchLists.emptyListCrews') }}</p>
    <div
      v-for="crew in crewsForReview"
      :key="crew.id"
      style="border-radius: 8px; border: 1px solid rgba(0, 0, 0, 0.3)"
      class="w-full p-4 flex flex-column justify-content-center align-items-center gap-3 bg-white"
    >
      <div class="flex flex-column justify-content-center align-items-center gap-1">
        <span class="text-center text-lg font-semibold">{{ crew.name }}</span>
        <span v-if="crew?.manager?.fullName" class="text-center text-color-secondary">{{ crew?.manager?.fullName }}</span>
      </div>

      <div class="field w-full mb-0">
        <label for="workmanship_rating" class="w-full">{{ t('reviewsCrew.workmanship') }}:</label>
        <Rating v-model="crew.reviews[0].rating" :stars="5" :cancel="false" />
      </div>

      <div class="field w-full mb-0">
        <label for="workmanship_comments" class="w-full">{{ t('reviewsCrew.comment') }}:</label>
        <Textarea v-model="crew.reviews[0].comment" rows="4" class="w-full"></Textarea>
      </div>

      <div class="field w-full mb-0">
        <label for="timeliness_rating" class="w-full">{{ t('reviewsCrew.timeliness') }}:</label>
        <Rating v-model="crew.reviews[1].rating" :stars="5" :cancel="false" />
      </div>

      <div class="field w-full mb-0">
        <label for="timeliness_comments" class="w-full">{{ t('reviewsCrew.comment') }}:</label>
        <Textarea v-model="crew.reviews[1].comment" rows="4" class="w-full"></Textarea>
      </div>
    </div>
  </div>
</template>
