<script lang="ts" setup>
  import ImageComponent from '@/components/ImageComponent.vue'
  import LazyLoadComponent from '@/components/LazyLoadComponent.vue'
  import { uploadPhotoType } from '@/db/tables'
  import { i18n } from '@/i18n'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import {
    // ref,
    toRefs,
  } from 'vue'
  import { useRouter } from 'vue-router'
  // import { delay } from '@/utils/delay'
  // import useThumbnail from '@/composables/useThumbnail'

  interface IUser {
    fullName: string
    photoUrl: string | null
    zuid: string
    profile: string
  }

  interface IProject {
    id: string
    name: string
    type: string
    address: string
    entityType: string
  }

  interface PhotoVariant {
    variant: 'ORIGINAL' | 'THUMBNAIL' | 'MEDIUM'
    uri: string
  }

  export interface IPhotoFeed {
    id: string
    url: string
    user: IUser
    type: string
    commentCount: number
    createdTime: string
    description: string | null
    entityFeed: IProject
    uploadType?: uploadPhotoType
    variants: PhotoVariant[]
    isFavorite: boolean
    mediaType?: 'IMAGE' | 'VIDEO'
  }

  interface IProps {
    photo: IPhotoFeed
  }

  const severityTagMedia = {
    PROGRESS_PICTURES: 'info',
    PREPRODUCTION_PICTURES: 'info',
    TASK: 'warning',
    TASK_LIST: 'warning',
    TASK_AFTER: 'warning',
    TASK_BEFORE: 'warning',
    TASK_PICTURES: 'warning',
    FINAL_PICTURES: 'info',
    PROFILE_PICTURES: 'info',
    PROBLEM: 'danger',
  }

  const typePhoto = {
    PROGRESS_PICTURES: 'photoType.PROGRESS_PICTURES',
    PREPRODUCTION_PICTURES: 'photoType.PREPRODUCTION_PICTURES',
    TASK: 'photoType.TASK',
    TASK_LIST: 'photoType.TASK_LIST',
    TASK_AFTER: 'photoType.TASK_AFTER',
    TASK_BEFORE: 'photoType.TASK_BEFORE',
    TASK_PICTURES: 'photoType.TASK_PICTURES',
    FINAL_PICTURES: 'photoType.FINAL_PICTURES',
    PROFILE_PICTURES: 'photoType.PROFILE_PICTURES',
    PROBLEM: 'photoType.PROBLEM',
  }

  const { t } = i18n.global
  const props = defineProps<IProps>()
  const { photo } = toRefs(props)
  const router = useRouter()
  // const { url, failed, handleError, handleLoad, handleMountPhotos, handleResetAttempts } = useThumbnail(photo.value)

  // const handleLoad = () => {
  //   failed.value = false
  // }
  const sendEntityId = (event: MouseEvent, entity: IProject) => {
    event.preventDefault()
    event.stopPropagation()
    if (entity.entityType === 'Project') router.push(`/projects/${entity.id}/media`)
    else {
      router.push(`/leads/${entity.id}/media`)
    }
  }

  const handleIcon = (type: 'unknown' | 'camera' | 'library') => {
    return photo.value.uploadType?.toLowerCase().includes(type)
  }
</script>

<template>
  <LazyLoadComponent class="hover-main cursor-pointer masonry-item shadow-2">
    <div style="height: 100%; width: 100%">
      <figure :class="false ? 'hidden' : ''">
        <ImageComponent :id="photo.id" :disable-cache="true" :url="photo.variants?.[0]?.uri ?? photo.variants?.[1]?.uri ?? photo?.url" @onerror="() => {}" @onload="() => {}" />
        <div v-if="photo?.mediaType === 'IMAGE'" class="absolute" style="top: 5px; left: 5px; background-color: rgba(0, 0, 0, 0.5); border-radius: 6px; color: white">
          <i v-if="handleIcon('library')" class="pi pi-upload p-2"></i>
          <i v-else-if="handleIcon('camera')" class="pi pi-camera p-2"></i>
          <i v-else class="pi pi-camera p-2"></i>
        </div>
        <div v-if="photo?.mediaType === 'VIDEO'" class="absolute" style="top: 5px; left: 5px; background-color: rgba(0, 0, 0, 0.5); border-radius: 6px; color: white">
          <i class="pi pi-video p-2"></i>
        </div>
        <figcaption class="hover-child">
          <div class="flex flex-column w-full gap-1Q">
            <Button class="p-button-text p-0" @click="(e) => sendEntityId(e, photo.entityFeed)">
              <p class="font-bold text-sm m-0 text-white inline-block overflow-hidden white-space-nowrap text-overflow-ellipsis" style="max-width: 100%">
                {{ photo.entityFeed.name }}
              </p>
            </Button>
            <p class="text-xs subtext mb-0 text-overflow-ellipsis white-space-nowrap overflow-hidden">
              {{ useDateFormatUtil(photo.createdTime, 'MMM D, YYYY h:mm A') }}
            </p>
            <Tag
              :value="t(typePhoto[photo.type as keyof typeof typePhoto ?? 'PROGRESS_PICTURES'] ?? typePhoto.PROGRESS_PICTURES)"
              :severity="severityTagMedia[photo.type as keyof typeof severityTagMedia] ?? 'info'"
              class="w-fit text-xs"
            />
          </div>
        </figcaption>
      </figure>
    </div>
  </LazyLoadComponent>
</template>

<style lang="scss" scoped>
  .hover-main {
    display: block;
  }
  .hover-child {
    opacity: 0;
    transition: opacity 0.3ms ease;
  }
  .hover-main:hover .hover-child {
    opacity: 1;
  }

  .hidden {
    display: none;
  }
  .masonry-item-container {
    height: 200px;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
