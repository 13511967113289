import { AccountStore } from '@/store'
import localforage from 'localforage'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const blockBackPage = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const account = (await localforage.getItem('Account')) as AccountStore
  console.log('login', account)
  if (account?.isSignedIn) {
    history.replaceState(null, '', '/')
    return next('/')
  }
  next()
}
