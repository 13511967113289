export enum ProjectStageEnum {
  NONE = '-None-',
  READY_TO_VERIFY = 'Ready to Verify',
  CONTRACT_VERIFIED = 'Contract Verified',
  SCHEDULE_PRE_WALK_THROUGH = 'Schedule Pre Walk Through',
  PM_APPROVED = 'PM Approved',
  PO_REJECTED_BY_PM = 'PO Rejected by PM',
  CORRECTED_PO_AWAITING_APPROVAL = 'Corrected PO awaiting approval',
  SECOND_PO_REJECTED = 'Second PO Rejected',
  READY_TO_MEASURE = 'Ready to Measure',
  ATC_TO_MEASURE = 'ATC to Measure',
  MEASURE_SCHEDULED = 'Measure Scheduled',
  WINDOW_PROJECT_MEASURED_NEED_ATTENTION = 'Window Project Measured, Need Attention',
  ORDER_SENT_TO_MANUFACTURER = 'Order Sent to Manufacturer',
  MEASURE_COMPLETE = 'Measure Complete',
  WILL_CALLED_ORDERED = 'Will Called / Ordered',
  READY_FOR_PRODUCTION = 'Ready for Production',
  READY_FOR_PRODUCTION_DELIVERED = 'Ready for Production: Delivered',
  PROJECT_SCHEDULED = 'Project Scheduled',
  IN_PRODUCTION = 'In Production',
  COMPLETE = 'Complete',
  COLLECTION_SERVICE = 'COLLECTION SERVICE',
}
