import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging'
import { useToast } from 'primevue/usetoast'
import { statusDeviceToken, useDevieTokenStore } from '@/store'

export const usePushNotifications = () => {
  const toast = useToast()
  const DeviveTokenStore = useDevieTokenStore()

  const firebaseConfig = {
    apiKey: import.meta.env?.VITE_CONFIG_FIREBASE_API_KEY,
    authDomain: import.meta.env?.VITE_CONFIG_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env?.VITE_CONFIG_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env?.VITE_CONFIG_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env?.VITE_CONFIG_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env?.VITE_CONFIG_FIREBASE_APP_ID,
    measurementId: import.meta.env?.VITE_CONFIG_FIREBASE_MEASUREMENT_ID,
  }
  const keyPair = import.meta.env?.VITE_CONFIG_FIREBASE_KEY_PAIR

  const hasNotificationPermission = () => {
    try {
      return Notification.permission === 'denied'
    } catch (error) {
      return false
    }
  }

  const initializeFirebase = async () => {
    const isSupport = await isSupported()

    if (!isSupport || hasNotificationPermission()) {
      // console.error('initializeFirebase', 'not support or not permission')
      return
    }
    console.log('initializeFirebase')

    // console.log('initializeFirebase', { ...firebaseConfig, keyPair })

    const app = initializeApp(firebaseConfig)
    const messaging = getMessaging(app)
    onMessage(messaging, (payload: any) => {
      const obj = { title: payload.notification?.title ?? 'Notification', body: payload.notification?.body ?? '', link: payload?.data?.link ?? '' }
      toast.add({ severity: 'info', summary: payload.notification?.title ?? 'Notification', detail: obj, life: 50000, group: 'notification' })
    })

    try {
      const currentToken = await getToken(messaging, { vapidKey: keyPair })
      if (currentToken) {
        await statusDeviceToken(true)
        await DeviveTokenStore.setDeviceToken(currentToken)
      } else {
        await statusDeviceToken(false)
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err)
    }
  }
  return { initializeFirebase }
}
