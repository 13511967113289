export const projectStagesEnUs = {
  WILL_CALLED_ORDERED: 'Will Called / Ordered',
  READY_FOR_PRODUCTION: 'Ready for Production',
  PROJECT_SCHEDULED: 'Project Scheduled',
  IN_PRODUCTION: 'In Production',
  COMPLETE: 'Complete',
  NONE: '-None-',
  READY_TO_VERIFY: 'Ready to Verify',
  CONTRACT_VERIFIED: 'Contract Verified',
  SCHEDULE_PRE_WALK_THROUGH: 'Schedule Pre Walk Through',
  PM_APPROVED: 'PM Approved',
  PO_REJECTED_BY_PM: 'PO Rejected by PM',
  CORRECTED_PO_AWAITING_APPROVAL: 'Corrected PO awaiting approval',
  SECOND_PO_REJECTED: 'Second PO Rejected',
}

export const projectStagesEsMx = {
  WILL_CALLED_ORDERED: 'Será llamado / Ordenado',
  READY_FOR_PRODUCTION: 'Listo para producción',
  PROJECT_SCHEDULED: 'Proyecto programado',
  IN_PRODUCTION: 'En producción',
  COMPLETE: 'Completado',
  NONE: '-Ninguno-',
  READY_TO_VERIFY: 'Listo para verificar',
  CONTRACT_VERIFIED: 'Contrato verificado',
  SCHEDULE_PRE_WALK_THROUGH: 'Programar pre recorrido',
  PM_APPROVED: 'PM Aprobado',
  PO_REJECTED_BY_PM: 'PO rechazado por PM',
  CORRECTED_PO_AWAITING_APPROVAL: 'PO corregido esperando aprobación',
  SECOND_PO_REJECTED: 'Segundo PO rechazado',
}
