import { defineStore } from 'pinia'
import { UserInterface } from '@/models/user'
import api from '@/services/api'
import type { PermissionStatus } from '@capacitor/geolocation'
import ChangeLanguage from '@/services/changeLanguage'

interface geolocation {
  latitude: number
  longitude: number
}

interface IPermissions {
  [key: string]: boolean | IPermissions | any
}

interface UserStore extends UserInterface {
  geolocation: geolocation | null
  geolocationPermissions: PermissionStatus
  permissions: IPermissions
  crewCompanyId: string
  appVersion: string
  orgDetails: {
    companyName: string
    website: string
    primaryEmail: string
    streetAddress: string
    logoUrl: string
    city: string
    state: string
    zipCode: string
    phone: string
  }
}

export const useUserStore = defineStore({
  id: 'User',
  state: (): UserStore => ({
    id: '',
    email: '',
    lastName: '',
    fullName: '',
    timeZone: '',
    firstName: '',
    phoneNumber: '',
    appVersion: '',
    isActive: true,
    permissions: {},
    profile: '',
    punchLists: null,
    geolocation: null,
    marketLocations: [],
    zuid: '',
    photoUrl: null,
    zohoIntegration: false,
    crewCompanyId: '',
    settings: [],
    badgeCount: 0,
    geolocationPermissions: { location: 'prompt', coarseLocation: 'prompt' },
    orgDetails: {
      companyName: '',
      website: '',
      primaryEmail: '',
      streetAddress: '',
      logoUrl: '',
      city: '',
      state: '',
      zipCode: '',
      phone: '',
    },
  }),
  getters: {
    getFullName: (state): string => state.fullName,
  },
  actions: {
    async getUserInfo(): Promise<void> {
      const {
        data: { data: user },
      } = await api.get('/Api/User/Info')
      this.id = user.id
      this.email = user.email
      this.profile = user.profile
      this.timeZone = user.timezone
      this.lastName = user.lastName
      this.fullName = user.fullName
      this.firstName = user.firstName
      this.permissions = user.permissions
      this.punchLists = user.punchLists
      this.phoneNumber = user.phoneNumber
      this.crewCompanyId = user.crewCompanyId
      this.marketLocations = user.marketLocations
      this.zuid = user.zuid
      this.appVersion = user.appVersion
      this.photoUrl = user.photoUrl // string | null
      this.zohoIntegration = user.zohoIntegration // boolean
      this.settings = user.settings
      this.badgeCount = user?.badgeCount
      ChangeLanguage(user.settings.find((item: { key: string; value: string }) => item.key === 'Language')?.value || 'en')

      const {
        data: { data: response },
      } = await api.get('/Api/GetOrganization')

      if (user.permissions?.settingsPermissions?.seeCrewName) {
        const {
          data: { data: companyData },
        } = await api.get('/Api/GetCrewCompany')
        this.orgDetails.companyName = companyData.name
      } else {
        this.orgDetails.companyName = response.companyName
      }
      this.orgDetails.website = response.website
      this.orgDetails.primaryEmail = response.primaryEmail
      this.orgDetails.streetAddress = response.streetAddress
      this.orgDetails.logoUrl = response.logoUrl
      this.orgDetails.city = response.city
      this.orgDetails.state = response.state
      this.orgDetails.zipCode = response.zipCode
      this.orgDetails.phone = response.phone
      console.log(this.orgDetails)
    },
    async updateUserGeolocationPermissions(newPermissions: PermissionStatus) {
      this.geolocationPermissions = newPermissions
    },
    async setLanguage(language: string) {
      const currentLanguage = this.settings.find((item) => item.key === 'Language')
      if (currentLanguage) currentLanguage.value = language
      await api.patch('/Api/User/Settings', { key: 'Language', value: language })
    },
  },
})
