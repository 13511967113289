<script lang="ts" setup>
  import { toRefs } from 'vue'

  import { i18n } from '@/i18n'
  const { t } = i18n.global
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import { IProblem } from '@/interfaces/problem'
  import { useBreakpointsStore } from '@/store'
  import { storeToRefs } from 'pinia'

  interface Props {
    problem: IProblem
    descriptionType?: 'short' | 'long'
  }

  const props = defineProps<Props>()

  const { problem } = toRefs(props)
  const breakpointStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointStore)
</script>

<template>
  <figcaption class="flex-container flex-column">
    <div class="flex-item flex flex-row gap-2" :class="{ 'justify-content-between': props.descriptionType !== 'long' }">
      <strong class="w-max">Status:</strong>
      <div class="w-max">
        <Tag :severity="problem.problemStatus === 'OPEN' ? 'warning' : problem.problemStatus === 'IN_PROGRESS' ? 'info' : 'success'">
          {{ problem.problemStatus }}
        </Tag>
      </div>
    </div>
    <div class="flex-item flex flex-row gap-2" :class="{ 'justify-content-between': props.descriptionType !== 'long' }">
      <strong class="w-max">{{ t('projectProblem.type') }}:</strong>
      <div>
        <Tag severity="danger">
          {{ problem.problemType }}
        </Tag>
      </div>
    </div>
    <div class="flex-item flex-column">
      <strong class="w-max">{{ t('projectProblem.description') }}:</strong>
      <div
        class="overflow-hidden white-space-normal text-overflow-ellipsis description md:miclass"
        :class="{ 'long-description': props.descriptionType === 'long', 'height-item': md && props.descriptionType !== 'long' }"
      >
        {{ problem.description }}
      </div>
    </div>
    <div class="flex-item flex-column">
      <strong class="w-max">{{ t('projectProblem.assignedTo') }}:</strong>
      <div>
        {{ problem?.assignedTo?.fullName ?? '' }}
      </div>
    </div>
    <div class="flex-item flex-column">
      <strong class="w-max">{{ t('projectProblem.assignedDate') }}:</strong>
      <div>
        {{ useDateFormatUtil(problem.assignedDate, 'MMM D, YYYY h:mm A') }}
      </div>
    </div>
  </figcaption>
</template>

<style lang="scss" scoped>
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .height-item {
    min-height: 2.5rem;
  }
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Número máximo de líneas a mostrar */
    -webkit-box-orient: vertical;
  }
  .long-description {
    -webkit-line-clamp: unset;
  }
</style>
