<script lang="ts" setup>
  import { i18n } from '@/i18n'
  import { toRefs, ref } from 'vue'

  interface ICrewUser {
    email: string
    firstName: string
    fullName: string
    id: string
    lastName: string
    marketLocations: string[]
    phoneNumber: string
  }

  interface IProps {
    isAuthorized?: boolean
    crewUsers: ICrewUser[]
  }

  const renderPhoneHref = (phone: string) => 'tel:+1' + phone.replace(/\D/g, '')
  const { t } = i18n.global
  const props = defineProps<IProps>()
  const { crewUsers, isAuthorized } = toRefs(props)
  const menu = ref()

  const emit = defineEmits<{
    (e: 'changeCreateDialog'): void
    (e: 'changeUpdateDialog'): void
    (e: 'changeDeleteDialog'): void
    (e: 'toggleMenu', value: { crewUser: ICrewUser }): void
  }>()

  function toggleMenu(event: MouseEvent, crewUser: ICrewUser) {
    if (menu.value && menu.value.toggle) {
      menu.value.toggle(event)
      emit('toggleMenu', { crewUser: crewUser })
    } else if (menu.value && menu.value[0] && menu.value[0].toggle) {
      menu.value[0].toggle(event)
      emit('toggleMenu', { crewUser: crewUser })
    }
  }
  const menuItems = [
    { label: t('crewUser.menuEdit'), command: () => emit('changeUpdateDialog') },
    { label: t('crewUser.menuDelete'), command: () => emit('changeDeleteDialog') },
  ]
</script>

<template>
  <div class="flex w-full flex-wrap">
    <div v-for="crewUser in crewUsers" :key="crewUser.id" class="col col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3">
      <div class="flex w-full justify-content-start flex-wrap">
        <div class="p-card w-full p-3 flex-column bg-white">
          <div class="flex justify-content-between align-items-center flex-column gap-1">
            <div class="flex align-items-center flex-column gap-2 justify-content-center relative w-full">
              <Avatar :image="`https://contacts.zoho.com/file?ID=${crewUser.id}&fs=thumb`" size="large" shape="circle" />
              <div class="absolute" style="top: -11px; right: -11px; z-index: 1">
                <Button v-if="isAuthorized" style="width: 1rem; background-color: transparent; border: none" @click="toggleMenu($event, crewUser)">
                  <i class="pi pi-ellipsis-v" style="color: #495057" />
                </Button>
                <Menu ref="menu" :model="menuItems" :popup="true" />
              </div>
              <div class="flex flex-column align-items-center gap-1">
                <div class="text-muted font-normal text-xl">{{ crewUser.fullName }}</div>
              </div>
            </div>
            <div class="flex flex-column flex-nowrap justify-content-end w-full">
              <a
                v-if="crewUser.email"
                :href="`mailto:${crewUser.email}`"
                class="p-button-rounded p-button-tex w-12 h-12 text-lg flex flex-row align-items-center justify-content-center"
              >
                <i class="pi pi-envelope" />
                <span class="text-lg pl-3" style="max-width: 80%; text-overflow: ellipsis; overflow: hidden; display: block">
                  {{ crewUser.email }}
                </span>
              </a>
              <a
                v-if="crewUser.phoneNumber"
                :href="renderPhoneHref(crewUser.phoneNumber)"
                class="p-button-rounded p-button-text text-lg w-12 h-12 px-3 flex flex-row align-items-center justify-content-center"
              >
                <i class="pi pi-phone" />
                <span class="text-lg pl-3" style="max-width: 80%; text-overflow: ellipsis; overflow: hidden; display: block">
                  {{ crewUser.phoneNumber }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
