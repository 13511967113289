import { Device } from '@capacitor/device'

const isTabletAndroid = async () => {
  const deviceInfo = await Device.getInfo()
  const isAndroidPlatform = deviceInfo.platform === 'android'
  const isMobileUserAgent = window.navigator.userAgent.toLowerCase().includes('mobile')

  return isAndroidPlatform && !isMobileUserAgent
}
export default isTabletAndroid
