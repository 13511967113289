<script lang="ts" async setup>
  import { Capacitor } from '@capacitor/core'
  import { reactive, ref } from 'vue'
  import { useAccountStore } from '@/store'
  import { Haptics, ImpactStyle } from '@capacitor/haptics'
  import { i18n } from '@/i18n'
  import axios from 'axios'
  import { baseUrl } from '@/constants'
  import { storeToRefs } from 'pinia'
  import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator'
  import { MaybeComputedRef } from '@vueuse/shared'
  import { Rules } from 'async-validator'
  const { t } = i18n.global
  const emit = defineEmits(['submitSuccess', 'submitError'])
  const accountStore = useAccountStore()
  const { accessToken } = storeToRefs(accountStore)
  const errorMessage = ref<null | string>(null)

  interface IField {
    value?: string
    labelText: string
    isDisabled: boolean
  }

  interface IButton {
    labelText: string
    icon: string
    isActive: boolean
    isLoading: boolean
    isDisabled: boolean
  }

  interface IChangePassword {
    newPassword: IField
    confirmPassword: IField
    submit_button: IButton
  }

  const form = reactive({ newPassword: '', confirmPassword: '' })

  const rules: MaybeComputedRef<Rules> = {
    newPassword: [
      { required: true, message: t('changePassword.credentialsAreRequired') },
      {
        min: 8,
        message: t('changePassword.passwordMinLength'),
      },
    ],
    confirmPassword: [
      {
        required: true,
        message: t('changePassword.credentialsAreRequired'),
      },
      {
        validator: (rule, value, callback, source) => {
          return value === source.newPassword
        },
        message: t('changePassword.passwordsDontMatch'),
      },
    ],
  }

  const ChangePassword: IChangePassword = reactive({
    newPassword: {
      labelText: t('changePassword.newPassword'),
      isDisabled: false,
    },
    confirmPassword: {
      labelText: t('changePassword.confirmPassword'),
      isDisabled: false,
    },

    submit_button: {
      labelText: t('changePassword.changePassword'),
      icon: 'pi pi-chevron-right',
      isActive: false,
      isLoading: false,
      isDisabled: false,
    },
  })

  const { errorFields, pass } = useAsyncValidator(form, rules)
  async function handleSubmit() {
    try {
      ChangePassword.submit_button.isLoading = true
      if (Capacitor.isNativePlatform()) await Haptics.impact({ style: ImpactStyle.Medium })
      console.log(errorFields.value)
      console.log(pass.value)

      if (!pass.value) {
        const errorMessages = errorFields.value
        const errorMessagesNewPassword = errorMessages?.newPassword
        const errorMessagesConfirmPassword = errorMessages?.confirmPassword
        if (errorMessagesNewPassword) {
          errorMessage.value = errorMessagesNewPassword[0].message ?? ''
        } else if (errorMessagesConfirmPassword) {
          errorMessage.value = errorMessagesConfirmPassword[0].message ?? ''
        }

        ChangePassword.submit_button.isLoading = false
        return
      }

      errorMessage.value = null

      const payload = {
        password: form.newPassword,
        confirmPassword: form.confirmPassword,
      }

      await axios.post(`${baseUrl}/Api/updatePassword`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken.value,
        },
      })

      emit('submitSuccess')
    } catch (error: any) {
      let message = 'Error Request'
      if (error.response?.data?.responseException?.exceptionMessage) message = error.response?.data?.responseException?.exceptionMessage
      // if (error.response?.data?.responseException?.exceptionMessage?.message)
      //   message

      emit('submitError', message)
    } finally {
      ChangePassword.submit_button.isLoading = false
    }
  }
</script>

<template>
  <Card class="border-round card-dimensions">
    <template #content>
      <Message v-if="errorMessage" severity="error" :closable="false" icon="pi pi-lock">{{ errorMessage }} </Message>
      <form class="w-full" @submit.prevent="handleSubmit()">
        <div class="p-input-icon-left w-full mb-3">
          <Password
            v-model="form.newPassword"
            toggle-mask
            class="w-full"
            input-class="w-full"
            :feedback="false"
            :input-props="{
              disabled: ChangePassword.newPassword.isDisabled,
            }"
            :placeholder="ChangePassword.newPassword.labelText"
            name="newPassword"
          />
        </div>
        <div class="p-input-icon-left w-full mb-3">
          <i class="pi pi-user" />
          <Password
            v-model="form.confirmPassword"
            toggle-mask
            class="w-full"
            input-class="w-full"
            :feedback="false"
            :input-props="{
              disabled: ChangePassword.confirmPassword.isDisabled,
            }"
            :placeholder="ChangePassword.confirmPassword.labelText"
            name="confirmPassword"
          />
        </div>

        <div class="flex gap-3">
          <Button
            type="submit"
            class="w-full"
            icon-pos="right"
            :icon="ChangePassword.submit_button.icon"
            :label="ChangePassword.submit_button.labelText"
            :loading="ChangePassword.submit_button.isLoading"
            :disabled="ChangePassword.submit_button.isDisabled"
          />
        </div>
      </form>
    </template>
  </Card>
</template>

<style lang="scss" scoped>
  .card-dimensions {
    min-width: 450px;
    max-width: 450px;
    @media (max-width: 470px) {
      min-width: 90%;
      width: 90%;
    }
  }
  .grid {
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
  }
</style>
