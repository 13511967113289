<script lang="ts" setup>
  import { ref, type Ref } from 'vue'
  import Dropdown from 'primevue/dropdown'
  import Textarea from 'primevue/textarea'
  import Button from 'primevue/button'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import { i18n } from '@/i18n'
  import api from '@/services/api'
  import { ProblemTypeOptions } from '@/interfaces/problem'
  import { IUser } from '@/interfaces/user/user.interface'
  import { useToast } from 'primevue/usetoast'
  import { captureException } from '@sentry/vue'
  import { useEventBus } from '@vueuse/core'
  import { ICamera } from '@/components/PunchListCamera/Camera/CameraContainer.vue'
  import { Capacitor } from '@capacitor/core'
  import { onMounted } from 'vue'
  import { Device } from '@capacitor/device'
  import UploadButtonComponent from '@/components/UploadButtonComponent.vue'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import PhotoList from '@/components/PhotosList/PhotoList.vue'
  import { IQueuePhoto } from '@/db/tables'
  import processMediaInputAndGetMediaIds from '@/utils/proccessMediaInputAndGetIds'
  import Editor from '@/components/editor_images/Editor.vue'

  type IProps = { projectId: string }

  const { t } = i18n.global
  const emit = defineEmits(['updateProblems'])
  const props = defineProps<IProps>()
  const toast = useToast()
  const problemType: Ref<{ value: string; label: string } | null> = ref(null)
  const device = ref<'ios' | 'android' | 'web' | 'mac' | 'win'>('web')
  const description: Ref<string> = ref('')

  const problemsMediaList = ref<IQueuePhoto[]>([])
  const assignedToUser: Ref<IUser | null> = ref(null)
  const assignedDate: Ref<string | undefined> = ref(undefined)
  const latitude: Ref<string | null> = ref(null)
  const longitude: Ref<string | null> = ref(null)
  const assigneesSuggestions: Ref<any[]> = ref([])
  const isSubmitting = ref(false)
  const camera = useEventBus<ICamera>('camera')
  const closeCamera = async (photos: IQueuePhoto[]) => {
    const uploadPhotoType = await devicePhotoTag('CAMERA')
    const cameraPhotos = photos.map((photo) => ({ ...photo, uploadPhotoType })) as IQueuePhoto[]

    const problemsPhotos = problemsMediaList.value.filter((photo) => {
      return photo.uploadPhotoType?.includes('LIBRARY')
    }) as IQueuePhoto[]
    problemsMediaList.value = [...problemsPhotos, ...cameraPhotos]
  }

  function captureImage() {
    const problemsPhotosCamera = problemsMediaList.value.filter((photo) => {
      return photo.uploadPhotoType?.includes('CAMERA')
    })
    camera.emit({
      propsCamera: { projectId: props.projectId, photoType: PhotoTypeEnum.PROBLEM },
      cameraOptions: { photos: problemsPhotosCamera },
      actions: { closeCamera: closeCamera },
    })
  }

  async function getUsersToAssign(searchTerm: string): Promise<any[]> {
    const {
      data: { data: resp },
    } = await api.get(`/Api/Projects/${props.projectId}/Users/PunchList?&query=${searchTerm}`)
    return resp
  }

  async function searchUsersToAssign($event: any) {
    const term = $event.query
    assigneesSuggestions.value = await getUsersToAssign(term)
  }

  async function submitProblem() {
    try {
      if (assignedToUser.value && problemType.value) {
        isSubmitting.value = true
        const mediaIds = await processMediaInputAndGetMediaIds(problemsMediaList.value)
        const payload = {
          projectId: props.projectId,
          problemType: problemType.value.value,
          description: description.value,
          photoBeforeIds: mediaIds,
          assignedToId: assignedToUser.value.id,
          assignedDate: assignedDate.value,
          latitude: latitude.value,
          longitude: longitude.value,
        }
        const {
          data: { data: resp },
        } = await api.post('/Api/Problem', payload)

        isSubmitting.value = false

        emit('updateProblems', resp)

        toast.add({ severity: 'success', summary: t('projectProblemsAdd.success'), detail: t('projectProblemsAdd.successCreated'), life: 3000 })
      } else {
        toast.add({ severity: 'error', summary: t('projectProblemsAdd.error'), detail: t('projectProblemsAdd.errorCreating'), life: 3000 })
        isSubmitting.value = false
      }
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: t('projectProblemsAdd.error'), detail: t('projectProblemsAdd.errorCreating'), life: 3000 })
      isSubmitting.value = false
    }
  }

  onMounted(async () => {
    if (Capacitor.isNativePlatform()) {
      const info = await Device.getInfo()
      device.value = info.platform
    }

    if (!Capacitor.isNativePlatform()) {
      const userAgent = navigator.userAgent.toLowerCase()
      const isWindows = userAgent.includes('win')
      if (isWindows) device.value = 'win'
      const isMac = userAgent.includes('mac')
      if (isMac) device.value = 'mac'
    }
  })

  const handleImages = (media: IQueuePhoto[]) => {
    problemsMediaList.value = [...problemsMediaList.value, ...media]
  }

  const showDialogEditor = ref(false)
  const initialEditorImageSelected = {
    photoId: '',
    photoOURL: '',
    annotation: null,
  }
  const editor_image_selected = ref<{ photoId: string; photoOURL: string; annotation: string | null }>(initialEditorImageSelected)

  const handleCloseEditor = () => {
    editor_image_selected.value = initialEditorImageSelected
    showDialogEditor.value = false
  }
  const handleSaveEditor = (data: { base_64: string; annotation: string; photoId: string }) => {
    const newPhotoTaskPunch =
      problemsMediaList.value?.map?.((photo) => (photo.id === data?.photoId ? { ...photo, base64_annotation: data?.base_64, annotation: data?.annotation } : photo)) ?? []
    problemsMediaList.value = newPhotoTaskPunch
    editor_image_selected.value = initialEditorImageSelected
    showDialogEditor.value = false
  }
  const removePhoto = (id: string) => {
    const photosPunchList = problemsMediaList.value?.filter?.((photo) => photo?.id !== id)
    problemsMediaList.value = photosPunchList
  }
  const handleClickEditPhoto = (photo: IQueuePhoto) => {
    editor_image_selected.value = {
      photoId: photo.id,
      annotation: photo.annotation ?? null,
      photoOURL: photo.base64 ?? '',
    }
    showDialogEditor.value = true
  }
</script>

<template>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column gap-2">
      <label for="assignToUser">{{ t('projectProblemsAdd.assignedToUser') }}</label>
      <AutoComplete
        v-model="assignedToUser"
        class="w-full"
        option-label="fullName"
        :placeholder="t('projectPunchListAdd.assignToUserPlaceholder')"
        :dropdown="true"
        force-selection
        :suggestions="assigneesSuggestions"
        @complete="searchUsersToAssign($event)"
      >
        <template #item="slotProps">
          <div class="flex justify-content-start align-items-center">
            <div>
              <Avatar :image="`https://contacts.zoho.com/file?ID=${slotProps.item.zuid}&fs=thumb`" class="mr-2" size="large" shape="circle" />
            </div>
            <div>
              <div class="ml-2 text-base">{{ slotProps.item.fullName }}</div>
              <div class="ml-2 text-muted text-sm">
                {{ slotProps.item.profile }}
                <span v-if="slotProps.item.crewCompany"> - {{ slotProps.item.crewCompany }}</span>
              </div>
            </div>
          </div>
        </template>
      </AutoComplete>
    </div>
    <div class="w-full flex flex-column gap-2">
      <label for="problemType" class="w-full m-0">{{ t('projectProblemsAdd.type') }}</label>
      <Dropdown id="problemType" v-model="problemType" name="problemType" class="w-full" :options="ProblemTypeOptions" option-label="label" />
    </div>

    <div class="w-full flex flex-column gap-2">
      <label for="description" class="w-full m-0">{{ t('projectProblemsAdd.description') }}</label>
      <Textarea id="description" v-model="description" name="description" class="flex-grow-1 w-full" :auto-resize="false" rows="4"></Textarea>
      <div class="flex justify-content-end">
        <!-- <Button
          v-tooltip.bottom="'Camera'"
          :rounded="false"
          icon="pi pi-camera"
          :label="Capacitor.isNativePlatform() ? '' : t('projectMedia.capture')"
          class="p-button p-component RemoveText p-button-text CreateProblemTakePhotoButtonText"
          @click="captureImage"
        /> -->
        <UploadButtonComponent
          :rounded-button="false"
          entity-type="Project"
          photo-type="PROBLEM"
          :project-id="props.projectId"
          :label-button="Capacitor.isNativePlatform() ? '' : t('projectMedia.upload')"
          class-button="p-button-text ButtonInputFile"
          @on-media="handleImages"
        />
      </div>
    </div>

    <div class="flex flex-row w-full gap-2 align-items-center justify-start">
      <Editor
        v-if="showDialogEditor"
        :available-annotations="false"
        :photoid="editor_image_selected?.photoId"
        :photourl="editor_image_selected?.photoOURL"
        :annotation="editor_image_selected?.annotation"
        @close="handleCloseEditor"
        @save="handleSaveEditor"
      />
      <!-- <EditImageIndex v-if="isEditSelectedImage" :photo="selectedImage" @close-edit="(values) => handleCloseEditImage(values?.image)" /> -->
      <PhotoList :photos="problemsMediaList" @edit-photo="handleClickEditPhoto" @remove="removePhoto" />
    </div>

    <div class="flex justify-content-end">
      <Button :label="t('projectProblems.create')" class="w-full" :disabled="isSubmitting" :loading="isSubmitting" @click="submitProblem" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 577px) {
    .CreateProblemTakePhotoButtonText {
      font-size: 0;
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
