<script lang="ts" setup>
  import api from '@/services/api'
  import { onMounted, Ref, ref, watch } from 'vue'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import ProjectCardComponent from '@/components/ProjectCardComponent.vue'
  import LoadingProjectCard from '@/components/LoadingProjectCard.vue'
  import { IOption } from '@/interfaces/option/option.interface'
  import { projectTypes } from '@/options/project/project-types.options'
  import { projectStages } from '@/options/project/project-stage.options'
  import { hasObjectWithValue } from '@/utils/hasObjectWithLabel'
  import Sidebar from 'primevue/sidebar'
  import AutoComplete from 'primevue/autocomplete'
  import { i18n } from '@/i18n'
  import { captureException } from '@sentry/vue'
  import PullRefresh from '@/components/PullRefresh.vue'
  import InfiniteLoading from 'v3-infinite-loading'
  import 'v3-infinite-loading/lib/style.css'
  // import QuickContacsVue from '@/components/QuickContacs.vue'

  interface IPagination {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }

  const { t } = i18n.global
  const projectsNearMeLoading: Ref<boolean> = ref(false)
  const projectsNearMe: Ref<any[]> = ref([])
  const projectsFilterIsVisible = ref(false)
  const typeValue: Ref<IOption | null> = ref(null)
  const stageValue: Ref<IOption | null> = ref(null)
  const loading = ref(false)
  const filteredTypes: Ref<IOption[]> = ref(projectTypes)
  const filteredStages: Ref<IOption[]> = ref(projectStages)
  const takeValue = ref(6)
  const skipValue = ref(0)
  const pagination = ref<IPagination>({
    pageSize: 0,
    pageNumber: 0,
    pageCount: 0,
    rowCount: 0,
    nextPage: null,
  })

  function toggleFilterDrawer() {
    projectsFilterIsVisible.value = !projectsFilterIsVisible.value
  }

  async function updateType($event: any) {
    try {
      const { query } = $event
      filteredTypes.value = projectTypes.filter((pt) => pt.value.includes(query.toUpperCase()))
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function typeSelected($event: any) {
    try {
      typeValue.value = $event.value
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function updateStage($event: any) {
    try {
      const { query } = $event
      filteredStages.value = projectStages.filter((ps) => ps.value.includes(query.toUpperCase()))
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function stageSelected($event: any) {
    try {
      stageValue.value = $event.value
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function updateProjects() {
    try {
      await fetchProjects({
        projectType: typeValue.value?.value,
        projectStage: stageValue.value?.value,
      })
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  function resetValue(type: 'type' | 'stage') {
    if (type === 'type') {
      typeValue.value = null
    } else if (type === 'stage') {
      stageValue.value = null
    }
  }

  watch([skipValue, takeValue], () => {
    fetchProjects({
      projectType: typeValue.value?.value,
      projectStage: stageValue.value?.value,
    })
  })

  watch(typeValue, async (newValue, oldValue) => {
    if (newValue !== oldValue && (!newValue || newValue.label.length === 0 || hasObjectWithValue(projectTypes, 'value', newValue.value))) {
      resetPagination()
      await updateProjects()
    }
  })

  watch(stageValue, async (newValue, oldValue) => {
    if (newValue !== oldValue && (!newValue || newValue.label.length === 0 || hasObjectWithValue(projectStages, 'value', newValue.value))) {
      resetPagination()
      await updateProjects()
    }
  })

  const resetPagination = () => {
    projectsNearMe.value = []
    skipValue.value = 0
    takeValue.value = 6
  }

  async function fetchProjects({ projectType, projectStage }: { projectType?: string; projectStage?: string }) {
    try {
      projectsNearMeLoading.value = true
      // const { latitude, longitude } = await getUsersLocation()
      const { latitude, longitude } = await fetchCurrentLocationOnce()

      if (!latitude && !longitude) {
        return
      }

      const params = []
      if (latitude) params.push(`&latitude=${latitude}`)
      if (longitude) params.push(`&longitude=${longitude}`)
      params.push(`&filter.ProjectType=${projectType ?? 'ALL'}`)
      params.push(`&filter.ProjectStage=${projectStage ?? 'ALL'}`)

      // const response = await api.get(`/Api/Projects?${params.join('')}`)

      const {
        data: { data: pnm = {} },
      } = await api.get(`/Api/v2/Projects/NearByProjects?${params.join('')}&skip=${skipValue.value}&take=${takeValue.value}`)
      const data = [
        ...(pnm.projects.map((p: any) => {
          if (typeof p?.photoUrl === 'string') {
            p.photoUrl = p?.photoUrl?.length ? p?.photoUrl : ''
          }
          return p
        }) ?? []),
      ]
      projectsNearMe.value = [...projectsNearMe.value, ...data]
      pagination.value = pnm.pagination
    } catch (err) {
      captureException(err)
      console.log('err', err)
    } finally {
      projectsNearMeLoading.value = false
    }
  }

  onMounted(async () => {
    fetchProjects({}).then(() => console.log('ran'))
    const {
      data: { data: qc },
    } = await api.get('/Api/v2/Leads/NearByLeads')
    console.log('qc', qc)
  })

  const onRefresh = () => {
    resetPagination()
    fetchProjects({}).then(() => (loading.value = false))
  }
  const handleScrollPagination = () => {
    if (pagination.value.nextPage !== null) {
      skipValue.value = skipValue.value + 6
      takeValue.value = 6
    }
  }
</script>

<template>
  <div class="flex flex-column h-full p-save-bottom">
    <header class="mt-3">
      <div class="flex justify-content-between align-items-center">
        <h1 class="mb-0">{{ t('homeIndex.nearByProjects') }}</h1>
        <Button
          icon="pi pi-filter"
          class="p-button-rounded p-button-lg"
          :class="typeValue || stageValue ? '' : 'p-button-outlined'"
          style="border-style: dashed"
          @click="toggleFilterDrawer"
        />
      </div>
    </header>

    <div class="flex-1 overflow-y-scroll">
      <div class="grid mx-0 px-2 h-full">
        <PullRefresh @refresh="onRefresh">
          <template v-if="projectsNearMe.length > 0">
            <div class="flex flex-wrap mt-2 w-full justify-content-evenly gap-3 h-full">
              <div v-for="project in projectsNearMe" :key="project.id" class="col col-12 sm:col-6 md:col-4 flex-grow-1 p-0 my-1" style="min-height: 300px">
                <ProjectCardComponent :project="project" />
              </div>
            </div>
          </template>
          <template v-if="!projectsNearMeLoading">
            <div v-if="!projectsNearMe.length" class="col-12">
              <div class="card flex align-items-center justify-content-center flex-wrap col gap-2">
                <h6 class="pt-3">No Projects. Either try again later or update your filters to see more.</h6>
              </div>
            </div>
          </template>
          <div v-else class="flex flex-wrap mt-2 w-full justify-content-evenly gap-3">
            <LoadingProjectCard v-for="i in 4" :key="`Skeleton-${i}`" class="col col-12 sm:col-6 md:col-4 flex-grow-1 p-0 my-4" style="min-height: 300px" />
          </div>
          <div v-show="pagination?.nextPage !== null && !projectsNearMeLoading" class="flex justify-content-center py-3">
            <InfiniteLoading v-show="pagination?.nextPage !== null" @infinite="handleScrollPagination" />
          </div>
        </PullRefresh>
      </div>
    </div>
    <!-- <QuickContacsVue /> -->
  </div>
  <Sidebar
    id="projects-filter-sidebar"
    v-model:visible="projectsFilterIsVisible"
    append-to="body"
    :dismissable="true"
    position="right"
    :show-close-icon="true"
    class="flex w-full"
    style="max-width: 400px"
  >
    <template #header>
      <h5 class="m-0 text-left w-full">
        {{ t('projectsIndex.projectsFilter') }}
      </h5>
    </template>

    <div class="flex flex-column flex-grow-1 h-full py-4">
      <div id="scroll-container" class="flex flex-column flex-grow-1 h-full overflow-auto gap-3">
        <div class="field w-full mb-0">
          <label for="sortAutocomplete" class="w-full">{{ t('projectsIndex.filterType') }}:</label>
          <div class="relative w-full">
            <div
              v-if="typeValue"
              class="absolute project-filter"
              style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
              @click.stop="resetValue('type')"
            ></div>
            <AutoComplete
              id="typeAutocomplete"
              v-model="typeValue"
              class="w-full"
              :class="{ empty: !typeValue }"
              style="max-height: 40px"
              :dropdown="true"
              :force-selection="true"
              option-label="label"
              :placeholder="t('projectsIndex.filterTypeSearch')"
              :suggestions="
                filteredTypes.map((type) => ({
                  ...type,
                  label: t(type.label),
                }))
              "
              @complete="updateType($event)"
              @item-select="typeSelected($event)"
            >
              <template #item="slotProps">
                {{ slotProps.item.label }}
              </template>
            </AutoComplete>
          </div>
        </div>

        <div class="field w-full mb-0">
          <label for="sortAutocomplete" class="w-full">{{ t('projectsIndex.filterStage') }}:</label>
          <div class="relative w-full">
            <div
              v-if="stageValue"
              class="absolute project-filter"
              style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
              @click.stop="resetValue('stage')"
            ></div>
            <AutoComplete
              id="stageAutocomplete"
              v-model="stageValue"
              class="w-full"
              :class="{ empty: !stageValue }"
              style="max-height: 40px"
              :dropdown="true"
              :force-selection="true"
              option-label="label"
              :placeholder="t('projectsIndex.filterStageSearch')"
              :suggestions="
                filteredStages.map((stage) => ({
                  ...stage,
                  label: t(stage.label),
                }))
              "
              @complete="updateStage($event)"
              @item-select="stageSelected($event)"
            >
              <template #item="slotProps">
                {{ slotProps.item.label }}
              </template>
            </AutoComplete>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<style scoped lang="scss">
  .p-save-bottom {
    padding-bottom: var(--sab);
  }
  .p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-chip.status-readytoverify,
  .p-chip.status-inproduction {
    background: #ffda62;
    color: #333;
  }
  .p-chip.status-contractverify {
    background: #4137be;
    color: #fff;
  }
  .near-projects-container {
    width: 100%;
    @media screen and (min-width: 80em) {
      width: initial;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      .p-card {
        margin-bottom: 0 !important;
      }
    }
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 0rem;
  }
</style>
<style lang="scss">
  .home-messages {
    .p-message {
      .p-message-wrapper {
        display: flex;
        width: 100%;

        > .p-message-text {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }
</style>
