export const handlePointDragMove = (index: number, type: 'BOX' | 'ARROW' | 'CIRCLE') => {
  return (points: number[], cx: number, cy: number, ex: number, ey: number) => {
    // eslint-disable-next-line prefer-const
    let newPoints = points

    if (type === 'BOX') {
      if (index === 0) {
        newPoints[newPoints?.length - 2] = cx - Number(ex)
        newPoints[newPoints?.length - 1] = cy - Number(ey)

        newPoints[index] = cx - Number(ex)
        newPoints[index + 1] = cy - Number(ey)
      } else {
        newPoints[index] = cx - Number(ex)
        newPoints[index + 1] = cy - Number(ey)
      }
    }
    if (type === 'ARROW') {
      newPoints[index] = cx - Number(ex)
      newPoints[index + 1] = cy - Number(ey)
    }
    if (type === 'CIRCLE') {
      newPoints[index] = cx - Number(ex)
      newPoints[index + 1] = cy - Number(ey)
    }
    return newPoints
  }
}
