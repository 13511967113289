import { TShape } from '../shapes/types'
import uuid from 'uuid-random'
export type Template = {
  shapeKey: TShape['shapeKey']
  color: string
  screenWidth: number
  screenHeight: number
  pathSticker?: TShape['pathSticker']
  width?: number
  height?: number
  points?: number[]
  shadowPoints?: number[]
  text?: string
  ft?: string
  in_prop?: string
  m?: string
  text_unit?: TShape['text_unit']
  cm?: string
  thickness?: number
  id?: string
  x?: number
  y?: number
  scale?: number
  image_scale?: number
  image_rotation: number
  range?: number
}
export const positionByShape = (props: Template) => {
  const { shapeKey, range = 0 } = props

  const image_scale = Number(props?.image_scale)

  if (shapeKey === 'DRAW') {
    return {
      x: 0,
      y: 0,
    }
  }
  if (shapeKey === 'ARROW') {
    const x = props?.screenWidth / image_scale / 2 - range / 2
    const y = props?.screenHeight / image_scale / 2 - range / 2
    return {
      x,
      y,
    }
  }
  if (shapeKey === 'CIRCLE') {
    const x = props?.screenWidth / image_scale / 2
    const y = props?.screenHeight / image_scale / 2
    return {
      x,
      y,
    }
  }

  if (shapeKey === 'TEXT') {
    const x = props?.screenWidth / image_scale / 2
    const y = props?.screenHeight / image_scale / 2 - Number(props.height) / 2
    return {
      x,
      y,
    }
  }
  if (shapeKey === 'BOX') {
    const x = props?.screenWidth / image_scale / 2 - range / 2
    const y = props?.screenHeight / image_scale / 2 - range / 2
    return {
      x,
      y,
    }
  }
  if (shapeKey === 'STICKER') {
    const x = props?.screenWidth / image_scale / 2
    const y = props?.screenHeight / image_scale / 2
    return {
      x,
      y,
    }
  }
  const x = props?.screenWidth / image_scale / 2 - range / 2
  const y = props?.screenHeight / image_scale / 2 - range / 2
  return {
    x,
    y,
  }
}

export const templateShape = (props: Template & Partial<TShape>): TShape => {
  const {
    shapeKey,
    color,
    id,
    x: XShape,
    y: YShape,
    pathSticker,
    points,
    shadowPoints,
    text,
    ft,
    in_prop,
    m,
    text_unit,
    thickness,
    cm,
    fontSize,
    width,
    height,
    radius,
    scale,
    in: InProp,
    centerTextFirstTime = false,
    rotation,
  } = props

  const { x, y } = positionByShape(props)
  return {
    x: XShape ?? x,
    y: YShape ?? y,
    id: id ?? (uuid() as string),
    color,
    fontSize: fontSize ?? 20,
    ft: ft ?? '0',
    cm: cm ?? '0',
    in: InProp ?? in_prop ?? '0',
    m: m ?? '0',
    text_unit: text_unit ?? 'ABC',
    height: height ?? 100,
    width: width ?? 100,
    pathSticker: pathSticker ?? 'check',
    points: points ?? [],
    shadowPoints: shadowPoints ?? [],
    radius: radius ?? 80,
    shapeKey,
    text: text ?? '',
    thickness: thickness ?? 6,
    scale: scale ?? 2,
    centerTextFirstTime: centerTextFirstTime ?? false,
    rotation: rotation ?? {
      points: [0, 0, 80, 0],
      rotation: 0,
      angle: 0,
      x: 205.71428571428572,
      y: 290.9627329192547,
    },
  }
}
export const templateShapeCopy = (props: TShape): TShape => {
  const {
    shapeKey,
    color,
    // id,
    x: XShape,
    y: YShape,
    pathSticker,
    points,
    shadowPoints,
    text,
    ft,
    // in: Inprop,
    m,
    text_unit,
    thickness,
    cm,
    fontSize,
    width,
    height,
    radius,
    scale,
    in: InProp,
    centerTextFirstTime = false,
    rotation,
  } = props

  return {
    x: shapeKey === 'TEXT' ? XShape + 15 : XShape + 125,
    y: shapeKey === 'TEXT' ? YShape + 15 : YShape + 125,
    id: uuid() as string,
    color,
    fontSize: fontSize ?? 20,
    ft: ft ?? '0',
    cm: cm ?? '0',
    in: InProp ?? '0',
    m: m ?? '0',
    text_unit: text_unit ?? 'ABC',
    height: height ?? 100,
    width: width ?? 100,
    pathSticker: pathSticker ?? 'check',
    points: points ?? [],
    shadowPoints: shadowPoints ?? [],
    radius: radius ?? 80,
    shapeKey,
    text: text ?? '',
    thickness: thickness ?? 6,
    scale: scale ?? 2,
    centerTextFirstTime: centerTextFirstTime ?? false,
    rotation: rotation ?? {
      points: [0, 0, 80, 0],
      rotation: 0,
      angle: 0,
      x: 205.71428571428572,
      y: 290.9627329192547,
    },
  }
}
