<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
  import { onMounted, onUnmounted, toRefs } from 'vue'
  import EditorBar from './EditorBar.vue'
  import EditorStage from './EditorStage.vue'
  import { useShapes } from './store/shapes'
  import { AnnotationAPI, IEditorProps } from './types'
  import instance from '@/services/api'
  import { useStage } from './store/stage'
  import { CreateOrUpdateAnnotation } from './services/Annotation'
  import { useDraw } from './store/draw'
  const props = defineProps<IEditorProps>()
  const emits = defineEmits(['close', 'save'])
  const { photoid, availableAnnotations, annotation } = toRefs(props)
  const shapesStore = useShapes()
  const stageStore = useStage()
  const { hadleResetDraw } = useDraw()
  const { handleSetJSON, handleResetStore } = shapesStore
  const { SET_ROTATE } = stageStore
  const { stage, image } = toRefs(stageStore)
  const { shapes } = toRefs(shapesStore)
  const handleSave = (base_64: string) => {
    const shapesJSON = JSON.parse(JSON.stringify(shapes.value))
    const annotation = JSON.stringify({
      screen: {
        width: stage?.value?.width,
        height: stage?.value?.height,
      },
      image: {
        width: image?.value?.width,
        height: image?.value?.height,
        scale: image?.value?.scale,
        x: image?.value?.x,
        y: image?.value?.y,
      },
      shapes: shapesJSON,
      rotation: image?.value?.rotation,
    })

    if (availableAnnotations.value) {
      CreateOrUpdateAnnotation({
        photoId: photoid.value,
        annotation,
      }).catch(() => alert('Error Save Annotation, Could not save the annotation'))
    }
    emits('save', {
      base_64,
      annotation: annotation,
      photoId: photoid.value,
    })
    handleResetStore()
    hadleResetDraw()
  }
  const handleClose = () => {
    emits('close')
    handleResetStore()
    hadleResetDraw()
  }

  onMounted(() => {
    if (annotation.value) {
      const data = JSON.parse(annotation.value) as AnnotationAPI
      handleSetJSON(data.shapes)
      SET_ROTATE(data?.rotation ?? 0)
    }
    if (!photoid.value || !availableAnnotations?.value) return
    const handle = async () => {
      const response = await instance.get(`/Api/Annotation/${photoid.value}`)
      if (response?.data?.data?.annotation === null) return

      const data = JSON.parse(response?.data?.data?.annotation) as AnnotationAPI
      SET_ROTATE(data?.rotation ?? 0)
      handleSetJSON(data.shapes)
    }
    handle()
  })

  onUnmounted(() => {
    SET_ROTATE(0)
    handleResetStore()
    hadleResetDraw()
  })
</script>
<template>
  <Teleport to="#popup-target">
    <main class="main_layout_editor">
      <EditorBar />
      <EditorStage v-bind="props" @close="handleClose" @save="(b) => handleSave(b)" />
    </main>
  </Teleport>
</template>

<style scoped>
  .main_layout_editor {
    display: grid;
    grid-template-columns: 55px 100%;
    background-color: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    overflow-x: hidden;
    z-index: 1201 !important;
  }
</style>
