import axios from '@/services/api'
type UpdateANnotationProps = {
  annotation: string
  photoId: string
}

export const CreateOrUpdateAnnotation = async ({ annotation, photoId }: UpdateANnotationProps) => {
  await axios.post(`/Api/UpdateAnnotation/${photoId}`, {
    annotation,
  })
  return 'Annotations was updated!'
}
