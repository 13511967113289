import { Capacitor } from '@capacitor/core'
import { Credentials, NativeBiometric } from 'capacitor-native-biometric'
import { i18n } from '@/i18n'
const { t } = i18n.global

const server = 'www.infinityhomeservices.com'

const setCredentialsNative = async (username: string, password: string) => {
  const isNative = Capacitor.isNativePlatform()
  if (isNative) {
    const { isAvailable } = await NativeBiometric.isAvailable({
      useFallback: true,
    })
    if (isAvailable) {
      deleteCredentialsNative()
      NativeBiometric.setCredentials({
        username,
        password,
        server: server,
      })
    }
  }
}

const checkIsBiometricAvailableNative = async () => {
  const isNative = Capacitor.isNativePlatform()
  if (isNative) {
    const { isAvailable } = await NativeBiometric.isAvailable({
      useFallback: true,
    })
    return isAvailable
  }
  return false
}

// const BiometricAuthError = {
//   0: 'UNKNOWN_ERROR',
//   1: 'BIOMETRICS_UNAVAILABLE',
//   2: 'USER_LOCKOUT',
//   3: 'BIOMETRICS_NOT_ENROLLED',
//   4: 'USER_TEMPORARY_LOCKOUT',
//   10: 'AUTHENTICATION_FAILED',
//   11: 'APP_CANCEL',
//   12: 'INVALID_CONTEXT',
//   13: 'NOT_INTERACTIVE',
//   14: 'PASSCODE_NOT_SET',
//   15: 'SYSTEM_CANCEL',
//   16: 'USER_CANCEL',
//   17: 'USER_FALLBACK',
// }

const isBiometricAvailable = async () => {
  // const { isAvailable, errorCode } = await NativeBiometric.isAvailable({
  //   useFallback: true,
  // })
  // if (true) {
  return {
    status: 'ERROR',
    // error: BiometricAuthError[errorCode as keyof typeof BiometricAuthError],
    error: 'BIOMETRICS_UNAVAILABLE',
  }
  // }
  // return {
  //   status: 'AVAILABLE',
  //   error: null,
  // }
}

export type TCredentialNative = {
  status: 'MOBILE' | 'DESKTOP_WEB'
  credentials: Credentials | null
}

const getCredentialsNative = async (): Promise<TCredentialNative> => {
  const isNative = Capacitor.isNativePlatform()
  if (isNative) {
    const credentials = await NativeBiometric.getCredentials({
      server,
    })
    return {
      status: 'MOBILE',
      credentials,
    }
  }
  return {
    status: 'DESKTOP_WEB',
    credentials: null,
  }
}
const deleteCredentialsNative = () => {
  const isNative = Capacitor.isNativePlatform()
  if (isNative) {
    NativeBiometric.deleteCredentials({
      server,
    })
  }
}

const verifyIndentityNative = async (username: string) => {
  const isIdentity = await NativeBiometric.verifyIdentity({
    reason: t('faceIdVerify.reason'),
    title: t('faceIdVerify.title'),
    subtitle: `${t('faceIdVerify.subtitle')} ${username}`,
    description: t('faceIdVerify.description'),
    useFallback: true,
    maxAttempts: 10,
  })
    .then(() => true)
    .catch(() => false)
  return isIdentity
}

export {
  getCredentialsNative,
  setCredentialsNative,
  deleteCredentialsNative,
  verifyIndentityNative,
  isBiometricAvailable,
  checkIsBiometricAvailableNative as checkIsBiometricAvailable,
}
