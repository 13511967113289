import { defineStore, PiniaCustomStateProperties } from 'pinia'

interface IBreakpoints {
  xs: boolean
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
}

export const useBreakpointsStore = defineStore({
  id: 'Breakpoints',
  state: (): IBreakpoints => ({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  }),
  getters: {
    getBreakpoints: (state): PiniaCustomStateProperties<IBreakpoints> => state,
  },
  actions: {
    setBreakPoints(xs: boolean, sm: boolean, md: boolean, lg: boolean, xl: boolean) {
      this.sm = sm
      this.xs = xs
      this.md = md
      this.lg = lg
      this.xl = xl
    },
  },
})
