import api from '@/services/api'
import { captureException } from '@sentry/vue'
import { ref } from 'vue'

type TUser = {
  email: string
  fullName: string
  id: string
  phoneNumber: null
  photoUrl: null
  profile: string
  zuid: string
}

type TDrowpUsers = {
  value: string
  label: string
}
const useLoginUsersByAdmin = () => {
  const users = ref<TDrowpUsers[]>([])
  const loading = ref(false)
  const getUsersByOrg = async () => {
    loading.value = true
    try {
      const response = await api.get('/Api/Users/ProfileBy/ALL')
      const dataUsers = response.data?.data as TUser[]

      const transformedUsers = dataUsers?.map((user) => ({
        label: user?.fullName,
        value: user?.id,
      }))
      users.value = transformedUsers
    } catch (error) {
      captureException(error)
    } finally {
      loading.value = false
    }
  }
  return {
    getUsersByOrg,
    users,
    loading,
  }
}

export default useLoginUsersByAdmin
