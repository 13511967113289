<script setup lang="ts">
  import { toRefs } from 'vue'
  import { ICrewUser } from '../Types'
  import { useProjectStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'

  interface Iprops {
    crewUsersProject: ICrewUser[]
  }

  const renderPhoneHref = (phone: string) => 'tel:+1' + phone.replace(/\D/g, '')
  const props = defineProps<Iprops>()
  const emit = defineEmits<{ (e: 'removeUser', value: { crewUser: ICrewUser }): void }>()
  const { crewUsersProject } = toRefs(props)
  const projectStore = useProjectStore()
  const { project } = storeToRefs(projectStore)
</script>

<template>
  <div v-if="crewUsersProject.length > 0" class="grid m-0 w-full">
    <div v-for="crewUser in crewUsersProject" :key="crewUser.id" class="col col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3">
      <div class="flex w-full justify-content-start flex-wrap">
        <div class="p-card w-full shadow-none p-3 flex-column">
          <div class="flex justify-content-between align-items-center flex-column gap-1">
            <div class="flex align-items-center flex-column gap-2 justify-content-center relative w-full">
              <Avatar :image="`https://contacts.zoho.com/file?ID=${crewUser.id}&fs=thumb`" size="large" shape="circle" />
              <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsCrewsPermissions'" :key-name="'crewUserRemove'">
                <div class="absolute" style="top: -11px; right: -5px; z-index: 1">
                  <Button
                    style="width: 1rem; background-color: transparent; border: none"
                    @click="
                      () => {
                        emit('removeUser', { crewUser: crewUser })
                      }
                    "
                  >
                    <i class="pi pi-times" style="color: #ef4444" />
                  </Button>
                </div>
              </PermissionRole>
              <div class="flex flex-column align-items-center gap-1">
                <div class="text-muted font-normal text-xl">
                  {{ crewUser.fullName ?? crewUser.name }}
                </div>
              </div>
            </div>

            <div class="flex flex-column flex-nowrap justify-content-end w-12">
              <a v-if="crewUser.email" :href="`mailto:${crewUser.email}`" class="p-button-rounded p-button-tex text-lg flex flex-row align-items-center justify-content-center">
                <i class="pi pi-envelope" />
                <span class="text-lg pl-3" style="max-width: 80%; text-overflow: ellipsis; overflow: hidden; display: block">
                  {{ crewUser.email }}
                </span>
              </a>
              <a
                v-if="crewUser.phoneNumber"
                :href="renderPhoneHref(crewUser.phoneNumber)"
                class="p-button-rounded p-button-text text-lg w-12 h-12 px-3 flex flex-row align-items-center"
              >
                <i class="pi pi-phone" />
                <span class="text-lg pl-3" style="max-width: 80%; text-overflow: ellipsis; overflow: hidden; display: block">
                  {{ crewUser.phoneNumber }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
