import api from '@/services/api'
import { fetchCurrentLocationOnce } from './geolocation'

export const projectCheckin = async (projectId: string) => {
  const { latitude, longitude } = await fetchCurrentLocationOnce(true)
  await api.post('/Api/Projects/CheckIn', {
    projectId,
    latitude: `${latitude}`,
    longitude: `${longitude}`,
  })
}

type PayloadCheckinUpdate = {
  id: string
  type: 'Leads' | 'Projects'
  payload: {
    latitude: number
    longitude: number
  }
}

export const checkinUpdate = async ({ id, payload, type }: PayloadCheckinUpdate) => {
  const { data } = await api.post(`/Api/${type}/${id}/CheckInUpdate`, payload)
  return data
}
