export const tagsDataTableColumnUS = {
  name: 'Name',
  count: 'Number of photos',
  created: 'Created at',
  placeholderName: 'Search By Name',
  placeholderCount: 'Search By Number of photos',
}

export const tagsDataTableColumnEs = {
  name: 'Nombre',
  count: 'Numero de fotos',
  created: 'Creando en',
  placeholderName: 'Busca por nombre',
  placeholderCount: 'Busca por numero de fotos',
}
