<script lang="ts" setup>
  import { i18n } from '@/i18n'
  const { t } = i18n.global
</script>

<template>
  <div class="w-full p-4 pl-5 pr-8">
    <div class="heading">{{ t('policiesIndex.title') }}</div>
    <p>
      <strong>
        {{ t('policiesIndex.lastUpdated') }}<u>{{ t('policiesIndex.lastUpdatedDate') }}</u>
      </strong>
    </p>
    <p>
      <strong>{{ t('policiesIndex.agreementOfOnlineBehavior.title') }}</strong>
    </p>
    <p>
      {{ t('policiesIndex.agreementOfOnlineBehavior.paragraph1') }}
    </p>
    <p>
      {{ t('policiesIndex.agreementOfOnlineBehavior.paragraph2') }}
    </p>
    <p>
      {{ t('policiesIndex.agreementOfOnlineBehavior.paragraph3') }}
    </p>
    <p>
      <strong>{{ t('policiesIndex.limitationOfWarrantyAndLiability.title') }}</strong>
    </p>
    <p>
      {{ t('policiesIndex.limitationOfWarrantyAndLiability.paragraph1') }}
    </p>
    <p></p>
    <p>
      {{ t('policiesIndex.limitationOfWarrantyAndLiability.paragraph2') }}
    </p>
    <p>
      <strong>{{ t('policiesIndex.warranty.title') }}</strong>
    </p>
    <p>
      {{ t('policiesIndex.warranty.paragraph1') }}
    </p>
    <p>
      <strong>{{ t('policiesIndex.severability.title') }}</strong>
    </p>
    <p>
      {{ t('policiesIndex.severability.paragraph1') }}
    </p>
    <p>
      <strong>{{ t('policiesIndex.termsAndConditions.title') }}</strong>
    </p>
    <p>
      {{ t('policiesIndex.termsAndConditions.paragraph1') }}
    </p>
    <ol>
      <li>
        <p>
          {{ t('policiesIndex.termsAndConditions.paragraph2') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.termsAndConditions.paragraph3') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.termsAndConditions.paragraph4') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.termsAndConditions.paragraph5') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.termsAndConditions.paragraph6') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.termsAndConditions.paragraph7') }}
        </p>
      </li>
    </ol>
    <p>
      <strong>{{ t('policiesIndex.linking.title') }}</strong>
    </p>
    <p>
      {{ t('policiesIndex.linking.paragraph1') }}
    </p>
    <ul>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph2') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph3') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph4') }}
        </p>
      </li>
    </ul>
    <p>
      {{ t('policiesIndex.linking.paragraph5') }}
    </p>
    <ul>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph6') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph7') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph8') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph9') }}
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph10') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph11') }}
        </p>
      </li>
    </ul>
    <p>
      {{ t('policiesIndex.linking.paragraph12') }}
    </p>
    <ul>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph13') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph14') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph15') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph16') }}
        </p>
      </li>
    </ul>
    <p>
      {{ t('policiesIndex.linking.paragraph17') }}
    </p>
    <ul>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph18') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph19') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph20') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph21') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.linking.paragraph22') }}
        </p>
      </li>
    </ul>
    <p>
      <strong>{{ t('policiesIndex.privacy.title') }}</strong>
    </p>
    <p>
      {{ t('policiesIndex.privacy.paragraph1') }}
    </p>
    <ul>
      <li>
        <p>
          {{ t('policiesIndex.privacy.paragraph2') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.privacy.paragraph3') }}
        </p>
      </li>
      <li>
        <p>
          {{ t('policiesIndex.privacy.paragraph4') }}
        </p>
      </li>
    </ul>
    <p>
      {{ t('policiesIndex.privacy.paragraph5') }}
    </p>
    <p>
      {{ t('policiesIndex.privacy.paragraph6') }}
    </p>

    <p>
      <strong>{{ t('policiesIndex.support.title') }}</strong>
    </p>
    <p>
      {{ t('policiesIndex.support.paragraph1') }}
      <a href="mailto:productionappsupport@infinityhomeservices.com"> productionappsupport@infinityhomeservices.com </a>
      {{ t('policiesIndex.support.paragraph2') }}
      <a href="tel:+12626505040"> +12626505040 </a>
    </p>

    <p>
      <strong>{{ t('policiesIndex.copyrights.title') }}</strong>
    </p>
    <p></p>
    <p>
      <strong
        ><em>{{ t('policiesIndex.Team') }}</em></strong
      >
    </p>
    <div class="text-center">
      <a href="/account/login" class="btn btn-fill-app btn-icon-arrow my-1">{{ t('policiesIndex.accessIhsHomeBuilder') }}</a>
    </div>
  </div>
</template>

<style scoped></style>
