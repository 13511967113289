<script lang="ts" setup>
  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  import { toRefs } from 'vue'
  import QueueCard from '../QueueCard.vue'
  import { i18n } from '@/i18n'
  const { t } = i18n.global
  const queueStore = uploadNewQueuePhoto()
  const { uploadQueuePhoto, uploadQueuePhotos } = queueStore
  const { queue } = toRefs(queueStore)
</script>

<template>
  <header v-if="queue?.length > 0" class="w-full flex justify-content-end">
    <Button icon="pi pi-upload" class="p-button-text" :label="t('settingsQueuePhoto.uploadAll')" @click="uploadQueuePhotos" />
  </header>
  <main v-if="(queue ?? []).length > 0" class="flex w-full flex-column mt-2 w-full justify-content-evenly gap-2">
    <article v-for="photo in queue" :key="photo.id" class="w-full">
      <QueueCard :queue-photo="photo" @upload-photo="(e) => uploadQueuePhoto(e)" />
    </article>
  </main>
  <p v-if="queue?.length === 0" class="text-sm">Congratulations, you have no pending images to upload. All your images have been successfully uploaded</p>
</template>
