<script lang="ts" async setup>
  import { ref, type Ref, watch, onMounted } from 'vue'
  import ProjectCardsComponent from '@/components/ProjectCardsComponent.vue'
  import { useProjectStore } from '@/store'
  import { IOption } from '@/interfaces/option/option.interface'
  import { projectTypes } from '@/options/project/project-types.options'
  import { sortValues } from '@/options/project/project-sort.options'
  import { projectStages } from '@/options/project/project-stage.options'
  import { hasObjectWithValue } from '@/utils/hasObjectWithLabel'
  import Sidebar from 'primevue/sidebar'
  import AutoComplete from 'primevue/autocomplete'
  import { i18n } from '@/i18n'
  import { storeToRefs } from 'pinia'
  import { captureException } from '@sentry/vue'
  import InfiniteLoading from 'v3-infinite-loading'
  import 'v3-infinite-loading/lib/style.css'

  const { t } = i18n.global
  const projectStore = useProjectStore()
  const { fetchProjects, setDefaultProjects, setDefaultPagination } = projectStore
  const { projects, pagination, loadingProjects } = storeToRefs(projectStore)
  const projectsFilterIsVisible = ref(false)
  const takeValue: Ref = ref(6)
  const skipValue: Ref = ref(0)
  const sortValue: Ref<IOption | null> = ref(null)
  const typeValue: Ref<IOption | null> = ref(null)
  const stageValue: Ref<IOption | null> = ref(null)

  // const sortValues = ['NEWEST', 'OLDEST']

  // TODO: needs to be fetched from the server
  // const projectStages = [
  //   'WILL_CALLED_ORDERED',
  //   'READY_FOR_PRODUCTION',
  //   'PROJECT_SCHEDULED',
  //   'IN_PRODUCTION',
  //   'PROJECT_COMPLETED',
  //   'NONE',
  //   'READY_TO_VERIFY',
  //   'CONTRACT_VERIFIED',
  //   'SCHEDULE_PRE_WALK_THROUGH',
  //   'PM_APPROVED',
  //   'PO_REJECTED_BY_PM',
  //   'CORRECTED_PO_AWAITING_APPROVAL',
  //   'SECOND_PO_REJECTED',
  // ].sort()

  const filteredSort: Ref<IOption[]> = ref(sortValues)
  const filteredTypes: Ref<IOption[]> = ref(projectTypes)
  const filteredStages: Ref<IOption[]> = ref(projectStages)

  function toggleFilterDrawer() {
    projectsFilterIsVisible.value = !projectsFilterIsVisible.value
  }

  async function updateSort($event: any) {
    try {
      const { query } = $event
      filteredSort.value = sortValues.filter((sv) => sv.value.includes(query.toUpperCase()))
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function sortSelected($event: any) {
    try {
      sortValue.value = $event.value
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function updateType($event: any) {
    try {
      const { query } = $event
      filteredTypes.value = projectTypes.filter((pt) => pt.value.includes(query.toUpperCase()))
    } catch (err) {
      console.log('err', err)
      throw err
    }
  }

  async function typeSelected($event: any) {
    try {
      typeValue.value = $event.value
    } catch (err) {
      console.log('err', err)
      throw err
    }
  }

  async function updateStage($event: any) {
    try {
      const { query } = $event
      filteredStages.value = projectStages.filter((ps) => ps.value.includes(query.toUpperCase()))
    } catch (err) {
      console.log('err', err)
      throw err
    }
  }

  async function stageSelected($event: any) {
    try {
      stageValue.value = $event.value
    } catch (err) {
      console.log('err', err)
      throw err
    }
  }

  async function updateProjects() {
    try {
      await fetchProjects({
        take: takeValue.value,
        skip: skipValue.value,
        sort: sortValue.value?.value,
        projectType: typeValue.value?.value,
        projectStage: stageValue.value?.value,
      })
    } catch (err) {
      console.log('err', err)
      throw err
    }
  }

  function resetValue(type: 'sort' | 'type' | 'stage') {
    if (type === 'sort') {
      sortValue.value = null
    } else if (type === 'type') {
      typeValue.value = null
    } else if (type === 'stage') {
      stageValue.value = null
    }
  }

  const resetPagination = () => {
    takeValue.value = 6
    skipValue.value = 0
  }

  watch(skipValue, async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await updateProjects()
    }
  })

  watch(takeValue, async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await updateProjects()
    }
  })

  watch(sortValue, async (newValue, oldValue) => {
    if (newValue !== oldValue && (!newValue || newValue.label.length === 0 || hasObjectWithValue(sortValues, 'value', newValue.value))) {
      resetPagination()
      setDefaultProjects()
      await updateProjects()
    }
  })

  watch(typeValue, async (newValue, oldValue) => {
    if (newValue !== oldValue && (!newValue || newValue.label.length === 0 || hasObjectWithValue(projectTypes, 'value', newValue.value))) {
      setDefaultProjects()
      await updateProjects()
    }
  })

  watch(stageValue, async (newValue, oldValue) => {
    if (newValue !== oldValue && (!newValue || newValue.label.length === 0 || hasObjectWithValue(projectStages, 'value', newValue.value))) {
      resetPagination()
      setDefaultProjects()
      await updateProjects()
    }
  })

  onMounted(async () => {
    setDefaultPagination()
    setDefaultProjects()
    await fetchProjects({
      take: takeValue.value,
      skip: skipValue.value,
    })
  })

  const handleScrollPagination = () => {
    if (pagination.value.rowCount > projects.value.length) {
      skipValue.value = skipValue.value + 6
      takeValue.value = 6
    }
  }
</script>

<template>
  <div class="flex flex-column h-full">
    <div class="col col-12">
      <div class="flex justify-content-between align-items-center">
        <h1 class="mb-0">{{ $t('projectsIndex.projects') }}</h1>
        <Button
          icon="pi pi-filter"
          class="p-button-rounded p-button-lg"
          :class="typeValue || stageValue ? '' : 'p-button-outlined'"
          style="border-style: dashed"
          @click="toggleFilterDrawer"
        />
      </div>
    </div>

    <div class="flex-1 flex flex-column overflow-y-scroll">
      <ProjectCardsComponent :projects="projects" :loading-projects="loadingProjects" />
      <div v-show="!loadingProjects && pagination?.nextPage !== null && pagination.nextPage.length > 0" class="flex justify-content-center py-3">
        <InfiniteLoading v-show="pagination?.rowCount > projects?.length" @infinite="handleScrollPagination" />
      </div>
    </div>
  </div>

  <Sidebar
    id="projects-filter-sidebar"
    v-model:visible="projectsFilterIsVisible"
    append-to="body"
    :dismissable="true"
    position="right"
    :show-close-icon="true"
    class="flex w-full"
    style="max-width: 400px"
  >
    <template #header>
      <h5 class="m-0 text-left w-full">
        {{ t('projectsIndex.projectsFilter') }}
      </h5>
    </template>

    <div class="flex flex-column flex-grow-1 h-full py-4">
      <div id="scroll-container" class="flex flex-column flex-grow-1 h-full overflow-auto gap-3">
        <div class="field w-full mb-0">
          <label for="sortAutocomplete" class="w-full">{{ t('projectsIndex.sort') }}:</label>
          <div class="relative w-full">
            <div
              v-if="sortValue"
              class="absolute project-filter"
              style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
              @click.stop="resetValue('sort')"
            ></div>
            <AutoComplete
              id="sortAutocomplete"
              v-model="sortValue"
              class="w-full"
              :class="{ empty: !sortValue }"
              style="max-height: 40px"
              :dropdown="true"
              :force-selection="true"
              option-label="label"
              :placeholder="t('projectsIndex.sort')"
              :suggestions="
                filteredSort.map((sort) => ({
                  ...sort,
                  label: t(sort.label),
                }))
              "
              @complete="updateSort($event)"
              @item-select="sortSelected($event)"
            >
              <template #item="slotProps">
                {{ slotProps.item.label }}
              </template>
            </AutoComplete>
          </div>
        </div>

        <div class="field w-full mb-0">
          <label for="sortAutocomplete" class="w-full">{{ t('projectsIndex.filterType') }}:</label>
          <div class="relative w-full">
            <div
              v-if="typeValue"
              class="absolute project-filter"
              style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
              @click.stop="resetValue('type')"
            ></div>
            <AutoComplete
              id="typeAutocomplete"
              v-model="typeValue"
              class="w-full"
              :class="{ empty: !typeValue }"
              style="max-height: 40px"
              :dropdown="true"
              :force-selection="true"
              option-label="label"
              :placeholder="t('projectsIndex.filterTypeSearch')"
              :suggestions="
                filteredTypes.map((type) => ({
                  ...type,
                  label: t(type.label),
                }))
              "
              @complete="updateType($event)"
              @item-select="typeSelected($event)"
            >
              <template #item="slotProps">
                {{ slotProps.item.label }}
              </template>
            </AutoComplete>
          </div>
        </div>

        <div class="field w-full mb-0">
          <label for="sortAutocomplete" class="w-full">{{ t('projectsIndex.filterStage') }}:</label>
          <div class="relative w-full">
            <div
              v-if="stageValue"
              class="absolute project-filter"
              style="right: 48px; top: 8px; height: 24px; width: 24px; cursor: pointer; z-index: 1"
              @click.stop="resetValue('stage')"
            ></div>
            <AutoComplete
              id="stageAutocomplete"
              v-model="stageValue"
              class="w-full"
              :class="{ empty: !stageValue }"
              style="max-height: 40px"
              :dropdown="true"
              :force-selection="true"
              option-label="label"
              :placeholder="t('projectsIndex.filterStageSearch')"
              :suggestions="
                filteredStages.map((stage) => ({
                  ...stage,
                  label: t(stage.label),
                }))
              "
              @complete="updateStage($event)"
              @item-select="stageSelected($event)"
            >
              <template #item="slotProps">
                {{ slotProps.item.label }}
              </template>
            </AutoComplete>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<style lang="scss">
  .project-filter {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");

    input:not(.empty) {
      padding-right: 28px;
    }
  }

  #projects-filter-sidebar {
    .p-sidebar-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    .p-sidebar-header-content {
      width: 100%;
    }
  }
</style>
