export default {
  filterLabel: 'Filtro',
  filterIcon:
    '<g stroke-width=".125em" stroke="currentColor" fill="none"><path d="M18.347 9.907a6.5 6.5 0 1 0-1.872 3.306M3.26 11.574a6.5 6.5 0 1 0 2.815-1.417 M10.15 17.897A6.503 6.503 0 0 0 16.5 23a6.5 6.5 0 1 0-6.183-8.51"/></g>',
  filterLabelChrome: 'Cromado',
  filterLabelFade: 'Atenuar',
  filterLabelCold: 'Frío',
  filterLabelWarm: 'Cálido',
  filterLabelPastel: 'Pastel',
  filterLabelMonoDefault: 'Mono',
  filterLabelMonoNoir: 'Negro',
  filterLabelMonoWash: 'Lavado',
  filterLabelMonoStark: 'Dorado',
  filterLabelSepiaDefault: 'Sepia',
  filterLabelSepiaBlues: 'Azules',
  filterLabelSepiaRust: 'Óxido',
  filterLabelSepiaColor: 'Color',
}
