<script lang="ts" setup>
  import { checkinUpdate, projectCheckin } from '@/services/project'
  import { useToast } from 'primevue/usetoast'
  import { ref, toRefs } from 'vue'
  import { i18n } from '@/i18n'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'

  const { t } = i18n.global

  type Props = {
    projectId: string
  }
  const props = defineProps<Props>()

  const { projectId } = toRefs(props)

  const toast = useToast()

  const loading = ref(false)
  const loadingUpdateCheckin = ref(false)
  const showDialogChangeLocation = ref(false)

  const handleCheckin = async () => {
    try {
      loading.value = true
      await projectCheckin(projectId.value)
      toast.add({ severity: 'success', summary: t('projectId.success'), detail: t('projectId.checkedInCorrectly'), life: 3000 })
    } catch (error: any) {
      const label = 'You are not in the project area'
      const errorMessage = error.response?.data?.responseException?.exceptionMessage?.message

      toast.add({
        severity: 'error',
        summary: t('projectId.error'),
        detail: errorMessage ?? t('projectId.errorCheckingIn'),
        life: 3000,
      })
      if (errorMessage === label) {
        showDialogChangeLocation.value = true
      }
    } finally {
      loading.value = false
    }
  }

  const handleUpdateCheckin = async () => {
    try {
      loadingUpdateCheckin.value = true
      const { latitude, longitude } = await fetchCurrentLocationOnce(true)
      await checkinUpdate({
        id: projectId.value,
        payload: {
          latitude,
          longitude,
        },
        type: 'Projects',
      })
      showDialogChangeLocation.value = false
      toast.add({ severity: 'success', summary: t('projectId.success'), detail: t('projectId.checkInUpdate.success'), life: 3000 })
    } catch (error) {
      console.log('hello test')
      toast.add({
        severity: 'error',
        summary: t('projectId.error'),
        detail: t('projectId.checkInUpdate.error'),
        life: 3000,
      })
    } finally {
      loadingUpdateCheckin.value = false
    }
  }
</script>

<template>
  <Dialog
    v-model:visible="showDialogChangeLocation"
    class="w-full ml-8"
    style="max-width: 40rem; z-index: 1215"
    modal
    :draggable="false"
    close-on-escape
    closable
    :header="t('projectId.checkInUpdate.title')"
  >
    <Message severity="error" :closable="false" icon="pi pi-lock">{{ t('projectId.checkInUpdate.projectError') }} </Message>
    <p>
      {{ t('projectId.checkInUpdate.makeSure') }}
    </p>
    <Button
      class="btn flex-row flex justify-content-center align-items-center w-full bg-black-alpha-90 border-0"
      :label="t('projectId.checkInUpdate.finish')"
      :loading="loadingUpdateCheckin"
      @click="handleUpdateCheckin"
    >
    </Button>
  </Dialog>
  <Button
    class="btn flex-row flex justify-content-center align-items-center w-full bg-black-alpha-90 border-0"
    :label="t('projectId.checkIn')"
    :loading="loading"
    severity="danger"
    @click="handleCheckin"
  >
  </Button>
</template>
