<script lang="ts" async setup>
  // import { Capacitor } from '@capacitor/core'
  import { IQueuePhoto } from '@/db/tables'
  import { toRefs } from 'vue'

  interface IControlsCamera {
    isLoading: boolean
    isFlipAvailable: boolean
    images: IQueuePhoto[]
    lastThreeImages: IQueuePhoto[]
    isLandscape: boolean
    togglePreviewVisibility?: () => void
    takePicture?: () => void
    flipCamera?: () => void
  }
  const generateStyle = (lastThreeImages: IQueuePhoto[], index: number, image: IQueuePhoto) => {
    const length = lastThreeImages.length
    const unit = length === 3 ? 8 : length === 2 ? 12 : 0
    const scale = index === 1 ? 0.9 : 0.8
    const isScale = (length === 3 && index !== 2) || (length === 2 && index !== 1)

    return {
      left: index * unit + 'px',
      transform: isScale ? `scale(${scale})` : 'scale(1)',
      backgroundImage: `url(${image?.base64_annotation ?? image.base64})`,
    }
  }

  const emit = defineEmits(['togglePreviewVisibility', 'takePicture', 'flipCamera', 'changeStatus', 'startVideo', 'stopVideo', 'tooglePreviewVideo'])
  const props = defineProps<IControlsCamera>()
  const { isLoading, images, lastThreeImages, isLandscape } = toRefs(props)
</script>

<template>
  <div v-if="!isLoading" class="controls select-none">
    <div class="flex flex-row justify-content-evenly h-full pl-4 align-items-center">
      <div class="w-full h-full flex align-items-center start relative" :class="{ 'is-rotate': isLandscape }">
        <button
          v-for="(image, index) in lastThreeImages"
          :key="image.id"
          class="btn shadow-3 bg-cover border-round-lg bg-no-repeat bg-center border-none cursor-pointer h-5rem w-5rem bg-transparent absolute"
          :style="generateStyle(lastThreeImages, index, image)"
          @click="$emit('togglePreviewVisibility')"
        >
          <div
            class="absolute top-0 left-0 bottom-0 right-0 border-round-lg border-solid border-1 border-white"
            style="
                opacity: 0.3,
                backgroundColor: '#fff',
              "
          />
        </button>
        <div v-if="images.length > 3" class="absolute border-round-lg bg-white text-black p-2 shadow-3 font-bold text-sm" style="top: -45px; left: 70px; z-index: 1">
          + {{ images.length - 3 }}
        </div>
      </div>

      <div class="w-full h-full select-none flex flex-column align-items-center">
        <div class="flex p-3 align-items-center justify-content-center select-none">
          <div
            class="btn p-button-icon-only p-button-rounded text-white h-6rem w-6rem p-2 shadow-3 select-none cursor-pointer"
            style="background-color: transparent; border: 5px solid #fff; border-radius: 100%"
            @click="emit('takePicture')"
          ></div>
        </div>
      </div>

      <div class="w-full flex align-items-center justify-content-end h-full pr-4">
        <Button v-if="isFlipAvailable" class="btn p-button p-button-icon-only p-button-rounded bg-white h-4rem w-4rem border-none shadow-3" @click="$emit('flipCamera')">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24" class="text-800">
            <path
              fill="currentColor"
              d="M19,8L15,12H18A6,6 0 0,1 12,18C11,18 10.03,17.75 9.2,17.3L7.74,18.76C8.97,19.54 10.43,20 12,20A8,8 0 0,0 20,12H23M6,12A6,6 0 0,1 12,6C13,6 13.97,6.25 14.8,6.7L16.26,5.24C15.03,4.46 13.57,4 12,4A8,8 0 0,0 4,12H1L5,16L9,12"
            />
          </svg>
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .controls {
    position: fixed;
    bottom: 3rem;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    min-height: 112px;
  }
  .is-rotate {
    transform: rotate(90deg);
  }
</style>
