<script lang="ts" setup>
  import { defineProps, onMounted } from 'vue'
  import { useProjectStore } from '@/store'
  import { storeToRefs } from 'pinia'

  const props = defineProps({
    projectId: {
      type: String,
      required: true,
    },
  })

  const projectStore = useProjectStore()
  const { fetchWorkContacts } = useProjectStore()
  const { projectWorkContacts } = storeToRefs(projectStore)

  const getWorkContacts = async () => {
    await fetchWorkContacts(props.projectId)
  }

  function renderPhoneHref(phone: string) {
    return 'tel:+1' + phone.replace(/\D/g, '')
  }

  function renderSMSHref(phone: string) {
    return 'sms:+1' + phone.replace(/\D/g, '')
  }

  onMounted(async () => {
    await getWorkContacts()
  })
</script>

<template>
  <div class="col-12">
    <div class="flex align-items-center justify-content-between title-tab">
      <div class="flex text-xl align-items-center">
        <div class="flex pr-2">
          <i class="pi pi-book" />
        </div>
        <div class="flex text-xl">
          {{ $t('projectWorkContacts.workContacts') }}
        </div>
      </div>
    </div>

    <div class="grid w-full">
      <div v-for="workContact in projectWorkContacts" :key="workContact.id" class="col col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3">
        <div class="flex w-full justify-content-start flex-wrap">
          <div class="p-card w-full shadow-none p-3 flex-column">
            <div class="flex justify-content-between align-items-center flex-column gap-1">
              <div class="flex align-items-center flex-column gap-2 justify-content-center">
                <Avatar :image="`https://contacts.zoho.com/file?ID=${workContact.zuid}&fs=thumb`" class="mr-2" size="large" shape="circle" />
                <div class="flex flex-column align-items-center gap-1">
                  <div class="text-lg font-bold">
                    {{ workContact.fullName }}
                  </div>
                  <div v-if="workContact.profile" class="text-muted font-normal text-sm">
                    {{ workContact.profile }}
                  </div>
                </div>
              </div>
              <div>
                <div class="flex flex-nowrap justify-content-end gap-1 pt-2">
                  <a v-if="workContact.email" :href="`mailto:${workContact.email}`" class="p-button-rounded p-button-text text-lg w-12 h-12 px-3">
                    <i class="pi pi-envelope" />
                  </a>
                  <a v-if="workContact.phone" :href="renderPhoneHref(workContact.phone)" class="p-button-rounded p-button-text text-lg w-12 h-12 px-3">
                    <i class="pi pi-phone" />
                  </a>
                  <a v-if="workContact.phone" :href="renderSMSHref(workContact.phone)" class="p-button-rounded p-button-text text-lg w-12 h-12 px-3">
                    <i class="pi pi-comment" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
  .title-tab {
    min-height: 40px;
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 0rem;
  }
</style>
