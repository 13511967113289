<script lang="ts">
  import { PropType } from 'vue'
  import { IMention } from '@/interfaces/mention/Mention'

  export default {
    props: {
      items: {
        type: Array as PropType<IMention[]>,
        required: true,
      },

      command: {
        type: Function,
        required: true,
      },
    },

    data() {
      return {
        selectedIndex: 0,
      }
    },

    watch: {
      items() {
        this.selectedIndex = 0
      },
    },

    methods: {
      onKeyDown({ event }: { event: KeyboardEvent }) {
        if (event.key === 'ArrowUp') {
          this.upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          this.downHandler()
          return true
        }

        if (event.key === 'Enter') {
          this.enterHandler()
          return true
        }

        return false
      },

      upHandler() {
        this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
      },

      downHandler() {
        this.selectedIndex = (this.selectedIndex + 1) % this.items.length
      },

      enterHandler() {
        this.selectItem(this.selectedIndex)
      },

      selectItem(index: number) {
        const item = this.items[index]
        if (item) {
          const lowercaseKeys = (obj: IMention) => {
            return Object.keys(obj).reduce((acc, key) => {
              acc[key.toLowerCase()] = obj[key]
              return acc
            }, {} as IMention)
          }
          const newItem = lowercaseKeys(item)
          this.command(newItem)
        }
      },
    },
  }
</script>
<template>
  <div id="mention-items" class="items">
    <template v-if="items.length">
      <button v-for="(item, index) in items" :key="index" class="item" :class="{ 'is-selected': index === selectedIndex }" @click="selectItem(index)">
        {{ item.fullName }}
      </button>
    </template>
    <div v-else class="item">No result</div>
  </div>
</template>

<style lang="scss">
  .items {
    padding: 0.2rem;
    position: relative;
    border-radius: 0.5rem;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
    max-height: 100px;
    overflow-y: scroll;
    right: 12px;
  }

  .item {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    border-radius: 0.4rem;
    border: 1px solid transparent;
    padding: 0.2rem 0.4rem;

    &:hover {
      background-color: whitesmoke;
    }

    &.is-selected {
      border-color: var(--primary-color);
    }
  }
</style>
