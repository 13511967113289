<script setup async lang="ts">
  import MenuComponent from '@/components/MenuComponent/MenuComponent.vue'
  import LogoutListVue from '@/components/MenuComponent/LogoutList.vue'
  import ListNotifications from '@/components/Notifications/ListNotifications.vue'
  import { ref, onMounted, toRefs, computed, onUnmounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useUserStore } from '@/store/Account/UserStore'
  import { storeToRefs } from 'pinia'
  import PhotoQueueComponent from '@/components/PhotoQueueComponent.vue'
  // import { usePhotoQueueStore } from '@/store'
  import Message from 'primevue/message'
  import { requestUserGeolocationPermissions } from '@/services/geolocation'
  import { connectToZoho } from '@/utils/connectToZoho'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { captureException } from '@sentry/vue'
  import { i18n } from '@/i18n'
  import SearchBarComponent from '@/components/SearchBarComponent.vue'
  import { Capacitor } from '@capacitor/core'
  const { t } = i18n.global
  import { updateBadgeUser } from '../services/notifications'
  import { useSingalR } from '@/composables/useSignalR'
  import { baseUrl } from '@/constants'
  import * as signalR from '@microsoft/signalr'
  import { useSearchDialog } from '@/store/searchStore'

  const router = useRouter()

  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  import ListPhotoQueue from '@/components/Lists/ListPhotoQueue.vue'

  const queueStore = uploadNewQueuePhoto()
  const { queue } = toRefs(queueStore)
  const sidebarMenuActive = ref(false)
  const zohoIsConnecting = ref(false)
  const mouseOverMenu = ref(false)
  const showDialogPhotoQueue = ref(false)
  const versionApp = import.meta.env.PACKAGE_VERSION as string

  function open_sidenav() {
    sidebarMenuActive.value = true
  }

  function navigation_to(event: { path: string }) {
    sidebarMenuActive.value = false
    router.push(event.path)
  }

  const userStore = useUserStore()
  const { getFullName, zohoIntegration, geolocationPermissions, profile, appVersion: versionServer, badgeCount, id: userId } = storeToRefs(userStore)
  const URLWebsocket = computed(() => {
    return `${baseUrl}/Notification?userId=${userId.value}`
  })
  const config = {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  }
  const { startConnection, stopConnection: StopNotificationUser } = useSingalR(URLWebsocket.value, 'NewNotification', config)

  const { startConnection: StartConnectionZoho, stopConnection: StopZohoWebsocket } = useSingalR(URLWebsocket.value, 'zoho', config)

  // const photoQueueStore = usePhotoQueueStore()
  // const { getPhotoQueueLength } = storeToRefs(photoQueueStore)
  // const { openSideBarQueue } = photoQueueStore
  const userName = getFullName.value
  const menu = ref()
  const items = ref([{ label: 'Logout', icon: 'pi pi-fw pi-power-off' }])

  const searchDialog = useSearchDialog()
  const { handleChange } = searchDialog
  const { dialogSearch } = toRefs(searchDialog)
  const toggle = (event: MouseEvent) => {
    if (menu.value && menu.value.toggle) {
      menu.value.toggle(event)
    } else if (menu.value && menu.value[0] && menu.value[0].toggle) {
      menu.value[0].toggle(event)
    }
  }

  const leaveMenu = (event: MouseEvent) => {
    if (menu.value && menu.value.visible && mouseOverMenu.value) toggle(event)
  }

  async function requestGeoPermission() {
    try {
      await requestUserGeolocationPermissions()
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function initiateZohoConnection() {
    try {
      zohoIsConnecting.value = true

      await connectToZoho()

      zohoIsConnecting.value = false
    } catch (err) {
      captureException(err)
      console.log('err', err)

      zohoIsConnecting.value = false
    }
  }

  const openDownloadApp = () => {
    let url = ''
    if (Capacitor.getPlatform() === 'ios') url = `https://apps.apple.com/us/app/ihs-production/id6462755950`
    else url = `https://play.google.com/store/apps/details?id=com.infinityhomeservices.ihsproduction&amp;pli=1`
    const _url = new URL(url)
    console.log(_url.href)
    window.open(_url.href, '_blank', 'noreferrer')
  }

  const showNotifications = ref(false)
  const handleOpenNotifications = async () => {
    if (badgeCount.value !== 0) {
      await updateBadgeUser()
    }
    badgeCount.value = 0
    showNotifications.value = true
  }
  onMounted(() => {
    startConnection(() => {
      badgeCount.value = badgeCount?.value + 1
    })
    StartConnectionZoho((e) => {
      zohoIntegration.value = e ?? false
    })
    userStore.getUserInfo()
  })
  onUnmounted(() => {
    StopNotificationUser()
    StopZohoWebsocket()
  })
</script>

<template>
  <div class="layout-wrapper layout-static h-full">
    <div class="layout-topbar">
      <Button class="p-button-rounded p-button-text p-button-secondary layout-menu-button layout-topbar-button lg:hidden" @click="open_sidenav">
        <i class="pi pi-bars"></i>
      </Button>
      <router-link to="/" class="layout-topbar-logo">
        <img alt="Logo" src="/assets/icon/logo.png" height="30" />
        <span>{{ t('appLayout.appName') }}</span>
      </router-link>
      <div class="flex flex-row lg:hidden">
        <Button class="p-button-rounded p-button-text p-button-secondary layout-topbar-button" @click="handleChange(true)">
          <i class="pi pi-search"></i>
        </Button>
        <Button class="relative p-button-rounded p-button-text p-button-secondary layout-topbar-button border-round" @click="handleOpenNotifications">
          <i class="pi pi-bell"></i>
          <div
            v-if="badgeCount > 0"
            class="bg-red-500 border-round-2xl p-1 top-0 bottom-0 right-0 text-sm h-min absolute text-white"
            style="width: 20px; height: 20px !important; padding: 0; display: flex; align-items: center; justify-content: center; font-weight: bold; font-size: x-small !important"
          >
            {{ badgeCount }}
          </div>
        </Button>
      </div>
      <ul class="layout-topbar-menu hidden lg:flex origin-top">
        <li class="flex align-items-center gap-1 hover:bg-blue-50 border-round-3xl p-1 cursor-pointer">
          <Button
            type="button"
            :label="userName"
            aria-haspopup="true"
            aria-controls="overlay_tmenu"
            icon="pi pi-user"
            class="p-button-link"
            @mouseenter="toggle"
            @mouseleave="mouseOverMenu = false"
          />
          <TieredMenu id="overlay_tmenu" ref="menu" :model="items" :popup="true" class="p-3 text-color" @mouseenter="mouseOverMenu = true" @mouseleave="leaveMenu">
            <template #item="{ item }">
              <router-link v-slot="{ href, navigate }" to="/account/logout" custom>
                <a :href="href" class="flex gap-1 align-items-center text-color" @click="navigate">
                  <i :class="item.icon"></i>
                  <span>{{ item.label }}</span>
                </a>
              </router-link>
            </template>
          </TieredMenu>
        </li>

        <li>
          <Button class="relative p-button-rounded p-button-text p-button-secondary layout-topbar-button border-round" @click="showDialogPhotoQueue = true">
            <i class="pi pi-images"></i>
            <div
              v-if="queue.length > 0"
              class="bg-red-500 border-round-2xl p-1 top-0 bottom-0 right-0 text-sm h-min absolute text-white"
              style="
                width: 20px;
                height: 20px !important;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: x-small !important;
              "
            >
              {{ queue.length }}
            </div>
          </Button>
        </li>
        <li>
          <Button class="p-button-rounded p-button-text p-button-secondary layout-topbar-button" @click="handleChange(true)">
            <i class="pi pi-search"></i>
            <span>{{ t('appLayout.search') }}</span>
          </Button>
        </li>
        <li>
          <Button class="relative p-button-rounded p-button-text p-button-secondary layout-topbar-button border-round" @click="handleOpenNotifications">
            <i class="pi pi-bell"></i>
            <div
              v-if="badgeCount > 0"
              class="bg-red-500 border-round-2xl p-1 top-0 bottom-0 right-0 text-sm h-min absolute text-white"
              style="
                width: 20px;
                height: 20px !important;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: x-small !important;
              "
            >
              {{ badgeCount }}
            </div>
          </Button>
        </li>
      </ul>
    </div>

    <Sidebar v-model:visible="sidebarMenuActive" :base-z-index="10000">
      <div class="grid h-full flex-column">
        <div class="col-12 pb-4" style="height: max-content">
          <h3 style="margin-bottom: 1rem">{{ t('appLayout.menu') }}</h3>
          <p class="mb-0 font-bold text-sm">{{ userName }}</p>
          <p class="mb-0 text-xs">{{ profile }}</p>
        </div>
        <div class="col-12 flex-1 overflow-y-auto">
          <MenuComponent @navigation_to="navigation_to" />
        </div>
        <LogoutListVue style="height: 48px; padding: 0.5rem" @navigation_to="navigation_to" />
      </div>
    </Sidebar>

    <div class="layout-sidebar">
      <div class="layout-menu-container h-full flex flex-column">
        <div class="col-12 flex-1 overflow-y-auto">
          <MenuComponent @navigation_to="navigation_to" />
        </div>
        <LogoutListVue style="height: 48px; padding: 0.5rem" @navigation_to="navigation_to" />
      </div>
    </div>

    <div class="layout-main-container h-full">
      <div class="layout-main h-full">
        <PhotoQueueComponent />

        <div class="col home-messages">
          <PermissionRole nodo-name="settingsPermissions" key-name="zohoConnect">
            <Message v-if="!zohoIntegration" severity="error" :closable="false">
              <div class="flex flex-row align-items-center justify-content-between w-full">
                <span>Zoho has not been connected!</span>
                <Button :loading="zohoIsConnecting" :disabled="zohoIsConnecting" class="mb2" @click="initiateZohoConnection"> CONNECT NOW </Button>
              </div>
            </Message>
          </PermissionRole>

          <Message v-if="!geolocationPermissions" severity="error" :closable="false">
            <div class="flex flex-row align-items-center justify-content-between w-full">
              <span>Geolocation is not enabled!</span>
              <!-- TODO: loader and disabled ref -->
              <Button @click="requestGeoPermission">ENABLE NOW</Button>
            </div></Message
          >
        </div>
        <slot name="main"></slot>
      </div>
    </div>
  </div>
  <Dialog
    v-if="Capacitor.isNativePlatform()"
    :visible="Number(versionApp.replace(/\./g, '')) < Number(versionServer.replace(/\./g, ''))"
    class="w-full mx-2"
    style="max-width: 36rem"
    :modal="true"
    :dismissable-mask="true"
    :draggable="false"
    :show-header="false"
    :close-on-escape="true"
    :closable="true"
    content-class="border-round-lg p-2"
  >
    <div class="p-2 pt-4 flex flex-column justify-content-center align-items-center gap-3">
      <span class="text-center">{{ t('appLayout.appVersion') }}</span>
      <Button :label="t('appLayout.buttonUpdate')" @click="openDownloadApp" />
    </div>
  </Dialog>
  <Dialog v-model:visible="showDialogPhotoQueue" header="Photo Queue" :style="{ width: '25rem', maxHeight: '25rem' }" :position="'topright'" :modal="true" :draggable="false">
    <ListPhotoQueue @close-dialog="() => (showDialogPhotoQueue = false)" />
  </Dialog>
  <Dialog v-model:visible="showNotifications" header="Notifications" :style="{ width: '25rem' }" :position="'topright'" :modal="true" :draggable="false">
    <ListNotifications @close-dialog="() => (showNotifications = false)" />
  </Dialog>
  <SearchBarComponent v-if="dialogSearch" />
</template>

<style>
  @import 'lightgallery/css/lightgallery.css';
  @import 'lightgallery/css/lg-thumbnail.css';
  @import 'lightgallery/css/lg-zoom.css';
</style>

<style scoped lang="scss">
  .grid {
    display: flex;
    flex-wrap: nowrap;
  }
</style>
