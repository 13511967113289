<script setup lang="ts">
  import { ref, watch, toRefs } from 'vue'
  import Lightgallery from 'lightgallery/vue'
  import { useCommentsStore } from '@/store'
  import { InitDetail, AfterSlideDetail } from 'lightgallery/lg-events'
  import { delay } from '@/utils/delay'
  import lgZoom from 'lightgallery/plugins/zoom'
  import { Capacitor } from '@capacitor/core'
  import Dialog from 'primevue/dialog'
  import { i18n } from '@/i18n'

  type Iinstance = InitDetail['instance']

  type Iprops = {
    id: string
    photos: { id: string; url: string }[]
    speed?: number
    isAuthorized?: boolean
    deleteImage?: boolean
  }

  const { t } = i18n.global
  const props = defineProps<Iprops>()
  const emit = defineEmits(['deleteImage'])
  const { photos, deleteImage, isAuthorized } = toRefs(props)
  const commentsStore = useCommentsStore()
  const { openComments, init, destroy } = commentsStore
  const currentIndex = ref(0)
  const deleteImageDialogIsActive = ref(false)
  const instanceRef = ref<Iinstance | null>(null)

  const toggleDeleteDialog = () => {
    deleteImageDialogIsActive.value = !deleteImageDialogIsActive.value
  }

  const plugins = [lgZoom]

  const deleteImageFuntion = () => {
    if (instanceRef.value !== null) {
      const getItems = instanceRef.value.getItems()
      instanceRef.value.updateSlides(
        getItems.filter((_, i) => i !== currentIndex.value),
        currentIndex.value
      )
      emit('deleteImage', photos.value[currentIndex.value].id)
      toggleDeleteDialog()
    }
  }

  function onAfterOpen() {
    if (!isAuthorized.value) {
      return
    }
    init(activateComments)
    if (deleteImage?.value) {
      addButtonDelete(() => {
        toggleDeleteDialog()
      })
    }
  }

  const addButtonDelete = (fn: () => void) => {
    const commentButton = document.getElementById('comment-button2')

    if (!commentButton) {
      const lgContainer = document.querySelector('.lg-container.lg-show')

      if (lgContainer) {
        const downloadButton = lgContainer.querySelector('.lg-download')

        if (downloadButton) {
          const commentButton = document.createElement('button')
          commentButton.type = 'button'
          commentButton.id = 'comment-button2'
          commentButton.ariaLabel = 'Toggle Comments'
          commentButton.classList.add('lg-icon')
          commentButton.onclick = fn
          commentButton.innerHTML = `
          <i class="pi pi-trash" style="font-size: 1.5rem"></i>
        `
          const nextSibling = downloadButton.nextSibling
          if (nextSibling) {
            downloadButton.parentNode?.insertBefore(commentButton, nextSibling)
          } else {
            downloadButton.parentNode?.appendChild(commentButton)
          }
        }
      }
    }
  }

  async function activateComments() {
    const activePhoto = photos.value[currentIndex.value]
    if (activePhoto) {
      openComments(activePhoto.id)
    }
  }

  const onInit = (detail: InitDetail) => {
    if (!Capacitor.isNativePlatform()) {
      /* detail.instance.$inner.once('touchmove.lg', () => {
        return false
      }) */
      detail.instance.outer.once('touchstart.lg', () => {
        return false
      })
      /* detail.instance.$inner.once('touchend.lg', () => {
        return false
      }) */
      /* detail.instance.outer.once('mousedown.lg.zoom', () => {
        return false
      })
      detail.instance.outer.once('dblclick.lg', () => {
        return false
      }) */
    }

    instanceRef.value = detail.instance

    instanceRef.value.refresh()
  }

  const onBeforeSlide = (event: AfterSlideDetail) => {
    currentIndex.value = event.index
  }

  const onAfterClose = () => {
    destroy()
  }

  watch(photos, async () => {
    if (instanceRef.value !== null) {
      await delay(1000)
      instanceRef.value.refresh()
    }
  })
</script>

<template>
  <Lightgallery
    :settings="{
      speed: speed || 0,
      mobileSettings: {
        download: true,
        showCloseIcon: true,
        controls: false,
      },
      plugins: plugins,
    }"
    :on-init="onInit"
    :on-before-slide="onBeforeSlide"
    :on-after-open="onAfterOpen"
    :on-after-close="onAfterClose"
  >
    <slot />
    <Dialog v-model:visible="deleteImageDialogIsActive" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
      <template #header>
        <h5>{{ t('galleryComponent.dialog.removePhoto') }}</h5>
      </template>
      <div class="flex flex-column gap-2">
        <div style="font-size: 1rem; font-weight: 600; margin-bottom: 0.5rem">
          {{ t('galleryComponent.dialog.removePhotoDescription') }}
        </div>
        <div class="flex flex-row gap-2">
          <Button :label="t('galleryComponent.dialog.removePhotoCancel')" class="p-button-text" @click="toggleDeleteDialog" />
          <Button :label="t('galleryComponent.dialog.removePhotoConfirm')" class="p-button-danger" @click="deleteImageFuntion" />
        </div>
      </div>
    </Dialog>
  </Lightgallery>
</template>
