import { TShape } from '../shapes/types'

export const showTypTextUnit = (shape: TShape) => {
  if (shape.text_unit === 'ABC') {
    return shape?.text
  }

  if (shape.text_unit === 'FTIN') {
    return `${shape.ft}' ${shape.in}''`
  }
  return `${shape.m}m. ${shape.cm}cm.`
}
