import PinturaEnUs from './en_GB'
import PinturaEsMx from './es_ES'

export const pinturaEditorEnUS = {
  ...PinturaEnUs,
}

export const pinturaEditorEsMx = {
  ...PinturaEsMx,
}
