import { RouteRecordRaw } from 'vue-router'
import Login from '@/views/account/LoginIndex.vue'
import Logout from '@/views/account/LogoutIndex.vue'
import ForgotPassword from '@/views/account/ForgorPassword.vue'
import Policies from '@/views/account/PoliciesIndex.vue'
import Projects from '@/views/projects/ProjectsIndex.vue'
import Directory from '@/views/directory/DirectoryIndex.vue'
import Project from '@/views/projects/ProjectId.vue'
import Settings from '@/views/settings/SettingsIndex.vue'
import CrewDirectory from '@/views/crews/directory/CrewDirectoryIndex.vue'
import Integrations from '@/views/settings/IntegrationsIndex.vue'
import Leads from '@/views/leads/LeadsIndex.vue'
import Lead from '@/views/leads/leadId.vue'
import { isAuthenticatedGuard, blockBackPage, pendingPasswordGuard } from '@/router/guards'
import NotFound from '@/views/errors/NotFound.vue'
import CrewUser from '@/views/crews/user/CrewUser.vue'
import ConnectZohoSuccess from '@/views/account/ConnectZoho.vue'
import ChangePassword from '@/views/account/ChangePassword.vue'
import ShareImages from '@/views/projects/share/ShareImagesIndex.vue'
import Galleries from '@/views/galleries/GalleriesIndex.vue'
import PhotoQueue from '@/views/settings/PhotoQueue.vue'
import MyCalendar from '@/views/calendar/MyCalendar.vue'
import NearByLeads from '@/views/home/NearBy/NearByLeads.vue'
import NearByProjects from '@/views/home/NearBy/NearByProjects.vue'
import HomeIndex from '@/views/home/HomeIndex.vue'
import TagsIndex from '@/views/tags/TagsIndex.vue'
import ProjectMedia from '@/views/widgets/entity/EntityMedia.vue'
import DownloadApp from '@/views/download/DownloadApp.vue'
// import MapsView from '@/views/maps/MapsView.vue'

export const routes: Array<RouteRecordRaw> = [
  { path: '/', component: HomeIndex, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  // { path: '/maps', component: MapsView, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/directory', component: Directory, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/directory/:filter', component: Directory, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/leads', component: Leads, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/nearbyleads', component: NearByLeads, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/leads/:id/share-images', component: ShareImages, meta: { layout: 'Default', entity: 'Lead' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/leads/:id/:tab', component: Lead, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/projects', component: Projects, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/nearbyprojects', component: NearByProjects, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/projects/:id/share-images', component: ShareImages, meta: { layout: 'Default', entity: 'Project' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/calendar', component: MyCalendar, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/projects/:id/:tab', component: Project, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/crew-directory', component: CrewDirectory, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/my-company/:id', component: CrewUser, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/settings/:integration/authentication', component: Integrations, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/settings', component: Settings, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/queuePhotos', component: PhotoQueue, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/ConnectZohoSuccess', component: ConnectZohoSuccess, beforeEnter: [], meta: { layout: 'Account' } },
  { path: '/account/login', component: Login, beforeEnter: [blockBackPage], meta: { layout: 'Account' } },
  { path: '/account/policies', component: Policies, meta: { layout: 'Account' } },
  { path: '/account/forgot-password', component: ForgotPassword, beforeEnter: [], meta: { layout: 'Account' } },
  { path: '/account/change-password', component: ChangePassword, meta: { layout: 'Account' }, beforeEnter: [isAuthenticatedGuard, pendingPasswordGuard] },
  { path: '/account/logout', component: Logout, beforeEnter: [], meta: { layout: 'Account' } },
  { path: '/galleries/:company/:token', component: Galleries, meta: { layout: 'Account' } },
  { path: '/tags', component: TagsIndex, meta: { layout: 'Default' }, beforeEnter: [isAuthenticatedGuard] },
  { path: '/widgets/:tenant/:entity/:id/media', component: ProjectMedia, meta: { layout: 'Account' } },
  { path: '/home/:pathMatch(.*)*', component: DownloadApp, meta: { layout: 'Account' } },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound, meta: { layout: 'ErrorLayout' } },
]
