import { defineStore } from 'pinia'

interface ICommentsStore {
  isOpen: boolean
  photoId: string
}

export const useCommentsStore = defineStore({
  id: 'Comments',
  state: (): ICommentsStore => ({ isOpen: false, photoId: '' }),
  actions: {
    openComments(photoId: string) {
      this.photoId = photoId
      this.isOpen = true
    },
    closeComments() {
      this.isOpen = false
      this.photoId = ''
    },
    init(fn: () => void) {
      const commentButton = document.getElementById('comment-button') as HTMLButtonElement
      if (!commentButton) {
        const lgContainer = document.querySelectorAll('.lg-container.lg-show')[0] as HTMLDivElement
        if (lgContainer) {
          const downloadButton = lgContainer.getElementsByClassName('lg-download')[0] as HTMLAnchorElement
          if (downloadButton) {
            const commentButton = document.createElement('button')
            commentButton.type = 'button'
            commentButton.id = 'comment-button'
            commentButton.ariaLabel = 'Toggle Comments'
            commentButton.classList.add('lg-icon')
            commentButton.onclick = fn
            commentButton.innerHTML =
              '<svg style="width: 28px;height: 28px;fill: currentColor;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>comment-outline</title><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10Z" /></svg>'
            downloadButton.parentNode?.insertBefore(commentButton, downloadButton.nextSibling)
          }
        }
      }
    },
    destroy() {
      const commentButton = document.getElementById('comment-button') as HTMLButtonElement
      if (commentButton) commentButton.remove()
    },
  },
})
