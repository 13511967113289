<script lang="ts" setup>
  import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import api from '@/services/api'
  import Button from 'primevue/button'
  import CommentComponent from './CommentComponent.vue'
  import { i18n } from '@/i18n'
  import { captureException } from '@sentry/vue'
  import { useLeadStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import EditorContent from '@/components/EditorNotes/EditorContent.vue'
  import { EditorComponent } from '@/components/EditorNotes/editorInterface'
  import { computed } from 'vue'

  const { t } = i18n.global
  interface IEditedComment {
    id: string
    comment: string
  }
  interface Props {
    photoId: any
    entityType: 'Leads' | 'Projects'
  }

  const props = defineProps<Props>()
  // TODO: Commenting this because is assigned but never used
  // const { orientation } = useScreenOrientation()
  const toast = useToast()
  const leadStore = useLeadStore()
  const { fetchWorkContacts } = useLeadStore()
  const { projectWorkContacts } = storeToRefs(leadStore)
  const comments = ref<any[]>([])
  const noteInfo = ref('')
  const editorChild = ref<EditorComponent | null>(null)
  const submitLoading = ref<boolean>(false)

  const entity = computed(() => {
    return props?.entityType
  }).value
  const getWorkContacts = async () => {
    await fetchWorkContacts()
  }
  async function fetchComments() {
    try {
      const {
        data: { data: resp },
      } = await api.get(`/Api/${entity}/Photo/${props.photoId}/Comments`)
      comments.value = [...resp]
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function submitComment() {
    if (clearHtml(noteInfo?.value).trim().length === 0) return
    try {
      const html = noteInfo.value
      submitLoading.value = true
      const payload = { photoId: props.photoId, comment: html }
      await api.post(`/Api/${entity}/Photo/Comment`, payload)
      if (editorChild.value) {
        editorChild.value.commands.clearEditorContent()
      }
      submitLoading.value = false
      await fetchComments()
      toast.add({ severity: 'success', summary: t('commentsComponent.success'), detail: t('commentsComponent.commentSaved'), life: 3000 })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: t('commentsComponent.error'), detail: t('commentsComponent.errorCreating'), life: 3000 })
      submitLoading.value = false
    }
  }

  function updateCommentAfterEdit(editedComment: IEditedComment) {
    const comment = comments.value.find((n) => n.id === editedComment.id)
    if (comment) comment.comment = editedComment.comment
  }

  function removeCommentAfterDelete(deletedComment: { id: string }) {
    const commentIndex = comments.value.findIndex((n) => n.id === deletedComment.id)
    if (commentIndex > -1) comments.value.splice(commentIndex, 1)
  }
  const updatecontent = (content: string) => {
    noteInfo.value = content
  }
  watch(
    () => props.photoId,
    () => {
      fetchComments()
    }
  )

  const clearHtml = (html = '') => {
    const regex = /(<([^>]+)>)/gi
    return html.replace(regex, '')
  }

  function onKeydownEditor() {
    submitComment()
    if (editorChild.value) {
      editorChild.value.commands.focus()
    }
  }

  const handleApplyTouchStart = () => {
    const elementContainer = document.getElementById('note_content')
    elementContainer?.addEventListener('touchstart', function () {
      if (editorChild.value) {
        editorChild.value.commands.focus()
      }
    })
  }

  onMounted(async () => {
    document.addEventListener('click', handleApplyTouchStart)
    await fetchComments()
    await getWorkContacts()
    if (editorChild.value) {
      editorChild.value.commands.focus()
    }
  })

  onBeforeUnmount(() => {
    if (editorChild.value) {
      editorChild.value.commands.focus()
    }
    document.removeEventListener('click', handleApplyTouchStart)
  })
</script>

<template>
  <div class="pb-4 pt-2 pl-3">
    <p class="text-lg font-bold">{{ t('galleryComponent.titleComments') }}</p>
  </div>
  <section class="w-full flex-1 pl-3 pr-3" style="overflow-x: hidden; overflow-y: scroll">
    <template v-if="comments?.length > 0">
      <div v-for="comment in comments" :id="comment.id" :key="comment.id" class="flex flex-row mb-4">
        <CommentComponent :comment="comment" @update-comment="updateCommentAfterEdit" @delete-comment="removeCommentAfterDelete" />
      </div>
    </template>
    <template v-if="comments?.length === 0">
      <div class="flex flex-column align-items-center justify-content-center flex-grow-1 h-full">
        {{ t('commentsComponent.noComments') }}
      </div>
    </template>
  </section>
  <section class="w-full flex gap-2 p-2">
    <div class="w-full flex flex-row align-items-center gap-2">
      <EditorContent
        ref="editorChild"
        :items-suggestions="projectWorkContacts"
        class="flex-1"
        :placeholder="i18n.global.t('galleryCommentComponent.placeholder')"
        @create:note="onKeydownEditor"
        @update:note="updatecontent"
      />
      <Button
        :loading="submitLoading"
        icon="pi pi-send"
        :disabled="clearHtml(noteInfo).trim().length === 0 ? true : submitLoading"
        @click="submitComment"
        @touchstart="submitComment"
      />
    </div>
  </section>
</template>
<style>
  .tiptap p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  .p-inputtext {
    display: flex;
    overflow-x: hidden;
  }

  .ContainerBoxes {
    overflow: hidden;
  }
</style>
<style lang="scss" scoped>
  .ProseMirror {
    width: 300px;
    overflow: hidden;
    overflow-x: hidden;
  }
</style>
