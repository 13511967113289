<script setup lang="ts">
  import { computed, onMounted, ref, toRefs, watch } from 'vue'
  import { i18n } from '@/i18n'
  const { t } = i18n.global
  import { createRangeDays, DaysArray, useDaysOfMonth } from '@/hooks/useCalendar'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'

  type Props = {
    startDate: string | null
    endDate: string | null
    primaryColor: string
    secondaryColor: string
  }

  const props = defineProps<Props>()
  const emit = defineEmits(['close', 'apply'])
  const showCalendarSmall = ref(false)
  const { primaryColor, secondaryColor } = toRefs(props)

  const displayTime = new Date()
  const today = new Date()

  const yearRef = ref(displayTime.getFullYear())
  const monthRef = ref(displayTime.getMonth())
  const startDateRef = ref(props.startDate ?? today.toISOString().split('T')[0])
  const endDateRef = ref(props.endDate)

  const storeCalendar = useDaysOfMonth()
  const { dateArray } = toRefs(storeCalendar)
  const rangeDates = computed(() => {
    return createRangeDays(startDateRef.value, endDateRef.value)
  })

  onMounted(() => {
    storeCalendar.updateDaysArray(yearRef.value, monthRef.value)
  })
  watch([yearRef, monthRef], ([y, m]) => {
    storeCalendar.updateDaysArray(y, m)
  })

  const daysLabels = [
    t('calendar.days.sunday'),
    t('calendar.days.monday'),
    t('calendar.days.tuesday'),
    t('calendar.days.wednesday'),
    t('calendar.days.thursday'),
    t('calendar.days.friday'),
    t('calendar.days.saturday'),
  ]
  const months = {
    0: t('calendar.months.january'),
    1: t('calendar.months.february'),
    2: t('calendar.months.march'),
    3: t('calendar.months.april'),
    4: t('calendar.months.may'),
    5: t('calendar.months.june'),
    6: t('calendar.months.july'),
    7: t('calendar.months.august'),
    8: t('calendar.months.september'),
    9: t('calendar.months.october'),
    10: t('calendar.months.november'),
    11: t('calendar.months.december'),
  }
  const handleBackMonth = () => {
    if (monthRef.value <= 0) {
      yearRef.value = yearRef?.value - 1
      monthRef.value = 11
      return
    }
    monthRef.value = monthRef.value - 1
  }
  const handleNextMonth = () => {
    if (monthRef?.value >= 11) {
      yearRef.value = yearRef?.value + 1
      monthRef.value = 0
      return
    }

    monthRef.value = monthRef?.value + 1
  }
  const handleClickDay = (item: DaysArray) => {
    const dateOnlyDDMMYY = item.datesp

    if (startDateRef?.value?.length && endDateRef?.value?.length) {
      startDateRef.value = dateOnlyDDMMYY
      endDateRef.value = ''
      return
    }

    if (startDateRef?.value?.length) {
      if (new Date(dateOnlyDDMMYY) < new Date(startDateRef.value)) {
        startDateRef.value = dateOnlyDDMMYY
        endDateRef.value = ''
        return
      }
      endDateRef.value = dateOnlyDDMMYY
      return
    }
  }
  const isSelectedDayStyle = (item: DaysArray) => {
    const dateOnlyDDMMYY = item.datesp
    const isInTheRange = rangeDates.value?.includes(dateOnlyDDMMYY)
    const isInitialRange = startDateRef.value === dateOnlyDDMMYY || endDateRef.value === dateOnlyDDMMYY

    if (isInitialRange) {
      return {
        backgroundColor: `${primaryColor.value} !important`,
        color: 'white',
      }
    }
    if (!isInitialRange && isInTheRange) {
      return {
        borderColor: `${primaryColor.value} !important`,
        backgroundColor: `${secondaryColor.value} !important`,
        color: 'black',
      }
    }
    if (item.month === monthRef.value) {
      return {
        color: 'black',
      }
    }
    return {
      color: 'rgba(0,0,0,0.3)',
      backgroundColor: 'white',
    }
  }
  const handleClose = () => {
    showCalendarSmall.value = false
    startDateRef.value = props.startDate as string
    endDateRef.value = props.endDate
    monthRef.value = displayTime.getMonth()
    yearRef.value = displayTime.getFullYear()

    emit('close')
  }
  const handleApply = () => {
    emit('apply', { startDate: startDateRef.value, endDate: endDateRef.value })
    const temporalStartDate = new Date(startDateRef.value)
    monthRef.value = temporalStartDate.getMonth()
    yearRef.value = temporalStartDate.getFullYear()
    showCalendarSmall.value = false
  }
</script>

<template>
  <main class="relative">
    <button class="button_calendar" @click="showCalendarSmall = !showCalendarSmall">
      <div class="flex justify-content-center align-items-center">
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_230_2485)">
            <path
              d="M4.78571 0.5C5.37835 0.5 5.85714 0.974805 5.85714 1.5625V2.625H10.1429V1.5625C10.1429 0.974805 10.6217 0.5 11.2143 0.5C11.8069 0.5 12.2857 0.974805 12.2857 1.5625V2.625H13.8929C14.7801 2.625 15.5 3.33887 15.5 4.21875V5.8125H0.5V4.21875C0.5 3.33887 1.21987 2.625 2.10714 2.625H3.71429V1.5625C3.71429 0.974805 4.19308 0.5 4.78571 0.5ZM0.5 6.875H15.5V15.9062C15.5 16.7861 14.7801 17.5 13.8929 17.5H2.10714C1.21987 17.5 0.5 16.7861 0.5 15.9062V6.875ZM11.5156 10.627C11.8304 10.3148 11.8304 9.81016 11.5156 9.50137C11.2009 9.19258 10.692 9.18926 10.3806 9.50137L7.19978 12.6557L5.62612 11.0951C5.31138 10.783 4.80245 10.783 4.49107 11.0951C4.17969 11.4072 4.17634 11.9119 4.49107 12.2207L6.63393 14.3457C6.94866 14.6578 7.45759 14.6578 7.76897 14.3457L11.5156 10.627Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_230_2485">
              <rect width="15" height="17" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <section class="w-full gap-2 flex flex-row w-full align-items-center justify-content-around">
        <div v-if="props.startDate" class="button_calendar_date">
          <p v-if="props.startDate" class="button_calendar_date_label text-sm">
            {{ useDateFormatUtil(props.startDate, 'Do MMM, YYYY') }}
          </p>
        </div>
        <div
          v-if="props.startDate && props.endDate"
          :style="{
            width: '25%',
            height: '1px',
            backgroundColor: 'black',
          }"
        ></div>
        <div v-if="props.endDate" class="button_calendar_date">
          <p class="button_calendar_date_label text-sm">
            {{ useDateFormatUtil(props.endDate, 'Do MMM, YYYY') }}
          </p>
        </div>
        <p v-if="!props.startDate && !props.endDate" class="opacity-50 font-semibold">{{ t('calendar.empty') }}</p>
      </section>
    </button>

    <!-- ---------------------------------------------------------------------------------------- -->

    <section v-if="showCalendarSmall" class="calendar_container" style="z-index: 999999 !important">
      <header class="calendar_header">
        <button class="calendar_date_container" @click="handleBackMonth">
          <svg width="10" height="13" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.73127 1.51763C9.81711 1.43176 9.88422 1.33074 9.92876 1.22035C9.97331 1.10996 9.99442 0.992358 9.99088 0.874255C9.98735 0.756153 9.95924 0.639865 9.90816 0.532031C9.85709 0.424197 9.78405 0.326929 9.69321 0.24578C9.60237 0.164631 9.49551 0.101191 9.37873 0.059082C9.26196 0.0169728 9.13755 -0.00298116 9.01262 0.000359791C8.88768 0.00370074 8.76467 0.030271 8.6506 0.0785535C8.53653 0.126836 8.43363 0.195885 8.34779 0.281759L0.260084 8.37701C0.0930622 8.54401 0 8.76512 0 8.99495C0 9.22477 0.0930622 9.44588 0.260084 9.61288L8.34779 17.709C8.43307 17.7968 8.53594 17.8676 8.65043 17.9175C8.76492 17.9674 8.88875 17.9952 9.01472 17.9994C9.14069 18.0037 9.2663 17.9842 9.38424 17.9421C9.50219 17.9001 9.61012 17.8363 9.70177 17.7545C9.79342 17.6727 9.86696 17.5745 9.91811 17.4656C9.96927 17.3567 9.99703 17.2392 9.99977 17.1201C10.0025 17.001 9.9802 16.8825 9.9341 16.7716C9.88801 16.6607 9.81906 16.5596 9.73127 16.4741L2.26013 8.99495L9.73127 1.51763Z"
              fill="black"
            />
          </svg>
        </button>
        <p class="font-medium m-0 p-0">{{ months[monthRef as keyof typeof months] }} {{ yearRef }}</p>
        <button class="calendar_date_container" @click="handleNextMonth">
          <svg width="10" height="13" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.268733 16.4824C0.182891 16.5682 0.115782 16.6693 0.0712376 16.7796C0.0266924 16.89 0.00558376 17.0076 0.00911808 17.1257C0.0126524 17.2438 0.04076 17.3601 0.091835 17.468C0.14291 17.5758 0.215953 17.6731 0.306792 17.7542C0.397633 17.8354 0.504492 17.8988 0.621268 17.9409C0.738044 17.983 0.862449 18.003 0.987382 17.9996C1.11232 17.9963 1.23533 17.9697 1.3494 17.9214C1.46347 17.8732 1.56637 17.8041 1.65221 17.7182L9.73992 9.62299C9.90694 9.45599 10 9.23488 10 9.00505C10 8.77523 9.90694 8.55412 9.73992 8.38712L1.65221 0.290968C1.56693 0.203215 1.46406 0.132353 1.34957 0.0825005C1.23508 0.0326462 1.11125 0.00479698 0.98528 0.000566483C0.859307 -0.00366592 0.733701 0.0158081 0.615756 0.0578556C0.497812 0.0999012 0.389881 0.163681 0.298232 0.245493C0.206584 0.327305 0.133044 0.425514 0.0818863 0.53442C0.0307274 0.643326 0.00296974 0.760754 0.000226021 0.879887C-0.00251865 0.999018 0.019804 1.11748 0.0658979 1.22839C0.111992 1.33929 0.180939 1.44044 0.268733 1.52594L7.73987 9.00505L0.268733 16.4824Z"
              fill="black"
            />
          </svg>
        </button>
      </header>
      <div>
        <div class="calendar_custom_container">
          <article v-for="label in daysLabels" :key="label">
            <p class="opacity-50">
              {{ label }}
            </p>
          </article>
        </div>
        <ul class="calendar_custom_container">
          <li v-for="day in dateArray" :key="`${day.day}-${day.month}-${day?.year}`">
            <button class="calendar_custom_day_item w-full bg-transparent cursor-pointer" :style="isSelectedDayStyle(day)" @click="() => handleClickDay(day)">
              <p>
                {{ day.day }}
              </p>
            </button>
          </li>
        </ul>
      </div>
      <footer class="flex flex-row w-full gap-3">
        <button class="actions_button" @click="handleClose">{{ t('calendar.actions.cancel') }}</button>
        <button
          class="actions_button"
          :style="{
            backgroundColor: primaryColor,
            color: 'white',
          }"
          @click="handleApply"
        >
          {{ t('calendar.actions.apply') }}
        </button>
      </footer>
    </section>
  </main>
</template>

<style scoped>
  .actions_button {
    width: 100%;
    background-color: transparent;
    outline: 0;
    border: 0;
    padding: 10px 5px;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
  }
  .button_calendar_date_label {
    color: #666666;
    opacity: 0.75;
    font-weight: 500;
  }
  .button_calendar {
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }
  .button_calendar_date {
    border: 0px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25px;
    padding: 5px 10px;
    background: #f0f0f0;
  }

  .calendar_header {
    display: grid;
    grid-template-columns: 35px 1fr 35px;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 12px;
    align-content: center;
    justify-self: center;
    justify-items: center;
    align-items: center;
  }
  .calendar_date_container {
    border: 0px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 10px;
    cursor: pointer;
    background: #f0f0f0;
  }
  .calendar_dates_container {
    gap: 12px;
  }
  .calendar_container {
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    position: absolute;
    top: 60px;
    z-index: 999999 !important;
  }
  .calendar_custom_container {
    display: grid;
    grid-template-columns: repeat(7, 35px);
    list-style: none;
    list-style-type: none;
    width: 100%;
    gap: 12px;
    padding: 0px;
    margin: 0px;
  }
  .calendar_custom_day_item {
    height: 35px;
    border: 1px solid rgb(244, 244, 244);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
