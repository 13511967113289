export const loginIndexEnUS = {
  production: 'Production',
  byInfinityHomeServices: 'By Infinity Home Services',
  userNameOrEmail: 'User ID',
  password: 'Password',
  selectATenant: 'Select a Tenant',
  signIn: 'SIGN IN',
  cancel: 'CANCEL',
  invalidCredentials: 'Invalid credentials',
  attemptsLeft: 'attempts left',
  attemptsWait5Minutes: 'attempts wait 5 minutes',
  yourAccountIsNotAssigned: `Your Account is not assigned to an organization`,
  credentialsAreRequired: 'Credentials are required',
  policies: 'Policies',
  forgotPassword: 'Forgot password?',
  FACEID: 'Log in with Face ID',
}

export const loginIndexEsMX = {
  production: 'Producción',
  byInfinityHomeServices: 'Por Infinity Home Services',
  userNameOrEmail: 'ID de usuario',
  password: 'Contraseña',
  selectATenant: 'Seleccione un inquilino',
  signIn: 'INICIAR SESIÓN',
  cancel: 'CANCELAR',
  invalidCredentials: 'Credenciales inválidas',
  attemptsLeft: 'intentos restantes',
  attemptsWait5Minutes: 'intentos esperar 5 minutos',
  yourAccountIsNotAssigned: `Su cuenta no está asignada a una organización`,
  credentialsAreRequired: 'Se requieren credenciales',
  policies: 'Políticas',
  forgotPassword: '¿Olvidaste tu contraseña?',
  FACEID: 'Inicia sesion con Face ID',
}
