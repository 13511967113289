import { Capacitor } from '@capacitor/core'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { OrientationLockType } from '@vueuse/core'
import isIpadDevice from './isPad'
import isTabletAndroid from './IsTabletAndroid'

export const lockOrientationApp = async (orientation: OrientationLockType = 'portrait') => {
  const isIpad = await isIpadDevice()
  const isTabled = await isTabletAndroid()
  if (Capacitor.isNativePlatform() && !isIpad && !isTabled) await ScreenOrientation.lock({ orientation: orientation })
}

export const unlockOrientationApp = async () => {
  if (Capacitor.isNativePlatform()) await ScreenOrientation.unlock()
}
