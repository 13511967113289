export const reviewsCrewEnUS = {
  workmanship: 'Workmanship',
  comment: 'Comment',
  timeliness: 'Timeliness',
  edit: 'Edit review',
  cancel: 'Cancel',
  save: 'Save',
}

export const reviewsCrewEsMX = {
  workmanship: 'Calidad de Trabajo',
  comment: 'Comentario',
  timeliness: 'Puntualidad',
  edit: 'Editar revisión',
  cancel: 'Cancelar',
  save: 'Guardar',
}
