<script setup lang="ts">
  import { newIQueuePhoto } from '@/db/tables'
  import { ref, toRefs } from 'vue'

  type Iprops = {
    photo: newIQueuePhoto
  }

  const props = defineProps<Iprops>()
  const { photo } = toRefs(props)
  const isHorizontal = ref(false)
  const blobUrl = URL.createObjectURL(photo.value.file)
  const loadImage = async (src: string): Promise<{ width: number; height: number; src: string; aspectRatio: number }> => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        const aspectRatio = img.width / img.height
        resolve({ src, aspectRatio, width: img.width, height: img.height })
      }
    })
  }

  const handleload = async () => {
    const dimension = await loadImage(blobUrl)
    isHorizontal.value = dimension.width > dimension.height
  }
</script>

<template>
  <div class="cursor-pointer masonry-item shadow-2" :class="isHorizontal ? 'w-15rem' : 'w-8rem'">
    <figure class="relative">
      <div class="absolute h-full w-full border-rounded-md z-2 flex align-items-start justify-content-end p-2">
        <Tag
          :value="photo.status?.toLowerCase()"
          icon="pi pi-cloud-upload"
          :pt="{
            root: {
              style: {
                background: '#dee2e6',
              },
            },
            icon: { class: 'text-gray-700' },
            value: {
              class: 'text-xs capitalize font-medium text-gray-700',
            },
          }"
        />
      </div>
      <img :src="blobUrl" alt="media image" class="absolute h-full w-full border-round-md lazyload z-1" style="object-fit: cover" @load="handleload" />
    </figure>
  </div>
</template>

<style scoped lang="scss">
  .masonry-item-container {
    height: 200px;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
