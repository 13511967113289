<script lang="ts" setup>
  import LeadsIndexMobile from './LeadsIndexMobile.vue'
  import LeadsIndexWeb from './LeadsIndexWeb.vue'
  import { useBreakpointsStore } from '@/store'
  import { storeToRefs } from 'pinia'
  const breakpointStore = useBreakpointsStore()
  const { lg } = storeToRefs(breakpointStore)
</script>

<template>
  <LeadsIndexWeb v-if="lg" />
  <LeadsIndexMobile v-else />
</template>
