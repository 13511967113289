export const projectMediaEnUS = {
  noMedia: 'No Media',
  comments: 'COMMENTS',
  capture: 'TAKE PHOTOS',
  upload: 'UPLOAD MEDIA',
}

export const projectMediaEsMX = {
  noMedia: 'Sin medios',
  comments: 'COMENTARIOS',
  capture: 'TOMAR FOTOS',
  upload: 'CARGAR MEDIA',
}
