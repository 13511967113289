<script lang="ts" setup>
  import api from '@/services/api'
  import { onMounted, ref, toRefs } from 'vue'
  import { useRouter } from 'vue-router'

  import { i18n } from '@/i18n'
  import { DataTablePageEvent } from 'primevue/datatable'
  import { FilterMatchMode, FilterMatchModeOptions } from 'primevue/api'
  import generateFilterByString from '@/utils/filters/filterByString'
  import generateFilterByNumbers from '@/utils/filters/filterByNumber'
  import { useUserStore } from '@/store'
  import CardCrewDirectoryInvitation from '@/components/CardCrewDirectoryInvitation.vue'

  type ICrewCompany = {
    id: string
    crewCompanyId: null | string
    name: string
    email: null | string
    phone: null | string
    phoneClean: null | string
    logoUrl: null | string
    rating: number
    manager: {
      fullName: string
      email: string
      phone: string
      rating: null | number
    }
  }

  type filterValue<T> = {
    value: T | null
    matchMode: keyof FilterMatchModeOptions
  }

  type filterTypeData = {
    name: filterValue<string>
    rating: filterValue<number>
    email: filterValue<string>
    phoneClean: filterValue<string>
  }

  type sortType = {
    field: string
    order: number
  }

  type paginationType = {
    take: number
    skip: number
  }

  // sortArrayByKey omitted for lists
  // function sortArrayByKey<T>(array: T[], key: keyof T, order: -1 | 1) {
  //   return array.sort((a, b) => {
  //     if (a[key] === null && b[key] === null) {
  //       return 0
  //     }
  //     if (a[key] === null) {
  //       return 1
  //     }
  //     if (b[key] === null) {
  //       return -1
  //     }
  //     if (a[key] < b[key]) {
  //       return -1 * order
  //     }
  //     if (a[key] > b[key]) {
  //       return 1 * order
  //     }
  //     return 0
  //   })
  // }

  const { t } = i18n.global
  const router = useRouter()
  const crewCompaniesResponse = ref<ICrewCompany[]>([])
  const dataTable = ref<ICrewCompany[]>([])
  const sort = ref<sortType | null>(null)
  const loading = ref(false)
  const takeValue = ref(10)
  const skipValue = ref(0)
  const filters = ref({
    name: { value: '', matchMode: FilterMatchMode.CONTAINS },
    rating: { value: null, matchMode: FilterMatchMode.EQUALS },
    email: { value: '', matchMode: FilterMatchMode.CONTAINS },
    phoneClean: { value: '', matchMode: FilterMatchMode.CONTAINS },
  })

  const getCrewDirectoryList = async () => {
    try {
      loading.value = true
      const {
        data: { data: response },
      } = await api.get(`/Api/CrewCompanies`)
      crewCompaniesResponse.value = response
      dataTable.value = crewCompaniesResponse.value
    } finally {
      loading.value = false
    }
  }

  function getDataTable<T extends ICrewCompany>(array: T[], pagination: paginationType, filter?: filterTypeData) {
    let filteredArray: T[] = array
    const start = pagination.skip
    const end = start + pagination.take

    const applyFilterString = (key: keyof Omit<filterTypeData, 'rating'>) => {
      const constraint = filter?.[key]
      if (constraint && typeof constraint.value === 'string' && constraint.value !== '') {
        const fnFilterString = generateFilterByString<ICrewCompany>(constraint.matchMode, constraint.value, key as keyof ICrewCompany)
        filteredArray = filteredArray.filter((data) => fnFilterString(data))
      }
    }

    const applyFilterNumber = (key: keyof Pick<filterTypeData, 'rating'>) => {
      const constraint = filter?.[key]
      if (constraint) {
        const value = constraint.value
        if (value !== null && value !== '') {
          const numericValue = typeof value === 'number' ? value : parseFloat(value.toString())
          const fnFilterNumber = generateFilterByNumbers<ICrewCompany>(constraint.matchMode, numericValue, key as keyof ICrewCompany)
          filteredArray = filteredArray.filter(fnFilterNumber)
        }
      }
    }

    applyFilterString('name')
    applyFilterString('phoneClean')
    applyFilterString('email')
    applyFilterNumber('rating')

    dataTable.value = filteredArray
    return filteredArray.slice(start, end)
  }

  const onPage = (event: DataTablePageEvent) => {
    skipValue.value = event.first
    takeValue.value = event.rows
    const data = getDataTable(crewCompaniesResponse.value, { skip: event.first, take: event.rows }, filters?.value as filterTypeData)
    dataTable.value = data
  }
  const onFilter = () => {
    getDataTable(crewCompaniesResponse.value, { skip: skipValue.value, take: takeValue.value }, filters?.value as filterTypeData)
  }

  const formatPhoneNumber = (str: string) => {
    const cleaned = ('' + str).replace(/\D/g, '')
    if (cleaned.length > 10) return `+${cleaned}`
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    return null
  }
  const userStore = useUserStore()
  const { permissions } = toRefs(userStore)

  onMounted(async () => {
    await getCrewDirectoryList()
  })
</script>

<template>
  <div>
    <header class="mt-3">
      <div class="flex flex-column lg:flex-row lg:justify-content-between flex-nowrap md:flex-wrap gap-3">
        <div>
          <h1 class="mb-0">{{ t('crewDirectory.crewDirectory') }}</h1>
        </div>
      </div>
    </header>
  </div>

  <DataTable
    ref="dt"
    v-model:filters="filters"
    v-model:rows="takeValue"
    class="w-full pt-3"
    lazy
    :first="skipValue"
    :value="dataTable"
    size="large"
    paginator
    :rows-per-page-options="[5, 10, 20, 50]"
    data-key="id"
    filter-display="row"
    scroll-height="flex"
    :total-records="crewCompaniesResponse.length"
    :global-filter-fields="['name', 'rating', 'email', 'phone']"
    @page="onPage($event)"
    @filter="onFilter()"
  >
    <Column
      field="name"
      :header="t('crewDirectory.table.headers.name')"
      :show-filter-operator="false"
      :show-apply-button="false"
      :show-add-button="false"
      header-style="position: sticky; top: 0; z-index: 1; background: white; left: 0; width: 150px; min-width: 150px; border-top-left-radius: 10px"
      body-style="position: sticky; left: 0; background: white; width: 150px; min-width: 150px; z-index: 0;"
      style="min-width: 20rem; padding-right: 0px; padding-left: 1rem; background-color: white; position: sticky; top: 0; left: 0; z-index: 1"
    >
      <template #body="{ data }">
        <div class="flex align-items-center">
          <Avatar :image="`https://contacts.zoho.com/file?fs=thumb&ID=`" class="mr-2" size="large" shape="circle" />
          <h2 class="text-lg font-bold m-0 cursor-pointer" @click="router.push(`/my-company/${data.id}`)">
            {{ data.name }}
          </h2>
        </div>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" @input="filterCallback()" />
      </template>
    </Column>
    <Column
      field="rating"
      :header="t('crewDirectory.table.headers.rating')"
      :show-filter-operator="false"
      :show-add-button="false"
      style="min-width: 20rem; background-color: white"
    >
      <template #body="{ data }">
        <div class="flex align-items-center gap-2">
          <p v-if="data.rating === 0 || data.rating === null" class="flex gap-1 align-items-center text-lg">
            <span>{{ 'N/A' }}</span>
          </p>
          <p v-else class="flex gap-1 align-items-center text-lg">
            <i class="pi pi-star-fill" style="color: #ffca22; font-size: 1.2rem"></i><span>{{ data.rating }}</span>
          </p>
        </div>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by rating" @input="filterCallback()" />
      </template>
    </Column>
    <Column
      field="email"
      :header="t('crewDirectory.table.headers.email')"
      :show-filter-operator="false"
      :show-apply-button="false"
      :show-add-button="false"
      style="min-width: 20rem; background-color: white"
    >
      <template #body="{ data }">
        <a :href="'mailto:' + data?.email" class="text-right">{{ data?.email || '-' }}</a>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by email" @input="filterCallback()" />
      </template>
    </Column>
    <Column
      field="phoneClean"
      :header="t('crewDirectory.table.headers.phone')"
      :show-filter-operator="false"
      :show-apply-button="false"
      :show-add-button="false"
      style="min-width: 20rem; background-color: white"
    >
      <template #body="{ data }">
        <a :href="'tel:' + formatPhoneNumber(data?.phoneClean)" class="text-right">{{ formatPhoneNumber(data?.phoneClean) || '-' }}</a>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by phone" @input="filterCallback()" />
      </template>
    </Column>
    <Column
      v-if="permissions?.crewDirectoryPermissions?.canInviteCrew"
      field="Invitation"
      :header="t('crewDirectory.invitation')"
      :show-filter-operator="false"
      :show-apply-button="false"
      :show-add-button="false"
      :sortable="false"
      header-style="background: white; border-top-right-radius: 10px"
      style="min-width: 20rem; background-color: white"
    >
      <template #body="{ data }">
        <CardCrewDirectoryInvitation :id="data?.id" :name="data?.name" />
      </template>
    </Column>
  </DataTable>

  <div v-if="!crewCompaniesResponse?.length && !loading">No hay crews disponibles</div>
</template>
