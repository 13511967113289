export const tagsEnUS = {
  createTag: 'Create Tag',
  createTagPlaceholder: 'Please enter tag name',
  createTagHeader: 'Create Tag',
  createTagSuccess: 'Create Tag Success',
  createTagError: 'Create Tag Error',
  createButton: 'Create',
  editTag: 'Edit Tag',
  editTagPlaceholder: 'Please enter tag name',
  editTagHeader: 'Edit Tag',
  editTagSuccess: 'Edit Tag Success',
  editTagError: 'Edit Tag Error',
  editButton: 'updated tag',
  deleteTag: 'Delete Tag',
  deleteTagHeader: 'Delete Tag',
  deleteTagDescription: 'Are you sure you want to delete this tag?',
  deleteTagButton: 'Delete',
  deleteTagButtonCancel: 'Cancel',
  deleteTagError: 'Delete Tag Error',
  deleteTagSuccess: 'Delete Tag Success',
}

export const tagsEsMX = {
  createTag: 'Crear Etiqueta',
  createTagPlaceholder: 'Por favor ingrese el nombre de la etiqueta',
  createTagHeader: 'Crear Etiqueta',
  createTagSuccess: 'Crear Etiqueta Exitosamente',
  createTagError: 'Error al crear etiqueta',
  createButton: 'Crear',
  editTag: 'Editar Etiqueta',
  editTagPlaceholder: 'Por favor ingrese el nombre de la etiqueta',
  editTagHeader: 'Editar Etiqueta',
  editTagSuccess: 'Editar Etiqueta Exitosamente',
  editTagError: 'Error al editar etiqueta',
  editButton: 'Actualizar etiqueta',
  deleteTag: 'Eliminar Etiqueta',
  deleteTagHeader: 'Eliminar Etiqueta',
  deleteTagDescription: '¿Estás seguro de que deseas eliminar esta etiqueta?',
  deleteTagButton: 'Eliminar',
  deleteTagButtonCancel: 'Cancelar',
  deleteTagError: 'Error al eliminar etiqueta',
  deleteTagSuccess: 'Eliminar Etiqueta Exitosamente',
}
