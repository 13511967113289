<script setup lang="ts">
  import { i18n } from '@/i18n'
  import { useLeadStore, useProjectStore } from '@/store'
  import { Capacitor } from '@capacitor/core'
  import { storeToRefs } from 'pinia'
  import { computed, onMounted, reactive, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import moment from 'moment'
  import ProjectHeaderInfo from '../ProjectHeaderInfo.vue'
  import { TPhotoContent } from '@/constants/types'
  import { Share } from '@capacitor/share'
  import { useToast } from 'primevue/usetoast'
  import ProjectMediaImageComponent from '@/components/ProjectMedia/ProjectMediaImageComponent.vue'
  import GalleryHomeComponent from '@/components/GalleryHomeComponent.vue'

  const { t } = i18n.global
  const projectStore = useProjectStore()
  const leadStore = useLeadStore()
  const route = useRoute()
  const router = useRouter()
  const toast = useToast()
  const entity = route.meta.entity as 'Project' | 'Lead'
  const entityStore = entity === 'Project' ? projectStore : leadStore
  const { fetchProjectById, fetchProjectMedia, setProjectMedia, shareMedia } = entityStore
  const { projectMedia, project, paginationMedia, loadingProjectMedia } = storeToRefs(entityStore)
  const takeValue = ref(25),
    skipValue = ref(0),
    isUploading = ref(false),
    isProjectMediaFirstRequestDone = ref(false),
    selectedPhotos = ref<string[]>([])

  const dialog = reactive({ isVisible: false, endpoint: '' })
  const entityId = route.params.id as string

  async function fetchProject() {
    setProjectMedia([])
    try {
      await fetchProjectById(entityId)
    } catch (error) {
      console.error(error)
    }
  }

  watch(skipValue, async () => {
    await fetchProjectMedia(entityId, { skip: skipValue.value, take: takeValue.value })
  })

  const getIsSelectedPhoto = computed(() => (photoId: string) => !!selectedPhotos.value.find((p) => p === photoId))

  function handleSelectedImages(photo: TPhotoContent) {
    const indexExists = selectedPhotos.value?.findIndex((p) => p === photo.id)
    if (indexExists < 0) {
      selectedPhotos.value.push(photo.id)
    } else {
      selectedPhotos.value.splice(indexExists, 1)
    }
  }

  const getUrl = (url: string) => {
    const match = url.match(/^(?:\w+:\/\/)?([^/]+)/)
    if (match) return `https://${match[1]}`
    return 'https://ihsproduction.infinityhomeservices.com'
  }

  async function handleShareImages() {
    isUploading.value = true

    try {
      const { data } = await shareMedia({
        entity,
        entityId,
        showDetails: true,
        expiration: moment().add(1, 'weeks').toISOString(), // falta definir tiempo
        photoIds: selectedPhotos.value,
      })
      const newUrl = new URL(getUrl(window.location.origin)).origin
      dialog.endpoint = newUrl + `/galleries/${data.data.data.organization}/${data.data.data.token}`
      dialog.isVisible = true
    } catch (error) {
      console.error(error)
    }
    isUploading.value = false
  }

  async function handleShareLink() {
    const platform = Capacitor.isNativePlatform() ? Share : navigator

    try {
      await platform.share({ url: dialog.endpoint, title: `IHS Production - shared gallery` })
    } catch (error) {
      console.error(error)
    }
    handleCloseDialog()
  }

  function handleCloseDialog(isFromBtn = false) {
    if (isFromBtn) {
      navigator.clipboard.writeText(dialog.endpoint)
      toast.add({ severity: 'success', summary: 'Success', detail: t('projectId.linkCopyToClipboard'), life: 3000 })
    }
    selectedPhotos.value = []
    dialog.endpoint = ''
    dialog.isVisible = false
  }

  function handleGoBack() {
    router.push(`/${entity.toLowerCase()}s/${entityId}/media`)
  }
  const content = ref<HTMLDivElement>()
  const activeIndex = ref(0)
  const displayCustom = ref(false)

  const loadContent = async () => {
    if (paginationMedia?.value?.nextPage !== null) {
      setTimeout(() => {
        if (content?.value) {
          if (content?.value?.scrollTop + content?.value?.clientHeight >= content?.value?.scrollHeight) {
            skipValue.value = skipValue.value + takeValue.value
            loadContent()
          }
        }
      }, 2000)
    }
  }

  const imageClick = (index: number) => {
    activeIndex.value = index
    displayCustom.value = true
  }
  const handleClose = () => {
    activeIndex.value = 0
    displayCustom.value = false
  }
  onMounted(() => {
    fetchProject()
    fetchProjectMedia(entityId, { take: takeValue?.value, skip: 0 })
    loadContent()
    if (content?.value) {
      if (paginationMedia?.value?.nextPage !== null) {
        content.value.addEventListener('scroll', () => {
          if (content?.value) {
            if (content.value.scrollTop + content.value.clientHeight >= content.value.scrollHeight - 10 && !loadingProjectMedia?.value) {
              if (paginationMedia?.value?.nextPage !== null) {
                skipValue.value = skipValue.value + takeValue.value
              }
            }
          }
        })
      }
    }
  })
</script>
|
<template>
  <ProjectHeaderInfo :project="project" :go-back="handleGoBack" :is-uploading="isUploading" />
  <main class="flex flex-column" style="height: 80vh">
    <div class="col-12">
      <div class="flex align-items-center justify-content-between title-tab">
        <div class="flex text-xl align-items-center">
          <div class="flex pr-2">
            <i class="pi pi-images" />
          </div>
          <div class="flex text-xl">
            {{ $t('projectId.shareImages') }}
          </div>
        </div>
      </div>
    </div>
    <main ref="content" class="grid w-full overflow-y-scroll overflow-x-hidden">
      <div v-for="(image, index) of projectMedia" :key="`${image?.id}` ?? index" class="relative" style="width: 153px !important">
        <div class="masonry-item-container col col-6 sm:col-4 md:col-3 lg:col-3 xl:w-full lg-item-container lg-item relative p-1" style="height: 153px" @click="imageClick(index)">
          <ProjectMediaImageComponent
            :photo="image"
            selectable
            :is-tag-available="false"
            :is-upload-type-available="false"
            :is-selected="getIsSelectedPhoto(image.id)"
            @selected="handleSelectedImages"
          />
        </div>
      </div>
    </main>
    <GalleryHomeComponent
      :active-index="activeIndex"
      :display-custom="displayCustom"
      :images="projectMedia"
      :loading-next-images="loadingProjectMedia"
      :show-comments="false"
      :show-delete="false"
      :show-edit="false"
      :show-favorite="false"
      @on-close="handleClose"
    />
    <section
      v-if="!projectMedia.length && !loadingProjectMedia && isProjectMediaFirstRequestDone"
      class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem"
    >
      <h6>{{ $t('projectMedia.noMedia') }}</h6>
    </section>

    <div v-if="selectedPhotos.length" class="sticky z-5 col-12 bg-white py-3" style="bottom: 0">
      <div class="flex flex-wrap justify-content-between">
        <Button :label="`${$t('unselectAll')} (${selectedPhotos.length}) `" text size="small" :loading="isUploading" @click="selectedPhotos = []" />
        <Button size="small" :label="$t('share')" :icon="'pi pi-send'" class="shadow-4" :loading="isUploading" @click="handleShareImages" />
      </div>
    </div>
  </main>

  <Dialog v-model:visible="dialog.isVisible" modal :header="$t('projectId.shareImages')" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <div>
      {{ $t('projectId.sharedSuccessfully') }}
    </div>
    <div class="mt-4">{{ $t('projectId.generatedLink') }}:</div>
    <div>
      <a :href="dialog.endpoint" target="_blank" class="text-lg" style="word-wrap: break-word">{{ dialog.endpoint }}</a>
    </div>
    <div class="flex justify-content-between mt-5">
      <Button :label="`${$t('share')} URL`" icon="pi pi-share-alt" outlined size="small" @click="handleShareLink" />
      <Button :label="`${$t('copy')} URL`" icon="pi pi-copy" size="small" @click="handleCloseDialog(true)" />
    </div>
  </Dialog>

  <Toast />
</template>
