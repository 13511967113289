import { IQueuePhoto } from '@/db/tables'
import { base64ToBlob } from './base64Toblob'
import { uploadPhotosBlob } from './uploadPhotoBlob'
import { uploadVideosBlob } from './uploadVideoblob'

const processMediaInputAndGetMediaIds = async (media: IQueuePhoto[]) => {
  let mediaIds: string[] = []

  if (!media.length || media === undefined || media === null) return []

  const videosMedia = media.filter((video) => video.type_media === 'VIDEO')
  if (videosMedia.length) {
    const responseMediaSignedVideos = await uploadVideosBlob(videosMedia)
    const videosIds = responseMediaSignedVideos.map((video) => video.id)
    mediaIds = mediaIds.concat(videosIds)
  }

  const photosMedia = media.filter((photo) => photo.type_media === 'IMAGE')

  if (photosMedia.length) {
    const getPhotoBlobs = photosMedia.map((photo) => {
      const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
      return { ...photo, file: blob }
    })

    const responses = await uploadPhotosBlob(getPhotoBlobs)

    const photosIds = responses.map((photo) => photo.id)
    mediaIds = mediaIds.concat(photosIds)
  }

  return mediaIds
}
export default processMediaInputAndGetMediaIds
