// composables/useShapes.js
import { reactive, toRefs } from 'vue'
import { Shapes, TShape } from '../shapes/types'
import { templateShapeCopy } from '../templates/shape'

export type MagnifierTypeEvent = 'ARROW_HEAD_POINT' | 'POINT_BOX_0' | 'POINT_BOX_2' | 'POINT_BOX_4' | 'POINT_BOX_6' | `POINT_BOX${number}`

type ShapeCallEvent = (shape: TShape, magnifier?: MagnifierTypeEvent | null) => void

export type TShapeEvents = {
  onDragMoveStart: ShapeCallEvent
  onDragMoving: ShapeCallEvent
  onDragStop: ShapeCallEvent
  onPressShape: ShapeCallEvent
  onDoublePressShape: ShapeCallEvent
  onCopyShape: ShapeCallEvent
  onDeleteShape: ShapeCallEvent
  onTextShape: ShapeCallEvent
}

export type ImageStage = {
  width: number
  height: number
  url: string
  scale: number
  rotation: number
}

export type VariantThicknessDevice = {
  thickness: number
  hitbox: number
  circle_hitbox: number
  sticker_width: number
  sticker_height: number
  sticker_thickness: number
  arrow_head_scale: number
  arrow_thickness: number
  box_width: number
  box_height: number
  text_fontsize: number
  text_opions_circles: number
  shadow_range: number
}

export type TShapeProps = {
  shape: TShape
  draggable: boolean
  isSelected: boolean
  image: ImageStage
  device: VariantThicknessDevice
}

type History = {
  index: number
  shapes: Shapes
  type: 'INITIAL' | 'ACTUALLY' | 'CLEAR_ALL'
}

// Estado reactivo global
const state = reactive({
  shapeId: null as string | null,
  history: [{ index: 0, shapes: {}, type: 'INITIAL' }] as History[],
  shapes: {} as Shapes,
  counter: 0,
})

// Actions

function setNewHistory() {
  if (Object.keys(state.shapes).length === 0) return

  // Asegura que se eliminen los futuros estados en el historial
  const newHistory = state.history.slice(0, state.counter + 1)

  newHistory.push({
    index: state.counter + 1,
    shapes: JSON.parse(JSON.stringify(state.shapes)) as Shapes,
    type: 'ACTUALLY',
  })

  state.history = newHistory
  state.counter = newHistory.length - 1
}

function handleAddHistory() {
  setNewHistory()
}

function handleGoBack() {
  if (state.counter <= 0) return

  state.counter--
  state.shapes = JSON.parse(JSON.stringify(state.history[state.counter].shapes)) as Shapes
}

function handleGoForward() {
  if (state.counter >= state.history.length - 1) return

  state.counter++
  state.shapes = JSON.parse(JSON.stringify(state.history[state.counter].shapes)) as Shapes
}

function handleClearStage() {
  const lastHistory = state.history[state.history.length - 1]

  if (lastHistory?.type === 'CLEAR_ALL') return

  state.history.push({
    index: state.history.length,
    shapes: {},
    type: 'CLEAR_ALL',
  })

  state.counter = state.history.length - 1
  state.shapes = {}
}

function handleCreateShape(shape: TShape) {
  const newState = { ...state.shapes, [shape.id]: { ...shape } }
  state.shapes = newState
  handleAddHistory()
}

function handleUpdateShape(updateShape: TShape) {
  const updatedShapes = { ...state.shapes, [updateShape.id]: { ...updateShape } }
  state.shapes = updatedShapes
  handleAddHistory()
}

function handleDeleteShape(shape: TShape) {
  const { id } = shape
  const newState = { ...state.shapes }
  delete newState[id]
  state.shapes = newState
  handleAddHistory()
}
function handleCopyShape(shape: TShape) {
  const newCopy = templateShapeCopy(JSON.parse(JSON.stringify(shape)))
  const newState = { ...state.shapes, [newCopy.id]: { ...newCopy } }
  state.shapes = newState
  handleAddHistory()
  return newCopy
}

function handleDeleteOrderShape(shape: TShape) {
  const { id } = shape
  const newState = { ...state.shapes }
  delete newState[id]
  state.shapes = newState
}

function handleSetJSON(sps: Shapes) {
  state.shapes = sps
  state.history = state.history.map((e) => {
    if (e.index === 0 && e.type === 'INITIAL') {
      return {
        index: 0,
        shapes: sps,
        type: 'INITIAL',
      }
    }
    return e
  })
}

function handleOrderShape(updateShape: TShape) {
  const updatedShapes = { ...state.shapes, [updateShape.id]: { ...updateShape } }
  state.shapes = updatedShapes
}

function handleSelectShapeId(id: string | null) {
  state.shapeId = id
}

function handleResetStore() {
  state.history = []
  state.shapeId = null
  state.shapes = {}
  state.counter = 0
}

export function useShapes() {
  return {
    ...toRefs(state),
    setNewHistory,
    handleAddHistory,
    handleGoBack,
    handleGoForward,
    handleClearStage,
    handleCreateShape,
    handleUpdateShape,
    handleDeleteShape,
    handleDeleteOrderShape,
    handleSetJSON,
    handleCopyShape,
    handleOrderShape,
    handleSelectShapeId,
    handleResetStore,
  }
}
