export const LeadCardComponentEnUS = {
  noActualStartDate: 'No actual start date provided',
  noCustomerNumber: 'No Customer Number Provided',
  noProjectInterests: 'No Project Interests Provided',
}

export const LeadCardComponentEsMX = {
  noActualStartDate: 'No se proporcionó fecha de inicio',
  noCustomerNumber: 'No se proporcionó Customer Number',
  noProjectInterests: 'No se proporcionaron intereses del proyecto',
}
