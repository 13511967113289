<script lang="ts" setup>
  import api from '@/services/api'
  import { computed, onMounted, onUnmounted, ref, type Ref, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import ProjectPunchListAdd from '@/views/projects/punch_list/ProjectPunchListAdd.vue'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import { i18n } from '@/i18n'

  import { IPhotoData, useBreakpointsStore, useCloseOutStore, useProjectStore } from '@/store'
  import Accordion from 'primevue/accordion'
  import AccordionTab from 'primevue/accordiontab'
  import TaskListAdd from './TaskListAdd.vue'
  import TasksAdd from './TasksAdd.vue'
  import ListItemComponent from '@/components/PunchListCamera/SideBar/ListTaskComponent/TaskItem.vue'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { ETaskStatus } from '@/enums/punch-list/task/task.enum'
  import { ITask } from '@/interfaces/punch-list/task/task.interface'
  import { IActivePunchList, IPunchList } from '@/interfaces/punch-list/punch-list.interface'
  import { useToast } from 'primevue/usetoast'
  import { ITaskList } from '@/interfaces/punch-list/task-list/task-list.interface'

  const { t } = i18n.global
  const projectStore = useProjectStore()
  const { fetchProjectPunchList, setActivePunchList, setActiveTaskListId, resetProjectPunchList } = projectStore
  const { projectPunchList, loadingProjectPunchList, activePunchList, activeTaskListId, project } = storeToRefs(projectStore)
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const toast = useToast()
  const props = defineProps({ projectId: { type: String, required: true } })
  const punchListDialogIsActive: Ref<boolean> = ref(false)
  const taskListDialogIsActive: Ref<boolean> = ref(false)
  const loadingDeleting = ref(false)
  const tasksDialogIsActive: Ref<boolean> = ref(false)
  const showDialogDeletePunchlist = ref(false)
  const punchListDialogIsLoading: Ref<boolean> = ref(false)
  const punchListAssignToUserFieldValue = ref(null)
  const punchListAssignToUserSuggestions: Ref<any[]> = ref([])
  const activePunchListIndex: Ref<number | undefined> = ref(undefined)
  const activeTaskListIndex: Ref<number | undefined> = ref(undefined)

  type IPunchStore = {
    punchlist: IPunchList | null
    task: ITask | null
    taskList: ITaskList | null
    type: 'PUNCHLIST' | 'TASKLIST' | 'TASK' | 'NONE'
  }
  const punchlistDialogState = ref<IPunchStore>({
    punchlist: null,
    taskList: null,
    task: null,
    type: 'NONE',
  })
  const punchListCameraStore = useCloseOutStore()
  const { startCamera: startPunchListCamera } = punchListCameraStore
  const { isActive: isPunchListActive } = storeToRefs(punchListCameraStore)

  function openCompletePunchList(punchListId: string) {
    const data = { type: PhotoTypeEnum.TASK_AFTER, punchListId } as IPhotoData
    startPunchListCamera(props.projectId, data)
  }

  const activateTaskListDialog = async (tab: any) => {
    const punchlist = projectPunchList.value[tab.index]
    await getPunchList(punchlist.id)
  }
  const updateStatusTaskRequest = async (task: ITask, taskStatus: ETaskStatus) => {
    const payload = { status: taskStatus }
    await api.patch(`/Api/Projects/PunchLists/TaskLists/Tasks/${task.id}/UpdateStatus`, payload)
    task.status = taskStatus
    task.isCompleted = true
    return task
  }

  const updateStatusTask = async (task: ITask, taskStatus: ETaskStatus) => {
    await updateStatusTaskRequest(task, taskStatus)
    // search task in taskList
    const taskList = activePunchList.value?.taskLists.find((taskList) => taskList.id === activeTaskListId.value)
    if (taskList) {
      const taskIndex = taskList.tasks.findIndex((taskItem) => taskItem.id === task.id)
      if (taskIndex !== -1) taskList.tasks[taskIndex] = task
    }
  }

  function clearActivePunchList() {
    setTimeout(() => {
      setActivePunchList(null)
    }, 300)
  }

  const getPunchList = async (id: string) => {
    const {
      data: { data: resp },
    } = await api.get(`/Api/Projects/PunchLists/${id}`)
    await setActivePunchList(resp)
    return resp
  }

  const updateStatusCheckTask = async (task: ITask) => {
    try {
      const payloadOptions = { [ETaskStatus.COMPLETED]: ETaskStatus.NOT_COMPLETED, [ETaskStatus.NOT_COMPLETED]: ETaskStatus.COMPLETED, [ETaskStatus.NA]: ETaskStatus.NOT_COMPLETED }
      await updateStatusTask(task, payloadOptions[task.status])
    } catch (error) {
      console.log('error', error)
    }
  }

  const resetPunchListDialogModel = () => {
    punchListDialogIsLoading.value = false
    punchListAssignToUserFieldValue.value = null
    punchListAssignToUserSuggestions.value = []
  }

  function closeAddDialog() {
    punchListDialogIsActive.value = false
    fetchProjectPunchList(props.projectId)
  }

  function closeAddTaskListDialog() {
    taskListDialogIsActive.value = false
    if (activePunchList.value) {
      getPunchList(activePunchList.value.id)
    }
  }

  function closeAddTasksDialog() {
    tasksDialogIsActive.value = false
    if (activePunchList.value) {
      getPunchList(activePunchList.value.id)
    }
  }

  const activatePunchListDialog = () => {
    punchListDialogIsActive.value = true
    resetPunchListDialogModel()
  }

  const openCreateTaskListDialog = async () => {
    taskListDialogIsActive.value = true
  }

  const openCreateTaskDialog = async (taskList: any) => {
    await setActiveTaskListId(taskList.id)
    tasksDialogIsActive.value = true
  }

  function setActiveTaskListIndex(event: { index: number; originalEvent: MouseEvent }) {
    activeTaskListIndex.value = event.index
    setActiveTaskListId(activePunchList.value?.taskLists[activeTaskListIndex.value].id || null)
  }
  const handleAPIDeleteGeneral = async () => {
    const type = punchlistDialogState.value?.type
    try {
      loadingDeleting.value = true
      if (type === 'PUNCHLIST') {
        const punchListId = punchlistDialogState.value?.punchlist?.id
        if (!punchListId) return
        await api.delete(`/Api/Projects/PunchLists/${punchListId}`)
        projectPunchList.value = projectPunchList.value.filter((e) => e.id !== punchListId)
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: t('projectPunchList.punchlistDeletedSuccessfully'),
          life: 3000,
        })
      }

      if (type === 'TASKLIST') {
        const taskListId = punchlistDialogState.value?.taskList?.id
        if (!taskListId) return
        await api.delete(`/Api/Projects/PunchLists/TaskLists/${taskListId}`)
        const newTasklists = activePunchList?.value?.taskLists?.filter((e) => e.id !== taskListId) ?? []
        activePunchList.value = {
          ...(activePunchList.value ?? ({} as IActivePunchList)),
          taskLists: newTasklists,
        }
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: t('projectPunchList.tasklistDeletedSuccessfully'),
          life: 3000,
        })
      }
      if (type === 'TASK') {
        const taskId = punchlistDialogState.value?.task?.id
        const taskListId = punchlistDialogState.value?.taskList?.id
        if (!taskId) return
        await api.delete(`/Api/Projects/PunchLists/TaskLists/Tasks/${taskId}`)

        activePunchList.value = {
          ...(activePunchList.value ?? ({} as IActivePunchList)),
          taskLists:
            activePunchList.value?.taskLists?.map((e) =>
              e.id === taskListId
                ? {
                    ...e,
                    tasks: e?.tasks?.filter((task) => task.id !== taskId),
                  }
                : e
            ) ?? [],
        }
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: t('projectPunchList.taskDeletedSuccessfully'),
          life: 3000,
        })
      }

      showDialogDeletePunchlist.value = false
    } catch (e: any) {
      const errorMessage = e?.response?.data?.responseException?.exceptionMessage?.message
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage ?? '',
        life: 3000,
      })
    } finally {
      loadingDeleting.value = false
    }
  }
  const handleDeletePunchlist = (values: Partial<IPunchStore>) => {
    showDialogDeletePunchlist.value = true
    punchlistDialogState.value = {
      task: values?.task ?? null,
      taskList: values?.taskList ?? null,
      punchlist: values?.punchlist ?? null,
      type: values?.type ?? 'NONE',
    }
  }

  const dialogModal = computed(() => {
    if (punchlistDialogState.value.type === 'PUNCHLIST') {
      return {
        title: t('projectPunchList.deletePunchlist'),
        description: t('projectPunchList.deletePunchlistDescription'),
        entity: punchlistDialogState.value.punchlist?.name,
      }
    }
    if (punchlistDialogState.value.type === 'TASKLIST') {
      return {
        title: t('projectPunchList.deleteTasklist'),
        description: t('projectPunchList.deleteTasklistDescription'),
        entity: punchlistDialogState.value.taskList?.title,
      }
    }

    return {
      title: t('projectPunchList.deleteTask'),
      description: t('projectPunchList.deleteTaskDescription'),
      entity: punchlistDialogState.value.task?.title,
    }
  })

  watch(isPunchListActive, async (newValue, oldValue) => {
    if (!newValue && oldValue) {
      await fetchProjectPunchList(props.projectId)
      if (activePunchList.value) {
        activePunchList.value = await getPunchList(activePunchList.value.id)
      }
    }
  })

  onMounted(() => {
    fetchProjectPunchList(props.projectId)
  })

  onUnmounted(() => {
    setActivePunchList(null)
    setActiveTaskListId(null)
    resetProjectPunchList()
  })
</script>

<template>
  <div class="col-12">
    <div class="flex align-items-center justify-content-between title-tab">
      <div class="flex text-xl align-items-center">
        <div class="flex pr-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
            <path
              d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
            />
          </svg>
        </div>
        <div class="flex text-xl">
          {{ $t('projectPunchList.punchLists') }}
        </div>
      </div>
      <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsPunchListsPermissions'" :key-name="'createPunchList'">
        <div class="flex">
          <Button :label="t('projectPunchList.add')" icon="pi pi-plus" class="p-button-text" @click="activatePunchListDialog" />
        </div>
      </PermissionRole>
    </div>
  </div>

  <div v-if="projectPunchList.length > 0" class="col-12">
    <Accordion :multiple="false" :active-index="activePunchListIndex" @tab-open="activateTaskListDialog" @tab-close="clearActivePunchList">
      <AccordionTab v-for="punchList in projectPunchList" :key="punchList.id">
        <template #header>
          <div class="flex flex-column w-full">
            <div class="flex w-full justify-content-between align-items-center pb-2">
              <div class="flex">
                <div class="capitalize text-xl">{{ punchList.name }}</div>
              </div>
              <PermissionRole :nodo-name="'projectsPunchListsPermissions'" :key-name="'complete'">
                <div class="flex">
                  <Button
                    v-if="!(punchList.percentageCompleted === 100)"
                    class="p-button-sm"
                    :label="t('projectPunchList.completedPunchList')"
                    @click.stop="openCompletePunchList(punchList.id)"
                  />
                  <span v-else class="p-button-label text-primary">
                    {{ t('projectPunchList.completed') }}
                  </span>
                </div>
              </PermissionRole>
            </div>
            <div class="flex w-full flex-wrap">
              <div class="w-full mb-2">
                <ProgressBar :value="punchList.percentageCompleted" style="height: 0.5rem" :show-value="false"></ProgressBar>
              </div>
              <div class="flex w-full justify-content-between">
                <div class="flex">
                  {{ punchList.completedTasks }} / {{ punchList.totalTasks }}
                  {{ t('projectPunchList.percetajeCompleted') }}
                </div>
                <div class="flex text-600 capitalize">
                  {{ punchList.assignedTo.fullName }}
                  {{ punchList.profile.name }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <div v-if="activePunchList && activePunchList.id === punchList.id">
          <div class="grid">
            <div class="col-12">
              <div class="flex w-full justify-content-between align-items-center">
                <div class="flex text-xl font-semibold">
                  {{ $t('projectPunchList.taskLists') }}
                </div>
                <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsPunchListsPermissions'" :key-name="'createTask'">
                  <div v-if="punchList.percentageCompleted !== 100" class="flex">
                    <Button :label="t('projectPunchList.taskList')" icon="pi pi-plus" class="p-button-text" @click="openCreateTaskListDialog" />
                  </div>
                </PermissionRole>
              </div>
            </div>
            <div v-if="activePunchList.taskLists.length > 0" class="col-12">
              <Accordion :multiple="false" :active-index="activeTaskListIndex" @tab-open="setActiveTaskListIndex">
                <AccordionTab v-for="taskList in activePunchList.taskLists" :key="taskList.id">
                  <template #header>
                    <div class="flex w-full align-items-center">
                      <div class="flex pr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
                          <path
                            d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
                          />
                          <path
                            d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                      </div>
                      <div class="capitalize text-xl font-semibold">
                        {{ taskList.title }}
                      </div>
                    </div>
                  </template>
                  <div>
                    <ul role="list" class="close-out-list w-full">
                      <ListItemComponent
                        v-for="task in taskList.tasks"
                        :key="task.id"
                        :type="'LIST'"
                        :item="task"
                        :active-id="''"
                        :check-state="task.status === 'COMPLETED' ? true : task.status === 'NA' ? false : null"
                        @check-state-change="updateStatusCheckTask(task)"
                        @deletetask="
                          (v) =>
                            handleDeletePunchlist({
                              punchlist: punchList,
                              task: v,
                              taskList: taskList,
                              type: 'TASK',
                            })
                        "
                      />
                    </ul>
                    <div v-if="punchList.percentageCompleted !== 100" class="flex w-full justify-content-end">
                      <Button :label="t('projectPunchList.task')" icon="pi pi-plus" class="p-button-text" @click="openCreateTaskDialog(taskList)" />
                    </div>
                    <footer>
                      <Button
                        :label="t('projectPunchList.deleteTasklist')"
                        severity="danger"
                        @click="
                          () =>
                            handleDeletePunchlist({
                              punchlist: punchList,
                              task: null,
                              taskList: taskList,
                              type: 'TASKLIST',
                            })
                        "
                      />
                    </footer>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
            <div v-else class="col-12">
              <div class="flex flex-w-full justify-content-center">
                <div class="text-md font-semibold capitalize text-500">
                  {{ $t('projectPunchList.startByAddingTaskList') }}
                </div>
              </div>
            </div>
            <footer>
              <Button
                :label="t('projectPunchList.deletePunchlist')"
                severity="danger"
                @click="
                  () =>
                    handleDeletePunchlist({
                      punchlist: punchList,
                      task: null,
                      taskList: null,
                      type: 'PUNCHLIST',
                    })
                "
              />
            </footer>
          </div>
        </div>

        <div v-else class="col col-12">Loading...</div>
      </AccordionTab>
    </Accordion>
  </div>

  <div v-else-if="loadingProjectPunchList" class="col col-12">Loading...</div>

  <div v-else class="col-12">
    <div class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem">
      <h6>{{ $t('projectPunchList.noPunchlistsYet') }}</h6>
    </div>
  </div>

  <!-- Add Punch List -->
  <Dialog
    v-model:visible="punchListDialogIsActive"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="t('projectPunchList.newPunchList')"
  >
    <ProjectPunchListAdd :project-id="props.projectId" @close-add-dialog="closeAddDialog" />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>

  <!-- Add TaskList -->
  <Dialog
    v-model:visible="taskListDialogIsActive"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0 hide-on-camera"
    style="max-width: 42rem"
    :modal="true"
    :header="t('projectPunchList.newTaskList')"
    :draggable="false"
  >
    <TaskListAdd :project-id="props.projectId" :punch-list-id="(activePunchList && activePunchList.id) || ''" @close-add-task-list-dialog="closeAddTaskListDialog" />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>

  <!-- Add Tasks -->
  <Dialog
    v-model:visible="tasksDialogIsActive"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0 hide-on-camera"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="t('projectPunchList.newTask')"
  >
    <TasksAdd :project-id="props.projectId" :task-list-id="activeTaskListId || ''" @close-add-tasks-dialog="closeAddTasksDialog" />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>
  <Dialog
    v-model:visible="showDialogDeletePunchlist"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0 hide-on-camera"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="dialogModal.title"
  >
    <p>{{ dialogModal.description }} - {{ dialogModal.entity }}</p>
    <div>
      <Button
        :label="t('projectPunchList.labelModalCancelButton')"
        severity="primary"
        :outlined="true"
        class="border-0 shadow-none"
        raised
        @click="() => (showDialogDeletePunchlist = false)"
      />
      <Button
        :label="t('projectPunchList.labelModalDeleteButton')"
        severity="danger"
        :disabled="loadingDeleting"
        :loading="loadingDeleting"
        raised
        @click="handleAPIDeleteGeneral"
      />
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
  .title-tab {
    min-height: 40px;
  }

  .close-out-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 8px;
  }
  .list {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);

      &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
</style>

<style lang="scss">
  #photo-comment .p-sidebar-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  #photo-comment .p-sidebar-header-content {
    width: 100%;
  }
  #photo-comment .p-sidebar-content {
    height: calc(100% - 63px);
    max-height: calc(100% - 63px);
    padding: 0;
    overflow: hidden;
  }
</style>
