<script async setup lang="ts">
  import { useUserStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { onMounted, ref, toRefs, watch } from 'vue'
  import { getPermission } from './funtions'

  interface PermissionsProps {
    nodoName?: string
    keyName?: string
  }

  const props = defineProps<PermissionsProps>()
  const { nodoName, keyName } = toRefs(props)
  const userStore = useUserStore()
  const { permissions } = storeToRefs(userStore)
  const isPermission = ref(false)
  onMounted(() => {
    // console.log('onMounted nodo', nodoName?.value)
    // console.log('onMounted keyName', keyName?.value)
    if (nodoName?.value && keyName?.value) {
      // console.log('profile', profile.value)
      isPermission.value = getPermission(permissions.value, keyName?.value, nodoName?.value)
    }
  })

  watch([nodoName, keyName, permissions], () => {
    // console.log('profile', profile.value)
    // console.log('watch nodoName', nodoName?.value)
    // console.log('wathc keyName', keyName?.value)
    if (nodoName?.value && keyName?.value) {
      isPermission.value = getPermission(permissions.value, keyName?.value, nodoName?.value)
    }
  })
</script>
<template>
  <slot v-if="isPermission" />
</template>
<style lang="scss" scoped></style>
