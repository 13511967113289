<script setup lang="ts">
  import { Camera, PermissionStatus as CameraPermissionStatus } from '@capacitor/camera'
  import { Geolocation, PermissionStatus as GeolocationPermissionStatus } from '@capacitor/geolocation'
  import { PushNotifications, PermissionStatus } from '@capacitor/push-notifications'
  import { onMounted, ref, onUnmounted } from 'vue'
  import { Capacitor } from '@capacitor/core'
  import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings'
  import { i18n } from '@/i18n'
  import CardPermission from '@/components/CardPermission.vue'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import { checkIsBiometricAvailable } from '@/services/biometric'

  const { t } = i18n.global
  const permissionCamera = ref<CameraPermissionStatus | null>(null)
  const permissionGeolocation = ref<GeolocationPermissionStatus | null>(null)
  const permissionPushNotifications = ref<PermissionStatus | null>(null)
  const permissionBiometricFaceId = ref(false)
  const dialogIsActive = ref(false)
  const openSettings = () =>
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    })

  const checkPermissionsCameraWeb = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      console.error('No tienes permisos de acceso a la cámara:', error)
    }
  }
  const requestPermissionCamera = async () => {
    if (Capacitor.isNativePlatform()) {
      permissionCamera.value = await Camera.requestPermissions()
    } else {
      await checkPermissionsCameraWeb()
    }
  }

  const checkPermissionsStatus = async () => {
    permissionCamera.value = await Camera.checkPermissions()
    permissionGeolocation.value = await Geolocation.checkPermissions()
    if (Capacitor.isNativePlatform()) {
      permissionPushNotifications.value = await PushNotifications.checkPermissions()
    }
  }

  const handleGeoLocation = async () => {
    const locationPermission = permissionGeolocation.value?.location

    if (locationPermission === 'prompt') {
      await fetchCurrentLocationOnce()
    }
    if (locationPermission === 'denied' && Capacitor.isNativePlatform()) {
      await openSettings()
    } else if (locationPermission === 'denied') {
      dialogIsActive.value = true
    }
    await checkPermissionsStatus()
  }

  const handleCamera = async () => {
    const cameraPermission = permissionCamera.value?.camera
    if (cameraPermission === 'prompt') {
      await requestPermissionCamera()
    }
    if (cameraPermission === 'denied' && Capacitor.isNativePlatform()) {
      await openSettings()
    } else if (cameraPermission === 'denied') {
      dialogIsActive.value = true
    }
    await checkPermissionsStatus()
  }
  const handleOpenSettingsFaceId = async () => {
    if (Capacitor.isNativePlatform()) {
      await openSettings()
    }
  }

  onMounted(async () => {
    await checkPermissionsStatus()
    const result = await checkIsBiometricAvailable()
    if (result) {
      permissionBiometricFaceId.value = true
    }
  })
  onUnmounted(() => {
    permissionBiometricFaceId.value = false
  })
</script>
<template>
  <div class="pt-4">
    <h5 class="text-4xl">{{ t('settingPermissionInfo.title') }}</h5>
  </div>
  <div class="col col-12 md:col-12 gap-2 flex flex-column">
    <CardPermission
      v-if="Capacitor.isNativePlatform()"
      :title="t('settingPermissionInfo.FACEID')"
      :description="t('settingPermissionInfo.FACEID_DESC')"
      :text-button="t('request')"
      :success="permissionBiometricFaceId"
      icon="pi-mobile"
      @connect="handleOpenSettingsFaceId"
    />

    <CardPermission
      :title="t('settingPermissionInfo.geolocationTitle')"
      :description="t('settingPermissionInfo.geolocationDescription')"
      :text-button="t('request')"
      icon="pi-map-marker"
      :success="permissionGeolocation?.location === 'granted' && permissionGeolocation !== null"
      @connect="handleGeoLocation"
    />

    <CardPermission
      v-if="Capacitor.isNativePlatform()"
      :title="t('settingPermissionInfo.notificationTitle')"
      :description="t('settingPermissionInfo.notificationDescription')"
      :text-button="t('request')"
      icon="pi-bell"
      :success="permissionPushNotifications?.receive === 'granted' && permissionPushNotifications !== null"
      @connect="openSettings"
    />

    <Dialog v-model:visible="dialogIsActive" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
      <template #header>
        <h5>{{ t('settingPermissionInfo.alertTitle') }}</h5>
      </template>
      <p>
        {{ t('settingPermissionInfo.alertMessage') }}
      </p>
    </Dialog>
  </div>
</template>

<style scoped lang="scss">
  #integration-card {
    max-width: 100%;

    .p-card-title {
      text-align: center;
    }

    .p-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 64px !important;
      padding-right: 64px !important;
      padding-top: 48px !important;
      padding-bottom: 48px !important;
    }

    &.failed {
      .p-card-content {
        padding-bottom: 24px !important;
      }
    }
  }
</style>
