<script setup lang="ts">
  import { ref, onMounted } from 'vue'
  import { i18n } from '@/i18n'
  const { t } = i18n.global
  const confettiList = ref([] as number[])

  onMounted(() => {
    const totalConfetti = 300
    for (let i = 0; i < totalConfetti; i++) {
      confettiList.value.push(i)
    }
  })
</script>
<template>
  <div class="section">
    <div class="container-confetti">
      <div class="container-text">
        <p class="text-primary" style="text-align: center">
          {{ t('zohoConnet.connectionSuccess') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .section {
    position: relative;
    width: 100vw;
    height: 100%;
    min-width: 300px;
    overflow: hidden;
  }

  .container-confetti {
    position: relative;
    min-height: 100%;
  }
  .container-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(1.5rem, 5vw, 3rem);
    font-weight: 700;
    color: #fff;
  }
</style>
