<script setup lang="ts">
  import { ref } from 'vue'
  import { useRoute } from 'vue-router'

  import { useProjectStore } from '@/store'

  import GalleryComponent from '@/components/GalleryComponent.vue'
  import ProjectMediaImageComponent from '@/components/ProjectMedia/ProjectMediaImageComponent.vue'
  import { IPhoto } from '@/interfaces/photo/photo.interface'
  import ProjectMediaVideoComponent from '@/components/ProjectMedia/ProjectMediaVideoComponent.vue'
  import { onMounted } from 'vue'
  import { watch } from 'vue'

  const route = useRoute()
  const { company, token } = route.params
  const projectStore = useProjectStore()
  const { fetchGallery } = projectStore

  type IProject = {
    logo: string | undefined
    id: string
    ownerManager: null | string // Asumiendo que ownerManager puede ser un string o null
    marketLocation: null | string // Asumiendo que marketLocation puede ser un string o null
    projectType: string
    photos: IPhoto[]
    createdAt: string
    updatedAt: string
    company: string
  }

  const project = ref<IProject | null>(null)

  async function fetchProject() {
    try {
      const { data } = await fetchGallery(company as string, token as string)
      project.value = data.data.data
    } catch (error) {
      console.error(error)
    }
  }

  function handleRouter() {
    window.open(window.location.origin, '_blank')
  }

  onMounted(() => {
    fetchProject()
  })
  watch(project, (currentProject) => {
    if (currentProject?.logo) {
      document.title = 'Production'

      let link = document.querySelector("link[rel*='icon']") as unknown as HTMLAnchorElement
      if (!link) {
        link = document.createElement('link') as unknown as HTMLAnchorElement
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = currentProject?.logo ?? 'assets/icon/favicon.ico'
    }
  })
</script>

<template>
  <div class="layout-wrapper layout-static h-full">
    <div class="layout-topbar">
      <a class="layout-topbar-logo cursor-pointer" @click.stop="handleRouter">
        <img alt="Logo" :src="project?.logo ?? '/assets/icon/logo.png'" height="30" />
        <span>{{ $t('appLayout.appName') }}</span>
      </a>
    </div>

    <div v-if="project" class="layout-main-container h-full" style="margin-left: 0">
      <div class="layout-main h-full my-4">
        <div class="grid">
          <!-- <div class="flex flex-row align-items-center w-full mb-3">
            <Button
              class="p-button-text p-button-rounded"
              style="min-height: 42px; min-width: 42px; height: 42px; width: 42px"
              icon="pi pi-arrow-left"
              @click="$router.push('/')"
            />
            <div class="flex flex-column align-items-srart justify-content-start ml-2" style="width: calc(100% - 84px)">
              <h5 class="text-left font-bold mb-1 text-overflow-ellipsis white-space-nowrap overflow-hidden">
                {{ project.company }}
              </h5>
            </div>
          </div> -->

          <div class="w-full">
            <GalleryComponent entity-type="Projects" :images="project.photos" :show-comments="false" :show-delete="false" :show-edit="false" :show-favorite="false">
              <template #item="slotProps">
                <template v-if="slotProps.item?.url?.endsWith('mov')"> <ProjectMediaVideoComponent is-upload-type-available /> </template>
                <template v-if="!slotProps.item?.url?.endsWith('mov')">
                  <ProjectMediaImageComponent :photo="slotProps.item" :is-upload-type-available="true" />
                </template>
              </template>
            </GalleryComponent>

            <div v-if="!project.photos?.length" class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem">
              <h6>{{ $t('projectMedia.noMedia') }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
