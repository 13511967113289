import { IOption } from '@/interfaces/option/option.interface'
import { sortObjectByValue } from '@/utils/sortObjectByValue'

export const projectTypes: IOption[] = [
  { value: 'ROOFING_PLANKER', label: 'projectTypes.ROOFING_PLANKER' },
  { value: 'SIDING', label: 'projectTypes.SIDING' },
  { value: 'WINDOWS', label: 'projectTypes.WINDOWS' },
  { value: 'REPAIRS', label: 'projectTypes.REPAIRS' },
  { value: 'NONE', label: 'projectTypes.NONE' },
  { value: 'COMMERCIAL_GUTTERS', label: 'projectTypes.COMMERCIAL_GUTTERS' },
  { value: 'COMMERCIAL_INSULATION', label: 'projectTypes.COMMERCIAL_INSULATION' },
  { value: 'COMMERCIAL_SIDING', label: 'projectTypes.COMMERCIAL_SIDING' },
  { value: 'COMMERCIAL_WINDOWS', label: 'projectTypes.COMMERCIAL_WINDOWS' },
  { value: 'CONSTRUCTION', label: 'projectTypes.CONSTRUCTION' },
  { value: 'DECK', label: 'projectTypes.DECK' },
  { value: 'GUTTER_GUARDS', label: 'projectTypes.GUTTER_GUARDS' },
  { value: 'GUTTERS', label: 'projectTypes.GUTTERS' },
  { value: 'INSULATION', label: 'projectTypes.INSULATION' },
  { value: 'LABOR_ONLY', label: 'projectTypes.LABOR_ONLY' },
  { value: 'NEW_CONSTRUCTION_ROOFING', label: 'projectTypes.NEW_CONSTRUCTION_ROOFING' },
  { value: 'NEW_CONSTRUCTION_SIDING', label: 'projectTypes.NEW_CONSTRUCTION_SIDING' },
  { value: 'NEW_CONSTRUCTION_WINDOWS', label: 'projectTypes.NEW_CONSTRUCTION_WINDOWS' },
  { value: 'ROOFING', label: 'projectTypes.ROOFING' },
  { value: 'ROOFING_GUTTERS', label: 'projectTypes.ROOFING_GUTTERS' },
  { value: 'ROOFING_CEDAR_INSTALL', label: 'projectTypes.ROOFING_CEDAR_INSTALL' },
  { value: 'ROOFING_CEDAR_REDECK', label: 'projectTypes.ROOFING_CEDAR_REDECK' },
  { value: 'ROOFING_COMMERCIAL', label: 'projectTypes.ROOFING_COMMERCIAL' },
  { value: 'ROOFING_FLAT_ROOF_RESIDENTIAL', label: 'projectTypes.ROOFING_FLAT_ROOF_RESIDENTIAL' },
  { value: 'ROOFING_METAL', label: 'projectTypes.ROOFING_METAL' },
  { value: 'ROOFING_RANCH', label: 'projectTypes.ROOFING_RANCH' },
  { value: 'ROOFING_SPECIALTY', label: 'projectTypes.ROOFING_SPECIALTY' },
  { value: 'SIDING_HARDI', label: 'projectTypes.SIDING_HARDI' },
  { value: 'SIDING_HOLLOW', label: 'projectTypes.SIDING_HOLLOW' },
  { value: 'SIDING_INSULATED', label: 'projectTypes.SIDING_INSULATED' },
  { value: 'SIDING_REPAIRS', label: 'projectTypes.SIDING_REPAIRS' },
  { value: 'SIDING_SMARTSIDE', label: 'projectTypes.SIDING_SMARTSIDE' },
  { value: 'SIDING_STONE', label: 'projectTypes.SIDING_STONE' },
  { value: 'SKYLIGHTS', label: 'projectTypes.SKYLIGHTS' },
  { value: 'TRIM_WORK', label: 'projectTypes.TRIM_WORK' },
  { value: 'WINDOWS_DOORS', label: 'projectTypes.WINDOWS_DOORS' },
  { value: 'WINDOWS_DOORS_COMMERCIAL', label: 'projectTypes.WINDOWS_DOORS_COMMERCIAL' },
  { value: 'WINDOWS_DOORS_ENTRY_DOOR', label: 'projectTypes.WINDOWS_DOORS_ENTRY_DOOR' },
  { value: 'WINDOWS_DOORS_GARAGE_DOOR', label: 'projectTypes.WINDOWS_DOORS_GARAGE_DOOR' },
  { value: 'WINDOWS_DOORS_GREAT_LAKES', label: 'projectTypes.WINDOWS_DOORS_GREAT_LAKES' },
  { value: 'WINDOWS_DOORS_MARVIN', label: 'projectTypes.WINDOWS_DOORS_MARVIN' },
  { value: 'WINDOWS_DOORS_PATIO_DOOR', label: 'projectTypes.WINDOWS_DOORS_PATIO_DOOR' },
  { value: 'WINDOWS_DOORS_PELLA', label: 'projectTypes.WINDOWS_DOORS_PELLA' },
  { value: 'WINDOWS_DOORS_PROVIA', label: 'projectTypes.WINDOWS_DOORS_PROVIA' },
  { value: 'WINDOWS_DOORS_RICHLIN', label: 'projectTypes.WINDOWS_DOORS_RICHLIN' },
  { value: 'WINDOWS_DOORS_SIERRA', label: 'projectTypes.WINDOWS_DOORS_SIERRA' },
].sort(sortObjectByValue)

export const projectTypesArray = [{ value: 'ROOFING_PLANKER', label: '  ROOFING_PLANKER' }]
