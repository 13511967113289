export const photoQueueComponentEnUS = {
  photoQueue: 'Photo Queue',
  done: 'Done',
  uploading: 'Uploading',
  emptyQueue: 'Empty Queue',
}

export const photoQueueComponentEsMX = {
  photoQueue: 'Cola de Fotos',
  done: 'Hecho',
  uploading: 'Subiendo',
  emptyQueue: 'Cola Vacia',
}
