<script async setup lang="ts">
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { IeventBus } from '@/composables/uploadQueuePhoto'
  import { i18n } from '@/i18n'
  import { useUserStore } from '@/store'
  import { useEventBus } from '@vueuse/core'
  import { ref } from 'vue'
  import { useRoute } from 'vue-router'
  const route = useRoute()

  const isItemActive = (path: string, routerLink: string) => {
    if (routerLink === '/') {
      return path === '/'
    } else {
      return path.includes(routerLink)
    }
  }
  // const currentRoute = ref(route.path)
  const emit = defineEmits(['navigation_to'])
  const useStore = useUserStore()
  function navigation_to(path: string) {
    emit('navigation_to', { path })
  }
  const { t } = i18n.global

  const itemsMenu = [
    {
      label: 'menuComponent.home',
      icon: 'pi pi-home',
      routerLink: '/',
      active: '/',
      keyName: 'home',
    },
    { label: 'menuComponent.nearByProjects', icon: 'pi pi-bell', routerLink: '/nearbyprojects', active: '/nearbyprojects', keyName: 'projectsNearBy' },
    {
      label: 'menuComponent.projects',
      icon: 'pi pi-bell',
      routerLink: '/projects',
      active: '/projects',
      keyName: 'projects',
    },
    {
      label: 'menuComponent.calendar',
      icon: 'pi pi-calendar',
      routerLink: '/calendar',
      active: '/calendar',
      keyName: 'calendar',
    },
    {
      label: 'menuComponent.todayLeads',
      icon: 'pi pi-bell',
      routerLink: '/nearbyleads',
      active: '/nearbyleads',
      keyName: 'todayLeads',
    },
    {
      label: 'menuComponent.leads',
      icon: 'pi pi-bell',
      routerLink: '/leads',
      active: '/leads',
      keyName: 'leads',
    },
    // {
    //   label: 'menuComponent.photoqueue',
    //   icon: 'pi pi-images',
    //   routerLink: '/queuePhotos',
    //   active: '/queuePhotos',
    //   keyName: 'photoQueue',
    // },
    {
      label: 'menuComponent.company',
      icon: 'pi pi-briefcase',
      routerLink: '/my-company/' + useStore.crewCompanyId,
      active: '/my-company',
      keyName: 'crewManager',
    },
    {
      label: 'menuComponent.crewDirectory',
      icon: 'pi pi-users',
      routerLink: '/crew-directory',
      active: '/crew-directory',
      keyName: 'crewDirectory',
    },
    {
      label: 'menuComponent.directory',
      icon: 'pi pi-book',
      routerLink: '/directory',
      active: '/directory',
      keyName: 'directory',
    },
    {
      label: 'menuComponent.tagsData',
      icon: 'pi pi-tag',
      routerLink: '/tags',
      active: '/tags',
      keyName: 'tagManager',
    },
    // {
    //   label: 'menuComponent.mapsSidebar',
    //   icon: 'pi pi-map',
    //   routerLink: '/maps',
    //   active: '/maps',
    //   keyName: 'maps',
    // },
    {
      label: 'menuComponent.settings',
      icon: 'pi pi-cog',
      routerLink: '/settings',
      active: '/settings',
      keyName: 'settings',
    },
  ]
  // const { QueuePhotos } = uploadNewQueuePhoto()
  const bus = useEventBus<IeventBus>('queuePhoto')
  const dataQueuePhoto = ref<number>(0)

  const listener = (props: IeventBus) => {
    if (props?.uploadQueue) {
      dataQueuePhoto.value = props.uploadQueue?.length
    }
  }

  bus.on(listener)
</script>

<template>
  <ul class="layout-menu relative">
    <template v-for="(item, index) in itemsMenu" :key="`itemMenu-${index}`">
      <PermissionRole v-if="item.keyName" :nodo-name="'menuPermissions'" :key-name="item.keyName">
        <li class="layout-menuitem-category" role="none">
          <a :class="{ active: isItemActive(route.path, item.active) }" @click="navigation_to(item.routerLink)">
            <i :class="item.icon"></i>
            <span class="w-full">{{ t(item.label) }}</span>
            <div
              v-if="item.keyName === 'photoQueue' && dataQueuePhoto > 0"
              class="bg-red-200 flex align-items-center justify-content-center bg-black-alpha-90"
              style="border-radius: 50%; width: 40px; height: 30px"
            >
              <p class="text-white text-sm font-bold">{{ dataQueuePhoto }}</p>
            </div>
          </a>
        </li>
      </PermissionRole>
      <li v-else class="layout-menuitem-category w-full" role="none">
        <a :class="{ active: isItemActive(route.path, item.active) }" @click="navigation_to(item.routerLink)">
          <i :class="item.icon"></i>
          <span>{{ t(item.label) }}</span>
        </a>
      </li>
    </template>
  </ul>
</template>

<style scoped>
  .layout-menu {
    list-style-type: none;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .layout-menu li.layout-menuitem-category {
    margin-top: 0.75rem;
  }

  .layout-menu li.layout-menuitem-category:first-child {
    margin-top: 0;
  }

  .layout-menu li .layout-menuitem-root-text {
    text-transform: uppercase;
    color: var(--surface-900);
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }

  .layout-menu li a {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: var(--text-color);
    transition: color 0.2s;
    border-radius: 12px;
    padding: 1rem 1rem !important;
    transition: background-color 0.15s;
    font-size: 1.2rem;
  }

  .layout-menu li a i {
    padding: 0rem 0.3rem;
    font-size: 1.2rem;
  }

  .layout-menu li a span {
    margin-left: 0.5rem;
  }

  .layout-menu li a .menuitem-toggle-icon {
    margin-left: auto;
  }

  .layout-menu li a:focus {
    outline: 0 none;
    outline-offset: 0;
    transition: box-shadow 0.2s;
    box-shadow: inset var(--focus-ring);
  }

  .layout-menu li a:hover {
    background-color: var(--surface-hover);
  }

  .layout-menu li a.active,
  .layout-menu li a.active:hover {
    background-color: rgba(59, 130, 246, 0.7);
    color: #ffffff;
  }

  .layout-menu li a.router-link-exact-active {
    font-weight: 700;
    color: var(--primary-color);
  }

  .layout-menu li a .p-badge {
    margin-left: auto;
  }

  .layout-menu li.active-menuitem > a .menuitem-toggle-icon:before {
    content: 'î¤³';
  }

  .layout-menu li ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .layout-menu li ul.layout-submenu-wrapper-enter-from,
  .layout-menu li ul.layout-submenu-wrapper-leave-to {
    max-height: 0;
  }

  .layout-menu li ul.layout-submenu-wrapper-enter-to,
  .layout-menu li ul.layout-submenu-wrapper-leave-from {
    max-height: 1000px;
  }

  .layout-menu li ul.layout-submenu-wrapper-leave-active {
    overflow: hidden;
    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
  }

  .layout-menu li ul.layout-submenu-wrapper-enter-active {
    overflow: hidden;
    transition: max-height 1s ease-in-out;
  }

  .layout-menu li ul ul {
    padding-left: 1rem;
  }
</style>
