<script async setup lang="ts">
  import { ref, watch, onMounted, toRefs, onUnmounted } from 'vue'
  import { CameraPreview, CameraPreviewOptions } from '@capacitor-community/camera-preview'
  import { Camera, PermissionStatus as CameraPermissionStatus } from '@capacitor/camera'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import { IQueuePhoto } from '@/db/tables/QueuePhoto.table'
  import uuid from 'uuid-random'
  import CameraImagePreview from './CameraImagePreview.vue'
  import ControlsCamera from './ControlsCamera.vue'
  import { PhotoTypeEnum } from '@/db/tables'
  import { lockOrientationApp, unlockOrientationApp } from '@/utils/lockOrientationApp'
  import { ScreenOrientation } from '@capacitor/screen-orientation'
  import { Capacitor } from '@capacitor/core'

  import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings'
  import devicePhotoTag from '@/utils/device-photo-tag'

  type Iprops = {
    projectId?: string
    photoTypeId?: string
    photoType?: PhotoTypeEnum
    hiddenCloseButton?: boolean
    hiddenControls?: boolean
    photos?: IQueuePhoto[]
    // isCameraVisible?: boolean
  }

  const enableCameraClass = () => {
    const cameraComponent = document.querySelector('#camera-container')
    const body = document.querySelector('body')
    if (cameraComponent) cameraComponent.classList.remove('transparent')
    if (body) body.classList.add('transparent')
  }
  const enableDialogs = () => {
    const dialogs = document.querySelectorAll('.p-dialog')
    const dialogmask = document.querySelector('.p-dialog-mask')
    if (dialogs)
      dialogs.forEach((dialog) => {
        dialog.classList.remove('hidden')
      })
    if (dialogmask) dialogmask.classList.remove('hidden')
  }
  const disableDialogs = () => {
    const dialogs = document.querySelectorAll('.p-dialog')
    const dialogmask = document.querySelector('.p-dialog-mask')
    if (dialogs)
      dialogs.forEach((dialog) => {
        dialog.classList.add('hidden')
      })
    if (dialogmask) dialogmask.classList.add('hidden')
  }

  const disableCameraClass = () => {
    const cameraComponent = document.querySelector('#camera-container')
    const body = document.querySelector('body')
    if (cameraComponent) cameraComponent.classList.add('transparent')
    if (body) body.classList.remove('transparent')
  }

  const props = defineProps<Iprops>()
  const emit = defineEmits<{
    (e: 'takePicture', photo: IQueuePhoto): void
    (e: 'deletePhoto', photo: IQueuePhoto): void
    (e: 'closeCamera', photos: IQueuePhoto[]): void
    (e: 'editPhoto', photo: IQueuePhoto): void
    (e: 'update:isCameraVisible', value: boolean): void
  }>()
  const { projectId, photoTypeId, photoType, hiddenCloseButton, hiddenControls = false, photos } = toRefs(props)

  const permissionCamera = ref<CameraPermissionStatus | null>(null)
  const images = ref<IQueuePhoto[]>([])
  const lastThreeImages = ref<IQueuePhoto[]>([])
  const isLoading = ref(false)
  const isCameraVisible = ref(true)
  const previewIsVisible = ref(false)
  const isFlipAvailable = ref(false)
  const isLandscape = ref(false)
  const cameraPreviewOptions: CameraPreviewOptions = {
    position: 'rear',
    parent: 'content-camera-preview',
    className: 'camera-preview',
    toBack: true,
    storeToFile: false,
    enableHighResolution: true,
    rotateWhenOrientationChanged: true,
    disableAudio: true,
    enableOpacity: true,
    enableZoom: true,
  }

  // const isCameraVisible = computed({
  //   get: () => props.isCameraVisible,
  //   set: (value) => {
  //     emit('update:isCameraVisible', value)
  //   },
  // })

  const openSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    })
  }

  const requestPermission = async () => {
    if (Capacitor.isNativePlatform()) {
      const permission = await Camera.requestPermissions()
      permissionCamera.value = permission
    }
  }
  const checkPermissionsStatus = async () => {
    permissionCamera.value = await Camera.checkPermissions()
  }

  const handleCheckPermissions = async () => {
    const cameraPermission = permissionCamera.value?.camera
    if (cameraPermission === 'prompt') return await requestPermission()
    if (cameraPermission === 'denied' && Capacitor.isNativePlatform()) return openSettings()
    return await checkPermissionsStatus()
  }

  const chargePhotos = (photos: IQueuePhoto[]) => {
    if (photos.length === 0) return
    images.value = photos
  }

  watch(
    images,
    (newValue) => {
      lastThreeImages.value = images.value.slice(Math.max(newValue.length - 3, 0))
    },
    { deep: true }
  )

  const flipCamera = async () => await CameraPreview.flip()

  const initCamera = async () => {
    enableCameraClass()
    disableDialogs()
    isLoading.value = true
    console.log({ cameraPreviewOptions })
    await CameraPreview.start(cameraPreviewOptions)
    isLoading.value = false
    await unlockOrientationApp()
    chargePhotos(photos?.value ?? [])
  }
  const takePhotoBase64 = async () => {
    const result = await CameraPreview.captureSample({
      quality: 100,
    })
    return {
      base64Content: `${result.value}`,
      image: `data:image/jpeg;base64,${result.value}`,
    }
  }

  const emptyImages = () => {
    images.value = []
  }

  const handleTakePicture = async () => {
    const uploadPhotoType = await devicePhotoTag('CAMERA')
    Promise.all([takePhotoBase64(), fetchCurrentLocationOnce()])
      .then((values) => {
        const generateID = uuid()
        const { image, base64Content } = values[0]
        const { latitude, longitude } = values[1]

        const newPhoto: IQueuePhoto = {
          id: generateID,
          base64: image,
          latitude: `${latitude}`,
          longitude: `${longitude}`,
          photoType: photoType?.value ?? PhotoTypeEnum.PROGRESS_PICTURES,
          photoTypeId: photoTypeId?.value ?? '',
          projectId: projectId?.value ?? '',
          base64Content,
          type_media: 'IMAGE',
          uploadPhotoType,
        }
        images.value.push(newPhoto)
        emit('takePicture', newPhoto)
      })
      .catch((error) => console.log(error))
  }
  const stopCamera = async () => {
    console.log('stop camera')
    await CameraPreview.stop()
  }
  const closePreview = async () => {
    await lockOrientationApp()
    disableCameraClass()
    enableDialogs()
    await stopCamera()
    isCameraVisible.value = false
    emit('closeCamera', images.value)
    images.value = []
  }

  const togglePreviewVisibility = async () => {
    if (previewIsVisible.value) {
      await unlockOrientationApp()
    } else {
      await lockOrientationApp()
    }
    previewIsVisible.value = !previewIsVisible.value
  }
  const handleDeleteImage = async (photo: IQueuePhoto) => {
    images.value = images.value.filter((i) => i.id !== photo.id)
    emit('deletePhoto', photo)
    if (images.value.length === 0) {
      togglePreviewVisibility()
    }
  }

  const editImage = (image: { base64: string; base64Content: string; id: string }) => {
    const index = images.value.findIndex((i) => i.id === image.id)
    const imageFound = images.value[index]
    const newImage = { ...imageFound, ...image }
    images.value[index] = newImage
    emit('editPhoto', newImage)
  }

  onMounted(async () => {
    console.log('mount handle check permission')
    await handleCheckPermissions()
  })
  onMounted(() => {
    if (Capacitor.isNativePlatform()) {
      console.log('isNativePlatform true active listener')
      ScreenOrientation.addListener('screenOrientationChange', (e) => {
        const Landscape = e.type.includes('landscape')
        isLandscape.value = Landscape
        disableDialogs()
      })
    }
    flipCamera()
      .then(() => {
        isFlipAvailable.value = true
      })
      .catch(() => {
        isFlipAvailable.value = false
      })
  })
  onUnmounted(() => {
    if (Capacitor.isNativePlatform()) {
      ScreenOrientation.removeAllListeners()
      enableDialogs()
    }
  })
</script>

<template>
  <Sidebar
    id="camera-container"
    :auto-z-index="false"
    :base-z-index="1150"
    :visible="isCameraVisible"
    append-to="body"
    :dismissable="true"
    position="bottom"
    :show-close-icon="false"
    style="background-color: transparent; padding: 0 !important"
    class="absolute z-5 h-full w-full"
    @show="initCamera"
  >
    <!-- <div
      v-if="isLoading"
      class="col-12 h-full flex justify-content-center align-items-center"
      style="background: var(--surface-ground)"
    >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        style="margin: auto; display: block; shape-rendering: auto"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="translate(50,50)">
          <g transform="scale(0.8)">
            <g transform="translate(-50,-50)">
              <g>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  values="360 50 50;240 50 50;120 50 50;0 50 50"
                  keyTimes="0;0.333;0.667;1"
                  dur="1s"
                  keySplines="0.7 0 0.3 1;0.7 0 0.3 1;0.7 0 0.3 1"
                  calcMode="spline"
                />
                <path
                  fill="#000000"
                  d="M54.3,28.1h34.2c-4.5-9.3-12.4-16.7-21.9-20.8L45.7,28.1L54.3,28.1L54.3,28.1z"
                ></path>
                <path
                  fill="#000000"
                  d="M61.7,7.3C51.9,4,41.1,4.2,31.5,8.1v29.5l6.1-6.1L61.7,7.3C61.7,7.3,61.7,7.3,61.7,7.3z"
                ></path>
                <path
                  fill="#000000"
                  d="M28.1,11.6c-9.3,4.5-16.7,12.4-20.8,21.9l20.8,20.8v-8.6L28.1,11.6C28.1,11.6,28.1,11.6,28.1,11.6z"
                ></path>
                <path
                  fill="#000000"
                  d="M31.5,62.4L7.3,38.3c0,0,0,0,0,0C4,48.1,4.2,58.9,8.1,68.5h29.5L31.5,62.4z"
                ></path>
                <path
                  fill="#000000"
                  d="M45.7,71.9H11.5c0,0,0,0,0,0c4.5,9.3,12.4,16.7,21.9,20.8l20.8-20.8H45.7z"
                ></path>
                <path
                  fill="#000000"
                  d="M62.4,68.5L38.3,92.6c0,0,0,0,0,0c9.8,3.4,20.6,3.1,30.2-0.8V62.4L62.4,68.5z"
                ></path>
                <path
                  fill="#000000"
                  d="M71.9,45.7v8.6v34.2c0,0,0,0,0,0c9.3-4.5,16.7-12.4,20.8-21.9L71.9,45.7z"
                ></path>
                <path
                  fill="#000000"
                  d="M91.9,31.5C91.9,31.5,91.9,31.5,91.9,31.5l-29.5,0l0,0l6.1,6.1l24.1,24.1c0,0,0,0,0,0 C96,51.9,95.8,41.1,91.9,31.5z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div> -->
    <div class="w-full h-full flex flex-row justify-content-evenly">
      <div
        class="camera relative"
        :class="{
          'background-loading': isLoading,
          'is-rotate': isLandscape && Capacitor.getPlatform() === 'android',
          'is-rotate-iphone': isLandscape && Capacitor.getPlatform() === 'ios',
        }"
      >
        <div
          id="camera-top-controls"
          class="absolute left-0 top-0 right-0 w-full p-4 flex flex-row z-5 align-items-center"
          :class="{
            'top-controls-landscape': isLandscape,
          }"
        >
          <div class="relative w-full h-full flex justify-content-end align-items-center">
            <slot name="header" :close-camera="closePreview" />
            <div class="flex-none">
              <Button
                v-if="!hiddenCloseButton"
                aria-label="Bookmark"
                rounded
                class="bg-white text-black-alpha-90 border-none shadow-3 top-0 right-0 z-5"
                icon="pi pi-times"
                @click="closePreview"
              />
            </div>
          </div>
        </div>

        <div id="content-camera-preview" class="content-camera-preview">
          <div id="camera-preview" class="camera-preview w-full absolute h-full transparent"></div>
        </div>

        <ControlsCamera
          v-if="!hiddenControls"
          :is-landscape="isLandscape"
          :is-loading="false"
          :is-flip-available="isFlipAvailable"
          :images="images"
          :last-three-images="lastThreeImages"
          @take-picture="handleTakePicture"
          @toggle-preview-visibility="togglePreviewVisibility"
          @flip-camera="flipCamera"
        />
      </div>
      <slot name="content" :close-camera="closePreview" :delete-images="emptyImages" :images="images" />
    </div>
  </Sidebar>
  <CameraImagePreview :visible="previewIsVisible" :images="images" @close-preview="togglePreviewVisibility" @delete-image="handleDeleteImage" @edit-image="editImage" />
</template>

<style>
  #controls {
    background: rgb(0 0 0 / 41%);
  }

  .transparent {
    background: transparent !important;
  }

  #camera-container {
    background: transparent !important;
  }

  #camera-container > .p-sidebar-header {
    display: none;
    padding: 0;
  }
  #camera-container > .p-sidebar-content {
    padding: 0;
    overflow: hidden;
    height: 100%;
  }
  #camera-top-controls {
    margin-top: var(--sat);
  }
  .top-controls-landscape {
    margin-top: var(--sar) !important;
  }

  .is-rotate {
    height: 100vw !important;
    width: 100vh !important;
    transform: rotate(-90deg) translateX(50%);
  }

  .is-rotate-iphone {
    height: 100vw !important;
    width: 100vh !important;
    transform: rotate(-90deg) translateX(60%);
  }
</style>

<style scoped lang="scss">
  :deep(#video) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .camera {
    width: 100%;
    height: 100%;
  }

  .background-loading {
    background: rgba(0, 0, 0);
  }
  .content-camera-preview {
    height: 100%;
    width: 100%;
    background: transparent !important;
  }
</style>

<style lang="scss">
  #camera-top-controls {
    > .pi {
      font-size: 1.25rem;
      color: var(--surface-800);
    }
  }
</style>
