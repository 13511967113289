import { FilterMatchModeOptions } from 'primevue/api'

export function safeToString(value: any) {
  if (value === null || value === undefined) {
    return ''
  }
  return typeof value.toString === 'function' ? value.toString().toLowerCase() : String(value).toLowerCase()
}
const normalize = (value: string) => value.toLowerCase().trim()
function generateFilterByString<T extends object>(matchMode: keyof FilterMatchModeOptions, value: string, key: keyof T) {
  switch (matchMode?.toUpperCase()) {
    case 'CONTAINS':
      return (data: T) => normalize(safeToString(data[key])).includes(normalize(value))
    case 'NOTCONTAINS':
      return (data: T) => !normalize(safeToString(data[key])).includes(normalize(value))
    case 'STARTSWITH':
      return (data: T) => normalize(safeToString(data[key])).startsWith(normalize(value))
    case 'ENDSWITH':
      return (data: T) => normalize(safeToString(data[key])).endsWith(normalize(value))
    case 'EQUALS':
      return (data: T) => normalize(safeToString(data[key])).toLowerCase().trim() === normalize(value)
    case 'NOTEQUALS':
      return (data: T) => normalize(safeToString(data[key])) !== normalize(value)
    default:
      return (data: T) => normalize(safeToString(data[key])).includes(normalize(value))
  }
}

export default generateFilterByString
