export const crewDirectoryEnUS = {
  crewDirectory: 'Crew Directory',
  search: 'Search',
  phone: 'Phone',
  email: 'Email',
  sms: 'SMS',
  invitation: 'Invite users',
  sendInvitation: 'Send Invitation',
  sendingInvitation: 'Sending the invitation',
  sentInvitationTitle: 'The invitation was sent',
  sentInvitationCrew: {
    first: 'The Crew',
    second: 'was invited to the application',
  },
  errorSendInvitationTitle: 'The invitation was not sent',
  errorSendInvitationCrew: 'An unexpected error occurred.',
  confirmationDescription: 'Are you sure? This will reset the Crew password',
  table: {
    headers: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      rating: 'Rating',
    },
  },
}

export const crewDirectoryEsMX = {
  crewDirectory: 'Directorio de la tripulación',
  search: 'Buscar',
  phone: 'Teléfono',
  email: 'Correo electrónico',
  sms: 'SMS',
  invitation: 'Invitar usuarios',
  sendInvitation: 'Enviar Invitación',
  sendingInvitation: 'Enviando la Invitación',
  sentInvitationTitle: 'La invitación se envió',
  sentInvitationCrew: {
    first: 'El Crew',
    second: 'fue invitado a la aplicación',
  },
  errorSendInvitationTitle: 'La invitación no se envió',
  errorSendInvitationCrew: 'Se ha producido un error inesperado.',
  confirmationDescription: '¿Estás seguro? Esto reseteará la contraseña del Crew',
  table: {
    headers: {
      name: 'Nombre',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      rating: 'Rating',
    },
  },
}
