<script lang="ts" setup>
  import { Capacitor } from '@capacitor/core'
  import DefaultLayout from '@/layouts/DefaultLayout.vue'
  import LoaderComponent from '@/components/LoaderComponent.vue'
  import { StatusBar } from '@capacitor/status-bar'
  import { KeepAwake } from '@capacitor-community/keep-awake'
  import { storeToRefs } from 'pinia'
  import { checkUserGeolocationPermissions, fetchCurrentLocationOnce, requestUserGeolocationPermissions } from '@/services/geolocation'
  import { useAccountStore, useLoaderStore, usePhotoQueueStore, useProjectStore, useBreakpointsStore, useCloseOutStore, useUserStore, useLeadStore } from '@/store'
  import { onMounted, ref, onBeforeMount, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { App } from '@capacitor/app'
  // import { usePhotoQueueProcessor } from '@/composables/usePhotoQueueProcessor'
  import CloseOutContainer from './components/PunchListCamera/CloseOutContainer.vue'
  import NotificationContent from './components/NotificationContent.vue'
  // import onResumeApp from '@/services/onResumeApp'
  import { captureException } from '@sentry/vue'
  import { lockOrientationApp } from './utils/lockOrientationApp'
  import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture'
  import CameraContainer from './components/PunchListCamera/Camera/CameraContainer.vue'

  import { useUserInfo } from './composables/useUserInfo'
  import { useBreakpoints } from './composables/useBreakPoints'
  import { usePushNotifications } from './composables/usePushNotifications'
  import { usePushNotificationsMobile } from './services/pushNotification'

  // initialize stores
  useAccountStore()
  useProjectStore()
  useLeadStore()
  usePhotoQueueStore()
  useBreakpointsStore()
  useBreakpoints()
  useUserStore()
  // initialize hooks
  // usePushNotifications()

  const loaderStore = useLoaderStore()
  const punchListCameraStore = useCloseOutStore()
  const { refeshInfo } = useUserInfo()
  const { isActive: isActiveLoader } = storeToRefs(loaderStore)
  const { isActive: isActivePunchListCamera } = storeToRefs(punchListCameraStore)
  const { actionPerformedPushNotification } = usePushNotificationsMobile()
  const { initializeFirebase } = usePushNotifications()
  const { setLoaderActiveState } = loaderStore
  const ready = ref(false)

  const route = useRoute()

  const isPathPublic = computed(() => {
    const paths = ['/widgets', '/galleries']
    const isPath = paths?.some((e) => route?.path?.startsWith(e))
    return isPath
  })

  onMounted(async () => {
    if (isPathPublic.value) {
      setTimeout(() => {
        setLoaderActiveState(false)
        ready.value = true
      }, 1000)
      return
    } else {
      console.log('App Mounted Settings')

      await initializeFirebase()

      if (Capacitor.isNativePlatform()) {
        actionPerformedPushNotification()
      }
      if (route.meta.isPublic) return

      setLoaderActiveState(true)
      if (Capacitor.getPlatform() === 'ios') {
        SuppressLongpressGesture.activateService()
      }
      if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
        await lockOrientationApp()
        await requestUserGeolocationPermissions()
        requestUserGeolocationPermissions().catch((err) => {
          console.log('geolocation err', err)
        })
        KeepAwake.keepAwake().catch((err) => {
          console.log(err)
        })
        StatusBar.hide().catch((err) => {
          console.log('statusbar err', err)
        })
      }

      try {
        await checkUserGeolocationPermissions()
      } catch (err) {
        captureException(err)
        console.log('geolocation err', err)
      }

      setTimeout(() => {
        setLoaderActiveState(false)
        ready.value = true
      }, 1000)
    }
  })
  onBeforeMount(async () => {
    if (isPathPublic.value) {
      return
    } else {
      App.addListener('resume', async () => {
        console.log('Refresh info')
        await refeshInfo()
        await fetchCurrentLocationOnce(true)
      })
    }
  })
</script>

<template>
  <CameraContainer>
    <CloseOutContainer v-if="isActivePunchListCamera" />
    <LoaderComponent v-if="isActiveLoader || !ready" />
    <Toast position="top-right" group="notification">
      <template #container="slotProps">
        <NotificationContent
          :message="{
            summary: slotProps.message.summary,
            detail: slotProps.message.detail?.body ?? '',
          }"
          :link="slotProps.message.detail?.link ?? ''"
          @on-close="slotProps.closeCallback()"
        />
      </template>
    </Toast>

    <div v-show="!isActivePunchListCamera" class="bg-transparent h-full">
      <DefaultLayout v-if="ready" />
    </div>
  </CameraContainer>
</template>

<style lang="scss">
  @import './assets/styles/App.scss';
</style>
