// import api from '@/services/api'
import { useAccountStore, useUserStore } from '@/store'
import { Capacitor } from '@capacitor/core'
import { storeToRefs } from 'pinia'
import { Browser } from '@capacitor/browser'
import { baseUrl } from '@/constants'
import { captureException } from '@sentry/vue'

const openBrowser = async (url: string) => {
  if (Capacitor.isNativePlatform()) {
    await openCapacitorSite(url)
  } else {
    window.open(url, '_blank')
  }
}
const openCapacitorSite = async (url: string) => {
  await Browser.open({ url: url, presentationStyle: 'fullscreen' })

  Browser.addListener('browserFinished', async () => {
    const userStore = useUserStore()
    const { getUserInfo } = userStore
    await getUserInfo()
    await Browser.removeAllListeners()
  })
}

export async function connectToZoho() {
  try {
    const accountStore = useAccountStore()
    const { tenant } = storeToRefs(accountStore)
    const userStore = useUserStore()
    const { id } = storeToRefs(userStore)
    const url = `${baseUrl}/Api/Zoho/Integration/oAuthEnable?organization=${tenant.value}&userId=${id.value}`
    await openBrowser(url)
  } catch (err) {
    captureException(err)
    console.log('err', err)
  }
}
