<script lang="ts" setup>
  import { defineProps } from 'vue'
  import { Browser } from '@capacitor/browser'

  import { mimetypes } from '@/constants/mimetypes'

  interface IFile {
    name: string
    path: string
  }

  const props = defineProps<IFile>()

  const ext = props.name.split('.').pop()
  const matchingMimeType = mimetypes.find((mt) => mt.ext === ext)

  async function openInBrowser() {
    await Browser.open({ url: props.path })
  }

  function returnFileIcon() {
    if (props.name && props.path) {
      const ext = props.name.split('.').pop()
      const matchingMimeType = mimetypes.find((mt) => mt.ext === ext)
      if (matchingMimeType) {
        if (matchingMimeType.type === 'image') {
          return '<svg style="width:42px;height:42px" viewBox="0 0 24 24"><path fill="currentColor" d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" /></svg>'
        } else if (matchingMimeType.type === 'video') {
          return '<svg style="width:42px;height:42px" viewBox="0 0 24 24"><path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" /></svg>'
        } else if (matchingMimeType.type === 'audio') {
          return '<svg style="width:42px;height:42px" viewBox="0 0 24 24"><path fill="currentColor" d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z" /></svg>'
        } else if (matchingMimeType.type === 'pdf') {
          return '<svg style="width:42px;height:42px" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9.5 11.5C9.5 12.3 8.8 13 8 13H7V15H5.5V9H8C8.8 9 9.5 9.7 9.5 10.5V11.5M14.5 13.5C14.5 14.3 13.8 15 13 15H10.5V9H13C13.8 9 14.5 9.7 14.5 10.5V13.5M18.5 10.5H17V11.5H18.5V13H17V15H15.5V9H18.5V10.5M12 10.5H13V13.5H12V10.5M7 10.5H8V11.5H7V10.5Z" /></svg>'
        } else {
          return '<svg style="width:42px;height:42px" viewBox="0 0 24 24"><path fill="currentColor" d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" /></svg>'
        }
      }
    }
  }
</script>

<template>
  <div
    class="file-icon flex flex-column align-items-center justify-content-center pt-4 border-round-lg gap-1 overflow-hidden cursor-pointer"
    style="border: 1px solid rgba(0, 0, 0, 0.1)"
    @click="openInBrowser"
  >
    <div class="mr-3 ml-3 lg:mb-0 mb-3" v-html="returnFileIcon()" />
    <div class="lg:flex hidden h-2rem w-full label font-bold align-items-center justify-content-center uppercase">
      {{ matchingMimeType && matchingMimeType.type }}
    </div>
  </div>
</template>

<style lang="css" scoped>
  .file-icon {
    transition: 3ms background-color;
  }
  .file-icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .label {
    background-color: #2fb1db;
    color: #ffffff;
  }
</style>
