<script setup async lang="ts">
  import AccountLayout from '@/layouts/AccountLayout.vue'
  import AppLayout from '@/layouts/AppLayout.vue'
  import { useRoute } from 'vue-router'
  import { computed } from 'vue'
  import ErrorLayout from '@/layouts/ErrorLayout.vue'

  const route = useRoute()

  const layout = computed(() => {
    return route.meta.layout as string
  })
</script>

<template>
  <AccountLayout v-if="layout == 'Account'">
    <template #main>
      <router-view></router-view>
    </template>
  </AccountLayout>
  <ErrorLayout v-else-if="layout == 'ErrorLayout'">
    <template #main>
      <router-view></router-view>
    </template>
  </ErrorLayout>
  <AppLayout v-else>
    <template #main>
      <router-view></router-view>
    </template>
  </AppLayout>
</template>
