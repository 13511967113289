<script setup lang="ts">
  import api from '@/services/api'
  import { Ref, onMounted, ref } from 'vue'
  import { i18n } from '@/i18n'
  // import { watch } from 'vue'
  import { DataTableCellEditCompleteEvent, DataTablePageEvent, DataTableSortEvent } from 'primevue/datatable'
  // import { FilterMatchMode, FilterOperator } from 'primevue/api'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import generateFilterByString from '@/utils/filters/filterByString'
  import generateFilterByNumbers from '@/utils/filters/filterByNumber'
  import generateFilterByDate from '@/utils/filters/filterByDate'
  import { FilterMatchMode, FilterMatchModeOptions, FilterOperator } from 'primevue/api'
  import { watch } from 'vue'
  interface TagObject {
    tagId: string
    tagName: string
    name: string
    description: string | null
    photoCount: number
    createTime: string
    isDefault: boolean
    userId: string | null
  }

  interface IPaginationInfo {
    pageSize: number
    pageNumber: number
    pageCount: number
    rowCount: number
    nextPage: string | null
  }

  interface ITagResponse {
    data: TagObject[]
    pagination: IPaginationInfo
  }

  type filterValue<T, K> = {
    value: K | null
    matchMode: keyof T
  }

  type filterValueData<T> = {
    operator: string
    constraints: filterValue<FilterMatchModeOptions, T>[]
  }
  type filterTypeData = {
    name: filterValueData<string>
    photoCount: filterValueData<number>
    createTime: filterValueData<string>
  }

  type filterType = {
    name: filterValue<FilterMatchModeOptions, string>
    photoCount: filterValue<FilterMatchModeOptions, number>
    createTime: filterValue<FilterMatchModeOptions, string>
  }

  type sortType = {
    field: string
    order: number
  }

  type paginationType = {
    take: number
    skip: number
  }

  function getDataTable<T extends TagObject>(array: T[], pagination: paginationType, sort: sortType | null, filter: filterTypeData) {
    // if (!sort) return array.slice(pagination.skip, pagination.skip + pagination.take)
    let filteredArray: T[] = array

    if (filter?.name && filter.name.constraints[0].value !== '') {
      // console.log(filter.name.value, 'FILTER DATATABLE')

      const fnFilterString = generateFilterByString<TagObject>(filter.name.constraints[0]?.matchMode, filter.name.constraints[0].value ?? '', 'name')
      filteredArray = filteredArray.filter((data) => {
        return fnFilterString(data)
      })
    }

    if (filter?.photoCount && filter.photoCount.constraints[0].value !== null) {
      // console.log('FILTERCOUNT')
      // console.log(filter.photoCount.value)

      const fnFilterByNumber = generateFilterByNumbers<TagObject>(filter.photoCount.constraints[0]?.matchMode, filter.photoCount.constraints[0].value, 'photoCount')

      filteredArray = filteredArray.filter((data) => {
        return fnFilterByNumber(data)
      })
    }
    console.log(filter)

    if (filter?.createTime && filter.createTime.constraints[0].value) {
      const fnFilterByDate = generateFilterByDate<TagObject>(filter.createTime.constraints[0].matchMode, `${filter.createTime.constraints[0].value}` ?? '', 'createTime')

      filteredArray = filteredArray.filter((data) => {
        if (!filter.createTime.constraints[0].value) return true
        return fnFilterByDate(data)
      })
    }

    if (!sort) return filteredArray.slice(pagination.skip, pagination.skip + pagination.take)

    const newData = sortArrayByKey(filteredArray, sort.field as keyof T, sort.order as -1 | 1)

    return newData.slice(pagination.skip, pagination.skip + pagination.take)
  }

  function sortArrayByKey<T>(array: T[], key: keyof T, order: -1 | 1) {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1 * order
      }
      if (a[key] > b[key]) {
        return 1 * order
      }
      return 0
    })
  }
  // const isString = (value: any) => {
  //   return typeof value === 'string'
  // }
  const convertFormat = (inputObject: filterType) => {
    const resultArray = []
    for (const key in inputObject) {
      if (Object.prototype.hasOwnProperty.call(inputObject, key)) {
        const newObject = {
          field: key,
          value: inputObject[key as keyof typeof inputObject].value,
          matchMode: inputObject[key as keyof typeof inputObject].matchMode.replace(/([A-Z])/g, '_$1').toUpperCase(),
        }

        if (typeof inputObject?.[key as keyof typeof inputObject]?.value === 'string' && (inputObject[key as keyof typeof inputObject]?.value as string).length > 0) {
          resultArray.push(newObject)
        }
      }
    }

    return resultArray
  }

  type RefWithElement<T extends HTMLElement> = Ref<T & { $el: T }>
  const { t } = i18n.global
  const takeValue = ref(10)
  const skipValue = ref(0)
  const dialogCreateTag = ref(false)
  const dialogDeleteTag = ref(false)
  const tagSelect = ref<TagObject | null>(null)
  const createField = ref('')
  const searchInput = ref<RefWithElement<HTMLInputElement> | null>(null)
  const loadingCreateTag = ref(false)
  const loadingEditTag = ref(false)
  const loadingGetTags = ref(false)
  const sort = ref<sortType | null>(null)
  const tagResponse = ref<TagObject[]>([])
  const dataTable = ref<TagObject[]>([])
  const filters = ref({
    name: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS as keyof FilterMatchModeOptions }] },
    photoCount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS as keyof FilterMatchModeOptions }] },
    createTime: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.DATE_IS as keyof FilterMatchModeOptions }] },
  })

  const handleEmptyCreate = () => {
    createField.value = ''
  }

  interface IFilterParams {
    skip?: number
    take?: number
    filters?: filterType
    sort?: sortType
  }

  const resetPagination = () => {
    skipValue.value = 0
    takeValue.value = 10
  }
  const resetData = () => {
    tagResponse.value = []
  }

  const fechtTags = async (params?: IFilterParams) => {
    loadingGetTags.value = true
    const payload = {
      properties: convertFormat(params?.filters ?? ({} as filterType)),
      sort: null,
    }
    const response = await api.post(`/Api/v2/PhotoTag/Filter`, payload)
    console.log(response.data.data)
    tagResponse.value = response.data.data
    dataTable.value = tagResponse.value
    console.log({ tagResponse: tagResponse.value })
    loadingGetTags.value = false
  }

  const createTag = async () => {
    loadingCreateTag.value = true
    const payload = { names: [createField.value] }
    await api.post('/Api/Photo/Tag', payload)
    resetPagination()
    resetData()
    loadingCreateTag.value = false
    dialogCreateTag.value = false
    fechtTags({ skip: skipValue.value, take: takeValue.value })
  }

  const deleteTag = async () => {
    loadingEditTag.value = true
    await api.delete(`/Api/Photo/Tag/${tagSelect.value?.tagId}`)
    resetPagination()
    resetData()
    dialogDeleteTag.value = false
    loadingEditTag.value = false
    fechtTags({ skip: skipValue.value, take: takeValue.value })
  }

  const onPage = (event: DataTablePageEvent) => {
    skipValue.value = event.first
    takeValue.value = event.rows
    const data = getDataTable(tagResponse.value, { skip: skipValue.value, take: takeValue.value }, sort.value, filters.value)
    dataTable.value = data
  }
  const onSort = (event: DataTableSortEvent) => {
    sort.value = { field: event.sortField as string, order: event.sortOrder as number }
    const data = getDataTable(tagResponse.value, { skip: skipValue.value, take: takeValue.value }, sort.value, filters.value)
    dataTable.value = data
  }
  const onFilter = () => {
    // console.log('filter')
    // console.log(filters.value)

    const data = getDataTable(tagResponse.value, { skip: skipValue.value, take: takeValue.value }, sort.value, filters.value)
    dataTable.value = data
    // fechtTags({ skip: skipValue.value, take: takeValue.value, filters: filters.value })
  }

  const onCellEditComplete = async (e: DataTableCellEditCompleteEvent) => {
    try {
      const data = e.newData as TagObject
      loadingEditTag.value = true
      const payload = { name: data.name }
      await api.patch(`/Api/Photo/Tag/${data?.tagId}`, payload)
      tagResponse.value = tagResponse.value.map((tag) => {
        if (tag.tagId === data.tagId) return data
        return tag
      })
    } catch (error) {
      console.log({ error })
    } finally {
      loadingEditTag.value = false
    }
  }

  // const clearFilter = () => {
  //   filters.value = {
  //     name: { value: '', matchMode: 'contains' },
  //     photoCount: { value: '', matchMode: 'equals' },
  //     createTime: {
  //       value: '',
  //       matchMode: 'dateIs',
  //     },
  //   }
  // }

  // watch(
  //   filters,
  //   () => {
  //     console.log(filters.value)

  //     fechtTags({ skip: skipValue.value, take: takeValue.value, filters: filters.value })
  //   },
  //   { deep: true }
  // )

  onMounted(() => {
    fechtTags({ skip: skipValue.value, take: takeValue.value })
  })
  watch(
    filters,
    () => {
      onFilter()
    },
    {
      deep: true,
    }
  )
</script>
<template>
  <!-- <TestDataTable /> -->
  <div>
    <header class="flex justify-content-between w-full mt-3">
      <h1 class="m-0">Tags</h1>
      <diV class="flex align-items-center gap-2">
        <div class="flex justify-content-end align-items-center">
          <!-- <Button class="p-column-filter-clear-button p-link" icon="pi pi-filter-slash" @click="clearFilter()" /> -->
          <span class="p-input-icon-left flex flex-row align-items-center gap-4">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['name'].constraints[0].value"
              placeholder="Keyword Search"
              @change="
                (e) => {
                  console.log(e, 'input')
                }
              "
            />
          </span>
        </div>
        <Button
          :label="t('tags.createTag')"
          icon="pi pi-tags"
          icon-pos="right"
          class="p-button-text"
          @click="
            () => {
              dialogCreateTag = true
              handleEmptyCreate()
            }
          "
        />
      </diV>
    </header>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      v-model:rows="takeValue"
      class="w-full pt-3"
      lazy
      :first="skipValue"
      :value="dataTable || []"
      size="large"
      paginator
      :rows-per-page-options="[5, 10, 20, 50]"
      data-key="id"
      filter-display="menu"
      :total-records="tagResponse.length"
      :global-filter-fields="['name', 'photoCount', 'createTime']"
      edit-mode="cell"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter()"
    >
      <Column
        field="name"
        :header="t('tagsData.name')"
        filter-field="name"
        :show-filter-operator="false"
        :show-apply-button="false"
        :show-add-button="false"
        sortable
        style="max-width: 10rem"
      >
        <template #body="{ data }">
          {{ data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" :placeholder="t('tagsData.name')" @input="filterCallback()" />
        </template>
      </Column>
      <Column
        field="photoCount"
        :header="t('tagsData.count')"
        filter-field="photoCount"
        :show-filter-operator="false"
        :show-add-button="false"
        :show-apply-button="true"
        sortable
        style="max-width: 10rem"
      >
        <!-- :show-apply-button="false" -->
        <!-- header="Count"
      field="photoCount"
      filter-field="photoCount"
      sortable
      style="max-width: 5rem"
      :show-filter-operator="false"
      :show-apply-button="false"
      :show-add-button="false" -->
        <template #body="{ data }">
          {{ data.photoCount }}
        </template>
        <template #filter="{ filterModel, applyFilter, filterCallback }">
          <!-- @blur="applyFilter()" -->
          <InputNumber
            v-model="filterModel.value"
            :placeholder="t('tagsData.placeholderCount')"
            @input="
              () => {
                // applyFilter()
                filterCallback()
              }
            "
            @update:model-value="
              () => {
                applyFilter()
                filterCallback()
              }
            "
          />
        </template>
      </Column>
      <Column
        field="createTime"
        :header="t('tagsData.created')"
        filter-field="createTime"
        data-type="date"
        sortable
        style="max-width: 10rem"
        :show-filter-operator="false"
        :show-apply-button="false"
        :show-add-button="false"
      >
        <template #body="{ data }">
          {{ useDateFormatUtil(data.createTime, 'MMM D, YYYY h:mm A') }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Calendar v-model="filterModel.value" date-format="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" @date-select="filterCallback()" />
        </template>
      </Column>
      <Column style="width: 10%; max-width: 8rem">
        <template #body="{ data }">
          <Button
            :disabled="!data.isDefault"
            class="p-button-rounded p-button-text p-button-danger p-0 h-fit"
            icon="pi pi-trash"
            @click="
              () => {
                tagSelect = data
                dialogDeleteTag = true
              }
            "
          />
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- dialog create -->
  <Dialog
    v-model:visible="dialogCreateTag"
    modal
    :header="t('tags.createTagHeader')"
    :draggable="false"
    :style="{ width: '50vw', marginTop: '70px' }"
    content-class="p-0"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <div class="w-full p-3 flex flex-column justify-content-end align-items-end">
      <div class="flex w-full align-items-center md:justify-content-end justify-content-center pb-4">
        <div class="col-12 flex align-items-center justify-content-center">
          <div class="w-full relative p-input-icon-right">
            <InputText ref="searchInput" v-model="createField" type="text" :placeholder="t('tags.createTagPlaceholder')" class="w-full pr-5" />
            <i v-if="loadingCreateTag" class="pi pi-spin pi-spinner absolute" style="right: 10px" />
            <i v-if="!loadingCreateTag && createField.length > 0" class="pi pi-times absolute" style="right: 10px" @click="handleEmptyCreate" />
          </div>
        </div>
      </div>
      <div class="">
        <Button :label="t('tags.createButton')" @click="createTag" />
      </div>
    </div>
  </Dialog>
  <!-- dialog delete -->
  <Dialog v-model:visible="dialogDeleteTag" class="w-full m-0" style="max-width: 42rem" :header="t('tags.deleteTagHeader')" :modal="true" :draggable="false">
    <div class="flex flex-column gap-2">
      <div style="font-size: 1rem; font-weight: 600; margin-bottom: 0.5rem">
        {{ t('tags.deleteTagDescription') }}
      </div>
      <div class="flex flex-row gap-2">
        <Button :label="t('tags.deleteTagButtonCancel')" class="p-button-text" @click="dialogDeleteTag = false" />
        <Button :label="t('tags.deleteTagButton')" class="p-button-danger" @click="deleteTag" />
      </div>
    </div>
  </Dialog>
</template>
