export const noteComponentEnUS = {
  edit: 'Edit',
  delete: 'Delete',
  reply: 'Reply',
  noteTitle: 'Note Title',
  noteContent: 'Note Content',
  cancel: 'CANCEL',
  submit: 'SUBMIT',
  phone: 'Phone',
  email: 'Email',
  areYouSure: 'Are you sure you want to delete this note?',
  confirm: 'CONFIRM',
  projectIsClosed: 'The project is closed',
  isNotAuthorized: 'You are not authorized to create notes',
  zohoIsIntegrate: "Zoho ins't integrated",
}

export const noteComponentEsMX = {
  edit: 'Editar',
  delete: 'Borrar',
  reply: 'Responder',
  noteTitle: 'Título de la nota',
  noteContent: 'Contenido de la nota',
  cancel: 'CANCELAR',
  submit: 'ENVIAR',
  phone: 'Teléfono',
  email: 'Correo electrónico',
  areYouSure: '¿Estás seguro de que quieres eliminar esta nota?',
  confirm: 'CONFIRMAR',
  projectIsClosed: 'El proyecto esta cerrado',
  isNotAuthorized: 'No estas autorizado a crear notas',
  zohoIsIntegrate: 'No hay una integracion de zoho',
}
