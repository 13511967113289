export const projectNotesEnUS = {
  success: 'Success',
  noteAdded: 'Note added',
  error: 'Error',
  errorAddingNote: 'Error adding note',
  submit: 'SUBMIT',
  addNotes: 'Add Notes',
  notes: 'Notes',
  addNote: 'Add Note',
  noteEditor: 'write a note...',
}

export const projectNotesEsMX = {
  success: 'Éxito',
  noteAdded: 'Nota agregada',
  error: 'Error',
  errorAddingNote: 'Error agregando nota',
  submit: 'ENVIAR',
  addNotes: 'Agregar notas',
  notes: 'Notas',
  addNote: 'Agregar nota',
  noteEditor: 'escribe una nota...',
}
