export const projectLaborBillEnUS = {
  noFound: 'Final Labor Bill not available',
  description: 'Description',
  laborCost: 'Labor Cost',
  quantity: 'Quantity',
  total: 'Total',
  lineItem: 'Line Item',
  title: 'Final Labor Bill',
  totalLaborCost: 'Total Labor Cost',
  submit: 'SUBMIT',
  addLineItem: 'ADD LINE ITEM',
  deleteLineItem: 'DELETE LINE ITEM',
  alertSuccess: 'Success',
  alertSuccessMessage: 'Labor bill updated',
  alertError: 'Error',
  alertErrorMessage: 'Error updating labor bill',
  gatheringInformation: 'Gathering information',
}

export const projectLaborBillEsMX = {
  noFound: 'No hay estimación de la Factura Final de Trabajo disponible',
  description: 'Descripción',
  laborCost: 'Mano de obra',
  title: 'Factura Final de Trabajo',
  gatheringInformation: 'Recopilando información',
  quantity: 'Cantidad',
  total: 'Total',
  lineItem: 'Artículo',
  totalLaborCost: 'Costo total',
  submit: 'ENVIAR',
  addLineItem: 'AGREGAR ITEM',
  deleteLineItem: 'ELIMINAR ITEM',
  alertSuccess: 'Éxito',
  alertSuccessMessage: 'Labor bill actualizado',
  alertError: 'Error',
  alertErrorMessage: 'Error al actualizar la factura de mano de obra',
}
