export const calculateBase = (original_width: number, original_height: number) => {
  const referenceWidth = 1280
  const referenceHeight = 720

  const widthScale = original_width / referenceWidth
  const heightScale = original_height / referenceHeight
  const scale = (widthScale + heightScale) / 2

  const baseSize = 300
  const scaledSize = baseSize * scale

  return scaledSize
}
