export const commentComponentEnUS = {
  success: 'Success',
  commentDeleted: 'Comment deleted',
  error: 'Error',
  errorDeleting: 'Error deleting comment',
  commentEdited: 'Comment edited',
  errorEditing: 'Error editing comment',
  cancel: 'CANCEL',
  submit: 'SUBMIT',
  edit: 'Edit',
  delete: 'Delete',
  confirmDeletion: 'Confirm Deletion',
  areYouSure: 'Are you sure you want to delete this comment?',
  confirm: 'CONFIRM',
}

export const commentComponentEsMX = {
  success: 'Éxito',
  commentDeleted: 'Comentario eliminado',
  error: 'Error',
  errorDeleting: 'Error al eliminar el comentario',
  commentEdited: 'Comentario editado',
  errorEditing: 'Error al editar el comentario',
  cancel: 'CANCELAR',
  submit: 'ENVIAR',
  edit: 'Editar',
  delete: 'Eliminar',
  confirmDeletion: 'Confirmar eliminación',
  areYouSure: '¿Estás seguro de que quieres eliminar este comentario?',
  confirm: 'CONFIRMAR',
}
