<script lang="ts" setup>
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, ref } from 'vue'
  import api from '@/services/api'
  import { i18n } from '@/i18n'
  import { getPermissionsUser, getPermission } from '@/components/PermissionRole/funtions'
  import { useDebounceFn } from '@vueuse/core'
  import ProgressSpinner from 'primevue/progressspinner'

  type IuserDirectory = {
    id: string
    fullName: string
    email: string
    phoneNumber: string
    profile: string
    zuid: string
  }

  const search_term = ref<string>('')
  const route = useRoute()
  const router = useRouter()
  const { t } = i18n.global
  const filter = route.params.filter

  const usersMenu = [
    {
      id: '5640e2bc-9106-4b94-b61d-da890dc54a7a',
      title: t('directoryIndex.all'),
      to: '/directory',
      value: 'all',
      keyNodo: 'directoryAllPermissions',
      keyName: 'show',
    },
    {
      id: 'c2891f03-abaf-4167-a7d1-4b3bf99f1a59',
      title: t('directoryIndex.production'),
      to: '/directory/production',
      value: 'production',
      keyNodo: 'directoryProductionPermissions',
      keyName: 'show',
    },
    {
      id: 'dad2d890-e661-4fe4-9b78-b405017fe31f',
      title: t('directoryIndex.sales'),
      to: '/directory/sales',
      value: 'sales',
      keyNodo: 'directorySalesPermissions',
      keyName: 'show',
    },
    {
      id: '62304738-6938-4f11-8c18-4fa79519faab',
      title: t('directoryIndex.office'),
      to: '/directory/office',
      value: 'office',
      keyNodo: 'directoryOfficePermissions',
      keyName: 'show',
    },
  ]
  const userDirectory = ref<IuserDirectory[]>([])
  const tabActive = ref(0)

  async function getUsersByGroup(group: string, name = '') {
    userDirectory.value = []
    const {
      data: { data: _users },
    } = await api.get(`/Api/Users/ProfileBy/${group.toUpperCase()}?query=${name}`)

    userDirectory.value = [..._users]
  }

  function tabClick(tabEvent: { index: number; originalEvent: Event }) {
    search_term.value = ''
    router.push(usersMenu[tabEvent.index].to)
    getUsersByGroup(usersMenu[tabEvent.index].value)
  }

  const debounced = useDebounceFn(async () => {
    await filterName()
  }, 500)
  const filterName = async () => {
    const group = filter ? (filter as string) : 'all'
    await getUsersByGroup(group, search_term.value)
  }
  // function sendSms(phone: any) {
  //   window.location = `sms://+1${phone}`
  // }

  // function makePhoneCall(phone: any) {
  //   window.location = `tel://+1${phone}`
  // }
  // function sendEmail(email: string) {
  //   window.location = `mailto:${email}`
  // }

  onMounted(async () => {
    const group = filter ? (filter as string) : 'all'
    await getUsersByGroup(group)
  })
</script>

<template>
  <div class="flex flex-column h-full">
    <div class="col">
      <div class="flex flex-column lg:flex-row lg:justify-content-between flex-nowrap md:flex-wrap gap-3">
        <div>
          <h1 class="mb-0">{{ $t('directoryIndex.directory') }}</h1>
        </div>
        <div>
          <span class="p-input-icon-left w-full md:w-full">
            <i class="pi pi-search" />
            <InputText v-model="search_term" type="text" :placeholder="t('directoryIndex.search')" class="w-full md:w-full" @input="debounced" />
          </span>
        </div>
      </div>
    </div>
    <Toast />
    <div class="grid">
      <div class="col col-12">
        <TabView v-model:activeIndex="tabActive" @tab-click="tabClick">
          <TabPanel
            v-for="item in usersMenu.filter((item) => getPermission(getPermissionsUser(), item.keyName, item.keyNodo))"
            :key="item.id"
            :header="item.title"
            content-class="hidden"
          ></TabPanel>
        </TabView>
      </div>
    </div>
    <div v-if="userDirectory.length === 0" class="flex justify-content-center w-full align-items-center h-full">
      <ProgressSpinner
        style="width: 50px; height: 50px background-color: red"
        stroke-width="5"
        animation-duration="1s"
        :pt="{
          circle: {
            style: {
              stroke: '#2563eb',
              animation: 'p-progress-spinner-dash 1.5s ease-in-out infinite',
            },
          },
        }"
      />
    </div>
    <div v-else class="grid w-full h-full">
      <div v-for="user in userDirectory" :key="user.id" class="col col-12 md:col-4 lg:col-4 xl:col-3">
        <div v-if="userDirectory.length > 0" class="flex w-full justify-content-start flex-wrap">
          <div class="p-card w-full shadow-none p-3 flex-column">
            <div class="flex justify-content-between align-items-center flex-column gap-1">
              <div class="flex align-items-center flex-column gap-2 justify-content-center">
                <Avatar :image="`https://contacts.zoho.com/file?ID=${user.zuid}&fs=thumb`" class="mr-2" size="large" shape="circle" />
                <div class="flex flex-column align-items-center gap-1">
                  <div class="text-lg font-bold">
                    {{ user.fullName }}
                  </div>
                  <div v-if="user.profile" class="text-muted font-normal text-sm">
                    {{ user.profile }}
                  </div>
                </div>
              </div>
              <div>
                <div class="flex flex-nowrap justify-content-end gap-1 pt-2">
                  <a v-if="user.email" :href="`mailto:${user.email}`" class="p-button-rounded p-button-text text-lg w-12 h-12 px-3">
                    <i class="pi pi-envelope" />
                  </a>
                  <a v-if="user.phoneNumber" :href="`tel:${user.phoneNumber}`" class="p-button-rounded p-button-text text-lg w-12 h-12 px-3">
                    <i class="pi pi-phone" />
                  </a>

                  <a v-if="user.phoneNumber" :href="`sms://${user.phoneNumber}`" icon="pi pi-comment" class="p-button-rounded p-button-text text-lg w-12 h-12 px-3">
                    <i class="pi pi-comment" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .p-chip.location-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }

  .p-tabview .p-tabview-nav-btn.p-link {
    background: #eff3f8;
  }
</style>
