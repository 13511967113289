<script setup lang="ts">
  import { toRefs } from 'vue'
  import { ICrew } from '@/interfaces/crew/Crew'
  import { i18n } from '@/i18n'

  const { t } = i18n.global
  type Iprops = {
    crewsForReview: ICrew[]
  }

  const props = defineProps<Iprops>()
  const { crewsForReview } = toRefs(props)
</script>
<template>
  <div class="flex-column gap-4" style="padding-top: 1.25rem; padding-bottom: 1.25rem">
    <div v-for="crew in crewsForReview" :key="crew.id" class="w-full flex flex-column justify-content-center align-items-center gap-3 bg-white">
      <div class="field w-full mb-0">
        <label for="workmanship_comments" class="w-full">{{ t('reviewsCrew.workmanship') }}:</label>
        <Rating v-model="crew.reviews[0].rating" :stars="5" :cancel="false" />
      </div>

      <div class="field w-full mb-0">
        <label for="timeliness_comments" class="w-full">{{ t('reviewsCrew.comment') }}:</label>
        <Textarea v-model="crew.reviews[0].comment" rows="4" class="w-full"></Textarea>
      </div>

      <div class="field w-full mb-0">
        <label for="timeliness_rating" class="w-full">{{ t('reviewsCrew.timeliness') }}:</label>
        <Rating v-model="crew.reviews[1].rating" :stars="5" :cancel="false" />
      </div>

      <div class="field w-full mb-0">
        <label for="timeliness_comments" class="w-full">{{ t('reviewsCrew.comment') }}:</label>
        <Textarea v-model="crew.reviews[1].comment" rows="4" class="w-full"></Textarea>
      </div>
    </div>
  </div>
</template>
