<script setup lang="ts">
  import { i18n } from '@/i18n'
  import { TImpersonationId, useAccountStore, useBreakpointsStore, useUserStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { ref, computed } from 'vue'
  import useLoginUsersByAdmin from './composable'
  import { required, minLength } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  import api from '@/services/api'
  import { captureException } from '@sentry/vue'
  import { useToast } from 'primevue/usetoast'
  import { useUserInfo } from '@/composables/useUserInfo'
  import { AutoCompleteCompleteEvent } from 'primevue/autocomplete'

  type TError = {
    response: {
      data: {
        responseException: {
          exceptionMessage: string
        }
      }
    }
  }

  type IOption = {
    label: string
    value: string
  }

  const { t } = i18n.global
  const showDialogUsers = ref(false)
  const loadingCreateImpersonation = ref(false)
  const breakpointStore = useBreakpointsStore()
  const userId = ref<IOption | null>(null)
  const { getUsersByOrg, users } = useLoginUsersByAdmin()
  const { md } = storeToRefs(breakpointStore)
  const accountStore = useAccountStore()
  const userInfo = useUserInfo()
  const showModalBackToAdmin = ref(false)
  const suggestions = ref(users.value)
  const { accessTokenAdmin, impersonation } = storeToRefs(accountStore)
  const user = useUserStore()
  const { profile } = storeToRefs(user)

  const isRolAdmin = computed(() => {
    const rol = profile?.value?.toUpperCase()
    return rol === 'ADMINISTRATOR'
  })

  const { changeTokenInAdmin, backTokenInAdmin } = accountStore
  const handleShowDialog = (show: boolean) => {
    showDialogUsers.value = show
  }

  const handleClickButton = () => {
    handleShowDialog(true)
    getUsersByOrg()
  }

  const rules = computed(() => ({
    userId: {
      value: {
        required,
        minLength: minLength(1),
      },
    },
  }))
  const toast = useToast()
  const validationForm = useVuelidate(rules, { userId })

  const handleCreateImpersonation = async () => {
    loadingCreateImpersonation.value = true
    try {
      const endpoint = `/api/ImpersonateUser/${userId.value?.value}`
      const response = await api.post(endpoint)

      const impersonation = response.data?.data as TImpersonationId
      changeTokenInAdmin(impersonation)
      userInfo.getInfoUserFirstTime().finally(() => {
        location.reload()
      })
    } catch (error) {
      const err = error as TError
      toast.add({
        severity: 'error',
        summary: t('settingsIndex.successTitleDiagnostic'),
        detail: err?.response?.data?.responseException?.exceptionMessage,
        life: 3000,
      })
      captureException(error)
    } finally {
      loadingCreateImpersonation.value = false
    }
  }

  const handleRemoveImpersonation = async () => {
    loadingCreateImpersonation.value = true
    try {
      const endpoint = `/api/StopImpersonation/${impersonation?.value?.impersonationId}`
      await api.post(endpoint)
      backTokenInAdmin()
      userInfo.getInfoUserFirstTime().finally(() => {
        location.reload()
      })
    } catch (error) {
      const err = error as TError
      toast.add({
        severity: 'error',
        summary: t('settingsIndex.successTitleDiagnostic'),
        detail: err?.response?.data?.responseException?.exceptionMessage,
        life: 3000,
      })
      captureException(error)
    } finally {
      loadingCreateImpersonation.value = false
    }
  }

  const handleSubmitForm = async () => {
    const resultForm = validationForm.value.$validate()

    resultForm.then((res) => {
      if (res) {
        handleCreateImpersonation()
      }
    })
  }
  const handleBackToAdmin = async () => {
    handleRemoveImpersonation()
  }
  const handleClickBackAdmin = () => {
    showModalBackToAdmin.value = true
  }
  const search = (event: AutoCompleteCompleteEvent) => {
    suggestions.value = users.value.filter((user) => user.label.toLowerCase().includes(event.query.toLowerCase()))
  }
</script>
<template>
  <Dialog
    v-model:visible="showDialogUsers"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0"
    style="max-width: 40rem"
    :modal="true"
    :dismissable-mask="true"
    :draggable="false"
    :show-header="true"
    :close-on-escape="true"
    :closable="true"
    :header="t('settingsIndex.loginWithOtherUserTitleModal')"
  >
    <form action="" class="flex flex-column gap-3" @submit.prevent="handleSubmitForm">
      <p class="m-0">
        {{ t('settingsIndex.loginWithOtherUserDescriptionModal') }}
      </p>
      <AutoComplete
        v-model="userId"
        :suggestions="suggestions"
        option-label="label"
        option-value="value"
        class="w-full"
        :dropdown="true"
        :placeholder="t('settingsIndex.loginWithOtherUserLabelDropdown')"
        :class="{ 'p-invalid': validationForm.userId.$errors?.length > 0 }"
        @complete="search"
      />
      <!-- <Dropdown
        v-model="userId"
        :options="users"
        option-label="label"
        option-value="value"
        class="w-full"
        :placeholder="t('settingsIndex.loginWithOtherUserLabelDropdown')"
        :class="{ 'p-invalid': validationForm.userId.$errors?.length > 0 }"
      /> -->
      <small v-if="validationForm.userId.$errors?.length > 0" id="dd-error" class="p-error"> {{ t('settingsIndex.loginWithOtherUserError') }}</small>
      <Button
        :loading="loadingCreateImpersonation"
        :disabled="loadingCreateImpersonation"
        :label="t('settingsIndex.loginWithOtherUserButtonSubmit')"
        severity="primary"
        type="submit"
      />
    </form>
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>
  <Dialog
    v-model:visible="showModalBackToAdmin"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0"
    style="max-width: 40rem"
    :modal="true"
    :dismissable-mask="true"
    :draggable="false"
    :show-header="true"
    :close-on-escape="true"
    :closable="true"
    :header="t('settingsIndex.loginBackToAdmin')"
  >
    <form action="" class="flex flex-column gap-3" @submit.prevent="handleSubmitForm">
      <p>
        {{ t('settingsIndex.loginBackToAdminDescription') }}
      </p>
      <Button
        :loading="loadingCreateImpersonation"
        :disabled="loadingCreateImpersonation"
        :label="t('settingsIndex.loginBackToAdminButton')"
        severity="primary"
        type="submit"
        @click="handleBackToAdmin"
      />
    </form>
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>

  <div v-if="isRolAdmin || accessTokenAdmin">
    <Button v-if="accessTokenAdmin" :label="t('settingsIndex.loginBackToAdmin')" class="w-full" severity="primary" :outlined="true" @click="handleClickBackAdmin" />
    <Button v-else :label="t('settingsIndex.loginWithOtherUserAdmin')" class="w-full" severity="primary" :outlined="true" @click="handleClickButton" />
  </div>
</template>
