<script setup lang="ts">
  import { useSwipe } from '@vueuse/core'
  import { ref, onMounted, computed, toRefs } from 'vue'

  const STATUS_ERROR = -1
  const STATUS_START = 0
  const STATUS_READY = 1
  const STATUS_REFRESH = 2

  const LABELS = ['Datos incorrectos', 'Arrastra para actualizar', 'Suelta para actualizar', 'Actualizando datos...']

  interface Config {
    pullDownHeight?: number
    errorLabel?: string
    startLabel?: string
    readyLabel?: string
    loadingLabel?: string
  }

  interface Props {
    onRefresh?: () => Promise<void> | void
    config?: Config
  }

  const el = ref<HTMLElement | null>(null)
  const pullDownHeader = ref<HTMLElement | null>(null)
  const pullDown = ref({
    status: STATUS_START,
    height: 0,
    msg: '',
  })
  const canPull = ref(false)
  const touchPosition = ref({ start: 0, distance: 0 })

  const props = defineProps<Props>()
  const { config, onRefresh } = toRefs(props)

  const customLabels = computed(() => {
    const getLabel = (label: string | undefined, index: number) => (label !== undefined ? label : LABELS[index])
    return [getLabel(config?.value?.errorLabel, 0), getLabel(config?.value?.startLabel, 1), getLabel(config?.value?.readyLabel, 2), getLabel(config?.value?.loadingLabel, 3)]
  })

  const label = computed(() => (pullDown.value.status === STATUS_ERROR ? pullDown.value.msg : customLabels.value[pullDown.value.status + 1]))

  const iconClass = computed(() => {
    if (pullDown.value.status === STATUS_REFRESH) return 'pull-down-refresh'
    if (pullDown.value.status === STATUS_ERROR) return 'pull-down-error'
    return ''
  })

  const pullDownContentStyle = computed(() => ({
    bottom: `${(config?.value?.pullDownHeight ?? 60 - 40) / 2}px`,
  }))

  const { direction } = useSwipe(el, {
    onSwipeStart(e) {
      if (!el.value) return
      const touch = e.touches.item(0) as Touch
      touchPosition.value.start = touch.pageY

      canPull.value = el.value.scrollTop === 0
    },
    onSwipe(e) {
      if (!canPull.value) return

      const touch = e.touches.item(0) as Touch
      const distance = Math.min(touch.pageY - touchPosition.value.start, 180)

      if (distance > 0 && direction.value === 'down') {
        el.value?.style && (el.value.style.overflow = 'hidden')
      }

      touchPosition.value.distance = distance
      pullDown.value.height = distance

      pullDown.value.status = distance > (config?.value?.pullDownHeight ?? 60) ? STATUS_READY : STATUS_START
    },
    onSwipeEnd() {
      canPull.value = false

      if (!el.value) return
      el.value.style.overflowY = 'auto'

      if (touchPosition.value.distance - el.value.scrollTop > (config?.value?.pullDownHeight ?? 60)) {
        el.value.scrollTop = 0
        pullDown.value.height = config?.value?.pullDownHeight ?? 60
        pullDown.value.status = STATUS_REFRESH

        if (onRefresh?.value && typeof onRefresh.value === 'function') {
          const res = onRefresh.value()

          if (res && res.then && typeof res.then === 'function') {
            res.then(
              () => resetPullDown(true),
              (error) => {
                pullDown.value.msg = error || customLabels.value[0]
                pullDown.value.status = STATUS_ERROR
                setTimeout(() => {
                  resetPullDown(true)
                }, 1000)
              }
            )
          } else {
            resetPullDown()
          }
        } else {
          resetPullDown()
          console.warn('please use :on-refresh to pass onRefresh callback')
        }
      } else {
        resetPullDown()
      }

      touchPosition.value.distance = 0
      touchPosition.value.start = 0
    },
  })

  const resetPullDown = (withAnimation = false) => {
    const pullDownHeader = el.value?.querySelector('.pull-down-header') as HTMLElement
    const icon = pullDownHeader?.querySelector('.pull-down-content--icon') as HTMLElement
    if (!pullDownHeader || !icon) return
    if (withAnimation) {
      //   pullDownHeader?.style && (pullDownHeader.style.transition = ANIMATION)
    }

    pullDown.value.height = 0
    pullDown.value.status = STATUS_START
    icon.style.transform = ''
  }

  onMounted(() => {
    if (!el.value) return
    console.log('el.value', el.value)
    pullDownHeader.value?.addEventListener('transitionend', () => {
      pullDownHeader.value?.style && (pullDownHeader.value.style.transition = '')
    })

    pullDownHeader.value?.addEventListener('webkitTransitionEnd', () => {
      pullDownHeader.value?.style && (pullDownHeader.value.style.transition = '')
    })
  })
</script>

<template>
  <div ref="el" class="pull-down-container">
    <div class="pull-down-header" :style="{ height: `${pullDown.height}px` }">
      <div class="pull-down-content" :style="pullDownContentStyle">
        <i
          class="pull-down-content--icon pi"
          :class="{
            'pi-times': iconClass === 'pull-down-error',
            'pi-arrow-up': iconClass === 'pull-down-refresh',
            'pi-arrow-down': iconClass === '',
          }"
        ></i>
        <span class="pull-down-content--label">{{ label }}</span>
      </div>
    </div>
    <div class="slot-container">
      <slot class="custom-slot"></slot>
    </div>
  </div>
</template>

<style lang="scss">
  .pull-down-container {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  .pull-down-header {
    width: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
    background-color: var(--surface-100);
    transition: height 0.2s ease;
  }

  .pull-down-content {
    position: absolute;
    width: 90%;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    color: #d5d5d5;
    text-align: center;
    border-left: 20px solid transparent;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--icon {
      float: left;
      height: 20px;
      width: 20px;
      margin-top: 10px;
      margin-left: -20px;
      background-size: 20px 20px;

      &.pull-down-refresh {
        background-size: 20px 20px;
        animation: rotate 2s infinite;
        animation-timing-function: linear;
      }

      &.pull-down-error {
        background-size: 20px 20px;
      }
    }

    &--label {
      color: var(--blue-500);
      float: left;
      height: 20px;
      line-height: 20px;

      width: 100%;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .custom-slot {
    max-height: calc(100% - 40px);
    overflow-y: scroll;
    width: 100%;
  }
</style>
