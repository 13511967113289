<script setup lang="ts"></script>

<template>
  <div>
    Not Found
    <router-link to="/">back to home</router-link>
  </div>
</template>

<style scoped lang="scss"></style>
