import { i18n } from '@/i18n'
import { useUserStore } from '@/store'
import { storeToRefs } from 'pinia'

export type Language = 'en' | 'es'

const ChangeLanguage = (language: Language) => {
  const userStore = useUserStore()
  const { setLanguage } = userStore
  const { settings } = storeToRefs(userStore)

  const languageUser = settings.value.find((item) => item.key === 'Language')
  if (languageUser && i18n.global.locale !== language) {
    i18n.global.locale = language
    setLanguage(language)
  }
}

export default ChangeLanguage
