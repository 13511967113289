import { IHttpConnectionOptions, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { ref } from 'vue'

const createSignalRConnection = (url: string, options: IHttpConnectionOptions) => new HubConnectionBuilder().withUrl(url, options).configureLogging(LogLevel.Information).build()

export const useSignalRMedia = (url: string, key: string, tenant: string, callback?: (e: any) => void, options: IHttpConnectionOptions = {}) => {
  const connectionRef = ref<signalR.HubConnection>()
  const startConnection = async () => {
    const connection = createSignalRConnection(url, options)
    try {
      connection.on(key, (e) => {
        console.log('Media event received: ', e)
        callback?.(e)
      })
      await connection.start()
      await connection.invoke('ConnectToChannel', `${tenant}-${key}`)
      console.log('SignalR connected.')
      connectionRef.value = connection
    } catch (err) {
      console.error('Error while starting SignalR connection: ', err)
      setTimeout(startConnection, 5000) // Retry connection every 5 seconds
    }
    console.log('---------------------------------------')
  }

  const stopConnection = async () => {
    if (connectionRef.value) {
      await connectionRef.value.stop()
      console.log('SignalR disconnected.')
    }
  }

  return { startConnection, stopConnection }
}
