<script lang="ts" setup>
  import { reactive, ref } from 'vue'
  import { i18n } from '@/i18n'
  import api from '@/services/api'
  const { t } = i18n.global

  const emit = defineEmits(['closeAddDialog'])
  const loading = ref(false)
  const values = reactive({
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
  })

  const handleSubmit = async () => {
    loading.value = true
    const input = {
      email: values.email,
      phoneNumber: values.phoneNumber,
      firstName: values.firstName,
      lastName: values.lastName,
    }
    await api.post(`/Api/CrewUser/Create`, input).then((response) => {
      console.log(response)
      emit('closeAddDialog')
      loading.value = false
    })
  }
</script>

<template>
  <form class="w-full" @submit.prevent="handleSubmit()">
    <div class="field">
      <label for="firstName">{{ t('crewUser.firstname') }}</label>
      <InputText id="firstName" v-model="values.firstName" type="text" class="w-full" />
    </div>

    <div class="field">
      <label for="lastname">{{ t('crewUser.lastname') }}</label>
      <InputText id="lastname" v-model="values.lastName" type="text" class="w-full" />
    </div>
    <div class="field">
      <label for="phoneNumber">{{ t('crewUser.phone') }}</label>
      <InputText id="phoneNumber" v-model="values.phoneNumber" type="text" class="w-full" />
    </div>
    <div class="field">
      <label for="email">{{ t('crewUser.email') }}</label>
      <InputText id="email" v-model="values.email" type="text" class="w-full" />
    </div>
    <div class="flex gap-3">
      <Button type="submit" class="w-full" icon-pos="right" :loading="loading" icon="pi pi-chevron-right" :label="t('crewUser.submit')" />
    </div>
  </form>
</template>
