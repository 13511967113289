import { IOption } from '@/interfaces/option/option.interface'
import { sortObjectByValue } from '@/utils/sortObjectByValue'

export const projectStages: IOption[] = [
  {
    value: 'WILL_CALLED_ORDERED',
    label: 'projectStage.WILL_CALLED_ORDERED',
    color: '#2DCCFF',
  },
  {
    value: 'READY_FOR_PRODUCTION',
    label: 'projectStage.READY_FOR_PRODUCTION',
    color: '#2DCCFF',
  },
  {
    value: 'PROJECT_SCHEDULED',
    label: 'projectStage.PROJECT_SCHEDULED',
    color: '#56F000',
  },
  {
    value: 'IN_PRODUCTION',
    label: 'projectStage.IN_PRODUCTION',
    color: '#56F000',
  },
  {
    value: 'COMPLETE',
    label: 'projectStage.COMPLETE',
    color: '#56F000',
  },
  {
    value: 'NONE',
    label: 'projectStage.NONE',
    color: '#FF3838',
  },
  {
    value: 'READY_TO_VERIFY',
    label: 'projectStage.READY_TO_VERIFY',
    color: '',
  },
  {
    value: 'CONTRACT_VERIFIED',
    label: 'projectStage.CONTRACT_VERIFIED',
    color: '#2DCCFF',
  },
  {
    value: 'SCHEDULE_PRE_WALK_THROUGH',
    label: 'projectStage.SCHEDULE_PRE_WALK_THROUGH',
    color: '#FCE83A',
  },
  {
    value: 'PM_APPROVED',
    label: 'projectStage.PM_APPROVED',
    color: '#56F000',
  },
  {
    value: 'PO_REJECTED_BY_PM',
    label: 'projectStage.PO_REJECTED_BY_PM',
    color: '#FFB302',
  },
  {
    value: 'CORRECTED_PO_AWAITING_APPROVAL',
    label: 'projectStage.CORRECTED_PO_AWAITING_APPROVAL',
    color: '#FCE83A',
  },
  {
    value: 'SECOND_PO_REJECTED',
    label: 'projectStage.SECOND_PO_REJECTED',
    color: '#FFB302',
  },
].sort(sortObjectByValue)

export const removeSlash = (str?: string) => {
  if (!str) return ''
  return str.replace('/', '').replace(/\s+/g, '_').toUpperCase()
}
// Critical, alert, form error, emergency, urgent = #FF3838
// Serious, error, warning, needs attention = #FFB302
// Caution, unstable, unsatisfactory = #FCE83A
// Normal, on, ok, fine, go, satisfactory = #56F000
// Standby, available, enabled = #2DCCFF
// Off, unavailable, disabled = #A4ABB6
