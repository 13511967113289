import { defineStore } from 'pinia'

import { PhotoQueueInterface } from '@/db/tables/PhotoQueue.table'
interface PhotoQueueStore {
  sidebarActive: boolean
  photoQueue: PhotoQueueInterface[]
}
export const usePhotoQueueStore = defineStore({
  id: 'PhotoQueue',
  state: (): PhotoQueueStore => ({ sidebarActive: false, photoQueue: [] }),
  getters: {
    getPhotoQueue: (state): PhotoQueueInterface[] => state.photoQueue,
    getSidebarActive: (state): boolean => state.sidebarActive,
    getPhotoQueueLength: (state): number => state.photoQueue.length,
  },
  actions: {
    async openSideBarQueue() {
      this.sidebarActive = true
    },
    async addPhotoToQueue(photo: PhotoQueueInterface) {
      this.photoQueue = [...this.photoQueue, photo]
    },
    async updatePhoto(photo: any) {
      const index = this.photoQueue.findIndex((p) => p.id === photo.id)
      if (index >= 0) {
        this.photoQueue[index].status = photo.status
        this.photoQueue[index].progress = photo.progress
      }
    },
    async removePhotoFromQueue(photo: any) {
      const index = this.photoQueue.findIndex((p) => p.id === photo.id)
      if (index >= 0) this.photoQueue.splice(index, 1)
    },
  },
})
