export const leadsIndexEnUS = {
  leads: 'Leads',
  sort: 'Sort',
  projectsFilter: 'Projects Filter',
  filterType: 'Filter Type',
  filterTypeSearch: 'Filter Type',
  filterStage: 'Filter Stage',
  filterStageSearch: 'Filter Stage',
  table: {
    headers: {
      ccNumber: 'Customer Number',
      salesManager: 'Sales Manager',
      address: 'Address',
      lastModified: 'Last Modification',
      customerName: 'Customer Name',
    },
  },
}

export const leadsIndexEsMX = {
  leads: 'Leads',
  sort: 'Ordenar',
  projectsFilter: 'Filtro de proyectos',
  filterType: 'Tipo de proyecto',
  filterTypeSearch: 'Filtrar tipo',
  filterStage: 'Etapa del proyecto',
  filterStageSearch: 'Filtrar etapa',
  table: {
    headers: {
      ccNumber: 'Customer Number',
      salesManager: 'Gerente de ventas',
      address: 'Dirección',
      lastModified: 'Última modificación',
      customerName: 'Nombre del cliente',
    },
  },
}
