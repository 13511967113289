<script lang="ts" setup>
  import { computed, onMounted, ref, toRefs } from 'vue'
  import { IEditorProps } from './types'
  import { useStage } from './store/stage'
  import ShapeBox from './shapes/ShapeBox.vue'
  import { useShapes } from './store/shapes'
  import { device } from './mocks/device'
  import { ShapeKey, TShape } from './shapes/types'
  import { KonvaEventObject } from 'konva/lib/Node'
  import ShapeArrow from './shapes/ShapeArrow.vue'
  import ShapeCircle from './shapes/ShapeCircle.vue'
  import { useOrder } from './hooks/useOrder'
  import ShapeSticker from './shapes/ShapeSticker.vue'
  import { useDraw } from './store/draw'
  import EditorStageInDraw from './EditorStageInDraw.vue'
  import { templateShape } from './templates/shape'
  import { useColor } from './store/color'
  import ShapeDrawn from './shapes/ShapeDrawn.vue'
  import ShapeText from './shapes/ShapeText.vue'
  import { useDialogs } from './store/dialogs'
  import { useTextInDialog } from './store/text'
  import Konva from 'konva'

  const props = defineProps<IEditorProps>()
  const emit = defineEmits(['save', 'close'])
  const stageStore = useStage()
  const shapesStore = useShapes()
  const colorStore = useColor()
  const drawStore = useDraw()
  const { handleShowDialog } = useDialogs()
  const { handleSetText } = useTextInDialog()
  const { SET_IMAGE_DIMENSIONS, SET_STAGE_DIMENSIONS } = stageStore
  const { handleSelectShapeId, handleUpdateShape, handleDeleteShape, handleCreateShape, handleCopyShape } = shapesStore
  const { SET_COLOR } = colorStore
  const { color } = toRefs(colorStore)
  const { image } = toRefs(stageStore)
  const { photourl } = toRefs(props)
  const { isPaint } = toRefs(drawStore)
  const { shapes, shapeId } = toRefs(shapesStore)
  const stageExportRef = ref<Konva.Stage>()

  const stageDiv = ref<HTMLDivElement>()

  const loadFirstTimeImage = () => {
    const image = new Image()
    image.src = photourl.value
    image.onload = () => {
      SET_IMAGE_DIMENSIONS(image)
    }
  }
  const ImageRender = computed(() => {
    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.src = photourl.value

    return image
  })

  const imageProps = computed(() => {
    return {
      width: image.value?.width,
      height: image.value?.height,
      url: photourl.value,
      rotation: image.value?.rotation,
      scale: image.value?.scale,
    }
  })

  const handleSelect = (shape: TShape) => {
    if (isPaint.value) return
    SET_COLOR(shape.color)
    handleSelectShapeId(shape?.id)
  }

  const handleDragStop = (shape: TShape) => {
    handleUpdateShape(shape)
  }

  const handleClick = (e: KonvaEventObject<MouseEvent>) => {
    if (!e.target?.attrs?.id) {
      handleSelectShapeId(null)
    }
  }

  const handleCopy = (shape: TShape) => {
    const response = handleCopyShape(shape)
    handleSelect(response)
  }

  const handleDelete = (shape: TShape) => {
    handleDeleteShape(shape)
  }
  const handleText = (shape: TShape) => {
    handleSetText(shape)
    handleShowDialog('showDialogText', true)
  }
  const { startDrawing, continuousDrawing, stopDrawing, clearDrawPoints } = useDraw()
  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    handleClick(e)
    const stage = e.target?.getStage?.()
    const position = stage?.getRelativePointerPosition?.()
    const x = position?.x ?? 0
    const y = position?.y ?? 0
    startDrawing(x, y, image.value.scale)
  }
  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target?.getStage?.()
    const position = stage?.getRelativePointerPosition?.()
    const x = position?.x ?? 0
    const y = position?.y ?? 0
    continuousDrawing(x, y, image.value.scale)
  }
  const handleMouseUp = () => {
    const points = stopDrawing()
    const shape = templateShape({
      shapeKey: 'DRAW',
      color: color.value,
      screenWidth: image.value.width,
      screenHeight: image.value.height,
      points,
      shadowPoints: points,
      centerTextFirstTime: true,
      x: 0,
      y: 0,
      image_scale: image.value.scale,
      image_rotation: image.value.rotation,
    })
    handleCreateShape(shape)
    clearDrawPoints()
  }
  const hadleSave = () => {
    const uri = stageExportRef.value?.getStage()?.toDataURL()
    if (!uri) return
    emit('save', uri)
  }
  const handleClose = () => {
    emit('close')
  }

  useOrder()
  onMounted(() => {
    const clienRect = stageDiv.value?.getBoundingClientRect()
    if (!clienRect) return
    SET_STAGE_DIMENSIONS({
      width: clienRect?.width,
      height: clienRect?.height,
    })
    loadFirstTimeImage()
    const resizeObserver = new ResizeObserver(() => {
      if (stageDiv.value) {
        const { width, height } = stageDiv.value.getBoundingClientRect()
        SET_STAGE_DIMENSIONS({
          width,
          height,
        })
        loadFirstTimeImage()
      }
    })
    if (stageDiv.value) {
      resizeObserver.observe(stageDiv.value as HTMLDivElement)
    }
  })
  function getShapeComponent(shapeKey: ShapeKey) {
    const shapeComponents = {
      BOX: ShapeBox,
      ARROW: ShapeArrow,
      CIRCLE: ShapeCircle,
      STICKER: ShapeSticker,
      DRAW: ShapeDrawn,
      TEXT: ShapeText,
    }
    return shapeComponents[shapeKey as keyof typeof shapeComponents] || null
  }
</script>

<template>
  <main class="w-full flex justify-content-center align-items-center">
    <div class="absolute top-0 left-0 opacity-0">
      <v-stage
        ref="stageExportRef"
        :config="
          image.rotation === 90
            ? {
                width: image?.height,
                height: image?.width,
                rotation: image.rotation,
                offsetY: image.height,
                offsetX: 0,
              }
            : image.rotation === 180
            ? {
                width: image?.width,
                height: image?.height,
                rotation: image.rotation,
                offsetY: image.height,
                offsetX: image.width,
              }
            : image.rotation === 270
            ? {
                width: image?.height,
                height: image?.width,
                rotation: image.rotation,
                offsetY: 0,
                offsetX: image.width,
              }
            : {
                width: image?.width,
                height: image?.height,
                rotation: image.rotation,
                offsetY: 0,
                offsetX: 0,
              }
        "
        @click="handleClick"
        @touch="handleClick"
        @touchstart="handleMouseDown"
        @touchmove="handleMouseMove"
        @touchend="handleMouseUp"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
      >
        <v-layer>
          <v-image
            :config="{
              width: image?.width,
              height: image?.height,
              image: ImageRender,
            }"
          />
        </v-layer>
        <v-layer>
          <template v-for="shp in Object.values(shapes)" :key="shp.id">
            <component
              :is="getShapeComponent(shp.shapeKey)"
              :image="imageProps"
              :shape="shp"
              :is-selected="false"
              :draggable="false"
              :device="device"
              @onselectshape="handleSelect"
              @ondragstart="() => {}"
              @ondragmoving="() => {}"
              @ondragstop="handleDragStop"
              @ondelete="handleDelete"
              @ontext="handleText"
            />
          </template>
        </v-layer>
        <v-layer>
          <EditorStageInDraw :image="imageProps" />
        </v-layer>
      </v-stage>
    </div>
    <section ref="stageDiv" class="h-full flex justify-content-center align-items-center relative" style="width: 50%; background-color: #151515">
      <v-stage
        :config="
          image.rotation === 90
            ? {
                width: image?.height,
                height: image?.width,
                rotation: image.rotation,
                offsetY: image.height,
                offsetX: 0,
              }
            : image.rotation === 180
            ? {
                width: image?.width,
                height: image?.height,
                rotation: image.rotation,
                offsetY: image.height,
                offsetX: image.width,
              }
            : image.rotation === 270
            ? {
                width: image?.height,
                height: image?.width,
                rotation: image.rotation,
                offsetY: 0,
                offsetX: image.width,
              }
            : {
                width: image?.width,
                height: image?.height,
                rotation: image.rotation,
                offsetY: 0,
                offsetX: 0,
              }
        "
        @click="handleClick"
        @touch="handleClick"
        @touchstart="handleMouseDown"
        @touchmove="handleMouseMove"
        @touchend="handleMouseUp"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
      >
        <v-layer>
          <v-image
            :config="{
              width: image?.width,
              height: image?.height,
              image: ImageRender,
            }"
          />
        </v-layer>
        <v-layer>
          <template v-for="shp in Object.values(shapes)" :key="shp.id">
            <component
              :is="getShapeComponent(shp.shapeKey)"
              :image="imageProps"
              :shape="shp"
              :is-selected="shp?.id === shapeId"
              :draggable="shp?.id === shapeId"
              :device="device"
              @onselectshape="handleSelect"
              @ondragstart="() => {}"
              @ondragmoving="() => {}"
              @ondragstop="handleDragStop"
              @oncopy="handleCopy"
              @ondelete="handleDelete"
              @ontext="handleText"
            />
          </template>
        </v-layer>
        <v-layer>
          <EditorStageInDraw :image="imageProps" />
        </v-layer>
      </v-stage>
    </section>
    <section class="absolute top-0 right-0 p-4 flex flex-row gap-2">
      <button class="button_save" style="background-color: #00ff65" @click="hadleSave">
        <svg width="292" height="292" viewBox="0 0 292 292" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: scale(1.7)">
          <path
            d="M287.063 146.001C287.063 125.888 276.732 108.324 261.303 98.1989C265.091 80.0835 259.856 60.3845 245.736 46.2645C231.616 32.0756 211.848 26.9097 193.802 30.698C183.608 15.2693 165.975 4.9375 146.001 4.9375C126.026 4.9375 108.393 15.2693 98.1989 30.698C80.0835 26.9097 60.3845 32.1445 46.2645 46.2645C32.0756 60.3845 26.9097 80.1527 30.698 98.1989C15.2004 108.393 4.9375 126.026 4.9375 146.001C4.9375 165.975 15.2693 183.608 30.698 193.802C26.9097 211.917 32.1445 231.616 46.2645 245.736C60.3845 259.925 80.0149 265.091 98.1989 261.303C108.393 276.8 126.026 287.063 146.001 287.063C166.044 287.063 183.608 276.732 193.802 261.303C211.986 265.091 231.616 259.856 245.736 245.736C259.925 231.616 265.091 211.848 261.303 193.802C276.8 183.608 287.063 165.975 287.063 146.001ZM199.581 124.493L137.866 186.208C135.284 188.791 131.9 190.082 128.516 190.082C125.133 190.082 121.749 188.791 119.166 186.208L92.7163 159.759C87.5504 154.593 87.5504 146.224 92.7163 141.058C97.8822 135.892 106.251 135.892 111.417 141.058L128.516 158.157L180.881 105.792C186.046 100.626 194.415 100.626 199.581 105.792C204.747 110.958 204.747 119.327 199.581 124.493Z"
            fill="black"
          />
        </svg>
      </button>
      <button class="button_save" @click="handleClose">
        <i class="pi pi-times" style="font-size: 1.2rem; line-height: 0%"></i>
      </button>
    </section>
  </main>
</template>

<style scoped>
  .button_save {
    border: none;
    padding: 12px;
    height: 35px;
    width: 35px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
</style>
